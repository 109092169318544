import { Component, Input } from "@angular/core";
import { FormControl, ValidationErrors } from "@angular/forms";
import { NgClass, NgIf } from "@angular/common";
import { ParkourFormErrorMessageComponent } from "../parkour-form-error-message/parkour-form-error-message";

@Component({
  standalone: true,
  selector: "parkour-form-field-without-label",
  templateUrl: "./parkour-form-field-without-label.component.html",
  imports: [NgIf, NgClass, ParkourFormErrorMessageComponent],
})
export class ParkourFormFieldWithoutLabelComponent {
  @Input() public classes: string = "";
  @Input({ required: true }) public inputId!: string;
  @Input({ required: true }) public accessibleLabel!: string;
  @Input() public testId?: string;
  @Input() public errorLabel: string | null = null;
  @Input() public isLabelHidden = false;
  @Input() public inputFormControl?: FormControl;
  @Input() public errorMessages?: Record<string, string>;
  @Input() public additionalErrors: ValidationErrors | null = null;
  @Input() public required = false;
  protected readonly Object = Object;

  getFormErrors() {
    return {
      ...this.inputFormControl?.errors,
      ...this.additionalErrors,
    };
  }

  getErrorMessage(key: string) {
    const errorStrings: Record<string, string> = {
      required: `Vul een ${this.errorLabel?.toLocaleLowerCase()} in.`,
      ...this.errorMessages,
    };
    if (key in errorStrings) {
      return errorStrings[key];
    } else return null;
  }
}
