import { Component, Input } from "@angular/core";

import { IParkourAvatarBadgeImageProps } from "./parkour-avatar-badge.types";
import { NgClass, NgIf } from "@angular/common";
import { ParkourIconComponent } from "../parkour-icon/parkour-icon.component";
import { ParkourImageComponent } from "../parkour-image/parkour-image.component";

@Component({
  standalone: true,
  selector: "parkour-avatar-badge",
  templateUrl: "./parkour-avatar-badge.component.html",
  imports: [NgClass, ParkourIconComponent, ParkourImageComponent, NgIf],
})
export class ParkourAvatarBadgeComponent {
  @Input() public classes?: string;
  @Input({ required: true }) public image!: IParkourAvatarBadgeImageProps;
  @Input() public badgeCount?: number | undefined;
}
