import { Injectable } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ContactFormGroup } from '../../profiel/component/profile-details-contact-edit/profile-details-contact-edit.component';
import { OverJezelfFormGroup } from '../../profiel/component/profile-details-over-jezelf-edit/profile-details-over-jezelf-edit.component';
import { Avatar, ProfielNaamType } from 'parkour-web-app-dto';
import { NaamFormGroup } from '../../profiel/component/profile-details-profielnaam-edit/profile-details-profielnaam-edit.component';
import { ProfielFotoFormGroup } from '../../profiel/component/profiel-details-foto-edit/profiel-details-foto-edit.component';
import { AfwezigFormGroup } from '../component/afwezig/afwezig.component';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class ProfielFormService {
  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly datePipe: DatePipe,
  ) {}

  createContactFormGroup(contact: { email?: string; telefoonnummer?: string }): ContactFormGroup {
    let type: 'EMAIL' | 'GSM' = 'EMAIL';
    if (contact.email && !contact.telefoonnummer) {
      type = 'EMAIL';
    } else if (contact.telefoonnummer && !contact.email) {
      type = 'GSM';
    }
    const contactForm = this.formBuilder.nonNullable.group(
      {
        type: this.formBuilder.nonNullable.control<'EMAIL' | 'GSM'>(type),
        email: this.formBuilder.nonNullable.control(contact.email),
        telefoonnummer: this.formBuilder.nonNullable.control(contact.telefoonnummer),
      },
      { validators: [contactValidator] },
    );

    contactForm.controls.type.valueChanges.subscribe((selectedValue) => {
      if (selectedValue === 'EMAIL') {
        contactForm.patchValue({ telefoonnummer: undefined });
      } else if (selectedValue === 'GSM') {
        contactForm.patchValue({ email: undefined });
        contactForm.patchValue({ telefoonnummer: '+32' });
      }
    });
    return contactForm;
  }

  createOverJezelfFormGroup(overJezelf: {
    bio?: string;
    links: string[];
    adres?: string;
  }): OverJezelfFormGroup {
    return this.formBuilder.nonNullable.group({
      bio: this.formBuilder.nonNullable.control(overJezelf.bio),
      links: this.formBuilder.nonNullable.array(
        overJezelf.links.map((link) =>
          this.formBuilder.nonNullable.control(link, Validators.required),
        ),
      ),
      adres: this.formBuilder.nonNullable.control(overJezelf.adres),
    });
  }

  createAfwezigFormGroup(afwezigheid: {
    afwezig: boolean;
    van?: string;
    tot?: string;
    bericht?: string;
  }): AfwezigFormGroup {
    return this.formBuilder.nonNullable.group(
      {
        afwezig: this.formBuilder.nonNullable.control(
          afwezigheid.afwezig ? afwezigheid.afwezig : false,
        ),
        van: this.formBuilder.nonNullable.control(afwezigheid.van),
        tot: this.formBuilder.nonNullable.control(afwezigheid.tot),
        bericht: this.formBuilder.nonNullable.control(afwezigheid.bericht),
      },
      { validators: [fromDateNotAfterToDateValidator] },
    );
  }

  createProfielNaamFormGroup(profielNaam: {
    roepnaam?: string;
    profielnaamType: ProfielNaamType;
  }): NaamFormGroup {
    const naamForm = this.formBuilder.nonNullable.group(
      {
        profielnaamType: this.formBuilder.nonNullable.control<ProfielNaamType>(
          profielNaam.profielnaamType,
        ),
        roepnaam: this.formBuilder.nonNullable.control(profielNaam.roepnaam),
      },
      { validators: roepNaamValidator },
    );

    naamForm.controls.profielnaamType.valueChanges.subscribe((selectedValue) => {
      if (selectedValue === 'VOLLEDIGE_NAAM') {
        naamForm.patchValue({ roepnaam: undefined });
      }
    });

    return naamForm;
  }

  createProfielFotoFormGroup(profielFoto: {
    avatar: Avatar;
    profielFoto?: string;
    profielFotoFile?: File;
  }): ProfielFotoFormGroup {
    return this.formBuilder.nonNullable.group({
      avatar: this.formBuilder.nonNullable.control<Avatar>(profielFoto.avatar),
      profielFoto: this.formBuilder.nonNullable.control(profielFoto.profielFoto),
      profielFotoFile: this.formBuilder.nonNullable.control(profielFoto.profielFotoFile),
    });
  }
}

const roepNaamValidator: ValidatorFn = (form: AbstractControl): ValidationErrors | null => {
  switch (form.getRawValue().profielnaamType) {
    case 'ROEPNAAM': {
      const input = form.getRawValue().roepnaam;
      const invisibleUnicodePattern =
        // eslint-disable-next-line no-control-regex, no-misleading-character-class
        /[\u0000-\u001F\u007F-\u009F\u00AD\u034F\u061C\u115F-\u1160\u17B4-\u17B5\u180B-\u180E\u200B-\u200F\u202A-\u202E\u2060-\u2064\u2066-\u206F\uFEFF\uFFF9-\uFFFB]/;

      if (!input) {
        return { roepnaamRequired: true };
      }

      if (/^\s/.test(input) || input.trim() === '') {
        return { invalidSpacing: true };
      }

      if (invisibleUnicodePattern.test(input)) {
        return { invisibleUnicode: true };
      }

      return null;
    }
  }
  return null;
};

const contactValidator: ValidatorFn = (form: AbstractControl): ValidationErrors | null => {
  switch (form.getRawValue().type) {
    case 'EMAIL': {
      return form.getRawValue().email ? null : { emailRequired: true };
    }
    case 'GSM': {
      return form.getRawValue().telefoonnummer ? null : { telefoonnummerRequired: true };
    }
  }
  return null;
};

const fromDateNotAfterToDateValidator: ValidatorFn = (
  form: AbstractControl,
): ValidationErrors | null => {
  const fromDate = form.getRawValue().van;
  const toDate = form.getRawValue().tot;

  if (!fromDate || !toDate) {
    return null;
  }

  const fromDateValue = new Date(fromDate);
  const toDateValue = new Date(toDate);

  return fromDateValue > toDateValue ? { dateRangeInvalid: true } : null;
};
