import { Pipe, PipeTransform } from '@angular/core';
import { Bericht } from '../model/gesprek';

@Pipe({
  standalone: true,
  name: 'berichtenData',
})
export class BerichtenDataPipe implements PipeTransform {
  transform(berichten: Bericht[]): Bericht[][] {
    return berichten.reduce((acc: Bericht[][], currentItem: Bericht) => {
      const currentGroup: Bericht[] = acc[acc.length - 1];

      if (
        !currentGroup ||
        !('deelnemerId' in currentItem) ||
        !('deelnemerId' in currentGroup[0]) ||
        currentGroup[0].deelnemerId !== currentItem.deelnemerId
      ) {
        acc.push([currentItem]);
      } else {
        currentGroup.push(currentItem);
      }

      return acc;
    }, []);
  }
}
