import { Component } from '@angular/core';
import { IonContent, IonHeader, IonTitle, IonToolbar } from '@ionic/angular/standalone';
import { PageLayoutComponent } from '../../../shared/components/page-layout/page-layout.component';
import { ParkourButtonComponent, ParkourInfoCardComponent } from '@parkour/ui';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  templateUrl: './uitnodiging-geweigerd.page.html',
  imports: [
    IonHeader,
    IonContent,
    PageLayoutComponent,
    ParkourButtonComponent,
    ParkourInfoCardComponent,
    TranslateModule,
    IonTitle,
    IonToolbar,
  ],
})
export class UitnodigingGeweigerdPage {}
