import { ExterneHoedanigheid, PersoonId, ProfielId, RoleDto } from 'parkour-web-app-dto';

export type UserRole = RoleDto;

export function validateAangemeldeUser(
  user: User,
  errorMessage = 'Gebruiker heeft geen profiel',
): AangemeldeUser {
  if (user.type !== 'aangemeld') {
    throw new Error(errorMessage);
  }

  return user;
}

export function getVolledigeNaam(user: GeenProfielUser | AangemeldeUser): string {
  return `${user.voornaam} ${user.naam}`;
}

export type AnoniemeUser = {
  readonly type: 'anoniem';
  readonly error: boolean;
};

export type AangemeldeUser = {
  readonly type: 'aangemeld';
  readonly profielId: ProfielId;
  readonly persoonId: PersoonId;
  readonly hoedanigheid: ExterneHoedanigheid;
  readonly voornaam: string;
  readonly naam: string;
  readonly error: boolean;
};

export type User = AnoniemeUser | AangemeldeUser;

export type GeenProfielUser = {
  readonly type: 'geen-profiel';
  readonly hoedanigheid: ExterneHoedanigheid;
  readonly persoonId: PersoonId;
  readonly voornaam: string;
  readonly naam: string;
  readonly profielOpties: ProfielId[];
  readonly error: boolean;
};

export function validateGeenProfielUser(user: DetailedUser): GeenProfielUser {
  if (user.type !== 'geen-profiel') {
    throw new Error('User is niet correct');
  }

  return user;
}

export type DetailedUser = AnoniemeUser | AangemeldeUser | GeenProfielUser;
export type RetrieveUserCause =
  | 'from-background'
  | 'from-deeplink'
  | 'login'
  | 'logout'
  | 'profiel-created'
  | '401'
  | 'profiel-selected'
  | 'refresh'
  | 'initial';
