import { Component, Input } from '@angular/core';
import { WatWatSelfTest } from 'parkour-web-app-dto';
import { ParkourActionComponent, ParkourIconComponent } from '@parkour/ui';

@Component({
  selector: 'parkour-artikel-self-test-item',
  standalone: true,
  imports: [ParkourActionComponent, ParkourIconComponent],
  templateUrl: './artikel-self-test-item.component.html',
})
export class ArtikelSelfTestItemComponent {
  @Input({ required: true }) selfTest!: WatWatSelfTest;
}
