import { Component, Input } from "@angular/core";
import { NgStyle } from "@angular/common";

@Component({
  selector: "parkour-progress",
  templateUrl: "./parkour-progress.component.html",
  standalone: true,
  imports: [NgStyle],
})
export class ParkourProgressComponent {
  @Input({ required: true }) public progress!: number;
}
