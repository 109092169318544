import { Component, ElementRef, Input, viewChild } from '@angular/core';
import { EMPTY, filter, finalize, Observable, startWith, switchMap, take, tap } from 'rxjs';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormGroupToErrorConfig, RawValueOfFormGroup } from './parkour-form.types';
import { stripNullProperties } from '../../../utils';
import { TranslateModule } from '@ngx-translate/core';
import { ErrorSummaryComponent } from '../error-summary/error-summary.component';

export type FormSubmitObservableGenerator<Type> = (formValue: Type) => Observable<unknown>;
export type ErrorLabelMapping<Type extends FormGroup> = FormGroupToErrorConfig<Type>;

@Component({
  selector: 'parkour-form',
  templateUrl: './parkour-form.component.html',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, TranslateModule, ErrorSummaryComponent],
})
export class ParkourFormComponent<Type extends FormGroup> {
  scrollAnchor = viewChild.required<ElementRef<HTMLElement>>('scrollAnchor');
  @Input({ required: true }) submitObservableGenerator!: FormSubmitObservableGenerator<
    RawValueOfFormGroup<Type>
  >;
  @Input({ required: true }) formId!: string;
  @Input({ required: true }) formGroup!: Type;

  @Input({ required: true }) errorLabelMapping!: ErrorLabelMapping<Type> | false;

  showErrorSummary = false;
  errorFields: string[] = [];

  constructor() {}

  private _busy = false;

  get busy(): boolean {
    return this._busy;
  }

  protected onSubmit() {
    this.formGroup.markAllAsTouched();

    if (this.formGroup.invalid && this.errorLabelMapping !== false) {
      this.errorFields = this.getErrorFields(this.formGroup, this.errorLabelMapping);
      this.scrollAnchor().nativeElement.scrollIntoView({ behavior: 'smooth' });

      return;
    }

    if (this._busy) {
      return;
    }

    this._busy = true;
    this.showErrorSummary = false;

    this.formGroup.statusChanges
      .pipe(
        startWith(this.formGroup.status),
        filter((status) => status !== 'PENDING'),
        take(1),
        switchMap((status) => {
          // Check form validity again, because it might have changed
          if (status === 'INVALID') {
            return EMPTY;
          } else {
            this.formGroup.markAsPristine();
            return this.submitObservableGenerator(
              stripNullProperties(this.formGroup.getRawValue()),
            );
          }
        }),
        tap(() => {
          this.formGroup.reset();
          this.formGroup.markAsPristine(); //Second markAsPristine after reset is needed for async validator
        }),
        finalize(() => (this._busy = false)),
      )
      .subscribe();
  }

  private getErrorFields<T extends FormGroup>(
    formGroup: T,
    errorConfig: ErrorLabelMapping<T> | false,
  ): string[] {
    let fields: string[] = [];

    if (errorConfig === false) {
      return fields;
    }

    Object.keys(formGroup.controls).forEach((key) => {
      const control = formGroup.get(key);

      if (control instanceof FormGroup) {
        const childConfig = errorConfig[key];
        if (childConfig && typeof childConfig === 'object') {
          fields = fields.concat(this.getErrorFields(control, childConfig));
        }
      } else if (control?.errors) {
        const label = errorConfig[key];
        if (typeof label === 'string') {
          fields.push(label);
        }
      }
    });

    return fields;
  }
}
