import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { IonRefresher } from '@ionic/angular/standalone';
import { RefresherCustomEvent } from '@ionic/angular/standalone';
import { Subscription } from 'rxjs';

@Directive({
  standalone: true,
  selector: '[parkourRefresh]',
  providers: [IonRefresher],
})
export class ParkourRefreshDirective implements OnDestroy, OnInit {
  constructor(private readonly ionRefresher: IonRefresher) {}

  private subscription?: Subscription;

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  ngOnInit(): void {
    this.ionRefresher.disabled = false;
    this.subscription = this.ionRefresher.ionRefresh.subscribe((event: RefresherCustomEvent) =>
      this.parkourRefresh(event),
    );
  }

  @Input({ required: true })
  public parkourRefresh!: (event: RefresherCustomEvent) => void;
}
