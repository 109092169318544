import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { BerichtenDatePipe } from '../../pipes/berichten-date.pipe';
import { LinkifyPipe } from '../../pipes/linkify.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { isPlatform } from '@ionic/core';
import { ProfielnaamPipe } from '../../../shared/pipes/profielnaam.pipe';
import { AttachmentBericht, SpraakBericht, TextBericht } from '../../model/gesprek';
import { getIsTimeToday } from '../../../utils';
import { ParkourButtonComponent } from '@parkour/ui';

@Component({
  selector: 'parkour-bericht-text-wrapper',
  standalone: true,
  imports: [
    AsyncPipe,
    NgIf,
    BerichtenDatePipe,
    LinkifyPipe,
    TranslateModule,
    NgClass,
    ProfielnaamPipe,
    ParkourButtonComponent,
  ],
  templateUrl: './bericht-text-wrapper.component.html',
})
export class BerichtTextWrapperComponent {
  @Input({ required: true }) bericht!: TextBericht | SpraakBericht | AttachmentBericht;
  @Input() isUser: boolean = false;
  @Input() disabled: boolean = false;
  @Input() hasSpacing: boolean = true;

  @Output() moreOptionsClick = new EventEmitter<void>();
  protected readonly getIsTimeToday = getIsTimeToday;

  isDesktop() {
    return isPlatform('desktop');
  }

  onMoreOptionsClick() {
    this.moreOptionsClick.emit();
  }
}
