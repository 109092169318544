<parkour-page-with-hero
  [heroImage]="gebeurtenis | gebeurtenisHero | async"
  [title]="gebeurtenis.titel"
>
  <parkour-ex-jongere-info-card info-card />
  <ng-container hero-above-title>
    <p class="typo-body variant-body-fat">
      {{ 'verhaal.detail-page.sub-titel-gebeurtenis' | translate }}
    </p>
  </ng-container>

  <ng-container hero-content>
    @if (gebeurtenis.startDatum) {
      <span class="text-n-600 typo-h5" data-testid="datum">
        {{ (gebeurtenis.startDatum | date) ?? '' }}
      </span>
    }
  </ng-container>

  <parkour-gedeeld-met-indicator
    (clicked)="gedeeldMetInfoComponent.open()"
    [deelMode]="gebeurtenis.deelMode"
    [gedeeldMet]="gebeurtenis.gedeeldMet"
    class="self-center"
    format="button"
    size="large"
    testId="deel-details-button"
  />
</parkour-page-with-hero>

<div class="container container-space">
  <div class="container-1-col flex flex-col gap-y-8">
    <p class="typo-body whitespace-pre-wrap" data-testid="omschrijving">
      {{ gebeurtenis.omschrijving }}
    </p>

    @if (gebeurtenis.bijlagen.length) {
      <div class="space-y-2">
        <h2 class="typo-h3">{{ 'verhaal.detail-page.bijlagen' | translate }}</h2>

        <ul class="space-y-4">
          @for (bijlage of gebeurtenis.bijlagen; track bijlage) {
            <li>
              <parkour-button
                (clicked)="downloadFile(bijlage)"
                classes="!text-left grid grid-cols-[auto_1fr]"
                iconBefore="attachment"
                size="none"
                variant="ghost"
              >
                {{ bijlage.naam }}
              </parkour-button>
            </li>
          }
        </ul>
      </div>
    }
  </div>
</div>

<parkour-gedeeld-met-info
  #gedeeldMetInfoComponent
  [exJongereView]="exJongereView"
  [deelMode]="gebeurtenis.deelMode"
  [gedeeldMet]="gebeurtenis.gedeeldMet"
/>
