<div aria-live="assertive" class="chat-flow">
  @if (getDeelnemerProfiel(gesprek); as deelnemerProfiel) {
    @if (
      deelnemerProfiel &&
      isEenOpEenGesprek(gesprek) &&
      deelnemerProfiel.status === 'ACTIEF' &&
      deelnemerProfiel.afwezigheid.afwezig &&
      checkIfActiveDate(deelnemerProfiel.afwezigheid.van, deelnemerProfiel.afwezigheid.tot)
    ) {
      <div class="chat-flow__wrapper chat-flow__afwezigheid">
        <parkour-event-afwezigheid
          [afwezigheid]="deelnemerProfiel.afwezigheid"
          [professioneel]="deelnemerProfiel.type === 'PROFESSIONEEL_TEAMLID'"
          class="relative z-0 self-center max-w-[28rem] w-full"
        />
      </div>
    }
  }

  @if (isEenOpEenGesprek(gesprek) && gesprek.videogesprekId) {
    <div class="chat-flow__wrapper">
      <parkour-event-start-videogesprek
        class="relative z-0 self-center max-w-[28rem] w-full"
        (clicked)="onVideoGesprekJoin()"
      />
    </div>
  }

  @for (bericht of berichten.slice().reverse(); track bericht[0].id) {
    <div class="chat-flow__wrapper">
      @for (subBericht of bericht.slice().reverse(); track subBericht.id) {
        @if (subBericht | isSupportedBericht) {
          @switch (subBericht.type) {
            @case ('EVENT') {
              <parkour-bericht-event [bericht]="subBericht" />
            }
            @case ('GEMASKEERD') {
              <parkour-bericht-gemaskeerd
                [isUser]="subBericht.deelnemerId === profiel.id"
                [gesprekWithDeelnemers]="gesprek"
              />
            }
            @default {
              <parkour-bericht-message
                (rapporteer)="onRapporteer($event)"
                (closingPopover)="closedBerichtMessagePopover()"
                (deleteReactie)="onDeleteReactie($event)"
                (openedPopover)="openedBerichtMessagePopover()"
                (selectedReactie)="onSelectedReactie($event)"
                (verwijderd)="onVerwijderd(subBericht.id)"
                (saveNotitie)="onSaveNotitie($event)"
                (replyBericht)="onReplyBericht($event)"
                (scrollToBottom)="onScrollToBottom()"
                [bericht]="subBericht"
                [gesprekId]="gesprekId"
                [gesprekWithDeelnemers]="gesprek"
                [isUser]="subBericht.deelnemerId === profiel.id"
                [userProfielId]="profiel.id"
                [berichten]="berichten"
                [disabled]="isGesprekGestopt()"
              />
            }
          }
        }
      }
    </div>
  }
</div>
