import { Component } from '@angular/core';
import { MeldingenViewPage, MeldingView } from '../../model/meldingen';
import { ActivatedRoute, Router } from '@angular/router';
import { ParkourBackButtonDirective } from '../../../shared/directives/parkour-back-button.directive';
import {
  ParkourBlockPlaceholderComponent,
  ParkourButtonComponent,
  ParkourLoadingSpinnerComponent,
  ParkourPageGradientComponent,
} from '@parkour/ui';
import { ParkourMeldingComponent } from '../../component/parkour-notification/parkour-melding.component';
import { ParkourUnsupportedMeldingComponent } from '../../component/parkour-unsupported-melding/parkour-unsupported-melding.component';
import { TranslateModule } from '@ngx-translate/core';
import { PageLayoutComponent } from '../../../shared/components/page-layout/page-layout.component';
import { ParkourPaginatedPage } from '../../../shared/parkour-paginated-page';
import { combineLatest, filter, map, Observable, switchMap, take, tap } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonInfiniteScroll,
  IonRadio,
  IonRadioGroup,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';
import { ParkourRefreshDirective } from '../../../shared/directives/parkour-refresh-directive';
import AuthService from '../../../authentication/service/auth.service';
import { LoggingService } from '../../../core/logging.service';
import { ContentUnavailableCardComponent } from '../../../shared/components/content-unavailable-card/content-unavailable-card.component';
import { ContextService } from '../../../shared/services/context.service';
import { getProfielnaam } from '../../model/profiel-in-team';
import { Context } from '../../../shared/model/context';
import { ContextId } from 'parkour-web-app-dto';
import { ProfielFotoBadgeComponent } from '../../../shared/components/profiel-foto-badge/profiel-foto-badge.component';
import { FotoBadgeComponent } from '../../../shared/components/foto-badge/foto-badge.component';

type MeldingePageData = {
  context: Context;
  showCurrentContextOnly: boolean;
};

@Component({
  standalone: true,
  templateUrl: './meldingen.page.html',
  styleUrls: ['./meldingen.page.css'],
  imports: [
    ParkourBackButtonDirective,
    ParkourPageGradientComponent,
    ParkourLoadingSpinnerComponent,
    ParkourMeldingComponent,
    ParkourUnsupportedMeldingComponent,
    ParkourBlockPlaceholderComponent,
    TranslateModule,
    PageLayoutComponent,
    AsyncPipe,
    IonBackButton,
    IonRefresher,
    IonRefresherContent,
    IonContent,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonTitle,
    ParkourRefreshDirective,
    IonInfiniteScroll,
    ContentUnavailableCardComponent,
    ParkourButtonComponent,
    IonRadioGroup,
    IonRadio,
    ProfielFotoBadgeComponent,
    FotoBadgeComponent,
  ],
})
export class MeldingenPage extends ParkourPaginatedPage<
  MeldingView,
  { totalOngelezenMeldingen: number }
> {
  protected readonly context$ = this.contextService.context$.pipe(take(1));
  protected showCurrentContextOnly$ = this.route.queryParamMap.pipe(
    map((params) => {
      const showCurrentContextOnly = params.get('showCurrentContextOnly');
      return showCurrentContextOnly === 'true';
    }),
  );

  protected data$: Observable<MeldingePageData> = combineLatest([
    this.context$,
    this.showCurrentContextOnly$,
  ]).pipe(map(([context, showCurrentContextOnly]) => ({ context, showCurrentContextOnly })));

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly authService: AuthService,
    private readonly contextService: ContextService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    loggingService: LoggingService,
  ) {
    super(loggingService);
  }

  protected override getInitialExtraData(): { totalOngelezenMeldingen: number } {
    return {
      totalOngelezenMeldingen: 0,
    };
  }

  override retrievePage = (pageNumber: number): Observable<MeldingenViewPage> => {
    this.loading = true;
    return combineLatest([
      this.authService.getAangemeldeUser$(),
      this.contextService.context$,
      this.showCurrentContextOnly$,
    ]).pipe(
      switchMap(([user, context, currentContextOnly]) =>
        this.meldingenService.getMeldingen(
          user.profielId,
          pageNumber,
          currentContextOnly ? context.contextId : '',
        ),
      ),
      tap(() => {
        this.loading = false;
      }),
    );
  };

  override ionViewWillEnter(): void {
    super.ionViewWillEnter();

    const meldingId = this.activatedRoute.snapshot.queryParamMap.get('meldingId');

    if (meldingId) {
      this.router
        .navigate([], {
          queryParams: {
            meldingId: null,
          },
          queryParamsHandling: 'merge',
        })
        .then(() => this.meldingenService.navigateToMelding(meldingId));
    }

    this.showCurrentContextOnly$.subscribe(() => this.refreshPageDataOnly());
  }

  handleMeldingClicked(melding: MeldingView) {
    this.meldingenService.clickMelding(melding).subscribe();
  }

  onMarkAsReadClick() {
    this.meldingenService.markAllMeldingenAsRead().subscribe(() => this.refreshPageDataOnly());
  }

  onMarkMeldingenInCurrentContextAsReadClick(contextId: ContextId) {
    this.meldingenService
      .markMeldingenWitContextIdAsRead(contextId)
      .subscribe(() => this.refreshPageDataOnly());
  }

  onFilterChange(event: CustomEvent) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        showCurrentContextOnly: event.detail.value === 'contextOnly',
      },
      queryParamsHandling: 'merge',
    });
  }
  // TODO delete if current design is OK
  // onFilterChangeCheckBox(value: boolean) {
  //   this.router.navigate([], {
  //     relativeTo: this.route,
  //     queryParams: {
  //       showCurrentContextOnly: value,
  //     },
  //     queryParamsHandling: 'merge',
  //   });
  // }

  protected readonly getProfielnaam = getProfielnaam;
  protected readonly JSON = JSON;
  protected readonly filter = filter;
}
