import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  BookmarkDto,
  BookmarkId,
  BookmarkItemType,
  BookmarksDto,
  BookmarkUpsertDto,
} from 'parkour-web-app-dto';
import { catchError, Observable, of, switchMap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { asType } from '../../utils';
import { LoggingService } from '../../core/logging.service';
import AuthService from '../../authentication/service/auth.service';
import { AnalyticsService } from '../../analytics/analytics.service';
import { AnalyticsEvent, trackAnalyticsEvent } from '../../analytics/analytics-event.model';

@Injectable({
  providedIn: 'root',
})
export class BookmarkService {
  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private analyticsService: AnalyticsService,
    private loggingService: LoggingService,
  ) {}

  addBookmark(bookmark: BookmarkUpsertDto): Observable<BookmarkDto> {
    return this.http
      .post<BookmarkDto>(
        `${environment.API_BASE_URL}/api/profiel/me/bewaarde-items/bookmarks`,
        asType<BookmarkUpsertDto>(bookmark),
      )
      .pipe(
        trackAnalyticsEvent(
          this.analyticsService,
          new AnalyticsEvent(
            'bewaardeItems',
            'bookmarkAangemaakt',
            bookmark.itemType.toLowerCase(),
          ),
        ),
      );
  }

  getBookmarks(
    itemType: BookmarkItemType,
    itemIds?: string[],
    pageSize?: number,
    pageNumber?: number,
  ): Observable<BookmarksDto> {
    // Return empty array of bookmarks if user is not logged in

    return this.authService.getCurrentUser$().pipe(
      switchMap((user) => {
        if (user.type === 'aangemeld') {
          let params = new HttpParams();
          if (itemIds && itemIds.length > 0) {
            params = params.append('itemIds', encodeURIComponent(itemIds.join(',')));
          }

          if (pageSize) {
            params = params.append('pageSize', pageSize);
          }

          if (pageNumber) {
            params = params.append('pageNumber', pageNumber);
          }

          params = params.append('itemType', itemType);

          return this.http.get<BookmarksDto>(
            `${environment.API_BASE_URL}/api/profiel/me/bewaarde-items/bookmarks`,
            {
              params,
            },
          );
        } else {
          return of([]);
        }
      }),
      catchError((error) => {
        this.loggingService.error('Getting bookmarks failed', error);
        return of([]);
      }),
    );
  }

  deleteBookmark(bookmarkId: BookmarkId): Observable<void> {
    return this.http
      .delete<void>(
        `${environment.API_BASE_URL}/api/profiel/me/bewaarde-items/bookmarks/${bookmarkId}`,
      )
      .pipe(
        trackAnalyticsEvent(
          this.analyticsService,
          new AnalyticsEvent('bewaardeItems', 'bookmarkVerwijderd'),
        ),
      );
  }
}
