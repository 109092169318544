import { BrandedType, IdOf } from '../branded-type';
import { SuggestieId } from './gebeurtenis-suggestie.dto';
import { DeelMode } from './delen.dto';
import { ApiProperty, getSchemaPath } from '@nestjs/swagger';
import { ProfielId } from './profiel.dto';
import { DrieKolommenDocumentDto } from './drie-kolommen-document.dto';
import { Thema } from './thema.dto';
import { FileId } from './bericht.dto';

export type GebeurtenisId = BrandedType<string, 'gebeurtenis'>;
export type FotoId = IdOf<'foto'>;
export type VerhaalItemType = 'PARKOUR' | 'EYOUTH';

export class FileData {
  @ApiProperty({ type: String })
  readonly id!: FileId;
  readonly naam!: string;
}

class BaseGebeurtenisDto {
  @ApiProperty({ type: String })
  readonly id!: GebeurtenisId;
  readonly titel!: string;
  readonly omschrijving?: string;
  @ApiProperty({ type: String })
  readonly deelMode!: DeelMode;
  @ApiProperty({ type: [String] })
  readonly gedeeldMet!: ProfielId[];
  @ApiProperty({ type: String })
  readonly thema?: Thema;
  @ApiProperty({ type: String })
  readonly fotoId?: FotoId;
  readonly bijlagen!: FileData[];
  readonly startDatum!: string;
  readonly eindDatum?: string;
  readonly persoonId?: string;
}

export class ParkourGebeurtenisDto extends BaseGebeurtenisDto {
  @ApiProperty({ type: String })
  readonly type = "PARKOUR"
}

export class EyouthGebeurtenisDto extends BaseGebeurtenisDto {
  verborgenVoorJongere!: boolean;
  @ApiProperty({ type: String })
  readonly type = "EYOUTH"
}

export type GebeurtenisDto = ParkourGebeurtenisDto | EyouthGebeurtenisDto

export type VerhaalItem = GebeurtenisDto | DrieKolommenDocumentDto;

export class VerhaalDto {
  readonly error!: boolean;
  @ApiProperty({
    type: 'array',
    items: {
      anyOf: [
        { $ref: getSchemaPath(ParkourGebeurtenisDto) },
        { $ref: getSchemaPath(EyouthGebeurtenisDto) },
        { $ref: getSchemaPath(DrieKolommenDocumentDto) },
      ],
    },
  })
  readonly items!: Array<VerhaalItem>;
}

export class JeugdhulpHistoriekGebeurtenisUpsertDto {
  readonly verborgenVoorJongere!: boolean;
  @ApiProperty({ type: String })
  readonly deelMode!: DeelMode;
  @ApiProperty({ type: [String] })
  readonly gedeeldMet!: ProfielId[];
  @ApiProperty({ type: String })
  readonly type!: 'EYOUTH';
}

export class ParkourGebeurtenisUpsertDto {
  readonly titel?: string;
  readonly omschrijving?: string;
  readonly imageId?: FotoId;
  readonly startDatum?: string;
  readonly bijlagen!: FileData[];
  @ApiProperty({ type: String })
  readonly originSuggestie?: SuggestieId;
  @ApiProperty({ type: String })
  readonly deelMode!: DeelMode;
  @ApiProperty({ type: [String] })
  readonly gedeeldMet!: ProfielId[];
  @ApiProperty({ type: String })
  readonly type!: 'PARKOUR';
  @ApiProperty({ type: String })
  readonly thema?: Thema;
}

export type GebeurtenisUpsertDto =
  | ParkourGebeurtenisUpsertDto
  | JeugdhulpHistoriekGebeurtenisUpsertDto;
