import { Component, Input } from '@angular/core';
import { CoreModule } from '../../../core/core.module';
import { ParkourInfoCardComponent } from '@parkour/ui';

@Component({
  standalone: true,
  selector: 'parkour-content-unavailable-card',
  templateUrl: './content-unavailable-card.component.html',
  imports: [CoreModule, ParkourInfoCardComponent],
})
export class ContentUnavailableCardComponent {
  // TODO: Add copy to JSON errors content-unavailable
  @Input() description = 'We kunnen de inhoud nu niet weergeven.';
}
