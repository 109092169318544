import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ParkourIconComponent, ParkourInfoCardComponent } from '@parkour/ui';

@Component({
  standalone: true,
  selector: 'parkour-error-summary',
  templateUrl: './error-summary.component.html',
  imports: [TranslateModule, ParkourIconComponent, ParkourInfoCardComponent],
})
export class ErrorSummaryComponent {
  @Input({ required: true }) fields: string[] = [];
}
