import { Component, Input } from '@angular/core';
import { themaMapping } from '../../../../home/model/thema.const';
import { DatePipe, NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { Doel } from '../../model/doel';
import { ParkourDividerComponent, ParkourHeroComponent, ParkourIconComponent } from '@parkour/ui';

@Component({
  selector: 'parkour-doel-details-hero',
  standalone: true,
  imports: [
    DatePipe,
    TranslateModule,
    NgIf,
    ParkourIconComponent,
    ParkourHeroComponent,
    ParkourDividerComponent,
  ],
  templateUrl: './doel-details-hero.component.html',
})
export class DoelDetailsHeroComponent {
  @Input({ required: true }) doel!: Doel;

  protected readonly themaMapping = themaMapping;
}
