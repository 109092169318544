export class LoginWithTokenDto {
  readonly token!: string;
  readonly deviceId!: string;
}

export class GenerateBiometricsTokenDto {
  readonly deviceId!: string;
}

export class DeleteBiometricsTokenDto {
  readonly deviceId!: string;
}

export type SessionType = 'native_acm_idm' | 'native_biometrics' | 'web';

export const isNativeSession = (sessionType: SessionType | undefined) => {
  return sessionType === 'native_acm_idm' || sessionType === 'native_biometrics'
}