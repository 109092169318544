import { Component } from '@angular/core';
import { ParkourCookiesService } from '../../../shared/services/parkour-cookies.service';
import { AdaptToSidebarVisibleDirective } from '../../../shared/directives/adapt-to-sidebar-visible.directive';
import { ParkourBackButtonDirective } from '../../../shared/directives/parkour-back-button.directive';
import {
  ParkourButtonComponent,
  ParkourDividerComponent,
  ParkourInfoCardComponent,
  ParkourPageGradientComponent,
} from '@parkour/ui';
import { PageLayoutComponent } from '../../../shared/components/page-layout/page-layout.component';
import { ParkourPage } from 'src/app/shared/parkour-page';
import { ParkourRefreshDirective } from '../../../shared/directives/parkour-refresh-directive';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';
import { CoreModule } from '../../../core/core.module';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  templateUrl: './cookie-beleid.page.html',
  imports: [
    CoreModule,
    AdaptToSidebarVisibleDirective,
    ParkourBackButtonDirective,
    ParkourPageGradientComponent,
    ParkourDividerComponent,
    PageLayoutComponent,
    ParkourRefreshDirective,
    IonBackButton,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonTitle,
    IonContent,
    IonRefresher,
    IonRefresherContent,
    ParkourButtonComponent,
    ParkourInfoCardComponent,
    TranslateModule,
  ],
})
export class CookieBeleidPageComponent extends ParkourPage {
  constructor(protected readonly parkourCookiesService: ParkourCookiesService) {
    super();
  }
}
