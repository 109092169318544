import { Component } from '@angular/core';
import { AppUpdate, AppUpdateAvailability } from '@capawesome/capacitor-app-update';
import { isPlatform } from '@ionic/core';
import { IonRouterOutlet } from '@ionic/angular/standalone';
import { CoreModule } from '../../../core/core.module';
import { ParkourButtonComponent, ParkourImageComponent } from '@parkour/ui';
import { PageLayoutComponent } from '../../../shared/components/page-layout/page-layout.component';
import { IonContent, IonHeader } from '@ionic/angular/standalone';

@Component({
  templateUrl: './upgrade.page.html',
  standalone: true,
  imports: [
    CoreModule,
    ParkourButtonComponent,
    ParkourImageComponent,
    PageLayoutComponent,
    IonHeader,
    IonContent,
  ],
})
export class UpgradePage {
  constructor(private readonly routerOutlet: IonRouterOutlet) {}

  ionViewWillEnter() {
    this.routerOutlet.swipeGesture = false;
  }

  ionViewWillLeave() {
    this.routerOutlet.swipeGesture = true;
  }

  async openAppStore() {
    if (isPlatform('android')) {
      await this.performImmediateUpdate().catch(async () => await this.openAppstore());
    } else {
      await this.openAppstore();
    }
  }

  async openAppstore() {
    await AppUpdate.openAppStore({ country: 'be' });
  }

  async performImmediateUpdate() {
    const result = await AppUpdate.getAppUpdateInfo();

    if (result.updateAvailability !== AppUpdateAvailability.UPDATE_AVAILABLE) {
      await this.openAppstore();
      return;
    }

    if (result.immediateUpdateAllowed) {
      await AppUpdate.performImmediateUpdate();
    }
  }
}
