import { Component, HostBinding, Input } from '@angular/core';
import { EventBericht } from '../../model/gesprek';
import { EventUserLeftComponent } from '../event-user-left/event-user-left.component';
import { EventUserRejoinedComponent } from '../event-user-rejoined/event-user-rejoined.component';
import { EventEndVideogesprekComponent } from '../event-end-videogesprek/event-end-videogesprek.component';

@Component({
  standalone: true,
  selector: 'parkour-bericht-event',
  templateUrl: './bericht-event.component.html',
  imports: [EventUserLeftComponent, EventUserRejoinedComponent, EventEndVideogesprekComponent],
})
export class BerichtEventComponent {
  @HostBinding('class') get class() {
    return 'chat-flow__event';
  }

  @Input({ required: true }) bericht!: EventBericht;
}
