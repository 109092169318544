import { Component, HostBinding } from '@angular/core';
import { ParkourBlockPlaceholderComponent } from '@parkour/ui';

@Component({
  selector: 'parkour-verhaal-placeholder',
  standalone: true,
  imports: [ParkourBlockPlaceholderComponent],
  templateUrl: './verhaal-placeholder.component.html',
})
export class VerhaalPlaceholderComponent {
  @HostBinding('class') class = 'block';
}
