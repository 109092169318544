<div
  class="h-[calc(100vh-var(--app-plus-global-header-height)-var(--app-toolbar-height))] container container-space flex items-center justify-center"
>
  <div class="py-8 flex flex-col items-center gap-y-10 w-full container-1-col">
    @if (errorType === 'offline' || errorType === 'server-unreachable') {
      <div
        class="block relative w-[10rem] h-[10rem] rounded-[100vmax] bg-n-300 before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:rounded-[100vmax] before:bg-n-300 before:blur-[32px] before:scale-125"
      >
        <img
          class="absolute p-8 w-full h-full rounded-[100vmax]"
          src="/assets/general/no_connection.svg"
          alt=""
        />
      </div>
    } @else {
      <parkour-lottie-player
        [loop]="true"
        [src]="environment.API_BASE_URL + '/assets/general/animatie_404.json'"
        class="block relative w-[10rem] h-[10rem] p-8 rounded-[100vmax] bg-n-300 before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:rounded-[100vmax] before:bg-n-300 before:blur-[32px] before:scale-125"
      />
    }
    <div class="w-full">
      <div class="flex flex-col gap-y-3 text-center">
        <h1 class="typo-h2">Oeps!</h1>

        <p>
          @switch (errorType) {
            @case ('not-found') {
              Deze pagina lijkt niet te bestaan. <br />
              Zoek je weg terug via de startpagina.
            }
            @case ('offline') {
              Het lijkt erop dat je offline bent. <br />
              Controleer je internet verbinding en probeer opnieuw.
            }
            @case ('server-unreachable') {
              Er kon geen verbinding gemaakt worden met de server. <br />
              Probeer later opnieuw.
            }
            @default {
              Deze pagina lijkt niet te bestaan. <br />
              Zoek je weg terug via de startpagina.
            }
          }
        </p>
      </div>

      @if (errorType === 'offline' || errorType === 'server-unreachable') {
        @if (refresh === 'none') {
          <parkour-button (clicked)="onClickNavigateHome()" class="block w-full mt-6"
            >Ga naar home
          </parkour-button>
        } @else {
          <parkour-button (clicked)="refresh()" class="block w-full mt-6"
            >Probeer opnieuw
          </parkour-button>
        }
      } @else {
        <parkour-button (clicked)="onClickNavigateRootTab()" class="block w-full mt-6">
          @switch (activeTab) {
            @case ('home') {
              Ga naar home
            }
            @case ('profiel') {
              Terug naar profiel
            }
            @case ('doelen') {
              Terug naar doelen
            }
            @case ('verhaal') {
              Terug naar verhaal
            }
            @case ('berichten') {
              Terug naar berichten
            }
            @default {
              Ga naar home
            }
          }
        </parkour-button>
      }
    </div>
  </div>
</div>
