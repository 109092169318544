import { ApiProperty, getSchemaPath } from '@nestjs/swagger';
import { IdOf } from '../branded-type';
import { ProfielId } from './profiel.dto';
import { Page } from './page.dto';
import { InteractieEmoji, ReactiesDto } from './reactie.dto';
import { FotoId } from './gebeurtenis.dto';

export type GesprekId = IdOf<'gesprek'>;
export type BerichtId = IdOf<'bericht'>;
export type BestandId = IdOf<'bestand'>;
export type VideogesprekId = IdOf<'videogesprek'>;
export type EventId = IdOf<'event'>;
export type FileId = IdOf<'file'>;

export type GesprekEvent =
  | DeelnemerLeftEventDto
  | DeelnemerRejoinedEventDto
  | VideoGesprekEndedGesprekEventDto;

export class DeelnemerLeftEventDto {
  @ApiProperty({ type: String })
  readonly type: 'DEELNEMER_LEFT' = 'DEELNEMER_LEFT' as const;
  @ApiProperty({ type: String })
  readonly id!: EventId;
  @ApiProperty({ type: String })
  readonly deelnemerId!: ProfielId;
  readonly timestamp!: string;
}

export class DeelnemerRejoinedEventDto {
  @ApiProperty({ type: String })
  readonly type: 'DEELNEMER_REJOINED' = 'DEELNEMER_REJOINED' as const;
  @ApiProperty({ type: String })
  readonly id!: EventId;
  @ApiProperty({ type: String })
  readonly deelnemerId!: ProfielId;
  readonly timestamp!: string;
}

export class VideoGesprekEndedGesprekEventDto {
  @ApiProperty({ type: String })
  readonly type: 'VIDEOGESPREK_ENDED' = 'VIDEOGESPREK_ENDED' as const;
  readonly startTimestamp!: string;
  readonly endTimestamp!: string;
  readonly answered!: boolean;
}

export class DeelnemerDto {
  @ApiProperty({ type: String })
  readonly id!: ProfielId;
  readonly channel!: string;
}

export type GesprekStatus = 'ACTIEF' | 'GEARCHIVEERD' | 'GEBLOKKEERD';
export type GesprekType = 'ONE_ON_ONE' | 'GROEPSGESPREK' | 'DOEL';

export class GroepsgesprekInsertDto {
  readonly deelnemers!: Array<GesprekDeelnemerUpsertDto>;
  @ApiProperty({ type: String })
  readonly ownerId!: ProfielId;
  @ApiProperty({ type: String })
  readonly imageId?: FotoId;
  readonly type!: 'GROEPSGESPREK';
}

export class GesprekUpdateDto {
  @ApiProperty({ type: String })
  readonly imageId?: FotoId;
}

export class GesprekDeelnemerUpsertDto {
  @ApiProperty({ type: String })
  readonly externeId!: ProfielId;
}

export type BerichtDto =
  | TextBerichtDto
  | GedeeldeResourceBerichtDto
  | AttachmentBerichtDto
  | SpraakBerichtDto
  | EventBerichtDto
  | GemaskeerdBerichtDto;

export class GemaskeerdBerichtDto {
  @ApiProperty({ type: String })
  readonly id!: BerichtId;
  readonly type!: 'GEMASKEERD';
  @ApiProperty({ type: String })
  readonly deelnemerId!: ProfielId;
  readonly timestamp!: string;
}

export class TextBerichtDto {
  @ApiProperty({ type: String })
  readonly id!: BerichtId;
  readonly type!: 'TEKST';
  readonly inhoud!: string;
  @ApiProperty({ type: String })
  readonly deelnemerId!: ProfielId;
  readonly timestamp!: string;
  readonly reacties!: ReactiesDto;
  @ApiProperty({ type: String })
  readonly origineelBerichtId?: BerichtId;
  readonly origineelBericht?: TextBerichtDto;
  readonly antwoord!: boolean;
}

export class GedeeldeResourceBerichtDto {
  @ApiProperty({ type: String })
  readonly id!: BerichtId;
  readonly type!: 'GEDEELDE_RESOURCE';
  readonly resourceId!: string;
  @ApiProperty({ type: String })
  readonly resourceType!: GedeeldeResourceType;
  @ApiProperty({ type: String })
  readonly deelnemerId!: ProfielId;
  readonly timestamp!: string;
  readonly reacties!: ReactiesDto;
}

export class AttachmentBerichtDto {
  @ApiProperty({ type: String })
  readonly id!: BerichtId;
  readonly type!: 'ATTACHMENT';
  @ApiProperty({ type: String })
  readonly deelnemerId!: ProfielId;
  readonly timestamp!: string;
  readonly bestand!: BestandDto;
  readonly reacties!: ReactiesDto;
  @ApiProperty({ type: String })
  readonly origineelBerichtId?: BerichtId;
  readonly origineelBericht?: AttachmentBerichtDto;
  readonly antwoord!: boolean;
}

export class SpraakBerichtDto {
  @ApiProperty({ type: String })
  readonly id!: BerichtId;
  readonly type!: 'SPRAAK';
  @ApiProperty({ type: String })
  readonly deelnemerId!: ProfielId;
  readonly timestamp!: string;
  readonly bestand!: BestandDto;
  readonly reacties!: ReactiesDto;
  @ApiProperty({ type: String })
  readonly origineelBerichtId?: BerichtId;
  readonly origineelBericht?: SpraakBerichtDto;
  readonly antwoord!: boolean;
}

export class EventBerichtDto {
  @ApiProperty({ type: String })
  readonly id!: BerichtId;
  readonly type!: 'EVENT';
  readonly timestamp!: string;
  readonly gesprekEvent!: GesprekEvent;
  @ApiProperty({ type: String })
  readonly deelnemerId!: ProfielId;
}

export class PusherAuthDto {
  readonly auth!: string;
}

export class BestandDto {
  @ApiProperty({ type: String })
  readonly id!: BestandId;
  readonly filename!: string;
  @ApiProperty({ type: String })
  readonly fileId!: FileId;
  readonly size!: number;
  readonly hasPreview!: boolean;
}

export type CreateBerichtDto =
  | CreateTekstBerichtDto
  | CreateGedeeldeResourceBerichtDto
  | CreateBestandBericht;

class CreateTekstBerichtDto {
  readonly type = 'TEKST';
  readonly inhoud!: string;
  @ApiProperty({ type: String })
  readonly deelnemerId!: ProfielId;
  @ApiProperty({ type: String })
  readonly origineelBerichtId?: BerichtId;
}

class CreateGedeeldeResourceBerichtDto {
  readonly type = 'GEDEELDE_RESOURCE';
  readonly resourceId!: string;
  @ApiProperty({ type: String })
  readonly resourceType!: GedeeldeResourceType;
  @ApiProperty({ type: String })
  readonly deelnemerId!: ProfielId;
  @ApiProperty({ type: String })
  readonly origineelBerichtId?: BerichtId;
}

export class CreateBestandBericht {
  readonly type!: BestandType;
  @ApiProperty({ type: String })
  readonly deelnemerId!: ProfielId;
  readonly bestand!: File;
  @ApiProperty({ type: String })
  readonly origineelBerichtId?: BerichtId;
}

export class CreateReactieBerichtDto {
  @ApiProperty({ type: String })
  readonly type!: InteractieEmoji;
}

export type GedeeldeResourceType = 'ARTIKEL' | 'HULPLIJN';

export type BestandType = 'SPRAAK' | 'ATTACHMENT';

export class NotifierAuthDto {
  readonly socket_id!: string;
  readonly channel_name!: string;
}

export class BerichtenPage implements Page<BerichtDto> {
  @ApiProperty({
    type: 'array',
    items: {
      anyOf: [
        { $ref: getSchemaPath(TextBerichtDto) },
        { $ref: getSchemaPath(SpraakBerichtDto) },
        { $ref: getSchemaPath(AttachmentBerichtDto) },
        { $ref: getSchemaPath(SpraakBerichtDto) },
        { $ref: getSchemaPath(SpraakBerichtDto) },
        { $ref: getSchemaPath(EventBerichtDto) },
        { $ref: getSchemaPath(GemaskeerdBerichtDto) },
      ],
    },
  })
  readonly content!: BerichtDto[];
  readonly totalPages!: number;
  readonly number!: number;
  readonly last!: boolean;
}

export function validateGesprekId(id: string | null | undefined): GesprekId {
  if (!id) {
    throw new Error('id is undefined');
  }

  return id as GesprekId;
}

export function validateVideogesprekId(id: string | null | undefined): VideogesprekId {
  if (!id) {
    throw new Error('id is undefined');
  }

  return id as VideogesprekId;
}

export class GesprekDto {
  @ApiProperty({ type: String })
  readonly id!: GesprekId;
  readonly ownerId!: string;
  readonly avatar?: string;
  @ApiProperty({ type: String })
  readonly status!: GesprekStatus;
  @ApiProperty({ type: String })
  readonly type!: GesprekType;
  @ApiProperty({ type: String })
  readonly fotoId?: FotoId;
  @ApiProperty({
    anyOf: [
      { $ref: getSchemaPath(TextBerichtDto) },
      { $ref: getSchemaPath(GedeeldeResourceBerichtDto) },
      { $ref: getSchemaPath(AttachmentBerichtDto) },
      { $ref: getSchemaPath(SpraakBerichtDto) },
      { $ref: getSchemaPath(SpraakBerichtDto) },
      { $ref: getSchemaPath(EventBerichtDto) },
      { $ref: getSchemaPath(GemaskeerdBerichtDto) },
    ],
  })
  readonly laatsteBericht?: BerichtDto;
  readonly deelnemers!: DeelnemerDto[];
  readonly aangemaaktOp!: string;
  @ApiProperty({
    type: 'array',
    items: {
      anyOf: [
        { $ref: getSchemaPath(DeelnemerLeftEventDto) },
        { $ref: getSchemaPath(DeelnemerRejoinedEventDto) },
      ],
    },
  })
  readonly events!: GesprekEvent[]; //TODO: Backwards compatibility: 1.001.0041
  readonly channel?: string;
  @ApiProperty({ type: String })
  readonly videogesprekId?: VideogesprekId;
}

export class BerichtEvent {
  @ApiProperty({ type: String })
  readonly berichtId!: BerichtId;
  @ApiProperty({ type: String })
  readonly gesprekId!: GesprekId;
  readonly timestamp!: string;
}
