import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';
import { CoreModule } from '../../../core/core.module';
import {
  ParkourButtonComponent,
  ParkourImageComponent,
  ParkourModalService,
  ParkourPageGradientComponent,
} from '@parkour/ui';
import { BiometricsService } from '../../../authentication/service/biometrics.service';
import { Subscription, filter, switchMap, tap } from 'rxjs';
import AuthService from '../../../authentication/service/auth.service';

@Component({
  selector: 'parkour-biometrics-config-modal',
  standalone: true,
  imports: [
    IonTitle,
    IonHeader,
    IonToolbar,
    IonContent,
    IonItem,
    IonInput,
    CoreModule,
    ParkourButtonComponent,
    ParkourPageGradientComponent,
    ParkourImageComponent,
  ],
  templateUrl: './biometrics-config-modal.component.html',
})
export class BiometricsConfigModalComponent implements OnInit, OnDestroy {
  @Input({ required: true }) modalId!: string;
  protected success = false;
  protected closed = false;
  private userSubscription?: Subscription;

  constructor(
    private readonly biometricsService: BiometricsService,
    private readonly parkourModalService: ParkourModalService,
    private readonly authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.userSubscription = this.authService.user$
      .pipe(filter((user) => user.type !== 'aangemeld'))
      .subscribe(() => this.closeModal());
  }

  ngOnDestroy() {
    this.userSubscription?.unsubscribe();
  }

  onBiometricsActivateClick() {
    this.authService
      .getGeenProfielOrAangemeldeUser$()
      .pipe(switchMap((user) => this.biometricsService.enableBiometrics(user.persoonId)))
      .subscribe(() => (this.success = true));
  }

  onCancelClick() {
    this.authService
      .getGeenProfielOrAangemeldeUser$()
      .pipe(
        switchMap((user) => this.biometricsService.disableBiometrics(user.persoonId)),
        tap(() => this.closeModal()),
      )
      .subscribe();
  }

  closeModal(): void {
    if (this.parkourModalService.isModalOpen(this.modalId)) {
      this.parkourModalService.closeModal(this.modalId);
    }
  }

  onVolgendeStapClick() {
    this.parkourModalService.closeModal(this.modalId);
  }
}
