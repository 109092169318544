import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LoggingService {
  currentLog: string = '';

  public log(...value: unknown[]): void {
    if (environment.logging === 'debug') {
      // eslint-disable-next-line no-console
      console.log(...value);
      this.addToLog('log', value);
    }
  }

  public error(...value: unknown[]): void {
    // eslint-disable-next-line no-console
    console.error(...value);
    this.addToLog('error', value);
  }

  public warn(value: unknown[]): void {
    // eslint-disable-next-line no-console
    console.warn(...value);
    this.addToLog('warn', value);
  }

  private addToLog(type: string, ...value: unknown[]): void {
    try {
      this.currentLog += `[${new Date().toISOString()}] [${type}]: ${JSON.stringify(value)}\n`;
    } catch {
      this.currentLog += 'Could not log warning';
    }
  }

  public getFullLog() {
    return this.currentLog;
  }
}
