import { Component, Input } from '@angular/core';
import { UnsupportedMeldingView } from '../../model/meldingen';
import { TranslateModule } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { ParkourCardComponent } from '@parkour/ui';

@Component({
  selector: 'parkour-unsuppored-melding',
  templateUrl: './parkour-unsupported-melding.component.html',
  standalone: true,
  imports: [TranslateModule, DatePipe, ParkourCardComponent],
})
export class ParkourUnsupportedMeldingComponent {
  @Input({ required: true }) public meldingView!: UnsupportedMeldingView;
}
