import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { SpraakBerichtDto } from 'parkour-web-app-dto';
import { AudioPlayerComponent } from '../audio-player/audio-player.component';
import { BerichtenDatePipe } from '../../pipes/berichten-date.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { isPlatform } from '@ionic/core';
import { ProfielnaamPipe } from '../../../shared/pipes/profielnaam.pipe';
import { getIsTimeToday } from '../../../utils';
import { ParkourButtonComponent } from '@parkour/ui';

@Component({
  selector: 'parkour-bericht-speech-wrapper',
  standalone: true,
  imports: [
    AsyncPipe,
    NgIf,
    AudioPlayerComponent,
    BerichtenDatePipe,
    TranslateModule,
    NgClass,
    ProfielnaamPipe,
    ParkourButtonComponent,
  ],
  templateUrl: './bericht-speech-wrapper.component.html',
})
export class BerichtSpeechWrapperComponent {
  @Input({ required: true }) bericht!: SpraakBerichtDto;
  @Input() isUser: boolean = false;
  @Input() disabled: boolean = false;

  @Output() moreOptionsClick = new EventEmitter<void>();
  protected readonly getIsTimeToday = getIsTimeToday;

  isDesktop() {
    return isPlatform('desktop');
  }

  onMoreOptionsClick() {
    this.moreOptionsClick.emit();
  }
}
