import { Component, Input } from '@angular/core';
import { Doel } from '../../model/doel';
import { ParkourIconComponent } from '@parkour/ui';
import { NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { DoelProgressCircleComponent } from '../doel-progress-circle/doel-progress-circle.component';
import { DoelUpsert } from '../../model/doel-upsert';

@Component({
  standalone: true,
  selector: 'parkour-doel-progress',
  templateUrl: './doel-progres.component.html',
  imports: [ParkourIconComponent, NgIf, TranslateModule, DoelProgressCircleComponent],
})
export class DoelProgresComponent {
  @Input({ required: true }) doel!: Doel | DoelUpsert;

  getTotalAmountOfAction(): number {
    return this.doel.acties.length;
  }

  getAmountOfCompletedAction(): number {
    return this.doel.acties.filter((actie) => actie.voltooid).length;
  }

  isCompleted() {
    if (this.doel instanceof Doel) {
      return this.doel.isBehaald;
    }

    return false;
  }
}
