import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Profiel } from '../../model/profiel';
import { ProfielFotoService } from '../../service/profiel-foto.service';
import { getProfielnaamWithoutFallback } from 'parkour-web-app-dto';
import { ParkourActionComponent, ParkourAvatarComponent, ParkourIconComponent } from '@parkour/ui';
import { ProfielFotoPipe } from '../../../shared/pipes/profiel-foto.pipe';
import { AsyncPipe, NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ProfessioneelIndicatorComponent } from '../../../shared/components/professioneel-indicator/professioneel-indicator.component';

@Component({
  standalone: true,
  selector: 'parkour-profiel-select-option',
  templateUrl: './profiel-select-option.component.html',
  imports: [
    ParkourActionComponent,
    ProfielFotoPipe,
    AsyncPipe,
    ParkourAvatarComponent,
    TranslateModule,
    ProfessioneelIndicatorComponent,
    ParkourIconComponent,
    NgIf,
  ],
})
export class ProfielSelectOptionComponent {
  @Input({ required: true }) profiel!: Profiel;
  @Input() isSelected = false;

  @Output() selected = new EventEmitter<Profiel>();

  constructor(protected readonly profielFotoService: ProfielFotoService) {}

  protected readonly getProfielnaamWithoutFallback = getProfielnaamWithoutFallback;
}
