import { Component, Input } from "@angular/core";
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from "@angular/forms";
import {
  TParkourInputSlotPlacement,
  TParkourInputType,
} from "./parkour-input.types";
import { NgClass, NgIf, NgTemplateOutlet } from "@angular/common";
import { ParkourFocusVisibleModule } from "../parkour-focus-visible/parkour-focus-visible.module";

@Component({
  selector: "parkour-input",
  templateUrl: "./parkour-input.component.html",
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ParkourInputComponent,
    },
  ],
  imports: [
    NgClass,
    NgIf,
    NgTemplateOutlet,
    ReactiveFormsModule,
    ParkourFocusVisibleModule,
  ],
})
export class ParkourInputComponent implements ControlValueAccessor {
  @Input() public classes?: string;
  @Input({ required: true }) public inputId!: string;
  @Input() public testId?: string;
  @Input() public error?: string;
  @Input() public type: TParkourInputType = "text";
  @Input() public inputPlaceholder: string = "";
  @Input() public min: number | string = 0;
  @Input() public max: number | string = 100;
  @Input() public disabled = false;
  @Input() public required = false;
  @Input() public pattern = "";
  @Input() public slotPlacement: TParkourInputSlotPlacement = "right";
  @Input() public formControl: FormControl<string | undefined> | null = null;

  public isFocus!: boolean;
  public value: FormControl = new FormControl(null);
  public onChange?: (value: string | number) => void;
  public onTouched?: () => void;

  onInputChange(event: Event) {
    if (this.onChange) {
      this.onChange((event.target as HTMLInputElement).value);
    }
  }

  writeValue(value: string | number): void {
    this.value.setValue(value, { emitEvent: false });
  }

  registerOnChange(onChange: () => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }
}
