@if (gesprekFoto$ | async; as gesprekFoto) {
  @if (gesprekFoto.type === 'profiel-foto') {
    <parkour-profiel-foto-badge
      [alt]="false"
      [badgeCount]="
        _gesprek.isGroepsgesprek() ? _gesprek.getDeelnemersAndJongere().length : undefined
      "
      [grayout]="grayout"
      [profiel]="gesprekFoto.profiel"
      [size]="size"
    />
  } @else {
    <parkour-foto-badge
      [badgeCount]="
        _gesprek.isGroepsgesprek() ? _gesprek.getDeelnemersAndJongere().length : undefined
      "
      [grayout]="grayout"
      [image]="gesprekFoto"
      [size]="size"
    />
  }
} @else {
  <parkour-foto-badge-placeholder [size]="size" />
}
