import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ParkourIconComponent } from '@parkour/ui';
import { TranslateModule } from '@ngx-translate/core';

import { OrganisationMethodWithNowOpen } from '../../../shared/model/artikel';

@Component({
  selector: 'parkour-helpline-icon-list',
  standalone: true,
  imports: [ParkourIconComponent, TranslateModule],
  templateUrl: './helpline-icon-list.component.html',
})
export class HelplineIconListComponent implements OnChanges {
  @Input({ required: true }) methods!: OrganisationMethodWithNowOpen[];
  @Input() nowOpen: boolean = false;
  @Input() color: string = 'text-n-600';

  filteredMethods: OrganisationMethodWithNowOpen[] = [];

  ngOnChanges(changes: SimpleChanges) {
    if (changes['methods'] && changes['methods'].currentValue) {
      this.filteredMethods = this.methods.filter((method) => method.nowOpen === this.nowOpen);
    }
  }

  hasNowOpenMethods() {
    return this.methods.some((method) => method.nowOpen === this.nowOpen) && this.nowOpen;
  }
}
