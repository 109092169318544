import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AsyncPipe, NgIf } from '@angular/common';
import { ContextOption } from '../../../../profiel/model/context-option';
import { ContextService } from '../../../services/context.service';
import { ContextId, ContextOptionType } from 'parkour-web-app-dto';
import { TranslateModule } from '@ngx-translate/core';
import { MeldingenService } from '../../../../profiel/service/meldingen.service';
import { ProfielFotoBadgeComponent } from '../../profiel-foto-badge/profiel-foto-badge.component';
import {
  ParkourActionComponent,
  ParkourBadgeComponent,
  ParkourButtonComponent,
  ParkourIconComponent,
  ParkourNewIndicatorComponent,
} from '@parkour/ui';
import { RolLabelWithDefaultPipe } from '../../../pipes/rol-label-with-default.pipe';
import { meldingenForWisselTeamPage } from '../../../../meldingen/config';
import { combineLatest, map } from 'rxjs';
import { ParkourPopoverDirective } from '../../../directives/parkour-popover.directive';
import { IonPopover } from '@ionic/angular/standalone';

@Component({
  selector: 'parkour-sidebar-wissel-team-popover',
  standalone: true,
  imports: [
    AsyncPipe,
    NgIf,
    TranslateModule,
    ProfielFotoBadgeComponent,
    RolLabelWithDefaultPipe,
    ParkourPopoverDirective,
    ParkourActionComponent,
    ParkourIconComponent,
    ParkourBadgeComponent,
    ParkourNewIndicatorComponent,
    ParkourButtonComponent,
    IonPopover,
  ],
  templateUrl: './sidebar-wissel-team-popover.component.html',
})
export class SidebarWisselTeamPopoverComponent {
  @ViewChild('popover') popover!: ParkourPopoverDirective;

  @Input({ required: true }) mijnContexten!: ContextOption[];
  @Input({ required: true }) triggerId!: string;

  @Output() hasPresented = new EventEmitter<void>();
  @Output() hasDismissed = new EventEmitter<void>();
  wisselTeamIndicator$ = this.meldingenService.hasWisselTeamMeldingen();
  hasMeldingenOnWisselTeamPage$ = this.meldingenService.hasMeldingenInCurrentContextByType(
    meldingenForWisselTeamPage.specificMeldingen,
  );

  constructor(
    private readonly contextService: ContextService,
    private readonly meldingenService: MeldingenService,
  ) {}

  onWisselTeam(context: ContextOption) {
    this.popover.close().then(() => {
      if (context.geblokkeerd) {
        this.contextService.navigateToAbsoluteUrl([
          'profiel',
          'team',
          'geblokkeerd',
          context.contextId,
        ]);
      } else {
        const url = context.type === 'voor-mezelf' ? ['profiel'] : ['profiel', 'team'];
        this.contextService.switchContext(context.contextId, url);
      }
    });
  }

  onPopoverClose() {
    this.popover.close();
  }

  hasMeldingenInContext(contextId: ContextId) {
    return combineLatest([
      this.meldingenService.hasMeldingenInContextByType(contextId),
      this.hasMelingenOnBlockedPage(contextId),
    ]).pipe(
      map(
        ([meldingenInContext, hasMelingenOnBlockedPage]) =>
          meldingenInContext || hasMelingenOnBlockedPage,
      ),
    );
  }

  hasMelingenOnBlockedPage(contextId: ContextId) {
    return this.meldingenService
      .getMeldingenInCurrentContextByType(
        meldingenForWisselTeamPage.GEBLOKKEERD_TEAM_PAGE.allMeldingen,
      )
      .pipe(
        map(
          (meldingen) =>
            meldingen.filter((melding) => melding.metaData.jongereProfielId === contextId).length >
            0,
        ),
      );
  }

  filterContexten(mijnContexten: ContextOption[], type: ContextOptionType) {
    return mijnContexten.filter((context) => context.type === type);
  }

  willPresent() {
    this.hasPresented.emit();
  }

  willDismiss() {
    this.hasDismissed.emit();
  }
}
