<parkour-form
  [errorLabelMapping]="{
    doelDataSection: {
      naam: 'doelen.doel-toevoegen-page.form.naam' | translate,
      thema: 'doelen.doel-toevoegen-page.form.thema' | translate,
      omschrijving: 'doelen.doel-suggestie-page.bericht' | translate,
      streefDatum: 'doelen.doel-toevoegen-page.form.streefdatum' | translate,
    },
    actieSection: {
      acties: false,
    },
    gedeeldMetSection: {
      gedeeldMet: false,
      deelMode: false,
    },
  }"
  [formGroup]="doelForm"
  [formId]="formId"
  [submitObservableGenerator]="submitDoel"
>
  <div class="flex flex-col gap-y-8">
    <parkour-doel-data-section [doelFormGroup]="doelForm.controls.doelDataSection" />

    <div class="flex flex-col gap-y-4">
      <h2 class="typo-h3">{{ 'doelen.doel-toevoegen-page.acties.title' | translate }}</h2>

      <p>{{ 'doelen.doel-toevoegen-page.acties.beschrijving' | translate }}</p>

      <parkour-acties-select-section [actieSectionGroup]="doelForm.controls.actieSection" />
    </div>

    <div class="flex flex-col gap-y-6">
      <div class="space-y-2">
        <h2 class="typo-h3">{{ 'doelen.doel-toevoegen-page.delen.title' | translate }}</h2>

        <p>{{ 'doelen.doel-toevoegen-page.delen.description' | translate }}</p>
      </div>

      <parkour-delen-section
        id="doel-delen-section"
        [delenFormGroup]="doelForm.controls.gedeeldMetSection"
        [teamleden]="teamleden"
      />
    </div>

    <parkour-button class="block w-full" iconAfter="disk" type="submit">
      {{ 'doelen.doel-edit-page.doel-opslaan' | translate }}
    </parkour-button>
  </div>
</parkour-form>
