import { Component } from '@angular/core';
import { map } from 'rxjs';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';
import { GebeurtenisDetailsJongereBasePage } from '../../verhaal-shared/page/gebeurtenis-details-jongere-base-page/gebeurtenis-details-jongere-base.page';
import {
  MarksMeldingenAsReadOnView,
  MeldingenToMarkAsRead,
} from '../../../shared/marks-meldingen-as-read';
import { meldingenForPage } from '../../../meldingen/config';
import { ParkourBackButtonDirective } from '../../../shared/directives/parkour-back-button.directive';
import { AsyncPipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ParkourHeaderActionComponent } from '../../../shared/components/parkour-header-action/parkour-header-action.component';
import { ParkourRefreshDirective } from '../../../shared/directives/parkour-refresh-directive';
import { PageLayoutComponent } from '../../../shared/components/page-layout/page-layout.component';
import { PageContentDirective } from '../../../shared/directives/page-content.directive';
import { JeugdhulpHistoriekDetailsComponent } from '../../verhaal-shared/component/jeugdhulp-historiek-details-component/jeugdhulp-historiek-details-component.component';
import { ParkourGebeurtenisDetailsComponent } from '../../verhaal-shared/component/parkour-gebeurtenis-details-component/parkour-gebeurtenis-details-component.component';
import { ShowIfSuccesfulDirective } from '../../../shared/directives/show-if-succesful.directive';

@Component({
  standalone: true,
  templateUrl: './gebeurtenis-details-jongere.page.html',
  imports: [
    IonHeader,
    IonToolbar,
    IonButtons,
    IonTitle,
    IonBackButton,
    IonContent,
    IonRefresher,
    IonRefresherContent,
    ParkourBackButtonDirective,
    AsyncPipe,
    TranslateModule,
    ParkourHeaderActionComponent,
    ParkourRefreshDirective,
    PageLayoutComponent,
    PageContentDirective,
    JeugdhulpHistoriekDetailsComponent,
    ParkourGebeurtenisDetailsComponent,
    ShowIfSuccesfulDirective,
  ],
})
export class GebeurtenisDetailsJongerePage
  extends GebeurtenisDetailsJongereBasePage
  implements MarksMeldingenAsReadOnView
{
  getMeldingenToMarkAsRead() {
    return this.mainPageData$.pipe(
      map(
        (mainPageData) =>
          new MeldingenToMarkAsRead(
            {
              ...meldingenForPage.VERHAAL_TAB.VERHAAL_PAGE.PARKOUR_GEBEURTENIS_PAGE
                .specificMeldingen,
              ...meldingenForPage.VERHAAL_TAB.VERHAAL_PAGE.EYOUTH_GEBEURTENIS_PAGE
                .specificMeldingen,
            },
            (melding) =>
              mainPageData.success && melding.metaData.gebeurtenisId === mainPageData.value.id,
          ),
      ),
    );
  }
}
