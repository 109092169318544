import { Component, Input } from "@angular/core";

@Component({
  selector: "parkour-page-gradient",
  templateUrl: "./parkour-page-gradient.component.html",
  standalone: true,
})
export class ParkourPageGradientComponent {
  @Input() public classes?: string;
}
