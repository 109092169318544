<div
  [ngClass]="{
    'border-n-600': isFocus,
  }"
  class="flex gap-x-2 items-end p-2 rounded bg-n-100 border-[1px] border-n-300 typo-body transition-colors"
>
  <div class="relative flex flex-1 items-center">
    <div
      #contentEditable
      (blur)="isFocus = false"
      (focus)="isFocus = true"
      (input)="onInput()"
      (keydown)="onkeydown($event)"
      aria-label="Tekst input nieuw bericht"
      class="flex flex-col-reverse grow whitespace-pre-wrap overflow-y-auto overflow-x-hidden min-h-[1.2rem] max-h-[7.75rem] outline-none overflow-hidden text-n-1000"
      contenteditable="true"
      parkourFocusVisibleElement
      role="textbox"
      spellcheck="true"
      style="word-break: break-word"
      tabindex="0"
    >
      <p class="typo-body !text-2"><br /></p>
    </div>

    <span *ngIf="!value" class="absolute truncate w-full pointer-events-none text-n-1000">{{
      placeholder
    }}</span>
  </div>

  <parkour-button
    (clicked)="openAssets($event)"
    [ariaLabel]="'buttons.add-attachement' | translate"
    class="flex"
    format="iconButton"
    icon="attachment"
    size="none"
    variant="ghost"
  />
</div>
