import { GesprekId, GesprekStatus } from 'parkour-web-app-dto';

export abstract class AbstractGesprek {
  constructor(
    public readonly id: GesprekId,
    public readonly aangemaaktOp: string,
    public readonly status: GesprekStatus,
  ) {}

  public abstract isGroepsgesprek(): boolean;
}
