<parkour-checkbox
  (checkboxChange)="handleCheckboxChange($event)"
  *ngIf="editable; else staticCheckbox"
  [checked]="actie.voltooid"
  class="flex"
>
  {{ actie.naam }}
</parkour-checkbox>

<ng-template #staticCheckbox>
  <div class="flex">
    <div class="w-6 mr-2">
      <parkour-icon *ngIf="actie.voltooid" class="flex items-center text-positive" name="check" />
    </div>
    <p>{{ actie.naam }}</p>
  </div>
</ng-template>
