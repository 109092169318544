<parkour-modal [stayOpenOnNavigation]="true" [footerTransparent]="true">
  <div class="flex items-start gap-2" content>
    <parkour-icon class="text-n-600" name="cookies" size="3" />

    <div>
      <p class="typo-body variant-body-fat">PARKOUR gebruikt cookies</p>

      <p class="mt-2">
        Sommige cookies zijn noodzakelijk om PARKOUR goed te laten werken. Marketing cookies zijn
        optioneel en dienen om video&rsquo;s van derde partijen te kunnen afspelen binnen PARKOUR.
        Cookies zijn kleine tekstbestanden die wij, als Opgroeien, en die derde partijen op je
        apparaat kunnen plaatsen. Lees hierover meer in ons
        <a
          [routerLink]="cookiesUrl$ | async"
          (click)="parkourModal.close()"
          class="typo-body variant-body-fat underline"
        >
          cookiebeleid
        </a>
        .
      </p>

      <ion-accordion-group class="mt-3 divide-y-[1.5px]" value="mandatory-cookies">
        <ion-accordion
          class="cookies-modal-accordion"
          toggleIconSlot="start"
          value="mandatory-cookies"
        >
          <button
            class="flex items-center py-3 cursor-pointer w-full hover:bg-n-300 focus-visible:bg-n-300 transition-colors"
            slot="header"
          >
            <parkour-icon class="ion-accordion-toggle-icon mr-2" name="chevron-down" />

            <p class="flex-1 typo-body variant-body-fat text-left">Noodzakelijke cookies</p>

            <parkour-toggle
              (click)="$event.stopPropagation()"
              [ariaLabel]="'Noodzakelijke cookies'"
              [checked]="true"
              [disabled]="true"
              class="ml-2 flex"
            />
          </button>

          <p class="pb-3" slot="content">
            Noodzakelijke cookies helpen onze website bruikbaarder te maken, door basisfuncties als
            paginanavigatie en toegang tot beveiligde gedeelten van de website mogelijk te maken.
            Zonder deze cookies kan de website niet naar behoren werken.
          </p>
        </ion-accordion>

        <ion-accordion
          class="cookies-modal-accordion border-t border-n-300"
          toggleIconSlot="start"
          value="marketing-cookies"
        >
          <button
            class="flex items-center py-3 w-full hover:bg-n-300 focus-visible:bg-n-300 transition-colors"
            slot="header"
          >
            <parkour-icon class="ion-accordion-toggle-icon mr-2" name="chevron-down" />

            <p class="flex-1 typo-body variant-body-fat text-left">Marketing cookies</p>

            <parkour-toggle
              (click)="$event.stopPropagation()"
              [(ngModel)]="marketingCookiesEnabled"
              ariaLabel="Marketing cookies"
              class="ml-2 flex"
            />
          </button>

          <p class="pb-3" slot="content">
            Marketing cookies zijn optioneel en dienen om video&rsquo;s van derde partijen te kunnen
            afspelen binnen PARKOUR. Onze website kan ingebedde elementen van deze derde partijen
            bevatten. Ze kunnen activiteiten op verschillende websites volgen om een gebruiksprofiel
            samen te stellen. Zo kunnen ze relevantere inhoud aanbieden op andere bezochte websites.
          </p>
        </ion-accordion>

        <ion-accordion
          class="cookies-modal-accordion border-t border-n-300"
          toggleIconSlot="start"
          value="analytic-cookies"
        >
          <button
            class="flex items-center py-3 w-full hover:bg-n-300 focus-visible:bg-n-300 transition-colors"
            slot="header"
          >
            <parkour-icon class="ion-accordion-toggle-icon mr-2" name="chevron-down" />

            <p class="flex-1 typo-body variant-body-fat text-left">Analytische cookies</p>

            <parkour-toggle
              (click)="$event.stopPropagation()"
              [(ngModel)]="analyticsCookiesEnabled"
              ariaLabel="Analytische cookies"
              class="ml-2 flex"
            />
          </button>

          <p class="pb-3" slot="content">
            Analytische tracking met Matomo verzamelt en rapporteert anoniem surfgedrag zonder
            cookies. Dit helpt ons om het gebruik van PARKOUR beter te begrijpen. Je kan dit
            uitschakelen (opt-out) als je niet wil dat we kunnen leren van je surfgedrag om de
            website te verbeteren.
          </p>
        </ion-accordion>
      </ion-accordion-group>
    </div>
  </div>

  <div footer>
    <parkour-button (clicked)="onBevestigenClick()" class="block w-full">
      Bevestigen
    </parkour-button>
  </div>
</parkour-modal>
