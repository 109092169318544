import { Directive, OnDestroy, OnInit } from '@angular/core';
import { IonPopover } from '@ionic/angular/standalone';
import { NavigationEnd, Router } from '@angular/router';
import { filter, Subscription } from 'rxjs';

@Directive({
  selector: '[parkourPopover]',
  exportAs: 'parkourPopover',
  standalone: true,
})
export class ParkourPopoverDirective implements OnInit, OnDestroy {
  get isOpen(): boolean {
    return this.ionPopover.isCmpOpen;
  }

  constructor(
    private readonly ionPopover: IonPopover,
    private readonly router: Router,
  ) {}

  private subscription?: Subscription;
  private dismissSubscription?: Subscription;

  ngOnInit(): void {
    this.subscription = this.router.events
      .pipe(filter((e) => e instanceof NavigationEnd))
      .subscribe(() => this.close());
  }

  public open(event?: MouseEvent) {
    this.ionPopover.present(event);
  }

  public async close() {
    await this.ionPopover.dismiss();
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.dismissSubscription?.unsubscribe();
  }
}
