<ion-header parkourAdaptToSidebarVisible>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button parkourBackButton />
    </ion-buttons>

    <ion-title>{{ 'over-parkour.toegankelijkheids-verklaring-page.title' | translate }} </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-refresher [parkourRefresh]="onPageRefresh" slot="fixed">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>
  <parkour-page-layout>
    <parkour-page-gradient />

    <div class="relative z-10 py-8 container container-space">
      <article class="container-1-col">
        <h1 class="text-center mb-4">
          {{ 'over-parkour.toegankelijkheids-verklaring-page.title' | translate }}
        </h1>

        <parkour-divider></parkour-divider>

        <div class="wysiwyg mt-8">
          <p>
            Deze toegankelijkheidsverklaring is van toepassing op de website
            <strong>
              <a href="https://parkour.opgroeien.be/"> https://parkour.opgroeien.be </a>
              en mobiele iOS- en Android toepassingen PARKOUR</strong
            >
            van agentschap Opgroeien. De
            <strong
              >Vlaamse overheid streeft ernaar haar websites en -toepassingen toegankelijk te
              maken</strong
            >, overeenkomstig het
            <a
              href="http://www.ejustice.just.fgov.be/cgi_loi/loi_a1.pl?language=nl&cn=2018120705&table_name=wet&caller=list&fromtab=wet#LNK0011"
              >bestuursdecreet van 7 december 2018
            </a>
            waarmee de
            <a
              href="https://eur-lex.europa.eu/legal-content/NL/TXT/?uri=uriserv:OJ.L_.2016.327.01.0001.01.NLD&toc=OJ:L:2016:327:TOC"
              >Europese Richtlijn 2016/2102
            </a>
            is omgezet. Opgroeien levert inspanningen om de toegankelijkheid van deze webapplicatie
            en mobiele toepassingen voor alle gebruikers voortdurend te verbeteren. Deze
            toegankelijkheidsverklaring en onderstaande nalevingsstatus is
            <strong>voor het laatst herzien op 23 oktober 2024</strong> en gebaseerd op een audit
            door een toegankelijkheidsexpert van een derde partij. De audit omvat een automatische
            controle en handmatige controle op een <strong>steekproef</strong> van de meeste
            representatieve gebruiksscenarioʼs voor de website en mobiele toepassingen waarbij alle
            WCAG 2.1 AA-toegankelijkheidscriteria worden geëvalueerd.
          </p>
          <h2>Nalevingsstatus</h2>
          <p>
            Deze website voldoet <strong>gedeeltelijk</strong> aan de
            <a href="https://www.w3.org/TR/WCAG21"
              >Web Content Accessibility Guidelines versie 2.1 niveau AA
            </a>
            omdat nog niet aan de onderstaande criteria is voldaan.
          </p>
          <h2>Niet-toegankelijke inhoud</h2>
          <p>
            PARKOUR wordt nog verder getest op toegankelijkheid. Deze criteria worden dan ook verder
            aangevuld en bijgewerkt naarmate de testen vorderen. De onderstaande inhoud is nog niet
            toegankelijk om de volgende redenen:
          </p>
          <h3>Vrijstellingen</h3>
          <ul>
            <li>
              Spraakberichten ingesproken door gebruikers hebben geen
              <strong>audiobeschrijving of tekstversie.</strong>
            </li>
            <li>
              Videoʼs van externe bron (<a href="https://watwat.be" target="_blank">watwat.be</a>)
              hebben geen
              <strong>ondertitels voor doven en slechthorenden.</strong>
            </li>
            <li>
              Videoʼs van externe bron (<a href="https://watwat.be" target="_blank">watwat.be</a>)
              hebben geen
              <strong>audiobeschrijving of media-alternatief.</strong>
            </li>
            <li>
              Live (beeld)bellen heeft geen
              <strong>ondertitels voor doven en slechthorenden.</strong>
            </li>
          </ul>
          <h3>Verbeteringen</h3>
          <p>
            PARKOUR wordt regelmatig bijgewerkt en problemen worden aangepakt in volgende updates
            van de website en mobiele toepassingen.
          </p>
          <h2>Contact</h2>
          <p>
            Ondervind je <strong>problemen</strong> met het gebruiken van deze website en wens je
            hulp bij het vinden van informatie of het uitvoeren van een actie? Heb je een
            <strong>vraag</strong> of <strong>opmerking</strong> over de toegankelijkheid van
            PARKOUR of over deze toegankelijkheidsverklaring?
            <a href="mailto:webmaster@opgroeien.be?subject=Melding%20via%20opgroeien.be"
              >Contacteer ons
            </a>
            met je opmerkingen of vragen.
          </p>
          <h2>Klacht</h2>
          <p>
            Heb je contact opgenomen maar ben je niet tevreden met het antwoord? Stuur dan je klacht
            naar de
            <a href="mailto:klachten.digitaal@vlaanderen.be"
              >klachtenbehandelaar van Digitaal Vlaanderen
            </a>
            . Heb je contact opgenomen met de klachtenbehandelaar van Digitaal Vlaanderen, maar ben
            je niet tevreden met het antwoord? Stuur dan je klacht naar de
            <strong>
              <a href="mailto:klachten@vlaamseombudsdienst.be">Vlaamse Ombudsdienst </a> </strong
            >.
          </p>
        </div>

        <span class="block mt-3 typo-body variant-body-small text-n-600">
          Laatst bijgewerkt op 23 oktober 2024.
        </span>
      </article>
    </div>
  </parkour-page-layout>
</ion-content>
