import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ParkourButtonComponent, ParkourFormFieldComponent } from '@parkour/ui';
import { TranslateModule } from '@ngx-translate/core';
import { GebeurtenisAttachmentUploadComponent } from '../gebeurtenis-attachment-upload/gebeurtenis-attachment-upload.component';
import { FileData, FileId } from 'parkour-web-app-dto';
import {
  BehaviorSubject,
  catchError,
  filter,
  firstValueFrom,
  map,
  Observable,
  of,
  startWith,
  Subject,
  takeUntil,
} from 'rxjs';
import {
  AsyncValidator,
  ControlValueAccessor,
  NG_ASYNC_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
} from '@angular/forms';
import { asType } from 'src/app/utils';
import { FileService } from '../../../../shared/services/file.service';
import { Directory } from '@capacitor/filesystem';
import {
  AnalyticsEvent,
  CategoriesContainingBijlageToegevoegd,
} from '../../../../analytics/analytics-event.model';
import { AnalyticsService } from '../../../../analytics/analytics.service';

type NewlyUploadedFileData = {
  readonly type: 'newly-uploaded';
  readonly naam: string;
  readonly id: FileId;
  readonly file: File;
  readonly tempId: string;
};

type UploadedFileData = {
  readonly type: 'uploaded';
  readonly naam: string;
  readonly id: FileId;
};

type UploadingFileData = {
  readonly type: 'uploading';
  readonly naam: string;
  readonly tempId: string;
  readonly file: File;
};

type ErroredFileData = {
  readonly type: 'error';
  readonly naam: string;
  readonly tempId: string;
  readonly file: File;
  readonly error: Error;
};

export type AttachmentFileData =
  | UploadedFileData
  | UploadingFileData
  | ErroredFileData
  | NewlyUploadedFileData;

@Component({
  standalone: true,
  selector: 'parkour-attachments-upload',
  templateUrl: './attachments-upload.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: AttachmentsUploadComponent,
    },
    {
      provide: NG_ASYNC_VALIDATORS,
      multi: true,
      useExisting: AttachmentsUploadComponent,
    },
  ],
  imports: [
    ParkourFormFieldComponent,
    ParkourButtonComponent,
    TranslateModule,
    GebeurtenisAttachmentUploadComponent,
  ],
})
export class AttachmentsUploadComponent implements ControlValueAccessor, AsyncValidator {
  @ViewChild('fileInput') fileInput!: ElementRef;
  currentFiles: AttachmentFileData[] = [];
  currentFiles$ = new BehaviorSubject<AttachmentFileData[]>(this.currentFiles);

  @Output() existingFileDownload = new EventEmitter<FileData>();
  @Output() uploadingStateChanged = new EventEmitter<boolean>();

  @Input({ required: true }) type!: CategoriesContainingBijlageToegevoegd;

  private readonly uploadCancelled$ = new Subject<File>();

  private onChange?: (filesData: FileData[]) => void;
  private onTouched?: () => void;

  constructor(
    private readonly fileService: FileService,
    private readonly analyticsService: AnalyticsService,
  ) {}

  writeValue(filesData: FileData[]): void {
    this.currentFiles = filesData.map((file) => ({
      type: 'uploaded',
      id: file.id,
      naam: file.naam,
    }));

    this.currentFiles$.next(this.currentFiles);
  }

  registerOnChange(onChange: (filesData: FileData[]) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  async validate(): Promise<ValidationErrors | null> {
    const states = await firstValueFrom(
      this.currentFiles$.pipe(filter((files) => !files.some((file) => file.type === 'uploading'))),
    );

    if (states.some((file) => file.type === 'error')) {
      return { fileUploadFailed: true };
    } else {
      return null;
    }
  }

  openFileInput() {
    this.fileInput.nativeElement.click();
  }

  onFileSelected(event: Event) {
    const target = event.target as HTMLInputElement;

    if (target.files) {
      for (const file of Array.from(target.files)) {
        this.uploadFile(file)
          .pipe(
            takeUntil(
              this.uploadCancelled$.pipe(filter((cancelledFile) => cancelledFile === file)),
            ),
          )
          .subscribe((attachmentFileData) => {
            this.updateFiles(attachmentFileData);
            this.onTouched?.();
            if (attachmentFileData.type === 'newly-uploaded') {
              this.analyticsService.trackEvent(new AnalyticsEvent(this.type, 'bijlageToegevoegd'));
            }
          });
      }
    }
  }

  private updateFiles(fileData: AttachmentFileData) {
    const tempCurrentFiles = this.removeExistingFile(fileData);

    if (fileData.type !== 'error') {
      this.currentFiles = [...tempCurrentFiles, fileData];
    } else {
      this.currentFiles = [...tempCurrentFiles];
    }

    this.currentFiles$.next(this.currentFiles);
    this.onChange?.(this.getUploadedFiles());

    if (fileData.type === 'error') {
      throw fileData.error;
    }
  }

  private removeExistingFile(fileData: AttachmentFileData): AttachmentFileData[] {
    return this.currentFiles.filter((file) => !this.isSameFile(file, fileData));
  }

  private getUploadedFiles(): FileData[] {
    return this.currentFiles
      .filter(
        (file): file is UploadedFileData =>
          file.type === 'uploaded' || file.type === 'newly-uploaded',
      )
      .map((file) =>
        asType<FileData>({
          id: file.id,
          naam: file.naam,
        }),
      );
  }

  private isSameFile(file1: AttachmentFileData, file2: AttachmentFileData): boolean {
    return (
      ('tempId' in file1 && 'tempId' in file2 && file1.tempId === file2.tempId) ||
      ('id' in file1 && 'id' in file2 && file1.id === file2.id)
    );
  }

  removeFile(fileData: AttachmentFileData) {
    this.currentFiles = this.removeExistingFile(fileData);
    this.currentFiles$.next(this.currentFiles);
    this.onChange?.(this.getUploadedFiles());

    if ('file' in fileData) {
      this.uploadCancelled$.next(fileData.file);
    }
  }

  uploadFile(file: File): Observable<AttachmentFileData> {
    const tempId = 'file_' + Math.random();
    return this.fileService.uploadFile(file).pipe(
      map((result) => {
        if (result.status === 'done') {
          return asType<NewlyUploadedFileData>({
            type: 'newly-uploaded',
            naam: file.name,
            id: result.fileId,
            file,
            tempId,
          });
        } else {
          return asType<UploadingFileData>({
            type: 'uploading',
            naam: file.name,
            file,
            tempId,
          });
        }
      }),
      startWith(
        asType<UploadingFileData>({
          type: 'uploading',
          naam: file.name,
          file,
          tempId,
        }),
      ),
      catchError((e) =>
        of(
          asType<ErroredFileData>({
            type: 'error',
            naam: file.name,
            file,
            tempId,
            error: e,
          }),
        ),
      ),
    );
  }

  onDownload(file: AttachmentFileData) {
    if (file.type === 'uploaded') {
      this.existingFileDownload.emit({ id: file.id, naam: file.naam });
    } else {
      this.fileService.downloadB64LocalFile(file.file, file.naam, Directory.Cache); // Is cache correct? I reused what  was there before;
    }
  }
}
