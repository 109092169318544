<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button parkourBackButton />
    </ion-buttons>

    <ion-title aria-level="1" role="heading"
      >{{ 'doelen.behaalde-doelen-edit-page.title' | translate }}
    </ion-title>

    <ion-buttons slot="end">
      @if (delenForm) {
        <parkour-header-action
          [label]="'doelen.doel-edit-page.doel-opslaan' | translate"
          form="doel-delen-form"
          icon="disk"
          type="submit"
        />
      }
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <parkour-page-layout>
    <ng-container *parkourPageContent="data$ | async; refresh: 'none'; let data">
      <parkour-page-image
        [src]="themaMapping[data.doel.thema].largeImage.blur"
        classes="absolute top-0 left-0 z-0"
      />

      <div class="relative z-10 pt-4 container container-space flex flex-col gap-y-8">
        <div class="flex flex-col gap-y-8">
          <parkour-doel-details-hero [doel]="data.doel" />

          <parkour-info-card class="container-1-col">
            <p>{{ 'doelen.behaalde-doelen-edit-page.info' | translate }}</p>
          </parkour-info-card>

          @if (delenForm) {
            <form
              id="doel-delen-form"
              [formGroup]="delenForm"
              (ngSubmit)="onFormSubmit(data.doel, data.teamleden)"
            >
              <parkour-doel-edit-delen-section
                [teamleden]="data.teamleden"
                [gedeeldMetSection]="delenForm"
              />
            </form>
          }

          <div class="full-bleed container-space bg-n-200 pt-6 pb-8 mt-8">
            <div class="container-1-col">
              <h3 class="mb-2">{{ 'doelen.behaalde-doelen-edit-page.beindigen' | translate }}</h3>

              <p>{{ 'doelen.behaalde-doelen-edit-page.beindigen-confirmatie' | translate }}</p>

              <parkour-button
                (clicked)="isVerwijderBehaaldDoelPopupOpen = true"
                class="block w-full mt-4"
                iconAfter="trash"
                variant="tertiary"
              >
                {{ 'doelen.doel-edit-page.verwijderen' | translate }}
              </parkour-button>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </parkour-page-layout>
</ion-content>

<parkour-popup
  (hasBeenDismissed)="isVerwijderBehaaldDoelPopupOpen = false"
  [isOpen]="isVerwijderBehaaldDoelPopupOpen"
  icon="trash"
>
  <div content>
    <p class="typo-body variant-body-fat">{{ 'doelen.doel-edit-page.verwijderen' | translate }}</p>

    <p>{{ 'doelen.doel-edit-page.verwijderen-popup-description' | translate }}</p>
  </div>

  <div class="flex items-center gap-x-4" footer>
    <parkour-button
      (clicked)="isVerwijderBehaaldDoelPopupOpen = false"
      class="w-full"
      variant="tertiary"
    >
      {{ 'buttons.nee' | translate }}
    </parkour-button>

    <parkour-button (clicked)="verwijderDoel()" class="w-full">
      {{ 'buttons.ja' | translate }}
    </parkour-button>
  </div>
</parkour-popup>
