<!--eslint-disable @angular-eslint/template/click-events-have-key-events-->
<!--eslint-disable @angular-eslint/template/interactive-supports-focus-->
<div
  (click)="onClose()"
  [ngClass]="{
    'opacity-1 pointer-events-auto cursor-pointer': isOpen,
    'opacity-0 pointer-events-none': !isOpen,
  }"
  class="global-header-margin fixed inset-0 z-50 bg-n-100/80 transition-opacity duration-150 ease-[cubic-bezier(0.4,0,0.2,1)] backdrop-blur-[2px]"
></div>

<div
  [cdkTrapFocusAutoCapture]="true"
  [cdkTrapFocus]="isOpen"
  [id]="id"
  [ngClass]="{
    'translate-x-0 opacity-1': isOpen,
    'translate-x-[100%] opacity-0': !isOpen,
  }"
  aria-modal="true"
  class="global-header-margin fixed z-50 inset-y-0 right-0 flex flex-col h-[calc(100%-var(--app-plus-global-header-height))] w-full lg:w-[50%] bg-n-100 transition-all duration-500 ease-[cubic-bezier(0.4,0,0.2,1)] border-l-[1px] border-solid border-n-200"
  role="dialog"
  aria-label="Sheet"
>
  <div
    class="relative z-10 bg-n-200 p-3 flex justify-between items-center shadow-2 pt-[calc(var(--ion-safe-area-top)+1rem)]"
  >
    <div class="flex items-center gap-x-2">
      <span class="typo-h3">{{ title }}</span>

      <parkour-icon [name]="icon" class="flex" size="3" />
    </div>

    <parkour-button
      (clicked)="onClose()"
      [ariaLabel]="'buttons.sluiten' | translate"
      class="flex"
      format="iconButton"
      icon="cross"
      variant="ghost"
    />
  </div>

  <div class="flex-1 pb-[var(--ion-safe-area-bottom)]">
    <ng-template [cdkPortalOutlet]="selectedPortal"></ng-template>
  </div>
</div>
