import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Doel } from '../../model/doel';
import { themaMapping } from '../../../../home/model/thema.const';
import {
  ParkourActionComponent,
  ParkourImageComponent,
  ParkourNewIndicatorComponent,
} from '@parkour/ui';
import { DatePipe } from '@angular/common';
import { DoelProgresComponent } from '../doel-progress/doel-progres.component';
import { GedeeldMetIndicatorComponent } from '../../../../delen/component/gedeeld-met-indicator/gedeeld-met-indicator.component';

@Component({
  standalone: true,
  selector: 'parkour-doel-list-item',
  templateUrl: './doel-list-item.component.html',
  styleUrls: ['./doel-list-item.component.css'],
  encapsulation: ViewEncapsulation.None,
  imports: [
    ParkourActionComponent,
    DatePipe,
    ParkourImageComponent,
    DoelProgresComponent,
    ParkourNewIndicatorComponent,
    GedeeldMetIndicatorComponent,
  ],
})
export class DoelListItemComponent {
  @Input({ required: true }) doel!: Doel;
  @Input() loading = false;
  @Input() showNewIndicator = false;
  protected readonly themaMapping = themaMapping;
}
