import { Component, EventEmitter, Input, Output } from '@angular/core';
import { InteractieEmoji, interactieEmojis } from 'parkour-web-app-dto';
import { Haptics, ImpactStyle } from '@capacitor/haptics';
import { isNativeApp } from '../../../utils';
import { ParkourActionComponent } from '@parkour/ui';
import { ParkourEmojiPipe } from '../../pipes/parkourEmoji.pipe';

@Component({
  standalone: true,
  selector: 'parkour-add-emoji',
  templateUrl: './add-emoji.component.html',
  imports: [ParkourActionComponent, ParkourEmojiPipe],
})
export class AddEmojiComponent {
  @Input() activeEmojiKey?: InteractieEmoji | null = null;

  @Output()
  selectedEmoji: EventEmitter<InteractieEmoji> = new EventEmitter<InteractieEmoji>();
  @Output() deleteEmoji: EventEmitter<void> = new EventEmitter<void>();

  emojis = interactieEmojis;

  async onEmojiSelected(emojiKey: InteractieEmoji) {
    if (isNativeApp()) {
      await Haptics.impact({ style: ImpactStyle.Medium });
    }

    if (this.activeEmojiKey === emojiKey) {
      this.activeEmojiKey = null;

      this.deleteEmoji.emit();
    } else {
      this.activeEmojiKey = emojiKey;

      this.selectedEmoji.emit(emojiKey);
    }
  }
}
