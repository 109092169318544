import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { TParkourIconName } from "../parkour-icon/parkour-icon.types";
import { NavigationEnd, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { ParkourIconComponent } from "../parkour-icon/parkour-icon.component";
import { NgClass, NgIf } from "@angular/common";
import { ParkourButtonComponent } from "../parkour-button/parkour-button.component";
import { IonModal } from "@ionic/angular/standalone";

@Component({
  selector: "parkour-modal",
  templateUrl: "./parkour-modal.component.html",
  standalone: true,

  imports: [
    ParkourIconComponent,
    NgIf,
    ParkourButtonComponent,
    NgClass,
    IonModal,
  ],
})
export class ParkourModalComponent implements OnInit, OnDestroy {
  @ViewChild(IonModal, { static: true }) protected modal!: IonModal;
  @Input() public stayOpenOnNavigation = false;
  @Input() public classes?: string;
  @Input() public icon?: TParkourIconName;
  @Input() public id?: string;
  @Input() public canDismiss = true;
  @Input() public hasSpacing = true;
  @Input() public title?: string = "";
  @Input() public footerTransparent?: boolean = false;

  @Output() public hasBeenDismissed: EventEmitter<Event> =
    new EventEmitter<Event>();
  private subscription?: Subscription;

  constructor(private router: Router) {}

  public get isOpen(): boolean {
    return this.modal.isCmpOpen;
  }

  public open() {
    this.modal.present();
  }

  async close() {
    await this.modal.dismiss();
  }

  ngOnInit() {
    this.subscription = this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd && !this.stayOpenOnNavigation) {
        this.close();
      }
    });
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  protected didDismiss(event: Event) {
    this.hasBeenDismissed.emit(event);
  }

  protected handleCloseModal() {
    this.close();
  }
}
