import { Component, EventEmitter, Input, OnInit, Output, viewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { DelenFormService } from '../../../../delen/service/delen-form.service';
import { DelenSectionGroup } from '../../../../delen/model/delen-form-group';
import { ParkourGebeurtenisUpsert } from '../../model/gebeurtenis';
import { FileData, FotoId, GebeurtenisDto, Thema } from 'parkour-web-app-dto';
import { TeamlidProfiel } from '../../../../team/model/teamlid.model';
import { themaMapping } from '../../../../home/model/thema.const';
import { combineLatest, NEVER, Observable, of, startWith, switchMap } from 'rxjs';
import { placeholderHeroSources } from '../../../constants';
import { RawValueOfFormGroup } from '../../../../shared/components/parkour-form/parkour-form.types';
import { CustomFotoEditComponent } from '../../../../shared/components/custom-foto-edit/custom-foto-edit.component';
import { PageWithHeroComponent } from '../../../../shared/components/page-with-hero/page-with-hero.component';
import {
  FormSubmitObservableGenerator,
  ParkourFormComponent,
} from '../../../../shared/components/parkour-form/parkour-form.component';
import {
  ParkourButtonComponent,
  ParkourFormFieldComponent,
  ParkourInputComponent,
  ParkourTextareaComponent,
} from '@parkour/ui';
import { AsyncPipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { DelenSectionComponent } from '../../../../delen/component/delen-section/delen-section.component';
import { AttachmentsUploadComponent } from '../gebeurtenis-attachments-upload/attachments-upload.component';
import { HeroSources } from '../../../../shared/components/page-with-hero/page-with-hero.types';

import { ImageUploadData } from '../../../../shared/model/image-upload';

export type GebeurtenisEditForm = FormGroup<{
  thema: FormControl<Thema | undefined>;
  imageUploadData: FormControl<ImageUploadData>;
  titel: FormControl<string>;
  omschrijving: FormControl<string>;
  bijlagen: FormControl<FileData[]>;
  datum: FormControl<string>;
  delenSection: DelenSectionGroup;
}>;

@Component({
  standalone: true,
  selector: 'parkour-gebeurtenis-form',
  templateUrl: './gebeurtenis-form.component.html',
  imports: [
    CustomFotoEditComponent,
    PageWithHeroComponent,
    ParkourFormComponent,
    ParkourFormFieldComponent,
    ParkourInputComponent,
    ParkourTextareaComponent,
    ParkourButtonComponent,
    ReactiveFormsModule,
    AsyncPipe,
    TranslateModule,
    DelenSectionComponent,
    AttachmentsUploadComponent,
  ],
})
export class GebeurtenisFormComponent implements OnInit {
  @Input() formId = '';
  @Input() initialState: Partial<GebeurtenisDto> = {};
  @Input({ required: true }) teamleden!: TeamlidProfiel[];
  @Input({ required: true })
  submitGebeurtenisForm!: FormSubmitObservableGenerator<ParkourGebeurtenisUpsert>;
  @Input({ required: true }) getExistingImage!: (imageId: FotoId) => Observable<string>;
  @Output() existingFileDownload = new EventEmitter<FileData>();

  form = viewChild.required(ParkourFormComponent);

  registerGebeurtenisForm!: GebeurtenisEditForm;
  hero$: Observable<HeroSources | string> = NEVER;
  pictureChanged = false;

  protected readonly themaMapping = themaMapping;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly delenFormService: DelenFormService,
  ) {}

  ngOnInit() {
    this.registerGebeurtenisForm = this.createForm();
    this.hero$ = this.getHeroObservable();
  }

  private getHeroObservable(): Observable<HeroSources | string> {
    return combineLatest({
      imageUploadData: this.registerGebeurtenisForm.controls.imageUploadData.valueChanges.pipe(
        startWith(this.registerGebeurtenisForm.controls.imageUploadData.value),
      ),
      thema: this.registerGebeurtenisForm.controls.thema.valueChanges.pipe(
        startWith(this.registerGebeurtenisForm.controls.thema.value),
      ),
    }).pipe(
      switchMap(({ imageUploadData, thema }) => {
        if (imageUploadData.type === 'uploaded') {
          return this.getExistingImage(imageUploadData.imageId);
        } else if (imageUploadData.type === 'newly-uploaded') {
          return of(imageUploadData.base64);
        } else if (thema) {
          return of({
            background: themaMapping[thema].largeImage.blur,
            hero: themaMapping[thema].largeImage,
          });
        } else {
          return of(placeholderHeroSources);
        }
      }),
    );
  }

  private createForm(): GebeurtenisEditForm {
    const initialUploadData: ImageUploadData = this.initialState.fotoId
      ? { type: 'uploaded', imageId: this.initialState.fotoId }
      : { type: 'no-image' };

    return this.formBuilder.nonNullable.group({
      thema: this.formBuilder.nonNullable.control(this.initialState.thema ?? undefined),
      imageUploadData: this.formBuilder.nonNullable.control<ImageUploadData>(initialUploadData),
      titel: this.formBuilder.nonNullable.control(
        this.initialState.titel ?? '',
        Validators.required,
      ),
      omschrijving: this.formBuilder.nonNullable.control(this.initialState.omschrijving ?? ''),
      bijlagen: this.formBuilder.nonNullable.control(this.initialState.bijlagen ?? []),
      datum: this.formBuilder.nonNullable.control(
        this.initialState.startDatum ?? '',
        Validators.required,
      ),
      delenSection: this.delenFormService.createDelenFormGroup(this.teamleden, this.initialState),
    });
  }

  submitForm = (formValue: RawValueOfFormGroup<GebeurtenisEditForm>) => {
    if (
      formValue.imageUploadData.type !== 'uploaded' &&
      formValue.imageUploadData.type !== 'newly-uploaded' &&
      formValue.imageUploadData.type !== 'no-image'
    ) {
      throw new Error('Image still uploading or errored');
    }

    return this.submitGebeurtenisForm({
      titel: formValue.titel,
      omschrijving: formValue.omschrijving,
      imageId:
        formValue.imageUploadData.type === 'no-image'
          ? undefined
          : formValue.imageUploadData.imageId,
      bijlagen: formValue.bijlagen,
      startDatum: formValue.datum,
      deelMode: formValue.delenSection.deelMode,
      thema: formValue.thema,
      gedeeldMet: this.teamleden
        .filter((teamLid, index) => formValue.delenSection.gedeeldMet[index].gedeeldMet)
        .map((teamlid) => teamlid.id),
      type: 'PARKOUR',
    });
  };

  protected onExistingFileDownload(fileData: FileData) {
    this.existingFileDownload.emit(fileData);
  }
}
