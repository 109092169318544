<div class="w-full h-full flex items-center justify-center">
  <span
    [ngClass]="{
      'w-[1.5rem] h-[1.5rem]': size === 'default',
      'w-[1rem] h-[1rem]': size === 'small',
      'border-b-p-100': variant === 'default',
      'border-b-n-1000': variant === 'secondary',
    }"
    class="w-[1.5rem] h-[1.5rem] animate-spin rounded-[50%] border-[3px] border-solid border-n-1000/[.15]  {{
      classes
    }}"
  ></span>
</div>
