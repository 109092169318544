<div
  [ngClass]="{
    'max-w-[5.5rem]': size === 'full-size',
    'w-[1.5rem] h-[1.5rem]': size === '1.5',
    'w-[2rem] h-[2rem]': size === '2',
    'w-[2.5rem] h-[2.5rem]': size === '2.5',
    'w-[3rem] h-[3rem]': size === '3',
    'w-[3.5rem] h-[3.5rem]': size === '3.5',
    'w-[4rem] h-[4rem]': size === '4',
    'w-[5rem] h-[5rem]': size === '5',
    'opacity-50': grayout,
  }"
  class="relative"
>
  @switch (image.type) {
    @case ('icon') {
      <div
        [ngClass]="{
          rounded:
            size === 'full-size' ||
            size === '2' ||
            size === '2.5' ||
            size === '3' ||
            size === '3.5' ||
            size === '4' ||
            size === '5',
          'rounded-sm': size === '1.5',
        }"
        class="relative z-10 aspect-square flex items-center justify-center bg-n-200 overflow-hidden"
      >
        <parkour-icon class="text-n-1000" [name]="image.name" size="3" />
      </div>
    }
    @case ('image') {
      <parkour-image
        [ngClass]="{
          rounded:
            size === 'full-size' ||
            size === '2' ||
            size === '2.5' ||
            size === '3' ||
            size === '3.5' ||
            size === '4' ||
            size === '5',
          'rounded-sm': size === '1.5',
        }"
        [alt]="image.alt ? image.alt : ''"
        [src]="image.src"
        class="relative z-10 block aspect-square overflow-hidden"
      />
    }
  }
  @if (badgeCount) {
    <span
      class="absolute z-20 typo-body variant-body-small bg-n-400 rounded-sm px-[7%] py-[5%] flex gap-x-1 items-center -right-[10%] -bottom-[10%]"
    >
      <parkour-icon class="text-n-1000" name="people" />

      {{ badgeCount }}
    </span>

    <div
      class="absolute top-0 left-0 w-full h-full bg-n-400 rounded translate-x-[5%] translate-y-[5%]"
    ></div>
  }
</div>
