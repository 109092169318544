import { Component, Input, OnInit } from '@angular/core';
import { ArtikelCardComponent } from '../../../watwat/components/artikel-card/artikel-card.component';
import { AsyncPipe } from '@angular/common';
import { map, ReplaySubject, Subject } from 'rxjs';
import { WatwatService } from '../../../shared/services/watwat.service';
import { parseOrganisationId } from 'parkour-web-app-dto';
import { HelplineCardItemComponent } from '../../../home/component/helpline-card-item/helpline-card-item.component';
import { ContentUnavailableCardComponent } from '../../../shared/components/content-unavailable-card/content-unavailable-card.component';
import { TranslateModule } from '@ngx-translate/core';
import { OrganisationWithBookmark } from '../../../shared/model/artikel';
import { ParkourLoadingSpinnerComponent } from '@parkour/ui';

@Component({
  selector: 'parkour-bericht-hulplijn',
  templateUrl: './bericht-hulplijn.component.html',
  imports: [
    ArtikelCardComponent,
    AsyncPipe,
    HelplineCardItemComponent,
    ContentUnavailableCardComponent,
    TranslateModule,
    ParkourLoadingSpinnerComponent,
  ],
  standalone: true,
})
export class BerichtHulplijnComponent implements OnInit {
  @Input({ required: true }) organisationId!: string;

  readonly hulplijn$: Subject<OrganisationWithBookmark> = new ReplaySubject();
  protected readonly String = String;

  constructor(private readonly watwatService: WatwatService) {}

  ngOnInit() {
    this.watwatService
      .getOrganisationsOfIds([parseOrganisationId(this.organisationId)])
      .pipe(map((organisations) => organisations.at(0)))
      .subscribe((organisation) => organisation && this.hulplijn$.next(organisation));
  }
}
