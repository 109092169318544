import { Component, Input } from '@angular/core';
import { Gesprek } from '../../model/gesprek';
import { ParkourProfielBlockComponent } from '../../../shared/components/parkour-profiel-block/parkour-profiel-block.component';
import { NgForOf, NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  selector: 'parkour-groepsgesprek-geblokkeerde-deelnemer-list',
  templateUrl: './groepsgesprek-geblokkeerde-deelnemer-list.component.html',
  imports: [ParkourProfielBlockComponent, NgForOf, NgIf, TranslateModule],
})
export class GroepsgesprekGeblokkeerdeDeelnemerListComponent {
  @Input({ required: true }) gesprek!: Gesprek;
}
