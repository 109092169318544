<div class="flex items-start gap-x-3">
  <div class="relative">
    <parkour-profiel-foto-badge [alt]="false" [profiel]="profielData" [size]="'3'" />

    <parkour-new-indicator [show]="showNewIndicator" />
  </div>

  <div class="flex flex-col items-start gap-y-1 whitespace-normal">
    <p class="typo-body variant-body-fat">{{ profielData | profielnaam }}</p>

    <span
      [ngClass]="{
        'text-s-100': profielData.viewType === 'JONGERE',
      }"
      class="typo-body text-n-600 flex align-center gap-x-1"
    >
      @if (customTag) {
        <ng-content></ng-content>
      } @else {
        @switch (profielData.viewType) {
          @case ('JONGERE') {
            Teameigenaar
          }
          @case ('TEAMLID') {
            @if (
              profielData.status !== 'UNSUPPORTED' &&
              profielData.status !== 'INACTIEF' &&
              profielData.status !== 'GEBLOKKEERDE_WEERGAVE' &&
              profielData.status !== 'UITGENODIGD'
            ) {
              {{ profielData.rol | rolLabelDefault: '' }}
            }
          }
        }

        @if (profielData.status === 'ACTIEF' && profielData.type === 'PROFESSIONEEL_TEAMLID') {
          <parkour-professioneel-indicator />
        }
      }
    </span>
    @if (profielData.status === 'GEBLOKKEERD') {
      <parkour-tag iconBefore="block" variant="light-blue">
        {{ 'delen.geblokkeerd' | translate }}
      </parkour-tag>
    }
  </div>
</div>
