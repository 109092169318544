<parkour-foto-badge
  [badgeCount]="badgeCount"
  [image]="
    profiel.status === 'ACTIEF' ||
    profiel.status === 'UITGENODIGD' ||
    profiel.status === 'UITGENODIGDE_WEERGAVE'
      ? {
          type: 'image',
          src:
            profiel
            | profielFoto: (size === '5' || size === 'full-size' ? 'full' : 'thumbnail')
            | async,
          alt,
        }
      : { type: 'icon', name: getIconNaam(profiel) }
  "
  [size]="size"
  class="block"
/>
