import { Component } from '@angular/core';
import {
  ParkourBlockPlaceholderComponent,
  ParkourDividerComponent,
  ParkourHeroComponent,
  ParkourPageImageComponent,
} from '@parkour/ui';
import { AanmeldButtonComponent } from '../../../../shared/components/aanmeld-button/aanmeld-button.component';
import { PageLayoutComponent } from '../../../../shared/components/page-layout/page-layout.component';
import {
  IonContent,
  IonHeader,
  IonRefresher,
  IonRefresherContent,
} from '@ionic/angular/standalone';
import { ParkourRefreshDirective } from '../../../../shared/directives/parkour-refresh-directive';
import { ParkourPage } from '../../../../shared/parkour-page';
import { verhaalHeroSources } from '../../../constants';
import { PageWithHeroComponent } from '../../../../shared/components/page-with-hero/page-with-hero.component';
import { TranslateModule } from '@ngx-translate/core';
import { VerhaalPlaceholderComponent } from '../../component/verhaal-placeholder/verhaal-placeholder.component';

@Component({
  standalone: true,
  templateUrl: './verhaal-niet-aangemeld.page.html',
  imports: [
    ParkourPageImageComponent,
    ParkourHeroComponent,
    ParkourDividerComponent,
    AanmeldButtonComponent,
    ParkourBlockPlaceholderComponent,
    PageLayoutComponent,
    IonHeader,
    IonContent,
    IonRefresherContent,
    IonRefresher,
    ParkourRefreshDirective,
    PageWithHeroComponent,
    TranslateModule,
    VerhaalPlaceholderComponent,
  ],
})
export class VerhaalNietAangemeldPage extends ParkourPage {
  protected readonly verhaalHeroSources = verhaalHeroSources;
}
