import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { Params } from "@angular/router";

import { TParkourActionTypes } from "../parkour-action/parkour-action.types";
import {
  TParkourButtonFormat,
  TParkourButtonSize,
  TParkourButtonVariant,
} from "./parkour-button.types";
import {
  TParkourIconName,
  TParkourIconSizes,
} from "../parkour-icon/parkour-icon.types";
import { ParkourActionComponent } from "../parkour-action/parkour-action.component";
import { NgClass, NgIf, NgTemplateOutlet } from "@angular/common";
import { ParkourIconComponent } from "../parkour-icon/parkour-icon.component";
import { ParkourLoadingSpinnerComponent } from "../parkour-loading-spinner/parkour-loading-spinner.component";

@Component({
  standalone: true,
  selector: " parkour-button",
  templateUrl: "./parkour-button.component.html",
  imports: [
    ParkourActionComponent,
    NgIf,
    NgTemplateOutlet,
    ParkourIconComponent,
    NgClass,
    ParkourLoadingSpinnerComponent,
  ],
})
export class ParkourButtonComponent {
  @ViewChild("action") action!: ParkourActionComponent;
  @Input() public classes?: string = "";
  @Input() public href?: string;
  @Input() public link?: string[] | string | null;
  @Input() public routeInfo?: unknown;
  @Input() public queryParams: Params = {};
  @Input() public type?: TParkourActionTypes = "button";
  @Input() public disabled?: boolean;
  @Input() public ariaLabel?: string;
  @Input() public ariaExpanded?: boolean;
  @Input() public ariaCurrent?: boolean;
  @Input() public ariaPressed?: boolean;
  @Input() public ariaControls?: string;
  @Input() public tabIndex?: number;
  @Input() public icon?: TParkourIconName;
  @Input() public iconBefore?: TParkourIconName;
  @Input() public iconAfter?: TParkourIconName;
  @Input() public format: TParkourButtonFormat = "default";
  @Input() public variant: TParkourButtonVariant = "default";
  @Input() public size: TParkourButtonSize = "default";
  @Input() public iconSize: TParkourIconSizes = "2";
  @Input() public testId?: string;
  @Input() public download: string | true | undefined;
  @Input() public isActive: boolean = false;
  @Input() public loading: boolean = false;

  @Output() public clicked: EventEmitter<MouseEvent> =
    new EventEmitter<MouseEvent>();

  clickAction(event: MouseEvent) {
    this.clicked.emit(event);
  }

  setFocus() {
    this.action.setFocus();
  }

  getParkourActionClasses() {
    return `inline-flex w-[inherit]`;
  }

  getParkourActionRootClasses() {
    return `relative overflow-hidden w-[inherit] inline-flex items-center justify-center text-center transition-all group ${this.classes}`;
  }

  getParkourActionNgClasses() {
    return {
      "min-h-[3rem]":
        this.size === "default" &&
        this.variant !== "ghost" &&
        this.variant !== "ghost-light",
      "min-h-[2rem]":
        this.size === "small" &&
        this.variant !== "ghost" &&
        this.variant !== "ghost-light",
      "parkour-button__gradient":
        this.variant === "default" || this.variant === "quaternary",
      "parkour-button__gradient--default": this.variant === "default",
      "parkour-button__gradient--quaternary": this.variant === "quaternary",
      "text-n-0 bg-s-100 no-underline hover:bg-s-200 focus-visible:bg-s-200":
        this.variant === "secondary",
      "border-[1.5px] border-n-1000 bg-transparent no-underline hover:bg-n-1000/20 focus-visible:bg-n-1000/20":
        this.variant === "tertiary",
      "text-n-600 bg-transparent": this.variant === "ghost",
      "text-n-1000 bg-transparent": this.variant === "ghost-light",
      "hover:text-n-1000 focus:text-n-1000":
        this.variant === "ghost" && !this.disabled,
      "hover:text-n-600 focus:text-n-600":
        this.variant === "ghost-light" && !this.disabled,
      rounded: this.size === "default",
      "gap-1 rounded-sm": this.size === "small",
      "p-2":
        this.size === "small" ||
        (this.size === "default" && this.variant !== "ghost"),
      "gap-2": this.size === "none" || this.size === "default",
      "cursor-not-allowed opacity-50": this.disabled,
      "bg-black/30 hover:bg-n-200 focus:bg-n-200":
        this.variant === "dark-transparent",
      "bg-n-200": this.variant === "dark-transparent" && this.isActive,
    };
  }

  getParkourActionNgIconButtonClasses() {
    return {
      "aspect-square rounded": this.variant !== "ghost",
      "min-h-[3rem]": this.size === "default" && this.variant !== "ghost",
      "min-h-[2.25rem]": this.size === "medium" && this.variant !== "ghost",
      "min-h-[2rem]": this.size === "small" && this.variant !== "ghost",
      "bg-p-100 hover:bg-p-200 focus-visible:bg-p-200":
        this.variant === "default",
      "border-n-1000 bg-transparent hover:bg-n-1000/20 focus-visible:bg-n-1000/20":
        this.variant === "tertiary",
      "bg-transparent": this.variant === "ghost",
      "border-[1.5px]":
        this.variant === "tertiary" || this.variant === "secondary",
      "cursor-not-allowed opacity-50": this.disabled,
      "bg-black/30 hover:bg-n-200 focus:bg-n-200":
        this.variant === "dark-transparent",
      "text-s-100 border-s-100 bg-transparent hover:border-s-200 focus-visible:border-s-200 hover:text-s-200 focus-visible:text-s-200":
        this.variant === "secondary",
    };
  }
}
