<ion-header></ion-header>

<ion-content>
  <ion-refresher [parkourRefresh]="onPageRefresh" slot="fixed">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>

  <parkour-page-layout>
    <parkour-page-with-hero
      [heroImage]="verhaalHeroSources"
      [title]="'verhaal.overview-page.title' | translate"
    >
      <div class="container-1-col">
        <ng-container *parkourShowIfSuccessful="mainPageData$ | async; let verhaal">
          @if (verhaal.verhaalZichtbaar.items.length === 0 && !query) {
            <p class="text-center mb-8">Je hebt nog geen gebeurtenissen in je verhaal.</p>
          }
        </ng-container>

        <parkour-button class="w-full flex" iconAfter="plus-circle" link="nieuw">
          Gebeurtenis toevoegen
        </parkour-button>

        <div class="mt-8 space-y-6">
          <ng-container
            *parkourSectionContent="
              {
                data: mainPageData$ | async,
                errorDescription:
                  'We kunnen sommige gebeurtenissen nu niet weergeven, probeer later opnieuw.',
              };
              let verhaalData
            "
          >
            <ng-container
              *parkourSectionContent="
                { data: gebeurtenisSuggesties$ | async, hideSpinner: true };
                let suggesties
              "
            >
              <ul class="space-y-6">
                @for (suggestie of suggesties; track suggestie.id) {
                  <li>
                    <parkour-gebeurtenis-voorstel
                      [gebeurtenisSuggestie]="suggestie"
                      (confirm)="onSuggestieConfirm(suggestie)"
                      (reject)="onSuggestieReject(suggestie)"
                    />
                  </li>
                }
              </ul>
            </ng-container>
            <form (ngSubmit)="search()" class="space-y-6">
              <parkour-form-field
                [inputFormControl]="null"
                [isLabelHidden]="true"
                inputId="verhaal-search"
                [label]="'verhaal.overview-page.search-input' | translate"
              >
                <parkour-input
                  [(ngModel)]="enteredQueryString"
                  inputId="verhaal-search"
                  [inputPlaceholder]="'verhaal.overview-page.search-input' | translate"
                  name="verhaal-search"
                >
                  <parkour-button
                    [ariaLabel]="'buttons.search' | translate"
                    format="iconButton"
                    icon="search"
                    size="medium"
                    type="submit"
                  />
                </parkour-input>
              </parkour-form-field>

              @if (query) {
                <div aria-live="polite">
                  <span
                    class="block"
                    [innerHTML]="
                      'verhaal.overview-page.search-suggestie'
                        | translate
                          : {
                              amount: verhaalData.verhaalZichtbaar.items.length,
                              query,
                            }
                    "
                  ></span>
                </div>
              }
            </form>

            <parkour-gebeurtenis-list
              [verhaal]="verhaalData.verhaalZichtbaar"
              [ongelezenGebeurtenisMeldingen]="(ongelezenGebeurtenisMeldingen$ | async) ?? []"
            />

            @if (!query && verhaalData.verhaalVerborgen.items.length > 0) {
              <details class="group">
                <summary class="flex items-center justify-center text-n-600 cursor-pointer">
                  <span class="typo-body variant-body-fat underline text-center">
                    {{ 'verhaal.overview-page.toon-verborgen' | translate }}
                  </span>

                  <parkour-icon
                    class="flex group-open:rotate-180 transition-transform ml-2"
                    name="chevron-bottom"
                  />
                </summary>

                <parkour-gebeurtenis-list
                  [placeholderEnabled]="false"
                  [verhaal]="verhaalData.verhaalVerborgen"
                  [ongelezenGebeurtenisMeldingen]="(ongelezenGebeurtenisMeldingen$ | async) ?? []"
                />
              </details>
            }
          </ng-container>
        </div>
      </div>
    </parkour-page-with-hero>
  </parkour-page-layout>
</ion-content>
