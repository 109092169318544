import { FotoId } from 'parkour-web-app-dto';

export type NewlyUploadedImageData = {
  readonly type: 'newly-uploaded';
  readonly imageId: FotoId;
  readonly file: File;
  readonly base64: string;
};
export type UploadedImageData = {
  readonly type: 'uploaded';
  readonly imageId: FotoId;
};
export type ConvertingImageData = {
  readonly type: 'converting';
};
export type UploadingImageData = {
  readonly type: 'uploading';
  readonly file: File;
  readonly base64: string;
};
export type ErroredImageData = {
  readonly type: 'error';
  readonly error: Error;
};
export type NoImageData = {
  readonly type: 'no-image';
};
export type ImageUploadData =
  | UploadedImageData
  | UploadingImageData
  | ErroredImageData
  | NewlyUploadedImageData
  | ConvertingImageData
  | NoImageData;

export const toImageId = (image: ImageUploadData): FotoId | undefined => {
  switch (image.type) {
    case 'newly-uploaded':
      return image.imageId;
    case 'uploaded':
      return image.imageId;
    default:
      return undefined;
  }
};
