type LoginErrorType = 'login-ongeldige-hoedanigheid' | 'technische-fout' | 'login-inactief-profiel';
type ErrorConfig = {
  titel: string;
  description: string;
};

const errorMappings: Record<LoginErrorType, ErrorConfig> = {
  'login-ongeldige-hoedanigheid': {
    titel: 'Ongeldige hoedanigheid',
    description: 'De hoedanigheid is niet geldig.',
  },
  'technische-fout': {
    titel: 'Technische fout',
    description: 'Er is een technische fout opgetreden.',
  },
  'login-inactief-profiel': {
    titel: 'Inactief profiel',
    description: 'Je profiel is gedeactiveerd door PARKOUR.',
  },
};

function isLoginErrorType(error: string | null): error is LoginErrorType {
  if (error === null) {
    return false;
  }

  return error in errorMappings;
}

export function mapLoginError(error: string | null): ErrorConfig {
  if (!isLoginErrorType(error)) {
    return errorMappings['technische-fout'];
  }
  return errorMappings[error];
}
