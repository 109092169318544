<div
  class="grid min-h-[18rem] max-h-[20rem] grid-rows-[1fr_auto] grid-cols-[auto] rounded overflow-hidden {{
    classes
  }}"
>
  <parkour-image
    class="col-start-1 col-end-2 row-start-1 row-end-3"
    fit="cover"
    [src]="src"
    [alt]="alt"
  />

  <div
    class="col-start-1 col-end-2 row-start-1 row-end-3 bg-gradient-to-t from-p-100/90 to-transparent"
  ></div>

  <div class="col-start-1 col-end-2 row-start-2 row-end-3 p-5">
    <ng-content></ng-content>
  </div>
</div>
