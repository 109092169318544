<div class="flex items-center gap-x-2">
  @if (gesprekId | berichtAttachmentMeta: bericht | async; as metadata) {
    <div class="flex-1 flex flex-col">
      <span class="bericht-message-title typo-body variant-body-small variant-body-fat">
        <ng-container *ngIf="bericht.deelnemerId | profiel | async as profiel">
          {{ profiel | profielnaam }}
        </ng-container>
      </span>

      <div class="bericht-message-content">
        <div class="inline-flex items-start gap-x-1">
          <parkour-icon *ngIf="!downloading" name="attachment" />

          <parkour-loading-spinner *ngIf="downloading" [size]="'small'" />

          <parkour-action
            (clicked)="onDownloadAttachment(bericht, bericht.bestandId)"
            class="block mr-auto"
            classes="typo-body variant-body-fat text-n-1000 underline break-all"
          >
            <span>{{ decodeURIComponent(metadata.name) }}</span>
          </parkour-action>
        </div>
      </div>
    </div>

    @if (metadata.hasPreview) {
      <parkour-image
        alt=""
        class="bg-n-1000 rounded-sm overflow-hidden w-[20%] h-[4rem]"
        [src]="bericht | bestandPreview: gesprekId | async"
      />
    }
  }
</div>
