import { Component, Input } from '@angular/core';
import { ParkourIconComponent, ParkourNewIndicatorComponent, TParkourIconName } from '@parkour/ui';
import { RouterLink, RouterLinkActive } from '@angular/router';

@Component({
  standalone: true,
  selector: 'parkour-page-layout-sidebar-nav-item',
  templateUrl: './page-layout-sidebar-nav-item.component.html',
  imports: [RouterLinkActive, ParkourIconComponent, RouterLink, ParkourNewIndicatorComponent],
})
export class PageLayoutSidebarNavItemComponent {
  @Input({ required: true }) public text!: string;
  @Input({ required: true }) public icon!: TParkourIconName;
  @Input({ required: true }) public link!: string[] | null;
  @Input() public exact = false;
  @Input() showNewIndicator: boolean | null = false;
}
