<parkour-action
  (clicked)="openModal()"
  [ariaExpanded]="parkourModal.isOpen"
  [ngClasses]="{
    'border-n-500': !error && !disabled,
    'border-negative': error,
    'border-n-300': disabled,
  }"
  ariaHasPopup="listbox"
  ariaControls="custom-list"
  role="combobox"
  class="block"
  classes="flex items-center justify-between border-[1.5px] rounded bg-n-200 w-full p-3"
>
  @if (_value) {
    <parkour-persoon-select-option [option]="_value" />
  } @else {
    <div class="flex items-center gap-x-3 w-full">
      <p>{{ placeholder }}</p>
    </div>
  }

  <parkour-icon name="chevron-down" size="3" />
</parkour-action>

<parkour-modal #parkourModal [hasSpacing]="false" icon="people" title="Persoon">
  <ul role="listbox" class="divide-y-[1.5px] divide-n-500" content id="custom-list">
    @for (option of options; track option; let i = $index) {
      <li
        [attr.aria-selected]="option === _value"
        role="option"
        [attr.aria-setsize]="options.length"
        [attr.aria-posinset]="i + 1"
      >
        <parkour-action
          (clicked)="selectPerson(option)"
          [ngClass]="{
            'bg-n-200': option === _value,
            'bg-n-100': option !== _value,
          }"
          class="block"
          classes="w-full p-3 flex items-center justify-between hover:bg-n-200 focus:bg-n-200 transition-colors"
        >
          <parkour-persoon-select-option [option]="option" />

          @if (option === _value) {
            <parkour-icon class="text-positive" name="check" size="3" />
          }
        </parkour-action>
      </li>
    }
  </ul>
</parkour-modal>
