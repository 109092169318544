@switch (content.type) {
  @case ('text') {
    <parkour-artikel-text-content [content]="content" />
  }
  @case ('quote') {
    <parkour-artikel-quote-content [content]="content" />
  }
  @case ('video') {
    <parkour-artikel-video-content [content]="content" />
  }
  @case ('faq') {
    <parkour-artikel-faq-content [content]="content" />
  }
  @case ('call_to_action') {
    <parkour-artikel-call-to-action-content [content]="content" />
  }
  @case ('attachment') {
    <parkour-artikel-attachment-content [content]="content" />
  }
  @case ('image') {
    <parkour-artikel-image-content [content]="content" />
  }
  @case ('html_embed') {
    <parkour-artikel-html-embed-content [content]="content" />
  }
  @case ('html_embed_large') {
    <parkour-artikel-html-embed-large-content [content]="content" />
  }
}
