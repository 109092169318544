import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { ProfielService } from '../../../profiel/service/profiel.service';
import { IonContent, IonHeader, ViewWillEnter } from '@ionic/angular/standalone';
import { AangemeldeUser, GeenProfielUser } from '../../../authentication/user';
import { asResult, Result } from '../../../utils';
import { ActivatedRoute } from '@angular/router';
import {
  ParkourActionComponent,
  ParkourDividerComponent,
  ParkourPageImageComponent,
} from '@parkour/ui';
import { AsyncPipe, NgIf } from '@angular/common';
import { ProfielFotoComponent } from '../../../shared/components/profiel-foto/profiel-foto.component';
import { ProfessioneelIndicatorComponent } from '../../../shared/components/professioneel-indicator/professioneel-indicator.component';
import { ContentUnavailableCardComponent } from '../../../shared/components/content-unavailable-card/content-unavailable-card.component';
import { PageLayoutComponent } from '../../../shared/components/page-layout/page-layout.component';
import { MijnProfiel } from '../../../profiel/model/profiel';
import { ProfielnaamPipe } from '../../../shared/pipes/profielnaam.pipe';
import AuthService from '../../../authentication/service/auth.service';

@Component({
  standalone: true,
  templateUrl: './profiel-selecteer.page.html',
  imports: [
    ParkourPageImageComponent,
    AsyncPipe,
    NgIf,
    ParkourActionComponent,
    ParkourDividerComponent,
    ProfielFotoComponent,
    ProfessioneelIndicatorComponent,
    ContentUnavailableCardComponent,
    PageLayoutComponent,
    IonHeader,
    IonContent,
    ProfielnaamPipe,
  ],
})
export class ProfielSelecteerPage implements ViewWillEnter {
  user$ = new Observable<GeenProfielUser | AangemeldeUser>();
  profielen$ = new Observable<Result<MijnProfiel[]>>();

  constructor(
    private readonly authService: AuthService,
    private readonly profielService: ProfielService,
    private readonly activatedRoute: ActivatedRoute,
  ) {}

  ionViewWillEnter(): void {
    this.user$ = this.authService.getGeenProfielOrAangemeldeUser$();

    this.profielen$ = asResult(this.profielService.getMyProfielen());
  }

  private getRedirectUrl(): string {
    const redirectUrl = this.activatedRoute.snapshot.queryParamMap.get('redirectUrl');

    if (!redirectUrl || redirectUrl === '') {
      return '/';
    }

    return redirectUrl;
  }

  onProfielClick(profiel: MijnProfiel) {
    this.authService.selectProfiel(profiel.id, this.getRedirectUrl()).subscribe();
  }
}
