import { Component } from '@angular/core';
import { ProfielFormService } from '../../../profiel/service/profiel-form.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ProfielCreateService } from '../../../profiel/service/profiel-create.service';
import { ParkourBackButtonDirective } from '../../../shared/directives/parkour-back-button.directive';
import {
  ParkourButtonComponent,
  ParkourInfoCardComponent,
  ParkourPageImageComponent,
} from '@parkour/ui';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { PageLayoutComponent } from '../../../shared/components/page-layout/page-layout.component';
import { ProfileDetailsOverJezelfEditComponent } from '../../../profiel/component/profile-details-over-jezelf-edit/profile-details-over-jezelf-edit.component';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';
import { finalize } from 'rxjs';

@Component({
  standalone: true,
  templateUrl: './profiel-create-about.page.html',
  imports: [
    ParkourBackButtonDirective,
    ParkourPageImageComponent,
    ParkourInfoCardComponent,
    FormsModule,
    TranslateModule,
    ParkourButtonComponent,
    PageLayoutComponent,
    ProfileDetailsOverJezelfEditComponent,
    IonBackButton,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonTitle,
    IonContent,
  ],
})
export class ProfielCreateAboutPage {
  busy = false;
  overJezelfFormGroup = this.profielFormService.createOverJezelfFormGroup({ links: [] });

  constructor(
    private readonly profielFormService: ProfielFormService,
    private readonly profileCreateService: ProfielCreateService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
  ) {}

  async onSubmit() {
    this.profileCreateService.setOverJezelfData(this.overJezelfFormGroup.getRawValue());

    this.busy = true;
    this.profileCreateService
      .createMyProfiel()
      .pipe(finalize(() => (this.busy = false)))
      .subscribe(() => {
        this.profileCreateService.resetProfielData();
        this.router.navigate(['../', 'succes'], {
          relativeTo: this.route,
          queryParamsHandling: 'preserve',
        });
      });
  }
}
