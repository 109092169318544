import { Pipe, PipeTransform } from '@angular/core';
import { ProfielId } from 'parkour-web-app-dto';
import { Observable } from 'rxjs';
import { ProfielService } from '../../profiel/service/profiel.service';
import { ProfielInTeam } from '../../profiel/model/profiel-in-team';

@Pipe({
  standalone: true,
  name: 'profiel',
})
export class ProfielPipe implements PipeTransform {
  constructor(private readonly profielService: ProfielService) {}

  transform(value: ProfielId): Observable<ProfielInTeam> {
    return this.profielService.getProfielInCurrentTeam(value);
  }
}
