import { Pipe, PipeTransform } from '@angular/core';
import { Rol } from 'parkour-web-app-dto';
import { getRolLabel } from '../../profiel/rol-labels';

@Pipe({
  name: 'rolLabel',
  standalone: true,
})
export class RolLabelPipe implements PipeTransform {
  transform(rol: Rol): string {
    return getRolLabel(rol);
  }
}
