import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { Haptics, ImpactStyle } from "@capacitor/haptics";
import { isNativeApp } from "../utils";
import { IonCheckbox } from "@ionic/angular/standalone";

@Component({
  standalone: true,
  selector: "parkour-checkbox",
  templateUrl: "./parkour-checkbox.component.html",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ParkourCheckboxComponent,
    },
  ],
  imports: [IonCheckbox],
})
export class ParkourCheckboxComponent
  implements ControlValueAccessor, OnChanges
{
  @Input() public classes?: string;
  @Input() public disabled = false;
  @Input() public checked = false;

  @Output() checkboxChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public onChange?: (value: boolean) => void;
  public onTouched?: () => void;

  async onCheckboxChange() {
    this.checked = !this.checked;

    if (isNativeApp()) {
      await Haptics.impact({ style: ImpactStyle.Light });
    }

    this.checkboxChange.emit(this.checked);
    this.onChange?.(this.checked);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["disabled"] && changes["disabled"].currentValue) {
      this.checked = false;

      this.checkboxChange.emit(this.checked);
    }
  }

  writeValue(value: boolean): void {
    this.checked = value;
    this.onChange?.(value);
  }

  registerOnChange(onChange: (value: boolean) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }
}
