import { Directive, OnDestroy, OnInit } from '@angular/core';
import { IonModal } from '@ionic/angular/standalone';
import { NavigationEnd, Router } from '@angular/router';
import { filter, Subscription } from 'rxjs';

@Directive({
  selector: '[parkourModalBehavior]',
  exportAs: 'parkourModalBehavior',
  standalone: true,
  providers: [IonModal],
})
export class ParkourModalBehaviorDirective implements OnInit, OnDestroy {
  get isOpen(): boolean {
    return this.ionModal.isCmpOpen;
  }

  constructor(
    private readonly ionModal: IonModal,
    private readonly router: Router,
  ) {}

  private subscription?: Subscription;
  private dismissSubscription?: Subscription;

  ngOnInit(): void {
    this.subscription = this.router.events
      .pipe(filter((e) => e instanceof NavigationEnd))
      .subscribe(() => this.close());
  }

  public open() {
    this.ionModal.present();
  }

  public async close() {
    await this.ionModal.dismiss();
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.dismissSubscription?.unsubscribe();
  }
}
