import { Component, Input } from '@angular/core';
import { DoelDataSectionGroup } from '../doel-form/doel-form.component';
import { themas } from 'parkour-web-app-dto';
import { themaMapping } from '../../../../home/model/thema.const';
import {
  ParkourDividerComponent,
  ParkourFormFieldComponent,
  ParkourHeroComponent,
  ParkourInputComponent,
  ParkourTextareaComponent,
} from '@parkour/ui';
import { ReactiveFormsModule } from '@angular/forms';
import { DoelThemaComponent } from '../doel-thema/doel-thema.component';
import { TranslateModule } from '@ngx-translate/core';
import { IonRadioGroup } from '@ionic/angular/standalone';
import { DoelUpsert } from '../../model/doel-upsert';
import { NgTemplateOutlet } from '@angular/common';

@Component({
  standalone: true,
  selector: 'parkour-doel-edit-data-section',
  templateUrl: './doel-edit-data-section.component.html',
  imports: [
    ParkourHeroComponent,
    ReactiveFormsModule,
    ParkourFormFieldComponent,
    ParkourInputComponent,
    ParkourDividerComponent,
    DoelThemaComponent,
    TranslateModule,
    IonRadioGroup,
    NgTemplateOutlet,
    ParkourTextareaComponent,
  ],
})
export class DoelEditDataSectionComponent {
  @Input({ required: true }) doelFormGroup!: DoelDataSectionGroup;
  @Input({ required: true }) initialState!: Partial<DoelUpsert>;
  protected readonly themas = themas;
  protected readonly themaMapping = themaMapping;
}
