import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { DoelUpsert } from '../../model/doel-upsert';
import { DoelFormService } from '../../service/doel-form.service';
import { DelenFormService } from '../../../../delen/service/delen-form.service';
import { TeamlidProfiel } from '../../../../team/model/teamlid.model';
import { TranslateModule } from '@ngx-translate/core';
import { DoelEditDataSectionComponent } from '../doel-edit-data-section/doel-edit-data-section.component';
import { ActiesSelectSectionComponent } from '../acties-select-section/acties-select-section.component';
import { DelenSectionComponent } from '../../../../delen/component/delen-section/delen-section.component';
import {
  FormSubmitObservableGenerator,
  ParkourFormComponent,
} from '../../../../shared/components/parkour-form/parkour-form.component';
import { RawValueOfFormGroup } from '../../../../shared/components/parkour-form/parkour-form.types';
import { DoelForm } from '../doel-form/doel-form.component';

@Component({
  standalone: true,
  selector: 'parkour-doel-edit-form',
  templateUrl: './doel-edit-form.component.html',
  imports: [
    ReactiveFormsModule,
    TranslateModule,
    DoelEditDataSectionComponent,
    ActiesSelectSectionComponent,
    DelenSectionComponent,
    ParkourFormComponent,
  ],
})
export class DoelEditFormComponent implements OnInit {
  @Input() formId = '';
  @Input({ required: true }) teamleden!: Array<TeamlidProfiel>;
  @Input({ required: true }) initialState!: Partial<DoelUpsert>;
  @Input({ required: true }) submitDoel!: FormSubmitObservableGenerator<
    RawValueOfFormGroup<DoelForm>
  >;

  doelForm!: DoelForm;

  constructor(
    private readonly doelFormService: DoelFormService,
    private readonly formBuilder: FormBuilder,
    private readonly delenFormService: DelenFormService,
  ) {}

  ngOnInit(): void {
    this.doelForm = this.formBuilder.nonNullable.group({
      doelDataSection: this.doelFormService.createDoelDataFormGroup(this.initialState),
      actieSection: this.doelFormService.createActieSectionFormGroup(this.initialState),
      gedeeldMetSection: this.delenFormService.createDelenFormGroup(
        this.teamleden,
        this.initialState,
      ),
    });
  }
}
