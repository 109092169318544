import { Route } from '@angular/router';
import { ArtikelsPage } from './page/artikels-page/artikels.page';
import { ArtikelPage } from './page/artikel-page/artikel.page';
import { HulplijnenPage } from './page/hulplijnen-page/hulplijnen.page';
import { HulplijnPage } from './page/hulplijn-page/hulplijn-page.component';

export const artikelOverviewRoutes: Route = {
  path: 'artikels',
  title: 'watwat.artikels-page.title',
  children: [
    {
      path: '',
      pathMatch: 'full',
      component: ArtikelsPage,
    },
    {
      path: ':artikelId',
      component: ArtikelPage,
      data: {
        standalone: false,
      },
    },
  ],
};

export const specificArtikelRoute: Route = {
  path: 'artikel/:artikelId',
  component: ArtikelPage,
  data: {
    standalone: true,
  },
};

export const hulplijnOverviewRoutes: Route = {
  path: 'hulplijnen',
  title: 'watwat.hulplijnen-page.title',
  children: [
    {
      path: '',
      pathMatch: 'full',
      component: HulplijnenPage,
    },
    {
      path: ':hulplijnId',
      component: HulplijnPage,
      data: {
        standalone: false,
      },
    },
  ],
};

export const specificHulplijnRoute: Route = {
  path: 'hulplijn/:hulplijnId',
  component: HulplijnPage,
  data: {
    standalone: true,
  },
};
