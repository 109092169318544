<ng-container *ngIf="format === 'button'; else noButton">
  <parkour-action
    (clicked)="onClick()"
    [ariaLabel]="
      ariaLabel
        ? ariaLabel
        : deelMode === 'SPECIFIEK'
          ? ('delen.delen-specifiek' | translate)
          : ('delen.delen-iedereen' | translate)
    "
    [testId]="testId"
    class="group"
  >
    <ng-container [ngTemplateOutlet]="content"></ng-container>
  </parkour-action>
</ng-container>

<ng-template #noButton>
  <ng-container [ngTemplateOutlet]="content"></ng-container>
</ng-template>

<ng-template #content>
  <div class="flex items-center gap-x-2">
    <div
      [ngClass]="{ 'p-1 rounded-sm': size === 'default', 'p-2 rounded': size === 'large' }"
      class="bg-n-400 group-hover:bg-n-300 transition-colors"
    >
      @switch (deelMode) {
        @case ('IEDEREEN') {
          <parkour-icon class="flex" name="people" />
        }
        @case ('SPECIFIEK') {
          <parkour-icon class="flex" name="lock" />
        }
      }
    </div>

    <ng-container *ngIf="actieveTeamleden$ | async as actieveTeamleden">
      <parkour-profiel-foto-badge
        *ngFor="let teamlid of getVisibleTeamleden(actieveTeamleden).slice(0, maxItems)"
        [alt]="teamlid | profielnaam"
        [profiel]="teamlid"
        [size]="size === 'default' ? '1.5' : '2'"
      />

      <span
        *ngIf="getVisibleTeamleden(actieveTeamleden).length > maxItems"
        class="typo-body variant-body-small variant-body-fat text-n-1000"
      >
        +{{ getVisibleTeamleden(actieveTeamleden).length - maxItems }}
      </span>
    </ng-container>
  </div>
</ng-template>
