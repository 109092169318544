import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import {
  ParkourIconComponent,
  ParkourLoadingSpinnerComponent,
  TParkourActionTypes,
  TParkourIconName,
} from '@parkour/ui';
import { RouterLink } from '@angular/router';
import { IonButton } from '@ionic/angular/standalone';

@Component({
  standalone: true,
  selector: 'parkour-header-action',
  templateUrl: './parkour-header-action.component.html',
  imports: [ParkourIconComponent, RouterLink, IonButton, ParkourLoadingSpinnerComponent],
})
export class ParkourHeaderActionComponent implements AfterViewInit {
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  @ViewChild('ionButton') ionButton!: any;

  @Input() public testId?: string;
  @Input() public disabled?: boolean;
  @Input() public form?: string;
  @Input() public link?: string[] | string | null;
  @Input() public type?: TParkourActionTypes = 'button';
  @Input({ required: true }) public icon!: TParkourIconName;
  @Input({ required: true }) public label!: string;
  @Input() public loading: boolean = false;

  @Output() clicked = new EventEmitter<void>();

  handleClicked() {
    this.clicked.emit();
  }

  ngAfterViewInit() {
    this.ionButton.el.removeAttribute('tabindex');
  }
}
