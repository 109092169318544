<p class="typo-body text-n-600 py-1 inline-block">
  {{ (document.startDatum | date) ?? '' }}
</p>

<div class="clickable-card-200 p-4 rounded">
  <parkour-new-indicator [show]="shouldShowNewIndicator()" />

  <div class="flex items-center gap-x-2 mb-3">
    <parkour-image
      alt="Vlaamse Overheid"
      class="block w-[1.75rem] h-[1.75rem] bg-n-1000 p-1 rounded-sm"
      fit="contain"
      src="/assets/header/vlaamse-overheid-logo.svg"
    />

    <span class="typo-body variant-body-small variant-body-fat"> Opgroeien </span>
  </div>
  <p class="typo-h5">
    <parkour-action
      [link]="['document', document.id]"
      classes="before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:z-10 focus-visible:outline-none"
    >
      {{ 'verhaal.overview-page.domino-titel' | translate }}
    </parkour-action>
  </p>

  <p class="typo-body mt-2">{{ 'verhaal.overview-page.domino-omschrijving' | translate }}</p>

  <parkour-gedeeld-met-indicator
    [deelMode]="'SPECIFIEK'"
    [gedeeldMet]="[]"
    class="border-t-[1.5px] border-n-600/30 flex pt-4 mt-4"
  />
</div>
