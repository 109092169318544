import { Component, Input } from '@angular/core';
import { WatWatOrganisation, WatWatOrganisationMethod } from 'parkour-web-app-dto';
import { NgClass, NgIf } from '@angular/common';
import { TParkourIconName } from '@parkour/ui';
import { WatWatArtikel } from '../../../shared/model/artikel';
import { HelplineMethodsComponent } from '../watwat-helplines/helpline-methods.component';

type MethodView = {
  icon: TParkourIconName;
  href: string;
  title: string;
};

@Component({
  selector: 'parkour-helpline-methods',
  standalone: true,
  imports: [NgClass, NgIf, HelplineMethodsComponent],
  templateUrl: './artikel-helpline-methods.component.html',
})
export class ArtikelHelplineMethodsComponent {
  @Input({ required: true }) artikel!: WatWatArtikel;

  getHelplineMethodView(
    helpLine: WatWatOrganisation,
    method: WatWatOrganisationMethod,
  ): MethodView {
    switch (method.type) {
      case 'chat':
        return {
          icon: 'bubble',
          title: `Chat met ${helpLine.title}`,
          href: method.url || '',
        };
      case 'activity':
        return {
          icon: 'earth',
          title: `Ga langs bij ${helpLine.title}`,
          href: method.url || '',
        };
      case 'mail':
        return {
          icon: 'email',
          title: `Mail met ${helpLine.title}`,
          href: method.email ? `mailto:${method.email}` : method.url || '',
        };
      case 'forum':
        return {
          icon: 'bubbles',
          title: `Bezoek het forum van ${helpLine.title}`,
          href: method.url || '',
        };
      case 'tel':
        return {
          icon: 'telephone',
          title: `Bel met ${helpLine.title}`,
          href: method.telephone ? `tel:${method.telephone}` : method.url || '',
        };
      case 'visit':
        return {
          icon: 'calendar',
          title: `Bekijk activiteiten van ${helpLine.title}`,
          href: method.url || '',
        };
    }
  }
}
