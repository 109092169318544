import { Routes } from '@angular/router';
import { UitgenodigdPage } from './page/uitgenodigd-page/uitgenodigd.page';
import { UitnodigingGeweigerdPage } from './page/uitnodiging-geweigerd-page/uitnodiging-geweigerd.page';
import { aboutParkourRoutes } from '../about/about.routes';

export const uitnodigingRoutes: Routes = [
  {
    path: ':uitnodigingId',
    children: [
      { path: '', component: UitgenodigdPage, pathMatch: 'full' },
      { path: 'geweigerd', component: UitnodigingGeweigerdPage },
      {
        path: 'over',
        children: aboutParkourRoutes,
      },
    ],
  },
];
