<div class="relative">
  <ng-container *ngIf="image.type === 'icon'">
    <div
      [ngClass]="classes"
      class="relative z-10 aspect-square flex items-center justify-center bg-n-200 rounded overflow-hidden"
    >
      <parkour-icon class="text-n-1000" [name]="image.name" size="3" />
    </div>
  </ng-container>

  <parkour-image
    *ngIf="image.type === 'image'"
    [alt]="image.alt ? image.alt : ''"
    [ngClass]="classes"
    [src]="image.src"
    class="relative z-10 block aspect-square rounded overflow-hidden"
  />

  <ng-container *ngIf="badgeCount">
    <span
      class="absolute z-20 typo-body variant-body-small bg-n-400 rounded-sm px-[7%] py-[5%] flex gap-x-1 items-center -right-[10%] -bottom-[10%]"
    >
      <parkour-icon class="text-n-1000" name="people" />

      {{ badgeCount }}
    </span>

    <div
      class="absolute top-0 left-0 w-full h-full bg-n-400 rounded translate-x-[5%] translate-y-[5%]"
    ></div>
  </ng-container>
</div>
