import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  combineLatest,
  filter,
  map,
  Observable,
  ReplaySubject,
  shareReplay,
  Subscription,
  switchMap,
  take,
} from 'rxjs';
import { BerichtenService } from '../../service/berichten.service';
import { Gesprek } from '../../model/gesprek';
import {
  BerichtId,
  GesprekId,
  MeldingDto,
  validateGesprekId,
  VideogesprekId,
} from 'parkour-web-app-dto';
import { ActivatedRoute, Router } from '@angular/router';
import { PusherService } from '../../service/pusher.service';
import { BerichtFooterComponent } from '../../component/bericht-footer/bericht-footer.component';
import { ContextService } from '../../../shared/services/context.service';
import { MeldingenService } from '../../../profiel/service/meldingen.service';
import { AangemeldeUser } from '../../../authentication/user';
import { getProfielnaam } from 'src/app/profiel/model/profiel-in-team';
import { asResult, combineResults, Result } from '../../../utils';
import {
  ParkourActionComponent,
  ParkourInfoCardComponent,
  ParkourLoadingSpinnerComponent,
  ParkourModalComponent,
  ParkourPageGradientComponent,
  ParkourPopupService,
} from '@parkour/ui';
import { GesprekContainerComponent } from '../../component/gesprek-container/gesprek-container-component';
import { BeeldbellenService } from '../../../beeldbellen/beeldbellen.service';
import { EenOpEenGesprek } from '../../model/eenOpEenGesprek';
import {
  BerichtenComponent,
  VideoGesprekJoinEvent,
} from '../../component/berichten/berichten.component';
import { meldingenForPage } from '../../../meldingen/config';
import { AppTitlePrefix } from '../../../app-title.prefix';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonInfiniteScroll,
  IonToolbar,
  ViewWillEnter,
  ViewWillLeave,
} from '@ionic/angular/standalone';
import { ShowIfSuccesfulDirective } from '../../../shared/directives/show-if-succesful.directive';
import { AsyncPipe, NgClass, NgIf, NgTemplateOutlet } from '@angular/common';
import { ParkourBackButtonDirective } from '../../../shared/directives/parkour-back-button.directive';
import { GesprekFotoBadgeComponent } from '../../component/gesprek-foto-badge/gesprek-foto-badge.component';
import { ProfessioneelIndicatorComponent } from '../../../shared/components/professioneel-indicator/professioneel-indicator.component';
import { ParkourHeaderActionComponent } from '../../../shared/components/parkour-header-action/parkour-header-action.component';
import { PageContentDirective } from '../../../shared/directives/page-content.directive';
import { BerichtenDataPipe } from '../../pipes/berichten-data.pipe';
import { GroepsgesprekGeblokkeerdeDeelnemerListComponent } from '../../component/groepsgesprek-geblokkeerde-deelnemer-list/groepsgesprek-geblokkeerde-deelnemer-list.component';
import { GroepsgesprekDeelnemerListComponent } from '../../component/groepsgesprek-deelnemer-list/groepsgesprek-deelnemer-list.component';
import { ProfielnaamPipe } from '../../../shared/pipes/profielnaam.pipe';
import { LoggingService } from '../../../core/logging.service';
import { Channel } from 'pusher-js';
import { Profiel } from '../../../profiel/model/profiel';
import { ProfielService } from '../../../profiel/service/profiel.service';
import AuthService from '../../../authentication/service/auth.service';
import { AnalyticsService } from '../../../analytics/analytics.service';

@Component({
  standalone: true,
  templateUrl: './bericht-gesprek-page.component.html',
  imports: [
    IonHeader,
    IonToolbar,
    IonButtons,
    IonBackButton,
    ShowIfSuccesfulDirective,
    AsyncPipe,
    ParkourBackButtonDirective,
    ParkourActionComponent,
    GesprekFotoBadgeComponent,
    ProfessioneelIndicatorComponent,
    ParkourHeaderActionComponent,
    TranslateModule,
    PageContentDirective,
    IonContent,
    ParkourPageGradientComponent,
    NgClass,
    IonInfiniteScroll,
    ParkourLoadingSpinnerComponent,
    BerichtenComponent,
    ParkourModalComponent,
    IonFooter,
    NgTemplateOutlet,
    BerichtenDataPipe,
    GroepsgesprekGeblokkeerdeDeelnemerListComponent,
    GroepsgesprekDeelnemerListComponent,
    BerichtFooterComponent,
    ParkourInfoCardComponent,
    ProfielnaamPipe,
    NgIf,
  ],
})
export class BerichtGesprekPage
  extends GesprekContainerComponent
  implements ViewWillEnter, OnInit, ViewWillLeave
{
  @ViewChild('berichtFooter') berichtFooter!: BerichtFooterComponent;
  @ViewChild('chatFlow') chatFlow!: ElementRef;
  @ViewChild('chatAnchor') chatAnchor!: ElementRef;
  @ViewChild('chatFlowScroll') chatFlowScroll!: ElementRef;
  @ViewChild('settingsModal') settingsModal!: ParkourModalComponent;

  gesprekId!: GesprekId;

  gesprek$ = new ReplaySubject<Result<Gesprek>>(1);
  data$: Observable<
    Result<{
      user: AangemeldeUser;
      gesprek: Gesprek;
      profiel: Profiel;
    }>
  > = new Observable();
  isBerichtPopoverOpened = false;
  protected readonly getProfielnaam = getProfielnaam;
  protected busyJoiningGesprek = false;
  protected readonly EenOpEenGesprek = EenOpEenGesprek;
  private videogesprekGestartSubscription?: Subscription;
  private videogesprekGestoptSubscription?: Subscription;

  constructor(
    berichtenService: BerichtenService,
    parkourPopupService: ParkourPopupService,
    notifierService: PusherService,
    activatedRoute: ActivatedRoute,
    router: Router,
    loggingService: LoggingService,
    analyticsService: AnalyticsService,
    private readonly authService: AuthService,
    private readonly contextService: ContextService,
    private readonly meldingenService: MeldingenService,
    private readonly beeldbellenService: BeeldbellenService,
    private readonly appTitlePrefix: AppTitlePrefix,
    private readonly translate: TranslatePipe,
    private readonly profielService: ProfielService,
    private route: ActivatedRoute,
  ) {
    super(
      berichtenService,
      parkourPopupService,
      notifierService,
      router,
      activatedRoute,
      loggingService,
      analyticsService,
    );
  }

  ionViewWillLeave(): void {
    this.unsubscribeFromGesprekEvents();
    this.unsubscribeFromBerichtenEvents();
    this.meldingenService.removeOngelezenMeldingenFilter(this.meldingenFilter);
  }

  ngOnInit() {
    this.gesprekId = validateGesprekId(this.activatedRoute.snapshot.paramMap.get('gesprekId'));
  }

  initializeMeldingen() {
    this.meldingenService.addOngelezenMeldingenFilter(this.meldingenFilter);
    this.meldingenService.markMeldingenInCurrentContextAsReadWithTypes(
      meldingenForPage.BERICHTEN_TAB.GESPREKKEN_PAGE.GESPREK_PAGE.specificMeldingen,
      (melding) => melding.metaData.gesprekId === this.gesprekId,
    );
  }

  fetchGesprek() {
    asResult(this.berichtenService.getGesprek(this.gesprekId)).subscribe((gesprek) => {
      this.gesprek$.next(gesprek);
      this.markShouldScrollToBottom();
    });
  }

  ionViewWillEnter(): void {
    this.initializeMeldingen();
    this.fetchGesprek();

    this.data$ = combineLatest({
      user: this.authService.getAangemeldeUser$(),
      gesprek: this.gesprek$,
      profiel: this.profielService.getCurrentUserProfiel$(),
    }).pipe(map(combineResults), shareReplay(1));

    this.data$
      .pipe(
        take(1),
        filter((data) => data.success),
      )
      .subscribe(() => {
        this.fetchInitialPage();
      }); // Fetch initial page after user and gesprek are loaded (To prevent error popup when gesprek no longer accessible)

    this.data$.subscribe((data) => {
      const categoryName = this.translate.transform('berichten.gesprek');

      if (data.success) {
        this.appTitlePrefix.setTitleWithCategory(
          data.value.gesprek.getGesprekNaam(data.value.user),
          categoryName,
        );
      } else {
        this.appTitlePrefix.setTitleWithPrefix(categoryName);
      }
    });

    this.subscribeOnBerichtenEvents(this.pusherService.profielPusherChannel$);
    this.subscribeOnGesprekEvents(this.pusherService.profielPusherChannel$);
  }

  subscribeOnGesprekEvents(channel: Observable<Channel | undefined>) {
    this.videogesprekGestartSubscription = this.pusherService
      .createBerichtenPusherSubscription(channel, 'videogesprek-gestart')
      .subscribe(() => this.fetchGesprek());

    this.videogesprekGestoptSubscription = this.pusherService
      .createBerichtenPusherSubscription(channel, 'videogesprek-gestopt')
      .subscribe(() => {
        this.fetchGesprek();
      });
  }

  unsubscribeFromGesprekEvents() {
    this.videogesprekGestartSubscription?.unsubscribe();
    this.videogesprekGestoptSubscription?.unsubscribe();
  }

  openedBerichtMessagePopover() {
    this.isBerichtPopoverOpened = true;
  }

  closedBerichtMessagePopover() {
    this.isBerichtPopoverOpened = false;
  }

  onOpenSettingsModal(gesprek: Gesprek) {
    if (gesprek.isGroepsgesprek()) {
      this.settingsModal.open();
    }
  }

  navigateToBerichtenOverview() {
    this.contextService.navigateToAbsoluteUrl(['berichten']);
  }

  getModalTitle(gesprek: Gesprek) {
    const amountOfTeamleden = gesprek.getDeelnemersAndJongere().length;

    if (amountOfTeamleden === 1) {
      return amountOfTeamleden + ' teamlid in groepsgesprek';
    } else if (amountOfTeamleden > 1) {
      return amountOfTeamleden + ' teamleden in groepsgesprek';
    } else {
      return '';
    }
  }

  onStartVideogesprek(user: Profiel, gesprek: Gesprek) {
    this.busyJoiningGesprek = true;

    if (gesprek instanceof EenOpEenGesprek && gesprek.videogesprekId) {
      this.startVideogesprek(gesprek, gesprek.videogesprekId, user, 'joined').subscribe({
        next: () => (this.busyJoiningGesprek = false),
        error: () => (this.busyJoiningGesprek = false),
      });
    } else {
      this.berichtenService
        .createVideogesprek(this.gesprekId)
        .pipe(
          switchMap((gesprek) => {
            if (!(gesprek instanceof EenOpEenGesprek) || !gesprek.videogesprekId) {
              throw new Error('Videogesprek kan niet gestart worden');
            }

            return this.startVideogesprek(gesprek, gesprek.videogesprekId, user, 'start');
          }),
        )
        .subscribe({
          next: () => (this.busyJoiningGesprek = false),
          error: () => (this.busyJoiningGesprek = false),
        });
    }
  }

  onVideogesprekJoinClick({ videogesprekId, gesprek, profiel }: VideoGesprekJoinEvent) {
    this.busyJoiningGesprek = true;

    this.startVideogesprek(gesprek, videogesprekId, profiel, 'joined').subscribe({
      next: () => (this.busyJoiningGesprek = false),
      error: () => (this.busyJoiningGesprek = false),
    });
  }

  onScrollToBottom() {
    this.markShouldScrollToBottom();
  }

  onRapporteer(event: { gesprekId: GesprekId; berichtId: BerichtId }) {
    this.router.navigate(['probleem'], {
      relativeTo: this.route,
      queryParams: {
        berichtId: event.berichtId,
        gesprekId: event.gesprekId,
      },
    });
  }

  private startVideogesprek(
    gesprek: EenOpEenGesprek,
    videogesprekId: VideogesprekId,
    profiel: Profiel,
    joinType: 'start' | 'joined',
  ) {
    return this.beeldbellenService.startVideogesprek(
      {
        jongere: gesprek.jongere,
        teamlid: gesprek.getTeamlidDeelnemer(),
        videogesprekId,
      },
      getProfielnaam(profiel),
      profiel,
      'gesprek',
      joinType,
    );
  }

  private readonly meldingenFilter = (melding: MeldingDto) => {
    if (melding.type === 'NIEUW_CHAT_BERICHT' || melding.type === 'NIEUW_CHAT_REACTIE') {
      return melding.metaData.gesprekId === this.gesprekId;
    } else {
      return false;
    }
  };
}
