import {
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FileId, ProfielId } from 'parkour-web-app-dto';
import { isFileValid } from '../../../shared/file-validation';
import { HumanReadableError } from '../../../core/human-readable-error';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { GenericResponse, VoiceRecorder } from 'capacitor-voice-recorder';
import { PermissionService } from '../../../shared/services/permission.service';
import { ParkourModalBehaviorDirective } from '../../../shared/directives/parkour-modal-behavior.directive';
import { AttachmentBericht, SpraakBericht, TextBericht } from '../../model/gesprek';
import { BerichtInputComponent } from '../bericht-input/bericht-input.component';
import { SpraakberichtInputComponent } from '../spraakbericht-input/spraakbericht-input.component';
import { FormsModule } from '@angular/forms';
import { ParkourButtonComponent, ParkourProgressComponent } from '@parkour/ui';
import { IonModal } from '@ionic/angular/standalone';
import { BerichtReplyComponent } from '../bericht-reply/bericht-reply.component';
import { CreateTekstBericht } from '../../service/berichten.service';
import { FileService, UploadFile } from '../../../shared/services/file.service';
import { map, tap } from 'rxjs';

type InputMode = 'SPRAAK' | 'TEKST' | 'ATTACHMENT';

export
@Component({
  standalone: true,
  selector: 'parkour-bericht-footer',
  templateUrl: './bericht-footer.component.html',
  styleUrls: ['./bericht-footer.component.css'],
  imports: [
    SpraakberichtInputComponent,
    BerichtInputComponent,
    FormsModule,
    ParkourButtonComponent,
    TranslateModule,
    ParkourModalBehaviorDirective,
    ParkourProgressComponent,
    IonModal,
    BerichtReplyComponent,
  ],
})
class BerichtFooterComponent implements OnChanges {
  @ViewChild('berichtInput') berichtInput?: BerichtInputComponent;
  @ViewChild('attachment', { static: true }) attachment?: ElementRef<HTMLInputElement>;
  @ViewChild('uploadModal', { static: true }) fileUploadModal!: ParkourModalBehaviorDirective;
  @Output() stuurTekstBericht = new EventEmitter<CreateTekstBericht>();
  @Output() attachmentFileUpload = new EventEmitter<FileId>();
  @Output() audioFileUpload = new EventEmitter<FileId>();

  @Output() closeReplyBericht = new EventEmitter<void>();
  busyUploadingFile = false;
  @Input({ required: true }) deelnemerId!: ProfielId;
  uploadingPercentage = 0;
  @Input({ required: true }) isReplyBerichtMode!: boolean;
  @Input({ required: true }) replyBericht!: TextBericht | AttachmentBericht | SpraakBericht;
  nieuwBerichtValue = '';
  file?: File;
  inputMode: InputMode = 'TEKST';

  private readonly fileService = inject(FileService);

  constructor(
    private readonly translateService: TranslateService,
    private readonly permissionService: PermissionService,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if ('isReplyBerichtMode' in changes && this.isReplyBerichtMode) {
      setTimeout(() => this.berichtInput?.setFocus());
    }
  }

  onBerichtInputClickedAssets() {
    const attachment = this.attachment?.nativeElement;
    if (attachment) {
      attachment.click();
    }
  }

  onStuurBericht() {
    const value = this.nieuwBerichtValue.trim();
    this.nieuwBerichtValue = '';
    this.stuurTekstBericht.emit({
      type: 'TEKST',
      deelnemerId: this.deelnemerId,
      inhoud: value,
    });
  }

  showMicrophone() {
    return this.nieuwBerichtValue === '';
  }

  onBerichtInputChangeAssets(event: Event) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const file: File = event.target!.files[0]; // eslint-disable-line @typescript-eslint/no-non-null-assertion
    if (file && isFileValid(file)) {
      this.fileUploadModal.open();
      this.file = file;
    } else {
      this.resetUploadFile();
      this.throwFileToLargeError();
    }
  }

  throwFileToLargeError() {
    this.translateService.get('file.file-too-large').subscribe((label) => {
      throw new HumanReadableError(label);
    });
  }

  resetUploadFile() {
    this.file = undefined;
    if (this.attachment) {
      this.attachment.nativeElement.value = '';
    }
    this.busyUploadingFile = false;
    this.uploadingPercentage = 0;
    this.uploadModalClose();
  }

  onUploadFile() {
    if (!this.file) {
      throw new Error('File cannot be empty');
    }
    this.busyUploadingFile = true;
    this.fileService
      .uploadFile(this.file, true)
      .pipe(
        map((uploadFile) => {
          if (
            uploadFile.status === 'progress' &&
            this.isUploadingAndMatchesLastUploadedFile(uploadFile)
          ) {
            this.uploadingPercentage = uploadFile.percentage;
          }
          return uploadFile;
        }),
      )
      .subscribe((uploadFile) => {
        if (uploadFile.status === 'error') {
          this.resetUploadFile();
        }
        if (uploadFile.status === 'done' && uploadFile.fileId) {
          this.attachmentFileUpload.emit(uploadFile.fileId);
          this.resetUploadFile();
        }
      });
  }

  private isUploadingAndMatchesLastUploadedFile(uploadFile: UploadFile) {
    return uploadFile.status === 'progress' && uploadFile.fileName === this.file?.name;
  }

  async openRecordingMode() {
    const result: GenericResponse = await VoiceRecorder.requestAudioRecordingPermission();
    if (result.value) {
      this.inputMode = 'SPRAAK';
    } else {
      await this.permissionService.showPermissionsDeniedPopup({
        errorTitle: 'PARKOUR wil je microfoon gebruiken',
        errorDescription: 'Geef toestemming voor toegang tot je microfoon in je instellingen.',
      });
    }
  }

  destroyRecording() {
    this.inputMode = 'TEKST';
  }

  sendRecording(recordedBlob: Blob) {
    const recording = new File([recordedBlob], 'recording', { type: recordedBlob.type });
    this.destroyRecording();

    if (!isFileValid(recording)) {
      this.throwFileToLargeError();
    }

    this.fileService
      .uploadAudio(recording)
      .pipe(
        tap({
          error: () => this.resetUploadFile(),
        }),
      )
      .subscribe((fileId) => this.audioFileUpload.emit(fileId));
  }

  onCloseReplyBericht() {
    this.closeReplyBericht.emit();
  }

  private uploadModalClose() {
    this.fileUploadModal.close();
  }
}
