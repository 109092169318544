import { Component, Input } from "@angular/core";
import { TAccordionItems } from "./parkour-accordion.types";
import { NgForOf } from "@angular/common";
import { ParkourIconComponent } from "../parkour-icon/parkour-icon.component";
import { IonAccordion, IonAccordionGroup } from "@ionic/angular/standalone";

@Component({
  standalone: true,
  selector: "parkour-accordion",
  templateUrl: "./parkour-accordion.component.html",
  imports: [NgForOf, ParkourIconComponent, IonAccordion, IonAccordionGroup],
})
export class ParkourAccordionComponent {
  @Input({ required: true }) items: TAccordionItems = [];
}
