import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CreateProbleemDto, ProbleemMetaData } from 'parkour-web-app-dto';
import { from, map, Observable, of, switchMap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { asType, isNativeApp, stripNullProperties } from '../../utils';
import { App, AppInfo } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { CreateDefaultProbleem } from '../model/probleem';
import { CreateBerichtProbleem } from '../../bericht/model/berichtProbleem';

@Injectable({
  providedIn: 'root',
})
export class ProbleemService {
  constructor(private readonly http: HttpClient) {}

  addProbleem(probleem: CreateDefaultProbleem | CreateBerichtProbleem): Observable<void> {
    const metaData: Observable<ProbleemMetaData> = this.gatherMetaData();

    return metaData.pipe(
      switchMap((data) => {
        return this.http.post<void>(
          `${environment.API_BASE_URL}/api/problemen`,
          asType<CreateProbleemDto>(
            stripNullProperties({
              ...probleem,
              metaData: data,
            }),
          ),
        );
      }),
    );
  }

  gatherMetaData(): Observable<ProbleemMetaData> {
    const appInfo: Observable<AppInfo> = isNativeApp()
      ? from(App.getInfo())
      : of({
          version: 'latest',
          name: '',
          id: '',
          build: '',
        });

    const os = Capacitor.getPlatform();

    return appInfo.pipe(
      map((info) => ({
        os: os,
        appVersion: info.version,
      })),
    );
  }
}
