import { Component, Input, OnInit } from '@angular/core';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { TextBerichtDto } from 'parkour-web-app-dto';
import { AbstractGesprek } from '../../model/abstractGesprek';
import { BerichtenDatePipe } from '../../pipes/berichten-date.pipe';
import { linkify, LinkifyPipe } from '../../pipes/linkify.pipe';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { ProfielnaamPipe } from '../../../shared/pipes/profielnaam.pipe';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ProfielPipe } from '../../../shared/pipes/profiel.pipe';
import { getProfielnaam } from '../../../profiel/model/profiel-in-team';
import { ProfielService } from '../../../profiel/service/profiel.service';

@Component({
  selector: 'parkour-bericht-text-content',
  standalone: true,
  imports: [
    AsyncPipe,
    NgIf,
    BerichtenDatePipe,
    LinkifyPipe,
    TranslateModule,
    NgClass,
    ProfielnaamPipe,
    ProfielPipe,
  ],
  templateUrl: './bericht-text-content.component.html',
})
export class BerichtTextContentComponent implements OnInit {
  @Input({ required: true }) bericht!: TextBerichtDto;
  @Input() isUser: boolean = false;
  @Input({ required: true }) gesprek!: AbstractGesprek;
  @Input() isNameVisible = false;
  @Input() isTruncatedVisible = false;

  safeHtmlContent!: SafeHtml;
  htmlContent!: string;

  constructor(
    private readonly sanitizer: DomSanitizer,
    private readonly translate: TranslatePipe,
    private readonly profielService: ProfielService,
  ) {}

  ngOnInit() {
    if (!this.isUser) {
      this.profielService.getProfielInCurrentTeam(this.bericht.deelnemerId).subscribe((profiel) => {
        this.htmlContent =
          `<span class="sr-only">${getProfielnaam(profiel)}: </span>` +
          linkify(this.bericht.inhoud);

        this.safeHtmlContent = this.sanitizer.bypassSecurityTrustHtml(this.htmlContent);
      });
    } else {
      this.htmlContent =
        `<span class="sr-only">${this.translate.transform('berichten.gesprek-page.me')}: </span>` +
        linkify(this.bericht.inhoud);

      this.safeHtmlContent = this.sanitizer.bypassSecurityTrustHtml(this.htmlContent);
    }
  }
}
