<ul
  [attr.aria-activedescendant]="activeIndex"
  class="bg-n-100 rounded border-[1.5px] border-n-500 divide-y-[1.5px] divide-n-500"
  role="listbox"
>
  @for (context of contexten; let index = $index; track context.contextId) {
    <li
      [ngClass]="{ 'bg-n-300 hover:bg-n-300': activeIndex === index }"
      class="hover:bg-n-200 first:rounded-tr first:rounded-tl last:rounded-br last:rounded-bl transition-colors"
      role="option"
      [id]="index"
      [attr.aria-selected]="activeIndex === index"
    >
      <parkour-action
        (clicked)="onContextSelected(context)"
        [ariaLabel]="
          context.contextId !== profielId
            ? (context.rol | rolLabelDefault) + ' van ' + context.teamOwnerNaam
            : isJongere
              ? ('team.wisselen.mijnteam-jongere' | translate)
              : ('team.wisselen.mijnteam-geen-jongere' | translate)
        "
        classes="flex items-center justify-between p-4 w-full"
      >
        @if (context.contextId !== profielId) {
          <parkour-team-switch-card-content
            [rol]="context.rol"
            [naam]="context.teamOwnerNaam"
            [profiel]="
              context.geblokkeerd
                ? { status: 'GEBLOKKEERD' }
                : {
                    status: 'ACTIEF',
                    id: context.contextId,
                    profielFotoId: context.profielFotoId,
                  }
            "
            [showNewIndicator]="(this.hasMeldingenInContext(context.contextId) | async) ?? false"
          />
        } @else {
          <div class="flex items-center gap-x-3">
            <div class="relative">
              <parkour-badge icon="people-2" iconSize="3" />

              <parkour-new-indicator
                class="[--notification-outline-color:transparent]"
                [show]="(this.hasMeldingenInContext(context.contextId) | async) ?? false"
              />
            </div>

            <span class="typo-body variant-body-fat">
              @if (isJongere) {
                {{ 'team.wisselen.mijnteam-jongere' | translate }}
              } @else {
                {{ 'team.wisselen.mijnteam-geen-jongere' | translate }}
              }
            </span>
          </div>
        }

        @if (activeIndex === index) {
          <parkour-icon class="text-positive" name="check" size="3" />
        }
      </parkour-action>
    </li>
  }
</ul>
