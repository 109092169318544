<ion-popover
  #popover="parkourPopover"
  (willDismiss)="willDismiss()"
  (willPresent)="willPresent()"
  [showBackdrop]="false"
  [trigger]="triggerId"
  alignment="end"
  parkourPopover
  side="right"
  style="--offset-x: 0.625rem"
>
  <ng-template>
    <div class="bg-n-400 rounded w-[14.25rem] relative">
      <parkour-action (click)="popover.close()" ariaLabel="sluiten" class="absolute top-2 right-3">
        <parkour-icon class="text-n-1000" name="cross" />
      </parkour-action>

      <ul class="space-y-1 pt-8 pl-3 pr-3 pb-3 mt-2">
        <parkour-page-layout-sidebar-nav-item
          [link]="['profiel', 'mijn']"
          [showNewIndicator]="hasMijnProfielMeldingen$ | async"
          class="block"
          icon="people-3"
          text="Profiel"
        />

        <parkour-page-layout-sidebar-nav-item
          [link]="['profiel', 'meldingen']"
          [showNewIndicator]="hasMeldingenPageMeldingen$ | async"
          class="block"
          icon="notifications"
          text="Meldingen"
        />

        <parkour-page-layout-sidebar-nav-item
          [link]="['profiel', 'kalender']"
          [showNewIndicator]="hasKalenderMeldingen$ | async"
          class="block"
          icon="calendar"
          text="Kalender"
        />

        <parkour-page-layout-sidebar-nav-item
          [link]="['profiel', 'bewaard']"
          class="block"
          icon="bookmark"
          text="Bewaard"
        />

        <parkour-page-layout-sidebar-nav-item
          [link]="['profiel', 'over', 'parkour']"
          class="block"
          icon="info-circle"
          text="Over PARKOUR"
        />

        <parkour-page-layout-sidebar-nav-item
          [link]="['profiel', 'probleem']"
          [text]="'profiel.probleem-page.title' | translate"
          class="block"
          icon="alert-triangle"
        />
      </ul>

      <div class="p-3 border-t-[2px] border-n-600">
        <parkour-action (clicked)="closeUitloggen()" classes="flex items-center w-full gap-4">
          <parkour-badge class="text-n-1000" icon="logout" size="1" theme="negative" width="w-8" />
          <p class="typo-body variant-body-fat text-n-1000">
            {{ 'profiel-nav.logout' | translate }}
          </p>
        </parkour-action>
      </div>
    </div>
  </ng-template>
</ion-popover>
