<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button parkourBackButton />
    </ion-buttons>

    <ion-title aria-level="1" role="heading"
      >{{ 'profiel.notitie-edit-page.title' | translate }}
    </ion-title>

    <ion-buttons slot="end">
      <parkour-header-action
        *ngIf="notitie$ | async"
        [label]="'bewaard.notitie-save' | translate"
        form="notitie-form"
        icon="disk"
        testId="submit"
        type="submit"
      />
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <parkour-page-layout>
    <parkour-page-gradient />

    <div class="relative z-10 pt-8 container container-space flex flex-col gap-y-8 h-full">
      @if (notitieId) {
        <parkour-notitie-form
          (formSubmit)="onNotitieSubmit(notitieId, $event)"
          #notitieForm
          class="container-1-col"
          type="edit"
        />

        <div class="pt-6 pb-8 bg-n-200 full-bleed container-space mt-auto">
          <div class="container-1-col">
            <h2 class="typo-h3">{{ 'profiel.notitie-edit-page.verwijderen-titel' | translate }}</h2>

            <p class="mt-2">
              {{ 'profiel.notitie-edit-page.verwijderen-description' | translate }}
            </p>

            <parkour-button
              (clicked)="onDeleteClick(notitieId)"
              class="w-full block mt-4"
              iconAfter="trash"
              testId="notitie-delete"
              variant="tertiary"
            >
              {{ 'bewaard.notitie-delete-button' | translate }}
            </parkour-button>
          </div>
        </div>
      }
    </div>
  </parkour-page-layout>
</ion-content>
