import { Component, Input } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { Observable } from 'rxjs';
import { BewaardeItemsService } from '../../service/bewaarde-items.service';
import { NotitieComponent } from '../notitie-card/notitie.component';
import { CoreModule } from '../../../core/core.module';
import {
  ParkourBlockPlaceholderComponent,
  ParkourButtonComponent,
  ParkourLoadingSpinnerComponent,
} from '@parkour/ui';
import { Notitie } from '../../model/notitie';
import { asResult, Result } from '../../../utils';
import { PageSectionDirective } from '../../../shared/directives/page-section.directive';

@Component({
  selector: 'parkour-bewaarde-notities',
  standalone: true,
  imports: [
    AsyncPipe,
    CoreModule,
    NotitieComponent,
    ParkourButtonComponent,
    ParkourBlockPlaceholderComponent,
    ParkourLoadingSpinnerComponent,
    PageSectionDirective,
  ],
  templateUrl: './bewaarde-notities.component.html',
})
export class BewaardeNotitiesComponent {
  @Input({ required: true }) active!: boolean;
  notities$ = new Observable<Result<Notitie[]>>();

  constructor(private readonly bewaardeItemsService: BewaardeItemsService) {}

  initializeData() {
    this.notities$ = asResult(this.bewaardeItemsService.getNotities());
  }
}
