import { Pipe, PipeTransform } from '@angular/core';
import { map, Observable } from 'rxjs';
import { DoelId, MeldingOfType } from 'parkour-web-app-dto';

@Pipe({
  standalone: true,
  name: 'hasMeldingenForDoel',
})
export class HasMeldingenForDoelPipe implements PipeTransform {
  transform(
    meldingen$: Observable<
      MeldingOfType<
        | 'DOEL_GEDEELD'
        | 'DOEL_EMOJI_REACTIE_ADDED'
        | 'DOEL_VOLTOOID'
        | 'DOEL_NIEUW_BERICHT'
        | 'DOEL_NIEUW_BERICHT_REACTIE'
      >[]
    >,
    doelId: DoelId,
  ): Observable<boolean> {
    return meldingen$.pipe(
      map((meldingen) => meldingen.some((melding) => melding.metaData.doelId === doelId)),
    );
  }
}
