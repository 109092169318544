import { Component } from '@angular/core';
import {
  ParkourActionComponent,
  ParkourButtonComponent,
  ParkourDividerComponent,
  ParkourIconComponent,
  ParkourPageGradientComponent,
  ParkourToastService,
  ParkourToggleComponent,
} from '@parkour/ui';
import { AsyncPipe } from '@angular/common';
import { finalize, from, map, of, switchMap } from 'rxjs';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
  ViewWillEnter,
} from '@ionic/angular/standalone';
import { FormsModule } from '@angular/forms';
import { ParkourBackButtonDirective } from '../../../shared/directives/parkour-back-button.directive';
import { TranslateModule } from '@ngx-translate/core';
import { PageLayoutComponent } from '../../../shared/components/page-layout/page-layout.component';
import { ParkourRefreshDirective } from '../../../shared/directives/parkour-refresh-directive';
import { ParkourPage } from '../../../shared/parkour-page';
import { isNativeApp } from '../../../utils';
import { App } from '@capacitor/app';
import { BiometricsService } from '../../../authentication/service/biometrics.service';
import { environment } from '../../../../environments/environment';
import AuthService from '../../../authentication/service/auth.service';
import { LoggingService } from '../../../core/logging.service';
import { FileService } from '../../../shared/services/file.service';
import { Directory, Encoding } from '@capacitor/filesystem';

@Component({
  selector: 'parkour-instellingen',
  standalone: true,
  imports: [
    AsyncPipe,
    ParkourButtonComponent,
    ParkourToggleComponent,
    FormsModule,
    IonBackButton,
    IonButtons,
    IonHeader,
    IonTitle,
    IonToolbar,
    ParkourBackButtonDirective,
    TranslateModule,
    IonRefresher,
    IonRefresherContent,
    PageLayoutComponent,
    IonContent,
    ParkourRefreshDirective,
    ParkourDividerComponent,
    ParkourPageGradientComponent,
    ParkourActionComponent,
    ParkourIconComponent,
  ],
  templateUrl: './instellingen.page.html',
})
export class InstellingenPage extends ParkourPage implements ViewWillEnter {
  biometricsEnabled: boolean = false;
  appInfo$ = isNativeApp() ? from(App.getInfo()) : of({ version: 'web' });

  constructor(
    private readonly biometricsService: BiometricsService,
    private readonly toastService: ParkourToastService,
    private readonly authService: AuthService,
    private readonly loggingService: LoggingService,
    private readonly fileService: FileService,
  ) {
    super();
  }

  override ionViewWillEnter() {
    super.ionViewWillEnter();
    this.refreshBiometricsEnabled();
  }

  enableBiometrics() {
    this.authService
      .getAangemeldeUser$()
      .pipe(
        switchMap((user) => this.biometricsService.enableBiometrics(user.persoonId)),
        switchMap(() =>
          this.translateService.get([
            'biometrics.configure-biometrics.success-title',
            'biometrics.configure-biometrics.success-description',
          ]),
        ),
        switchMap((translations) =>
          this.toastService.showToast({
            header: translations['biometrics.configure-biometrics.success-title'],
            content: translations['biometrics.configure-biometrics.success-description'],
            error: false,
          }),
        ),
        finalize(() => this.refreshBiometricsEnabled()),
      )
      .subscribe();
  }

  disableBiometrics() {
    this.authService
      .getAangemeldeUser$()
      .pipe(
        switchMap((user) => this.biometricsService.disableBiometrics(user.persoonId)),
        map(() => this.refreshBiometricsEnabled()),
      )
      .subscribe();
  }

  refreshBiometricsEnabled() {
    this.authService
      .getCurrentUser$()
      .pipe(switchMap((user) => this.biometricsService.getStatusForUser(user)))
      .subscribe((status) => (this.biometricsEnabled = status === 'INGESTELD'));
  }

  async onToggleChanged(enabled: boolean) {
    if (enabled) {
      this.enableBiometrics();
    } else {
      this.disableBiometrics();
    }
  }

  protected readonly isNativeApp = isNativeApp;

  onClearBiometrics() {
    this.biometricsService
      .clearBiometrics()
      .pipe(finalize(() => this.refreshBiometricsEnabled()))
      .subscribe(() => this.toastService.showToast({ header: 'Biometrics cleared' }));
  }

  onSaveLogClick() {
    this.fileService.downloadFile(
      this.loggingService.getFullLog(),
      `log-${Date.now()}.txt`,
      Directory.Documents,
      Encoding.UTF8,
    );
  }

  protected readonly environment = environment;
}
