import { Component, Input } from '@angular/core';
import { SidebarWisselTeamPopoverComponent } from '../sidebar-wissel-team-popover/sidebar-wissel-team-popover.component';
import { JongereContext } from '../../../model/context';
import { ContextOption } from '../../../../profiel/model/context-option';
import { AsyncPipe } from '@angular/common';
import { MeldingenService } from '../../../../profiel/service/meldingen.service';
import { TranslateModule } from '@ngx-translate/core';
import { meldingenForTeamPage } from '../../../../meldingen/config';
import { ParkourButtonComponent, ParkourNewIndicatorComponent } from '@parkour/ui';

@Component({
  selector: 'parkour-sidebar-jongere-context-card',
  standalone: true,
  imports: [
    SidebarWisselTeamPopoverComponent,
    AsyncPipe,
    TranslateModule,
    ParkourNewIndicatorComponent,
    ParkourButtonComponent,
  ],
  templateUrl: './sidebar-jongere-context-card.component.html',
})
export class SidebarJongereContextCardComponent {
  @Input({ required: true }) context!: JongereContext;
  @Input({ required: true }) mijnContexten!: ContextOption[];

  isTeamSwitchPopoverOpen = false;

  wisselTeamIndicator$ = this.meldingenService.hasWisselTeamMeldingen();
  hasMijnTeamMeldingen$ = this.meldingenService.hasMeldingenInCurrentContextByType(
    meldingenForTeamPage.allMeldingen,
  );

  constructor(private readonly meldingenService: MeldingenService) {}
}
