<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button parkourBackButton />

      <ng-container *parkourShowIfSuccessful="mainPageData$ | async; let data">
        <div class="flex overflow-hidden">
          <div class="flex items-center gap-x-2 ml-4 overflow-hidden flex-1 pr-4">
            <parkour-gesprek-foto-badge
              [gesprek]="data.gesprek"
              [imageOverride]="imageBase64$ | async"
              [overrideImage]="data.context.type === 'jongere'"
              [size]="'2'"
              [user]="data.user"
            />

            <div class="grid overflow-hidden">
              <h1 class="typo-body variant-body-fat truncate" data-testid="gesprekspartner-naam">
                {{ data.gesprek.getGesprekNaam(data.user) }}
              </h1>

              <span class="typo-body variant-body-small text-p-300 flex align-center gap-x-1">
                <span>{{ data.gesprek.getSubtitleForHeader(data.user) }}</span>

                @if (data.gesprek.getVisibleDeelnemer(data.user.profielId); as visibleDeelnemer) {
                  @if (
                    visibleDeelnemer.status === 'ACTIEF' &&
                    visibleDeelnemer.type === 'PROFESSIONEEL_TEAMLID'
                  ) {
                    <parkour-professioneel-indicator />
                  }
                }
              </span>
            </div>
          </div>
        </div>
      </ng-container>
    </ion-buttons>

    <ion-buttons class="flex-none" slot="end">
      <ng-container *parkourShowIfSuccessful="mainPageData$ | async; let data">
        @if (data.user.profielId === data.gesprek.jongere.id) {
          <parkour-header-action
            [label]="'berichten.groepsgesprek-edit-page.gesprek-save' | translate"
            form="gesprek-form"
            icon="disk"
            type="submit"
          />
        }
      </ng-container>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-refresher [parkourRefresh]="onPageRefresh" slot="fixed">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>

  <parkour-page-layout>
    <parkour-page-gradient></parkour-page-gradient>

    <ng-container *parkourPageContent="mainPageData$ | async; refresh: onPageRefresh; let data">
      <div class="relative container container-space flex flex-col gap-y-8 h-full">
        <div class="container-1-col">
          <div class="flex flex-col items-center gap-y-4 pt-8">
            <parkour-form
              [errorLabelMapping]="false"
              [formGroup]="gesprekForm"
              [formId]="'gesprek-form'"
              [submitObservableGenerator]="submitGesprekForm"
            >
              <div class="flex flex-col items-center gap-y-6">
                <parkour-gesprek-foto-badge
                  [gesprek]="data.gesprek"
                  [imageOverride]="imageBase64$ | async"
                  [overrideImage]="data.context.type === 'jongere'"
                  [size]="'5'"
                  [user]="data.user"
                />

                @if (data.user.profielId === data.gesprek.jongere.id) {
                  <parkour-custom-foto-edit
                    [formControl]="gesprekForm.controls.imageData"
                    [fotoName]="'gesprek-foto'"
                    [mode]="'edit'"
                    [existingImageGetFunction]="getGesprekFoto"
                  />
                }
              </div>
            </parkour-form>
            <div class="flex flex-col items-center gap-y-1">
              <p class="text-center typo-h4">{{ data.gesprek.getSubtitleForHeader(data.user) }}</p>
            </div>
          </div>

          <div class="flex flex-col gap-y-2">
            <h2 class="typo-h3 mb-2">
              {{ 'berichten.groepsgesprek-edit-page.deelnemers' | translate }}
            </h2>

            <parkour-card class="mb-2" theme="n-200">
              <parkour-groepsgesprek-deelnemer-list [gesprek]="data.gesprek" />
            </parkour-card>

            @if (data.gesprek.getGeblokkeerdeDeelnemers().length > 0) {
              <parkour-card theme="n-150">
                <parkour-groepsgesprek-geblokkeerde-deelnemer-list [gesprek]="data.gesprek" />
              </parkour-card>
            }
          </div>
        </div>

        @if (data.gesprek.status === 'ACTIEF' && data.context.type === 'jongere') {
          <div class="pl-4 pr-4 bg-n-200 full-bleed container-space mt-auto">
            <div class="flex flex-col gap-y-8 container-1-col">
              <div class="space-y-2 pt-6">
                <h3>{{ 'berichten.groepsgesprek-edit-page.stopzetten-title' | translate }}</h3>

                <p>{{ 'berichten.groepsgesprek-edit-page.stopzetten-description' | translate }}</p>
              </div>

              <parkour-button
                (clicked)="onGroepsgesprekStopzetten()"
                class="block w-full mb-7"
                iconAfter="logout"
                variant="tertiary"
                >{{ 'berichten.groepsgesprek-edit-page.stopzetten-button' | translate }}
              </parkour-button>
            </div>
          </div>
        } @else if (data.context.type === 'teamlid') {
          <div class="pl-4 pr-4 bg-n-200 full-bleed container-space mt-auto">
            <div class="flex flex-col gap-y-8 container-1-col">
              <div class="space-y-2 pt-6">
                <h2 class="typo-h3">
                  {{ 'berichten.groepsgesprek-edit-page.verlaten-title' | translate }}
                </h2>

                <p>{{ 'berichten.groepsgesprek-edit-page.verlaten-description' | translate }}</p>
              </div>

              <parkour-button
                (clicked)="onGroepsgesprekVerlaten()"
                class="block w-full mb-7"
                iconAfter="logout"
                variant="tertiary"
                >{{ 'berichten.groepsgesprek-edit-page.verlaten-button' | translate }}
              </parkour-button>
            </div>
          </div>
        }
      </div>
    </ng-container>
  </parkour-page-layout>
</ion-content>
