import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TParkourBookmarkButtonVariants } from './bookmark-button.types';
import { TranslatePipe } from '@ngx-translate/core';
import { isNativeApp } from '../../../utils';
import { Haptics, ImpactStyle } from '@capacitor/haptics';
import { ParkourButtonComponent } from '@parkour/ui';
import { NgClass } from '@angular/common';
import { IsUserWithProfielDirective } from '../../directives/is-user-with-profiel.directive';

@Component({
  standalone: true,
  selector: 'parkour-bookmark-button',
  templateUrl: './bookmark-button.component.html',
  imports: [ParkourButtonComponent, NgClass, IsUserWithProfielDirective],
})
export class BookmarkButtonComponent {
  @Input() public classes?: string;
  @Input() public isActive: boolean = false;
  @Input() public variant: TParkourBookmarkButtonVariants = 'iconButton';

  @Output() public clicked: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private readonly translate: TranslatePipe) {}

  getLabel() {
    return this.isActive
      ? this.translate.transform('buttons.saved')
      : this.translate.transform('buttons.save');
  }

  getAriaLabelIconButton() {
    return this.isActive
      ? this.getLabel() + '; ' + this.translate.transform('buttons.bookmark-delete')
      : this.getLabel();
  }

  getAriaLabelButton() {
    return this.isActive ? this.translate.transform('buttons.bookmark-delete') : '';
  }

  async onClick() {
    this.isActive = !this.isActive;

    if (this.isActive && isNativeApp()) {
      await Haptics.impact({ style: ImpactStyle.Light });
    }

    this.clicked.emit(this.isActive);
  }
}
