import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, map, mergeMap, Observable, switchMap, take, tap } from 'rxjs';
import {
  InteractieEmoji,
  ReactieDto,
  ReactieId,
  ReactieOverviewDto,
  UpdateReactieDto,
} from 'parkour-web-app-dto';
import { DoelId } from '../model/doel';
import { InteractieOverview } from '../model/interactie-overview';
import { ContextService } from '../../../shared/services/context.service';
import { environment } from '../../../../environments/environment';
import { AnalyticsService } from '../../../analytics/analytics.service';
import { AnalyticsEvent, trackAnalyticsEvent } from '../../../analytics/analytics-event.model';

@Injectable({ providedIn: 'root' })
export class DoelReactieService {
  private reacties$: BehaviorSubject<InteractieOverview> = new BehaviorSubject(
    new InteractieOverview([], undefined),
  );

  constructor(
    private http: HttpClient,
    private readonly contextService: ContextService,
    private readonly analyticsService: AnalyticsService,
  ) {}

  getReactiesForDoel(doelId: DoelId): Observable<InteractieOverview> {
    this.updateReacties(doelId);
    return this.reacties$.asObservable();
  }

  updateReactie(reactieEmoji: InteractieEmoji, doelId: DoelId): Observable<ReactieDto> {
    const body: UpdateReactieDto = { value: reactieEmoji };
    return this.contextService.contextIdOfJongere$().pipe(
      mergeMap((contextId) =>
        this.http.put<ReactieDto>(
          `${environment.API_BASE_URL}/api/jongere/${contextId}/doelen/${doelId}/reactie`,
          body,
        ),
      ),
      tap(() => this.updateReacties(doelId)),
      trackAnalyticsEvent(
        this.analyticsService,
        new AnalyticsEvent('doelen', 'doelReactieToegevoegd', reactieEmoji),
      ),
    );
  }

  verwijderReactie(reactie: ReactieDto, doelId: DoelId): Observable<ReactieId> {
    return this.contextService.contextIdOfJongere$().pipe(
      mergeMap((contextId) =>
        this.http.delete(
          `${environment.API_BASE_URL}/api/jongere/${contextId}/doelen/${doelId}/reactie/${reactie.id}`,
          { responseType: 'text' },
        ),
      ),
      tap(() => this.updateReacties(doelId)),
      trackAnalyticsEvent(
        this.analyticsService,
        new AnalyticsEvent('doelen', 'doelReactieVerwijderd'),
      ),
    );
  }

  private updateReacties(doelId: DoelId) {
    this.contextService
      .contextIdOfJongere$()
      .pipe(
        switchMap((contextId) =>
          this.http.get<ReactieOverviewDto>(
            `${environment.API_BASE_URL}/api/jongere/${contextId}/doelen/${doelId}/reactie`,
          ),
        ),
        take(1),
        map((dto) => InteractieOverview.fromDto(dto)),
        tap((reactieOverview) => this.reacties$.next(reactieOverview)),
      )
      .subscribe();
  }
}
