import { Component } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { DoelListItemComponent } from '../../doel-shared/component/doel-list-item/doel-list-item.component';
import { DoelVoorstelComponent } from '../../doel-shared/component/doel-voorstel/doel-voorstel.component';
import {
  IonContent,
  IonHeader,
  IonRefresher,
  IonRefresherContent,
} from '@ionic/angular/standalone';
import { PageContentDirective } from '../../../shared/directives/page-content.directive';
import { PageLayoutComponent } from '../../../shared/components/page-layout/page-layout.component';
import { PageSectionDirective } from '../../../shared/directives/page-section.directive';
import { PageWithHeroComponent } from '../../../shared/components/page-with-hero/page-with-hero.component';
import {
  ParkourBlockPlaceholderComponent,
  ParkourButtonComponent,
  ParkourSegmentComponent,
} from '@parkour/ui';
import { TranslateModule } from '@ngx-translate/core';
import { ParkourRefreshDirective } from '../../../shared/directives/parkour-refresh-directive';
import { doelHeroSources } from '../../doel-shared/constants';
import { Observable, of } from 'rxjs';
import { Doel, DoelSuggestie } from '../../doel-shared/model/doel';
import { DoelListComponent } from '../../doel-shared/component/doel-list/doel-list.component';
import { DoelenPlaceholderComponent } from '../../doel-shared/component/doelen-placeholder/doelen-placeholder.component';
import { DoelOverviewPage } from '../../doel-shared/page/doel-overview/doel-overview-page';
import { HasMeldingenForDoelPipe } from '../../doel-shared/pipe/has-meldingen-for-doel.pipe';
import { ExJongereInfoCardComponent } from '../../../shared/components/ex-jongere-info-card/ex-jongere-info-card.component';

@Component({
  standalone: true,
  imports: [
    AsyncPipe,
    DoelListItemComponent,
    DoelVoorstelComponent,
    HasMeldingenForDoelPipe,
    IonContent,
    IonHeader,
    IonRefresher,
    IonRefresherContent,
    PageContentDirective,
    PageLayoutComponent,
    PageSectionDirective,
    PageWithHeroComponent,
    ParkourBlockPlaceholderComponent,
    ParkourButtonComponent,
    ParkourSegmentComponent,
    TranslateModule,
    ParkourRefreshDirective,
    DoelListComponent,
    DoelenPlaceholderComponent,
    ExJongereInfoCardComponent,
  ],
  templateUrl: './doelen-ex-jongere.page.html',
})
export class DoelenExJongerePage extends DoelOverviewPage {
  protected readonly doelHeroSources = doelHeroSources;

  override getActieveDoelen(): Observable<Doel[]> {
    return this.doelenService.getActieveDoelen();
  }

  override getBehaaldeDoelen(): Observable<Doel[]> {
    return this.doelenService.getBehaaldeDoelen();
  }

  override getDoelSuggesties(): Observable<DoelSuggestie[]> {
    return of([]);
  }
}
