import { ApiProperty } from '@nestjs/swagger';
import { ProfielId } from './profiel.dto';

export class DrieKolommenDocumentDto {
  readonly id!: string;
  @ApiProperty({ type: String })
  readonly jongereProfielId!: ProfielId;
  readonly eyouthRef!: string;
  readonly startDatum!: string;
  readonly rubrieken!: Array<DrieKolommenRubriek>;
  readonly type!: 'DRIE_KOLOMMEN';
}

export type Rubriek = 'ZORGEN' | 'KRACHTEN' | 'ACTIES';

export class DrieKolommenRubriek {
  @ApiProperty({ type: String })
  readonly rubriek!: Rubriek;
  readonly subrubriek!: string;
  readonly inhoud!: string;
}
