<ion-header></ion-header>

<ion-content>
  <parkour-page-layout>
    <parkour-image
      classes="absolute top-0 left-0 z-0 w-full h-full overflow-hidden"
      src="/assets/login/background.png"
    />

    <div class="relative z-10 py-8 container container-space h-full flex flex-col">
      <section class="flex flex-col gap-y-8 container-1-col">
        <div class="flex flex-col gap-y-6">
          <h1 class="text-center">{{ 'afmelden.title' | translate }}</h1>

          <parkour-divider></parkour-divider>
        </div>
      </section>

      <section class="flex-1 container-1-col">
        <parkour-loading-spinner classes="w-14 h-14"></parkour-loading-spinner>
      </section>

      <section class="flex flex-col pb-10 gap-y-8 container-1-col">
        <p class="text-center self-center">
          {{ 'afmelden.logout-description' | translate }}
        </p>
        <parkour-button (click)="onRetryClick()" class="w-full">
          {{ 'afmelden.retry-button' | translate }}
        </parkour-button>

        <parkour-button link="/" [variant]="'tertiary'" class="w-full">
          {{ 'buttons.annuleren' | translate }}
        </parkour-button>
      </section>
    </div>
  </parkour-page-layout>
</ion-content>
