import { MeldingOfType, MeldingType } from 'parkour-web-app-dto';
import { MeldingenMapType } from '../profiel/service/meldingen.service';
import { Observable } from 'rxjs';

export class MeldingenToMarkAsRead<T extends MeldingType> {
  constructor(
    readonly meldingen: MeldingenMapType<T>,
    readonly filter?: (melding: MeldingOfType<T>) => boolean,
  ) {}
}

// Interface for pages that mark certain meldingen as read on open
export interface MarksMeldingenAsReadOnView<MeldingenToMarkAsReadType extends MeldingType = never> {
  getMeldingenToMarkAsRead(): Observable<MeldingenToMarkAsRead<MeldingenToMarkAsReadType>>;
}

export const implementsMarksMeldingenAsReadOnView = <MeldingenToMarkAsReadType extends MeldingType>(
  page: object,
): page is MarksMeldingenAsReadOnView<MeldingenToMarkAsReadType> => {
  return 'getMeldingenToMarkAsRead' in page;
};
