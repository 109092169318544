import { Component } from '@angular/core';
import { berichtenHero } from '../../model/berichten-hero';
import { PageWithHeroComponent } from '../../../shared/components/page-with-hero/page-with-hero.component';
import { AsyncPipe, NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ParkourBlockPlaceholderComponent } from '@parkour/ui';
import {
  IonContent,
  IonHeader,
  IonRefresher,
  IonRefresherContent,
} from '@ionic/angular/standalone';
import { PageLayoutComponent } from '../../../shared/components/page-layout/page-layout.component';
import { ParkourRefreshDirective } from '../../../shared/directives/parkour-refresh-directive';
import { ProfielService } from '../../../profiel/service/profiel.service';
import { ParkourDataPage } from '../../../shared/parkour-data-page';
import { MijnProfiel } from '../../../profiel/model/profiel';
import { Observable } from 'rxjs';
import { PageContentDirective } from '../../../shared/directives/page-content.directive';
import { BerichtenPlaceholderComponent } from '../../component/berichten-placeholder/berichten-placeholder.component';

@Component({
  standalone: true,
  templateUrl: './berichten-niet-in-team.page.html',
  imports: [
    IonHeader,
    IonContent,
    PageWithHeroComponent,
    AsyncPipe,
    TranslateModule,
    ParkourBlockPlaceholderComponent,
    PageLayoutComponent,
    NgIf,
    IonRefresher,
    IonRefresherContent,
    ParkourRefreshDirective,
    PageContentDirective,
    BerichtenPlaceholderComponent,
  ],
})
export class BerichtenNietInTeamPage extends ParkourDataPage<MijnProfiel> {
  constructor(private readonly profielService: ProfielService) {
    super();
  }

  override retrieveMainData(): Observable<MijnProfiel> {
    return this.profielService.getCurrentUserProfiel$();
  }

  protected readonly berichtenHero = berichtenHero;
}
