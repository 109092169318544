import { Component, Input } from '@angular/core';
import { DelenSectionGroup } from '../../model/delen-form-group';
import { TeamlidProfiel } from '../../../team/model/teamlid.model';
import { ReactiveFormsModule } from '@angular/forms';
import { ParkourInfoCardComponent, ParkourRadioComponent } from '@parkour/ui';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf } from '@angular/common';
import { GedeeldMetItemComponent } from '../gedeeld-met-item/gedeeld-met-item.component';
import { IonRadioGroup } from '@ionic/angular/standalone';

@Component({
  standalone: true,
  selector: 'parkour-delen-section',
  templateUrl: './delen-section.component.html',
  imports: [
    ReactiveFormsModule,
    ParkourRadioComponent,
    TranslateModule,
    NgIf,
    ParkourInfoCardComponent,
    GedeeldMetItemComponent,
    IonRadioGroup,
  ],
})
export class DelenSectionComponent {
  @Input({ required: true }) delenFormGroup!: DelenSectionGroup;
  @Input({ required: true }) teamleden!: Array<TeamlidProfiel>;

  hasGeblokkeerdeTeamleden(): boolean {
    return this.teamleden.some((teamlid) => teamlid.status === 'GEBLOKKEERD');
  }
}
