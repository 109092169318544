import { ThemaVisualizationMapping } from './thema';

export const themaMapping: ThemaVisualizationMapping = {
  ALCOHOL_DRUGS: {
    label: 'Alcohol & drugs',
    largeImage: {
      sources: {
        default: {
          src: '/assets/themes/alcohol-drugs/default/thema_alcohol-drugs_default.webp',
          fallback: '/assets/themes/alcohol-drugs/default/thema_alcohol-drugs_default.jpg',
        },
        md: {
          src: '/assets/themes/alcohol-drugs/md/thema_alcohol-drugs_md.webp',
          fallback: '/assets/themes/alcohol-drugs/md/thema_alcohol-drugs_md.jpg',
        },
        lg: {
          src: '/assets/themes/alcohol-drugs/lg/thema_alcohol-drugs_lg.webp',
          fallback: '/assets/themes/alcohol-drugs/lg/thema_alcohol-drugs_lg.jpg',
        },
      },
      blur: '/assets/themes/alcohol-drugs/background_alcohol-drugs_blur.jpg',
    },
    smallImage: {
      src: '/assets/themes/alcohol-drugs/square/thema_alcohol-drugs_square.webp',
      fallback: '/assets/themes/alcohol-drugs/square/thema_alcohol-drugs_square.jpg',
    },
  },
  GELD: {
    label: 'Geld',
    largeImage: {
      sources: {
        default: {
          src: '/assets/themes/geld/default/thema_geld_default.webp',
          fallback: '/assets/themes/geld/default/thema_geld_default.jpg',
        },
        md: {
          src: '/assets/themes/geld/md/thema_geld_md.webp',
          fallback: '/assets/themes/geld/md/thema_geld_md.jpg',
        },
        lg: {
          src: '/assets/themes/geld/lg/thema_geld_lg.webp',
          fallback: '/assets/themes/geld/lg/thema_geld_lg.jpg',
        },
      },
      blur: '/assets/themes/geld/background_geld_blur.jpg',
    },
    smallImage: {
      src: '/assets/themes/geld/square/thema_geld_square.webp',
      fallback: '/assets/themes/geld/square/thema_geld_square.jpg',
    },
  },
  IDENTITEIT: {
    label: 'Identiteit',
    largeImage: {
      sources: {
        default: {
          src: '/assets/themes/identiteit/default/thema_identiteit_default.webp',
          fallback: '/assets/themes/identiteit/default/thema_identiteit_default.jpg',
        },
        md: {
          src: '/assets/themes/identiteit/md/thema_identiteit_md.webp',
          fallback: '/assets/themes/identiteit/md/thema_identiteit_md.jpg',
        },
        lg: {
          src: '/assets/themes/identiteit/lg/thema_identiteit_lg.webp',
          fallback: '/assets/themes/identiteit/lg/thema_identiteit_lg.jpg',
        },
      },
      blur: '/assets/themes/identiteit/background_identiteit_blur.jpg',
    },
    smallImage: {
      src: '/assets/themes/identiteit/square/thema_identiteit_square.webp',
      fallback: '/assets/themes/identiteit/square/thema_identiteit_square.jpg',
    },
  },
  LIEFDE: {
    label: 'Liefde',
    largeImage: {
      sources: {
        default: {
          src: '/assets/themes/liefde/default/thema_liefde_default.webp',
          fallback: '/assets/themes/liefde/default/thema_liefde_default.jpg',
        },
        md: {
          src: '/assets/themes/liefde/md/thema_liefde_md.webp',
          fallback: '/assets/themes/liefde/md/thema_liefde_md.jpg',
        },
        lg: {
          src: '/assets/themes/liefde/lg/thema_liefde_lg.webp',
          fallback: '/assets/themes/liefde/lg/thema_liefde_lg.jpg',
        },
      },
      blur: '/assets/themes/liefde/background_liefde_blur.jpg',
    },
    smallImage: {
      src: '/assets/themes/liefde/square/thema_liefde_square.webp',
      fallback: '/assets/themes/liefde/square/thema_liefde_square.jpg',
    },
  },
  MENTAAL_WELZIJN: {
    label: 'Mentaal welzijn',
    largeImage: {
      sources: {
        default: {
          src: '/assets/themes/welzijn/default/thema_welzijn_default.webp',
          fallback: '/assets/themes/welzijn/default/thema_welzijn_default.jpg',
        },
        md: {
          src: '/assets/themes/welzijn/md/thema_welzijn_md.webp',
          fallback: '/assets/themes/welzijn/md/thema_welzijn_md.jpg',
        },
        lg: {
          src: '/assets/themes/welzijn/lg/thema_welzijn_lg.webp',
          fallback: '/assets/themes/welzijn/lg/thema_welzijn_lg.jpg',
        },
      },
      blur: '/assets/themes/welzijn/background_welzijn_blur.jpg',
    },
    smallImage: {
      src: '/assets/themes/welzijn/square/thema_welzijn_square.webp',
      fallback: '/assets/themes/welzijn/square/thema_welzijn_square.jpg',
    },
  },
  MIJN_GEZONDHEID: {
    label: 'Mijn gezondheid',
    largeImage: {
      sources: {
        default: {
          src: '/assets/themes/gezondheid/default/thema_gezondheid_default.webp',
          fallback: '/assets/themes/gezondheid/default/thema_gezondheid_default.jpg',
        },
        md: {
          src: '/assets/themes/gezondheid/md/thema_gezondheid_md.webp',
          fallback: '/assets/themes/gezondheid/md/thema_gezondheid_md.jpg',
        },
        lg: {
          src: '/assets/themes/gezondheid/lg/thema_gezondheid_lg.webp',
          fallback: '/assets/themes/gezondheid/lg/thema_gezondheid_lg.jpg',
        },
      },
      blur: '/assets/themes/gezondheid/background_gezondheid_blur.jpg',
    },
    smallImage: {
      src: '/assets/themes/gezondheid/square/thema_gezondheid_square.webp',
      fallback: '/assets/themes/gezondheid/square/thema_gezondheid_square.jpg',
    },
  },
  SCHOOL: {
    label: 'School',
    largeImage: {
      sources: {
        default: {
          src: '/assets/themes/school/default/thema_school_default.webp',
          fallback: '/assets/themes/school/default/thema_school_default.jpg',
        },
        md: {
          src: '/assets/themes/school/md/thema_school_md.webp',
          fallback: '/assets/themes/school/md/thema_school_md.jpg',
        },
        lg: {
          src: '/assets/themes/school/lg/thema_school_lg.webp',
          fallback: '/assets/themes/school/lg/thema_school_lg.jpg',
        },
      },
      blur: '/assets/themes/school/background_school_blur.jpg',
    },
    smallImage: {
      src: '/assets/themes/school/square/thema_school_square.webp',
      fallback: '/assets/themes/school/square/thema_school_square.jpg',
    },
  },
  SEKS: {
    label: 'Seks',
    largeImage: {
      sources: {
        default: {
          src: '/assets/themes/sex/default/thema_sex_default.webp',
          fallback: '/assets/themes/sex/default/thema_sex_default.jpg',
        },
        md: {
          src: '/assets/themes/sex/md/thema_sex_md.webp',
          fallback: '/assets/themes/sex/md/thema_sex_md.jpg',
        },
        lg: {
          src: '/assets/themes/sex/lg/thema_sex_lg.webp',
          fallback: '/assets/themes/sex/lg/thema_sex_lg.jpg',
        },
      },
      blur: '/assets/themes/sex/background_sex_blur.jpg',
    },
    smallImage: {
      src: '/assets/themes/sex/square/thema_sex_square.webp',
      fallback: '/assets/themes/sex/square/thema_sex_square.jpg',
    },
  },
  VRIENDEN_EN_FAMILIE: {
    label: 'Vrienden & Familie',
    largeImage: {
      sources: {
        default: {
          src: '/assets/themes/familie/default/thema_familie_default.webp',
          fallback: '/assets/themes/familie/default/thema_familie_default.jpg',
        },
        md: {
          src: '/assets/themes/familie/md/thema_familie_md.webp',
          fallback: '/assets/themes/familie/md/thema_familie_md.jpg',
        },
        lg: {
          src: '/assets/themes/familie/lg/thema_familie_lg.webp',
          fallback: '/assets/themes/familie/lg/thema_familie_lg.jpg',
        },
      },
      blur: '/assets/themes/familie/background_familie_blur.jpg',
    },
    smallImage: {
      src: '/assets/themes/familie/square/thema_familie_square.webp',
      fallback: '/assets/themes/familie/square/thema_familie_square.jpg',
    },
  },
  VRIJE_TIJD: {
    label: 'Vrije tijd',
    largeImage: {
      sources: {
        default: {
          src: '/assets/themes/vrije-tijd/default/thema_vrije-tijd_default.webp',
          fallback: '/assets/themes/vrije-tijd/default/thema_vrije-tijd_default.jpg',
        },
        md: {
          src: '/assets/themes/vrije-tijd/md/thema_vrije-tijd_md.webp',
          fallback: '/assets/themes/vrije-tijd/md/thema_vrije-tijd_md.jpg',
        },
        lg: {
          src: '/assets/themes/vrije-tijd/lg/thema_vrije-tijd_lg.webp',
          fallback: '/assets/themes/vrije-tijd/lg/thema_vrije-tijd_lg.jpg',
        },
      },
      blur: '/assets/themes/vrije-tijd/background_vrije-tijd_blur.jpg',
    },
    smallImage: {
      src: '/assets/themes/vrije-tijd/square/thema_vrije-tijd_square.webp',
      fallback: '/assets/themes/vrije-tijd/square/thema_vrije-tijd_square.jpg',
    },
  },
  WERELD: {
    label: 'Wereld',
    largeImage: {
      sources: {
        default: {
          src: '/assets/themes/wereld/default/thema_wereld_default.webp',
          fallback: '/assets/themes/wereld/default/thema_wereld_default.jpg',
        },
        md: {
          src: '/assets/themes/wereld/md/thema_wereld_md.webp',
          fallback: '/assets/themes/wereld/md/thema_wereld_md.jpg',
        },
        lg: {
          src: '/assets/themes/wereld/lg/thema_wereld_lg.webp',
          fallback: '/assets/themes/wereld/lg/thema_wereld_lg.jpg',
        },
      },
      blur: '/assets/themes/wereld/background_wereld_blur.jpg',
    },
    smallImage: {
      src: '/assets/themes/wereld/square/thema_wereld_square.webp',
      fallback: '/assets/themes/wereld/square/thema_wereld_square.jpg',
    },
  },
  WERK: {
    label: 'Werk',
    largeImage: {
      sources: {
        default: {
          src: '/assets/themes/werk/default/thema_werk_default.webp',
          fallback: '/assets/themes/werk/default/thema_werk_default.jpg',
        },
        md: {
          src: '/assets/themes/werk/md/thema_werk_md.webp',
          fallback: '/assets/themes/werk/md/thema_werk_md.jpg',
        },
        lg: {
          src: '/assets/themes/werk/lg/thema_werk_lg.webp',
          fallback: '/assets/themes/werk/lg/thema_werk_lg.jpg',
        },
      },
      blur: '/assets/themes/werk/background_werk_blur.jpg',
    },
    smallImage: {
      src: '/assets/themes/werk/square/thema_werk_square.webp',
      fallback: '/assets/themes/werk/square/thema_werk_square.jpg',
    },
  },
  WONEN: {
    label: 'Wonen',
    largeImage: {
      sources: {
        default: {
          src: '/assets/themes/wonen/default/thema_wonen_default.webp',
          fallback: '/assets/themes/wonen/default/thema_wonen_default.jpg',
        },
        md: {
          src: '/assets/themes/wonen/md/thema_wonen_md.webp',
          fallback: '/assets/themes/wonen/md/thema_wonen_md.jpg',
        },
        lg: {
          src: '/assets/themes/wonen/lg/thema_wonen_lg.webp',
          fallback: '/assets/themes/wonen/lg/thema_wonen_lg.jpg',
        },
      },
      blur: '/assets/themes/wonen/background_wonen_blur.jpg',
    },
    smallImage: {
      src: '/assets/themes/wonen/square/thema_wonen_square.webp',
      fallback: '/assets/themes/wonen/square/thema_wonen_square.jpg',
    },
  },
};
