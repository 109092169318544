import { Component, Input } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { HeroSources } from './page-with-hero.types';
import {
  IParkourHeroImageProps,
  ParkourDividerComponent,
  ParkourHeroComponent,
  ParkourInfoCardComponent,
  ParkourPageImageComponent,
} from '@parkour/ui';

@Component({
  selector: 'parkour-page-with-hero',
  standalone: true,
  imports: [
    AsyncPipe,
    ParkourPageImageComponent,
    ParkourHeroComponent,
    ParkourDividerComponent,
    ParkourInfoCardComponent,
  ],
  templateUrl: './page-with-hero.component.html',
})
export class PageWithHeroComponent {
  heroProps: IParkourHeroImageProps = {
    src: '',
  };
  background: string | null = '';
  @Input() showTitle: boolean = true;
  @Input({ required: true }) title!: string;

  @Input({ required: true }) set heroImage(heroImage: HeroSources | null) {
    if (typeof heroImage === 'string') {
      this.heroProps = {
        src: heroImage,
      };
      this.background = heroImage;
    } else if (heroImage) {
      this.heroProps = heroImage.hero;
      this.background = heroImage.background;
    }
  }
}
