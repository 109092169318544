import { Component, Input, ViewEncapsulation } from '@angular/core';
import { WatWatAttachmentContent } from 'parkour-web-app-dto';
import { ParkourActionComponent, ParkourIconComponent } from '@parkour/ui';
import { NgForOf } from '@angular/common';

@Component({
  selector: 'parkour-artikel-attachment-content',
  templateUrl: './artikel-attachment-content.component.html',
  styleUrls: ['./artikel-attachment-content.component.css'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [NgForOf, ParkourActionComponent, ParkourIconComponent],
})
export class ArtikelAttachmentContentComponent {
  @Input({ required: true }) content!: WatWatAttachmentContent;
}
