import { Component, Input } from '@angular/core';
import { WatWatContent } from 'parkour-web-app-dto';
import { ArtikelFaqContentComponent } from '../artikel-faq-content/artikel-faq-content.component';
import { ArtikelTextContentComponent } from '../artikel-text-content/artikel-text-content.component';
import { ArtikelQuoteContentComponent } from '../artikel-quote-content/artikel-quote-content.component';
import { ArtikelVideoContentComponent } from '../artikel-video-content/artikel-video-content.component';
import { ArtikelCallToActionContentComponent } from '../artikel-call-to-action-content/artikel-call-to-action-content.component';
import { ArtikelAttachmentContentComponent } from '../artikel-attachment-content/artikel-attachment-content.component';
import { ArtikelImageContentComponent } from '../artikel-image-content/artikel-image-content.component';
import { ArtikelHtmlEmbedContentComponent } from '../artikel-html-embed-content/artikel-html-embed-content.component';
import { ArtikelHtmlEmbedLargeContentComponent } from '../artikel-html-embed-large-content/artikel-html-embed-large-content.component';

@Component({
  selector: 'parkour-artikel-content',
  templateUrl: './artikel-content.component.html',
  standalone: true,
  imports: [
    ArtikelFaqContentComponent,
    ArtikelTextContentComponent,
    ArtikelQuoteContentComponent,
    ArtikelVideoContentComponent,
    ArtikelCallToActionContentComponent,
    ArtikelAttachmentContentComponent,
    ArtikelImageContentComponent,
    ArtikelHtmlEmbedContentComponent,
    ArtikelHtmlEmbedLargeContentComponent,
  ],
})
export class ArtikelContentComponent {
  @Input({ required: true }) content!: WatWatContent;
}
