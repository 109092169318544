import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import {
  IonApp,
  IonicRouteStrategy,
  IonRouterOutlet,
  provideIonicAngular,
} from '@ionic/angular/standalone';
import { isPlatform } from '@ionic/core';
import {
  NavigationEnd,
  NavigationStart,
  Router,
  RouteReuseStrategy,
  TitleStrategy,
} from '@angular/router';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { CoreModule, HttpLoaderFactory } from './core/core.module';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import localeNl from '@angular/common/locales/nl';
import { DatePipe, registerLocaleData } from '@angular/common';
import { GlobalErrorHandler } from './core/global-error-handler';
import { GlobalHttpErrorHandler } from './core/global-http-error-handler';
import {
  MatomoConsentMode,
  provideMatomo,
  withRouter,
  withRouterInterceptors,
} from 'ngx-matomo-client';
import { environment } from '../environments/environment';
import { PushNotificatieService } from './profiel/service/push-notificatie.service';
import { BrowserAnimationsModule, provideAnimations } from '@angular/platform-browser/animations';
import { MeldingenService } from './profiel/service/meldingen.service';
import { VersioningService } from './versioning/versioning.service';
import { PortalModule } from '@angular/cdk/portal';
import { UuidFilterInterceptor } from './analytics/uuid-filter.interceptor';
import { MakeReferencesAbsolutePipe } from './watwat/pipes/make-references-absolute.pipe';
import { MakeUrlAbsolutePipe } from './watwat/pipes/make-url-absolute.pipe';
import { AppTitlePrefix } from './app-title.prefix';
import { ParkourBeeldbellenAnchorDirective } from './beeldbellen/directive/parkour-beeldbellen-anchor.directive';
import { CookiesModalComponent } from './shared/components/cookies-modal/cookies-modal.component';
import { ParkourSheetComponent } from './shared/components/parkour-sheet/parkour-sheet.component';
import { ParkourVoHeaderComponent } from '@parkour/ui';
import { NativeService } from './core/native.service';
import { ActivityDetectionService } from './authentication/service/activity-detection.service';
import AuthService from './authentication/service/auth.service';
import { BiometricsConfigModalService } from './authentication/service/biometrics-config-modal.service';
import BackgroundDetectionService from './authentication/service/background-detection.service';
import StatusService from './authentication/service/status.service';
import { LoggingService } from './core/logging.service';
import { DeeplinkingService } from './core/config/deeplinking.service';
import { BiometricsService } from './authentication/service/biometrics.service';
import { PageTitleInterceptor } from './analytics/page-title.interceptor';

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    HammerModule,
    TranslateModule.forRoot({
      defaultLanguage: 'nl',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    PortalModule,
    CoreModule,
    // IonicStorageModule.forRoot(),
    ParkourBeeldbellenAnchorDirective,
    CookiesModalComponent,
    ParkourSheetComponent,
    ParkourVoHeaderComponent,
    IonRouterOutlet,
    IonApp,
  ],
  providers: [
    AppTitlePrefix,
    {
      provide: TitleStrategy,
      useClass: AppTitlePrefix,
    },
    provideIonicAngular({
      animated: !isPlatform('desktop'),
    }),
    provideAnimations(),
    provideMatomo(
      {
        disabled: !environment.matomo.enabled,
        trackerUrl: environment.matomo.trackerUrl,
        siteId: environment.matomo.siteId,
        enableJSErrorTracking: true,
        requireConsent: MatomoConsentMode.COOKIE,
      },
      withRouterInterceptors([UuidFilterInterceptor, PageTitleInterceptor]),
      withRouter(),
    ),
    DatePipe,
    {
      provide: APP_INITIALIZER,
      useFactory: (statusService: StatusService) => () => {
        statusService.initialize();
      },
      deps: [StatusService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (authService: AuthService) => () => {
        authService.loadInitialUser();
      },
      deps: [AuthService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (meldingenService: MeldingenService) => () =>
        meldingenService.startMeldingenFetcher(),
      deps: [MeldingenService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (pushNotificatieService: PushNotificatieService) => () =>
        pushNotificatieService.initialize(),
      deps: [PushNotificatieService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (versioningService: VersioningService) => () =>
        versioningService.initializeVersionCheck(),
      deps: [VersioningService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (biometricsService: BiometricsConfigModalService) => () =>
        biometricsService.initialize(),
      deps: [BiometricsConfigModalService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (nativeService: NativeService) => () => nativeService.initialize(),
      deps: [NativeService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (biometricsService: BiometricsService) => async () =>
        biometricsService.initialize(),
      deps: [BiometricsService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (activityDetectionService: ActivityDetectionService) => () =>
        activityDetectionService.initialize(),
      deps: [ActivityDetectionService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (backgroundDetectionService: BackgroundDetectionService) => () =>
        backgroundDetectionService.initialize(),
      deps: [BackgroundDetectionService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (deeplinkingConfig: DeeplinkingService) => () =>
        deeplinkingConfig.configureDeepLinking(),
      deps: [DeeplinkingService],
      multi: true,
    },
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy,
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GlobalHttpErrorHandler,
      multi: true,
    },
    {
      provide: LOCALE_ID,
      useValue: 'nl-BE',
    },
    MakeReferencesAbsolutePipe,
    MakeUrlAbsolutePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    translate: TranslateService,
    private readonly router: Router,
    private readonly loggingService: LoggingService,
  ) {
    translate.setDefaultLang('nl');
    translate.use('nl');
    registerLocaleData(localeNl, LOCALE_ID);

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd || event instanceof NavigationStart) {
        this.loggingService.log(event);
      }
    });
  }
}
