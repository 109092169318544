import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ProfielNaamType } from 'parkour-web-app-dto';
import {
  ParkourFormFieldWithoutLabelComponent,
  ParkourInputComponent,
  ParkourRadioComponent,
} from '@parkour/ui';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf } from '@angular/common';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonRadioGroup,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';

export type NaamFormGroup = FormGroup<{
  profielnaamType: FormControl<ProfielNaamType>;
  roepnaam: FormControl<string | undefined>;
}>;

@Component({
  standalone: true,
  selector: 'parkour-profile-details-profielnaam-edit',
  templateUrl: './profile-details-profielnaam-edit.component.html',
  styleUrls: ['./profile-details-profielnaam-edit.component.scss'],
  imports: [
    ParkourRadioComponent,
    TranslateModule,
    ReactiveFormsModule,
    NgIf,
    ParkourFormFieldWithoutLabelComponent,
    ParkourInputComponent,
    IonBackButton,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonTitle,
    IonContent,
    IonRadioGroup,
  ],
})
export class ProfileDetailsProfielnaamEditComponent {
  @Input({ required: true }) naamFormgroup!: NaamFormGroup;
  @Input({ required: true }) fullName!: string;

  getRoepnaamErrors() {
    return {
      ...this.naamFormgroup.errors,
      ...this.naamFormgroup.controls.roepnaam.errors,
    };
  }
}
