import { BrandedType } from '../branded-type';
import { ApiProperty, getSchemaPath } from '@nestjs/swagger';
import { Thema } from './thema.dto';

export type WatWatHelpline =
  | WatWatChatHelpline
  | WatWatTelHelpline
  | WatWatVisitHelpline
  | WatWatForumHelpline
  | WatWatMailHelpline
  | WatWatActivityHelpline;

export class WatWatChatHelpline {
  method!: 'chat';
  organisation!: number;
}

export class WatWatTelHelpline {
  method!: 'tel';
  organisation!: number;
}

export class WatWatVisitHelpline {
  method!: 'visit';
  organisation!: number;
}

export class WatWatForumHelpline {
  method!: 'forum';
  organisation!: number;
}

export class WatWatMailHelpline {
  method!: 'mail';
  organisation!: number;
}

export class WatWatActivityHelpline {
  method!: 'activity';
  organisation!: number;
}

export class WatWatTextContent {
  readonly id!: string;
  readonly weight!: number;
  readonly type!: 'text';
  readonly content!: WatWatContentDto;
}

class WatWatContentDto {
  readonly text!: string;
}

export class WatWatVideoContent {
  readonly id!: string;
  readonly weight!: number;
  readonly type!: 'video';
  readonly content!: WatWatYoutubeContentDetails | WatWatVimeoContentDetails;
}

export class WatWatYoutubeContentDetails {
  readonly type!: 'youtube';
  readonly youtube!: string;
  readonly title!: string;
}

export class WatWatVimeoContentDetails {
  readonly type!: 'vimeo';
  readonly vimeo!: string;
  readonly title!: string;
}

export class WatWatCallToActionContent {
  readonly id!: string;
  readonly weight!: number;
  readonly type!: 'call_to_action';
  readonly content!: WatWatCallToActionContentDetails;
}

export class WatWatCallToActionContentDetails {
  readonly title!: string;
  readonly description?: string;
  readonly image?: WatWatCallToActionContentImage;
  readonly link?: WatWatCallToActionContentLink;
}

export class WatWatCallToActionContentImage {
  readonly copyright?: string;
  readonly file!: string;
  readonly title!: string;
}

export class WatWatCallToActionContentLink {
  readonly external!: boolean;
  readonly text!: string;
  readonly url!: string;
}

export class WatWatQuoteContent {
  readonly id!: string;
  readonly weight!: number;
  readonly type!: 'quote';
  readonly content!: WatWatQuoteContentDetails;
}

export class WatWatQuoteContentDetails {
  readonly text!: string;
  readonly source?: string;
}

export class WatWatAttachmentContent {
  readonly id!: string;
  readonly weight!: number;
  readonly type!: 'attachment';
  readonly content!: WatWatAttachmentContentDetails;
}

export class WatWatAttachmentContentDetails {
  readonly attachments!: WatWatAttachment[];
}

class WatWatAttachment {
  readonly file?: string;
  readonly title?: string;
}

export class WatWatFaqContent {
  readonly id!: string;
  readonly weight!: number;
  readonly type!: 'faq';
  readonly content!: WatWatFaqContentDetails;
}

export class WatWatFaqContentDetails {
  readonly items!: FaqItem[];
}

class FaqItem {
  readonly id?: string;
  readonly question?: string;
  readonly answer?: string;
}

export class WatWatImageContent {
  readonly id!: string;
  readonly weight!: number;
  readonly type!: 'image';
  readonly content!: WatWatImageContentDetails;
}

export class WatWatImageContentDetails {
  readonly image!: WatWatImageContentImage;
}

export class WatWatImageContentImage {
  readonly alt?: string;
  readonly copyright?: string;
  readonly file!: string;
  readonly title!: string;
}

export class WatWatHtmlEmbedContent {
  readonly id!: string;
  readonly weight!: number;
  readonly type!: 'html_embed';
}

export class WatWatHtmlEmbedLargeContent {
  readonly id!: string;
  readonly weight!: number;
  readonly type!: 'html_embed_large';
}

export type WatWatContent =
  | WatWatTextContent
  | WatWatVideoContent
  | WatWatCallToActionContent
  | WatWatQuoteContent
  | WatWatFaqContent
  | WatWatAttachmentContent
  | WatWatImageContent
  | WatWatHtmlEmbedContent
  | WatWatHtmlEmbedLargeContent;

export type ArtikelId = BrandedType<number, 'artikel'>;

export type OrganisationId = BrandedType<number, 'organisation'>;

export class WatWatArtikelAuthor {
  readonly name!: string;
  readonly email?: string;
  readonly isContributor!: boolean;
}

export class WatWatArtikelDto {
  @ApiProperty({ type: Number })
  readonly id!: ArtikelId;
  readonly title!: string;
  readonly author!: WatWatArtikelAuthor;
  readonly image!: string;
  @ApiProperty({ type: Array<string> })
  readonly themas!: Thema[];
  @ApiProperty({
    type: 'array',
    items: {
      anyOf: [
        { $ref: getSchemaPath(WatWatTextContent) },
        { $ref: getSchemaPath(WatWatVideoContent) },
        { $ref: getSchemaPath(WatWatCallToActionContent) },
        { $ref: getSchemaPath(WatWatQuoteContent) },
        { $ref: getSchemaPath(WatWatFaqContent) },
        { $ref: getSchemaPath(WatWatAttachmentContent) },
        { $ref: getSchemaPath(WatWatImageContent) },
        { $ref: getSchemaPath(WatWatHtmlEmbedContent) },
        { $ref: getSchemaPath(WatWatHtmlEmbedLargeContent) },
      ],
    },
  })
  readonly content!: WatWatContent[];
  @ApiProperty({
    type: 'array',
    items: {
      anyOf: [
        { $ref: getSchemaPath(WatWatActivityHelpline) },
        { $ref: getSchemaPath(WatWatChatHelpline) },
        { $ref: getSchemaPath(WatWatForumHelpline) },
        { $ref: getSchemaPath(WatWatTelHelpline) },
        { $ref: getSchemaPath(WatWatVisitHelpline) },
        { $ref: getSchemaPath(WatWatMailHelpline) },
      ],
    },
  })
  readonly helplines!: WatWatHelpline[];
  readonly selfTests!: number[];
}

export class WatWatSelfTest {
  readonly title!: string;
  readonly url?: string;
  readonly link_text?: string;
  readonly description?: string;
  readonly name?: string;
  readonly email?: string;
}

export type WatWatOrganisationMethodType = 'chat' | 'mail' | 'tel' | 'forum' | 'visit' | 'activity';

export class WatWatOrganisationMethod {
  @ApiProperty({ type: String })
  readonly type!: WatWatOrganisationMethodType;
  readonly description!: string;
  readonly telephone?: string;
  readonly url?: string;
  readonly email?: string;
}

export class WatWatOrganisation {
  @ApiProperty({ type: Number })
  readonly id!: OrganisationId;
  readonly title!: string;
  readonly url!: string;
  readonly description!: string;
  readonly helpOrganisationText!: string;
  readonly methods!: WatWatOrganisationMethod[];
  readonly image!: string;
  @ApiProperty({
    type: 'array',
    items: {
      anyOf: [
        { $ref: getSchemaPath(WatWatTextContent) },
        { $ref: getSchemaPath(WatWatVideoContent) },
        { $ref: getSchemaPath(WatWatCallToActionContent) },
        { $ref: getSchemaPath(WatWatQuoteContent) },
        { $ref: getSchemaPath(WatWatFaqContent) },
        { $ref: getSchemaPath(WatWatAttachmentContent) },
        { $ref: getSchemaPath(WatWatImageContent) },
        { $ref: getSchemaPath(WatWatHtmlEmbedContent) },
        { $ref: getSchemaPath(WatWatHtmlEmbedLargeContent) },
      ],
    },
  })
  readonly content!: WatWatContent[];
}

export function validateOrganisationId(id?: number | string | null): OrganisationId {
  if (typeof id === 'number') {
    return id as OrganisationId;
  }

  if (typeof id === 'string') {
    return parseInt(id) as OrganisationId;
  }

  throw new Error('OrganisationId cannnot be undefined');
}

export class WatWatOrganisationQueryResultDto {
  readonly total!: number;
  readonly results!: WatWatOrganisation[];
  readonly pageSize!: number;
}

export class ArtikelQueryResultDto {
  readonly total!: number;
  readonly results!: WatWatArtikelDto[];
  readonly pageSize!: number;
}

export function parseArtikelId(id?: number | string | null): ArtikelId {
  if (typeof id === 'number') {
    return id as ArtikelId;
  }

  if (typeof id === 'string') {
    return parseInt(id) as ArtikelId;
  }

  throw new Error('ArtikelId cannnot be undefined');
}

export function parseOrganisationId(id?: number | string | null): OrganisationId {
  if (typeof id === 'number') {
    return id as OrganisationId;
  }

  if (typeof id === 'string') {
    return parseInt(id) as OrganisationId;
  }

  throw new Error('OrganisationId cannnot be undefined');
}
