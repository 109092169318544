import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { Result } from '../../utils';

type SectionData<T> = Result<T> | null;
@Directive({
  standalone: true,
  selector: '[parkourShowIfSuccessful]',
})
export class ShowIfSuccesfulDirective<T> {
  constructor(
    private templateRef: TemplateRef<{ $implicit: T }>,
    private viewContainerRef: ViewContainerRef,
  ) {}

  static ngTemplateContextGuard<T>(
    dir: ShowIfSuccesfulDirective<T>,
    context: unknown,
  ): context is { $implicit: T } {
    return true;
  }

  @Input()
  set parkourShowIfSuccessful(sectionData: SectionData<T>) {
    this.viewContainerRef.clear();
    if (sectionData && sectionData.success) {
      this.viewContainerRef.createEmbeddedView(this.templateRef, {
        $implicit: sectionData.value,
      });
    }
  }
}
