import { Component, HostBinding, Input } from "@angular/core";
import { NgClass, NgIf } from "@angular/common";

@Component({
  selector: "parkour-new-indicator",
  templateUrl: "./parkour-new-indicator.component.html",
  standalone: true,
  imports: [NgIf, NgClass],
})
export class ParkourNewIndicatorComponent {
  @Input() public show: boolean | null = false;
  @Input() public inline: boolean = false;

  @HostBinding("class") class = "block";
}
