import { Component, viewChild } from '@angular/core';
import { GebeurtenisEditPage } from '../gebeurtenis-edit-page/gebeurtenis-edit.page';
import {
  ParkourButtonComponent,
  ParkourLoadingSpinnerComponent,
  ParkourPopupService,
} from '@parkour/ui';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncPipe } from '@angular/common';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';
import { FileData, FotoId } from 'parkour-web-app-dto';
import { ParkourHeaderActionComponent } from '../../../shared/components/parkour-header-action/parkour-header-action.component';
import { ParkourBackButtonDirective } from '../../../shared/directives/parkour-back-button.directive';
import { GebeurtenisFormComponent } from '../../verhaal-shared/component/gebeurtenis-form/gebeurtenis-form.component';
import { ContentUnavailableCardComponent } from '../../../shared/components/content-unavailable-card/content-unavailable-card.component';
import { PageLayoutComponent } from '../../../shared/components/page-layout/page-layout.component';
import { PageContentDirective } from '../../../shared/directives/page-content.directive';
import { DirtyFormCheck } from '../../../shared/components/dirty-form-guard';
import { GebeurtenisFotoService } from '../../verhaal-shared/service/gebeurtenis-foto.service';
import { EMPTY, map, switchMap, take } from 'rxjs';
import { getSecureImage } from '../../../shared/pipes/secure-image.pipe';
import { successOrThrow } from '../../../utils';
import { Gebeurtenis } from '../../verhaal-shared/model/gebeurtenis';

@Component({
  standalone: true,
  templateUrl: './parkour-gebeurtenis-edit.page.html',
  imports: [
    ParkourHeaderActionComponent,
    TranslateModule,
    ParkourBackButtonDirective,
    GebeurtenisFormComponent,
    ContentUnavailableCardComponent,
    AsyncPipe,
    ParkourButtonComponent,
    ParkourLoadingSpinnerComponent,
    PageLayoutComponent,
    PageContentDirective,
    IonBackButton,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonTitle,
    IonContent,
  ],
})
export class ParkourGebeurtenisEditPage extends GebeurtenisEditPage implements DirtyFormCheck {
  gebeurtenisForm = viewChild(GebeurtenisFormComponent);

  constructor(
    private readonly parkourPopupService: ParkourPopupService,
    private readonly gebeurtenisFotoService: GebeurtenisFotoService,
  ) {
    super();
  }

  protected getExistingGebeurtenisImage = (imageId: FotoId) => {
    return this.mainPageData$.pipe(
      take(1),
      switchMap((data) => {
        if (data.success) {
          return this.gebeurtenisFotoService.getGebeurtenisFotoUrl(
            data.value.gebeurtenis.id,
            imageId,
            'LARGE',
          );
        } else {
          return EMPTY;
        }
      }),
      switchMap((url) => getSecureImage(url)),
    );
  };

  // TODO: UX Copy verhaal aan JSON toevoegen
  async onDeleteGebeurtenis() {
    const result = await this.parkourPopupService.showPopup({
      title: 'Gebeurtenis verwijderen',
      description: 'Ben je zeker dat je deze gebeurtenis wil verwijderen?',
      icon: 'trash',
    });
    if (result === 'yes') {
      this.onConfirmDelete();
    }
  }

  onConfirmDelete() {
    this.mainPageData$
      .pipe(
        take(1),
        map(successOrThrow),
        switchMap((pageData) => this.verhalenService.deleteGebeurtenis(pageData.gebeurtenis.id)),
      )
      .subscribe(() => {
        this.gebeurtenisForm()?.registerGebeurtenisForm.markAsPristine();
        this.contextService.navigateToAbsoluteUrl(['verhaal']);
      });
  }

  isFormDirty() {
    return this.gebeurtenisForm()?.registerGebeurtenisForm.dirty ?? false;
  }

  onExistingFileDownload(fileData: FileData, gebeurtenis: Gebeurtenis) {
    this.verhalenService.downloadBijlage(gebeurtenis.id, fileData).subscribe();
  }
}
