import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { InteractieEmoji, interactieEmojis, ProfielId, ReactieDto } from 'parkour-web-app-dto';
import { ParkourActionComponent, ParkourButtonComponent, ParkourIconComponent } from '@parkour/ui';

import { InteractieOverview } from '../../model/interactie-overview';
import { NgClass } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ParkourEmojiPipe } from '../../../../shared/pipes/parkourEmoji.pipe';
import { isNativeApp } from '../../../../utils';
import { Haptics, ImpactStyle } from '@capacitor/haptics';
import { ParkourPopoverDirective } from '../../../../shared/directives/parkour-popover.directive';
import { EmojiReactiesComponent } from '../../../../shared/components/emoji-reacties/emoji-reacties.component';
import { AddEmojiComponent } from '../../../../shared/components/add-emoji/add-emoji.component';
import { IonPopover } from '@ionic/angular/standalone';

@Component({
  selector: 'parkour-reactie',
  templateUrl: './reactie-overview.component.html',
  standalone: true,
  imports: [
    NgClass,
    ParkourEmojiPipe,
    TranslateModule,
    ParkourPopoverDirective,
    EmojiReactiesComponent,
    ParkourActionComponent,
    ParkourIconComponent,
    ParkourButtonComponent,
    AddEmojiComponent,
    IonPopover,
  ],
})
export class ReactieOverviewComponent {
  @ViewChild('popoverEmojiSelect') popoverEmojiSelect!: ParkourPopoverDirective;

  @Input({ required: true }) reactieOverview!: InteractieOverview;
  @Input({ required: true }) userProfielId!: ProfielId;

  @Output()
  reactieSelect: EventEmitter<InteractieEmoji> = new EventEmitter<InteractieEmoji>();
  @Output()
  reactieUnSelect: EventEmitter<ReactieDto> = new EventEmitter<ReactieDto>();

  readonly emojis = interactieEmojis;

  getActiveSelectedReactie(): InteractieEmoji | null {
    const doesUserHaveReactie = this.reactieOverview
      .getReacties()
      .find((reactie) => reactie.ownerId === this.userProfielId);

    return doesUserHaveReactie ? doesUserHaveReactie.type : null;
  }

  onSelectedEmoji(selectedEmoji: InteractieEmoji) {
    this.popoverEmojiSelect.close();

    if (this.reactieOverview.getMyInteractie()?.type === selectedEmoji) {
      this.reactieUnSelect.next(this.reactieOverview.getMyInteractie() as ReactieDto);
    } else {
      this.reactieSelect.next(selectedEmoji);
    }
  }

  async openEmojiSelect(event: MouseEvent) {
    if (isNativeApp()) {
      await Haptics.impact({ style: ImpactStyle.Light });
    }

    this.popoverEmojiSelect.open(event);
  }

  onDeleteEmoji() {
    this.reactieUnSelect.next(this.reactieOverview.getMyInteractie() as ReactieDto);
  }

  protected readonly isNativeApp = isNativeApp;
}
