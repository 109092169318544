import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  Output,
} from '@angular/core';
import { GestureController } from '@ionic/angular/standalone';
import { Haptics, ImpactStyle } from '@capacitor/haptics';
import { isNativeApp } from '../../utils';

/**
 * Directive based on this ionic forum post:
 * https://forum.ionicframework.com/t/ionic-5-long-press-gesture-example/188746
 */
@Directive({
  standalone: true,
  selector: '[parkourLongPress]',
})
export class LongPressDirective implements AfterViewInit {
  @Output() public tap: EventEmitter<UIEvent> = new EventEmitter<UIEvent>();
  @Output() public press: EventEmitter<UIEvent> = new EventEmitter<UIEvent>();
  @Input() delay = 500;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action: any;

  private isLongPressActive = false;

  private positions = {
    start: {
      x: 0,
      y: 0,
    },
    current: {
      x: 0,
      y: 0,
    },
  };

  constructor(
    private el: ElementRef,
    private gestureCtrl: GestureController,
    private zone: NgZone,
  ) {}

  ngAfterViewInit() {
    this.loadLongPress();
  }

  loadLongPress() {
    const gesture = this.gestureCtrl.create({
      el: this.el.nativeElement,
      threshold: 0,
      gestureName: 'long-press',
      onStart: (event) => {
        window.addEventListener('contextmenu', this.onContextMenu);

        this.isLongPressActive = true;

        this.onLongPress(event.event);

        this.positions = {
          start: {
            x: event.startX,
            y: event.startY,
          },
          current: {
            x: event.currentX,
            y: event.currentY,
          },
        };
      },
      onMove: (event) => {
        this.positions.current = {
          x: event.currentX,
          y: event.currentY,
        };
      },
      onEnd: () => {
        this.isLongPressActive = false;

        window.removeEventListener('contextmenu', this.onContextMenu);
      },
    });

    gesture.enable(true);
  }

  private onContextMenu(e: UIEvent) {
    e.preventDefault();
  }

  private onLongPress(event: UIEvent) {
    if (this.action) {
      clearInterval(this.action);
    }

    this.action = setTimeout(() => {
      this.zone.run(async () => {
        const xDistance = Math.abs(this.positions.start.x - this.positions.current.x);
        const yDistance = Math.abs(this.positions.start.y - this.positions.current.y);

        if (xDistance > 15 || yDistance > 15) {
          return;
        }

        if (this.isLongPressActive) {
          this.isLongPressActive = false;
          this.press.emit(event);

          if (isNativeApp()) {
            await Haptics.impact({ style: ImpactStyle.Medium });
          }
        } else {
          this.tap.emit(event);
        }
      });
    }, this.delay);
  }
}
