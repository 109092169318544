<div class="flex items-start gap-x-3">
  <div class="relative">
    <parkour-profiel-foto-badge [alt]="false" [profiel]="profiel" [size]="'3'" />
    <parkour-new-indicator [show]="showNewIndicator" />
  </div>

  <div class="flex flex-col items-start gap-y-1 whitespace-normal">
    <span class="typo-body flex align-center gap-x-1">
      {{ rol | rolLabelDefault }} van
      @if (profiel.status === 'ACTIEF' && profiel.type === 'PROFESSIONEEL_TEAMLID') {
        <parkour-professioneel-indicator />
      }
    </span>
    <p class="typo-body variant-body-fat">{{ naam }}</p>
    @if (profiel.status === 'GEBLOKKEERD') {
      <parkour-tag iconBefore="block" variant="light-blue">
        {{ 'delen.geblokkeerd' | translate }}
      </parkour-tag>
    }

    <ng-content></ng-content>
  </div>
</div>
