<div [attr.data-testid]="testId" [ngClass]="classes" class="space-y-1">
	<label [for]="inputId" class="sr-only">{{ accessibleLabel }}</label>

  @for (key of Object.keys(getFormErrors()); track $index) {
    @if (getFormErrors()[key] && (this.inputFormControl?.touched ?? true)) {
      <ng-container *ngIf="getErrorMessage(key) as errorMessage">
        <parkour-form-error-message
          [errorMessage]="errorMessage"
          [show]="true"
        />
      </ng-container>
    }
  }
  <ng-content></ng-content>
</div>
