import { Component } from '@angular/core';
import { combineLatest, map, Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import AanDeSlagData from '../../../../data/aan-de-slag.data';
import { Thema } from 'parkour-web-app-dto';
import { TOnboardingSlides } from '../../component/onboarding/onboarding.types';
import {
  ArtikelWithBookmarkQueryResult,
  teleOnthaalOrganisation,
  WatwatService,
} from '../../../shared/services/watwat.service';
import { isNativeApp } from '../../../utils';
import { BookmarkService } from '../../../shared/services/bookmark.service';
import {
  ArtikelsPreviewComponent,
  TParkourArtikelsPreviewBookmarkEvent,
} from '../../component/artikels-preview/artikels-preview.component';
import {
  ParkourButtonComponent,
  ParkourDividerComponent,
  ParkourFormFieldComponent,
  ParkourIconComponent,
  ParkourInfoCardWithTitleComponent,
  ParkourInputComponent,
  ParkourPictureComponent,
} from '@parkour/ui';
import { AsyncPipe, NgForOf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HelplineCardItemComponent } from '../../component/helpline-card-item/helpline-card-item.component';
import { TranslateModule } from '@ngx-translate/core';
import { PageLayoutComponent } from '../../../shared/components/page-layout/page-layout.component';
import { AanmeldButtonComponent } from '../../../shared/components/aanmeld-button/aanmeld-button.component';
import { DownloadAppsComponent } from '../../../shared/components/download-apps/download-apps.component';
import { OnboardingComponent } from '../../component/onboarding/onboarding.component';
import { ThemaButtonComponent } from '../../component/thema-button/thema-button.component';
import { OverParkourComponent } from '../../component/over-parkour/over-parkour.component';
import { ParkourDataPage } from '../../../shared/parkour-data-page';
import {
  IonContent,
  IonHeader,
  IonRefresher,
  IonRefresherContent,
} from '@ionic/angular/standalone';
import { ParkourRefreshDirective } from '../../../shared/directives/parkour-refresh-directive';
import { PageSectionDirective } from '../../../shared/directives/page-section.directive';
import { OrganisationWithBookmark } from '../../../shared/model/artikel';
import AuthService from '../../../authentication/service/auth.service';
import { SysteemConfigService } from '../../../shared/services/systeem-config.service';
import { ShowIfSuccesfulDirective } from '../../../shared/directives/show-if-succesful.directive';
import { LinkifyPipe } from '../../../bericht/pipes/linkify.pipe';
import { ContactMethodeNietGeverifieerdCardComponent } from '../../../profiel/component/contact-methode-niet-geverifieerd-card/contact-methode-niet-geverifieerd-card.component';

type HomePageData = {
  artikels: ArtikelWithBookmarkQueryResult;
  hulplijnen: OrganisationWithBookmark[];
};

@Component({
  standalone: true,
  templateUrl: './home.page.html',
  imports: [
    ParkourPictureComponent,
    ParkourDividerComponent,
    AsyncPipe,
    ParkourButtonComponent,
    FormsModule,
    ParkourFormFieldComponent,
    ParkourInputComponent,
    NgForOf,
    ParkourIconComponent,
    HelplineCardItemComponent,
    TranslateModule,
    PageLayoutComponent,
    AanmeldButtonComponent,
    DownloadAppsComponent,
    OnboardingComponent,
    ThemaButtonComponent,
    ArtikelsPreviewComponent,
    OverParkourComponent,
    IonRefresher,
    IonRefresherContent,
    IonContent,
    IonHeader,
    ParkourRefreshDirective,
    PageSectionDirective,
    ParkourInfoCardWithTitleComponent,
    ShowIfSuccesfulDirective,
    LinkifyPipe,
    ContactMethodeNietGeverifieerdCardComponent,
  ],
})
export class HomePage extends ParkourDataPage<HomePageData> {
  isAangemeld$ = this.authService.user$.pipe(map((user) => user.type === 'aangemeld'));
  showLoginButton$ = this.authService.user$.pipe(map((user) => user.type === 'anoniem'));

  fixedOrganisationIds = [33, 61, 56, 11, 76];

  searchTerm = '';

  public onboardingSlides: TOnboardingSlides = AanDeSlagData;

  public showMoreThemes = false;
  homePageBasisThemas: Thema[] = [
    'ALCOHOL_DRUGS',
    'GELD',
    'IDENTITEIT',
    'LIEFDE',
    'MENTAAL_WELZIJN',
    'MIJN_GEZONDHEID',
  ];
  homePageMeerThemas: Thema[] = [
    'SCHOOL',
    'SEKS',
    'VRIENDEN_EN_FAMILIE',
    'VRIJE_TIJD',
    'WERELD',
    'WERK',
    'WONEN',
  ];
  protected readonly offlineTeleOnthaalOrganisation = teleOnthaalOrganisation;
  protected readonly isNativeApp = isNativeApp;
  protected readonly String = String;

  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly bookmarkService: BookmarkService,
    private readonly watwatService: WatwatService,
    private readonly systeemConfigService: SysteemConfigService,
  ) {
    super();
  }

  systeemConfig$ = this.extraPageData(this.systeemConfigService.getSysteemConfig());

  override retrieveMainData(): Observable<HomePageData> {
    return combineLatest([
      this.watwatService.getOrganisationsOfIds(this.fixedOrganisationIds),
      this.watwatService.getArtikelSummaries({ amount: 5 }),
    ]).pipe(
      map(([hulplijnen, artikels]) => ({
        hulplijnen: this.reorderOrganisations(hulplijnen),
        artikels,
      })),
    );
  }

  override ionViewWillEnter() {
    super.ionViewWillEnter();
  }

  getQueryParams() {
    if (this.searchTerm === '') {
      return {};
    }

    return {
      query: this.searchTerm,
    };
  }

  clearSearchTerm() {
    this.router.navigate(['artikels'], {
      relativeTo: this.activatedRoute,
      queryParams: this.getQueryParams(),
    });

    this.searchTerm = '';
  }

  onArtikelBookmarkClicked({
    hasBookmarked,
    artikelId,
    bookmarkId,
  }: TParkourArtikelsPreviewBookmarkEvent) {
    if (hasBookmarked) {
      this.bookmarkService
        .addBookmark({
          itemId: String(artikelId),
          itemType: 'ARTIKEL',
        })
        .subscribe(() => this.refreshMainData());
    } else {
      if (bookmarkId) {
        this.bookmarkService.deleteBookmark(bookmarkId).subscribe(() => this.refreshMainData());
      }
    }
  }

  reorderOrganisations(organisations: OrganisationWithBookmark[]): OrganisationWithBookmark[] {
    const teleonthaalOrganisation = 76;
    const OverkopOrganisation = 56;
    const topOrganisation = organisations.find(
      (organisation) => organisation.id === OverkopOrganisation,
    );
    const bottomOrganisation = organisations.find(
      (organisation) => organisation.id === teleonthaalOrganisation,
    );
    const middleOrganisations = organisations.filter(
      (organisation) =>
        organisation.id !== OverkopOrganisation && organisation.id !== teleonthaalOrganisation,
    );

    return [
      ...(topOrganisation ? [topOrganisation] : []),
      ...middleOrganisations,
      ...(bottomOrganisation ? [bottomOrganisation] : []),
    ];
  }

  protected readonly JSON = JSON;
}
