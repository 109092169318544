<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button parkourBackButton />
    </ion-buttons>

    <ion-title aria-level="1" role="heading"
      >{{ 'profiel.meldingen-page.title' | translate }}
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-refresher [parkourRefresh]="onPageRefresh" slot="fixed">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>
  <parkour-page-layout>
    <parkour-page-gradient />

    <div class="relative z-10 py-8 container container-space space-y-8">
      <div class="flex flex-col space-y-4 container-1-col">
        @if (paginatedData$ | async; as meldingenData) {
          @if (data$ | async; as data) {
            @if (data.context.type === 'teamlid') {
              <ion-radio-group
                class="flex flex-row gap-4 relative"
                name="filter-context"
                [value]="data.showCurrentContextOnly ? 'contextOnly' : 'all'"
                (ionChange)="onFilterChange($event)"
              >
                <ion-radio
                  class="rounded h-[3rem] relative items-center align-middle pl-2"
                  [value]="'all'"
                  mode="ios"
                >
                  <div class="flex flex-row grow items-center align-middle gap-x-2">
                    <parkour-foto-badge
                      [background]="'transparant'"
                      [image]="{ type: 'icon', name: 'people' }"
                      size="2"
                      class="block"
                    />
                    <span class="flex-1 typo-body whitespace-normal">Alle meldingen</span>
                  </div>
                </ion-radio>
                <ion-radio
                  class="rounded h-[3rem] relative items-center align-middle pl-2"
                  [value]="'contextOnly'"
                  mode="ios"
                >
                  <div class="flex flex-row grow items-center align-middle gap-x-2">
                    <parkour-profiel-foto-badge
                      [alt]="false"
                      [profiel]="data.context.jongereProfiel"
                      [size]="'2'"
                      class="block"
                    >
                    </parkour-profiel-foto-badge>
                    <span class="flex-1 typo-body whitespace-normal">{{
                      getProfielnaam(data.context.jongereProfiel)
                    }}</span>
                  </div>
                </ion-radio>
              </ion-radio-group>
              <!--                //TODO delete if current design is OK-->
              <!--                <parkour-checkbox-->
              <!--                  (checkboxChange)="onFilterChangeCheckBox($event)"-->
              <!--                  [checked]="data.showCurrentContextOnly"-->
              <!--                  >Enkel meldingen van team-->
              <!--                  {{ getProfielnaam(data.context.jongereProfiel) }}-->
              <!--                </parkour-checkbox>-->
            }
            @if (data.showCurrentContextOnly) {
              <parkour-button
                class="mt-2"
                variant="ghost-light"
                (clicked)="onMarkMeldingenInCurrentContextAsReadClick(data.context.contextId)"
                >Deze meldingen als gelezen tonen ({{
                  meldingenData.totalOngelezenMeldingen
                }})</parkour-button
              >
            } @else {
              <parkour-button class="mt-2" variant="ghost-light" (clicked)="onMarkAsReadClick()"
                >Alle meldingen als gelezen tonen ({{
                  meldingenData.totalOngelezenMeldingen
                }})</parkour-button
              >
            }
          }
          @if (meldingenData.content.length > 0 || !meldingenData.last) {
            <ul class="space-y-4 container-1-col">
              @if (loading) {
                <parkour-loading-spinner />
              } @else {
                <div class="h-[1.5rem]"></div>
              }
              @for (melding of meldingenData.content; track melding.id) {
                <li>
                  @switch (melding.type) {
                    @case ('supported') {
                      <parkour-melding
                        (meldingClicked)="handleMeldingClicked(melding)"
                        [meldingView]="melding"
                      />
                    }
                    @case ('unsupported') {
                      <parkour-unsuppored-melding [meldingView]="melding" />
                    }
                  }
                </li>
              }
            </ul>
          } @else {
            <p class="text-center">{{ 'meldingen.overview-page.geen-meldingen' | translate }}</p>
            <div>
              <parkour-block-placeholder
                [sources]="{
                  default: {
                    src: '/assets/meldingen/placeholder/placeholder_meldingen_default.svg',
                  },
                  md: {
                    src: '/assets/meldingen/placeholder/placeholder_meldingen_md.svg',
                  },
                }"
                alt=""
                class="block"
              />
            </div>
          }
          <ion-infinite-scroll
            (ionInfinite)="fetchNextPage($event)"
            [disabled]="meldingenData.last"
            [threshold]="'0'"
          >
            <div class="infinite-scroll-content">
              <parkour-loading-spinner />
            </div>
          </ion-infinite-scroll>
        } @else if (error) {
          <parkour-content-unavailable-card />
        }
      </div>
    </div>
  </parkour-page-layout>
</ion-content>
