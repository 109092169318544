import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { TemplatePortal } from '@angular/cdk/portal';

@Injectable({ providedIn: 'root' })
export class SheetService {
  get closeTrigger$(): Observable<void> {
    return this._closeTrigger$.asObservable();
  }

  private isOpen$ = new BehaviorSubject<boolean>(false);
  private _closeTrigger$ = new Subject<void>();
  private portal$ = new BehaviorSubject<TemplatePortal | null>(null);

  get isOpen(): Observable<boolean> {
    return this.isOpen$.asObservable();
  }

  get portal(): Observable<TemplatePortal | null> {
    return this.portal$.asObservable();
  }

  open(portal: TemplatePortal) {
    this.isOpen$.next(true);
    this.portal$.next(portal);
  }

  close() {
    this.isOpen$.next(false);
    this.portal$.next(null);
  }

  triggerClose() {
    this._closeTrigger$.next();
  }
}
