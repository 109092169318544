import { Pipe, PipeTransform } from '@angular/core';
import { Rol } from 'parkour-web-app-dto';
import { getRolLabel } from '../../profiel/rol-labels';

@Pipe({
  standalone: true,
  name: 'rolLabelDefault',
})
export class RolLabelWithDefaultPipe implements PipeTransform {
  constructor() {}

  transform(rol: Rol | undefined | null, defaultRolString = 'Teamlid'): string {
    return rol ? getRolLabel(rol) : defaultRolString;
  }
}
