<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button parkourBackButton />
    </ion-buttons>

    <ion-title aria-level="1" role="heading"
      >{{ 'profiel.notitie-add-page.title' | translate }}
    </ion-title>

    <ion-buttons slot="end">
      <parkour-header-action
        [label]="'bewaard.notitie-save' | translate"
        form="notitie-form"
        icon="disk"
        testId="submit"
        type="submit"
      />
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <parkour-page-layout>
    <parkour-page-gradient />

    <div class="relative z-10 py-8 container container-space">
      <parkour-notitie-form
        #notitieForm
        (formSubmit)="onNotitieSubmit($event)"
        class="block container-1-col"
      />
    </div>
  </parkour-page-layout>
</ion-content>
