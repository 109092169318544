import { Component, Input } from '@angular/core';
import { IonContent, IonHeader, IonTitle, IonToolbar } from '@ionic/angular/standalone';
import {
  ParkourButtonComponent,
  ParkourDividerComponent,
  ParkourImageComponent,
  ParkourLoadingSpinnerComponent,
  ParkourModalService,
} from '@parkour/ui';
import { AsyncPipe, NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { PageLayoutComponent } from '../../shared/components/page-layout/page-layout.component';
import { Subscription } from 'rxjs';

@Component({
  standalone: true,
  templateUrl: './biometrics-login-modal.component.html',
  imports: [
    ParkourImageComponent,
    AsyncPipe,
    NgIf,
    ParkourDividerComponent,
    ParkourLoadingSpinnerComponent,
    ParkourButtonComponent,
    TranslateModule,
    PageLayoutComponent,
    IonHeader,
    IonContent,
    IonTitle,
    IonToolbar,
  ],
})
export class BiometricsLoginModalComponent {
  @Input({ required: true }) aanmeldenClickCallback!: () => void;
  @Input({ required: true }) modalId!: string;
  eventsSubscription?: Subscription;

  constructor(private modalService: ParkourModalService) {}

  onCancelClicked() {
    this.modalService.closeModal(this.modalId);
  }
}
