import { Injectable } from '@angular/core';
import { PushNotifications, PushNotificationSchema, Token } from '@capacitor/push-notifications';
import { asType, isNativeApp } from '../../utils';
import { AangemeldeUser, User } from '../../authentication/user';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { RegistreerVoorMeldingenDto } from 'parkour-web-app-dto';
import { PluginListenerHandle } from '@capacitor/core';
import { MeldingenService } from './meldingen.service';
import { LocalNotifications } from '@capacitor/local-notifications';
import { Router } from '@angular/router';
import { LoggingService } from '../../core/logging.service';
import AuthService from '../../authentication/service/auth.service';
import BackgroundDetectionService from '../../authentication/service/background-detection.service';
import { map } from 'rxjs';
import { AnalyticsService } from '../../analytics/analytics.service';
import { AnalyticsEvent, trackAnalyticsEvent } from '../../analytics/analytics-event.model';

@Injectable({
  providedIn: 'root',
})
export class PushNotificatieService {
  tokenRegistrationSubscription?: PluginListenerHandle;

  constructor(
    private readonly loggingService: LoggingService,
    private readonly meldingenService: MeldingenService,
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly http: HttpClient,
    private readonly backgroundDetectionService: BackgroundDetectionService,
    private readonly analyticsService: AnalyticsService,
  ) {}

  private async attemptRegisterForNotifications(user: User) {
    if (user.type === 'aangemeld' && isNativeApp()) {
      await this.registerForPushNotifications(user);
    }
  }

  private async registerForPushNotifications(user: AangemeldeUser): Promise<void> {
    this.tokenRegistrationSubscription?.remove();
    this.tokenRegistrationSubscription = await PushNotifications.addListener(
      'registration',
      (token) => this.onTokenReceived(user, token),
    );

    let permStatus = await PushNotifications.checkPermissions();

    if (permStatus.receive === 'prompt') {
      permStatus = await PushNotifications.requestPermissions();
    }

    if (permStatus.receive === 'granted') {
      await PushNotifications.register();
    }
  }

  private onTokenReceived(user: AangemeldeUser, token: Token) {
    this.registerToken(user.profielId, token.value).subscribe();
  }

  private registerToken(persoonId: string, value: string) {
    return this.http
      .post(
        `${environment.API_BASE_URL}/api/meldingen/persoon/${persoonId}/pushNotificatieRegistratie`,
        asType<RegistreerVoorMeldingenDto>({ token: value }),
      )
      .pipe(
        trackAnalyticsEvent(
          this.analyticsService,
          new AnalyticsEvent('meldingen', 'geregisreerdVoorPushNotificaties'),
        ),
      );
  }

  public initialize() {
    if (isNativeApp()) {
      this.addHandlers();
    }

    this.authService.user$.subscribe((user) => this.attemptRegisterForNotifications(user));
  }

  async displayLocalNotification(notification: PushNotificationSchema) {
    this.loggingService.log('Attempting to schedule local notification', notification.body);
    const permissionStatus = await LocalNotifications.checkPermissions();
    if (permissionStatus.display === 'granted') {
      await LocalNotifications.schedule({
        notifications: [
          {
            title: '',
            body: notification.body || '',
            id: new Date().getUTCDate(),
            schedule: { at: new Date(new Date().getTime() + 500) },
            extra: {
              ...notification.data,
            },
          },
        ],
      });
    }
  }

  private async addHandlers() {
    await PushNotifications.addListener('registrationError', (err) => {
      this.loggingService.error('Registration error: ', err.error);
    });
    await PushNotifications.addListener('pushNotificationReceived', (notification) => {
      this.loggingService.log('Push notification notification received: ', notification.data);

      this.meldingenService.fetchOngelezenMeldingen();

      const meldingId = notification.data.meldingId;

      if (meldingId) {
        this.meldingenService.isMeldingSupportedEnGelezen(meldingId).subscribe((gelezen) => {
          if (!gelezen) {
            this.displayLocalNotification(notification);
          } else {
            this.loggingService.log('Melding is al gelezen, wordt niet getoond');
          }
        });
      }
    });

    await PushNotifications.addListener('pushNotificationActionPerformed', (notification) => {
      this.loggingService.log(
        'Push notification action performed: ',
        notification.notification.data,
      );

      this.backgroundDetectionService
        .waitUntilActive()
        .pipe(
          map(() => {
            this.meldingenService.fetchOngelezenMeldingen();

            const meldingId = notification.notification.data.meldingId;

            if (meldingId) {
              this.navigateToMelding(meldingId);
            }
          }),
        )
        .subscribe();
    });
    await LocalNotifications.addListener('localNotificationActionPerformed', (notification) => {
      this.loggingService.log('Local notification action performed', notification);

      this.backgroundDetectionService
        .waitUntilActive()
        .pipe(
          map(() => {
            const meldingId = notification.notification.extra.meldingId;
            if (meldingId) {
              this.navigateToMelding(meldingId);
            }
          }),
        )
        .subscribe();
    });

    this.loggingService.log('Added push notification handlers');
  }

  private navigateToMelding(meldingId: string) {
    this.router.navigate(['app', 'me', 'profiel', 'meldingen'], { queryParams: { meldingId } });
  }
}
