export const berichtenAnalyticsEvents = [
  'tekstBerichtVerstuurd',
  'bestandBerichtVerstuurd',
  'spraakBerichtVerstuurd',
  'berichtVerwijderd',
  'berichtEmojiToegevoegd',
  'berichtReactieToegevoegd',
  'berichtReactieVerwijderd',
  'berichtGerapporteerd',
  'berichtBewaard',
  'groepsgesprekAangemaakt',
  'groepsgesprekAfbeeldingToegevoegd',
  'groepsgesprekAfbeeldingVerwijderd',
  'groepsgesprekVerlaten',
  'gesprekGearchiveerd',
  'bewaarAlsNotitieClick',
] as const;
