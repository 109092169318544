import { Component, Input } from '@angular/core';
import { ProfielFotoData } from '../../model/teamlid-info';
import { AsyncPipe } from '@angular/common';
import { ProfielFotoPipe } from '../../pipes/profiel-foto.pipe';
import { FotoBadgeComponent } from '../foto-badge/foto-badge.component';

export type ProfielFotoBadgeSize = 'full-size' | '5' | '4' | '3.5' | '3' | '2.5' | '2' | '1.5';

@Component({
  selector: 'parkour-profiel-foto-badge',
  templateUrl: './profiel-foto-badge.component.html',
  standalone: true,
  imports: [AsyncPipe, ProfielFotoPipe, FotoBadgeComponent],
})
export class ProfielFotoBadgeComponent {
  @Input({ required: true }) public profiel!: ProfielFotoData;
  @Input({ required: true }) public alt!: false | string;
  @Input({ required: true }) public size!: ProfielFotoBadgeSize;
  @Input() badgeCount?: number | undefined;
  @Input() grayout? = false;

  getIconNaam(
    profiel:
      | {
          status: 'GEBLOKKEERD';
        }
      | {
          status: 'INACTIEF';
        }
      | {
          status: 'ONBEKEND';
        }
      | {
          status: 'UNSUPPORTED';
        }
      | {
          status: 'GEBLOKKEERDE_WEERGAVE';
        }
      | {
          status: 'INACTIEVE_WEERGAVE';
        },
  ) {
    switch (profiel.status) {
      case 'GEBLOKKEERD':
        return 'people-blocked';
      case 'INACTIEF':
        return 'people-remove';
      case 'ONBEKEND':
        return 'people';
      case 'UNSUPPORTED':
        return 'people';
      case 'GEBLOKKEERDE_WEERGAVE':
        return 'people-blocked';
      case 'INACTIEVE_WEERGAVE':
        return 'people-remove';
    }
  }
}
