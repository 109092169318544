import { Component, OnInit } from '@angular/core';
import {
  ParkourButtonComponent,
  ParkourFormFieldComponent,
  ParkourIconComponent,
  ParkourInputComponent,
  ParkourPageGradientComponent,
  ParkourRadioComponent,
  ParkourToastService,
} from '@parkour/ui';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonRadioGroup,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
  ViewDidLeave,
  ViewWillEnter,
} from '@ionic/angular/standalone';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ParkourPage } from '../../../shared/parkour-page';
import { getDateFromTimeString, isNativeApp } from '../../../utils';
import { environment } from '../../../../environments/environment';
import { ParkourBackButtonDirective } from '../../../shared/directives/parkour-back-button.directive';
import { PageLayoutComponent } from '../../../shared/components/page-layout/page-layout.component';
import { ParkourRefreshDirective } from '../../../shared/directives/parkour-refresh-directive';
import { ParkourHeaderActionComponent } from '../../../shared/components/parkour-header-action/parkour-header-action.component';
import { DatePipe } from '@angular/common';
import { ParkourFormComponent } from '../../../shared/components/parkour-form/parkour-form.component';
import { RawValueOfFormGroup } from '../../../shared/components/parkour-form/parkour-form.types';
import { Observable, of, switchMap, tap } from 'rxjs';
import { PushberichtenSettingsDto, UpdatePushberichtenSettingsDto } from 'parkour-web-app-dto';
import { ProfielService } from '../../service/profiel.service';
import { ActivatedRoute, Router } from '@angular/router';

export type NotificationPauzeType = 'ONE_HOUR' | 'EIGHT_HOUR' | 'ONE_WEEK' | 'ALWAYS' | 'CUSTOM';
const notificationPauzeTypeLabels: { [key in NotificationPauzeType]: string } = {
  ONE_HOUR: '1 uur',
  EIGHT_HOUR: '8 uur',
  ONE_WEEK: '1 week',
  ALWAYS: 'Altijd',
  CUSTOM: 'Aangepast',
} as const;

export function getPauzeNotificationsTypeLabel(pauzeType: NotificationPauzeType): string {
  return notificationPauzeTypeLabels[pauzeType];
}

type PauzeForm = FormGroup<{
  pauzeType: FormControl<NotificationPauzeType | undefined>;
  customToDate: FormControl<string | undefined>;
}>;

export const dateNotInThePastValidator: ValidatorFn = (
  form: AbstractControl,
): ValidationErrors | null => {
  const formDate = form?.getRawValue();

  if (!formDate) {
    return null;
  }

  const fromDateValue = new Date(formDate);
  const now = new Date();
  return fromDateValue < now ? { dateInPastInvalid: true } : null;
};

@Component({
  selector: 'parkour-instellingen',
  standalone: true,
  imports: [
    FormsModule,
    TranslateModule,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonBackButton,
    ParkourBackButtonDirective,
    IonTitle,
    IonContent,
    IonRefresher,
    IonRefresherContent,
    PageLayoutComponent,
    ParkourPageGradientComponent,
    ParkourRefreshDirective,
    IonRadioGroup,
    ParkourRadioComponent,
    ParkourHeaderActionComponent,
    ParkourFormFieldComponent,
    ParkourInputComponent,
    ParkourIconComponent,
    ReactiveFormsModule,
    ParkourFormComponent,
    ParkourButtonComponent,
    DatePipe,
  ],
  templateUrl: './pushberichten-page.component.html',
})
export class PushberichtenPage extends ParkourPage implements ViewWillEnter, OnInit, ViewDidLeave {
  pauzeForm!: PauzeForm;
  currentPauzeType: NotificationPauzeType | null = null;
  currentSettings!: PushberichtenSettingsDto;
  todayString = new Date().toISOString().slice(0, 16);
  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly datePipe: DatePipe,
    private readonly profielService: ProfielService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly parkourToastService: ParkourToastService,
  ) {
    super();
  }

  ionViewDidLeave(): void {
    this.currentPauzeType = null;
    this.pauzeForm.reset();
  }

  override ionViewWillEnter() {
    super.ionViewWillEnter();
    this.profielService
      .getCurrentUserProfiel$()
      .pipe(switchMap((profiel) => this.meldingenService.getPushberichtenInstellingen(profiel.id)))
      .subscribe((currentSettings) => {
        this.currentSettings = currentSettings;
      });
  }

  ngOnInit(): void {
    this.pauzeForm = this.formBuilder.nonNullable.group({
      pauzeType: this.formBuilder.nonNullable.control<NotificationPauzeType | undefined>(undefined),
      customToDate: this.formBuilder.nonNullable.control<string | undefined>(undefined),
    });
  }

  onSubmit = (pauzeFormData: RawValueOfFormGroup<PauzeForm>): Observable<void> => {
    let updateDto: UpdatePushberichtenSettingsDto;
    let successMessage;
    const pauzeUntil = this.calculatePauzeUntil(pauzeFormData);
    if (this.currentPauzeType === 'ALWAYS') {
      updateDto = { pauzeUntil: undefined, pauzeAlways: true };
      successMessage = 'Je pushberichten zijn gepauzeerd.';
    } else if (pauzeUntil) {
      updateDto = { pauzeUntil: pauzeUntil.toISOString(), pauzeAlways: false };
      successMessage =
        'Je pushberichten zijn gepauzeerd tot en met ' +
        this.datePipe.transform(pauzeUntil, 'dd.MM.yyyy') +
        ' om ' +
        this.datePipe.transform(pauzeUntil, 'HH:mm');
    } else {
      return of(undefined);
    }

    return this.updatePushberichtenInstellingen(updateDto).pipe(
      tap(() => {
        this.parkourToastService.showToast({
          header: 'Instellingen bewaard',
          content: successMessage,
        });
        this.router.navigate(['..'], { relativeTo: this.route });
      }),
    );
  };

  private updatePushberichtenInstellingen(updateDto: UpdatePushberichtenSettingsDto) {
    return this.profielService
      .getCurrentUserProfiel$()
      .pipe(
        switchMap((profiel) =>
          this.meldingenService.updatePushberichtenInstellingen(profiel.id, updateDto),
        ),
      );
  }

  protected readonly isNativeApp = isNativeApp;

  protected readonly environment = environment;
  protected readonly getPauzeNotificationsTypeLabel = getPauzeNotificationsTypeLabel;

  onSelectPauzeType(event: CustomEvent) {
    this.currentPauzeType = event.detail.value;
    const customToDateControl = this.pauzeForm.get('customToDate');
    if (this.currentPauzeType && this.currentPauzeType === 'CUSTOM') {
      customToDateControl?.setValidators([Validators.required, dateNotInThePastValidator]);
    } else {
      customToDateControl?.clearValidators();
    }
    customToDateControl?.updateValueAndValidity();
  }

  private calculatePauzeUntil(pauzeFormData: RawValueOfFormGroup<PauzeForm>): Date | undefined {
    if (!this.currentPauzeType) return undefined;
    const now = new Date();
    switch (this.currentPauzeType) {
      case 'ONE_HOUR': {
        const oneHourLater = new Date(now.getTime() + 1 * 60 * 60 * 1000); // 1 hour = 3600000 ms
        return oneHourLater;
      }
      case 'EIGHT_HOUR': {
        const eightHoursLater = new Date(now.getTime() + 8 * 60 * 60 * 1000);
        return eightHoursLater;
      }
      case 'ONE_WEEK': {
        const oneWeekLater = new Date(now.getTime() + 7 * 24 * 60 * 60 * 1000);
        return oneWeekLater;
      }
      case 'ALWAYS': {
        return now;
      }
      case 'CUSTOM': {
        return pauzeFormData.customToDate ? new Date(pauzeFormData.customToDate) : now;
      }
    }
  }

  getToDateErrors(): Record<string, string> {
    return {
      ...this.pauzeForm.errors,
      ...this.pauzeForm.controls.customToDate.errors,
    };
  }

  updateTimes() {}

  getShortTime(date: Date): string {
    return this.datePipe.transform(date, 'shortTime') || '';
  }

  roundTime(date: Date): Date {
    const roundedDate = new Date(date);

    roundedDate.setSeconds(0);
    roundedDate.setMilliseconds(0);

    return roundedDate;
  }

  minEndTimeValidator(): ValidatorFn {
    return (form: AbstractControl): ValidationErrors | null => {
      const startTime = getDateFromTimeString(form.value.van);
      const endTime = getDateFromTimeString(form.value.tot);

      if (endTime.getTime() <= startTime.getTime()) {
        return { totMustBeGreaterThanVan: true };
      }

      return null;
    };
  }

  onResetPauzeSettings() {
    this.updatePushberichtenInstellingen({ pauzeAlways: false, pauzeUntil: undefined })
      .pipe(
        tap(() => {
          this.parkourToastService.showToast({
            header: 'Instellingen bewaard',
            content: 'Je pushberichten zijn niet meer gepauzeerd',
          });
          this.router.navigate(['..'], { relativeTo: this.route });
        }),
      )
      .subscribe();
  }
}
