import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from "@angular/core";
import { IonContent } from "@ionic/angular/standalone";
import { NgClass, NgIf } from "@angular/common";
import { ParkourButtonComponent } from "../parkour-button/parkour-button.component";

@Component({
  selector: "parkour-pagination",
  templateUrl: "./parkour-pagination.component.html",
  standalone: true,
  imports: [NgClass, ParkourButtonComponent, NgIf],
})
export class ParkourPaginationComponent implements OnInit, OnChanges {
  @Input() public classes?: string;
  @Input() public currentPage = 1;
  @Input({ required: true }) public pageSize!: number;
  @Input({ required: true }) public contentRef!: IonContent;
  @Input({ required: true }) public collectionSize!: number;

  @Output() public clicked: EventEmitter<number> = new EventEmitter<number>();
  public maxSize = 2;
  public totalPages = 0;
  public totalPagesArray: number[] = [];

  ngOnInit(): void {
    this.totalPages = Math.ceil(this.collectionSize / this.pageSize);
    this.totalPagesArray = new Array(this.totalPages).slice(0, -1);
  }

  ngOnChanges() {
    this.totalPages = Math.ceil(this.collectionSize / this.pageSize);
    this.totalPagesArray = new Array(this.totalPages).slice(0, -1);
  }

  selectPageNumber(pageNumber: number) {
    this.currentPage = pageNumber;
    this.scrollToTop();

    this.clicked.emit(pageNumber);
  }

  next() {
    const nextPage = this.currentPage + 1;

    nextPage <= this.totalPages && this.selectPageNumber(nextPage);
  }

  previous() {
    const previousPage = this.currentPage - 1;

    previousPage >= 1 && this.selectPageNumber(previousPage);
  }

  scrollToTop() {
    this.contentRef.scrollToTop(300);
  }
}
