import { Routes } from '@angular/router';
import {
  actiefAlsExJongeMatcher,
  actiefAlsJongereMatcher,
  actiefAlsTeamlidMatcher,
  actiefVoorMezelfMatcher,
} from '../core/matchers';
import { doelJongereRoutes } from './doel-jongere/routes';
import { doelTeamlidRoutes } from './doel-teamlid/routes';
import { DoelenEmptyStateNoTeamPage } from './doel-shared/page/doelen-empty-state-no-team-page/doel-empty-state-no-team.page';
import { DoelenEmptyStateNotAangemeldPage } from './doel-shared/page/doelen-empty-state-not-aangemeld-page/doel-empty-state-not-aangemeld.page';
import { doelExJongereRoutes } from './doel-ex-jongere/routes';

export const doelRoutes: Routes = [
  {
    path: '',
    canMatch: [actiefAlsJongereMatcher],
    children: doelJongereRoutes,
  },
  {
    path: '',
    canMatch: [actiefAlsTeamlidMatcher],
    children: doelTeamlidRoutes,
  },
  {
    path: '',
    canMatch: [actiefVoorMezelfMatcher],
    component: DoelenEmptyStateNoTeamPage,
    data: { tabRoot: true },
  },
  {
    path: '',
    canMatch: [actiefAlsExJongeMatcher],
    children: doelExJongereRoutes,
  },
  {
    path: '',
    component: DoelenEmptyStateNotAangemeldPage,
    data: { tabRoot: true },
  },
];
