import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Uitnodiging } from '../../../uitnodiging/model/uitnodiging';
import { ParkourButtonComponent, ParkourCardComponent, ParkourPopupService } from '@parkour/ui';
import { TranslateModule } from '@ngx-translate/core';
import { RolLabelPipe } from '../../../shared/pipes/rol-label.pipe';

@Component({
  standalone: true,
  selector: 'parkour-uitnodiging-confirmation',
  templateUrl: './uitnodiging-confirmation.component.html',
  imports: [ParkourCardComponent, TranslateModule, ParkourButtonComponent, RolLabelPipe],
})
export class UitnodigingConfirmationComponent {
  @Input({ required: true }) uitnodiging!: Uitnodiging;
  @Output() bevestigUitnodiging = new EventEmitter<void>();
  @Output() verwijderUitnodiging = new EventEmitter<void>();

  constructor(private readonly parkourPopupService: ParkourPopupService) {}

  onNeeClick() {
    // TODO: Add UX Copy to JSON
    this.parkourPopupService
      .showPopup({
        jaText: 'Ja',
        icon: 'trash',
        title: 'Uitnodiging verwijderen',
        description: `Ben je zeker dat je de uitnodiging van ${this.uitnodiging.initiator} wil verwijderen?`,
      })
      .then((result) => {
        if (result === 'yes') {
          this.verwijderUitnodiging.emit();
        }
      });
  }

  onJaClick() {
    this.bevestigUitnodiging.emit();
  }
}
