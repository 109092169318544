import { Route } from '@angular/router';
import { dirtyFormGuard } from '../shared/components/dirty-form-guard';
import { GebeurtenisAddPage } from './verhaal-shared/page/gebeurtenis-add-page/gebeurtenis-add.page';

export const verhaalCreateRoutes: Route[] = [
  {
    path: '',
    pathMatch: 'full',
    title: 'verhaal.gebeurtenis-add-page.title',
    component: GebeurtenisAddPage,
    canDeactivate: [dirtyFormGuard],
  },
];
