import { Bericht, Gesprek, JongereDeelnemer, TeamlidDeelnemer } from './gesprek';
import { getRolLabelForTeamlid } from '../../profiel/rol-labels';
import { AangemeldeUser } from '../../authentication/user';
import { getProfielnaam } from '../../profiel/model/profiel-in-team';
import { GesprekId, GesprekStatus, ProfielId, VideogesprekId } from 'parkour-web-app-dto';

// TODO: UX Copy berichten in JSON
export class EenOpEenGesprek extends Gesprek {
  constructor(
    id: GesprekId,
    partners: TeamlidDeelnemer[],
    jongere: JongereDeelnemer,
    aangemaaktOp: string,
    status: GesprekStatus,
    laatsteBericht?: Bericht,
    public readonly videogesprekId?: VideogesprekId,
  ) {
    super(id, partners, jongere, aangemaaktOp, status, laatsteBericht);
  }

  public override isGroepsgesprek(): boolean {
    return false;
  }

  public override getGearchiveerdMessage(): string {
    return 'Het gesprek is stopgezet.';
  }

  public override getGesprekNaam(user: AangemeldeUser): string {
    return getProfielnaam(this.getVisibleDeelnemer(user.profielId));
  }

  public override getSubtitleForHeader(user: AangemeldeUser): string {
    const profiel = this.getVisibleDeelnemer(user.profielId);

    if (profiel.viewType === 'JONGERE') {
      return 'Teameigenaar';
    } else {
      return getRolLabelForTeamlid(profiel);
    }
  }

  public getTeamlidDeelnemer(): TeamlidDeelnemer {
    return this.partners[0];
  }

  public getVideoGesprekNaam(): string {
    return getProfielnaam(this.jongere) + ' - ' + getProfielnaam(this.getTeamlidDeelnemer());
  }

  public override getVisibleDeelnemer(
    userProfielId: ProfielId,
  ): TeamlidDeelnemer | JongereDeelnemer {
    const result = this.getDeelnemersAndJongereIncludingRemoved().find(
      (deelnemer) => deelnemer.id !== userProfielId,
    );

    return result ?? this.jongere;
  }
}
