<div class="flex flex-col">
  @if ((!isUser && gesprek.isGroepsgesprek()) || isNameVisible) {
    <span class="bericht-message-title typo-body variant-body-small variant-body-fat">
      <ng-container *ngIf="bericht.deelnemerId | profiel | async as profiel"
        >{{ profiel | profielnaam }}
      </ng-container>
    </span>
  }

  <span class="sr-only">
    @if (isUser) {
      <ng-container *ngIf="bericht.deelnemerId | profiel | async as profiel"
        >{{ profiel | profielnaam }}:
      </ng-container>
    } @else {
      {{ 'berichten.gesprek-page.me' | translate }}:
    }
  </span>

  <parkour-audio-player
    *ngIf="audioFile$ | async as audioFile"
    [audioFile]="audioFile"
    class="flex-1"
  />
</div>
