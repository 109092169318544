<ng-container *parkourIsUserWithProfiel>
  @if (variant === 'iconButton') {
    <parkour-button
      [ngClass]="{ 'hover:opacity-50': !isActive }"
      class="transition-opacity"
      (clicked)="onClick()"
      [ariaLabel]="getAriaLabelIconButton()"
      [ariaPressed]="isActive"
      format="iconButton"
      [icon]="isActive ? 'bookmark-fill' : 'bookmark'"
      iconSize="3"
      variant="ghost"
    />
  } @else {
    <parkour-button
      (clicked)="onClick()"
      [ariaLabel]="getAriaLabelButton()"
      [ariaPressed]="isActive"
      [iconAfter]="isActive ? 'bookmark-fill' : 'bookmark'"
      variant="tertiary"
      size="small"
    >
      {{ getLabel() }}
    </parkour-button>
  }
</ng-container>
