import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import './app/core/opentelemetry';
import './app/core/load-js';
import { loadJs } from './app/core/load-js';

loadJs(environment.jitsiApiEmbedCode);

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule) // eslint-disable-next-line no-console
  .catch((err) => console.log(err));
