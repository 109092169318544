import { Component } from '@angular/core';
import { VerhaalService } from '../../../verhaal-shared/service/verhaal.service';
import { Observable, switchMap } from 'rxjs';
import { ContextService } from '../../../../shared/services/context.service';
import { VerhaalDto } from 'parkour-web-app-dto';
import { meldingenForPage } from '../../../../meldingen/config';
import { PageWithHeroComponent } from '../../../../shared/components/page-with-hero/page-with-hero.component';
import { ProfielFotoOfJongerePipe } from '../../../../shared/pipes/profiel-foto-of-jongere.pipe';
import { AsyncPipe } from '@angular/common';
import { ProfielnaamPipe } from '../../../../shared/pipes/profielnaam.pipe';
import { GebeurtenisErrorCardComponent } from '../../../verhaal-shared/component/gebeurtenis-error-card/gebeurtenis-error-card.component';
import { GebeurtenisListItemComponent } from '../../../verhaal-shared/component/gebeurtenis-list-item/gebeurtenis-list-item.component';
import { GeenGebeurtenissenPlaceholderComponent } from '../../../verhaal-shared/component/geen-gebeurtenissen-placeholder/geen-gebeurtenissen-placeholder.component';
import { PageLayoutComponent } from '../../../../shared/components/page-layout/page-layout.component';
import { PageSectionDirective } from '../../../../shared/directives/page-section.directive';
import {
  IonContent,
  IonHeader,
  IonRefresher,
  IonRefresherContent,
} from '@ionic/angular/standalone';
import { ParkourDataPage } from '../../../../shared/parkour-data-page';
import { ParkourRefreshDirective } from '../../../../shared/directives/parkour-refresh-directive';
import { ParkourButtonComponent } from '@parkour/ui';
import { TranslateModule } from '@ngx-translate/core';
import { GebeurtenisListComponent } from '../../../verhaal-shared/component/gebeurtenis-list/gebeurtenis-list.component';

@Component({
  standalone: true,
  templateUrl: './verhaal-teamlid.page.html',
  imports: [
    PageWithHeroComponent,
    ProfielFotoOfJongerePipe,
    AsyncPipe,
    ProfielnaamPipe,
    GebeurtenisErrorCardComponent,
    GebeurtenisListItemComponent,
    GeenGebeurtenissenPlaceholderComponent,
    PageLayoutComponent,
    PageSectionDirective,
    IonHeader,
    IonContent,
    IonRefresher,
    IonRefresherContent,
    ParkourRefreshDirective,
    ParkourButtonComponent,
    TranslateModule,
    GebeurtenisListComponent,
  ],
})
export class VerhaalTeamlidPage extends ParkourDataPage<VerhaalDto> {
  teamlidContext$ = this.dataRefresh$.pipe(switchMap(() => this.contextService.teamlidContext$()));
  ongelezenGebeurtenisMeldingen$ = this.meldingenService.getMeldingenInCurrentContextByType({
    ...meldingenForPage.VERHAAL_TAB.VERHAAL_PAGE.EYOUTH_GEBEURTENIS_PAGE.allMeldingen,
    ...meldingenForPage.VERHAAL_TAB.VERHAAL_PAGE.PARKOUR_GEBEURTENIS_PAGE.allMeldingen,
    ...meldingenForPage.VERHAAL_TAB.VERHAAL_PAGE.DRIE_KOLOMMEN_DOCUMENT_PAGE.allMeldingen,
  });

  constructor(
    private readonly contextService: ContextService,
    private readonly verhalenService: VerhaalService,
  ) {
    super();
  }

  override retrieveMainData(): Observable<VerhaalDto> {
    return this.verhalenService.getGedeeldeGebeurtenissen();
  }
}
