import { BrandedType } from '../branded-type';
import { ApiProperty } from '@nestjs/swagger';
import { Page } from './page.dto';

export type BookmarkId = BrandedType<string, 'bookmark'>;

export type BookmarkItemType = 'ARTIKEL' | 'HULPLIJN';

export class BookmarkUpsertDto {
  @ApiProperty({ type: String })
  readonly itemType!: BookmarkItemType;
  readonly itemId!: string;
}

export type BookmarksDto = BookmarkDto[];

export class BookmarkDto {
  @ApiProperty({ type: String })
  readonly id!: BookmarkId;
  readonly itemId!: string;
  readonly aangemaaktOpTijdstip!: string;
}

export class BookmarksPageDto implements Page<BookmarkDto> {
  readonly content!: BookmarksDto;
  readonly totalPages!: number;
  readonly number!: number;
  readonly last!: boolean;
}
