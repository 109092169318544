@if (doelen.length > 0) {
  <ul class="space-y-6">
    @for (doel of doelen; track doel.id) {
      <li>
        <parkour-doel-list-item
          [doel]="doel"
          [showNewIndicator]="
            (ongelezenDoelMeldingen$ | hasMeldingenForDoel: doel.id | async) ?? false
          "
        ></parkour-doel-list-item>
      </li>
    }
  </ul>
} @else {
  <p>{{ geenDoelenText }}</p>
}
