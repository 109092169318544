@if (active) {
  <div class="flex flex-col gap-y-8" role="tabpanel" tabindex="0">
    @if (artikelsData.content.length > 0) {
      <parkour-button [link]="['artikels']" class="w-full">
        {{ 'buttons.find-articles' | translate }}
      </parkour-button>
      <ul class="flex flex-col gap-y-2" role="feed">
        @for (artikel of artikelsData.content; track artikel.id) {
          <li>
            <parkour-article-card
              (clickedBookmark)="
                this.onArtikelBookmarkClicked({
                  hasBookmarked: $event,
                  bookmarkId: artikel.bookmarkId,
                  artikelId: artikel.id,
                })
              "
              [image]="{ src: artikel.image, alt: '' }"
              [link]="['artikel', String(artikel.id)]"
              [themes]="artikel.themas"
              [title]="artikel.title"
              [isBookmarked]="!!artikel.bookmarkId"
              testId="artikel"
              titleTag="h2"
            />
          </li>
        }
      </ul>

      <ion-infinite-scroll
        (ionInfinite)="this.onBewaardeArtikelsInfinite($event)"
        [disabled]="artikelsData.complete"
      >
        <div class="infinite-scroll-content">
          <parkour-loading-spinner />
        </div>
      </ion-infinite-scroll>
    } @else {
      <p class="text-center">{{ 'bewaard.no-articles' | translate }}</p>

      <parkour-button [link]="['artikels']" class="w-full">
        {{ 'buttons.find-articles' | translate }}
      </parkour-button>

      <parkour-block-placeholder
        [sources]="{
          default: {
            src: '/assets/bewaard/placeholder/placeholder_bewaarde_artikels_default.svg',
          },
          md: {
            src: '/assets/bewaard/placeholder/placeholder_bewaarde_artikels_md.svg',
          },
        }"
        alt=""
        class="block"
      />
    }
  </div>
}
