import { Component, Input } from '@angular/core';
import { WatWatCallToActionContent } from 'parkour-web-app-dto';
import { MakeReferencesAbsolutePipe } from '../../pipes/make-references-absolute.pipe';
import { MakeUrlAbsolutePipe } from '../../pipes/make-url-absolute.pipe';
import { ParkourButtonComponent, ParkourImageComponent } from '@parkour/ui';

@Component({
  selector: 'parkour-artikel-call-to-action-content',
  templateUrl: './artikel-call-to-action-content.component.html',
  standalone: true,
  imports: [
    MakeReferencesAbsolutePipe,
    MakeUrlAbsolutePipe,
    ParkourImageComponent,
    ParkourButtonComponent,
  ],
})
export class ArtikelCallToActionContentComponent {
  @Input({ required: true }) content!: WatWatCallToActionContent;
}
