import { Component, HostBinding } from '@angular/core';
import { ParkourIconComponent } from '@parkour/ui';

@Component({
  selector: 'parkour-professioneel-indicator',
  templateUrl: './professioneel-indicator.component.html',
  standalone: true,
  imports: [ParkourIconComponent],
})
export class ProfessioneelIndicatorComponent {
  @HostBinding('class') class = 'inline-flex align-middle';
}
