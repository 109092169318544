import { Component, Input, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { DoelUpsert } from '../../model/doel-upsert';
import { DoelFormService } from '../../service/doel-form.service';
import { DelenSectionGroup } from '../../../../delen/model/delen-form-group';
import { DelenFormService } from '../../../../delen/service/delen-form.service';
import { DirtyFormCheck } from '../../../../shared/components/dirty-form-guard';
import { Thema } from 'parkour-web-app-dto';
import { TeamlidProfiel } from '../../../../team/model/teamlid.model';
import { ParkourButtonComponent } from '@parkour/ui';
import { TranslateModule } from '@ngx-translate/core';
import { DoelDataSectionComponent } from '../doel-data-section/doel-data-section.component';
import { ActiesSelectSectionComponent } from '../acties-select-section/acties-select-section.component';
import { DelenSectionComponent } from '../../../../delen/component/delen-section/delen-section.component';
import {
  FormSubmitObservableGenerator,
  ParkourFormComponent,
} from '../../../../shared/components/parkour-form/parkour-form.component';
import { RawValueOfFormGroup } from '../../../../shared/components/parkour-form/parkour-form.types';

export type GedeeldMetItemGroup = FormGroup<{
  gedeeldMet: FormControl<boolean>;
}>;

export type ActieItemGroup = FormGroup<{
  naam: FormControl<string>;
  voltooid: FormControl<boolean>;
  order: FormControl<number>;
}>;

export type ActieSectionGroup = FormGroup<{
  acties: FormArray<ActieItemGroup>;
}>;
export type DoelDataSectionGroup = FormGroup<{
  naam: FormControl<string>;
  omschrijving: FormControl<string | ''>;
  thema: FormControl<Thema | ''>;
  streefDatum: FormControl<string>;
}>;

export type DoelForm = FormGroup<{
  doelDataSection: DoelDataSectionGroup;
  actieSection: ActieSectionGroup;
  gedeeldMetSection: DelenSectionGroup;
}>;

@Component({
  standalone: true,
  selector: 'parkour-doel-form',
  templateUrl: './doel-form.component.html',
  imports: [
    ParkourButtonComponent,
    ReactiveFormsModule,
    TranslateModule,
    DoelDataSectionComponent,
    ActiesSelectSectionComponent,
    DelenSectionComponent,
    ParkourFormComponent,
  ],
})
export class DoelFormComponent implements OnInit, DirtyFormCheck {
  @Input() formId = '';
  @Input({ required: true }) teamleden!: Array<TeamlidProfiel>;
  @Input() initialState!: Partial<DoelUpsert>;
  @Input({ required: true }) submitDoel!: FormSubmitObservableGenerator<
    RawValueOfFormGroup<DoelForm>
  >;

  doelForm!: DoelForm;

  constructor(
    private readonly doelFormService: DoelFormService,
    private readonly formBuilder: FormBuilder,
    private readonly delenFormService: DelenFormService,
  ) {}

  ngOnInit(): void {
    this.doelForm = this.formBuilder.nonNullable.group({
      doelDataSection: this.doelFormService.createDoelDataFormGroup(this.initialState),
      actieSection: this.doelFormService.createActieSectionFormGroup(this.initialState),
      gedeeldMetSection: this.delenFormService.createDelenFormGroup(
        this.teamleden,
        this.initialState,
      ),
    });
  }

  isFormDirty() {
    return this.doelForm.dirty;
  }
}
