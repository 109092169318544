import { Component, CUSTOM_ELEMENTS_SCHEMA, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Avatar } from 'parkour-web-app-dto';
import { ProfielFotoService } from '../../service/profiel-foto.service';
import { AsyncPipe, NgClass, NgForOf } from '@angular/common';
import { ParkourActionComponent, ParkourCarouselModule, ParkourImageComponent } from '@parkour/ui';
import { SecureImagePipe } from '../../../shared/pipes/secure-image.pipe';
import { ArtikelPreviewCardComponent } from '../../../home/component/artikel-preview-card/artikel-preview-card.component';
import { TranslateModule } from '@ngx-translate/core';
import { Haptics, ImpactStyle } from '@capacitor/haptics';
import { isNativeApp } from '../../../utils';

@Component({
  standalone: true,
  selector: 'parkour-profiel-avatar-select',
  templateUrl: './profiel-avatar-select.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ProfielAvatarSelectComponent),
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    NgClass,
    ParkourActionComponent,
    NgForOf,
    ParkourImageComponent,
    AsyncPipe,
    SecureImagePipe,
    ArtikelPreviewCardComponent,
    ParkourCarouselModule,
    TranslateModule,
  ],
})
export class ProfielAvatarSelectComponent {
  @Input() public classes?: string;
  @Input({ required: true }) public items!: readonly Avatar[];
  @Input() required = false;
  @Input() isValid = true;
  @Input() disabled = false;

  inputValue = '';
  onChange!: (value: string) => void;
  onTouched?: () => void;

  constructor(protected readonly profielFotoService: ProfielFotoService) {}

  private _value: Avatar = 'AVATAR_1';

  get value(): Avatar {
    return this._value;
  }

  @Input() set value(val: Avatar) {
    this.writeValue(val);
  }

  writeValue(value: Avatar) {
    this._value = value;
    this.inputValue = value;
  }

  registerOnChange(fn: () => void) {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  handleClick(value: Avatar) {
    if (this.disabled) {
      return;
    }

    this.writeValue(value);
    this.onChange(value);
  }

  convertAlt(value: Avatar) {
    return value.replace(/_/g, ' ').replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase();
    });
  }

  handleSlideChange(event: Event) {
    if (this.disabled) {
      return;
    }

    const { detail } = event as CustomEvent;
    const [swiper] = detail;
    const item = this.items[swiper.activeIndex];

    this.writeValue(item);
    this.onChange(item);

    if (isNativeApp()) {
      Haptics.impact({ style: ImpactStyle.Light });
    }
  }
}
