import { Component } from '@angular/core';
import { PageWithHeroComponent } from '../../../shared/components/page-with-hero/page-with-hero.component';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncPipe } from '@angular/common';
import {
  ParkourButtonComponent,
  ParkourFormFieldComponent,
  ParkourIconComponent,
  ParkourInputComponent,
} from '@parkour/ui';
import { PageLayoutComponent } from '../../../shared/components/page-layout/page-layout.component';
import { PageSectionDirective } from '../../../shared/directives/page-section.directive';
import {
  IonContent,
  IonHeader,
  IonRefresher,
  IonRefresherContent,
} from '@ionic/angular/standalone';
import { ParkourRefreshDirective } from '../../../shared/directives/parkour-refresh-directive';
import { GebeurtenisVoorstelComponent } from '../../verhaal-shared/component/gebeurtenis-voorstel/gebeurtenis-voorstel.component';
import { ParkourDataPage } from '../../../shared/parkour-data-page';
import {
  MarksMeldingenAsReadOnView,
  MeldingenToMarkAsRead,
} from '../../../shared/marks-meldingen-as-read';
import { VerhaalDto, VerhaalItem } from 'parkour-web-app-dto';
import { meldingenForPage } from '../../../meldingen/config';
import { combineLatest, map, Observable, of, repeat, Subject, switchMap, takeUntil } from 'rxjs';
import { VerhaalService } from '../../verhaal-shared/service/verhaal.service';
import { ContextService } from '../../../shared/services/context.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { verhaalHeroSources } from '../../constants';
import { ShowIfSuccesfulDirective } from '../../../shared/directives/show-if-succesful.directive';
import { GebeurtenisListComponent } from '../../verhaal-shared/component/gebeurtenis-list/gebeurtenis-list.component';
import { FormsModule } from '@angular/forms';
import { AnalyticsService } from '../../../analytics/analytics.service';
import { AnalyticsEvent } from '../../../analytics/analytics-event.model';
import { GebeurtenisSuggestie } from '../../verhaal-shared/model/gebeurtenis';

type VerhaalData = {
  verhaalZichtbaar: VerhaalDto;
  verhaalVerborgen: VerhaalDto;
};

@Component({
  imports: [
    PageWithHeroComponent,
    TranslateModule,
    AsyncPipe,
    ParkourButtonComponent,
    PageLayoutComponent,
    PageSectionDirective,
    IonContent,
    IonHeader,
    ParkourRefreshDirective,
    IonRefresher,
    IonRefresherContent,
    GebeurtenisVoorstelComponent,
    ShowIfSuccesfulDirective,
    GebeurtenisListComponent,
    FormsModule,
    ParkourFormFieldComponent,
    ParkourInputComponent,
    ParkourIconComponent,
  ],
  standalone: true,
  templateUrl: './verhaal-jongere-page.component.html',
})
export class VerhaalForJongerePage
  extends ParkourDataPage<VerhaalData>
  implements MarksMeldingenAsReadOnView
{
  ongelezenGebeurtenisMeldingen$ = this.meldingenService.getMeldingenInCurrentContextByType({
    ...meldingenForPage.VERHAAL_TAB.VERHAAL_PAGE.EYOUTH_GEBEURTENIS_PAGE.allMeldingen,
    ...meldingenForPage.VERHAAL_TAB.VERHAAL_PAGE.PARKOUR_GEBEURTENIS_PAGE.allMeldingen,
    ...meldingenForPage.VERHAAL_TAB.VERHAAL_PAGE.DRIE_KOLOMMEN_DOCUMENT_PAGE.allMeldingen,
  });

  queryString$ = this.activatedRoute.queryParamMap.pipe(map((map) => this.getQueryString(map)));
  refreshSuggesties$ = new Subject<void>();
  gebeurtenisSuggesties$ = this.extraPageData(
    combineLatest([
      this.verhalenService
        .getGebeurtenisSuggesties()
        .pipe(repeat({ delay: () => this.refreshSuggesties$ })),
      this.contextService.context$,
    ]).pipe(
      map(([suggesties, context]) =>
        suggesties.filter((suggestie) => suggestie.suggestedById !== context.contextId),
      ),
    ),
  );

  enteredQueryString = '';
  query = '';
  protected readonly verhaalHeroSources = verhaalHeroSources;

  constructor(
    private readonly verhalenService: VerhaalService,
    private readonly contextService: ContextService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly analyticsService: AnalyticsService,
  ) {
    super();
  }

  getMeldingenToMarkAsRead() {
    return of(
      new MeldingenToMarkAsRead(meldingenForPage.VERHAAL_TAB.VERHAAL_PAGE.specificMeldingen),
    );
  }

  override retrieveMainData(): Observable<VerhaalData> {
    return this.activatedRoute.queryParamMap.pipe(
      switchMap((queryParams) => {
        this.query = this.getQueryString(queryParams);

        return this.verhalenService.getVerhaalTijdlijn().pipe(
          map((verhaal) => {
            const filteredItems = this.filterItemsOnSearchQuery(verhaal.items, this.query);

            const verhaalVerborgen = verhaal.items.filter((item) => {
              if (item.type === 'DRIE_KOLOMMEN' || item.type === 'PARKOUR') {
                return false;
              }

              return item.verborgenVoorJongere;
            });
            const verhaalZichtbaar = filteredItems.filter((item) => {
              if (item.type === 'DRIE_KOLOMMEN' || item.type === 'PARKOUR') {
                return true;
              }

              return !item.verborgenVoorJongere;
            });

            return {
              verhaalZichtbaar: {
                ...verhaal,
                items: verhaalZichtbaar,
              },
              verhaalVerborgen: {
                ...verhaal,
                items: verhaalVerborgen,
              },
            };
          }),
        );
      }),
    );
  }

  override ionViewWillEnter() {
    super.ionViewWillEnter();

    this.queryString$.pipe(takeUntil(this.pageWillLeave$)).subscribe((queryString) => {
      this.enteredQueryString = queryString;
    });
  }

  onSuggestieConfirm(suggestie: GebeurtenisSuggestie) {
    this.router.navigate(['nieuw'], {
      queryParams: { suggestieId: suggestie.id },
      relativeTo: this.activatedRoute,
    });
  }

  onSuggestieReject(suggestie: GebeurtenisSuggestie) {
    this.verhalenService.rejectSuggestie(suggestie.id).subscribe(() => {
      this.refreshSuggesties$.next();
    });
  }

  search() {
    this.analyticsService.trackEvent(
      new AnalyticsEvent('verhaal', 'zoek', this.enteredQueryString),
    );
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        query: this.enteredQueryString,
      },
    });
  }

  private filterItemsOnSearchQuery(items: VerhaalItem[], query: string) {
    const lowerCaseQuery = query.toLowerCase();

    if (!lowerCaseQuery) {
      return items;
    }

    return items.filter((item) => {
      const eyouth = 'jeugdhulphistoriek';
      const parkour = 'gebeurtenis';
      const drieKolommen = 'document';
      const formattedStartDate = item.startDatum.split('-').reverse().join('.');
      const matchesStartDate = formattedStartDate.includes(query);

      if (item.type === 'PARKOUR' || item.type === 'EYOUTH') {
        const matchesTitle = item.titel.toLowerCase().includes(lowerCaseQuery);
        const matchesOmschrijving = item.omschrijving?.toLowerCase().includes(lowerCaseQuery);
        const parkourType = parkour.includes(lowerCaseQuery);

        if (item.type === 'EYOUTH') {
          const formattedEndDate = item.eindDatum
            ? item.eindDatum.split('-').reverse().join('.')
            : 'heden';
          const matchesEndDate = formattedEndDate.includes(query);
          const eyouthType = eyouth.includes(lowerCaseQuery);

          return (
            matchesStartDate || matchesEndDate || matchesTitle || matchesOmschrijving || eyouthType
          );
        }

        return matchesStartDate || matchesTitle || matchesOmschrijving || parkourType;
      } else if (item.type === 'DRIE_KOLOMMEN') {
        const matchesRubrieken = item.rubrieken?.some((rub) => {
          return (
            rub.rubriek?.toLowerCase().includes(lowerCaseQuery) ||
            rub.subrubriek?.toLowerCase().includes(lowerCaseQuery) ||
            rub.inhoud?.toLowerCase().includes(lowerCaseQuery)
          );
        });

        const predefinedKeywords = 'zorgen, krachten en acties document van jeugdhulp';
        const matchesTitle = predefinedKeywords.toLowerCase().includes(lowerCaseQuery);
        const drieKolommenType = drieKolommen.includes(lowerCaseQuery);

        return matchesRubrieken || matchesTitle || matchesStartDate || drieKolommenType;
      } else {
        return false;
      }
    });
  }

  private getQueryString(map: ParamMap) {
    const query = map.get('query');

    return query ?? '';
  }
}
