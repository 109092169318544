import { Injectable } from '@angular/core';
import { catchError, filter, interval, of, switchMap, take } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { LoggingService } from '../../core/logging.service';
import { isNativeApp } from '../../utils';
import AuthService from './auth.service';

const ONE_MINUTE = 60 * 1000;
const TWENTY_SECONDS = 20 * 1000;

@Injectable({
  providedIn: 'root',
})
export class ActivityDetectionService {
  private lastEvent!: number;

  constructor(
    private readonly http: HttpClient,
    private readonly loggingService: LoggingService,
    private readonly authService: AuthService,
  ) {}

  private listenForActivity(): void {
    addEventListener('touchstart', () => this.updateLastEvent());
    addEventListener('mousemove', () => this.updateLastEvent());
    addEventListener('keydown', () => this.updateLastEvent());
  }

  private sessionAlwaysActive = false;

  private updateLastEvent() {
    this.lastEvent = Date.now();
  }

  private refreshSession() {
    return this.http.post(`${environment.API_BASE_URL}/api/auth/session/refresh`, {}).pipe(
      catchError((e: unknown) => {
        this.loggingService.error('Failed to refresh session', e);
        return of(undefined);
      }),
    );
  }

  public initialize(): void {
    if (isNativeApp()) {
      this.listenForActivity();

      interval(TWENTY_SECONDS)
        .pipe(
          filter(() => this.isActiveInLastMinute() || this.sessionAlwaysActive),
          switchMap(() => this.authService.detailedUser$.pipe(take(1))),
          filter((user) => user.type !== 'anoniem'),
          switchMap(() => this.refreshSession()),
        )
        .subscribe();
    }
  }

  private isActiveInLastMinute() {
    return Date.now() - this.lastEvent < ONE_MINUTE;
  }

  public startKeepingSessionActive() {
    this.sessionAlwaysActive = true;
  }

  public stopKeepingSessionActive() {
    this.sessionAlwaysActive = false;
  }
}
