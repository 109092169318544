import {
  getProfielnaamWithoutFallback,
  getProfielVolledigeNaam,
  ProfielNaamType,
  ProfielType,
} from 'parkour-web-app-dto';
import { JongereProfiel } from '../../profiel/model/profiel';
import { TeamlidProfiel } from '../../team/model/teamlid.model';

type ActiefProfiel = {
  status: 'ACTIEF';
  voornaam: string;
  naam: string;
  type: ProfielType;
  profielnaamType: ProfielNaamType;
  roepnaam?: string;
};

type JongereDieUitgenodigdeProfiel = {
  status: 'UITGENODIGDE_WEERGAVE';
  voornaam: string;
  naam: string;
  profielnaamType: ProfielNaamType;
  roepnaam?: string;
};

type UitgenodigdProfiel = {
  status: 'UITGENODIGD';
  voornaam: string;
  naam: string;
};

type InactiefProfiel = {
  status: 'INACTIEF';
  voornaam: string;
  naam: string;
};

type GeblokkeerdProfiel = {
  status: 'GEBLOKKEERD';
  voornaam: string;
  naam: string;
};

type GeblokkeerdeWeergaveProfiel = {
  status: 'GEBLOKKEERDE_WEERGAVE';
  voornaam: string;
  naam: string;
};

type InactieveWeergaveProfiel = {
  status: 'INACTIEVE_WEERGAVE';
  voornaam: string;
  naam: string;
};

type UnsupportedProfiel = {
  status: 'UNSUPPORTED';
  voornaam?: string;
  naam?: string;
};

export type ProfielInTeam = JongereProfiel | TeamlidProfiel;

export type ProfielnaamData =
  | ActiefProfiel
  | InactiefProfiel
  | GeblokkeerdProfiel
  | GeblokkeerdeWeergaveProfiel
  | InactieveWeergaveProfiel
  | UnsupportedProfiel
  | UitgenodigdProfiel
  | JongereDieUitgenodigdeProfiel;

export function getProfielnaam(profiel: ProfielnaamData): string {
  switch (profiel.status) {
    case 'INACTIEF':
      return getProfielVolledigeNaam(profiel);
    case 'ACTIEF':
      return getProfielnaamWithoutFallback(profiel);
    case 'GEBLOKKEERD':
      return getProfielVolledigeNaam(profiel);
    case 'GEBLOKKEERDE_WEERGAVE':
      return getProfielVolledigeNaam(profiel);
    case 'UITGENODIGD':
      return getProfielVolledigeNaam(profiel);
    case 'UITGENODIGDE_WEERGAVE':
      return getProfielnaamWithoutFallback(profiel);
    case 'INACTIEVE_WEERGAVE':
      return getProfielVolledigeNaam(profiel);
    case 'UNSUPPORTED':
      return profiel.voornaam && profiel.naam ? `${profiel.voornaam} ${profiel.naam}` : '';
  }
}
