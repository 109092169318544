import { Injectable } from '@angular/core';
import { filter, switchMap } from 'rxjs';
import { ParkourModalService } from '@parkour/ui';
import AuthService from './auth.service';
import { BiometricsService } from './biometrics.service';
import { ParkourSecureStorage } from 'parkour-secure-storage';
import { BiometricsConfigModalComponent } from '../../shared/components/biometrics-config-modal/biometrics-config-modal.component';

@Injectable({
  providedIn: 'root',
})
export class BiometricsConfigModalService {
  biometricsModalId = 'biometrics-modal';

  constructor(
    private readonly authService: AuthService,
    private readonly biometricsService: BiometricsService,
    private readonly parkourModalService: ParkourModalService,
  ) {}

  public initialize() {
    this.authService.user$
      .pipe(
        filter((user) => user.type === 'aangemeld'),
        switchMap((user) => this.biometricsService.getStatusForUser(user)),
        filter((status) => status === 'NIET_INGESTELD'),
        switchMap(() => ParkourSecureStorage.getAvailabilityStatus()),
        filter((result) => result.status === 'ENROLLED'),
        filter(() => !this.parkourModalService.isModalOpen(this.biometricsModalId)),
      )
      .subscribe(async () => {
        await this.parkourModalService.showFullscreenModal(
          BiometricsConfigModalComponent,
          this.biometricsModalId,
        );
      });
  }
}
