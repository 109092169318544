import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { BerichtId, GesprekId, ReactieEvent, VideogesprekId } from 'parkour-web-app-dto';
import { AbstractGesprek } from '../../model/abstractGesprek';
import { EenOpEenGesprek } from '../../model/eenOpEenGesprek';
import { AttachmentBericht, Bericht, SpraakBericht, TextBericht } from '../../model/gesprek';
import { EventStartVideogesprekComponent } from '../event-start-videogesprek/event-start-videogesprek.component';
import { BerichtEventComponent } from '../bericht-event/bericht-event.component';
import { BerichtMessageComponent } from '../bericht-message/bericht-message.component';
import { IsSupportedBerichtPipe } from '../../pipes/is-supported-bericht.pipe';
import { checkIfActiveDate } from '../../../utils';
import { EventAfwezigheidComponent } from '../event-afwezigheid/event-afwezigheid.component';
import { BerichtGemaskeerdComponent } from '../bericht-gemaskeerd/bericht-gesmaskeerd.component';
import { Profiel } from '../../../profiel/model/profiel';

export type VideoGesprekJoinEvent = {
  videogesprekId: VideogesprekId;
  gesprek: EenOpEenGesprek;
  profiel: Profiel;
};

@Component({
  standalone: true,
  selector: 'parkour-berichten',
  templateUrl: './berichten.component.html',
  styleUrls: ['./berichten.component.css'],
  encapsulation: ViewEncapsulation.None,
  imports: [
    EventStartVideogesprekComponent,
    BerichtEventComponent,
    BerichtMessageComponent,
    IsSupportedBerichtPipe,
    EventAfwezigheidComponent,
    BerichtGemaskeerdComponent,
  ],
})
export class BerichtenComponent {
  @Input({ required: true }) berichten!: Bericht[][];
  @Input({ required: true }) gesprek!: AbstractGesprek;
  @Input({ required: true }) gesprekId!: GesprekId;
  @Input({ required: true }) profiel!: Profiel;

  @Output() closedPopover = new EventEmitter<void>();
  @Output() openedPopover = new EventEmitter<void>();
  @Output() deleted = new EventEmitter<BerichtId>();
  @Output() selectedReactie = new EventEmitter<ReactieEvent>();
  @Output() deleteReactie = new EventEmitter<BerichtId>();
  @Output() videoGesprekJoin = new EventEmitter<VideoGesprekJoinEvent>();
  @Output() saveNotitie = new EventEmitter<{
    titel: string;
    beschrijving: string;
  }>();
  @Output() replyBericht = new EventEmitter<TextBericht | AttachmentBericht | SpraakBericht>();
  @Output() scrollToBottom = new EventEmitter<void>();
  @Output() rapporteer = new EventEmitter<{
    gesprekId: GesprekId;
    berichtId: BerichtId;
  }>();
  protected readonly JSON = JSON;

  protected readonly checkIfActiveDate = checkIfActiveDate;

  closedBerichtMessagePopover() {
    this.closedPopover.emit();
  }

  openedBerichtMessagePopover() {
    this.openedPopover.emit();
  }

  onVerwijderd(id: BerichtId) {
    this.deleted.emit(id);
  }

  onSelectedReactie(event: ReactieEvent) {
    this.selectedReactie.emit({
      berichtId: event.berichtId,
      reactie: event.reactie,
    });
  }

  onDeleteReactie(berichtId: BerichtId) {
    this.deleteReactie.emit(berichtId);
  }

  onReplyBericht(bericht: TextBericht | AttachmentBericht | SpraakBericht) {
    this.replyBericht.emit(bericht);
  }

  isEenOpEenGesprek(gesprek: AbstractGesprek): gesprek is EenOpEenGesprek {
    return gesprek instanceof EenOpEenGesprek;
  }

  onVideoGesprekJoin() {
    if (this.isEenOpEenGesprek(this.gesprek) && this.gesprek.videogesprekId) {
      this.videoGesprekJoin.emit({
        videogesprekId: this.gesprek.videogesprekId,
        gesprek: this.gesprek,
        profiel: this.profiel,
      });
    }
  }

  getDeelnemerProfiel(gesprek: AbstractGesprek) {
    if (gesprek instanceof EenOpEenGesprek) {
      return gesprek.getVisibleDeelnemer(this.profiel.id);
    } else {
      return undefined;
    }
  }

  onSaveNotitie(event: { titel: string; beschrijving: string }) {
    this.saveNotitie.emit({ ...event });
  }

  onScrollToBottom() {
    this.scrollToBottom.emit();
  }

  isGesprekGestopt() {
    return this.gesprek.status === 'GEARCHIVEERD';
  }

  onRapporteer(event: { gesprekId: GesprekId; berichtId: BerichtId }) {
    this.rapporteer.emit(event);
  }
}
