<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button parkourBackButton />
    </ion-buttons>

    <ion-title aria-level="1" role="heading"
      >{{ 'profiel.bewaarde-items-page.title' | translate }}
    </ion-title>

    <ion-buttons slot="end">
      <parkour-header-action
        [label]="'bewaard.notitie-add-button' | translate"
        [link]="['notitie', 'add']"
        icon="plus-circle"
        testId="add-link"
      />
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-refresher [parkourRefresh]="onPageRefresh" slot="fixed">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>
  <parkour-page-layout>
    <parkour-page-gradient />

    <div class="relative z-10 py-8 container container-space flex flex-col gap-y-8">
      <div class="container-1-col flex flex-col">
        <parkour-segment
          (changed)="segmentChanged($event)"
          [defaultValue]="activeSegmentItem"
          [fullWidth]="true"
          [items]="segmentItems"
        />
        <div class="mt-4">
          @if (paginatedData$ | async; as artikelsData) {
            <parkour-bewaarde-artikels
              (artikelBookmarkClick)="onArtikelBookmarkClicked($event)"
              [artikelsData]="artikelsData"
              [active]="activeSegmentItem === 'artikels'"
            />
          }

          <parkour-bewaarde-hulplijnen [active]="activeSegmentItem === 'hulplijnen'" />

          <parkour-bewaarde-notities
            #bewaardeNotitiesComponent
            [active]="activeSegmentItem === 'notities'"
          />
        </div>
      </div>
    </div>
  </parkour-page-layout>
</ion-content>
