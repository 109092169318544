import { Pipe, PipeTransform } from '@angular/core';
import { InteractieEmoji } from 'parkour-web-app-dto';

const emojiMappings: { [key in InteractieEmoji]: string } = {
  THUMBS_UP: '👍',
  OK: '👌',
  HEART: '❤️',
  HUNDRED: '💯',
  SAD_FACE: '😢',
};

export function getParkourEmoji(emojiKey: InteractieEmoji) {
  return emojiMappings[emojiKey];
}

@Pipe({
  name: 'parkourEmoji',
  standalone: true,
})
export class ParkourEmojiPipe implements PipeTransform {
  transform(emojiKey: InteractieEmoji): string {
    return getParkourEmoji(emojiKey);
  }
}
