import { RefresherCustomEvent, ViewWillEnter, ViewWillLeave } from '@ionic/angular/standalone';
import { inject } from '@angular/core';
import { RefreshService } from './services/refresh.service';
import { ReplaySubject, Subject, takeUntil } from 'rxjs';
import { AppTitlePrefix } from '../app-title.prefix';
import { MeldingenService } from '../profiel/service/meldingen.service';
import { implementsCustomPageTitle } from './custom-page-title';
import { TranslateService } from '@ngx-translate/core';
import { implementsMarksMeldingenAsReadOnView } from './marks-meldingen-as-read';

export abstract class ParkourPage implements ViewWillEnter, ViewWillLeave {
  get pageWillLeave$(): Subject<void> {
    return this._pageWillLeave$;
  }

  get pageActive(): boolean {
    return this._pageActive;
  }

  private _pageActive = false;

  private readonly appTitlePrefix = inject(AppTitlePrefix);
  protected readonly translateService = inject(TranslateService);
  protected readonly meldingenService = inject(MeldingenService);
  protected readonly refreshService = inject(RefreshService);
  private readonly _pageWillLeave$ = new Subject<void>();
  protected readonly pageWillEnter$ = new ReplaySubject<undefined>(1);

  protected onPageRefresh = (event?: RefresherCustomEvent) => {
    this.refreshPageDataOnly(event);
    this.refreshService.refresh();
  };

  protected refreshPageDataOnly = (event?: RefresherCustomEvent) => {
    setTimeout(() => event?.detail.complete(), 500);
  };

  ionViewWillLeave(): void {
    this._pageActive = false;
    this._pageWillLeave$.next();
  }

  ionViewWillEnter(): void {
    this._pageActive = true;

    this.pageWillEnter$.next(undefined);

    this.handleTitleSetting();
    this.handleMeldingenToMarkAsRead();
  }

  private handleMeldingenToMarkAsRead() {
    if (implementsMarksMeldingenAsReadOnView(this)) {
      this.getMeldingenToMarkAsRead()
        .pipe(takeUntil(this.pageWillLeave$))
        .subscribe((meldingenToMark) => {
          if (meldingenToMark) {
            this.meldingenService.markMeldingenInCurrentContextAsReadWithTypes(
              meldingenToMark.meldingen,
              meldingenToMark.filter,
            );
          }
        });
    }
  }

  private handleTitleSetting() {
    if (implementsCustomPageTitle(this)) {
      this.getCustomPageTitle()
        .pipe(takeUntil(this.pageWillLeave$))
        .subscribe((title) => {
          if (typeof title === 'string') {
            this.appTitlePrefix.setTitleWithPrefix(title);
          } else {
            this.appTitlePrefix.setTitleWithCategory(title.title, title.category);
          }
        });
    }
  }
}
