import { ApiProperty } from '@nestjs/swagger';

export const rollen = [
  'BEGELEIDER',
  'CONSULENT',
  'FAMILIE',
  'OUDER',
  'PARTNER',
  'VRIEND',
  'ANDERE',
] as const;

export type Rol = (typeof rollen)[number];

export class TeamlidUpdateDto {
  @ApiProperty({ type: String })
  readonly rol!: Rol;
}
