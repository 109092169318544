import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  Renderer2,
  SecurityContext,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { isPlatform } from '@ionic/core';
import { NgClass, NgIf } from '@angular/common';
import { ParkourButtonComponent, ParkourFocusVisibleModule } from '@parkour/ui';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  selector: 'parkour-bericht-input',
  templateUrl: './bericht-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BerichtInputComponent),
      multi: true,
    },
  ],
  imports: [NgClass, ParkourFocusVisibleModule, ParkourButtonComponent, NgIf, TranslateModule],
})
export class BerichtInputComponent implements ControlValueAccessor, AfterViewInit {
  @ViewChild('contentEditable', { static: true }) contentEditable?: ElementRef<HTMLElement>;

  @Input() placeholder = 'Bericht';

  @Output() public clickedAssets: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();
  @Output() public inputted: EventEmitter<string | null> = new EventEmitter<string | null>();
  @Output() public enter: EventEmitter<KeyboardEvent> = new EventEmitter<KeyboardEvent>();

  public isFocus!: boolean;
  value: string | null = null;

  public onChange?: (value: string | null) => void;
  public onTouched?: () => void;

  constructor(
    private renderer: Renderer2,
    private sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef,
  ) {}

  ngAfterViewInit() {
    if (isPlatform('desktop')) {
      this.setFocus();
    }
  }

  writeValue(value: string) {
    this.value = value;

    if (this.contentEditable && !this.value) {
      const contentEditable = this.contentEditable?.nativeElement as HTMLElement;

      contentEditable.innerHTML = '';

      this.setEmptyInput(contentEditable);
    }
  }

  onInput(): void {
    const contentEditable = this.contentEditable?.nativeElement as HTMLElement;
    const newValue = contentEditable.innerText;

    this.value = newValue;
    this.onChange?.(newValue);

    if (!contentEditable.firstChild) {
      this.setEmptyInput(contentEditable);
    }

    const sanitizedValue = this.sanitizer.sanitize(SecurityContext.HTML, newValue);

    this.inputted.emit(sanitizedValue);
  }

  setEmptyInput(contentEditable: HTMLElement) {
    const paragraph = this.renderer.createElement('p');
    const empty = this.renderer.createElement('br');

    this.renderer.setAttribute(paragraph, 'class', 'typo-body !text-2');
    this.renderer.appendChild(paragraph, empty);
    this.renderer.appendChild(contentEditable, paragraph);
  }

  registerOnChange(onChange: () => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  openAssets(event: MouseEvent) {
    this.clickedAssets.emit(event);
  }

  onkeydown(event: KeyboardEvent) {
    if (event.keyCode === 13 && !event.shiftKey) {
      event.preventDefault();

      if (this.value) {
        this.enter.emit(event);
      }
    }
  }

  setFocus() {
    const contentEditable = this.contentEditable?.nativeElement as HTMLElement;

    contentEditable.focus();

    this.cdr.detectChanges();
  }
}
