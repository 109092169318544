<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button defaultHref="home" parkourBackButton />
    </ion-buttons>

    <ion-title aria-level="1" role="heading"
      >{{ 'watwat.hulplijnen-page.title' | translate }}
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-refresher [parkourRefresh]="onPageRefresh" slot="fixed">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>

  <parkour-page-layout>
    <parkour-page-gradient classes="absolute top-0 left-0 before:-translate-y-[5rem]" />

    <div class="relative z-10 py-8 container-1-col container-space flex flex-col gap-y-8">
      <form (ngSubmit)="onSearch()">
        <parkour-form-field
          [inputFormControl]="null"
          [isLabelHidden]="true"
          inputId="artikel-search-term"
          label="Wat zoek je?"
        >
          <parkour-input
            [(ngModel)]="enteredQueryString"
            inputId="hulplijn-search-term"
            inputPlaceholder="Wat zoek je?"
            name="hulplijn-search-term"
          >
            <parkour-button
              [ariaLabel]="'buttons.search' | translate"
              format="iconButton"
              icon="search"
              size="medium"
              type="submit"
            />
          </parkour-input>
        </parkour-form-field>
      </form>

      <ng-container *parkourSectionContent="{ data: mainPageData$ | async }; let hulplijnenResult">
        <div class="flex flex-col gap-y-3">
          <parkour-filters
            (changed)="onFilterChange($event)"
            [activeFilter]="type$ | async"
            [filters]="[
              { icon: 'bubble', label: 'buttons.chat' | translate, value: 'chat' },
              { icon: 'telephone', label: 'buttons.call' | translate, value: 'tel' },
              { icon: 'pin', label: 'buttons.go-visit' | translate, value: 'visit' },
              { icon: 'bubbles', label: 'buttons.forum' | translate, value: 'forum' },
            ]"
          />

          <div class="flex items-center justify-between gap-4" aria-live="polite">
            <parkour-checkbox
              (checkboxChange)="onOpenCheckboxChange($event)"
              [checked]="(nowOpen$ | async) ?? false"
              [disabled]="(type$ | async) === 'visit' || (type$ | async) === 'forum'"
            >
              <span class="typo-body variant-body-fat">{{
                'watwat.hulplijnen-page.open' | translate
              }}</span>
            </parkour-checkbox>

            <p class="typo-body variant-body-fat text-n-600" data-testid="hulplijnen-count">
              @if (hulplijnenResult.total > 1) {
                {{
                  'watwat.hulplijnen-page.hoeveelheid-hulplijnen'
                    | translate
                      : {
                          amount: hulplijnenResult.total,
                        }
                }}
              } @else if (hulplijnenResult.total < 1) {
                {{ 'watwat.hulplijnen-page.geen-hulplijnen' | translate }}
              } @else {
                {{
                  'watwat.hulplijnen-page.hoeveelheid-hulplijn'
                    | translate: { amount: hulplijnenResult.total }
                }}
              }
            </p>
          </div>
        </div>

        <ul class="flex flex-col gap-y-2">
          @for (hulplijn of hulplijnenResult.results; track $index) {
            <li>
              <parkour-helpline-card-item
                [organisation]="hulplijn"
                [segmentsToHulplijn]="[String(hulplijn.id)]"
                titleTag="h2"
              />
            </li>
          }
        </ul>
        @if (hulplijnenResult.total > 1) {
          <div class="mt-10">
            <parkour-pagination
              (clicked)="changePage($event)"
              [collectionSize]="hulplijnenResult.total"
              [currentPage]="(page$ | async) ?? 1"
              [pageSize]="hulplijnenResult.pageSize"
              [contentRef]="contentRef"
              class="mt-10"
            />
          </div>
        }
      </ng-container>
    </div>
  </parkour-page-layout>
</ion-content>
