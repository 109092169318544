<article class="clickable-card-200 p-4 rounded">
  <div class="flex gap-x-4">
    <div class="flex-1 flex flex-col gap-y-2">
      <parkour-action
        [link]="[doel.id]"
        classes="doel-list-item__action focus-visible:outline-none"
      >
        <h2 class="typo-h5">{{ doel.naam }}</h2>
      </parkour-action>

      <span class="typo-body text-n-600"> {{ doel.streefDatum ?? '' | date }} </span>
    </div>

    <div class="relative">
      <parkour-image
        [src]="themaMapping[doel.thema].smallImage.src"
        alt=""
        class="block w-[4rem] h-[4rem] rounded-sm overflow-hidden"
      />

      <parkour-new-indicator [show]="showNewIndicator" />
    </div>
  </div>

  <div class="flex items-center justify-between gap-x-2 pt-3 mt-3 border-t-[1.5px] border-n-600/30">
    <parkour-doel-progress [doel]="doel" class="flex-1" />

    <parkour-gedeeld-met-indicator [deelMode]="doel.deelMode" [gedeeldMet]="doel.gedeeldMet" />
  </div>
</article>
