export interface ParkourSecureStoragePlugin {
    save(options: { key: string, value: string, title: string, cancelLabel: string }): Promise<void>;

    get(options: { key: string, title: string, cancelLabel: string }): Promise<{ value: string | null }>;

    remove(options: { key: string }): Promise<void>;

    getAvailabilityStatus(): Promise<{ status: AvailabilityStatus }>;

    hasKey(options: { key: string }): Promise<{ result: boolean }>;
}

export type AvailabilityStatus = 'ENROLLED' | 'HARDWARE_UNAVAILABLE' | 'NOT_ENROLLED';

const errorTypes = ['FAILED',
    'CANCELED',
    'NO_BIOMETRICS_HARDWARE',
    'BIOMETRICS_NOT_CONFIGURED',
    'BIOMETRICS_INVALIDATED',
    'NO_LOCKSCREEN',
    'NOT_FOUND'] as const;

export type ParkourSecureStorageError = {
    code: typeof errorTypes[number]
} & Error

export function isParkourSecureStorageError(e: Error): e is ParkourSecureStorageError {
    return 'code' in e && typeof e.code === 'string' && e.code in errorTypes
}

