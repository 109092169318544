import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { HttpLinkPipe } from '../../../shared/pipes/http-link.pipe';
import { ParkourButtonComponent } from '@parkour/ui';
import { Notitie } from '../../model/notitie';
import { LinkifyPipe } from '../../../bericht/pipes/linkify.pipe';

@Component({
  standalone: true,
  selector: 'parkour-notitie',
  templateUrl: './notitie.component.html',
  imports: [TranslateModule, HttpLinkPipe, LinkifyPipe, ParkourButtonComponent],
})
export class NotitieComponent {
  @Input({ required: true }) notitie!: Notitie;

  shortenUrl(url: string) {
    const urlWithoutPrefix = url
      .toLowerCase()
      .replace('https://', '')
      .replace('http://', '')
      .replace('www.', '');
    return urlWithoutPrefix.split('/')[0];
  }
}
