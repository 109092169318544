<div class="flex flex-col gap-y-8">
  <parkour-doel-details-hero [doel]="doel">
    <parkour-gedeeld-met-indicator
      (clicked)="gedeeldMetInfoComponent.open()"
      [deelMode]="doel.deelMode"
      [gedeeldMet]="doel.gedeeldMet"
      format="button"
      size="large"
    />
  </parkour-doel-details-hero>

  <div class="container-1-col">
    <p class="wysiwyg whitespace-pre-wrap">{{ doel.omschrijving }}</p>
  </div>

  @if (contextType === 'jongere' && doel.activeGebeurtenisSuggestie) {
    <div class="flex gap-x-4 bg-n-300 p-4 rounded container-1-col">
      <div class="bg-n-400 w-[3rem] h-[3rem] flex items-center justify-center rounded">
        <parkour-icon name="check" size="3" />
      </div>

      <div class="flex flex-col gap-y-4 flex-1">
        <div class="flex flex-col gap-y-1">
          <p class="typo-body variant-body-fat" [attr.data-testid]="'doel-titel'">
            {{ doel.activeGebeurtenisSuggestie.titel }}
          </p>

          <p>{{ 'doelen.doel-detail-page.toevoegen-aan-verhaal' | translate }}</p>
        </div>

        <div class="flex gap-x-4">
          <parkour-button
            (clicked)="ignoreGebeurtenis(doel.activeGebeurtenisSuggestie.id)"
            class="w-full"
            size="small"
            variant="tertiary"
          >
            {{ 'buttons.nee' | translate }}
          </parkour-button>

          <parkour-button
            [queryParams]="{ suggestieId: doel.activeGebeurtenisSuggestie.id }"
            [link]="['verhaal-suggestie']"
            class="w-full"
            size="small"
            variant="secondary"
          >
            {{ 'buttons.ja' | translate }}
          </parkour-button>
        </div>
      </div>
    </div>
  }

  @if (doel.acties.length) {
    <div class="flex flex-col gap-y-6 container-1-col">
      <h2 class="typo-h3">{{ 'doelen.doel-detail-page.acties' | translate }}</h2>

      <parkour-actie-list
        (actiesModified)="updateActies(doel, $event)"
        [acties]="doel.acties"
        [editable]="!doel.isBehaald && editable"
      />
    </div>
  }
</div>

<parkour-gedeeld-met-info
  #gedeeldMetInfoComponent
  [deelMode]="doel.deelMode"
  [gedeeldMet]="doel.gedeeldMet"
  [exJongereView]="contextType === 'ex-jongere'"
  class="block container-1-col"
/>
