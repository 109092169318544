import { Injectable } from '@angular/core';
import { catchError, from, map, Observable, of, switchMap, tap } from 'rxjs';
import { ParkourModalService, ParkourPopupService, ParkourToastService } from '@parkour/ui';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Device } from '@capacitor/device';
import { asType, errorToFailureType } from '../../utils';
import { BIOMETRICS_LOGIN_MODAL_ID, RedirectConfig } from './auth.service';
import { BiometricsService } from './biometrics.service';
import { LoginWithTokenDto, ProfielId } from 'parkour-web-app-dto';
import { HttpParams } from '@capacitor/core';
import { TranslateService } from '@ngx-translate/core';
import { ParkourError } from '../../core/parkour-error';
import { AcmIdmAuthService } from './acm-idm-auth.service';
import { BiometricsLoginModalComponent } from '../biometrics-login-modal/biometrics-login-modal.component';
import { LoginResult } from './background-detection.service';
import { AnalyticsEvent, trackAnalyticsEvent } from '../../analytics/analytics-event.model';
import { AnalyticsService } from '../../analytics/analytics.service';

@Injectable({
  providedIn: 'root',
})
export class BiometricsAuthService {
  readonly TRACKER_CATEGORY = 'authentication';

  constructor(
    private readonly http: HttpClient,
    private readonly translateService: TranslateService,
    private readonly popupService: ParkourPopupService,
    private readonly toastService: ParkourToastService,
    private readonly biometricsService: BiometricsService,
    private readonly acmIdmAuthService: AcmIdmAuthService,
    private readonly modalService: ParkourModalService,
    private readonly analyticsService: AnalyticsService,
  ) {}

  private modal?: HTMLIonModalElement;

  public closeModalIfOpen() {
    if (this.modal) {
      this.modal.dismiss();
      this.modal = undefined;
    }
  }

  public startBiometricsAuthFlow(redirectConfig: RedirectConfig): Observable<LoginResult> {
    return from(
      this.modalService.showFullscreenModal(
        BiometricsLoginModalComponent,
        BIOMETRICS_LOGIN_MODAL_ID,
        {
          aanmeldenClickCallback: () => {},
        },
      ),
    ).pipe(
      tap((modal) => (this.modal = modal)),
      trackAnalyticsEvent(
        this.analyticsService,
        new AnalyticsEvent('auth', 'loginBiometrieGestart'),
      ),
      switchMap(() =>
        this.biometricsService.getBiometricsToken().pipe(
          switchMap((token) => {
            return this.createBiometricsSession(token, redirectConfig.redirectProfielId);
          }),
          catchError((err: unknown) => this.onBiometricsError(redirectConfig, err)),
          map((type) => ({ type })),
        ),
      ),
    );
  }

  private createBiometricsSession(
    token: string,
    redirectProfielId?: ProfielId,
  ): Observable<'biometric-login'> {
    let params: HttpParams = {};
    if (redirectProfielId) {
      params = { redirectProfielId: redirectProfielId };
    }
    return from(Device.getId())
      .pipe(
        switchMap((deviceId) =>
          this.http.post(
            `${environment.API_BASE_URL}/api/auth/session`,
            asType<LoginWithTokenDto>({
              token,
              deviceId: deviceId.identifier,
            }),
            { params },
          ),
        ),
      )
      .pipe(map(() => 'biometric-login'));
  }

  private clearBiometricsTokenIfInvalid(err: unknown) {
    if (err instanceof ParkourError && err.errorType === 'login-ongeldige-biometrics-token') {
      return this.biometricsService.clearBiometrics();
    } else {
      return of(undefined);
    }
  }

  private onBiometricsError(
    redirectConfig: RedirectConfig,
    err: unknown,
  ): Observable<'home' | 'acm-idm-login' | 'biometric-login' | 'canceled'> {
    this.analyticsService.trackEvent(new AnalyticsEvent('auth', 'loginBiometrieFout'));

    const failureType = errorToFailureType(err);
    if (failureType === 'offline' || failureType === 'server-unreachable') {
      const headerKey = 'errors.offline.toast.title';
      const contentKey = 'errors.offline.toast.description';
      return this.translateService.get([headerKey, contentKey]).pipe(
        map(
          async (translations) =>
            await this.toastService.showToast({
              error: true,
              header: translations[headerKey],
              content: translations[contentKey],
            }),
        ),
        map(() => 'home'),
      );
    }

    const initial$ = this.clearBiometricsTokenIfInvalid(err);

    return initial$.pipe(
      switchMap(() =>
        this.translateService.get([
          'biometrics.login-error.title',
          'biometrics.login-error.description',
        ]),
      ),
      switchMap((translations) =>
        this.popupService.showPopup({
          icon: 'logout',
          title: translations['biometrics.login-error.title'],
          description: translations['biometrics.login-error.description'],
        }),
      ),
      switchMap((popupResult) => {
        switch (popupResult) {
          case 'yes':
            return this.acmIdmAuthService
              .startAcmIdmAuthFlow(redirectConfig)
              .pipe(map((result) => result.type)); //disregard reading type of result
          case 'no':
            return of<'home'>('home');
          case 'closed-externally':
            return of<'canceled'>('canceled');
        }
      }),
    );
  }
}
