import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Actie } from '../../model/doel';
import { ParkourCheckboxComponent, ParkourIconComponent } from '@parkour/ui';
import { NgIf } from '@angular/common';

@Component({
  standalone: true,
  selector: 'parkour-actie-item-view',
  templateUrl: './actie-item-view.component.html',
  imports: [ParkourCheckboxComponent, NgIf, ParkourIconComponent],
})
export class ActieItemViewComponent {
  @Input({ required: true }) actie!: Actie;
  @Input() editable = true;
  @Output() toggleActieVoltooid: EventEmitter<boolean> = new EventEmitter<boolean>();

  handleCheckboxChange(checked: boolean) {
    this.toggleActieVoltooid.emit(checked);
  }
}
