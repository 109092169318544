export const teamAnalyticsEvents = [
  'uitnodigingVerzonden',
  'uitnodigingGeweigerd',
  'uitnodigingGeaccepteerd',
  'uitnodigingVerwijderd',
  'uitnodigingBevestigd',
  'teamlidVerwijderd',
  'teamlidGeactiveerd',
  'teamlidGeblokkeerd',
  'teamlidRolGewijzigd',
  'teamVerlaten',
  'wisselTeam',
  'contextType',
] as const;
