<parkour-page-image
  [src]="getProfielFoto() | secureImage | async"
  classes="absolute top-0 left-0 z-0"
/>

<div class="relative z-10 pt-8 px-8 container container-space">
  <div class="flex flex-col items-center gap-4 container-1-col">
    <parkour-avatar
      [image]="{ src: getProfielFoto() | secureImage | async, type: 'image' }"
      [professional]="professioneel"
      class="block max-w-[10rem]"
      data-testid="avatar-img"
    />

    <div class="flex justify-center gap-4 flex-wrap">
      <parkour-button
        (clicked)="onAfbeeldingVerwijder()"
        *ngIf="fotoFormgroup.value.profielFoto"
        iconAfter="trash"
        size="small"
        variant="tertiary"
      >
        {{ 'profiel.delete-image-button' | translate }}
      </parkour-button>

      <parkour-button (clicked)="onAfbeeldingAanpassenClick()" iconAfter="edit" size="small">
        {{ 'profiel.edit-image-button' | translate }}
      </parkour-button>
    </div>
  </div>
</div>

<parkour-modal
  (hasBeenDismissed)="dismissChooseImageModal()"
  [title]="'profiel.edit-image-button' | translate"
  [hasSpacing]="false"
  icon="images"
>
  <parkour-profiel-details-foto-modal
    (fotoDetailsSaved)="onFotoDetailsSaved($event)"
    [initialAvatar]="fotoFormgroup.getRawValue().avatar"
    [initialProfielFotoFile]="fotoFormgroup.getRawValue().profielFotoFile"
    [initialProfielFoto]="fotoFormgroup.getRawValue().profielFoto"
    content
  >
  </parkour-profiel-details-foto-modal>
</parkour-modal>
