import { Component, Input } from "@angular/core";
import {
  TParkourIconName,
  TParkourIconSizes,
} from "../parkour-icon/parkour-icon.types";
import { TParkourBadgeTheme } from "./parkour-badge.types";
import { NgClass } from "@angular/common";
import { ParkourIconComponent } from "../parkour-icon/parkour-icon.component";

@Component({
  standalone: true,
  selector: "parkour-badge",
  templateUrl: "./parkour-badge.component.html",
  imports: [NgClass, ParkourIconComponent],
})
export class ParkourBadgeComponent {
  @Input() width = "w-12";
  @Input() theme: TParkourBadgeTheme = "n-400";
  @Input({ required: true }) icon!: TParkourIconName;
  @Input() iconSize: TParkourIconSizes = "3";
}
