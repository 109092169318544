<div class="flex gap-x-1">
  <div *ngIf="!isUser && gesprekWithDeelnemers.isGroepsgesprek()" class="w-[2rem] flex-none">
    <parkour-profiel-foto-badge
      *ngIf="profiel$ | async as profiel"
      [alt]="false"
      [profiel]="profiel"
      [size]="'2'"
      class="bericht-message-avatar relative -z-0"
    />
  </div>

  <div class="flex flex-col flex-1">
    <div
      (press)="onLongPress()"
      [ngClass]="{
        'bg-n-300': isUser,
        'bg-n-200': !isUser,
        'w-full': this.bericht.type === 'SPRAAK',
        'z-50': this.popover.isOpen,
      }"
      class="relative inline-flex rounded flex-1"
      parkourLongPress
    >
      <div
        [id]="bericht.id"
        class="pointer-events-none absolute top-[-0.625rem] left-0 w-full h-[calc(100%+1.25rem)]"
      ></div>

      <div class="flex-1">
        @if (isAntwoord) {
          @if (origineelBericht) {
            <div class="my-2 ml-3 mr-2 border-l-[1.5px] border-n-600 border-solid pl-3">
              @switch (origineelBericht.type) {
                @case ('GEMASKEERD') {
                  <parkour-bericht-gemaskeerd-content />
                }
                @case ('TEKST') {
                  <span class="sr-only">{{
                    'berichten.gesprek-page.reaction-message' | translate
                  }}</span>

                  <parkour-bericht-text-content
                    [gesprek]="gesprekWithDeelnemers"
                    [bericht]="origineelBericht"
                    [isUser]="isUser"
                    [isNameVisible]="true"
                    [isTruncatedVisible]="true"
                  />
                }
                @case ('ATTACHMENT') {
                  <span class="sr-only">{{
                    'berichten.gesprek-page.reaction-attachment' | translate
                  }}</span>

                  <parkour-bericht-attachment-preview
                    [gesprekId]="gesprekId"
                    [bericht]="origineelBericht"
                  />
                }
                @case ('SPRAAK') {
                  <span class="sr-only">{{
                    'berichten.gesprek-page.reaction-voicemessage' | translate
                  }}</span>

                  <parkour-bericht-speech-content
                    [gesprek]="gesprekWithDeelnemers"
                    [bericht]="origineelBericht"
                    [isUser]="isUser"
                    [isNameVisible]="true"
                  />
                }
              }
            </div>
          } @else {
            <div class="my-2 ml-3 mr-2 border-l-[1.5px] border-n-700 border-solid pl-3">
              <span class="text-n-700">{{
                'berichten.gesprek-page.bericht-unavailable' | translate
              }}</span>
            </div>
          }
        }

        @switch (bericht.type) {
          @case ('TEKST') {
            <parkour-bericht-text-wrapper
              (moreOptionsClick)="onMoreOptionsClick()"
              [bericht]="bericht"
              [isUser]="isUser"
              [disabled]="disabled"
            >
              <parkour-bericht-text-content
                [gesprek]="gesprekWithDeelnemers"
                [bericht]="bericht"
                [isUser]="isUser"
              />
            </parkour-bericht-text-wrapper>
          }
          @case ('ATTACHMENT') {
            <parkour-bericht-attachment-content
              (moreOptionsClick)="onMoreOptionsClick()"
              [gesprekId]="gesprekId"
              [gesprek]="gesprekWithDeelnemers"
              [bericht]="bericht"
              [isUser]="isUser"
              [disabled]="disabled"
            />
          }
          @case ('SPRAAK') {
            <parkour-bericht-speech-wrapper
              (moreOptionsClick)="onMoreOptionsClick()"
              [bericht]="bericht"
              [isUser]="isUser"
              [disabled]="disabled"
            >
              <parkour-bericht-speech-content
                [gesprek]="gesprekWithDeelnemers"
                [bericht]="bericht"
                [isUser]="isUser"
              />
            </parkour-bericht-speech-wrapper>
          }
          @case ('GEDEELDE_RESOURCE') {
            <parkour-bericht-resource
              (moreOptionsClick)="onMoreOptionsClick()"
              [bericht]="bericht"
              [isUser]="isUser"
              [disabled]="disabled"
            />
          }
        }
      </div>
    </div>

    @if (bericht.reacties.length >= 1) {
      <div [ngClass]="{ 'justify-end': isUser }" class="flex w-full">
        <parkour-emoji-reacties
          (deleteEmoji)="onDeleteEmoji()"
          [ngClass]="{ 'mr-1': isUser, 'ml-1': !isUser }"
          [reacties]="bericht.reacties"
          [totalAmount]="bericht.reacties.length"
          [userProfielId]="userProfielId"
          [disabled]="disabled"
          class="inline-block border-[1.5px] border-solid border-n-100 translate-y-[-0.2rem] rounded-[9999px]"
        />
      </div>
    }
  </div>
</div>

<ion-popover
  #popover="parkourPopover"
  (didDismiss)="onDidDismiss()"
  (willDismiss)="onWillDismiss()"
  [alignment]="getPopoverAlignment()"
  [arrow]="false"
  [ngClass]="{ 'bericht-message-popover-user': isUser, 'bericht-message-popover': !isUser }"
  [showBackdrop]="false"
  [trigger]="bericht.id"
  mode="ios"
  parkourPopover
  side="bottom"
>
  <ng-template>
    @if (isNativeApp()) {
      <parkour-button (clicked)="onCloseClick()" class="sr-only outline-none">
        {{ 'buttons.close-popover' | translate }}
      </parkour-button>
    }

    <ul class="divide-n-500/50 divide-y-[1.5px]">
      @if (!disabled) {
        <li>
          <parkour-add-emoji
            (deleteEmoji)="onDeleteEmoji()"
            (selectedEmoji)="onSelectedEmoji($event)"
            [activeEmojiKey]="getActiveSelectedReactie()"
            class="block p-4"
          />
        </li>
      }

      @if (
        (bericht.type === 'TEKST' || bericht.type === 'ATTACHMENT' || bericht.type === 'SPRAAK') &&
        !disabled
      ) {
        <li>
          <parkour-action
            (clicked)="onReplyBericht()"
            class="w-full"
            classes="w-full flex items-center px-4 py-2 gap-x-3 hover:bg-n-400/50 transition-colors"
          >
            <parkour-icon class="inline-flex" name="reply" />

            <p class="typo-body variant-body-small">
              {{ 'buttons.reply-message' | translate }}
            </p>
          </parkour-action>
        </li>
      }

      @if (bericht.type === 'TEKST' && !disabled) {
        <li>
          <parkour-action
            (clicked)="onSaveNotitie()"
            class="w-full"
            classes="w-full flex items-center px-4 py-2 gap-x-3 hover:bg-n-400/50 transition-colors"
          >
            <parkour-icon class="inline-flex" name="bookmark" />

            <p class="typo-body variant-body-small">
              {{ 'buttons.save-notitie' | translate }}
            </p>
          </parkour-action>
        </li>
      }

      @if (
        !isUser &&
        (bericht.type === 'TEKST' || bericht.type === 'ATTACHMENT' || bericht.type === 'SPRAAK')
      ) {
        <li>
          <parkour-action
            (clicked)="onClickRapporteren()"
            class="w-full"
            classes="w-full flex items-center px-4 py-2 gap-x-3 hover:bg-n-400/50 transition-colors"
          >
            <parkour-icon class="inline-flex" name="alert-triangle" />

            <p class="typo-body variant-body-small">
              {{ 'berichten.gesprek-page.bericht-rapporteren' | translate }}
            </p>
          </parkour-action>
        </li>
      }

      @if (isUser && !disabled) {
        <li>
          <parkour-action
            (clicked)="onClickVerwijder()"
            class="w-full"
            classes="w-full flex items-center px-4 py-2 gap-x-3 hover:bg-n-400/50 transition-colors rounded-bl rounded-br"
          >
            <parkour-icon class="text-negative inline-flex" name="trash" />

            <p class="text-negative typo-body variant-body-small">
              {{ 'berichten.gesprek-page.bericht-verwijderen' | translate }}
            </p>
          </parkour-action>
        </li>
      }
    </ul>
  </ng-template>
</ion-popover>
