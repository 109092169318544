import { Injectable } from '@angular/core';
import { combineLatest, map, mergeMap, Observable, switchMap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {
  Gebeurtenis,
  GebeurtenisSuggestie,
  GebeurtenisSuggestieUpsert,
  GebeurtenisUpsert,
} from '../model/gebeurtenis';
import { asType, stripNullProperties } from '../../../utils';
import { environment } from 'src/environments/environment';
import {
  DrieKolommenDocumentDto,
  FileData,
  FotoId,
  GebeurtenisDto,
  GebeurtenisId,
  GebeurtenisImageVariant,
  GebeurtenisSuggestieDto,
  GebeurtenisSuggestieUpsertDto,
  GebeurtenisUpsertDto,
  SuggestieId,
  SuggestieStatus,
  VerhaalDto,
} from 'parkour-web-app-dto';
import { ContextService } from '../../../shared/services/context.service';
import { Capacitor, HttpHeaders } from '@capacitor/core';
import { FileService } from '../../../shared/services/file.service';
import AuthService from '../../../authentication/service/auth.service';
import { AnalyticsService } from '../../../analytics/analytics.service';
import { AnalyticsEvent, trackAnalyticsEvent } from '../../../analytics/analytics-event.model';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';

@Injectable({
  providedIn: 'root',
})
export class VerhaalService {
  constructor(
    private readonly http: HttpClient,
    private readonly contextService: ContextService,
    private readonly authService: AuthService,
    private readonly fileService: FileService,
    private readonly analyticsService: AnalyticsService,
  ) {}

  getGebeurtenis(id: GebeurtenisId): Observable<Gebeurtenis> {
    return this.contextService
      .contextIdOfJongere$()
      .pipe(
        mergeMap((profielId) =>
          this.http.get<GebeurtenisDto>(
            `${environment.API_BASE_URL}/api/jongere/${profielId}/gebeurtenissen/${id}`,
          ),
        ),
      );
  }

  getDocument(id: string): Observable<DrieKolommenDocumentDto> {
    return this.contextService
      .contextIdOfJongere$()
      .pipe(
        mergeMap((contextId) =>
          this.http
            .get<DrieKolommenDocumentDto>(
              `${environment.API_BASE_URL}/api/jongere/${contextId}/drie-kolommen-document/${id}`,
            )
            .pipe(map((dto) => dto)),
        ),
      );
  }

  getVerhaalTijdlijn(): Observable<VerhaalDto> {
    return this.contextService
      .contextIdOfJongere$()
      .pipe(
        mergeMap((contextId) =>
          this.http.get<VerhaalDto>(
            `${environment.API_BASE_URL}/api/jongere/${contextId}/gebeurtenissen`,
          ),
        ),
      );
  }

  getGedeeldeGebeurtenissen(): Observable<VerhaalDto> {
    return combineLatest([
      this.contextService.teamlidContext$(),
      this.authService.getAangemeldeUser$(),
    ]).pipe(
      mergeMap(([context, user]) =>
        this.http.get<VerhaalDto>(
          `${environment.API_BASE_URL}/api/jongere/${context.jongereProfiel.id}/gebeurtenissen`,
          { params: { gedeeldMet: user.profielId } },
        ),
      ),
    );
  }

  addGebeurtenis(gebeurtenis: GebeurtenisUpsert): Observable<GebeurtenisDto> {
    return this.contextService.contextIdOfJongere$().pipe(
      switchMap((contextId) =>
        this.http.post<GebeurtenisDto>(
          `${environment.API_BASE_URL}/api/jongere/${contextId}/gebeurtenissen`,
          stripNullProperties(asType<GebeurtenisUpsertDto>({ ...gebeurtenis })),
        ),
      ),
      trackAnalyticsEvent(this.analyticsService, new AnalyticsEvent('verhaal', 'aangemaakt')),
    );
  }

  updateGebeurtenis(
    gebeurtenisId: GebeurtenisId,
    gebeurtenis: GebeurtenisUpsert,
    originalGebeurtenis: Gebeurtenis,
  ): Observable<GebeurtenisDto> {
    return this.contextService.contextIdOfJongere$().pipe(
      mergeMap((contextId) =>
        this.http.put<GebeurtenisDto>(
          `${environment.API_BASE_URL}/api/jongere/${contextId}/gebeurtenissen/${gebeurtenisId}`,
          stripNullProperties(asType<GebeurtenisUpsertDto>({ ...gebeurtenis })),
        ),
      ),
      trackAnalyticsEvent(this.analyticsService, () => {
        if (originalGebeurtenis.deelMode !== gebeurtenis.deelMode) {
          return new AnalyticsEvent(
            'verhaal',
            gebeurtenis.deelMode === 'IEDEREEN' ? 'gedeeldMetIedereen' : 'gedeeldMetSpecifiek',
          );
        } else if (
          originalGebeurtenis.type === 'EYOUTH' &&
          gebeurtenis.type === 'EYOUTH' &&
          gebeurtenis.verborgenVoorJongere != gebeurtenis.verborgenVoorJongere
        ) {
          if (gebeurtenis.verborgenVoorJongere) {
            return new AnalyticsEvent('verhaal', 'jeugdhulphistoriekVerborgen');
          } else {
            return new AnalyticsEvent('verhaal', 'jeugdhulphistoriekZichtbaar');
          }
        } else {
          return new AnalyticsEvent('verhaal', 'gewijzigd');
        }
      }),
    );
  }

  deleteGebeurtenis(gebeurtenisId: GebeurtenisId) {
    return this.contextService.contextIdOfJongere$().pipe(
      switchMap((contextId) =>
        this.http.delete<void>(
          `${environment.API_BASE_URL}/api/jongere/${contextId}/gebeurtenissen/${gebeurtenisId}`,
        ),
      ),
      trackAnalyticsEvent(this.analyticsService, new AnalyticsEvent('verhaal', 'verwijderd')),
    );
  }

  uploadGebeurtenisFoto(gebeurtenisId: GebeurtenisId, gebeurtenisFoto: File): Observable<void> {
    let headers: HttpHeaders = {};
    if (Capacitor.isNativePlatform()) {
      headers = { 'Content-Type': 'multipart/form-data; boundary=uploadGebeurtenisFoto' };
    }
    const data: FormData = new FormData();

    data.append('file', gebeurtenisFoto);

    return this.contextService.contextIdOfJongere$().pipe(
      switchMap((contextId) =>
        this.http.put<void>(
          `${environment.API_BASE_URL}/api/jongere/${contextId}/gebeurtenissen/${gebeurtenisId}/foto`,
          data,
          { headers },
        ),
      ),
      trackAnalyticsEvent(
        this.analyticsService,
        new AnalyticsEvent('verhaal', 'afbeeldingToegevoegd'),
      ),
    );
  }

  deleteGebeurtenisFoto(gebeurtenisId: GebeurtenisId, gebeurtenisFotoId: FotoId): Observable<void> {
    return this.contextService.contextIdOfJongere$().pipe(
      switchMap((contextId) =>
        this.http.delete<void>(
          `${environment.API_BASE_URL}/api/jongere/${contextId}/gebeurtenissen/${gebeurtenisId}/foto/${gebeurtenisFotoId}`,
        ),
      ),
      trackAnalyticsEvent(
        this.analyticsService,
        new AnalyticsEvent('verhaal', 'afbeeldingVerwijderd'),
      ),
    );
  }

  startFetchingJeugdhulpHistoriekGebeurtenissen() {
    return this.contextService.context$.subscribe((context) => {
      if (context.type === 'jongere') {
        this.http
          .post<void>(
            `${environment.API_BASE_URL}/api/jongere/${context.contextId}/jeugdhulphistoriek/refresh`,
            {},
          )
          .subscribe();
      }
    });
  }

  downloadBijlage(gebeurtenisId: GebeurtenisId, fileData: FileData) {
    return this.contextService.contextIdOfJongere$().pipe(
      switchMap((contextId) =>
        this.fileService.downloadFileFromUrl(
          `${environment.API_BASE_URL}/api/jongere/${contextId}/gebeurtenissen/${gebeurtenisId}/bijlage/${fileData.id}`,
          fileData.naam,
        ),
      ),
      trackAnalyticsEvent(
        this.analyticsService,
        new AnalyticsEvent('verhaal', 'bijlageDownloaded'),
      ),
    );
  }

  getGebeurtenisSuggestie(suggestieId: SuggestieId): Observable<GebeurtenisSuggestie> {
    return this.http.get<GebeurtenisSuggestieDto>(
      `${environment.API_BASE_URL}/api/suggesties/gebeurtenis/${suggestieId}`,
    );
  }

  getGebeurtenisSuggesties(): Observable<GebeurtenisSuggestie[]> {
    return this.contextService.contextIdOfJongere$().pipe(
      mergeMap((contextId) =>
        this.http.get<Array<GebeurtenisSuggestieDto>>(
          `${environment.API_BASE_URL}/api/suggesties/gebeurtenis`,
          {
            params: {
              to: contextId,
            },
          },
        ),
      ),
    );
  }

  updateGebeurtenisSuggestieStatus(
    suggestieId: SuggestieId,
    status: SuggestieStatus,
  ): Observable<void> {
    return this.http
      .put<void>(`${environment.API_BASE_URL}/api/suggesties/gebeurtenis/${suggestieId}/status`, {
        status,
      })
      .pipe(
        trackAnalyticsEvent(
          this.analyticsService,
          new AnalyticsEvent('verhaal', 'voorstelStatusGewijzigd'),
        ),
      );
  }

  addGebeurtenisSuggestie(gebeurtenisSuggestieUpsert: GebeurtenisSuggestieUpsert) {
    return this.http
      .post<void>(
        `${environment.API_BASE_URL}/api/suggesties/gebeurtenis`,
        asType<GebeurtenisSuggestieUpsertDto>(gebeurtenisSuggestieUpsert),
      )
      .pipe(
        trackAnalyticsEvent(
          this.analyticsService,
          new AnalyticsEvent('verhaal', 'voorstelAangemaakt'),
        ),
      );
  }

  getSuggestieImageUrl(id: SuggestieId, imageId: FotoId, variant: GebeurtenisImageVariant) {
    return `${environment.API_BASE_URL}/api/suggesties/gebeurtenis/${id}/image/${imageId}?variant=${variant}`;
  }

  downloadSuggestieBijlage(id: SuggestieId, fileData: FileData) {
    return fromPromise(
      this.fileService.downloadFileFromUrl(
        `${environment.API_BASE_URL}/api/suggesties/gebeurtenis/${id}/bijlage/${fileData.id}`,
        fileData.naam,
      ),
    ).pipe(
      trackAnalyticsEvent(
        this.analyticsService,
        new AnalyticsEvent('verhaal', 'bijlageDownloaded'),
      ),
    );
  }

  rejectSuggestie(suggestieId: SuggestieId): Observable<void> {
    return this.http
      .put<void>(`${environment.API_BASE_URL}/api/suggesties/${suggestieId}/reject`, {})
      .pipe(
        trackAnalyticsEvent(
          this.analyticsService,
          new AnalyticsEvent('verhaal', 'voorstelGeweigerd'),
        ),
      );
  }
}
