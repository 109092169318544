import { Component, Input } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { CoreModule } from '../../../../core/core.module';
import { TeamlidContext } from '../../../model/context';
import { SidebarWisselTeamPopoverComponent } from '../sidebar-wissel-team-popover/sidebar-wissel-team-popover.component';
import { ContextOption } from '../../../../profiel/model/context-option';
import { MeldingenService } from '../../../../profiel/service/meldingen.service';
import { ProfielFotoPipe } from '../../../pipes/profiel-foto.pipe';
import { RolLabelWithDefaultPipe } from '../../../pipes/rol-label-with-default.pipe';
import { ProfielnaamPipe } from '../../../pipes/profielnaam.pipe';
import {
  ParkourButtonComponent,
  ParkourCardComponent,
  ParkourNewIndicatorComponent,
} from '@parkour/ui';

@Component({
  selector: 'parkour-sidebar-teamlid-context-card',
  standalone: true,
  imports: [
    AsyncPipe,
    CoreModule,
    SidebarWisselTeamPopoverComponent,
    ProfielFotoPipe,
    RolLabelWithDefaultPipe,
    ProfielnaamPipe,
    ParkourButtonComponent,
    ParkourCardComponent,
    ParkourNewIndicatorComponent,
  ],
  templateUrl: './sidebar-teamlid-context-card.component.html',
})
export class SidebarTeamlidContextCardComponent {
  @Input({ required: true }) context!: TeamlidContext;
  @Input({ required: true }) mijnContexten!: ContextOption[];

  isTeamSwitchPopoverOpen = false;
  wisselTeamIndicator$ = this.meldingenService.hasWisselTeamMeldingen();

  constructor(private readonly meldingenService: MeldingenService) {}
}
