import { Component } from '@angular/core';
import { KalenderService } from '../../service/kalender.service';
import {
  ParkourBlockPlaceholderComponent,
  ParkourButtonComponent,
  ParkourInfoCardComponent,
  ParkourLoadingSpinnerComponent,
  ParkourPageGradientComponent,
  ParkourSegmentComponent,
  TParkourSegmentItems,
} from '@parkour/ui';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { combineLatest, map, Observable, of, shareReplay } from 'rxjs';
import { AfspraakViewDetailDto, ContextDto, validateVideogesprekId } from 'parkour-web-app-dto';
import { AangemeldeUser } from '../../../authentication/user';
import { BeeldbellenService } from '../../../beeldbellen/beeldbellen.service';
import { meldingenForPage } from '../../../meldingen/config';
import { ParkourBackButtonDirective } from '../../../shared/directives/parkour-back-button.directive';
import { AsyncPipe, NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { ParkourHeaderActionComponent } from '../../../shared/components/parkour-header-action/parkour-header-action.component';
import { DownloadAfsprakenComponent } from '../../component/download-afspraken/download-afspraken.component';
import { PageLayoutComponent } from '../../../shared/components/page-layout/page-layout.component';
import { AfsprakenPerMaandComponent } from '../../component/afspraken-per-maand/afspraken-per-maand.component';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';
import { ParkourRefreshDirective } from '../../../shared/directives/parkour-refresh-directive';
import { ParkourDataPage } from '../../../shared/parkour-data-page';
import { ShowIfSuccesfulDirective } from '../../../shared/directives/show-if-succesful.directive';
import { PageContentDirective } from '../../../shared/directives/page-content.directive';
import {
  MarksMeldingenAsReadOnView,
  MeldingenToMarkAsRead,
} from '../../../shared/marks-meldingen-as-read';
import { ContextService } from '../../../shared/services/context.service';
import { Profiel } from '../../model/profiel';
import { ProfielService } from '../../service/profiel.service';
import { getProfielnaam } from '../../model/profiel-in-team';
import AuthService from '../../../authentication/service/auth.service';

export type KalenderPageData = {
  user: AangemeldeUser;
  profiel: Profiel;
  context: ContextDto;
  vroegereAfsprakenMaandGroep: Array<AfspraakViewDetailDto>;
  komendeAfsprakenMaandGroep: Array<AfspraakViewDetailDto>;
};

@Component({
  standalone: true,
  templateUrl: './kalender.page.html',
  imports: [
    ParkourBackButtonDirective,
    NgIf,
    ParkourHeaderActionComponent,
    ParkourPageGradientComponent,
    ParkourSegmentComponent,
    ParkourInfoCardComponent,
    AsyncPipe,
    NgSwitch,
    NgSwitchCase,
    ParkourButtonComponent,
    DownloadAfsprakenComponent,
    ParkourLoadingSpinnerComponent,
    ParkourBlockPlaceholderComponent,
    TranslateModule,
    PageLayoutComponent,
    AfsprakenPerMaandComponent,
    IonRefresher,
    IonRefresherContent,
    IonBackButton,
    IonContent,
    IonButtons,
    IonTitle,
    IonToolbar,
    IonHeader,
    ParkourRefreshDirective,
    ShowIfSuccesfulDirective,
    PageContentDirective,
  ],
})
export class KalenderPage
  extends ParkourDataPage<KalenderPageData>
  implements MarksMeldingenAsReadOnView
{
  ongelezenAfspraakIds$ = this.meldingenService
    .getMeldingenInCurrentContextByType(
      meldingenForPage.PROFIEL_TAB_MOBILE.KALENDER_PAGE.AFSPRAAK_PAGE.allMeldingen,
    )
    .pipe(
      map((meldingen) => {
        return meldingen.map((melding) => melding.metaData.afspraakId);
      }),
    );

  activeSegmentItem = 'komendeAfspraken';
  segmentItems: TParkourSegmentItems = [];

  constructor(
    private readonly kalenderService: KalenderService,
    private readonly authService: AuthService,
    private readonly translate: TranslateService,
    private readonly beeldbellenService: BeeldbellenService,
    private readonly contextService: ContextService,
    private readonly profielService: ProfielService,
  ) {
    super();
  }

  override retrieveMainData(): Observable<KalenderPageData> {
    const alleAfspraken$ = this.kalenderService.getAfspraken().pipe(shareReplay(1));

    return combineLatest({
      user: this.authService.getAangemeldeUser$(),
      context: this.contextService.context$,
      vroegereAfsprakenMaandGroep: alleAfspraken$.pipe(
        map((afspraken) => afspraken.filter((afspraak) => this.isBeforeToday(afspraak.datum))),
      ),
      komendeAfsprakenMaandGroep: alleAfspraken$.pipe(
        map((afspraken) => afspraken.filter((afspraak) => !this.isBeforeToday(afspraak.datum))),
      ),
      profiel: this.profielService.getCurrentUserProfiel$(),
    });
  }

  getMeldingenToMarkAsRead() {
    return of(
      new MeldingenToMarkAsRead(
        meldingenForPage.PROFIEL_TAB_MOBILE.KALENDER_PAGE.specificMeldingen,
      ),
    );
  }

  override ionViewWillEnter(): void {
    super.ionViewWillEnter();

    this.translate
      .get([
        'kalender.kalender-page.komende-afspraken',
        'kalender.kalender-page.vroegere-afspraken',
      ])
      .subscribe((translations) => {
        this.segmentItems = [
          {
            label: translations['kalender.kalender-page.komende-afspraken'],
            value: 'komendeAfspraken',
          },
          {
            label: translations['kalender.kalender-page.vroegere-afspraken'],
            value: 'vroegereAfspraken',
          },
        ];
      });
  }

  segmentChanged(event: Event): void {
    this.activeSegmentItem = (event as CustomEvent).detail.value;
  }

  isBeforeToday(afspraakDatumString: string) {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const afspraakDatum = new Date(afspraakDatumString);
    return afspraakDatum < today;
  }

  onJoinAfspraakClicked(afspraak: AfspraakViewDetailDto, profiel: Profiel) {
    if (afspraak.type === 'BASIC') {
      return;
    }

    this.meldingenService.markMeldingenInCurrentContextAsReadWithTypes(
      meldingenForPage.PROFIEL_TAB_MOBILE.KALENDER_PAGE.AFSPRAAK_PAGE.specificMeldingen,
      (melding) => melding.metaData.afspraakId === afspraak.id,
    );

    this.meldingenService.markMeldingenInCurrentContextAsReadWithTypes(
      meldingenForPage.PROFIEL_TAB_DESKTOP.KALENDER_PAGE.AFSPRAAK_PAGE.specificMeldingen,
      (melding) => melding.metaData.afspraakId === afspraak.id,
    );

    if (!afspraak.gesprek?.id) {
      throw new Error('Videogesprek kan niet gestart worden');
    }

    if (afspraak.jongere.status !== 'ACTIEF') {
      throw new Error('Jongere is niet actief');
    }

    this.beeldbellenService
      .startVideogesprek(
        {
          jongere: afspraak.jongere,
          teamlid: afspraak.teamlid,
          videogesprekId: validateVideogesprekId(afspraak.gesprek?.id),
        },
        getProfielnaam(profiel),
        profiel,
        'afspraak',
        'joined',
      )
      .subscribe();
  }
}
