import { Pipe, PipeTransform } from '@angular/core';
import { mergeMap, Observable, of } from 'rxjs';
import { ImagesService } from '../../../shared/services/images.service';
import { GebeurtenisFotoService } from '../service/gebeurtenis-foto.service';
import { Gebeurtenis } from '../model/gebeurtenis';
import { themaMapping } from '../../../home/model/thema.const';
import { HeroSources } from '../../../shared/components/page-with-hero/page-with-hero.types';
import { verhaalHeroSources } from '../../constants';

@Pipe({
  name: 'gebeurtenisHero',
  standalone: true,
})
export class GebeurtenisHeroPipe implements PipeTransform {
  constructor(
    private readonly gebeurtenisFotoService: GebeurtenisFotoService,
    private readonly imageService: ImagesService,
  ) {}

  transform(gebeurtenis: Gebeurtenis): Observable<HeroSources> {
    if (gebeurtenis.fotoId) {
      return this.gebeurtenisFotoService
        .getGebeurtenisFotoUrl(gebeurtenis.id, gebeurtenis.fotoId, 'LARGE')
        .pipe(mergeMap((url) => this.imageService.getImage(url)));
    } else if (gebeurtenis.thema) {
      return of({
        background: themaMapping[gebeurtenis.thema].largeImage.blur,
        hero: themaMapping[gebeurtenis.thema].largeImage,
      });
    } else {
      return of(verhaalHeroSources);
    }
  }
}
