import { Component } from '@angular/core';
import { ParkourButtonComponent, ParkourHeroComponent } from '@parkour/ui';

@Component({
  standalone: true,
  selector: 'parkour-over-parkour',
  templateUrl: './over-parkour.component.html',
  imports: [ParkourHeroComponent, ParkourButtonComponent],
})
export class OverParkourComponent {}
