<parkour-action
  (clicked)="modal.open()"
  [ariaExpanded]="modal.isOpen"
  [ariaLabel]="getAriaLabel()"
  ariaControls="reacties-modal"
>
  <ul class="flex items-center gap-x-1 bg-n-400 rounded-[9999px] px-2 py-[2px]">
    @for (reactie of getUniqueReacties(); track $index) {
      <li>
        <span class="typo-body variant-body-small"> {{ reactie.type | parkourEmoji }} </span>
      </li>
    }

    @if (totalAmount > 1) {
      <li>
        <span class="typo-body variant-body-small variant-body-fat ml-1"> {{ totalAmount }} </span>
      </li>
    }
  </ul>
</parkour-action>

<parkour-modal
  [hasSpacing]="false"
  [title]="
    totalAmount > 1
      ? ('berichten.gesprek-page.reacties'
        | translate
          : {
              amount: totalAmount,
              type,
            })
      : ('berichten.gesprek-page.reactie'
        | translate
          : {
              type,
            })
  "
  id="reacties-modal"
>
  <ul class="p-2 space-y-1" footer>
    @if (getUserReactie().length > 0) {
      <li>
        @if (disabled) {
          <div class="flex p-2 justify-between items-center rounded w-full clickable-card-200">
            <ng-container [ngTemplateOutlet]="reactie"></ng-container>
          </div>
        } @else {
          <parkour-action
            [ariaLabel]="
              'berichten.gesprek-page.reactie-user-delete'
                | translate
                  : {
                      reactie: getUserReactie()[0].type | parkourEmoji,
                    }
            "
            (clicked)="deleteReactie()"
            classes="flex p-2 justify-between items-center rounded w-full clickable-card-200"
          >
            <ng-container [ngTemplateOutlet]="reactie"></ng-container>
          </parkour-action>
        }

        <ng-template #reactie>
          @if (getUserReactie()[0].ownerId | profiel | async; as profiel) {
            <parkour-profiel-block [profielData]="profiel" [customTag]="true">
              <span class="typo-link underline">{{ 'buttons.delete-reaction' | translate }}</span>
            </parkour-profiel-block>
          }

          <span class="typo-h3">{{ getUserReactie()[0].type | parkourEmoji }}</span>
        </ng-template>
      </li>
    }

    @for (reactie of getExternalReacties(); track reactie) {
      <li class="flex p-2 justify-between items-center rounded bg-n-200">
        @if (reactie.ownerId | profiel | async; as profiel) {
          <parkour-profiel-block [profielData]="profiel" />
        }

        <span class="typo-h3"
          ><span class="sr-only">{{ 'berichten.gesprek-page.reactie-team' | translate }}</span>

          {{ reactie.type | parkourEmoji }}
        </span>
      </li>
    }
  </ul>
</parkour-modal>
