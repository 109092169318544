<ion-radio-group [formControl]="delenFormGroup.controls.deelMode" class="flex flex-col gap-y-4">
  <parkour-radio
    [label]="'delen.delen-specifiek' | translate"
    icon="lock"
    inputId="specifiek"
    value="SPECIFIEK"
  />
  <parkour-radio
    [label]="'delen.delen-iedereen' | translate"
    icon="people-group"
    inputId="iedereen"
    value="IEDEREEN"
  />
</ion-radio-group>

<parkour-info-card *ngIf="teamleden.length === 0" classes="mt-2">
  @if (delenFormGroup.getRawValue().deelMode === 'SPECIFIEK') {
    {{ 'delen.info-specifiek-geen-teamleden' | translate }}
  } @else if (delenFormGroup.getRawValue().deelMode === 'IEDEREEN') {
    {{ 'delen.info-iedereen-geen-teamleden' | translate }}
  }
</parkour-info-card>

<div *ngIf="teamleden.length > 0" class="p-4 bg-n-200 rounded mt-6">
  <p class="typo-body variant-body-fat">{{ 'delen.actieve-teamleden' | translate }}</p>

  <ul class="mt-6 flex flex-col gap-y-6">
    @for (
      gedeeldMetForm of delenFormGroup.controls.gedeeldMet.controls;
      track gedeeldMetForm;
      let i = $index
    ) {
      <li *ngIf="teamleden[i].status === 'ACTIEF'">
        <parkour-gedeeld-met-item
          [editable]="delenFormGroup.getRawValue().deelMode === 'SPECIFIEK'"
          [formGroup]="gedeeldMetForm"
          [persoon]="teamleden[i]"
        />
      </li>
    }
  </ul>
</div>

<div *ngIf="hasGeblokkeerdeTeamleden()" class="p-4 bg-n-150 rounded mt-1">
  <p class="typo-body variant-body-fat">{{ 'delen.geblokkeerde-teamleden' | translate }}</p>

  <ul class="mt-6 flex flex-col gap-y-6">
    @for (
      gedeeldMetForm of delenFormGroup.controls.gedeeldMet.controls;
      track gedeeldMetForm;
      let i = $index
    ) {
      <li *ngIf="teamleden[i].status === 'GEBLOKKEERD'">
        <parkour-gedeeld-met-item
          [editable]="delenFormGroup.getRawValue().deelMode === 'SPECIFIEK'"
          [formGroup]="gedeeldMetForm"
          [persoon]="teamleden[i]"
        />
      </li>
    }
  </ul>
</div>
