import { Component, HostBinding, Input } from '@angular/core';
import { DeelnemerRejoinedEventDto } from 'parkour-web-app-dto';
import { AsyncPipe } from '@angular/common';
import { ProfielnaamPipe } from '../../../shared/pipes/profielnaam.pipe';
import { ProfielPipe } from '../../../shared/pipes/profiel.pipe';

@Component({
  selector: 'parkour-event-user-rejoined',
  templateUrl: './event-user-rejoined.component.html',
  imports: [AsyncPipe, ProfielnaamPipe, ProfielPipe],
  standalone: true,
})
export class EventUserRejoinedComponent {
  @Input({ required: true }) gesprekEvent!: DeelnemerRejoinedEventDto;

  @HostBinding('class') get class() {
    return 'chat-flow__event';
  }
}
