import { HeroSources } from '../../shared/components/page-with-hero/page-with-hero.types';

export const doelHeroSources: HeroSources = {
  background: '/assets/doelen/background/background_doelen_blur.jpg',
  hero: {
    sources: {
      lg: {
        src: '/assets/doelen/background/lg/background_doelen_lg.webp',
        fallback: '/assets/doelen/background/lg/background_doelen_lg.jpg',
      },
      md: {
        src: '/assets/doelen/background/md/background_doelen_md.webp',
        fallback: '/assets/doelen/background/md/background_doelen_md.jpg',
      },
      default: {
        src: '/assets/doelen/background/default/background_doelen_default.webp',
        fallback: '/assets/doelen/background/default/background_doelen_default.jpg',
      },
    },
  },
};
