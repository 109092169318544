import { Injectable } from '@angular/core';
import { from, map, NEVER, Observable } from 'rxjs';
import { ParkourModalService } from '@parkour/ui';
import { environment } from '../../../environments/environment';
import { isNativeApp } from '../../utils';
import { RedirectConfig } from './auth.service';
import { ProfielId } from 'parkour-web-app-dto';
import { Capacitor, CapacitorHttp, HttpParams, HttpResponse } from '@capacitor/core';
import { Router } from '@angular/router';
import { AcmIdmLoginModalComponent } from '../acm-idm-login-modal/acm-idm-login-modal.component';
import { Browser } from '@capacitor/browser';
import { LoginResult } from './background-detection.service';
import { AnalyticsService } from '../../analytics/analytics.service';
import { AnalyticsEvent, trackAnalyticsEvent } from '../../analytics/analytics-event.model';

@Injectable({
  providedIn: 'root',
})
export class AcmIdmAuthService {
  constructor(
    private readonly modalService: ParkourModalService,
    private readonly router: Router,
    private readonly analyticsService: AnalyticsService,
  ) {}

  public startAcmIdmAuthFlow(redirectConfig: RedirectConfig): Observable<LoginResult> {
    const redirectUrl = encodeURIComponent(
      redirectConfig.redirectUrl ??
        this.router.getCurrentNavigation()?.initialUrl.toString() ??
        this.router.url,
    );

    if (isNativeApp()) {
      this.openAcmIdmWaitingModal(redirectUrl, redirectConfig.redirectProfielId);
    }
    return this.continueAcmIdmAuthFlow(redirectUrl, redirectConfig.redirectProfielId).pipe(
      trackAnalyticsEvent(this.analyticsService, new AnalyticsEvent('auth', 'loginAcmIdmGestart')),
      map(() => ({ type: 'acm-idm-login' })),
    );
  }

  private startAcmIdmFlowForIOS(
    redirectUrl: string,
    redirectProfielId?: ProfielId,
  ): Observable<void> {
    let params: HttpParams;
    if (redirectProfielId) {
      params = { native: 'true', redirect: redirectUrl, redirectProfielId };
    } else {
      params = { native: 'true', redirect: redirectUrl };
    }

    return from(
      CapacitorHttp.get({
        url: `${environment.API_BASE_URL}/login`,
        params,
        disableRedirects: true,
      }),
    ).pipe(
      map((response) => {
        if (response.status === 302) {
          const locationHeader = this.getLocationFromLoginResponse(response);
          window.open(locationHeader);
        } else {
          throw Error('login failed');
        }
      }),
    );
  }

  private startAcmIdmFlowForAndroid(
    redirectUrl: string,
    redirectProfielId?: ProfielId,
  ): Observable<void> {
    let params: HttpParams;
    if (redirectProfielId) {
      params = { native: 'true', redirect: redirectUrl, redirectProfielId };
    } else {
      params = { native: 'true', redirect: redirectUrl };
    }
    return from(
      CapacitorHttp.get({
        url: `${environment.API_BASE_URL}/login?mobile`,
        params,
        disableRedirects: true,
      }),
    ).pipe(
      map((response) => {
        if (response.status === 302) {
          const locationHeader = this.getLocationFromLoginResponse(response);
          window.open(locationHeader);
        } else {
          throw Error('login failed');
        }
      }),
    );
  }

  private getLocationFromLoginResponse(loginResponse: HttpResponse) {
    if (loginResponse.headers['location']) {
      return loginResponse.headers['location'];
    } else if (loginResponse.headers['Location']) {
      return loginResponse.headers['Location'];
    } else {
      throw Error('RedirectUrl cannot be empty');
    }
  }

  private continueAcmIdmAuthFlow(
    redirectUrl: string,
    redirectProfielId?: ProfielId,
  ): Observable<void> {
    switch (Capacitor.getPlatform()) {
      case 'ios':
        return this.startAcmIdmFlowForIOS(redirectUrl, redirectProfielId);
      case 'android':
        return this.startAcmIdmFlowForAndroid(redirectUrl, redirectProfielId);
      default: {
        let href = `${environment.API_BASE_URL}/login?redirect=${redirectUrl}`;
        if (redirectProfielId) {
          href = `${environment.API_BASE_URL}/login?redirect=${redirectUrl}&redirectProfielId=${redirectProfielId}`;
        }
        window.location.href = href;
        return NEVER;
      }
    }
  }

  public logoutOfIOS(): Observable<void> {
    return from(
      CapacitorHttp.get({
        url: `${environment.API_BASE_URL}/logout`,
        disableRedirects: true,
      }),
    ).pipe(
      map((response) => {
        if (response.status === 302) {
          const location = this.getLocationFromLoginResponse(response);
          window.open(location);
        } else {
          throw Error(`Expected redirect, but got ${response.status} instead`);
        }
      }),
    );
  }

  public logoutOfAndroid(): Observable<void> {
    return from(
      CapacitorHttp.get({
        url: `${environment.API_BASE_URL}/logout?mobile`,
        disableRedirects: true,
      }),
    ).pipe(
      map((response) => {
        if (response.status === 302) {
          const location = this.getLocationFromLoginResponse(response);
          Browser.open({ url: location });
        } else {
          throw Error(`Expected redirect, but got ${response.status} instead`);
        }
      }),
    );
  }

  private async openAcmIdmWaitingModal(redirectUrl: string, redirectProfielId?: ProfielId) {
    await this.modalService.showFullscreenModal(AcmIdmLoginModalComponent, 'acm-idm-modal', {
      aanmeldenClickCallback: () =>
        this.continueAcmIdmAuthFlow(redirectUrl, redirectProfielId).subscribe(),
    });
  }
}
