import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import {
  ParkourFormFieldComponent,
  ParkourIconComponent,
  ParkourInputComponent,
  ParkourTextareaComponent,
  ParkourToggleComponent,
  ParkourUiComponentsModule,
} from '@parkour/ui';
import { TranslateModule } from '@ngx-translate/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { DatePipe } from '@angular/common';

export type AfwezigFormGroup = FormGroup<{
  afwezig: FormControl<boolean>;
  van: FormControl<string | undefined>;
  tot: FormControl<string | undefined>;
  bericht: FormControl<string | undefined>;
}>;

@Component({
  selector: 'parkour-afwezig',
  templateUrl: './afwezig.component.html',
  standalone: true,
  imports: [
    ParkourUiComponentsModule,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
    ParkourIconComponent,
    ParkourToggleComponent,
    ParkourFormFieldComponent,
    ParkourInputComponent,
    ParkourTextareaComponent,
  ],
})
export class AfwezigComponent implements OnChanges {
  @Input({ required: true }) afwezigFormGroup!: AfwezigFormGroup;

  afwezigheidEnabled = false;
  date?: string;

  constructor(private readonly datePipe: DatePipe) {}

  ngOnChanges(changes: SimpleChanges) {
    if ('afwezigFormGroup' in changes) {
      this.afwezigheidEnabled = changes['afwezigFormGroup'].currentValue.value.afwezig;
      this.date = this.afwezigFormGroup.getRawValue().van
        ? this.datePipe.transform(this.afwezigFormGroup.getRawValue().van ?? '', 'yyyy-MM-dd') ?? ''
        : this.datePipe.transform(new Date(), 'yyyy-MM-dd') ?? '';

      this.setAfwezigheid(this.afwezigheidEnabled);
    }
  }

  setAfwezigheid(afwezigheidEnabled: boolean) {
    this.afwezigheidEnabled = afwezigheidEnabled;
    this.setValidators(afwezigheidEnabled);

    if (afwezigheidEnabled) {
      this.afwezigFormGroup.patchValue({
        afwezig: true,
        van: this.date,
        tot:
          this.datePipe.transform(this.afwezigFormGroup.getRawValue().tot ?? '', 'yyyy-MM-dd') ??
          '',
        bericht: this.afwezigFormGroup.getRawValue().bericht,
      });
    } else {
      this.afwezigFormGroup.patchValue({
        afwezig: false,
      });
    }
  }

  setValidators(afwezigheidEnabled: boolean) {
    if (afwezigheidEnabled) {
      this.afwezigFormGroup.get('van')?.addValidators(Validators.required);
    } else {
      this.afwezigFormGroup.get('van')?.clearValidators();
    }
  }

  getVanErrors() {
    return {
      ...this.afwezigFormGroup.errors,
      ...this.afwezigFormGroup.controls.van.errors,
    };
  }
}
