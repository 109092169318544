export const themas = [
  'ALCOHOL_DRUGS',
  'GELD',
  'IDENTITEIT',
  'LIEFDE',
  'MENTAAL_WELZIJN',
  'MIJN_GEZONDHEID',
  'SCHOOL',
  'SEKS',
  'VRIENDEN_EN_FAMILIE',
  'VRIJE_TIJD',
  'WERELD',
  'WERK',
  'WONEN',
] as const;

export type Thema = (typeof themas)[number];
