<div class="group relative w-full bg-n-200 rounded">
  @switch (bericht.resourceType) {
    @case ('ARTIKEL') {
      <parkour-bericht-artikel [artikelId]="bericht.resourceId">
        <ng-container [ngTemplateOutlet]="menuButton"></ng-container>
      </parkour-bericht-artikel>
    }
    @case ('HULPLIJN') {
      <parkour-bericht-hulplijn [organisationId]="bericht.resourceId">
        <ng-container [ngTemplateOutlet]="menuButton"></ng-container>
      </parkour-bericht-hulplijn>
    }
  }
</div>

<ng-template #menuButton>
  @if ((isDesktop() && disabled && !isUser) || (isDesktop() && !disabled)) {
    <parkour-button
      (clicked)="onMoreOptionsClick()"
      [ariaLabel]="'buttons.open-menu' | translate"
      class="group-hover:opacity-100 has-[:focus-visible]:opacity-100 z-10 transition-opacity opacity-0"
      classes="h-full"
      format="iconButton"
      icon="menu"
      size="none"
      variant="ghost"
    />
  }
</ng-template>
