import { Component, Input } from '@angular/core';
import { NgStyle } from '@angular/common';

@Component({
  standalone: true,
  selector: 'parkour-doel-progress-circle',
  templateUrl: './doel-progress-circle.component.html',
  imports: [NgStyle],
})
export class DoelProgressCircleComponent {
  @Input({ required: true }) maxValue!: number;
  @Input({ required: true }) currentValue!: number;

  getPercentage(): number {
    const percentage = this.maxValue !== 0 ? (this.currentValue / this.maxValue) * 100 : 0;

    return percentage === 0 ? 5 : percentage;
  }

  percentageToStrokeDasharray(percentage: number, radius: number) {
    if (percentage < 0 || percentage > 100) {
      throw new Error('Percentage must be between 0 and 100.');
    }

    const circumference = 2 * Math.PI * radius;
    const visibleLength = (percentage / 100) * circumference;
    const gapLength = circumference - visibleLength;

    return `${visibleLength} ${gapLength}`;
  }
}
