import {Injectable} from "@angular/core";
import {ModalController} from "@ionic/angular/standalone";
import type {ComponentProps} from '@ionic/core';

@Injectable({
    providedIn: "root",
})
export class ParkourModalService {
    constructor(private readonly modalController: ModalController) {}

    openModals = new Set<string>();

    async showFullscreenModal(component: Function, id: string, props: ComponentProps<Function> = {}): Promise<HTMLIonModalElement> {
        const modal = await this.modalController.create({
            component: component,
            showBackdrop: true,
            cssClass: "full-screen-modal",
            componentProps: { ...props, modalId: id },
            id
        });

        this.openModals.add(id);
        await modal.present();
        modal.onDidDismiss().then(() => {
            if(this.openModals.has(id)) {
                this.openModals.delete(id)
            }
        });

        return modal
    }

    async closeModal(id: string) {
        await this.modalController.dismiss(undefined, undefined, id);
    }

    isModalOpen(id: string): boolean {
        return this.openModals.has(id);
    }
}
