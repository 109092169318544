import { Component, Input } from '@angular/core';

import { Thema } from 'parkour-web-app-dto';
import { themaMapping } from '../../../../home/model/thema.const';
import { ParkourPictureComponent } from '@parkour/ui';
import { IonRadio } from '@ionic/angular/standalone';

@Component({
  standalone: true,
  selector: 'parkour-doel-thema',
  templateUrl: './doel-thema.component.html',
  styleUrls: ['./doel-thema.component.css'],
  imports: [ParkourPictureComponent, IonRadio],
})
export class DoelThemaComponent {
  @Input({ required: true }) thema!: Thema;
  protected readonly themaMapping = themaMapping;
}
