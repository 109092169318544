import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../../environments/environment';

@Pipe({
  name: 'makeUrlAbsolute',
  standalone: true,
})
export class MakeUrlAbsolutePipe implements PipeTransform {
  transform(value: string): string {
    if (value && value.startsWith('/')) {
      return environment.watWatBaseUrl + value;
    }

    return value;
  }
}
