import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';
import { BerichtenService } from '../../service/berichten.service';
import { BerichtId, DoelId, GesprekId, ProbleemReden } from 'parkour-web-app-dto';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { ProbleemService } from '../../../profiel/service/probleem.service';
import {
  ParkourButtonComponent,
  ParkourDividerComponent,
  ParkourFormFieldComponent,
  ParkourInfoCardComponent,
  ParkourLoadingSpinnerComponent,
  ParkourPageGradientComponent,
  ParkourSelectComponent,
  ParkourTextareaComponent,
  ParkourToastService,
} from '@parkour/ui';
import { combineLatest } from 'rxjs';
import { Bericht, Gesprek } from '../../model/gesprek';
import { CreateBerichtProbleem } from '../../model/berichtProbleem';
import { TranslateModule } from '@ngx-translate/core';
import { PageLayoutComponent } from '../../../shared/components/page-layout/page-layout.component';
import { AsyncPipe } from '@angular/common';
import { IsSupportedBerichtPipe } from '../../pipes/is-supported-bericht.pipe';
import { BerichtTextWrapperComponent } from '../../component/bericht-text-wrapper/bericht-text-wrapper.component';
import { BerichtTextContentComponent } from '../../component/bericht-text-content/bericht-text-content.component';
import { BerichtAttachmentContentComponent } from '../../component/bericht-attachment-content/bericht-attachment-content.component';
import { BerichtSpeechWrapperComponent } from '../../component/bericht-speech-wrapper/bericht-speech-wrapper.component';
import { BerichtSpeechContentComponent } from '../../component/bericht-speech-content/bericht-speech-content.component';
import { ParkourBackButtonDirective } from '../../../shared/directives/parkour-back-button.directive';
import { OpenGesprek } from '../../model/OpenGesprek';
import { ParkourDataPage } from '../../../shared/parkour-data-page';
import { PageSectionDirective } from '../../../shared/directives/page-section.directive';
import { PageContentDirective } from '../../../shared/directives/page-content.directive';
import { MijnProfiel } from '../../../profiel/model/profiel';
import { ProfielService } from '../../../profiel/service/profiel.service';
import AuthService from '../../../authentication/service/auth.service';
import { User } from '../../../authentication/user';

type PageData = {
  user: User;
  bericht: Bericht;
  gesprek: Gesprek | OpenGesprek;
  profiel: MijnProfiel;
};

@Component({
  templateUrl: './bericht-probleem-add.page.html',
  standalone: true,
  imports: [
    TranslateModule,
    PageLayoutComponent,
    ParkourPageGradientComponent,
    ParkourDividerComponent,
    AsyncPipe,
    IsSupportedBerichtPipe,
    BerichtTextWrapperComponent,
    BerichtTextContentComponent,
    BerichtAttachmentContentComponent,
    BerichtSpeechWrapperComponent,
    BerichtSpeechContentComponent,
    ParkourLoadingSpinnerComponent,
    ReactiveFormsModule,
    ParkourFormFieldComponent,
    ParkourSelectComponent,
    ParkourTextareaComponent,
    ParkourInfoCardComponent,
    ParkourButtonComponent,
    ParkourBackButtonDirective,
    IonBackButton,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonTitle,
    IonContent,
    PageSectionDirective,
    PageContentDirective,
  ],
})
export class BerichtProbleemAddPage extends ParkourDataPage<PageData> {
  berichtId!: BerichtId;
  gesprekId!: GesprekId;
  doelId?: DoelId;

  typeOptions = [
    {
      value: 'FRAUDE_BEDROG',
      label: 'Fraude of bedrog',
    },
    {
      value: 'HAATSPRAAK',
      label: 'Haatspraak',
    },
    {
      value: 'ONGEPAST',
      label: 'Ongepaste dingen',
    },
    {
      value: 'PESTEN',
      label: 'Pesten',
    },
    {
      value: 'ZELFDODING',
      label: 'Zelfdoding of zelfbeschadiging',
    },
    {
      value: 'ANDERE',
      label: 'Iets anders',
    },
  ];

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly route: ActivatedRoute,
    private readonly berichtenService: BerichtenService,
    private readonly probleemService: ProbleemService,
    private readonly toastService: ParkourToastService,
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly profielService: ProfielService,
  ) {
    super();
  }

  berichtProbleemForm = this.formBuilder.group({
    reden: this.formBuilder.nonNullable.control<ProbleemReden>(
      'FRAUDE_BEDROG',
      Validators.required,
    ),
    beschrijving: this.formBuilder.nonNullable.control('', Validators.required),
  });

  override retrieveMainData() {
    this.gesprekId = this.route.snapshot.queryParamMap.get('gesprekId') as GesprekId;
    this.berichtId = this.route.snapshot.queryParamMap.get('berichtId') as BerichtId;
    this.doelId = this.route.snapshot.queryParamMap.get('doelId') as DoelId;

    const gesprek$ = this.doelId
      ? this.berichtenService.getGesprekOfDoel(this.doelId)
      : this.berichtenService.getGesprek(this.gesprekId);

    return combineLatest({
      user: this.authService.getAangemeldeUser$(),
      bericht: this.berichtenService.getBericht(this.gesprekId, this.berichtId),
      gesprek: gesprek$,
      profiel: this.profielService.getCurrentUserProfiel$(),
    });
  }

  isFormDirty() {
    return this.berichtProbleemForm?.dirty || false;
  }

  submitForm() {
    this.getCurrentMainDataOrThrow().subscribe((mainData) => {
      const formValues = this.berichtProbleemForm.getRawValue();

      this.berichtProbleemForm.markAllAsTouched();

      if (mainData.user.type === 'aangemeld' && this.berichtProbleemForm.valid) {
        const berichtProbleem: CreateBerichtProbleem = {
          type: 'BERICHT',
          beschrijving: formValues.beschrijving,
          reden: formValues.reden as ProbleemReden,
          berichtId: this.berichtId,
          gesprekId: this.gesprekId,
          rapporteerder: {
            type: 'AANGEMELD',
            profielId: mainData.user.profielId,
          },
        };

        this.probleemService.addProbleem(berichtProbleem).subscribe({
          complete: () => {
            this.berichtProbleemForm?.markAsPristine();
            this.berichtProbleemForm?.reset();
            this.toastService.showToast({ header: 'Het bericht is gerapporteerd.' });
            this.router.navigate(['..'], {
              relativeTo: this.route,
              queryParams: this.doelId && { sheet: true },
            });
          },
          error: (e) => {
            throw e;
          },
        });
      }
    });
  }
}
