import {
  ActieDto,
  DeelMode,
  DoelId as DoelIdDto,
  DoelSuggestieDto,
  DoelSuggestieUpsertDto,
  ProfielId,
  SuggestieId,
  Thema,
} from 'parkour-web-app-dto';
import { GebeurtenisSuggestie } from '../../../verhaal/verhaal-shared/model/gebeurtenis';
import { DoelUpsert, doelUpsertFromDto } from './doel-upsert';
import { TeamlidProfiel } from '../../../team/model/teamlid.model';

export type DoelSuggestieUpsert = DoelSuggestieUpsertDto;

export function doelSuggestieFromDto(doelSuggestieDto: DoelSuggestieDto): DoelSuggestie {
  return {
    ...doelSuggestieDto,
    suggestedBy: doelSuggestieDto.suggestedBy,
    doelData: doelUpsertFromDto(doelSuggestieDto.doelData),
    aangemaaktOpTijdstip: new Date(doelSuggestieDto.aangemaaktOpTijdstip),
  };
}

export type Suggestie = {
  readonly id: SuggestieId;
  readonly status: string;
  readonly titel: string;
  readonly bericht: string;
  readonly aangemaaktOpTijdstip: Date;
};

export type DoelSuggestie = {
  doelData: DoelUpsert;
  suggestedBy: TeamlidProfiel;
} & Suggestie;

export class Doel {
  constructor(
    public readonly id: DoelId,
    public readonly naam: string,
    public readonly omschrijving: string,
    public readonly thema: Thema,
    public readonly aanmaakDatum: string,
    public readonly eigenaarId: ProfielId,
    public readonly streefDatum: Date | undefined,
    public readonly eindDatum: Date | undefined,
    public readonly acties: Actie[],
    public readonly gedeeldMet: ProfielId[],
    public readonly deelMode: DeelMode,
    public readonly activeGebeurtenisSuggestie?: GebeurtenisSuggestie,
  ) {}

  get isBehaald(): boolean {
    return !!this.eindDatum;
  }

  hasActies(): boolean {
    return this.acties.length !== 0;
  }

  alleActiesZijnVoltooid() {
    return this.acties.every((actie) => actie.voltooid) && this.hasActies();
  }
}

export type DoelId = DoelIdDto;
export type Actie = ActieDto;
