import { Component, Input } from '@angular/core';
import { TeamlidProfiel } from '../../../../team/model/teamlid.model';
import { DelenSectionGroup } from '../../../../delen/model/delen-form-group';
import { CoreModule } from '../../../../core/core.module';
import { DelenSectionComponent } from '../../../../delen/component/delen-section/delen-section.component';

@Component({
  selector: 'parkour-doel-edit-delen-section',
  standalone: true,
  imports: [CoreModule, DelenSectionComponent],
  templateUrl: './doel-edit-delen-section.component.html',
})
export class DoelEditDelenSectionComponent {
  @Input({ required: true }) teamleden!: Array<TeamlidProfiel>;
  @Input({ required: true }) gedeeldMetSection!: DelenSectionGroup;
}
