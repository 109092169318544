import { Component, Input, ViewChild } from '@angular/core';
import { DeelMode, ProfielId } from 'parkour-web-app-dto';
import { TeamledenPipe } from '../../../shared/pipes/teamleden.pipe';
import { TeamService } from '../../../team/service/team.service';
import { TeamlidProfiel } from '../../../team/model/teamlid.model';
import { ParkourInfoCardComponent, ParkourModalComponent } from '@parkour/ui';
import { AsyncPipe, NgForOf, NgIf, NgTemplateOutlet } from '@angular/common';
import { ParkourProfielBlockComponent } from '../../../shared/components/parkour-profiel-block/parkour-profiel-block.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  selector: 'parkour-gedeeld-met-info',
  templateUrl: './gedeeld-met-info.component.html',
  providers: [TeamledenPipe],
  imports: [
    ParkourModalComponent,
    ParkourInfoCardComponent,
    NgIf,
    NgForOf,
    ParkourProfielBlockComponent,
    AsyncPipe,
    TranslateModule,
    NgTemplateOutlet,
  ],
})
export class GedeeldMetInfoComponent {
  @ViewChild(ParkourModalComponent) parkourModal!: ParkourModalComponent;
  @Input({ required: true }) deelMode!: DeelMode;
  @Input({ required: true }) gedeeldMet!: ProfielId[];
  @Input({ required: true }) exJongereView!: boolean;

  protected teamleden$ = this.teamService.teamleden$;

  constructor(
    private filterTeamledenPipe: TeamledenPipe,
    private readonly teamService: TeamService,
  ) {}

  getVisibleTeamleden(teamleden: TeamlidProfiel[]): TeamlidProfiel[] {
    switch (this.deelMode) {
      case 'IEDEREEN':
        return teamleden;
      case 'SPECIFIEK':
        return this.filterTeamledenPipe.transform(this.gedeeldMet, teamleden);
    }
  }

  getGeblokkeerdeTeamleden(teamleden: TeamlidProfiel[]): TeamlidProfiel[] {
    return this.getVisibleTeamleden(teamleden).filter(
      (teamlid) => teamlid.status === 'GEBLOKKEERD',
    );
  }

  getActieveTeamleden(teamleden: TeamlidProfiel[]): TeamlidProfiel[] {
    return this.getVisibleTeamleden(teamleden).filter((teamlid) => teamlid.status === 'ACTIEF');
  }

  hasGeblokkeerdeTeamleden(teamleden: TeamlidProfiel[]): boolean {
    return this.getVisibleTeamleden(teamleden).some((teamlid) => teamlid.status === 'GEBLOKKEERD');
  }

  open() {
    this.parkourModal.open();
  }
}
