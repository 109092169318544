<parkour-block-placeholder
  class="block"
  [sources]="{
    default: {
      src: '/assets/doelen/placeholder/placeholder_doelen_default.svg',
    },
    md: {
      src: '/assets/doelen/placeholder/placeholder_doelen_md.svg',
    },
  }"
  alt=""
/>
