import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { ModalController } from "@ionic/angular/standalone";
import { TParkourIconName } from "../parkour-icon/parkour-icon.types";
import { NavigationEnd, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { IonModal } from "@ionic/angular/standalone";
import { ParkourIconComponent } from "../parkour-icon/parkour-icon.component";
import { NgIf } from "@angular/common";
import { ParkourButtonComponent } from "../parkour-button/parkour-button.component";

@Component({
  selector: "parkour-popup",
  templateUrl: "./parkour-popup.component.html",
  standalone: true,
  imports: [IonModal, ParkourIconComponent, NgIf, ParkourButtonComponent],
  providers: [ModalController],
})
export class ParkourPopupComponent implements OnInit, OnDestroy {
  @Input() public classes?: string;
  @Input({ required: true }) public isOpen!: boolean;
  @Input() public icon?: TParkourIconName;

  @Output() public hasBeenDismissed: EventEmitter<Event> =
    new EventEmitter<Event>();
  routerSubscription!: Subscription;

  constructor(
    private popupCtrl: ModalController,
    private readonly router: Router,
  ) {}

  ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.routerSubscription = this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.handleCloseModal();
      }
    });
  }

  didDismiss(event: Event) {
    this.hasBeenDismissed.emit(event);
  }

  handleCloseModal() {
    if (this.isOpen && this.popupCtrl) {
      this.popupCtrl.dismiss(null, "close");
    }
  }
}
