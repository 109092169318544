import { Pipe, PipeTransform } from '@angular/core';
import { getProfielnaam, ProfielnaamData } from '../../profiel/model/profiel-in-team';

@Pipe({
  name: 'profielnaam',
  standalone: true,
})
export class ProfielnaamPipe implements PipeTransform {
  transform(profiel: ProfielnaamData): string {
    return getProfielnaam(profiel);
  }
}
