import { Doel, DoelSuggestie } from '../../model/doel';
import { ParkourDataPage } from '../../../../shared/parkour-data-page';
import {
  MarksMeldingenAsReadOnView,
  MeldingenToMarkAsRead,
} from '../../../../shared/marks-meldingen-as-read';
import { inject } from '@angular/core';
import { DoelenService } from '../../service/doelen.service';
import { meldingenForPage } from '../../../../meldingen/config';
import { combineLatest, map, Observable, of, Subject } from 'rxjs';
import { TParkourSegmentItems } from '@parkour/ui';
import { Result } from '../../../../utils';

export type DoelOverviewPageData = {
  actieveDoelen: Doel[];
  behaaldeDoelen: Doel[];
  voorstellen: DoelSuggestie[];
};

export abstract class DoelOverviewPage
  extends ParkourDataPage<DoelOverviewPageData>
  implements MarksMeldingenAsReadOnView
{
  protected activeSegmentItem = 'actieveDoelen';
  protected readonly doelenService = inject(DoelenService);

  protected readonly ongelezenDoelMeldingen$ =
    this.meldingenService.getMeldingenInCurrentContextByType(
      meldingenForPage.DOELEN_TAB.DOELEN_PAGE.DOEL_PAGE.allMeldingen,
    );

  protected readonly refreshVoorstellen = new Subject<void>();

  segmentItems$: Observable<Result<TParkourSegmentItems>> = this.extraPageData(
    this.translateService
      .get(['doelen.doelen-page.actieve-doelen', 'doelen.doelen-page.behaalde-doelen'])
      .pipe(
        map((translations) => {
          return [
            {
              label: translations['doelen.doelen-page.actieve-doelen'],
              value: 'actieveDoelen',
              showIndicator: this.getActieveDoelenMeldingIndicator(),
            },
            {
              label: translations['doelen.doelen-page.behaalde-doelen'],
              value: 'behaaldeDoelen',
              showIndicator: this.getBehaaldeDoelenMeldingIndicator(),
            },
          ];
        }),
      ),
  );

  override retrieveMainData(): Observable<DoelOverviewPageData> {
    return combineLatest({
      actieveDoelen: this.getActieveDoelen(),
      behaaldeDoelen: this.getBehaaldeDoelen(),
      voorstellen: this.getDoelSuggesties(),
    });
  }

  abstract getActieveDoelen(): Observable<Doel[]>;
  abstract getBehaaldeDoelen(): Observable<Doel[]>;

  getMeldingenToMarkAsRead() {
    return of(new MeldingenToMarkAsRead(meldingenForPage.DOELEN_TAB.DOELEN_PAGE.specificMeldingen));
  }
  getBehaaldeDoelenMeldingIndicator() {
    return this.getDoelenShouldShowIndicator(
      this.mainPageData$.pipe(map((data) => (data.success ? data.value.behaaldeDoelen : []))),
    );
  }

  getActieveDoelenMeldingIndicator() {
    return this.getDoelenShouldShowIndicator(
      this.mainPageData$.pipe(map((data) => (data.success ? data.value.actieveDoelen : []))),
    );
  }

  getDoelenShouldShowIndicator(doelen: Observable<Doel[]>): Observable<boolean> {
    return combineLatest([doelen, this.ongelezenDoelMeldingen$]).pipe(
      map(([doelen, ongelezenDoelMeldingen]) => {
        return doelen.some((doel) =>
          ongelezenDoelMeldingen.some((melding) => melding.metaData.doelId === doel.id),
        );
      }),
    );
  }

  abstract getDoelSuggesties(): Observable<DoelSuggestie[]>;

  segmentChanged(event: Event): void {
    this.activeSegmentItem = (event as CustomEvent).detail.value;
  }
}
