<div class="divide-y divide-n-600/30 bg-n-300 rounded">
  <div class="p-4 flex gap-x-2 justify-between">
    <div class="flex flex-col gap-y-2">
      <p class="typo-h5">{{ doelSuggestie.doelData.naam }}</p>

      <span class="typo-body text-n-700">{{ doelSuggestie.aangemaaktOpTijdstip | date }}</span>

      <parkour-doel-progress [doel]="doelSuggestie.doelData" />
    </div>

    <parkour-image
      [alt]="themaMapping[doelSuggestie.doelData.thema].label"
      [src]="themaMapping[doelSuggestie.doelData.thema].smallImage.src"
      class="block w-[4rem] h-[4rem] rounded-sm overflow-hidden"
    />
  </div>

  <div class="p-4 flex gap-x-2 items-center">
    @if (doelSuggestie.suggestedBy; as suggestedByProfiel) {
      <parkour-profiel-foto-badge
        [alt]="suggestedByProfiel | profielnaam"
        [profiel]="suggestedByProfiel"
        [size]="'2'"
      />

      <p
        [innerHtml]="
          'doelen.doel-suggestie-page.voorstel'
            | translate
              : {
                  name: suggestedByProfiel | profielnaam,
                  date: doelSuggestie.aangemaaktOpTijdstip | date,
                }
        "
      ></p>
    }
  </div>

  <div class="p-4 flex flex-col gap-y-6">
    <p>{{ 'doelen.doelen-page.suggestie-description' | translate }}</p>

    <div class="flex gap-x-4">
      <parkour-button (clicked)="onNo()" class="w-full" variant="tertiary">
        {{ 'buttons.nee' | translate }}
      </parkour-button>

      <parkour-button (clicked)="onYes()" class="w-full" variant="secondary"
        >{{ 'buttons.ja' | translate }}
      </parkour-button>
    </div>
  </div>
</div>
