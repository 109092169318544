import { Component, Input } from '@angular/core';
import { DrieKolommenDocumentDto, NieuwDrieKolommenDocumentMelding } from 'parkour-web-app-dto';
import {
  ParkourActionComponent,
  ParkourImageComponent,
  ParkourNewIndicatorComponent,
} from '@parkour/ui';
import { DatePipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { GedeeldMetIndicatorComponent } from '../../../../delen/component/gedeeld-met-indicator/gedeeld-met-indicator.component';

@Component({
  standalone: true,
  selector: 'parkour-drie-kolommen-gebeurtenis-list-item-card',
  templateUrl: './drie-kolommen-gebeurtenis-list-item-card.component.html',
  imports: [
    ParkourNewIndicatorComponent,
    DatePipe,
    ParkourImageComponent,
    ParkourActionComponent,
    TranslateModule,
    GedeeldMetIndicatorComponent,
  ],
})
export class DrieKolommenGebeurtenisListItemCardComponent {
  @Input({ required: true }) document!: DrieKolommenDocumentDto;
  @Input() ongelezenDrieKolommenMeldingen: Array<NieuwDrieKolommenDocumentMelding> = [];

  shouldShowNewIndicator(): boolean {
    return (
      this.ongelezenDrieKolommenMeldingen.find(
        (melding) => melding.metaData.documentId === this.document.id,
      ) !== undefined
    );
  }
}
