import { Component } from '@angular/core';
import { IonContent, IonHeader, IonTitle, IonToolbar } from '@ionic/angular/standalone';
import { TranslateModule } from '@ngx-translate/core';
import { PageLayoutComponent } from '../../components/page-layout/page-layout.component';
import { ErrorComponent } from '../../components/error-component/error.component';

@Component({
  standalone: true,
  templateUrl: './not-found.page.html',
  imports: [
    IonHeader,
    IonToolbar,
    IonTitle,
    TranslateModule,
    IonContent,
    PageLayoutComponent,
    ErrorComponent,
  ],
})
export class NotFoundPage {}
