<div
  class="relative"
  [ngClass]="{
    'max-w-[5.5rem]': size === 'full-size',
    'w-[1.5rem] h-[1.5rem] rounded-sm': size === '1.5',
    'w-[2rem] h-[2rem] rounded': size === '2',
    'w-[2.5rem] h-[2.5rem] rounded': size === '2.5',
    'w-[3rem] h-[3rem] rounded': size === '3',
    'w-[3.5rem] h-[3.5rem] rounded': size === '3.5',
    'w-[4rem] h-[4rem] rounded': size === '4',
    'w-[5rem] h-[5rem] rounded': size === '5',
  }"
></div>
