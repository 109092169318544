<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button parkourBackButton />
    </ion-buttons>

    <ion-title aria-level="1" role="heading"
      >{{ 'doelen.doel-edit-page.title' | translate }}
    </ion-title>

    <ion-buttons slot="end">
      @if (mainPageData$ | async) {
        <parkour-header-action
          [label]="'doelen.doel-edit-page.doel-opslaan' | translate"
          form="doel-edit-form"
          icon="disk"
          testId="submit"
          type="submit"
        />
      }
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <parkour-page-layout>
    <ng-container *parkourPageContent="mainPageData$ | async; refresh: onPageRefresh; let data">
      <parkour-page-image
        [src]="themaMapping[data.doel.thema].largeImage.blur"
        classes="absolute top-0 left-0 z-0"
      />

      <div class="relative z-10 pt-4 container container-space flex flex-col gap-y-8">
        <parkour-doel-edit-form
          #doelForm
          [submitDoel]="submitDoel"
          [initialState]="data.doel"
          [teamleden]="data.teamleden"
          formId="doel-edit-form"
        />

        <div class="full-bleed container-space bg-n-200 pt-6 pb-8 mt-8">
          <div class="container-1-col">
            <h3 class="mb-2">{{ 'doelen.doel-edit-page.afronden' | translate }}</h3>

            <p>{{ 'doelen.doel-edit-page.afronden-description' | translate }}</p>

            <parkour-button
              (clicked)="isVerwijderDoelPopupOpen = true"
              class="block w-full mt-4"
              iconAfter="trash"
              variant="tertiary"
            >
              {{ 'doelen.doel-edit-page.verwijderen' | translate }}
            </parkour-button>
          </div>
        </div>
      </div>
    </ng-container>
  </parkour-page-layout>
</ion-content>

<parkour-popup
  (hasBeenDismissed)="isVerwijderDoelPopupOpen = false"
  [isOpen]="isVerwijderDoelPopupOpen"
  icon="trash"
>
  <div content>
    <p class="typo-body variant-body-fat">{{ 'doelen.doel-edit-page.verwijderen' | translate }}</p>

    <p>{{ 'doelen.doel-edit-page.verwijderen-popup-description' | translate }}</p>
  </div>

  <div class="flex items-center gap-x-4" footer>
    <parkour-button (clicked)="isVerwijderDoelPopupOpen = false" class="w-full" variant="tertiary">
      {{ 'buttons.nee' | translate }}
    </parkour-button>

    <parkour-button (clicked)="verwijderDoel()" class="w-full">
      {{ 'buttons.ja' | translate }}
    </parkour-button>
  </div>
</parkour-popup>
