import { Component, Input } from '@angular/core';
import {
  ParkourActionComponent,
  ParkourIconComponent,
  ParkourTagComponent,
  TParkourIconName,
} from '@parkour/ui';
import { WatWatOrganisation } from 'parkour-web-app-dto';
import { NgClass, NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { OrganisationMethodWithNowOpen, OrganisationWithOpen } from '../../../shared/model/artikel';

type MethodView = {
  icon: TParkourIconName;
  href: string;
  title: string;
  ariaLabel: string;
};

@Component({
  selector: 'parkour-watwat-helplines',
  standalone: true,
  imports: [
    NgClass,
    NgIf,
    ParkourIconComponent,
    ParkourActionComponent,
    ParkourTagComponent,
    TranslateModule,
  ],
  templateUrl: './helpline-methods.component.html',
})
export class HelplineMethodsComponent {
  @Input() helplines!: OrganisationWithOpen[];

  getHelplineMethodView(
    helpline: WatWatOrganisation,
    method: OrganisationMethodWithNowOpen,
  ): MethodView {
    switch (method.type) {
      case 'chat':
        return {
          icon: 'bubble',
          title: `Chat met ${helpline.title}`,
          href: method.url || '',
          ariaLabel: `Chat met ${helpline.title} ${method.nowOpen ? 'Nu open.' : '.'}`,
        };
      case 'visit':
        return {
          icon: 'pin',
          title: `Ga langs bij ${helpline.title}`,
          href: method.url || '',
          ariaLabel: `Ga langs bij ${helpline.title} ${method.nowOpen ? 'Nu open.' : '.'}`,
        };
      case 'mail':
        return {
          icon: 'email',
          title: `Mail met ${helpline.title}`,
          href: method.email ? `mailto:${method.email}` : method.url || '',
          ariaLabel: `Mail met ${helpline.title} ${method.nowOpen ? 'Nu open.' : '.'}`,
        };
      case 'forum':
        return {
          icon: 'bubbles',
          title: `Bezoek het forum van ${helpline.title}`,
          href: method.url || '',
          ariaLabel: `Bezoek het forum van ${helpline.title} ${method.nowOpen ? 'Nu open.' : '.'}`,
        };
      case 'tel':
        return {
          icon: 'telephone',
          title: `Bel met ${helpline.title}`,
          href: method.telephone ? `tel:${method.telephone}` : method.url || '',
          ariaLabel: `Bel met ${helpline.title} ${method.nowOpen ? 'Nu open.' : '.'}`,
        };
      case 'activity':
        return {
          icon: 'calendar',
          title: `Bekijk activiteiten van ${helpline.title}`,
          href: method.url || '',
          ariaLabel: `Bekijk activiteiten van ${helpline.title} ${method.nowOpen ? 'Nu open.' : '.'}`,
        };
    }
  }
}
