import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GedeeldeResourceBericht } from '../../model/gesprek';
import { BerichtArtikelComponent } from '../bericht-artikel/bericht-artikel.component';
import { BerichtHulplijnComponent } from '../bericht-hulplijn/bericht-hulplijn.component';
import { BerichtenDatePipe } from '../../pipes/berichten-date.pipe';
import { isPlatform } from '@ionic/core';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ParkourButtonComponent } from '@parkour/ui';

@Component({
  selector: 'parkour-bericht-resource',
  templateUrl: './bericht-resource.component.html',
  standalone: true,
  imports: [
    BerichtArtikelComponent,
    BerichtHulplijnComponent,
    BerichtenDatePipe,
    NgClass,
    TranslateModule,
    NgTemplateOutlet,
    ParkourButtonComponent,
  ],
})
export class BerichtResourceComponent {
  @Input({ required: true }) bericht!: GedeeldeResourceBericht;
  @Input({ required: true }) disabled!: boolean;
  @Input({ required: true }) isUser!: boolean;

  @Output() moreOptionsClick = new EventEmitter<void>();

  isDesktop() {
    return isPlatform('desktop');
  }

  onMoreOptionsClick() {
    this.moreOptionsClick.emit();
  }
}
