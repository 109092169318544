<div class="relative">
  <parkour-button
    (clicked)="openDoelReacties()"
    [ariaControls]="sheetId"
    [ariaExpanded]="(sheetOpen$ | async) ?? false"
    [iconAfter]="'bubbles'"
    [variant]="'secondary'"
    class="w-full"
  >
    {{ 'doelen.doel-detail-page.bekijk-reacties' | translate }}
  </parkour-button>

  <parkour-new-indicator [show]="hasOngelezenDoelMeldingen$ | async" />
</div>

<ng-template #modalTemplate>
  <ion-content [scrollY]="false" class="content-child">
    <div class="flex flex-col h-full">
      <div class="relative flex flex-col h-full">
        <div
          [ngClass]="{
            'opacity-100': isBerichtPopoverOpened,
            'opacity-0': !isBerichtPopoverOpened,
          }"
          class="absolute top-0 left-0 w-full h-full z-40 bg-n-200/80 backdrop-blur-sm pointer-events-none transition-opacity"
        ></div>

        <div
          #chatFlowScroll
          class="ion-content-scroll-host flex flex-col flex-1 px-4 overflow-y-auto"
        >
          <ion-infinite-scroll
            (ionInfinite)="onIonInfinite($event)"
            [disabled]="berichtenContainer.isLastPage"
            [threshold]="'0'"
            class="pt-4"
            position="top"
          >
            <div class="infinite-scroll-content">
              <parkour-loading-spinner />
            </div>
          </ion-infinite-scroll>

          <div #chatFlow class="mt-6">
            @if (berichtenContainer.berichten.length) {
              <parkour-berichten
                (rapporteer)="onRapporteer($event)"
                (closedPopover)="onBerichtMessagePopoverClosed()"
                (openedPopover)="onBerichtMessagePopoverOpened()"
                (deleteReactie)="onDeleteReactie($event)"
                (deleted)="onDeleteBericht($event)"
                (selectedReactie)="onSelectedReactie($event)"
                (saveNotitie)="onSaveNotitie($event)"
                (replyBericht)="onReplyBericht($event)"
                [berichten]="berichtenContainer.berichten | berichtenData"
                [gesprekId]="gesprek.id"
                [gesprek]="gesprek"
                [profiel]="profiel"
              />
            } @else {
              <p class="text-center typo-body variant-body-small text-n-600">
                Start het gesprek om het doel te bereiken…
              </p>
            }
          </div>

          <div #chatAnchor></div>
        </div>
      </div>

      <div class="relative">
        <div
          [ngClass]="{
            'opacity-100': isBerichtPopoverOpened,
            'opacity-0': !isBerichtPopoverOpened,
          }"
          class="absolute top-0 left-0 w-full h-full z-40 bg-n-200/80 backdrop-blur-sm pointer-events-none transition-opacity"
        ></div>

        <parkour-bericht-footer
          #berichtFooter
          (closeReplyBericht)="onCloseReplyBericht()"
          (stuurBericht)="onStuurBericht($event)"
          (uploadFile)="onUploadFile($event)"
          [busyUploadingFile]="busyUploadingFile"
          [deelnemerId]="user.profielId"
          [isReplyBerichtMode]="isReplyBerichtMode"
          [replyBericht]="replyBericht"
          [uploadingPercentage]="uploadingPercentage"
          class="block px-4"
        />
      </div>
    </div>
  </ion-content>
</ng-template>
