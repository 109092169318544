<ion-modal (didDismiss)="didDismiss($event)" [isOpen]="isOpen" class="popup">
  <ng-template>
    <div
      class="container-1-col bg-n-200 rounded border-[1.5px] border-n-500 overflow-hidden max-h-[100vh] overflow-y-auto p-4 flex flex-col gap-y-6 my-[calc(var(--ion-safe-area-top)+1rem)]"
    >
      <div class="flex items-start justify-between gap-x-2">
        <div class="flex items-start gap-x-2">
          <parkour-icon
            *ngIf="icon"
            [name]="icon"
            class="sticky top-0 text-n-600"
            size="3"
          />

          <div>
            <ng-content select="[content]"></ng-content>
          </div>
        </div>

        <parkour-button
          (clicked)="handleCloseModal()"
          ariaLabel="sluiten"
          class="sticky top-0"
          format="iconButton"
          icon="cross"
          iconSize="3"
          size="medium"
          variant="ghost"
        />
      </div>

      <div class="empty:hidden">
        <ng-content select="[footer]"></ng-content>
      </div>
    </div>
  </ng-template>
</ion-modal>
