<form (ngSubmit)="onFotoSubmit()" class="flex flex-col gap-y-6">
  <div class="flex items-center flex-col mx-4 mt-8">
    @if (!imageLoading && isImageCropperHidden) {
      <parkour-avatar
        [image]="{ type: 'image', src: getProfielFoto() | secureImage | async }"
        class="block w-[10rem] h-[10rem]"
      />
    }

    @if (!isImageCropperHidden) {
      <p class="typo-body variant-body-fat text-center">{{ 'profiel.crop-image' | translate }}</p>
    }

    @if (imageLoading) {
      <parkour-loading-spinner class="block h-[10rem]" />
    }

    <image-cropper
      (imageCropped)="imageCropped($event)"
      (imageLoaded)="imageLoaded()"
      (loadImageFailed)="loadImageFailed()"
      [aspectRatio]="1 / 1"
      [hidden]="isImageCropperHidden"
      [imageBase64]="imageToCrop"
      [maintainAspectRatio]="true"
      [resizeToWidth]="700"
      [format]="'jpeg'"
      class="rounded mt-6"
    />
  </div>

  <div class="flex items-center gap-4 sm:flex-wrap mx-4">
    @if (!isNativeApp()) {
      <parkour-button
        (clicked)="handleClickOpenImageLibrary()"
        class="w-full"
        iconAfter="camera"
        variant="tertiary"
      >
        {{ 'profiel.upload-image' | translate }}
      </parkour-button>
    } @else {
      <parkour-button
        (clicked)="handleClickTakeSelfie()"
        class="w-full"
        iconAfter="camera"
        variant="tertiary"
      >
        {{ 'profiel.create-selfie' | translate }}
      </parkour-button>

      <parkour-button
        (clicked)="handleClickOpenImageLibrary()"
        class="w-full"
        iconAfter="ai-images"
        variant="tertiary"
      >
        {{ 'profiel.upload-image' | translate }}
      </parkour-button>
    }
  </div>

  @if (!imageToCrop) {
    <div class="flex flex-col gap-y-6 mb-4">
      <span class="typo-h4 mx-4">{{ 'profiel.select-avatar' | translate }}</span>

      <parkour-profiel-avatar-select
        (ngModelChange)="onAvatarChange($event)"
        [formControl]="fotoDetailsFormgroup.controls.avatar"
        [items]="avatars"
      />
    </div>
  }

  <div class="m-4">
    <parkour-button class="w-full" iconAfter="disk" type="submit">
      {{ 'profiel.image-submit-button' | translate }}
    </parkour-button>
  </div>
</form>
