import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { IonBackButton } from '@ionic/angular/standalone';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';

@Directive({
  selector: 'ion-back-button[parkourBackButton]',
  providers: [IonBackButton],
  standalone: true,
})
export class ParkourBackButtonDirective {
  constructor(
    private readonly ionBackButton: IonBackButton,
    private readonly el: ElementRef,
    private readonly renderer: Renderer2,
    private readonly translateService: TranslateService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
  ) {
    this.ionBackButton.icon = '/assets/header/back-button.svg';
    this.ionBackButton.text = '';
    this.translateService
      .get('buttons.vorige')
      .subscribe((label) => this.renderer.setAttribute(this.el.nativeElement, 'aria-label', label));
  }

  @Input({ required: true })
  set parkourBackButton(backRoute: string[] | '') {
    const defaultHref = this.router
      .createUrlTree(backRoute === '' ? ['..'] : backRoute, { relativeTo: this.activatedRoute })
      .toString();
    this.ionBackButton.defaultHref = defaultHref;
  }
}
