import { Pipe, PipeTransform } from '@angular/core';
import { ProfielFotoMode, ProfielFotoService } from '../../profiel/service/profiel-foto.service';
import { Observable } from 'rxjs';
import { ImagesService } from '../services/images.service';
import { ProfielFotoId, ProfielId } from 'parkour-web-app-dto';

@Pipe({
  name: 'profielFoto',
  standalone: true,
})
export class ProfielFotoPipe implements PipeTransform {
  constructor(
    private readonly profielFotoService: ProfielFotoService,
    private readonly imageService: ImagesService,
  ) {}

  transform(
    profiel: { id: ProfielId; profielFotoId: ProfielFotoId },
    mode: ProfielFotoMode = 'thumbnail',
  ): Observable<string> {
    return this.imageService.getImage(
      this.profielFotoService.getProfielFotoUrl(profiel.id, profiel.profielFotoId, mode),
    );
  }
}
