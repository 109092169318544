import { Routes } from '@angular/router';
import { ProfielCreatePage } from './page/profiel-create-page/profiel-create.page';
import { ProfielCreateVoorwaardenPage } from './page/profiel-create-voorwaarden-page/profiel-create-voorwaarden.page';
import { ProfielCreateProfielnaamPage } from './page/profiel-create-profielnaam-page/profiel-create-profielnaam.page';
import { ProfielCreateAboutPage } from './page/profiel-create-about-page/profiel-create-about.page';
import { ProfielCreateSuccesPage } from './page/profiel-create-succes/profiel-create-succes.page';
import { ProfielCreateContactPage } from './page/profiel-create-contact-page/profiel-create-contact.page';
import { ProfielSelecteerPage } from './page/profiel-selecteer-page/profiel-selecteer.page';
import { ProfielAgeRestrictionPage } from './page/profiel-age-restriction-page/profiel-age-restriction.page';

export const startRoutes: Routes = [
  {
    path: 'profiel/nieuw',
    children: [
      {
        path: '',
        component: ProfielCreatePage,
        pathMatch: 'full',
      },
      {
        path: 'voorwaarden',
        component: ProfielCreateVoorwaardenPage,
        title: 'over-parkour.gebruiksvoorwaarden-page.title',
      },
      {
        path: 'profielnaam',
        title: 'start.profiel-create-profielnaam-page.title',
        component: ProfielCreateProfielnaamPage,
      },
      {
        path: 'contact',
        title: 'start.profiel-create-contact-page.title',
        component: ProfielCreateContactPage,
      },
      {
        path: 'jezelf',
        title: 'start.profiel-create-about-page.title',
        component: ProfielCreateAboutPage,
      },
      {
        path: 'succes',
        title: 'start.profiel-succes',
        component: ProfielCreateSuccesPage,
      },
    ],
  },
  {
    path: 'profiel/selecteer',
    component: ProfielSelecteerPage,
  },
  {
    path: 'leeftijdsbeperking',
    component: ProfielAgeRestrictionPage,
  },
];
