import { Injectable } from '@angular/core';
import { Avatar, ProfielFotoId, ProfielId } from 'parkour-web-app-dto';
import { environment } from '../../../environments/environment';

export type ProfielFotoMode = 'thumbnail' | 'full';

@Injectable({
  providedIn: 'root',
})
export class ProfielFotoService {
  private getModeSegment(mode: ProfielFotoMode): string {
    switch (mode) {
      case 'thumbnail':
        return 'thumbnail';
      case 'full':
        return 'foto';
    }
  }

  getProfielFotoUrl(
    profielId: ProfielId,
    profielFotoId: ProfielFotoId,
    mode: ProfielFotoMode = 'thumbnail',
  ): string {
    return `${environment.API_BASE_URL}/api/profiel/${profielId}/${this.getModeSegment(
      mode,
    )}/${profielFotoId}`;
  }

  getAvatarUrl(avatar: Avatar): string {
    return `${environment.API_BASE_URL}/api/profiel/avatar/${avatar}`;
  }
}
