import { Component } from '@angular/core';
import { ParkourActionComponent, ParkourImageComponent } from '@parkour/ui';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  selector: 'parkour-download-apps',
  templateUrl: './download-apps.component.html',
  imports: [ParkourActionComponent, TranslateModule, ParkourImageComponent],
})
export class DownloadAppsComponent {}
