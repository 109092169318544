import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import {
  ActieItemGroup,
  ActieSectionGroup,
  DoelDataSectionGroup,
} from '../component/doel-form/doel-form.component';
import { DoelUpsert } from '../model/doel-upsert';
import { Thema } from 'parkour-web-app-dto';

@Injectable({
  providedIn: 'root',
})
export class DoelFormService {
  constructor(private readonly formBuilder: FormBuilder) {}

  createDoelDataFormGroup(initialState: Partial<DoelUpsert> = {}): DoelDataSectionGroup {
    return this.formBuilder.nonNullable.group({
      naam: this.formBuilder.nonNullable.control(initialState.naam ?? '', [
        Validators.required,
        Validators.maxLength(100),
      ]),
      omschrijving: this.formBuilder.nonNullable.control(initialState.omschrijving ?? ''),
      thema: this.formBuilder.nonNullable.control<Thema | ''>(initialState.thema ?? '', [
        Validators.required,
      ]),
      streefDatum: this.formBuilder.nonNullable.control(
        initialState.streefDatum?.toISOString().split('T')[0] ?? '',
      ),
    });
  }

  createActieSectionFormGroup(initialState: Partial<DoelUpsert> = {}): ActieSectionGroup {
    return this.formBuilder.nonNullable.group({
      acties: this.formBuilder.array<ActieItemGroup>(
        initialState.acties?.map((actie) => {
          return this.formBuilder.nonNullable.group({
            naam: [actie.naam, Validators.required],
            voltooid: [actie.voltooid],
            order: [actie.order],
          });
        }) ?? [],
      ),
    });
  }
}
