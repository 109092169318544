import { Component, Input } from "@angular/core";
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from "@angular/forms";
import { NgClass } from "@angular/common";
import { ParkourFocusVisibleModule } from "../parkour-focus-visible/parkour-focus-visible.module";

@Component({
  selector: "parkour-textarea",
  templateUrl: "./parkour-textarea.component.html",
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ParkourTextareaComponent,
    },
  ],
  imports: [NgClass, ReactiveFormsModule, ParkourFocusVisibleModule],
})
export class ParkourTextareaComponent implements ControlValueAccessor {
  @Input() public classes?: string;
  @Input({ required: true }) public inputId!: string;
  @Input() public testId?: string;
  @Input() public error?: string;
  @Input() public inputPlaceholder: string = "";
  @Input() public disabled = false;
  @Input() public required = false;
  @Input() public formControl: FormControl<string | undefined> | null = null;

  public isFocus!: boolean;
  public value: FormControl = new FormControl(null);
  public onChange?: (value: string | number) => void;
  public onTouched?: () => void;

  onFocus() {
    this.isFocus = true;
  }

  onBlur() {
    this.isFocus = false;
  }

  onInputChange(event: Event) {
    if (this.onChange) {
      this.onChange((event.target as HTMLInputElement).value);
    }
  }

  writeValue(value: string | number): void {
    this.value.setValue(value, { emitEvent: false });
  }

  registerOnChange(onChange: () => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }
}
