import { AbstractGesprek } from './abstractGesprek';
import { GesprekId, GesprekStatus } from 'parkour-web-app-dto';

export class OpenGesprek extends AbstractGesprek {
  constructor(
    id: GesprekId,
    aangemaaktOp: string,
    status: GesprekStatus,
    public readonly channel: string,
  ) {
    super(id, aangemaaktOp, status);
  }

  override isGroepsgesprek(): boolean {
    return true;
  }
}
