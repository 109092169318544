<svg
  [attr.aria-label]="'Percentage: ' + getPercentage() + '%'"
  class="block rotate-[270deg]"
  height="24px"
  viewBox="0 0 24 24"
  width="24px"
  xmlns="http://www.w3.org/2000/svg"
>
  <defs>
    <linearGradient gradientTransform="rotate(90)" id="stroke-gradient">
      <stop offset="0" stop-color="hsla(96, 96%, 78%)" />

      <stop offset="1" stop-color="hsla(96, 96%, 78%)" stop-opacity="0.5" />
    </linearGradient>
  </defs>

  <circle
    class="stroke-positive/10"
    cx="12px"
    cy="12px"
    fill="transparent"
    r="8px"
    stroke-width="4px"
  ></circle>

  <circle
    [attr.stroke-dasharray]="percentageToStrokeDasharray(getPercentage(), 8)"
    cx="12px"
    cy="12px"
    fill="transparent"
    r="8px"
    stroke="url(#stroke-gradient)"
    stroke-width="4px"
  ></circle>
</svg>
