<ion-header class="w-full transform-none"></ion-header>

<ion-content [scrollY]="false">
  <parkour-page-layout>
    <ng-container *ngIf="uitnodiging$ | async as uitnodiging">
      <div class="flex flex-col py-8 h-[100%]">
        <div class="mb-4 container container-space">
          <div class="container-1-col">
            <h1 class="text-center">{{ 'start.uitgenodigd.welkom' | translate }}</h1>

            <parkour-divider class="inline-block w-full mt-4" />
          </div>
        </div>

        <div class="flex-1 grid grid-cols-[auto] grid-rows-[1fr_10%_auto]">
          <div class="relative z-0 col-start-1 col-end-2 row-start-1 row-end-4">
            <parkour-picture
              [rounded]="true"
              [sources]="{
                default: {
                  src: '/assets/onboarding/background/default/background_onboarding_default.webp',
                  fallback:
                    '/assets/onboarding/background/default/background_onboarding_default.png',
                },
                md: {
                  src: '/assets/onboarding/background/md/background_onboarding_md.webp',
                  fallback: '/assets/onboarding/background/md/background_onboarding_md.png',
                },
              }"
              alt=""
              class="absolute h-full w-[110%] left-[-5%] blur-[29px]"
              fetchPriority="high"
              fit="contain"
              position="center"
            />

            <parkour-picture
              [rounded]="true"
              [sources]="{
                default: {
                  src: '/assets/onboarding/background/default/background_onboarding_default.webp',
                  fallback:
                    '/assets/onboarding/background/default/background_onboarding_default.jpg',
                },
                md: {
                  src: '/assets/onboarding/background/md/background_onboarding_md.webp',
                  fallback: '/assets/onboarding/background/md/background_onboarding_md.jpg',
                },
              }"
              alt=""
              class="absolute h-full w-[110%] left-[-5%]"
              fetchPriority="high"
              fit="contain"
              position="center"
            />
          </div>

          <div
            class="z-10 col-start-1 col-end-2 row-start-2 row-end-4 bg-gradient-to-b from-transparent to-n-100 to-80%"
            role="presentation"
          ></div>

          <div class="z-10 col-start-1 col-end-2 row-start-3 row-end-4 container container-space">
            <div class="container-1-col">
              <p class="text-center typo-h2">
                {{ 'start.uitgenodigd.begroeting' | translate: { naam: uitnodiging.naam } }}
              </p>

              <p class="text-center">
                {{
                  'start.uitgenodigd.begroeting-uitleg' | translate: { naam: uitnodiging.initiator }
                }}
              </p>
            </div>
          </div>
        </div>

        <div class="z-10 pt-6 container container-space bg-n-100">
          <div class="flex flex-col container-1-col">
            <ng-container *ngIf="user$ | async as user">
              @if (user.type !== 'aangemeld') {
                <parkour-button (clicked)="onAanmeldenClick(uitnodiging.id)" class="block w-full">
                  {{ 'buttons.aanmelden' | translate }}
                </parkour-button>
              } @else {
                <parkour-button
                  (clicked)="onBekijkUitnodiging(uitnodiging.id, user)"
                  class="block w-full"
                >
                  {{ 'start.uitgenodigd.bekijk-uitnodiging' | translate }}
                </parkour-button>
              }
            </ng-container>

            <parkour-button
              (clicked)="onWeigerenClick(uitnodiging.id)"
              class="block w-full mt-3"
              variant="tertiary"
            >
              {{ 'start.uitgenodigd.uitnodiging-weigeren' | translate }}
            </parkour-button>

            <parkour-button
              [link]="['over', 'parkour']"
              class="self-center block mt-6"
              iconAfter="chevron-right"
              variant="ghost"
            >
              {{ 'start.uitgenodigd.over-parkour' | translate }}
            </parkour-button>
          </div>
        </div>
      </div>
    </ng-container>
  </parkour-page-layout>
</ion-content>
