import { Component, OnInit } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { IonContent, IonHeader } from '@ionic/angular/standalone';
import { PageLayoutComponent } from '../../../shared/components/page-layout/page-layout.component';
import {
  ParkourButtonComponent,
  ParkourDividerComponent,
  ParkourPictureComponent,
} from '@parkour/ui';
import { TranslateModule } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  standalone: true,
  templateUrl: './profiel-age-restriction.page.html',
  imports: [
    AsyncPipe,
    PageLayoutComponent,
    IonContent,
    ParkourDividerComponent,
    ParkourPictureComponent,
    ParkourButtonComponent,
    IonHeader,
    TranslateModule,
  ],
})
export class ProfielAgeRestrictionPage implements OnInit {
  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
  ) {}

  name!: string;

  ngOnInit() {
    this.name = this.activatedRoute.snapshot.queryParams['name'];
  }

  goHome() {
    this.router.navigate(['app', 'anoniem', 'home']);
  }
}
