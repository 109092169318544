import {
  combineLatest,
  defaultIfEmpty,
  from,
  map,
  mergeMap,
  Observable,
  switchMap,
  take,
} from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { inject, Injectable } from '@angular/core';
import {
  Bericht,
  Gesprek,
  GesprekUpdate,
  GroepsgesprekInsert,
  JongereDeelnemer,
  TeamlidDeelnemer,
} from '../model/gesprek';
import {
  BerichtDto,
  BerichtenPage,
  BerichtId,
  BestandId,
  BestandMetadataDto,
  BestandType,
  CreateBerichtDto,
  CreateReactieBerichtDto,
  FotoId,
  GedeeldeResourceType,
  GesprekDto,
  GesprekId,
  GesprekImageVariant,
  GesprekUpdateDto,
  GroepsgesprekInsertDto,
  ProfielId,
} from 'parkour-web-app-dto';
import { ContextService } from '../../shared/services/context.service';
import { asType } from '../../utils';
import { TeamService } from '../../team/service/team.service';
import { JongereContext, TeamlidContext } from '../../shared/model/context';
import { Groepsgesprek } from '../model/groepsgesprek';
import { EenOpEenGesprek } from '../model/eenOpEenGesprek';
import { OpenGesprek } from '../model/OpenGesprek';
import { CapacitorHttp } from '@capacitor/core';
import { JongereProfiel, MijnProfiel } from '../../profiel/model/profiel';
import { ProfielService } from '../../profiel/service/profiel.service';
import AuthService from '../../authentication/service/auth.service';
import { AnalyticsService } from '../../analytics/analytics.service';
import {
  AnalyticsEvent,
  EventCategory,
  trackAnalyticsEvent,
} from '../../analytics/analytics-event.model';

export type BerichtUploadEvent =
  | {
      type: 'progress';
      number: number;
    }
  | {
      type: 'response';
      number: number;
      bericht: Bericht;
    };

export type CreateTekstBericht = {
  readonly type: 'TEKST';
  readonly inhoud: string;
  readonly deelnemerId: ProfielId;
};

export type CreateGedeeldeResourceBericht = {
  readonly type: 'GEDEELDE_RESOURCE';
  readonly resourceId: string;
  readonly resourceType: GedeeldeResourceType;
  readonly deelnemerId: ProfielId;
  readonly origineelBerichtId?: BerichtId;
};

export type CreateBestandBericht = {
  readonly type: BestandType;
  readonly deelnemerId: ProfielId;
  readonly bestand: File;
};

export type CreateBericht =
  | CreateTekstBericht
  | CreateGedeeldeResourceBericht
  | CreateBestandBericht;

@Injectable({
  providedIn: 'root',
})
export class BerichtenService {
  private readonly http = inject(HttpClient);
  private readonly contextService = inject(ContextService);
  private readonly teamService = inject(TeamService);
  private readonly authService = inject(AuthService);
  private readonly analyticsService = inject(AnalyticsService);
  private readonly profielService = inject(ProfielService);

  getGesprekken(): Observable<Gesprek[]> {
    return combineLatest([
      this.contextService.contextWithActiveJongere$(),
      this.authService.getAangemeldeUser$(),
    ]).pipe(
      take(1),
      mergeMap(([context, user]) => {
        let ownerId;
        let externeId;

        switch (context.type) {
          case 'jongere':
            ownerId = context.contextId;
            externeId = context.contextId;
            break;
          case 'teamlid':
            ownerId = context.contextId;
            externeId = user.profielId;
            break;
        }

        return this.http
          .get<Array<GesprekDto>>(
            `${environment.API_BASE_URL}/api/jongere/${context.contextId}/gesprekken`,
            {
              params: {
                ownerId,
                externeId,
              },
            },
          )
          .pipe(
            mergeMap((gesprekken) =>
              combineLatest(gesprekken.map((gesprek) => this.enrichGesprekDto(context, gesprek))),
            ),
            map((gesprekken) => [
              ...gesprekken.sort((a, b) =>
                (a.laatsteBericht?.timestamp ?? a.aangemaaktOp) <
                (b.laatsteBericht?.timestamp ?? b.aangemaaktOp)
                  ? 1
                  : -1,
              ),
            ]),
            defaultIfEmpty([]),
          );
      }),
    );
  }

  getGesprekPartners(jongereId: ProfielId, gesprek: GesprekDto): Observable<TeamlidDeelnemer[]> {
    const partners = gesprek.deelnemers.filter((deelnemer) => deelnemer.id !== jongereId);

    return combineLatest(
      partners.map((partner) =>
        this.teamService.getTeamlidInCurrentContext(partner.id).pipe(
          map((teamlid) =>
            asType<TeamlidDeelnemer>({
              ...teamlid,
              deelnemerId: partner.id,
              channel: partner.channel,
            }),
          ),
        ),
      ),
    ).pipe(defaultIfEmpty([]));
  }

  getBerichten(gesprekId: GesprekId, pageNumber: number): Observable<BerichtenPage> {
    return this.contextService
      .contextWithJongere$()
      .pipe(
        switchMap((context) =>
          this.http.get<BerichtenPage>(
            `${environment.API_BASE_URL}/api/jongere/${context.contextId}/gesprekken/${gesprekId}/berichten`,
            { params: { pageNumber } },
          ),
        ),
      );
  }

  getBlobString(gesprekId: GesprekId, berichtId: BerichtId, bestandId: string): Observable<string> {
    return this.contextService
      .contextWithJongere$()
      .pipe(
        switchMap((context) =>
          from(
            CapacitorHttp.get({
              url: `${environment.API_BASE_URL}/api/jongere/${context.contextId}/gesprekken/${gesprekId}/berichten/${berichtId}/bestand/${bestandId}`,
              responseType: 'blob',
            }),
          ),
        ),
      )
      .pipe(
        map((response) => {
          return response.data;
        }),
      );
  }

  getBericht(gesprekId: GesprekId, berichtId: BerichtId): Observable<BerichtDto> {
    return this.contextService
      .contextWithJongere$()
      .pipe(
        switchMap((context) =>
          this.http.get<BerichtDto>(
            `${environment.API_BASE_URL}/api/jongere/${context.contextId}/gesprekken/${gesprekId}/berichten/${berichtId}`,
          ),
        ),
      );
  }

  deleteBericht(gesprekId: GesprekId, berichtId: BerichtId): Observable<void> {
    return this.contextService.contextWithJongere$().pipe(
      switchMap((context) =>
        this.http.delete<void>(
          `${environment.API_BASE_URL}/api/jongere/${context.contextId}/gesprekken/${gesprekId}/berichten/${berichtId}`,
        ),
      ),
      trackAnalyticsEvent(
        this.analyticsService,
        new AnalyticsEvent('berichten', 'berichtVerwijderd'),
      ),
    );
  }

  updateGesprek(gesprekId: GesprekId, gesprekUpdate: GesprekUpdate): Observable<void> {
    return this.contextService
      .contextWithJongere$()
      .pipe(
        switchMap((context) =>
          this.http.put<void>(
            `${environment.API_BASE_URL}/api/jongere/${context.contextId}/gesprekken/${gesprekId}`,
            asType<GesprekUpdateDto>(gesprekUpdate),
          ),
        ),
      );
  }

  getBerichtenPage(gesprekId: GesprekId): Observable<BerichtenPage> {
    const params = new HttpParams().set('pageNumber', 0);

    return this.contextService
      .contextWithJongere$()
      .pipe(
        switchMap((context) =>
          this.http.get<BerichtenPage>(
            `${environment.API_BASE_URL}/api/jongere/${context.contextId}/gesprekken/${gesprekId}/berichten`,
            { params },
          ),
        ),
      );
  }

  getBerichtenPageSinceTime(gesprekId: GesprekId, sinceTime: string): Observable<BerichtenPage> {
    const params = new HttpParams().set('sinceTime', encodeURIComponent(sinceTime));
    return this.contextService
      .contextWithJongere$()
      .pipe(
        switchMap((context) =>
          this.http.get<BerichtenPage>(
            `${environment.API_BASE_URL}/api/jongere/${context.contextId}/gesprekken/${gesprekId}/berichten`,
            { params },
          ),
        ),
      );
  }

  getBerichtenPageBeforeTime(gesprekId: GesprekId, beforeTime: string): Observable<BerichtenPage> {
    const params = new HttpParams().set('beforeTime', encodeURIComponent(beforeTime));

    return this.contextService
      .contextWithJongere$()
      .pipe(
        switchMap((context) =>
          this.http.get<BerichtenPage>(
            `${environment.API_BASE_URL}/api/jongere/${context.contextId}/gesprekken/${gesprekId}/berichten`,
            { params },
          ),
        ),
      );
  }

  stuurBericht(
    gesprekId: GesprekId,
    createBerichtDto: CreateBerichtDto,
    source: 'doel' | 'chat',
  ): Observable<BerichtDto> {
    return this.contextService.contextWithJongere$().pipe(
      switchMap((context) =>
        this.http.post<BerichtDto>(
          `${environment.API_BASE_URL}/api/jongere/${context.contextId}/gesprekken/${gesprekId}/berichten`,
          createBerichtDto,
        ),
      ),
      trackAnalyticsEvent(this.analyticsService, () => {
        const category: EventCategory = source === 'chat' ? 'berichten' : 'doelen';
        if (createBerichtDto.type === 'GEDEELDE_RESOURCE') {
          return new AnalyticsEvent(category, 'artikelGedeeld');
        } else {
          return new AnalyticsEvent(category, 'tekstBerichtVerstuurd');
        }
      }),
    );
  }

  addReactieBericht(
    gesprekId: GesprekId,
    berichtId: BerichtId,
    createReactieBerichtDto: CreateReactieBerichtDto,
    source: 'doel' | 'chat',
  ): Observable<Bericht> {
    return this.contextService.contextWithJongere$().pipe(
      switchMap((context) =>
        this.http.put<Bericht>(
          `${environment.API_BASE_URL}/api/jongere/${context.contextId}/gesprekken/${gesprekId}/berichten/${berichtId}/reacties`,
          createReactieBerichtDto,
        ),
      ),
      trackAnalyticsEvent(this.analyticsService, () => {
        const category: EventCategory = source === 'chat' ? 'berichten' : 'doelen';
        return new AnalyticsEvent(category, 'berichtReactieToegevoegd');
      }),
    );
  }

  deleteReactieBericht(gesprekId: GesprekId, berichtId: BerichtId): Observable<Bericht> {
    return this.contextService.contextWithJongere$().pipe(
      switchMap((context) => {
        return this.http.delete<Bericht>(
          `${environment.API_BASE_URL}/api/jongere/${context.contextId}/gesprekken/${gesprekId}/berichten/${berichtId}/reacties`,
        );
      }),
      trackAnalyticsEvent(
        this.analyticsService,
        new AnalyticsEvent('berichten', 'berichtReactieVerwijderd'),
      ),
    );
  }

  getGesprek(gesprekId: GesprekId): Observable<Gesprek> {
    return combineLatest([
      this.getGesprekDto(gesprekId),
      this.contextService.contextWithActiveJongere$(),
    ]).pipe(
      switchMap(([gesprekDto, context]) => {
        return this.enrichGesprekDto(context, gesprekDto);
      }),
    );
  }

  getBestandMetadata(
    gesprekId: GesprekId,
    berichtId: BerichtId,
    bestandId: BestandId,
  ): Observable<BestandMetadataDto> {
    return this.contextService
      .contextWithJongere$()
      .pipe(
        switchMap((context) =>
          this.http.get<BestandMetadataDto>(
            `${environment.API_BASE_URL}/api/jongere/${context.contextId}/gesprekken/${gesprekId}/berichten/${berichtId}/bestand/${bestandId}/meta`,
          ),
        ),
      );
  }

  addGroepsgesprek(groepsgesprek: GroepsgesprekInsert): Observable<GesprekId> {
    return this.contextService.contextWithJongere$().pipe(
      switchMap((context) =>
        this.http.post<GesprekDto>(
          `${environment.API_BASE_URL}/api/jongere/${context.contextId}/gesprekken`,
          asType<GroepsgesprekInsertDto>(groepsgesprek),
        ),
      ),
      map((gesprek) => gesprek.id),
      trackAnalyticsEvent(
        this.analyticsService,
        new AnalyticsEvent('berichten', 'groepsgesprekAangemaakt'),
      ),
    );
  }

  archiveerGesprek(gesprekId: GesprekId): Observable<Gesprek> {
    return this.contextService.contextWithJongere$().pipe(
      switchMap((context) =>
        this.http.put<Gesprek>(
          `${environment.API_BASE_URL}/api/jongere/${context.contextId}/gesprekken/${gesprekId}/archiveer`,
          {},
        ),
      ),
      trackAnalyticsEvent(
        this.analyticsService,
        new AnalyticsEvent('berichten', 'gesprekGearchiveerd'),
      ),
    );
  }

  verlaatGesprek(gesprekId: GesprekId): Observable<Gesprek> {
    return this.contextService.contextWithJongere$().pipe(
      switchMap((context) =>
        this.http.put<Gesprek>(
          `${environment.API_BASE_URL}/api/jongere/${context.contextId}/gesprekken/${gesprekId}/verlaat`,
          {},
        ),
      ),
      trackAnalyticsEvent(
        this.analyticsService,
        new AnalyticsEvent('berichten', 'groepsgesprekVerlaten'),
      ),
    );
  }

  getDownloadLink(
    gesprekId: GesprekId,
    berichtId: BerichtId,
    bestandId: string,
  ): Observable<string> {
    return this.contextService.contextWithJongere$().pipe(
      map((context) => {
        return `${environment.API_BASE_URL}/api/jongere/${context.contextId}/gesprekken/${gesprekId}/berichten/${berichtId}/bestand/${bestandId}`;
      }),
    );
  }

  getGesprekOfDoel(doelId: string): Observable<OpenGesprek> {
    return this.http.get<GesprekDto>(`${environment.API_BASE_URL}/api/gesprek/doel/${doelId}`).pipe(
      map((gesprekDto) => {
        if (gesprekDto.type !== 'DOEL') {
          throw new Error('Het is niet mogelijk om dit gesprek te gebruiken.');
        }
        return new OpenGesprek(
          gesprekDto.id,
          gesprekDto.aangemaaktOp,
          gesprekDto.status,
          gesprekDto.channel ?? '',
        );
      }),
    );
  }

  getGesprekFotoUrl(
    gesprekId: GesprekId,
    gesprekFotoId: FotoId,
    gesprekImageVariant: GesprekImageVariant,
  ): Observable<string> {
    return this.contextService
      .contextWithJongere$()
      .pipe(
        map(
          (context) =>
            `${environment.API_BASE_URL}/api/jongere/${context.contextId}/gesprekken/${gesprekId}/foto/${gesprekFotoId}?variant=${gesprekImageVariant}`,
        ),
      );
  }

  createVideogesprek(gesprekId: GesprekId): Observable<Gesprek> {
    return this.contextService
      .contextWithActiveJongere$()
      .pipe(
        switchMap((context) =>
          this.http
            .post<GesprekDto>(
              `${environment.API_BASE_URL}/api/jongere/${context.contextId}/gesprekken/${gesprekId}/videogesprek`,
              {},
            )
            .pipe(switchMap((gesprekDto) => this.enrichGesprekDto(context, gesprekDto))),
        ),
      );
  }

  private getGesprekDto(gesprekId: GesprekId) {
    return this.contextService
      .contextWithJongere$()
      .pipe(
        switchMap((context) =>
          this.http.get<GesprekDto>(
            `${environment.API_BASE_URL}/api/jongere/${context.contextId}/gesprekken/${gesprekId}`,
          ),
        ),
      );
  }

  private enrichGesprekDto(
    context: JongereContext | TeamlidContext,
    gesprekDto: GesprekDto,
  ): Observable<Gesprek> {
    return this.profielService.getCurrentUserProfiel$().pipe(
      switchMap((profiel) => {
        const jongere = this.getJongere(context, profiel);

        const jongereDeelnemer = gesprekDto.deelnemers.find(
          (deelnemer) => deelnemer.id === jongere.id,
        );

        if (!jongereDeelnemer) {
          throw new Error('Jongere is geen deelnemer van gesprek');
        }

        return this.getGesprekPartners(jongere.id, gesprekDto).pipe(
          map((partners) => {
            if (gesprekDto.type === 'GROEPSGESPREK') {
              return new Groepsgesprek(
                gesprekDto.id,
                partners,
                asType<JongereDeelnemer>({
                  ...jongere,
                  deelnemerId: jongereDeelnemer.id,
                  channel: jongereDeelnemer.channel,
                }),
                gesprekDto.aangemaaktOp,
                gesprekDto.status,
                gesprekDto.laatsteBericht,
                gesprekDto.fotoId,
              );
            } else {
              return new EenOpEenGesprek(
                gesprekDto.id,
                partners,
                asType<JongereDeelnemer>({
                  ...jongere,
                  deelnemerId: jongereDeelnemer.id,
                  channel: jongereDeelnemer.channel,
                }),
                gesprekDto.aangemaaktOp,
                gesprekDto.status,
                gesprekDto.laatsteBericht,
                gesprekDto.videogesprekId,
              );
            }
          }),
        );
      }),
    );
  }

  private getJongere(
    currentContext: JongereContext | TeamlidContext,
    userProfiel: MijnProfiel,
  ): JongereProfiel {
    switch (currentContext.type) {
      case 'jongere':
        return { viewType: 'JONGERE', ...userProfiel };
      case 'teamlid':
        return currentContext.jongereProfiel;
    }
  }
}
