<article class="clickable-card-200 flex items-center gap-3 p-4 rounded">
  @switch (meldingView.visualization.type) {
    @case ('profiel') {
      <parkour-profiel-foto-badge
        [profiel]="meldingView.visualization.profiel"
        [alt]="false"
        [size]="'3'"
      />
    }
    @case ('icon') {
      <parkour-avatar-badge
        class="self-start aspect-square w-[3rem] rounded overflow-hidden"
        [image]="getMeldingIcon(meldingView.visualization.icon)"
      />
    }
    @case ('default') {
      <parkour-badge [theme]="'n-100'" [icon]="'people-3'" [width]="'w-[3rem]'" />
    }
    @case ('error') {
      <parkour-badge [theme]="'n-100'" [icon]="'broken-link'" [width]="'w-[3rem]'" />
    }
  }

  <div class="text-left flex-1">
    <p class="text-n-1000">
      <parkour-action
        (clicked)="onMeldingClicked()"
        classes="before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:z-10 text-left focus-visible:outline-none"
      >
        @if (meldingView.visualization.type === 'error') {
          <span>{{ 'profiel.meldingen-page.error-melding' | translate }}</span>
        } @else if (meldingView.visualization.type === 'unsupported') {
          <span>{{ 'profiel.meldingen-page.unsupported-melding' | translate }}</span>
        } @else {
          <div [innerHTML]="meldingView.visualization.berichtRichText"></div>
        }
      </parkour-action>
    </p>

    <div class="flex items-center gap-2">
      @if (!meldingView.gelezen && meldingView.visualization.type !== 'unsupported') {
        <div class="w-[0.375rem] h-[0.375rem] rounded-[100vmax] bg-notification"></div>
      }

      <p class="text-n-600 mt-1">{{ meldingView.timestamp | date: 'd MMMM HH:mm' }}</p>
    </div>
  </div>

  <div>
    <parkour-icon class="text-n-600" name="chevron-right" size="3" />
  </div>
</article>
