import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { getUitnodigingTypeLabel } from 'parkour-web-app-dto';
import {
  ParkourFormFieldWithoutLabelComponent,
  ParkourInputComponent,
  ParkourRadioComponent,
} from '@parkour/ui';
import { NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonRadioGroup,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';

export type ContactFormGroup = FormGroup<{
  type: FormControl<'EMAIL' | 'GSM'>;
  email: FormControl<string | undefined>;
  telefoonnummer: FormControl<string | undefined>;
}>;

export
@Component({
  standalone: true,
  selector: 'parkour-profile-details-contact-edit',
  templateUrl: './profile-details-contact-edit.component.html',
  imports: [
    ReactiveFormsModule,
    ParkourRadioComponent,
    ParkourFormFieldWithoutLabelComponent,
    ParkourInputComponent,
    NgIf,
    TranslateModule,
    IonBackButton,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonTitle,
    IonContent,
    IonRadioGroup,
  ],
})
class ProfileDetailsContactEditComponent {
  @Input({ required: true }) contactFormGroup!: ContactFormGroup;

  constructor() {}

  protected readonly getUitnodigingTypeLabel = getUitnodigingTypeLabel;

  getEmailErrors() {
    return {
      ...this.contactFormGroup.errors,
      ...this.contactFormGroup.controls.email.errors,
    };
  }

  getTelefoonnummerErrors() {
    return {
      ...this.contactFormGroup.errors,
      ...this.contactFormGroup.controls.telefoonnummer.errors,
    };
  }
}
