@if (context$ | async; as context) {
  @if (context.type === 'jongere' || context.type === 'teamlid') {
    <parkour-button (clicked)="openModal()" iconAfter="share" size="small" variant="tertiary">
      {{ 'buttons.share' | translate }}
    </parkour-button>

    <parkour-modal [title]="'watwat.share-title' | translate" icon="share">
      <div class="flex flex-col gap-y-8" content>
        <div class="block border-[1px] border-solid border-n-500 rounded">
          @if (artikel) {
            <parkour-article-card
              [image]="{ src: artikel.image, alt: '' }"
              [isBookmarkable]="false"
              [themes]="artikel.themas"
              [title]="artikel.title"
              titleTag="span"
            />
          }

          @if (organisation) {
            <parkour-helpline-card-item
              [organisation]="organisation"
              [isBookmarkable]="false"
              [segmentsToHulplijn]="false"
              titleTag="span"
            />
          }
        </div>

        <div class="flex flex-col gap-y-6">
          <p class="typo-h4">{{ 'watwat.share-subtitle' | translate }}</p>

          <ng-container *parkourSectionContent="{ data: data$ | async }; let data">
            @if (data.gesprekken.length) {
              <ul class="space-y-6">
                @for (gesprek of data.gesprekken; track gesprek) {
                  @if (gesprek.isActief()) {
                    <li>
                      <parkour-gesprek-overview-card
                        [gesprek]="gesprek"
                        [isStaticCard]="true"
                        [user]="data.user"
                      >
                        @if (gesprek.isGroepsgesprek()) {
                          <parkour-button
                            (clicked)="onShare(gesprek, data.user)"
                            [ariaLabel]="
                              'watwat.send-groepsgesprek'
                                | translate
                                  : {
                                      gesprekNaam: gesprek.getGesprekNaam(data.user),
                                    }
                            "
                            format="iconButton"
                            icon="paper-plane"
                            iconSize="3"
                          />
                        } @else {
                          <parkour-button
                            (clicked)="onShare(gesprek, data.user)"
                            [ariaLabel]="
                              'watwat.send-individueel'
                                | translate
                                  : {
                                      gesprekNaam: gesprek.getGesprekNaam(data.user),
                                    }
                            "
                            format="iconButton"
                            icon="paper-plane"
                            iconSize="3"
                          />
                        }
                      </parkour-gesprek-overview-card>
                    </li>
                  }
                }
              </ul>
            } @else {
              <p>{{ 'watwat.share-empty' | translate }}</p>
            }
          </ng-container>
        </div>
      </div>
    </parkour-modal>
  }
}
