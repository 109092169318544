<div class="flex items-center gap-x-3 bg-n-300 rounded-tl rounded-tr p-3">
  <parkour-icon name="reply" size="3" />

  <div class="flex-1 w-full grid overflow-hidden">
    @if (replyBericht.deelnemerId | profiel | async; as profiel) {
      <span class="typo-body variant-body-small variant-body-fat">{{ profiel | profielnaam }}</span>
    }

    @switch (replyBericht.type) {
      @case ('TEKST') {
        <span
          [innerHTML]="replyBericht.inhoud | linkify"
          class="typo-body text-n-1000 wysiwyg truncate"
        ></span>
      }
      @case ('ATTACHMENT') {
        <span class="typo-body text-n-1000 wysiwyg">
          {{ 'berichten.gesprek-page.attachement' | translate }}
        </span>
      }
      @case ('SPRAAK') {
        <span class="typo-body text-n-1000 wysiwyg">
          {{ 'berichten.gesprek-page.voice-message' | translate }}
        </span>
      }
    }
  </div>

  <parkour-button
    (clicked)="onCloseReplyBericht()"
    [ariaLabel]="'buttons.delete-reply-message' | translate"
    class="flex"
    format="iconButton"
    icon="cross"
    iconSize="3"
    variant="ghost"
  />
</div>
