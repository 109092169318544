<parkour-hero
  [fullScreen]="true"
  [image]="{
    sources: {
      lg: {
        src: '/assets/about/background/lg/background_about_lg.webp',
        fallback: '/assets/about/background/lg/background_about_lg.jpg',
      },
      md: {
        src: '/assets/about/background/md/background_about_md.webp',
        fallback: '/assets/about/background/md/background_about_md.jpg',
      },
      default: {
        src: '/assets/about/background/default/background_about_default.webp',
        fallback: '/assets/about/background/default/background_about_default.jpg',
      },
    },
  }"
>
  <div class="container-1-col max-lg:container-space flex flex-col gap-y-10">
    <div class="wysiwyg text-center px-4">
      <h2 class="typo-h3">Voor jongeren en hun team</h2>

      <p>
        PARKOUR is het Opgroeien-platform voor jongeren tussen 13 en 25 jaar. Ze vinden er makkelijk
        informatie terug over zichzelf en over de thema&rsquo;s die hen bezighouden.
      </p>

      <p>
        In PARKOUR schrijven jongeren hun eigen verhaal en formuleren ze zelf doelen en acties. Ze
        kunnen deze delen met het team dat ze zelf samenstellen. Met die teamleden kunnen ze ook
        veilig chatten en beeldbellen.
      </p>
    </div>

    <parkour-button
      [link]="['over', 'waarom-parkour']"
      class="self-center"
      iconAfter="chevron-right"
      variant="ghost"
    >
      Waarom PARKOUR
    </parkour-button>
  </div>
</parkour-hero>
