import {Rol} from './persoon.dto';
import {ProfielId} from './profiel.dto';
import {ApiProperty} from '@nestjs/swagger';
import {ActieveJongereDto} from './jongere-profiel.dto';

export class AnoniemeContextDto {
    readonly contextId!: 'anoniem';
    readonly type!: 'anoniem';
}

export class VoorMezelfContextDto {
    readonly type!: 'voor-mezelf';
    @ApiProperty({type: String})
    readonly contextId!: ProfielId;
}

export class TeamlidContextDto {
    readonly type!: 'teamlid';
    @ApiProperty({type: String})
    readonly contextId!: ProfielId;
    readonly jongereProfiel!: ActieveJongereDto;
    @ApiProperty({type: String})
    readonly rol!: Rol;
    readonly geblokkeerd!: boolean;
}

export class JongereContextDto {
    readonly type!: 'jongere';
    @ApiProperty({type: String})
    readonly contextId!: ProfielId;
}

export class ExJongereContextDto {
    readonly type!: 'ex-jongere';
    @ApiProperty({type: String})
    readonly contextId!: ProfielId;
}

export type ContextDto =
    | AnoniemeContextDto
    | VoorMezelfContextDto
    | TeamlidContextDto
    | JongereContextDto
    | ExJongereContextDto;
