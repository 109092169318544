import { Pipe, PipeTransform } from '@angular/core';
import { ContextService } from '../services/context.service';
import { Observable } from 'rxjs';

@Pipe({
  standalone: true,
  name: 'absoluteRoute',
})
export class AbsoluteRoutePipe implements PipeTransform {
  constructor(private readonly contextService: ContextService) {}

  transform(segments: string[]): Observable<string[]> {
    return this.contextService.getAbsoluteUrl(segments);
  }
}
