<ion-header parkourAdaptToSidebarVisible>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button parkourBackButton />
    </ion-buttons>

    <ion-title>{{ 'over-parkour.privacy-verklaring-page.title' | translate }} </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-refresher [parkourRefresh]="onPageRefresh" slot="fixed">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>
  <parkour-page-layout>
    <parkour-page-gradient />

    <div class="relative z-10 py-8 container container-space">
      <div class="container-1-col">
        <h1 class="text-center mb-4">
          {{ 'over-parkour.privacy-verklaring-page.title' | translate }}
        </h1>

        <parkour-divider></parkour-divider>

        <article class="wysiwyg space-y-8">
          <section class="mt-8 space-y-3">
            <h2 class="typo-h4">Samenvatting van de Privacyverklaring</h2>

            <p>
              Wij dragen jouw privacy hoog in het vaandel. Daarom hebben we speciaal voor jou een
              samenvatting van onze privacyverklaring gemaakt. Deze samenvatting kan je in minder
              dan 2 minuten lezen en informeert je in een mum van tijd over hoe jouw
              persoonsgegevens worden verwerkt in het kader van het PARKOUR-pilootproject. Als je
              meer wilt weten, nodigen we je uit om de volledige privacyverklaring te lezen.
            </p>
          </section>

          <section class="space-y-3">
            <h2 class="typo-h4">Wie is de verwerkingsverantwoordelijke?</h2>

            <p>
              Het agentschap Opgroeien regie treedt op als verwerkingsverantwoordelijke voor de
              verwerking van jouw Persoonsgegevens in het kader van jouw deelname aan het
              PARKOUR-pilootproject. Dit betekent dat wij het doel van en de middelen voor de
              verwerking van jouw Persoonsgegevens bepalen.
            </p>
          </section>

          <section class="space-y-3">
            <h2 class="typo-h4">Welke informatie verzamelen we over jou?</h2>

            <p>
              In het kader van jouw deelname verwerken wij onder meer jouw identificatie- en
              contactgegevens en jouw feedback, meningen en voorkeuren over PARKOUR. Als je een
              jongere in een jeugdhulptraject bent, verwerken we ook gegevens over jouw traject in
              de jeugdhulp. Een volledig overzicht vind je in sectie 3 van de Privacyverklaring.
            </p>
          </section>

          <section class="space-y-3">
            <h2 class="typo-h4">Waarom verzamelen we deze informatie?</h2>

            <p>
              We verwerken jouw Persoonsgegevens om de basisversie van PARKOUR uit te testen en
              inzichten te genereren en beslissingen te vormen over de verdere ontwikkeling en
              verbetering van de applicatie. Zo kan de PARKOUR-applicatie optimaal worden afgestemd
              op de unieke vereisten van jongeren in de jeugdhulp en hun team. Meer informatie vind
              je in sectie 5 van de Privacyverklaring.
            </p>
          </section>

          <section class="space-y-3">
            <h2 class="typo-h4">Wie heeft toegang tot jouw Persoonsgegevens?</h2>

            <p>
              Jouw Persoonsgegevens worden gedeeld met de andere teamleden. Daarnaast worden jouw
              gegevens verwerkt door een beperkt aantal medewerkers van het agentschap Opgroeien en
              aan ICT-ontwikkelaar Cronos. In elk geval is de toegang altijd strikt beperkt tot wat
              nodig is voor de doeleinden die in deze Privacyverklaring zijn uiteengezet. Meer
              informatie vind je in sectie 7 van de Privacyverklaring.
            </p>
          </section>

          <section class="space-y-3">
            <h2 class="typo-h4">Wat zijn jouw rechten?</h2>

            <p>
              Je kan ons te allen tijde verzoeken om jouw Persoonsgegevens in te zien, een kopie te
              verkrijgen of ze te corrigeren, hoewel er bepaalde uitzonderingen van toepassing
              kunnen zijn. Meer informatie over jouw rechten en onze contactgegevens vind je in
              sectie 8 van de Privacyverklaring.
            </p>
          </section>

          <section class="space-y-3">
            <h2 class="typo-h4">Wat doen wij om jouw Persoonsgegevens te beschermen?</h2>

            <p>
              Wij nemen passende beveiligingsmaatregelen om misbruik, verlies, onbevoegde toegang,
              ongewenste openbaarmaking en ongeoorloofde wijziging van jouw Persoonsgegevens te
              voorkomen. Wij bewaren jouw Persoonsgegevens ook niet langer dan strikt noodzakelijk
              is om de doeleinden zoals uiteengezet in deze Privacyverklaring te vervullen. Meer
              informatie vind je in sectie 9 van de Privacyverklaring of door contact op te nemen
              met
              <a href="mailto:dpo@opgroeien.be">dpo&#64;opgroeien.be</a>
              .
            </p>
          </section>

          <section class="space-y-3">
            <h2 class="typo-h4">Volledige Privacyverklaring</h2>

            <div>
              <ol>
                <li>
                  <h3 class="typo-h5">Algemeen</h3>

                  <ol>
                    <li>
                      Het agentschap Opgroeien regie (“we” of “wij”) treedt op als
                      verwerkingsverantwoordelijke voor de verwerking van jouw Persoonsgegevens in
                      het kader van jouw deelname aan het PARKOUR-pilootproject. Dit betekent dat
                      wij het doel van en de middelen voor de verwerking van jouw Persoonsgegevens
                      bepalen.
                    </li>

                    <li>
                      We beseffen dat je het belangrijk vindt hoe jouw Persoonsgegevens als
                      deelnemer worden gebruikt en verwerkt, en we nemen jouw privacy serieus. Het
                      doel van deze Privacyverklaring is om je uitgebreid te informeren over ons
                      gegevensverzamelingsproces en om jouw rechten als deelnemer te handhaven
                      tijdens het verwerken van jouw Persoonsgegevens.
                    </li>

                    <li>
                      We doen er alles aan om ervoor te zorgen dat er verantwoordelijk en veilig met
                      jouw gegevens wordt omgegaan, onder de juiste voorwaarden. We handelen
                      bijvoorbeeld steeds in overeenstemming met:

                      <ul>
                        <li>
                          de Algemene Verordening Gegevensbescherming 2016/679 (AVG) van de EU;
                          en/of
                        </li>

                        <li>
                          alle (toekomstige) nationale wetten met betrekking tot de uitvoering van
                          de AVG
                        </li>
                      </ul>
                    </li>
                  </ol>
                </li>

                <li>
                  <h3 class="typo-h5">Definities</h3>

                  <div>
                    <p>
                      In deze Privacyverklaring hebben de volgende specifieke definities de volgende
                      betekenis:
                    </p>

                    <ul>
                      <li>
                        “<b>Betrokkene</b>”: een geïdentificeerde of identificeerbare natuurlijke
                        persoon; in de context van deze Privacyverklaring is een Betrokkene een
                        deelnemer van het PARKOUR Pilootproject en een Gebruiker van de basisversie
                        van de PARKOUR-applicatie;
                      </li>

                      <li>
                        "<b>Persoonsgegevens</b>": alle informatie met betrekking tot een
                        Betrokkene.
                      </li>
                    </ul>
                  </div>
                </li>

                <li>
                  <h3 class="typo-h5">Overzicht van gegevensverwerking</h3>

                  <p>
                    Onderstaande tabel geeft een overzicht van de categorieën Persoonsgegevens die
                    we van jou als deelnemer aan het PARKOUR -pilootproject zullen verwerken:
                  </p>

                  <h4 class="typo-body variant-body-fat">Jongere in een jeugdhulptraject</h4>

                  <ul>
                    <li>Identificatie- en contactgegevens, zoals volledige naam en adres.</li>

                    <li>Rijksregisternummer</li>

                    <li>Leeftijd</li>

                    <li>
                      Gegevens over doelen, berichten bij en reacties op je doelen, thema’s gelinkt
                      aan doelen…)
                    </li>

                    <li>
                      Verhaal (bv. jeugdhulphistoriek, 3-kolommenmodel, belangrijke
                      levensgebeurtenissen, ...)
                    </li>

                    <li>Kalender met afspraken</li>

                    <li>
                      Bijzondere categorieën van persoonsgegevens, waaruit bv. ras of etnische
                      afkomst, politieke opvattingen, religieuze of levensbeschouwelijke opvattingen
                      kunnen blijken of gegevens over gezondheid, of gegevens over iemands seksueel
                      gedrag of seksuele gerichtheid (*)
                    </li>

                    <li>Vrijetijdsbesteding, interesses en beschikbaarheid</li>

                    <li>Profielfoto en andere foto&rsquo;s</li>

                    <li>Beeldbellen, spraak- en chatberichten</li>

                    <li>Samenstelling van het team</li>

                    <li>Elektronische communicatiegegevens</li>
                  </ul>

                  <h4 class="typo-body variant-body-fat">Jeugdhulpverlener</h4>

                  <ul>
                    <li>Identificatie- en contactgegevens</li>

                    <li>Rijksregisternummer</li>

                    <li>Rol en hoedanigheid</li>

                    <li>Vrijetijdsbesteding, interesses en beschikbaarheid</li>

                    <li>Afspraken met de jongere</li>

                    <li>Reacties op doelen en berichten jongere</li>

                    <li>Profielfoto en andere foto&rsquo;s</li>

                    <li>Beeldbellen, spraak- en chatberichten</li>

                    <li>Elektronische communicatiegegevens</li>

                    <li>Profielstatus</li>
                  </ul>

                  <h4 class="typo-body variant-body-fat">Teamleden</h4>

                  <ul>
                    <li>Identificatie- en contactgegevens</li>

                    <li>Rijksregisternummer</li>

                    <li>Hoedanigheid en rol</li>

                    <li>Vrijetijdsbesteding, interesses en beschikbaarheid</li>

                    <li>Reacties op doelen en berichten jongere</li>

                    <li>Profielfoto en andere foto&rsquo;s</li>

                    <li>Beeldbellen, spraak- en chatberichten</li>

                    <li>Elektronische communicatiegegevens</li>

                    <li>Profielstatus</li>
                  </ul>

                  <p>
                    (*) Het is in het kader van PARKOUR niet nodig noch voorzien dat er bijzondere
                    categorieën van Persoonsgegevens zoals bedoeld in artikel 9 AVG worden verwerkt
                    van andere Betrokkenen dan de jongere in de jeugdhulp. Indien een andere
                    Betrokkene dan de jongere in de jeugdhulp op eigen initiatief dergelijke van
                    zijn of haar gegevens in de applicatie registreert, gebeurt dit onder de eigen
                    verantwoordelijkheid, doch wordt steeds gepast beveiligd.
                  </p>
                </li>

                <li>
                  <h3 class="typo-h5">Bron van de gegevens</h3>

                  <p>
                    Jouw naam en rijksregisternummer verwerken we vanuit jouw eID (elektronische
                    identiteitskaart) wanneer je je aanmeldt bij PARKOUR. Gegevens over de
                    jeugdhulphistoriek en het 3-kolommenmodel van de jongere zijn afkomstig uit het
                    jeugdhulpdossier. De andere persoonsgegevens worden rechtstreeks bij jou
                    verzameld.
                  </p>
                </li>

                <li>
                  <h3 class="typo-h5">Doel en rechtsgrond van gegevensverwerking</h3>

                  <p>
                    We verwerken persoonsgegevens in PARKOUR om de jongere en zijn netwerk te
                    ondersteunen door:
                  </p>

                  <ol>
                    <li>
                      veilige onlinecommunicatie mogelijk maken tussen de jongere en zijn teamleden,
                      en zijn hulpverlener;
                    </li>

                    <li>
                      digitale samenwerking aan het eigen traject van de jongere mogelijk maken;
                    </li>

                    <li>
                      op maat veilig digitaal gegevens uit het jeugdhulpdossier te ontsluiten voor
                      de jongere;
                    </li>

                    <li>
                      informatie op maat aanbieden om de jongere te ondersteunen en te begeleiden;
                    </li>

                    <li>
                      faciliteren dat de jongere de regie van zijn jeugdhulptraject mee in handen
                      kan houden.
                    </li>
                  </ol>

                  <p>
                    Alle inzichten, feedback en gegevens verzameld tijdens het PARKOUR-pilootproject
                    zullen worden gebruikt door het agentschap Opgroeien om mogelijke problemen te
                    identificeren, noodzakelijke wijzigingen aan te brengen en de algehele kwaliteit
                    en effectiviteit van de PARKOUR-applicatie te verbeteren alsook kaders en
                    ondersteuning uit te werken voor een bredere uitrol. In het bijzonder worden
                    volgende specifieke doeleinden onderscheiden:
                  </p>

                  <ol>
                    <li>
                      Technisch: opsporen van bugs, time-outs, niet kunnen inloggen, eventuele
                      datalimieten die overschreden dreigen te worden …;
                    </li>

                    <li>
                      Gebruikservaring: is het gebruik intuïtief, zijn de flows logisch, wat zijn de
                      verschillen in het gebruik van de app versus de webversie, …?
                    </li>

                    <li>
                      Blended werken: gebruik van de app in combinatie met &lsquo;offline&rsquo;
                      begeleiden;
                    </li>

                    <li>
                      Crisis en misbruik: vindt de gebruiker van PARKOUR gemakkelijk ondersteuning
                      in geval van crisis of oneigenlijk gebruik van de toepassing? Hoe wordt
                      &lsquo;nazorg&rsquo; geboden?
                    </li>

                    <li>
                      Het opmaken van communicaties en publicaties in diverse project-gerelateerde
                      materialen om zo bij te dragen aan de bredere kennis en begrip van PARKOUR;
                    </li>

                    <li>
                      Waar ligt de meerwaarde of uitdaging? Zit de jongere echt aan het stuur?
                    </li>
                  </ol>

                  <p>
                    De verwerking van jouw Persoonsgegevens is met andere woorden noodzakelijk in
                    het kader van jouw deelname aan het PARKOUR-pilootproject op grond van jouw
                    toestemming. Dit vormt dan ook de
                    <strong>rechtmatige verwerkingsgrond</strong>. De aanvullende verwerkingsgrond
                    op grond van artikel 9 van de Algemene Verordening Gegevensverwerking (AVG) voor
                    de verwerking van de bijzondere categorieën van persoonsgegevens van de jongere
                    met een jeugdhulptraject is de voorafgaande uitdrukkelijke toestemming van de
                    jongere, verstrekt via het deelnameformulier dat deel uitmaakt van de
                    Deelnameovereenkomst. Je hebt te allen tijde het recht om jouw toestemming voor
                    de verwerking van jouw gegevens in te trekken, zoals nader gespecificeerd in
                    sectie 8.5 van deze Privacyverklaring.
                  </p>
                </li>

                <li>
                  <h3 class="typo-h5">Duur van de verwerking</h3>

                  <p>
                    Wij bewaren en verwerken je Persoonsgegevens zolang je profiel actief is op
                    PARKOUR. Na zes maanden inactiviteit sturen we je een bericht om te vragen of
                    jouw gegevens mogen worden verwijderd. Als je hier niet op reageert, worden jouw
                    gegevens na een maand verwijderd. Als je zelf jouw profiel verwijdert, worden
                    jouw gegevens nog één maand bewaard.
                  </p>
                </li>

                <li>
                  <h3 class="typo-h5">Delen van je gegevens</h3>

                  <p>
                    Jouw Persoonsgegevens worden verwerkt door een beperkt aantal medewerkers van
                    het agentschap Opgroeien en van ICT-ontwikkelaar Cronos. Deze gegevensdeling is
                    steeds beperkt tot de gegevens die relevant zijn voor de doeleinden die in deze
                    Privacyverklaring zijn uiteengezet onder sectie 4. Jouw Persoonsgegevens kunnen
                    ook worden gedeeld met andere leden van jouw team zoals jij dat zelf bepaalt.
                  </p>
                </li>

                <li>
                  <h3 class="typo-h5">Jouw rechten</h3>

                  <p>
                    Op grond van de toepasselijke gegevensbeschermingswetgeving beschikt je over een
                    aantal rechten met betrekking tot de verwerking van jouw persoonsgegevens. Het
                    agentschap Opgroeien regie hecht een groot belang aan de bescherming van jouw
                    persoonsgegevens en zal elk verzoek tot de uitoefening van een van deze rechten
                    steeds zorgvuldig bestuderen en redelijke en passende maatregelen treffen om
                    hieraan tegemoet te komen, rekening houdende met de specifieke context en
                    eventuele wettelijke uitzonderingen die op jouw verzoek van toepassing kunnen
                    zijn.
                  </p>

                  <ol>
                    <li>
                      <h4 class="typo-body variant-body-fat">
                        Recht van inzage en recht op een kopie
                      </h4>

                      <p>
                        Je hebt het recht om op elk moment toegang te vragen tot jouw
                        Persoonsgegevens en om geïnformeerd te worden over het doel van de
                        verwerking door ons.
                      </p>
                    </li>

                    <li>
                      <h4 class="typo-body variant-body-fat">Recht op rectificatie of beperking</h4>

                      <p>
                        Je hebt altijd het recht ons te vragen jouw Persoonsgegevens te rectificeren
                        (=fouten te herstellen) als je van mening bent dat deze onjuist of niet
                        langer actueel zijn (bijvoorbeeld een aanpassing van jouw adres indien je
                        bent verhuisd).
                      </p>

                      <p>
                        Je kan ons ook vragen om de verwerking van jouw Persoonsgegevens tijdelijk
                        stop te zetten (recht van beperking), in een van de volgende situaties:
                      </p>

                      <ul>
                        <li>
                          Wanneer je gevraagd hebt om bepaalde Persoonsgegevens te rectificeren kan
                          je verzoeken dat wij deze gegevens niet gebruiken zolang wij dit verzoek
                          nog niet hebben beoordeeld.
                        </li>

                        <li>
                          Wanneer blijkt dat de verwerking van bepaalde Persoonsgegevens
                          onrechtmatig is, kan je verzoeken dat wij deze gegevens niet wissen maar
                          wel dat we de verwerking ervan stopzetten (bijvoorbeeld omdat je de
                          gegevens later nog wil opvragen).
                        </li>

                        <li>
                          Wanneer wij de gegevens niet langer nodig hebben om de doeleinden
                          omschreven in deze Privacyverklaring na te streven, kan je verzoeken dat
                          wij deze gegevens niet wissen maar wel dat we de verwerking ervan
                          stopzetten (bijvoorbeeld omdat je de gegevens nog nodig heeft voor een
                          juridische procedure waarin je bent betrokken).
                        </li>

                        <li>
                          Wanneer je bezwaar hebt geuit tegen de verwerking van jouw
                          Persoonsgegevens kan je ons verzoeken dat wij deze gegevens niet meer
                          verwerken totdat jouw bezwaar werd beoordeeld.
                        </li>
                      </ul>
                    </li>

                    <li>
                      <h4 class="typo-body variant-body-fat">Recht van bezwaar</h4>

                      <p>
                        Je hebt het recht om bezwaar te maken tegen de verwerking van jouw
                        Persoonsgegevens als je daarvoor legitieme redenen hebt en ons die meedeelt.
                      </p>
                    </li>

                    <li>
                      <h4 class="typo-body variant-body-fat">Recht op gegevensoverdraagbaarheid</h4>

                      <p>
                        Je hebt het recht ons te verzoeken jouw Persoonsgegevens in een
                        gestructureerd, algemeen gebruikt formaat te verkrijgen en/of deze
                        Persoonsgegevens over te dragen aan een andere verwerkingsverantwoordelijke.
                      </p>
                    </li>

                    <li>
                      <h4 class="typo-body variant-body-fat">Recht om toestemming in te trekken</h4>

                      <p>
                        Je hebt het recht om jouw toestemming voor de verwerking van jouw gegevens
                        in het kader van PARKOUR te allen tijde in te trekken. Jouw intrekking van
                        toestemming heeft geen invloed op de rechtmatigheid van de verwerking op
                        basis van toestemming vóór de intrekking.
                      </p>
                    </li>

                    <li>
                      <h4 class="typo-body variant-body-fat">
                        Geautomatiseerde besluitvorming en profilering
                      </h4>

                      <p>
                        De verwerking van jouw Persoonsgegevens op grond van jouw deelname omvat
                        geen profilering en je wordt niet onderworpen aan geautomatiseerde
                        besluiten.
                      </p>

                      <p>
                        Niettemin hebt je het recht om ons te verzoeken niet te worden onderworpen
                        aan een besluit dat uitsluitend is gebaseerd op geautomatiseerde verwerking,
                        met inbegrip van profilering, en dat rechtsgevolgen voor je heeft of je op
                        vergelijkbare wijze in aanzienlijke mate treft.
                      </p>
                    </li>

                    <li>
                      <h4 class="typo-body variant-body-fat">Jouw rechten uitoefenen</h4>

                      <p>Je kan jouw rechten uitoefenen door contact met ons op te nemen:</p>

                      <p>
                        Ofwel via e-mail naar
                        <a href="mailto:dpo@opgroeien.be"> dpo&#64;opgroeien.be </a>
                      </p>

                      <p>Of per post naar,</p>

                      <address>DPO Opgroeien, Hallepoortlaan 27, 1060 Brussel</address>

                      <p>
                        Wanneer je contact met ons opneemt om jouw rechten uit te oefenen, kunnen
                        wij je om redelijke aanvullende informatie of instructies vragen om jouw
                        identiteit te bevestigen (bijvoorbeeld beveiligde digitale authenticatie via
                        jouw e-ID of itsme). Dit is een noodzakelijke stap om misbruik of oplichting
                        te voorkomen.
                      </p>
                    </li>

                    <li>
                      <h4 class="typo-body variant-body-fat">Recht om een klacht in te dienen</h4>

                      <p>
                        Als je niet tevreden bent over de manier waarop we jouw Persoonsgegevens
                        hebben behandeld of jouw verzoeken of klachten hebben afgehandeld, moedigen
                        we je aan eerst contact op te nemen met de coach van het
                        PARKOUR-pilootproject. We stellen jouw feedback op prijs en ons doel is om
                        samen met jou naar een bevredigende oplossing te zoeken.
                      </p>

                      <p>
                        Indien jouw probleem niet naar tevredenheid kon worden opgelost, behoud je
                        natuurlijk steeds het recht om een klacht in te dienen bij de
                        toezichthoudende autoriteit voor gegevensbescherming:
                      </p>

                      <p>
                        <i>Gegevensbeschermingsautoriteit, </i><br />

                        <i>Persstraat 35, 1000 Brussel,</i><br />

                        <i>Tel: +32 (0)2 274 48 00, Fax: +32 (0)2 274 48 35,</i><br />

                        <i
                          >E-mail:
                          <a href="mailto:contact@apd-gba.be"> contact&#64;apd-gba.be </a>
                        </i>
                      </p>

                      <p>
                        Dit doet geen afbreuk aan een mogelijke procedure bij de burgerlijke
                        rechtbank. Als je afkomstig bent uit een andere EU-lidstaat dan België, kan
                        je ook een klacht indienen bij jouw nationale gegevensbeschermingsautoriteit
                        (een lijst van de gegevensbeschermingsautoriteit voor elke EU-lidstaat vind
                        je hier:

                        <a
                          href="https://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm"
                          target="_blank"
                        >
                          https://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm
                        </a>
                        ).
                      </p>

                      <p>
                        Als je schade hebt geleden als gevolg van de verwerking van jouw
                        Persoonsgegevens, kan je een schadeclaim indienen.
                      </p>
                    </li>
                  </ol>
                </li>

                <li>
                  <h3 class="typo-h5">Veiligheid en vertrouwelijkheid</h3>

                  <p>
                    We hebben beveiligingsmaatregelen genomen die geschikt zijn op technisch,
                    organisatorisch en fysiek vlak om de vernietiging, het verlies, de vervalsing,
                    de wijziging, de ongeoorloofde toegang of de onbedoelde kennisgeving van
                    Persoonsgegevens aan een derde partij te voorkomen, evenals de ongeoorloofde
                    verwerking van dergelijke gegevens.
                  </p>

                  <p>
                    Mochten deze gebeurtenissen zich toch voordoen en jouw Persoonsgegevens
                    betreffen, dan zullen wij, waar nodig en wettelijk toegestaan, je zonder
                    onnodige vertraging op de hoogte stellen van de inbreuk, inclusief een korte
                    beschrijving van de mogelijke gevolgen en een aanbeveling voor maatregelen om de
                    mogelijke negatieve impact van de inbreuk te beperken.
                  </p>

                  <p>
                    Wij zijn op geen enkele wijze aansprakelijk voor rechtstreekse of
                    onrechtstreekse schade veroorzaakt door een onrechtmatig of oneigenlijk gebruik
                    van de Persoonsgegevens door een derde.
                  </p>

                  <p>
                    Tegelijkertijd draag je ook zelf een verantwoordelijkheid voor het behoud van
                    jouw privacy, bijvoorbeeld door derden geen inzage te geven in jouw
                    vertrouwelijke communicatie en jouw aanmeldgegevens of aangemelde sessie niet te
                    delen.
                  </p>
                </li>
              </ol>
            </div>
          </section>
        </article>
      </div>
    </div>
  </parkour-page-layout>
</ion-content>
