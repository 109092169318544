import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ParkourButtonComponent, ParkourIconComponent, TParkourIconName,} from '@parkour/ui';
import {ModalController} from '@ionic/angular';
import {NavigationEnd, Router} from '@angular/router';
import {Subscription} from 'rxjs';

@Component({
    standalone: true,
    templateUrl: "./parkour-yes-no-popup.component.html",
    imports: [ParkourIconComponent, ParkourButtonComponent],
    providers: [ModalController],
})
export class ParkourYesNoPopupComponent implements OnInit, OnDestroy {
    @Input() public icon?: TParkourIconName;
    @Input() public description!: string;
    @Input({required: true}) public title!: string;
    @Input() public jaText = "Ja";
    @Input() public neeText = "Nee";
    @Input({required: true}) public closeCallback!: () => void;
    @Input({required: true}) public cancelCallback!: () => void;
    @Input({required: true}) public confirmCallback!: () => void;

    open = false;
    routerSubscription!: Subscription;

    constructor(
        private readonly modalControl: ModalController,
        private readonly router: Router,
    ) {
    }

    ngOnDestroy(): void {
        this.routerSubscription.unsubscribe();
    }

    ngOnInit() {
        setTimeout(() => (this.open = true), 0); // Popup only seems to open when it doesn't start as "open"
        this.routerSubscription = this.router.events.subscribe((val) => {
            if (val instanceof NavigationEnd) {
                this.handleCloseYesNoPopup();
            }
        });
    }

    onJaClick() {
        this.confirmCallback();
    }

    onNeeClick() {
        this.cancelCallback();
    }

    onDismiss() {
        if(this.modalControl) this.cancelCallback();
    }

    handleCloseYesNoPopup() {
        if (this.open && this.modalControl) {
            this.closeCallback();
        }
    }
}
