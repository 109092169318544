import { Injectable, Injector, NgZone } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { LoggingService } from './logging.service';
import { SilentError } from './silent-error';
import { ParkourError } from './parkour-error';
import AuthService from '../authentication/service/auth.service';
import { trace } from '@opentelemetry/api';
import { HumanReadableError, OfflineError } from './human-readable-error';

@Injectable()
export class GlobalHttpErrorHandler implements HttpInterceptor {
  constructor(
    private injector: Injector,
    private ngZone: NgZone,
    private loggingService: LoggingService,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        this.logErrorInOpentelemetry(request, error);
        if (error.status === 401) {
          this.ngZone.run(() => {
            this.loggingService.error('401 error, refreshing user');
            this.injector.get(AuthService).retrieveUser('401').subscribe();
          });
          return throwError(() => new SilentError(error.message));
        }

        if (error.error && typeof error.error === 'object') {
          if ('errorType' in error.error) {
            return throwError(
              () => new ParkourError(error.error.message, error.error.errorType, { cause: error }),
            );
          }
          if ('supportID' in error.error) {
            return throwError(
              () =>
                new HumanReadableError(
                  `Oeps! Er ging iets mis. SupportID: ${error.error.supportID}`,
                ),
            );
          }
          if (!window.navigator.onLine) {
            return throwError(() => new OfflineError());
          }
        }

        return throwError(() => error);
      }),
    );
  }

  private logErrorInOpentelemetry(
    request: HttpRequest<unknown>,
    httpErrorResponse: HttpErrorResponse,
  ) {
    const tracer = trace.getTracer('http-interceptor');
    const span = tracer.startSpan(`HTTP ${request.method} ${request.url}`);
    span.recordException({
      name: httpErrorResponse.name || 'HTTPError',
      message: httpErrorResponse.message || 'An error occurred during the HTTP request',
      stack: httpErrorResponse.error || 'No stack trace available',
    });
    span.setStatus({ code: 2, message: httpErrorResponse.message });
    span.end();
  }
}
