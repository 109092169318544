<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button parkourBackButton />
    </ion-buttons>

    <ion-title aria-level="1" role="heading"
      >{{ 'doelen.doel-add-page.title' | translate }}
    </ion-title>

    <ion-buttons slot="end">
      @if (teamleden$ | async) {
        @if (doelForm?.doelForm) {
          <parkour-header-action
            [label]="'doelen.doel-edit-page.doel-opslaan' | translate"
            form="doel-new-form"
            icon="disk"
            testId="submit"
            type="submit"
          />
        }
      }
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <parkour-page-layout>
    <parkour-page-gradient />

    @if (teamleden$ | async; as teamleden) {
      <div class="relative z-10 py-8 container container-space">
        @if (teamleden && initialStateLoaded) {
          <div class="container-1-col">
            <parkour-doel-form
              #doelForm
              [initialState]="initialState"
              [submitDoel]="submitDoel"
              [teamleden]="teamleden"
              formId="doel-new-form"
            />
          </div>
        }
      </div>
    }
  </parkour-page-layout>
</ion-content>
