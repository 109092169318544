import { Router } from '@angular/router';
import { inject } from '@angular/core';
import { isNativeApp } from '../../../utils';

export const isNativeAppGuard = () => {
  const router: Router = inject(Router);

  if (!isNativeApp()) {
    return router.createUrlTree(['/']);
  }

  return true;
};
