import { DeelMode, ProfielId } from 'parkour-web-app-dto';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TeamledenPipe } from '../../../shared/pipes/teamleden.pipe';
import {
  TParkourGedeeldMetIndicatorFormat,
  TParkourGedeeldMetIndicatorSizes,
} from './gedeeld-met-indicator.types';
import { TeamService } from '../../../team/service/team.service';
import { TeamlidProfiel } from '../../../team/model/teamlid.model';
import { ParkourActionComponent, ParkourIconComponent } from '@parkour/ui';
import { AsyncPipe, NgClass, NgForOf, NgIf, NgTemplateOutlet } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ProfielFotoBadgeComponent } from '../../../shared/components/profiel-foto-badge/profiel-foto-badge.component';
import { ProfielnaamPipe } from '../../../shared/pipes/profielnaam.pipe';

@Component({
  standalone: true,
  selector: 'parkour-gedeeld-met-indicator',
  templateUrl: './gedeeld-met-indicator.component.html',
  providers: [TeamledenPipe],
  imports: [
    ParkourActionComponent,
    NgIf,
    TranslateModule,
    NgTemplateOutlet,
    NgClass,
    ParkourIconComponent,
    AsyncPipe,
    NgForOf,
    ProfielFotoBadgeComponent,
    ProfielnaamPipe,
  ],
})
export class GedeeldMetIndicatorComponent {
  @Input({ required: true }) deelMode!: DeelMode;
  @Input({ required: true }) gedeeldMet!: Array<ProfielId>;
  @Input() maxItems = 2;
  @Input() ariaLabel?: string;
  @Input() size: TParkourGedeeldMetIndicatorSizes = 'default';
  @Input() format: TParkourGedeeldMetIndicatorFormat = 'default';
  @Input() testId?: string;

  @Output() public clicked: EventEmitter<void> = new EventEmitter<void>();

  actieveTeamleden$ = this.teamService.actieveTeamleden$;

  constructor(
    private readonly teamledenPipe: TeamledenPipe,
    private readonly teamService: TeamService,
  ) {}

  getVisibleTeamleden(teamleden: TeamlidProfiel[]): Array<TeamlidProfiel> {
    switch (this.deelMode) {
      case 'SPECIFIEK':
        return this.teamledenPipe.transform(this.gedeeldMet, teamleden);
      case 'IEDEREEN':
        return teamleden;
    }
  }

  onClick() {
    this.clicked.emit();
  }
}
