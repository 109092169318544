import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { BerichtId, BestandId, GesprekId, ProfielId } from 'parkour-web-app-dto';
import { ImagesService } from '../../shared/services/images.service';
import { AttachmentBericht } from '../model/gesprek';
import { environment } from '../../../environments/environment';

@Pipe({
  name: 'bestandPreview',
  standalone: true,
})
export class BestandPreviewPipe implements PipeTransform {
  constructor(private readonly imageService: ImagesService) {}

  getPreviewUrl(
    profielId: ProfielId,
    gesprekId: GesprekId,
    berichtId: BerichtId,
    bestandId: BestandId,
  ): string {
    return `${environment.API_BASE_URL}/api/jongere/${profielId}/gesprekken/${gesprekId}/berichten/${berichtId}/bestand/${bestandId}/preview`;
  }

  transform(bericht: AttachmentBericht, gesprekId: GesprekId): Observable<string> {
    return this.imageService.getImage(
      this.getPreviewUrl(bericht.deelnemerId, gesprekId, bericht.id, bericht.bestandId),
    );
  }
}
