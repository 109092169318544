import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { isPlatform } from '@ionic/core';
import {
  AttachmentBericht,
  Bericht,
  GedeeldeResourceBericht,
  SpraakBericht,
  TextBericht,
} from '../../model/gesprek';
import {
  BerichtDto,
  BerichtId,
  GesprekId,
  InteractieEmoji,
  ProfielId,
  ReactieEvent,
  validatedProfielId,
} from 'parkour-web-app-dto';
import { BerichtenService } from '../../service/berichten.service';
import { ProfielService } from '../../../profiel/service/profiel.service';
import { map, Observable, shareReplay } from 'rxjs';
import { flattenDeep } from 'lodash';
import { getProfielnaam, ProfielInTeam } from '../../../profiel/model/profiel-in-team';
import { AbstractGesprek } from '../../model/abstractGesprek';
import { AsyncPipe, DatePipe, NgClass, NgIf } from '@angular/common';
import { isNativeApp } from '../../../utils';
import { ParkourPopoverDirective } from '../../../shared/directives/parkour-popover.directive';
import { ProfielFotoBadgeComponent } from '../../../shared/components/profiel-foto-badge/profiel-foto-badge.component';
import { BerichtTextContentComponent } from '../bericht-text-content/bericht-text-content.component';
import { BerichtAttachmentPreviewComponent } from '../bericht-attachment-preview/bericht-attachment-preview.component';
import { TranslateModule } from '@ngx-translate/core';
import { BerichtSpeechContentComponent } from '../bericht-speech-content/bericht-speech-content.component';
import { BerichtTextWrapperComponent } from '../bericht-text-wrapper/bericht-text-wrapper.component';
import { BerichtAttachmentContentComponent } from '../bericht-attachment-content/bericht-attachment-content.component';
import { BerichtSpeechWrapperComponent } from '../bericht-speech-wrapper/bericht-speech-wrapper.component';
import { BerichtResourceComponent } from '../bericht-resource/bericht-resource.component';
import { EmojiReactiesComponent } from '../../../shared/components/emoji-reacties/emoji-reacties.component';
import { ParkourActionComponent, ParkourButtonComponent, ParkourIconComponent } from '@parkour/ui';
import { AddEmojiComponent } from '../../../shared/components/add-emoji/add-emoji.component';
import { LongPressDirective } from '../../../shared/directives/long-press-directive';
import { IonPopover } from '@ionic/angular/standalone';
import { BerichtGemaskeerdContentComponent } from '../bericht-gemaskeerd-content/bericht-gemaskeerd-content.component';

@Component({
  standalone: true,
  selector: 'parkour-bericht-message',
  templateUrl: './bericht-message.component.html',
  styleUrls: ['./bericht-message.component.css'],
  imports: [
    ProfielFotoBadgeComponent,
    AsyncPipe,
    NgIf,
    NgClass,
    BerichtTextContentComponent,
    BerichtAttachmentPreviewComponent,
    TranslateModule,
    BerichtSpeechContentComponent,
    BerichtTextWrapperComponent,
    BerichtAttachmentContentComponent,
    BerichtSpeechWrapperComponent,
    BerichtResourceComponent,
    EmojiReactiesComponent,
    ParkourPopoverDirective,
    ParkourButtonComponent,
    AddEmojiComponent,
    ParkourActionComponent,
    ParkourIconComponent,
    LongPressDirective,
    IonPopover,
    BerichtGemaskeerdContentComponent,
  ],
})
export class BerichtMessageComponent implements OnInit, OnChanges {
  @ViewChild('popover', { static: true }) popover!: ParkourPopoverDirective;

  @Input() isUser = false;
  @Input({ required: true }) userProfielId!: ProfielId;
  @Input({ required: true }) bericht!:
    | TextBericht
    | AttachmentBericht
    | SpraakBericht
    | GedeeldeResourceBericht;
  @Input({ required: true }) berichten!: Bericht[][];
  @Input({ required: true }) gesprekId!: GesprekId;
  @Input({ required: true }) gesprekWithDeelnemers!: AbstractGesprek;
  @Input({ required: true }) disabled!: boolean;

  @Output() verwijderd = new EventEmitter<void>();
  @Output() openedPopover = new EventEmitter<void>();
  @Output() closedPopover = new EventEmitter<void>();
  @Output() closingPopover = new EventEmitter<void>();
  @Output() selectedReactie = new EventEmitter<ReactieEvent>();
  @Output() deleteReactie = new EventEmitter<BerichtId>();
  @Output() saveNotitie = new EventEmitter<{
    titel: string;
    beschrijving: string;
  }>();
  @Output() replyBericht = new EventEmitter<TextBericht | AttachmentBericht | SpraakBericht>();
  @Output() scrollToBottom = new EventEmitter<void>();
  @Output() rapporteer = new EventEmitter<{
    gesprekId: GesprekId;
    berichtId: BerichtId;
  }>();

  profiel$ = new Observable<ProfielInTeam>();
  audioFile$ = new Observable<string>();
  origineelBericht: BerichtDto | null = null;
  isAntwoord: boolean = false;
  protected readonly isNativeApp = isNativeApp;

  constructor(
    private readonly berichtenService: BerichtenService,
    private readonly profielService: ProfielService,
    private readonly datePipe: DatePipe,
  ) {}

  @HostBinding('class') get class() {
    return this.isUser
      ? `chat-flow__user-message ${this.bericht.type === 'SPRAAK' && 'chat-flow__audio-message'} ${
          this.bericht.type === 'GEDEELDE_RESOURCE' && 'chat-flow__resource-message'
        }`
      : `chat-flow__message ${this.bericht.type === 'SPRAAK' && 'chat-flow__audio-message'} ${
          this.bericht.type === 'GEDEELDE_RESOURCE' && 'chat-flow__resource-message'
        }`;
  }

  ngOnInit(): void {
    this.profiel$ = this.profielService
      .getProfielInCurrentTeam(this.bericht.deelnemerId)
      .pipe(shareReplay(1));

    if (this.bericht.type === 'SPRAAK') {
      this.audioFile$ = this.berichtenService
        .getBlobString(this.gesprekId, this.bericht.id, this.bericht.bestandId)
        .pipe(shareReplay(1));
    }

    this.setReplyBericht();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('bericht' in changes) {
      this.setReplyBericht();
    }
  }

  setReplyBericht() {
    if (
      this.bericht.type === 'TEKST' ||
      this.bericht.type === 'ATTACHMENT' ||
      this.bericht.type === 'SPRAAK'
    ) {
      this.isAntwoord = this.bericht.antwoord;

      this.getBerichtReply(this.bericht);
    }
  }

  getBerichtReply(bericht: TextBericht | AttachmentBericht | SpraakBericht) {
    if (bericht.antwoord && bericht.origineelBerichtId) {
      const filteredBerichten = flattenDeep(this.berichten).filter(
        (bericht) =>
          bericht.type === 'TEKST' || bericht.type === 'ATTACHMENT' || bericht.type === 'SPRAAK',
      ) as (TextBericht | AttachmentBericht | SpraakBericht)[];
      const findReplyInBerichten = filteredBerichten.find(
        (bericht) => bericht.id === bericht.origineelBerichtId,
      );

      if (findReplyInBerichten) {
        this.origineelBericht = findReplyInBerichten as BerichtDto;

        this.scrollToBottom.emit();
      } else {
        this.berichtenService
          .getBericht(this.gesprekId, bericht.origineelBerichtId)
          .subscribe((replyBericht) => {
            this.origineelBericht = replyBericht;

            this.scrollToBottom.emit();
          });
      }
    } else {
      this.origineelBericht = null;
    }
  }

  isDesktop() {
    return isPlatform('desktop');
  }

  onLongPress() {
    if (this.isDesktop() || (this.disabled && this.isUser)) {
      return;
    }

    this.openPopover();
  }

  openPopover() {
    this.popover.open();
    this.openedPopover.emit();
  }

  onDidDismiss() {
    this.closedPopover.emit();
  }

  onWillDismiss() {
    this.closingPopover.emit();
  }

  onMoreOptionsClick() {
    if (!this.isDesktop()) {
      return;
    }

    this.openPopover();
  }

  onClickVerwijder() {
    this.verwijderd.emit();
    this.popover.close();
  }

  onClickRapporteren() {
    this.rapporteer.emit({
      gesprekId: this.gesprekId,
      berichtId: this.bericht.id,
    });
    this.popover.close();
  }

  getPopoverAlignment() {
    return this.isUser ? 'end' : 'start';
  }

  getActiveSelectedReactie(): InteractieEmoji | null {
    const doesUserHaveReactie = this.bericht.reacties.find(
      (reactie) =>
        reactie.ownerId === this.userProfielId || reactie.deelnemerId === this.userProfielId, //TODO: Backwards compatibility: 1.001.0041
    );

    return doesUserHaveReactie ? doesUserHaveReactie.type : null;
  }

  onSelectedEmoji(selectedEmoji: InteractieEmoji) {
    this.popover.close();
    this.selectedReactie.emit({
      berichtId: this.bericht.id,
      reactie: {
        type: selectedEmoji,
      },
    });
  }

  onDeleteEmoji() {
    this.popover.close();
    this.deleteReactie.emit(this.bericht.id);
  }

  onSaveNotitie() {
    this.popover.close();

    setTimeout(() => {
      this.createTitelForNotitie().subscribe((titel) => {
        if (this.bericht.type === 'TEKST') {
          this.saveNotitie.emit({
            titel: titel,
            beschrijving: this.bericht.inhoud,
          });
        }
      });
    });
  }

  onReplyBericht() {
    this.popover.close();
    this.replyBericht.emit(this.bericht as TextBericht | AttachmentBericht | SpraakBericht);
  }

  getVerzenderNaam(): Observable<string> {
    return this.profielService
      .getProfielInCurrentTeam(validatedProfielId(this.bericht.deelnemerId))
      .pipe(
        map((verzender) => {
          return getProfielnaam(verzender);
        }),
      );
  }

  onCloseClick() {
    this.popover.close();
  }

  private createTitelForNotitie() {
    return this.getVerzenderNaam().pipe(
      map((profielNaam) => {
        return (
          'Gestuurd door ' +
          profielNaam +
          ' op ' +
          this.datePipe.transform(this.bericht.timestamp, 'd MMMM') +
          ' om ' +
          this.datePipe.transform(this.bericht.timestamp, 'HH:mm')
        );
      }),
    );
  }
}
