import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AttachmentBericht, SpraakBericht, TextBericht } from '../../model/gesprek';
import { ParkourButtonComponent, ParkourIconComponent } from '@parkour/ui';
import { ProfielPipe } from '../../../shared/pipes/profiel.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { LinkifyPipe } from '../../pipes/linkify.pipe';
import { ProfielnaamPipe } from '../../../shared/pipes/profielnaam.pipe';
import { AsyncPipe } from '@angular/common';

@Component({
  standalone: true,
  selector: 'parkour-bericht-reply',
  templateUrl: './bericht-reply.component.html',
  imports: [
    ParkourIconComponent,
    ProfielPipe,
    TranslateModule,
    LinkifyPipe,
    ProfielnaamPipe,
    AsyncPipe,
    ParkourButtonComponent,
  ],
})
export class BerichtReplyComponent {
  @Input({ required: true }) replyBericht!: TextBericht | AttachmentBericht | SpraakBericht;

  @Output() closeReplyBericht = new EventEmitter<void>();

  onCloseReplyBericht() {
    this.closeReplyBericht.emit();
  }
}
