<div [attr.data-testid]="testId" [ngClass]="classes" class="space-y-1">
  @if (label !== null) {
    <label
      [htmlFor]="inputId"
      [ngClass]="{ 'sr-only': isLabelHidden }"
      class="typo-body variant-body-fat flex items-center gap-x-1"
    >
      {{ label }}

      @if (!required) {
        <span class="typo-body variant-body-small text-n-1000-rgb-7">
          (optioneel)
        </span>
      }
    </label>
  }

  @for (key of Object.keys(getFormErrors()); track $index) {
    @if (getFormErrors()[key] && (this.inputFormControl?.touched ?? true)) {
      <div
        *ngIf="getErrorMessage(key) as errorMessage"
        class="flex space-x-1 text-negative items-start"
      >
        <parkour-icon name="alert-triangle" class="flex mt-[0.125rem]" />

        <p class="typo-body variant-body-small">
          {{ errorMessage }}
        </p>
      </div>
    }
  }
  <div>
    <ng-content></ng-content>
  </div>
</div>
