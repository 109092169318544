import { Observable, ReplaySubject, take } from 'rxjs';

// export type SimplePage<T> = {
//   readonly content: T[];
//   readonly last: boolean;
//   readonly [x: string]: unknown;
// };

export type GetPageFunction<T, ExtraData> = (
  pageNumber: number,
) => Observable<PaginatedData<T, ExtraData>>;

export type PaginatedData<T, ExtraData> = { content: T[]; last: boolean } & ExtraData;

export class Pagination<T, ExtraData> extends ReplaySubject<PaginatedData<T, ExtraData>> {
  private pageNumber = 0;
  private currentContent: T[] = [];
  private extraData: ExtraData = this.initialExtraData;
  private isComplete = false;

  constructor(
    private readonly getPage: GetPageFunction<T, ExtraData>,
    private readonly onError: (error: unknown) => void,
    private readonly initialExtraData: ExtraData,
  ) {
    super(1);

    this.fetchNextPage(() => {});
  }

  public fetchNextPage(onComplete: () => void) {
    this.getPage(this.pageNumber)
      .pipe(take(1))
      .subscribe({
        next: (page) => {
          this.currentContent = [...this.currentContent, ...page.content];
          this.isComplete = page.last;
          this.extraData = page;
          this.pageNumber++;
          onComplete();
          this.next({ ...page, content: this.currentContent, complete: this.isComplete });
        },
        error: (e: unknown) => {
          onComplete();
          this.onError(e);
        },
      });
  }

  public removeElementsFromCollection(condition: (element: T) => boolean) {
    this.currentContent = this.currentContent.filter(
      (currentElement) => !condition(currentElement),
    );

    this.next({ ...this.extraData, content: this.currentContent, last: this.isComplete });
  }
}
