<ion-header class="modal-header"> </ion-header>
<ion-content class="modal-content">
  <parkour-page-layout>
    <parkour-image
      classes="absolute top-0 left-0 z-0 w-full h-full overflow-hidden"
      src="/assets/login/background.png"
    />
    <div class="relative z-10 py-8 container container-space h-full flex flex-col">
      <section class="flex flex-col gap-y-8 container-1-col">
        <div class="flex flex-col gap-y-6">
          <h1 class="text-center">{{ 'aanmelden.title' | translate }}</h1>

          <parkour-divider></parkour-divider>
        </div>

        <p class="text-center self-center">
          {{ 'aanmelden.redirect-description' | translate }}
        </p>
      </section>

      <section class="flex-1 container-1-col">
        <parkour-loading-spinner classes="w-14 h-14"></parkour-loading-spinner>
      </section>
      <section class="flex flex-col gap-y-8 container-1-col">
        <p class="text-center self-center">
          {{ 'aanmelden.redirect-waiting' | translate }}
        </p>
        <parkour-button (click)="onAanmeldenClicked()" class="w-full">
          {{ 'aanmelden.redirect-button' | translate }}
        </parkour-button>

        <parkour-button (clicked)="onCancelClicked()" [variant]="'tertiary'" class="w-full">
          {{ 'buttons.annuleren' | translate }}
        </parkour-button>
        <parkour-button
          class="self-center"
          href="https://www.opgroeien.be/contact"
          iconAfter="chevron-right"
          variant="ghost"
        >
          {{ 'aanmelden.help-button' | translate }}
        </parkour-button>
      </section>
    </div>
  </parkour-page-layout>
</ion-content>
