<a
  [routerLinkActiveOptions]="{ exact }"
  [routerLinkActive]="'bg-p-100'"
  [routerLink]="link ?? []"
  class="flex items-center justify-between gap-x-2 px-4 py-3 hover:bg-p-100 focus-visible:bg-n-200 transition-colors rounded"
>
  <div class="flex items-center gap-x-2">
    <parkour-icon [name]="icon" class="text-n-1000" size="3" />

    <span class="typo-body variant-body-fat text-n-1000"> {{ text }} </span>
  </div>

  <parkour-new-indicator
    [inline]="true"
    [show]="showNewIndicator"
    class="[--notification-outline-color:transparent]"
  />
</a>
