import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  ParkourActionComponent,
  ParkourBadgeComponent,
  ParkourNewIndicatorComponent,
  TParkourIconName,
} from '@parkour/ui';

@Component({
  standalone: true,
  selector: 'parkour-profiel-nav-item',
  templateUrl: './profiel-nav-item.component.html',
  imports: [ParkourActionComponent, ParkourNewIndicatorComponent, ParkourBadgeComponent],
})
export class ParkourProfielNavItemComponent {
  @Input({ required: true }) text!: string;
  @Input({ required: true }) icon!: TParkourIconName;
  @Input({ required: true }) link!: string[] | string | null;
  @Input() showIndicator: boolean | null = false;
  @Output() public clicked: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  click(event: MouseEvent) {
    this.clicked.emit(event);
  }
}
