import { Component, HostBinding, Input } from '@angular/core';
import { ParkourActionComponent } from '@parkour/ui';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FotoBadgeComponent } from '../../../shared/components/foto-badge/foto-badge.component';
import { ProfielFotoPipe } from '../../../shared/pipes/profiel-foto.pipe';
import { AbstractGesprek } from '../../model/abstractGesprek';
import { BerichtGemaskeerdContentComponent } from '../bericht-gemaskeerd-content/bericht-gemaskeerd-content.component';

@Component({
  standalone: true,
  selector: 'parkour-bericht-gemaskeerd',
  templateUrl: './bericht-gemaskeerd.component.html',
  imports: [
    ParkourActionComponent,
    NgClass,
    TranslateModule,
    AsyncPipe,
    FotoBadgeComponent,
    ProfielFotoPipe,
    NgIf,
    BerichtGemaskeerdContentComponent,
  ],
})
export class BerichtGemaskeerdComponent {
  @Input() isUser = false;
  @Input({ required: true }) gesprekWithDeelnemers!: AbstractGesprek;

  @HostBinding('class') get class() {
    return this.isUser ? `chat-flow__user-message` : `chat-flow__message`;
  }
}
