<parkour-modal
  [icon]="deelMode === 'SPECIFIEK' ? 'lock' : 'people'"
  [title]="
    deelMode === 'SPECIFIEK'
      ? ('delen.delen-specifiek' | translate)
      : ('delen.delen-iedereen' | translate)
  "
  data-testid="gedeeldMetInfo"
>
  <div content>
    @if (teamleden$ | async; as teamleden) {
      @switch (deelMode) {
        @case ('IEDEREEN') {
          @if (teamleden.length === 0) {
            <parkour-info-card>
              <p>{{ 'delen.geen-teamleden' | translate }}</p>
            </parkour-info-card>
          } @else {
            <ng-container *ngTemplateOutlet="hasTeamleden" />
          }
        }
        @case ('SPECIFIEK') {
          @if (gedeeldMet.length === 0) {
            <parkour-info-card>
              <p>
                @if (exJongereView) {
                  {{ 'delen.geen-gedeelde-teamleden-ex-jongere' | translate }}
                } @else {
                  {{ 'delen.geen-gedeelde-teamleden' | translate }}
                }
              </p>
            </parkour-info-card>
          } @else {
            <ng-container *ngTemplateOutlet="hasTeamleden" />
          }
        }
      }
      <ng-template #hasTeamleden>
        <p class="typo-body variant-body-fat">{{ 'delen.actieve-teamleden' | translate }}</p>

        <ul class="mt-6 flex flex-col gap-y-6">
          @for (teamlid of getActieveTeamleden(teamleden); track teamlid.id) {
            <li>
              <parkour-profiel-block [profielData]="teamlid" />
            </li>
          }
        </ul>
      </ng-template>
    }
  </div>

  <ng-container footer>
    @if (teamleden$ | async; as teamleden) {
      @if (hasGeblokkeerdeTeamleden(teamleden)) {
        <div>
          <p class="typo-body variant-body-fat">{{ 'delen.geblokkeerde-teamleden' | translate }}</p>

          <ul class="mt-6 flex flex-col gap-y-6">
            @for (teamlid of getGeblokkeerdeTeamleden(teamleden); track teamlid.id) {
              <li>
                <parkour-profiel-block [profielData]="teamlid" />
              </li>
            }
          </ul>
        </div>
      }
    }
  </ng-container>
</parkour-modal>
