import { Component, Input } from '@angular/core';
import { DoelDataSectionGroup } from '../doel-form/doel-form.component';
import { themas } from 'parkour-web-app-dto';
import { themaMapping } from '../../../../home/model/thema.const';
import {
  ParkourFormFieldComponent,
  ParkourInputComponent,
  ParkourTextareaComponent,
} from '@parkour/ui';
import { ReactiveFormsModule } from '@angular/forms';
import { DoelThemaComponent } from '../doel-thema/doel-thema.component';
import { TranslateModule } from '@ngx-translate/core';
import { IonRadioGroup } from '@ionic/angular/standalone';

@Component({
  standalone: true,
  selector: 'parkour-doel-data-section',
  templateUrl: './doel-data-section.component.html',
  imports: [
    ParkourFormFieldComponent,
    ReactiveFormsModule,
    ParkourInputComponent,
    DoelThemaComponent,
    TranslateModule,
    IonRadioGroup,
    ParkourTextareaComponent,
  ],
})
export class DoelDataSectionComponent {
  @Input({ required: true }) doelFormGroup!: DoelDataSectionGroup;
  protected readonly themas = themas;
  protected readonly themaMapping = themaMapping;
}
