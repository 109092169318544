import { Component } from '@angular/core';

import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';
import { ParkourBackButtonDirective } from '../../../shared/directives/parkour-back-button.directive';
import { ShowIfSuccesfulDirective } from '../../../shared/directives/show-if-succesful.directive';
import { AsyncPipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ParkourRefreshDirective } from '../../../shared/directives/parkour-refresh-directive';
import { PageLayoutComponent } from '../../../shared/components/page-layout/page-layout.component';
import { PageContentDirective } from '../../../shared/directives/page-content.directive';
import { JeugdhulpHistoriekDetailsComponent } from '../../verhaal-shared/component/jeugdhulp-historiek-details-component/jeugdhulp-historiek-details-component.component';
import { ParkourGebeurtenisDetailsComponent } from '../../verhaal-shared/component/parkour-gebeurtenis-details-component/parkour-gebeurtenis-details-component.component';
import { GebeurtenisDetailsJongereBasePage } from '../../verhaal-shared/page/gebeurtenis-details-jongere-base-page/gebeurtenis-details-jongere-base.page';

@Component({
  standalone: true,
  templateUrl: './gebeurtenis-details-ex-jongere.page.html',
  imports: [
    IonHeader,
    IonToolbar,
    IonButtons,
    IonTitle,
    IonBackButton,
    IonContent,
    IonRefresher,
    IonRefresherContent,
    ParkourBackButtonDirective,
    ShowIfSuccesfulDirective,
    AsyncPipe,
    TranslateModule,
    ParkourRefreshDirective,
    PageLayoutComponent,
    PageContentDirective,
    JeugdhulpHistoriekDetailsComponent,
    ParkourGebeurtenisDetailsComponent,
  ],
})
export class GebeurtenisDetailsExJongerePage extends GebeurtenisDetailsJongereBasePage {}
