import { Component, Input } from '@angular/core';
import { Gebeurtenis } from '../../model/gebeurtenis';
import {
  JeugdhulpHistoriekGedeeldMelding,
  NieuwEyouthGebeurtenisMelding,
} from 'parkour-web-app-dto';
import {
  ParkourActionComponent,
  ParkourImageComponent,
  ParkourNewIndicatorComponent,
} from '@parkour/ui';
import { DatePipe } from '@angular/common';
import { GedeeldMetIndicatorComponent } from '../../../../delen/component/gedeeld-met-indicator/gedeeld-met-indicator.component';

@Component({
  standalone: true,
  selector: 'parkour-jeugdhulp-historiek-list-item-card',
  templateUrl: './jeugdhulp-historiek-list-item-card.component.html',
  imports: [
    ParkourNewIndicatorComponent,
    ParkourImageComponent,
    DatePipe,
    ParkourActionComponent,
    GedeeldMetIndicatorComponent,
  ],
})
export class JeugdhulpHistoriekListItemCardComponent {
  @Input({ required: true }) eyouthGebeurtenis!: Gebeurtenis;
  @Input() ongelezenEyouthGebeurtenisMeldingen: Array<
    NieuwEyouthGebeurtenisMelding | JeugdhulpHistoriekGedeeldMelding
  > = [];

  isLockedPopupOpen = false;

  shouldShowNewIndicator(): boolean {
    return (
      this.ongelezenEyouthGebeurtenisMeldingen.find(
        (melding) => melding.metaData.gebeurtenisId === this.eyouthGebeurtenis.id,
      ) !== undefined
    );
  }
}
