<div class="flex items-center gap-x-2">
  <span class="sr-only">{{ 'berichten.gesprek-page.voicemessage' | translate: { duration } }}</span>
  <parkour-button
    (clicked)="playPause()"
    [ariaLabel]="
      isAudioPlaying ? ('buttons.pause-audio' | translate) : ('buttons.play-audio' | translate)
    "
    [icon]="isAudioPlaying ? 'pause' : 'play'"
    class="flex"
    format="iconButton"
    size="none"
    variant="ghost"
  />

  <div class="relative w-full flex-1">
    <parkour-progress [progress]="audioProgress" />
  </div>

  <span class="typo-body variant-body-small">({{ duration }})</span>
</div>
