import { Component, ViewChild } from '@angular/core';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  ViewWillEnter,
} from '@ionic/angular/standalone';
import { ParkourPageGradientComponent, ParkourToastService } from '@parkour/ui';
import { CoreModule } from '../../../core/core.module';
import { NotitieFormComponent } from '../../component/notitie-form/notitie-form.component';
import { NotitieUpsert } from '../../model/notitie';
import { BewaardeItemsService } from '../../service/bewaarde-items.service';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { ParkourBackButtonDirective } from '../../../shared/directives/parkour-back-button.directive';
import { ParkourHeaderActionComponent } from '../../../shared/components/parkour-header-action/parkour-header-action.component';
import { PageLayoutComponent } from '../../../shared/components/page-layout/page-layout.component';

@Component({
  selector: 'parkour-notitie-add-page',
  standalone: true,
  imports: [
    CoreModule,
    NotitieFormComponent,
    ParkourBackButtonDirective,
    ParkourPageGradientComponent,
    ParkourHeaderActionComponent,
    PageLayoutComponent,
    IonBackButton,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonTitle,
    IonContent,
  ],
  templateUrl: './notitie-add.page.html',
})
export class NotitieAddPage implements ViewWillEnter {
  @ViewChild('notitieForm') notitieFormComponent: NotitieFormComponent | undefined;

  constructor(
    private readonly bewaardeItemService: BewaardeItemsService,
    private readonly location: Location,
    private readonly route: ActivatedRoute,
    private readonly toastService: ParkourToastService,
  ) {}

  ionViewWillEnter(): void {
    const titel = this.route.snapshot.queryParamMap.get('titel') || '';
    const beschrijving = this.route.snapshot.queryParamMap.get('beschrijving') || '';
    if (this.notitieFormComponent) {
      this.notitieFormComponent.initialState = { titel, beschrijving };
    }
  }

  onNotitieSubmit(notitieUpsert: NotitieUpsert) {
    this.notitieFormComponent?.notitieForm?.markAllAsTouched();
    if (this.notitieFormComponent?.notitieForm?.valid) {
      this.bewaardeItemService.addNotitie(notitieUpsert).subscribe(() => {
        this.toastService.showToast({ header: 'Notitie bewaard' });
        this.notitieFormComponent?.notitieForm?.reset();
        this.location.back();
      });
    }
  }

  isFormDirty() {
    return this.notitieFormComponent?.notitieForm?.dirty || false;
  }
}
