<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button parkourBackButton />
    </ion-buttons>

    <ion-title aria-level="1" role="heading"
      >{{ 'profiel.mijn-profiel-edit-page.title' | translate }}
    </ion-title>

    <ion-buttons slot="end">
      @if (profiel$ | async) {
        <parkour-header-action
          [label]="'profiel.mijn-profiel-edit-page.profiel-save' | translate"
          form="profiel-form"
          icon="disk"
          testId="submit"
          type="submit"
        />
      }
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <parkour-page-layout>
    <ng-container *ngIf="profiel$ | async as profiel">
      <parkour-profile-details-edit
        (formSubmit)="onSubmit($event)"
        (verwijderingStopzettenClick)="onVerwijderingStopzettenClicked()"
        [profiel]="profiel"
        formId="profiel-form"
      />
    </ng-container>
  </parkour-page-layout>
</ion-content>
