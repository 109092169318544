import { Component, Input } from '@angular/core';
import { SidebarWisselTeamPopoverComponent } from '../sidebar-wissel-team-popover/sidebar-wissel-team-popover.component';
import { Context } from '../../../model/context';
import { ContextOption } from '../../../../profiel/model/context-option';
import { AsyncPipe } from '@angular/common';
import { MeldingenService } from '../../../../profiel/service/meldingen.service';
import { TranslateModule } from '@ngx-translate/core';
import { ParkourButtonComponent, ParkourNewIndicatorComponent } from '@parkour/ui';

@Component({
  selector: 'parkour-sidebar-voor-mezelf-context-card',
  standalone: true,
  imports: [
    SidebarWisselTeamPopoverComponent,
    AsyncPipe,
    TranslateModule,
    ParkourNewIndicatorComponent,
    ParkourButtonComponent,
  ],
  templateUrl: './sidebar-voor-mezelf-context-card.component.html',
})
export class SidebarVoorMezelfContextCardComponent {
  @Input({ required: true }) context!: Context;
  @Input({ required: true }) mijnContexten!: ContextOption[];

  isTeamSwitchPopoverOpen = false;
  wisselTeamIndicator$ = this.meldingenService.hasWisselTeamMeldingen();

  constructor(private readonly meldingenService: MeldingenService) {}
}
