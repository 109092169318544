import { IdOf } from '../branded-type';
import { GebeurtenisSuggestieDto, SuggestieId } from './gebeurtenis-suggestie.dto';
import { DeelMode } from './delen.dto';
import { ApiProperty } from '@nestjs/swagger';
import { ProfielId } from './profiel.dto';
import { Thema } from './thema.dto';

export type DoelId = IdOf<'doel'>;

export class ActieDto {
  readonly naam!: string;
  readonly voltooid!: boolean;
  readonly order!: number;
}

export class DoelUpsertDto {
  readonly naam!: string;
  readonly omschrijving!: string;
  @ApiProperty({ type: String })
  readonly thema!: Thema;
  readonly streefDatum?: string;
  readonly acties!: ActieDto[];
  @ApiProperty({ type: [String] })
  readonly gedeeldMet!: ProfielId[];
  @ApiProperty({ type: String })
  readonly deelMode!: DeelMode;
  @ApiProperty({ type: String })
  readonly originSuggestieId?: SuggestieId;
}

export class DoelDto extends DoelUpsertDto {
  @ApiProperty({ type: String })
  readonly id!: DoelId;
  readonly aanmaakDatum!: string;
  @ApiProperty({ type: String })
  readonly eigenaarId!: ProfielId;
  readonly eindDatum?: string;
  readonly activeGebeurtenisSuggestie?: GebeurtenisSuggestieDto;
}
