<nav
  [ngClass]="classes"
  aria-label="paginatie"
  class="flex justify-center items-center gap-x-2"
>
  <parkour-button
    (clicked)="previous()"
    *ngIf="currentPage > 1"
    ariaLabel="vorige pagina"
    class="flex"
    format="iconButton"
    icon="chevron-left"
    size="medium"
    variant="ghost"
  ></parkour-button>

  <ul class="flex justify-center items-center gap-x-2">
    @for (page of totalPagesArray; track page; let index = $index) {
      <li
        *ngIf="
          index + 1 >= currentPage - maxSize &&
          index + 1 <= currentPage + maxSize
        "
      >
        <parkour-button
          (clicked)="selectPageNumber(index + 1)"
          *ngIf="currentPage !== index + 1; else active"
          [ariaLabel]="'ga naar pagina ' + (index + 1)"
          classes="min-w-[40px]"
          format="iconButton"
          size="medium"
          variant="tertiary"
        >
          {{ index + 1 }}
        </parkour-button>

        <ng-template #active>
          <parkour-button
            (clicked)="selectPageNumber(index + 1)"
            [ariaCurrent]="true"
            [ariaLabel]="'huidige pagina, pagina ' + (index + 1)"
            classes="min-w-[40px]"
            format="iconButton"
            size="medium"
          >
            {{ index + 1 }}
          </parkour-button>
        </ng-template>
      </li>
    } @empty {
      <span>1</span>
    }
    <li>
      <span class="typo-body variant-body-fat">van</span>
    </li>

    <li>
      <parkour-button
        (clicked)="selectPageNumber(totalPages)"
        *ngIf="currentPage !== totalPages; else active"
        [ariaLabel]="'ga naar pagina' + totalPages"
        classes="min-w-[40px]"
        format="iconButton"
        size="medium"
        variant="tertiary"
      >
        {{ totalPages }}
      </parkour-button>

      <ng-template #active>
        <parkour-button
          (clicked)="selectPageNumber(totalPages)"
          [ariaLabel]="'ga naar pagina ' + totalPages"
          classes="min-w-[40px]"
          format="iconButton"
          size="medium"
        >
          {{ totalPages }}
        </parkour-button>
      </ng-template>
    </li>
  </ul>

  <parkour-button
    (clicked)="next()"
    *ngIf="currentPage !== totalPages"
    ariaLabel="volgende pagina"
    class="flex"
    format="iconButton"
    icon="chevron-right"
    size="medium"
    variant="ghost"
  ></parkour-button>
</nav>
