import { Component, Input } from '@angular/core';
import { Gebeurtenis } from '../../model/gebeurtenis';
import { GebeurtenisGedeeldMelding } from 'parkour-web-app-dto';
import {
  ParkourActionComponent,
  ParkourIconComponent,
  ParkourNewIndicatorComponent,
} from '@parkour/ui';
import { GebeurtenisPreviewImageComponent } from '../gebeurtenis-preview-image/gebeurtenis-preview-image.component';
import { DatePipe } from '@angular/common';
import { GedeeldMetIndicatorComponent } from '../../../../delen/component/gedeeld-met-indicator/gedeeld-met-indicator.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  selector: 'parkour-gebeurtenis-list-item-card',
  templateUrl: './parkour-gebeurtenis-list-item-card.component.html',
  imports: [
    ParkourNewIndicatorComponent,
    GebeurtenisPreviewImageComponent,
    ParkourActionComponent,
    DatePipe,
    GedeeldMetIndicatorComponent,
    ParkourIconComponent,
    TranslateModule,
  ],
})
export class ParkourGebeurtenisListItemCardComponent {
  @Input({ required: true }) parkourGebeurtenis!: Gebeurtenis;
  @Input() ongelezenGebeurtenisMeldingen: Array<GebeurtenisGedeeldMelding> = [];

  isLockedPopupOpen = false;

  shouldShowNewIndicator() {
    return (
      this.ongelezenGebeurtenisMeldingen.find(
        (melding) => melding.metaData.gebeurtenisId === this.parkourGebeurtenis.id,
      ) !== undefined
    );
  }
}
