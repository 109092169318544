<ion-header></ion-header>

<ion-content>
  <ion-refresher [parkourRefresh]="onPageRefresh" slot="fixed">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>
  <parkour-page-layout>
    <parkour-page-with-hero
      [title]="'doelen.doelen-page.title' | translate"
      [heroImage]="doelHeroSources"
    >
      <div class="container-1-col space-y-8">
        <p class="text-center">{{ 'doelen.doelen-page.not-aangemeld-text' | translate }}</p>

        <parkour-aanmeld-button [redirectAfterLogin]="'/app/me/doelen'" class="block" />

        <parkour-doelen-placeholder />
      </div>
    </parkour-page-with-hero>
  </parkour-page-layout>
</ion-content>
