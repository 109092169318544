@if (gebeurtenis.fotoId) {
  <parkour-image
    alt=""
    class="block mb-4"
    classes="aspect-video rounded-sm overflow-hidden"
    [src]="{ id: gebeurtenis.id, fotoId: gebeurtenis.fotoId } | gebeurtenisImage: 'MEDIUM' | async"
  />
} @else if (gebeurtenis.thema) {
  <parkour-picture
    alt=""
    class="block mb-4 aspect-video rounded-sm overflow-hidden"
    [sources]="themaMapping[gebeurtenis.thema].largeImage.sources"
  />
}
