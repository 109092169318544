@if (cookiesEnabled) {
  @switch (content.content.type) {
    @case ('youtube') {
      <youtube-player [videoId]="videoId ?? ''" class="video" [playerVars]="playerVars" />
    }
    @case ('vimeo') {
      <div #vimeoPlayer class="video"></div>
    }
  }
} @else {
  <parkour-info-card-with-title
    [title]="'watwat.artikel-page.cookie-info-card.title' | translate"
    [description]="'watwat.artikel-page.cookie-info-card.description' | translate"
  >
    <parkour-button (clicked)="onOpenCookieVoorkeuren()" size="none" variant="ghost-light">
      {{ 'watwat.artikel-page.cookie-info-card.open-voorkeuren-button' | translate }}
    </parkour-button>
  </parkour-info-card-with-title>
}
