import { Component, viewChild } from '@angular/core';
import { ParkourBackButtonDirective } from '../../../shared/directives/parkour-back-button.directive';
import { ParkourHeaderActionComponent } from '../../../shared/components/parkour-header-action/parkour-header-action.component';
import {
  ParkourImageComponent,
  ParkourInfoCardComponent,
  ParkourLoadingSpinnerComponent,
  ParkourPageImageComponent,
} from '@parkour/ui';
import { AsyncPipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { PageLayoutComponent } from '../../../shared/components/page-layout/page-layout.component';
import { PageWithHeroComponent } from '../../../shared/components/page-with-hero/page-with-hero.component';
import { JeugdhulpHistoriekFormComponent } from '../../verhaal-shared/component/jeugdhulp-historiek-form/jeugdhulp-historiek-form.component';
import { ContentUnavailableCardComponent } from '../../../shared/components/content-unavailable-card/content-unavailable-card.component';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';
import { PageContentDirective } from '../../../shared/directives/page-content.directive';
import { ShowIfSuccesfulDirective } from '../../../shared/directives/show-if-succesful.directive';
import { GebeurtenisEditPage } from '../gebeurtenis-edit-page/gebeurtenis-edit.page';
import { DirtyFormCheck } from '../../../shared/components/dirty-form-guard';
import { verhaalHeroSources } from '../../constants';

@Component({
  standalone: true,
  templateUrl: './jeugdhulp-historiek-edit-page.component.html',
  imports: [
    ParkourBackButtonDirective,
    ParkourHeaderActionComponent,
    ParkourPageImageComponent,
    AsyncPipe,
    TranslateModule,
    PageLayoutComponent,
    PageWithHeroComponent,
    ParkourImageComponent,
    JeugdhulpHistoriekFormComponent,
    ParkourInfoCardComponent,
    ContentUnavailableCardComponent,
    ParkourLoadingSpinnerComponent,
    IonHeader,
    IonContent,
    IonToolbar,
    IonButtons,
    IonTitle,
    IonBackButton,
    PageContentDirective,
    ShowIfSuccesfulDirective,
  ],
})
export class JeugdhulpHistoriekEditPage extends GebeurtenisEditPage implements DirtyFormCheck {
  gebeurtenisForm = viewChild(JeugdhulpHistoriekFormComponent);
  protected readonly verhaalHeroSources = verhaalHeroSources;

  isFormDirty() {
    return this.gebeurtenisForm()?.eyouthGebeurtenisUpdateForm.dirty ?? false;
  }
}
