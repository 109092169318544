<picture>
  <ng-container *ngIf="sources.lg">
    <!-- > 975px (tailwind: lg) -->
    <source [srcset]="sources.lg.src" media="(min-width:60.9375em)" />
    <!-- Fallback for when webp is not supported -->
    <source
      *ngIf="sources.lg.fallback"
      [srcset]="sources.lg.fallback"
      media="(min-width:60.9375em)"
    />
  </ng-container>

  <ng-container *ngIf="sources.md">
    <!-- > 640px (tailwind: md) -->
    <source [srcset]="sources.md.src" media="(min-width:40em)" />
    <!-- Fallback for when webp is not supported -->
    <source
      *ngIf="sources.md.fallback"
      [srcset]="sources.md.fallback"
      media="(min-width:60.9375em)"
    />
  </ng-container>

  <!-- > 0 (tailwind: default) -->
  <source [srcset]="sources.default.src" />
  <!-- Fallback for when webp is not supported -->
  <source
    *ngIf="sources.default.fallback"
    [srcset]="sources.default.fallback"
  />

  <img
    [alt]="alt"
    [attr.fetchpriority]="fetchPriority"
    [ngClass]="{
      'h-full': fit === 'contain' || fit === 'cover',
      'object-contain': fit === 'contain',
      'object-cover': fit === 'cover',
      'object-center': position === 'center',
      'object-top': position === 'top',
      'object-bottom': position === 'bottom',
      'rounded-[100vmax]': rounded,
    }"
    [src]="
      sources.default.fallback ? sources.default.fallback : sources.default.src
    "
    class="w-full"
    loading="lazy"
  />
</picture>
