import { Component, Input } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { HelplineCardItemComponent } from '../../../home/component/helpline-card-item/helpline-card-item.component';
import { BookmarkId } from 'parkour-web-app-dto';
import { WatwatService } from '../../../shared/services/watwat.service';
import { Observable } from 'rxjs';
import { CoreModule } from '../../../core/core.module';
import { ParkourBlockPlaceholderComponent, ParkourButtonComponent } from '@parkour/ui';
import { OrganisationWithBookmark } from '../../../shared/model/artikel';

@Component({
  selector: 'parkour-bewaarde-hulplijnen',
  standalone: true,
  imports: [
    AsyncPipe,
    HelplineCardItemComponent,
    CoreModule,
    ParkourButtonComponent,
    ParkourBlockPlaceholderComponent,
  ],
  templateUrl: './bewaarde-hulplijnen.component.html',
})
export class BewaardeHulplijnenComponent {
  @Input({ required: true }) active!: boolean;
  readonly organisationBookmarks$: Observable<OrganisationWithBookmark[]> =
    this.watwatService.getBookmarksWithOrganisation();

  constructor(private readonly watwatService: WatwatService) {}

  onClickedOrganisationBookmark(hasBookmarked: boolean, bookmarkId: BookmarkId) {
    if (!hasBookmarked && bookmarkId) {
      this.watwatService.deleteOrganisationBookmark(bookmarkId).subscribe();
    }
  }

  protected readonly String = String;
}
