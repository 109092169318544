import { Component, EventEmitter, Input, Output } from "@angular/core";
import {ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR} from '@angular/forms';
import { Haptics, ImpactStyle } from "@capacitor/haptics";
import { isNativeApp } from "../utils";
import { IonToggle } from "@ionic/angular/standalone";

@Component({
  selector: "parkour-toggle",
  templateUrl: "./parkour-toggle.component.html",
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ParkourToggleComponent,
    },
  ],
  imports: [IonToggle, FormsModule],
})
export class ParkourToggleComponent implements ControlValueAccessor {
  @Input() public classes?: string;
  @Input() public disabled = false;
  @Input() public checked = false;
  @Input({ required: true }) public ariaLabel!: string;

  @Output() toggleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public onChange?: (value: boolean) => void;
  public onTouched?: () => void;

  onToggleChange() {
    if (isNativeApp()) {
      Haptics.impact({ style: ImpactStyle.Light });
    }
    this.toggleChange.emit(this.checked);
    this.onChange?.(this.checked);
  }

  writeValue(value: boolean): void {
    if (value != null) {
      this.checked = value;
      this.onChange?.(value);
    }
  }

  registerOnChange(onChange: (value: boolean) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }
}
