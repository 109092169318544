import { ReactieDto, ReactieOverviewDto, ReactiesDto } from 'parkour-web-app-dto';

export class InteractieOverview {
  constructor(
    private reacties: ReactiesDto,
    private myReactie: ReactieDto | undefined,
  ) {}

  static fromDto(dto: ReactieOverviewDto): InteractieOverview {
    return new InteractieOverview(dto.reacties, dto.myReaction ? dto.myReaction : undefined);
  }

  getReacties(): ReactiesDto {
    return this.reacties;
  }

  getMyInteractie(): ReactieDto | undefined {
    return this.myReactie;
  }

  getTotalAmountOfReacties(): number {
    return this.reacties.length;
  }
}
