import { Component, input, Input, output } from '@angular/core';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { PermissionService } from '../../services/permission.service';
import { isNativeApp } from '../../../utils';
import { HumanReadableError } from '../../../core/human-readable-error';
import { TranslateService } from '@ngx-translate/core';
import { CoreModule } from '../../../core/core.module';
import {
  ParkourButtonComponent,
  ParkourImageComponent,
  ParkourLoadingSpinnerComponent,
} from '@parkour/ui';
import { SecureImagePipe } from '../../pipes/secure-image.pipe';
import { LoggingService } from '../../../core/logging.service';

@Component({
  standalone: true,
  selector: 'parkour-custom-foto-edit-modal',
  templateUrl: './custom-foto-edit-modal.component.html',
  imports: [
    CoreModule,
    ParkourImageComponent,
    ImageCropperComponent,
    ParkourLoadingSpinnerComponent,
    ParkourButtonComponent,
    SecureImagePipe,
  ],
})
export class CustomFotoEditModalComponent {
  imageSrc = input.required<string | null>();
  @Input() aspectRatio: number = 1;
  fotoSubmit = output<Blob>();

  isImageCropperHidden = true;
  imageToCrop: string | undefined = '';
  imageFormat: string = '';
  blob: Blob | null | undefined;
  protected readonly isNativeApp = isNativeApp();

  constructor(
    private readonly permissionService: PermissionService,
    private readonly loggingService: LoggingService,
    private readonly translateService: TranslateService,
  ) {}

  @Input() set clearCropper(clearCropper: boolean) {
    if (clearCropper) {
      this.imageToCrop = '';
      this.imageFormat = '';
    }
  }

  async handleClickOpenImageLibrary() {
    await this.permissionService.withPermissions(
      Camera,
      'photos',
      async () => {
        try {
          const capturedPhoto = await Camera.getPhoto({
            resultType: CameraResultType.Base64,
            source: CameraSource.Photos,
            quality: 100,
          });
          this.imageToCrop = capturedPhoto.base64String;
          this.imageFormat = capturedPhoto.format;
        } catch (e) {
          this.loggingService.log('Closed phone camera', e);
        }
      },
      {
        errorTitle: 'PARKOUR wil je fotobibliotheek gebruiken',
        errorDescription:
          'Geef toestemming voor toegang tot je fotobibliotheek in je instellingen.',
      },
    );
  }

  async handleClickTakeSelfie() {
    await this.permissionService.withPermissions(
      Camera,
      'camera',
      async () => {
        try {
          const capturedPhoto = await Camera.getPhoto({
            resultType: CameraResultType.Base64,
            source: CameraSource.Camera,
            quality: 100,
          });

          this.imageToCrop = capturedPhoto.base64String;
          this.imageFormat = capturedPhoto.format;
        } catch (e) {
          this.loggingService.log('Closed phone camera', e);
        }
      },
      {
        errorTitle: 'PARKOUR wil je camera gebruiken',
        errorDescription: 'Geef toestemming voor toegang tot je camera in je instellingen.',
      },
    );
  }

  imageLoaded() {
    this.isImageCropperHidden = false;
  }

  loadImageFailed() {
    this.translateService.get('file.load-image-failed').subscribe((label) => {
      throw new HumanReadableError(label);
    });
  }

  imageCropped(event: ImageCroppedEvent) {
    const { blob } = event;

    if (!blob || !event.objectUrl) {
      throw new Error('Image could not be cropped');
    }

    this.blob = blob;
  }

  onFotoSubmit() {
    if (!this.blob) {
      return;
    }

    this.fotoSubmit.emit(this.blob);
    this.imageToCrop = '';
    this.isImageCropperHidden = true;
    this.imageFormat = '';
  }
}
