import { Component, Input } from "@angular/core";

@Component({
  standalone: true,
  selector: "parkour-divider",
  templateUrl: "./parkour-divider.component.html",
})
export class ParkourDividerComponent {
  @Input() public classes?: string;

  identifier: number | null = null;

  constructor() {
    this.identifier = Math.random();
  }

  getLinearGradientId() {
    return `parkour-divider-linear-gradient-${this.identifier}`;
  }

  getLinearGradientUrl() {
    return `url(#${this.getLinearGradientId()})`;
  }
}
