<form enctype="multipart/form-data">
  <parkour-form-field inputId="register-input-bijlagen" label="Bijlagen">
    <ul class="divide-y-[1.5px] divide-n-300">
      @for (file of currentFiles; track file.naam) {
        <li>
          <parkour-gebeurtenis-attachment-upload
            [file]="file"
            (remove)="removeFile(file)"
            (download)="onDownload(file)"
          />
        </li>
      }
    </ul>

    <input
      #fileInput
      (change)="onFileSelected($event)"
      class="sr-only"
      id="register-input-bijlagen"
      multiple
      type="file"
    />

    <parkour-button
      (clicked)="openFileInput()"
      class="mt-2 block"
      iconBefore="plus-circle"
      size="none"
      variant="ghost"
    >
      {{ 'buttons.add-attachment' | translate }}
    </parkour-button>
  </parkour-form-field>
</form>
