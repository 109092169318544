@if (currentImage$ | async; as currentImage) {
  <div class="container-1-col">
    <div class="flex justify-center gap-4">
      @if (currentImage.type !== 'no-image') {
        <parkour-button
          (clicked)="clearPicture()"
          iconAfter="trash"
          size="small"
          variant="tertiary"
        >
          {{ 'buttons.delete-picture' | translate }}
        </parkour-button>

        <parkour-button (clicked)="onAddPicture()" iconAfter="edit" size="small">
          {{ 'buttons.edit-picture' | translate }}
        </parkour-button>
      } @else {
        @switch (mode) {
          @case ('edit') {
            <parkour-button (clicked)="onAddPicture()" iconAfter="edit" size="small">
              {{ 'buttons.edit-picture' | translate }}
            </parkour-button>
          }
          @case ('create') {
            <parkour-button (clicked)="onAddPicture()" iconAfter="plus-circle" size="small">
              {{ 'buttons.add-picture' | translate }}
            </parkour-button>
          }
        }
      }
    </div>
  </div>

  <parkour-modal
    (hasBeenDismissed)="onHasBeenDismissed()"
    [title]="'verhaal.edit-picture' | translate"
    icon="images"
  >
    <parkour-custom-foto-edit-modal
      (fotoSubmit)="onPictureSubmit($event)"
      [aspectRatio]="aspectRatio"
      [clearCropper]="clearCropper"
      [imageSrc]="imageBase64$ | async"
      content
    />
  </parkour-modal>
}
