import { GetPageFunction, Pagination } from './parkour-pagination';
import { ParkourPage } from './parkour-page';
import { InfiniteScrollCustomEvent, RefresherCustomEvent } from '@ionic/angular/standalone';
import { merge, shareReplay, Subject, switchMap, tap } from 'rxjs';
import { LoggingService } from '../core/logging.service';
import { errorToFailureType, FailureType } from '../utils';

export abstract class ParkourPaginatedPage<T, ExtraData> extends ParkourPage {
  private readonly dataRefresh$ = new Subject<RefresherCustomEvent | undefined>();
  protected loading = false;
  protected error?: FailureType;

  constructor(private readonly loggingService: LoggingService) {
    // injecting using Inject only works within angular component
    super();
  }

  protected override refreshPageDataOnly = (event?: RefresherCustomEvent) => {
    this.dataRefresh$.next(event);
    this.error = undefined;
  };

  protected abstract retrievePage: GetPageFunction<T, ExtraData>;
  private currentPagination: Pagination<T, ExtraData> | undefined;

  protected abstract getInitialExtraData(): ExtraData;

  private createPagination(onError: () => void): Pagination<T, ExtraData> {
    this.currentPagination = new Pagination(
      this.retrievePage,
      (e) => {
        this.error = errorToFailureType(e);
        onError();
        this.loggingService.error('Error fetching page', e);
      },
      this.getInitialExtraData(),
    );
    return this.currentPagination;
  }

  public readonly paginatedData$ = merge(this.dataRefresh$, this.pageWillEnter$).pipe(
    switchMap((event) =>
      this.createPagination(() => event?.detail.complete()).pipe(
        tap(() => event?.detail.complete()),
      ),
    ),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  fetchNextPage(event: InfiniteScrollCustomEvent) {
    if (this.currentPagination === undefined) {
      throw new Error('No pagination available');
    }

    this.loading = true;
    this.currentPagination.fetchNextPage(() => {
      this.loading = false;
      event.target.complete();
    });
  }

  removeElementsFromCollection(condition: (element: T) => boolean) {
    if (this.currentPagination === undefined) {
      throw new Error('No pagination available');
    }

    this.currentPagination.removeElementsFromCollection(condition);
  }
}
