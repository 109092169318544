import { BrandedType } from '../branded-type';
import { ApiProperty } from '@nestjs/swagger';
import { Page } from './page.dto';

export type NotitieId = BrandedType<string, 'notitie'>;
export function validateNotitieId(id?: string | null): NotitieId {
  if (!id) {
    throw new Error('id is undefined');
  }

  return id as NotitieId;
}

export class NotitieUpsertDto {
  readonly titel!: string;
  readonly beschrijving?: string;
}

export class NotitieDto {
  @ApiProperty({ type: String })
  readonly id!: NotitieId;
  readonly titel!: string;
  readonly beschrijving!: string;
}

export class NotitiePageDto implements Page<NotitieDto> {
  readonly content!: NotitieDto[];
  readonly totalPages!: number;
  readonly number!: number;
  readonly last!: boolean;
}
