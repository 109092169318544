export const environment = {
  production: true,
  API_BASE_URL: 'https://parkour-test.opgroeien.be',
  watWatBaseUrl: 'https://www.watwat.be',
  globalHeaderEmbedCode:
    'https://tni.widgets.burgerprofiel.dev-vlaanderen.be/api/v1/widget/275a984a-0a80-456d-9204-97e1a22d446d',
  jitsiBaseUrl: 'jitsi.parkour-test.opgroeien.be',
  jitsiApiEmbedCode: 'https://jitsi.parkour-test.opgroeien.be/external_api.js',
  ongelezenMeldingenInterval: 30000,
  opentelemetry: {
    enabled: true,
    tracingUrl: 'https://otel.parkour-test.opgroeien.be/v1/traces',
  },
  logging: 'debug',
  matomo: {
    enabled: true,
    trackerUrl: 'https://kunlabora.matomo.cloud',
    siteId: 2,
    dimensions: {
      profielType: 1,
      contextType: 2,
    },
  },
  mockGlobalHeader: false,
  debugOptionsEnabled: true,
  showDebugErrors: true,
};
