<div
        class="container-1-col bg-n-200 rounded border-[1.5px] border-n-500 overflow-hidden max-h-[100vh] overflow-y-auto p-4 flex flex-col gap-y-6 my-[calc(var(--ion-safe-area-top)+1rem)]"
>
    <div class="flex items-start justify-between gap-x-2">
        <div class="flex items-start gap-x-2">
            @if (icon) {
                <parkour-icon
                        [name]="icon"
                        class="sticky top-0 text-n-600"
                        size="3"
                />
            }

            <div>
                <div>
                    <p class="typo-body variant-body-fat">{{ title }}</p>

                    <p>{{ description }}</p>
                </div>
            </div>
        </div>

        <parkour-button
                (clicked)="onDismiss()"
                ariaLabel="sluiten"
                class="sticky top-0"
                format="iconButton"
                icon="cross"
                iconSize="3"
                size="medium"
                variant="ghost"
        />
    </div>

    <div class="empty:hidden">
        <div class="flex items-center gap-x-4">
            <parkour-button
                    (clicked)="onNeeClick()"
                    class="w-full"
                    variant="tertiary"
            >
                {{ neeText }}
            </parkour-button>

            <parkour-button (clicked)="onJaClick()" class="w-full">
                {{ jaText }}
            </parkour-button>
        </div>
    </div>
</div>
