import { Injectable } from '@angular/core';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Capacitor } from '@capacitor/core';
import { BarcodeScanner } from '@capacitor-mlkit/barcode-scanning';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { isNativeApp } from '../utils';
import { LoggingService } from './logging.service';

@Injectable({
  providedIn: 'root',
})
export class NativeService {
  constructor(private readonly loggingService: LoggingService) {}

  public initialize() {
    if (isNativeApp()) {
      this.setupBarcodeScanner();
      this.setupDownloadsFolder();
      this.setupStatusBar();
    }
  }

  private setupStatusBar() {
    StatusBar.setStyle({ style: Style.Dark });
  }

  private setupBarcodeScanner() {
    if (Capacitor.getPlatform() === 'android') {
      BarcodeScanner.isGoogleBarcodeScannerModuleAvailable().then((result) => {
        if (!result.available) {
          BarcodeScanner.installGoogleBarcodeScannerModule();
        }
      });
    }
  }

  private setupDownloadsFolder() {
    Filesystem.mkdir({
      path: 'PARKOUR',
      directory: Directory.Documents,
    }).catch((e) => this.loggingService.error('Error creating downloads folder', e));
  }
}
