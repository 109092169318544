import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { BerichtId, DoelId, GesprekId } from 'parkour-web-app-dto';
import { BerichtenService } from '../../service/berichten.service';
import { BerichtFooterComponent } from '../bericht-footer/bericht-footer.component';
import { OpenGesprek } from '../../model/OpenGesprek';
import { AangemeldeUser } from '../../../authentication/user';
import { GesprekContainerComponent } from '../gesprek-container/gesprek-container-component';
import { map, Subject, takeUntil } from 'rxjs';
import {
  ParkourButtonComponent,
  ParkourLoadingSpinnerComponent,
  ParkourNewIndicatorComponent,
  ParkourPopupService,
} from '@parkour/ui';
import { PusherService } from '../../service/pusher.service';
import { TemplatePortal } from '@angular/cdk/portal';
import { SheetService } from '../../../shared/services/parkour-sheet.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MeldingenService } from '../../../profiel/service/meldingen.service';
import { meldingenForPage } from '../../../meldingen/config';
import { AsyncPipe, NgClass } from '@angular/common';
import { BerichtenComponent } from '../berichten/berichten.component';
import { BerichtenDataPipe } from '../../pipes/berichten-data.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { IonContent, IonInfiniteScroll } from '@ionic/angular/standalone';
import { LoggingService } from '../../../core/logging.service';
import { Profiel } from '../../../profiel/model/profiel';
import { AnalyticsService } from '../../../analytics/analytics.service';

@Component({
  standalone: true,
  selector: 'parkour-doel-berichten',
  templateUrl: './doel-berichten.component.html',
  imports: [
    ParkourButtonComponent,
    NgClass,
    ParkourLoadingSpinnerComponent,
    BerichtenComponent,
    BerichtenDataPipe,
    BerichtFooterComponent,
    TranslateModule,
    ParkourNewIndicatorComponent,
    AsyncPipe,
    IonContent,
    IonInfiniteScroll,
  ],
})
export class DoelBerichtenComponent extends GesprekContainerComponent implements AfterViewInit {
  @ViewChild('berichtFooter') berichtFooter!: BerichtFooterComponent;
  @ViewChild('chatFlow') chatFlow!: ElementRef;
  @ViewChild('chatFlowScroll') chatFlowScroll!: ElementRef;
  @ViewChild('chatAnchor') chatAnchor!: ElementRef;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @ViewChild('modalTemplate') modalTemplate!: TemplateRef<any>;

  isBerichtPopoverOpened = false;
  sheetId = 'reacties-sheet';
  @Input({ required: true }) gesprek!: OpenGesprek;
  @Input({ required: true }) user!: AangemeldeUser;
  @Input({ required: true }) profiel!: Profiel;
  @Input({ required: true }) doelId!: DoelId;
  @Output() openReactiesClicked = new EventEmitter<void>();
  ongelezenDoelReactieMelding$ = this.meldingenService.getMeldingenInCurrentContextByType(
    meldingenForPage.DOELEN_TAB.DOELEN_PAGE.DOEL_PAGE.DOEL_PAGE_GESPREK.allMeldingen,
  );
  hasOngelezenDoelMeldingen$ = this.ongelezenDoelReactieMelding$.pipe(
    map(
      (meldingen) =>
        meldingen.filter((melding) => melding.metaData.gesprekId === this.gesprek.id).length > 0,
    ),
  );

  sheetOpen$ = this.sheetService.isOpen;

  private ngUnsubscribe = new Subject<void>();

  constructor(
    berichtenService: BerichtenService,
    parkourPopupService: ParkourPopupService,
    pusherService: PusherService,
    activatedRoute: ActivatedRoute,
    router: Router,
    loggingService: LoggingService,
    analyticsService: AnalyticsService,
    private route: ActivatedRoute,
    private readonly viewContainerRef: ViewContainerRef,
    private readonly sheetService: SheetService,
    private readonly injector: Injector,
    private readonly meldingenService: MeldingenService,
  ) {
    super(
      berichtenService,
      parkourPopupService,
      pusherService,
      router,
      activatedRoute,
      loggingService,
      analyticsService,
    );
  }

  public ngAfterViewInit(): void {
    this.pageViewWillEnter(); // Initial load because it will not receive viewWillEnter from parent due to async data loading
  }

  getPortal(): TemplatePortal {
    return new TemplatePortal(
      this.modalTemplate,
      this.viewContainerRef,
      {
        id: this.sheetId,
        title: 'Reacties',
        icon: 'bubbles',
      },
      this.injector,
    );
  }

  public pageViewWillEnter() {
    this.fetchInitialPage();
    this.subscribeOnBerichtenEvents(this.pusherService.createPusherChannel(this.gesprek.channel));

    this.sheetOpen$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.meldingenService.markMeldingenInCurrentContextAsReadWithTypes(
        meldingenForPage.DOELEN_TAB.DOELEN_PAGE.DOEL_PAGE.DOEL_PAGE_GESPREK.specificMeldingen,
        (melding) => melding.metaData.gesprekId === this.gesprek.id,
      );
    });
  }

  public pageViewWillLeave() {
    this.sheetService.close();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.unsubscribeFromBerichtenEvents();
  }

  override get gesprekId(): GesprekId {
    return this.gesprek.id;
  }

  openDoelReacties() {
    this.openReactiesClicked.emit();
  }

  onBerichtMessagePopoverOpened() {
    this.isBerichtPopoverOpened = true;
  }

  onBerichtMessagePopoverClosed() {
    this.isBerichtPopoverOpened = false;
  }

  onRapporteer(event: { gesprekId: GesprekId; berichtId: BerichtId }) {
    this.isBerichtPopoverOpened = false;
    this.router.navigate(['probleem'], {
      relativeTo: this.route,
      queryParams: {
        berichtId: event.berichtId,
        gesprekId: event.gesprekId,
        doelId: this.doelId,
      },
    });
  }
}
