<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button parkourBackButton />
    </ion-buttons>

    <ion-title aria-level="1" role="heading"
      >{{ 'berichten.groepsgesprek-add-page.title' | translate }}
    </ion-title>

    <ion-buttons *ngIf="mainPageData$ | async" slot="end">
      <parkour-header-action
        [label]="'berichten.groepsgesprek-add-page.groepsgesprek-start' | translate"
        form="groepsgesprek-add"
        icon="paper-plane"
        testId="submit"
        type="submit"
      />
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <parkour-page-layout>
    <ng-container *parkourPageContent="mainPageData$ | async; refresh: onPageRefresh; let data">
      <div class="relative z-10 pt-4 pb-8 container container-space">
        <div class="container-1-col flex flex-col gap-y-8">
          <parkour-info-card *ngIf="data.context.type === 'teamlid'">
            {{ 'berichten.groepsgesprek-add-page.info-teameigenaar' | translate }}
          </parkour-info-card>

          <div class="flex items-center gap-x-4 overflow-hidden">
            <parkour-icon class="flex items-center text-positive" name="check" />
            <parkour-avatar
              [image]="{
                type: 'image',
                src:
                  (data.context.type === 'jongere' ? data.profiel : data.context.jongereProfiel)
                  | profielFoto
                  | async,
              }"
              class="block max-w-[48px]"
            />
            <div class="flex flex-col gap-y-1">
              <p class="typo-h4">
                {{
                  (data.context.type === 'jongere' ? data.profiel : data.context.jongereProfiel)
                    | profielnaam
                }}
              </p>
              <p class="text-s-200">{{ 'profiel.teameigenaar' | translate }}</p>
            </div>
          </div>

          <div
            *ngIf="data.context.type === 'teamlid'"
            class="flex items-center gap-x-4 overflow-hidden"
          >
            <parkour-icon class="flex items-center text-positive" name="check" />
            <parkour-profiel-block [profielData]="data.profielInTeam" />
          </div>

          <parkour-groepsgesprek-form
            #groepsgesprekForm
            (formSubmitted)="onSubmit($event)"
            [context]="data.context"
            [currentProfiel]="data.profiel"
            [formId]="'groepsgesprek-add'"
            [geblokkeerdeTeamleden]="getBeblokkeerdeTeamleden(data.teamleden)"
            [teamleden]="getTeamledenOptions(data.teamleden, data.user)"
          />
        </div>
      </div>
    </ng-container>
    <parkour-page-gradient />
  </parkour-page-layout>
</ion-content>
