<ul [attr.aria-activedescendant]="activeEmojiKey" class="flex items-center gap-x-3" role="listbox">
  @for (emojiKey of emojis; track $index) {
    <li role="option" [attr.aria-selected]="emojiKey === activeEmojiKey" [id]="emojiKey">
      <parkour-action
        class="block w-[2.375rem] h-[2.375rem]"
        classes="relative z-10 w-full h-full hover:after:bg-n-500/50 after:content-[''] after:absolute after:top-0 after:left-0 after:w-full after:h-full after:z-[-1] after:rounded-[9999px] after:transition-colors"
        [ngClasses]="{
          'after:!bg-n-500': emojiKey === activeEmojiKey,
          'after:bg-transparent': emojiKey !== activeEmojiKey,
        }"
        (clicked)="onEmojiSelected(emojiKey)"
      >
        <span class="block typo-h3 translate-y-[1px]"> {{ emojiKey | parkourEmoji }} </span>
      </parkour-action>
    </li>
  }
</ul>
