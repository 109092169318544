import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ParkourCarouselDirective } from "./parkour-carousel.directive";

@NgModule({
  declarations: [ParkourCarouselDirective],
  imports: [CommonModule],
  exports: [ParkourCarouselDirective],
})
export class ParkourCarouselModule {}
