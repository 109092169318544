import { inject, Pipe, PipeTransform } from '@angular/core';
import {
  GedeeldeResourceBericht,
  Gesprek,
  isSupportedBericht,
} from '../../../bericht/model/gesprek';
import { map, Observable, of } from 'rxjs';
import { TranslatePipe } from '@ngx-translate/core';
import { ProfielnaamFromProfielidPipe } from '../../pipes/profielnaam-from-profielid.pipe';
import { videogesprekDuration } from '../../../bericht/pipes/videogesprek-duration.pipe';
import { BerichtenService } from '../../../bericht/service/berichten.service';

@Pipe({
  name: 'laatsteBerichtInhoud',
  standalone: true,
})
export class LaatsteBerichtInhoudPipe implements PipeTransform {
  constructor(
    private readonly translate: TranslatePipe,
    private readonly profielnaamFromProfielId: ProfielnaamFromProfielidPipe,
  ) {}

  private readonly berichtenService = inject(BerichtenService);

  transform(gesprek: Gesprek): Observable<string> {
    if (gesprek.laatsteBericht && isSupportedBericht(gesprek.laatsteBericht)) {
      switch (gesprek.laatsteBericht.type) {
        case 'GEMASKEERD':
          return of(this.translate.transform('berichten.gesprek-page.gemaskeerd'));
        case 'TEKST':
          return of(gesprek.laatsteBericht.inhoud);
        case 'SPRAAK':
          return of(
            this.translate.transform('berichten.gesprekken-overview-page.laatste-bericht-spraak'),
          );
        case 'ATTACHMENT':
          return this.berichtenService
            .getBestandMetadata(
              gesprek.id,
              gesprek.laatsteBericht.id,
              gesprek.laatsteBericht.bestandId,
            )
            .pipe(map((metadata) => metadata.name));
        case 'GEDEELDE_RESOURCE':
          return this.getGedeeldeResourceInhoud(gesprek.laatsteBericht);
        case 'EVENT':
          switch (gesprek.laatsteBericht.gesprekEvent.type) {
            case 'DEELNEMER_LEFT':
              return this.profielnaamFromProfielId
                .transform(gesprek.laatsteBericht.gesprekEvent.deelnemerId)
                .pipe(
                  map((profielNaam) =>
                    this.translate.transform(
                      'berichten.gesprekken-overview-page.laatste-bericht-deelnemer-left',
                      {
                        naam: profielNaam,
                      },
                    ),
                  ),
                );
            case 'DEELNEMER_REJOINED':
              return this.profielnaamFromProfielId
                .transform(gesprek.laatsteBericht.gesprekEvent.deelnemerId)
                .pipe(
                  map((profielNaam) =>
                    this.translate.transform(
                      'berichten.gesprekken-overview-page.laatste-bericht-deelnemer-rejoined',
                      { naam: profielNaam },
                    ),
                  ),
                );
            case 'VIDEOGESPREK_ENDED':
              return of(
                this.translate.transform(
                  'berichten.gesprekken-overview-page.laatste-bericht-videogesprek-ended',
                  {
                    duration: videogesprekDuration(
                      gesprek.laatsteBericht.gesprekEvent.startTimestamp,
                      gesprek.laatsteBericht.gesprekEvent.endTimestamp,
                    ),
                  },
                ),
              );
          }
      }
    } else {
      return of(
        this.translate.transform('berichten.gesprekken-overview-page.laatste-bericht-default'),
      );
    }
  }

  getGedeeldeResourceInhoud(laatsteBericht: GedeeldeResourceBericht): Observable<string> {
    switch (laatsteBericht.resourceType) {
      case 'ARTIKEL':
        return of(this.translate.transform('berichten.gesprekken-overview-page.artikel-shared'));
      case 'HULPLIJN':
        return of(this.translate.transform('berichten.gesprekken-overview-page.hulplijn-shared'));
    }
  }
}
