import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IParkourArticlePreviewCardImageProps } from './artikel-preview-card.types';
import { Thema } from 'parkour-web-app-dto';
import { themaMapping } from '../../model/thema.const';
import { ParkourActionComponent, ParkourImageComponent } from '@parkour/ui';
import { BookmarkButtonComponent } from '../../../shared/components/bookmark-button/bookmark-button.component';

@Component({
  standalone: true,
  selector: 'parkour-artikel-preview-card',
  templateUrl: './artikel-preview-card.component.html',
  imports: [ParkourActionComponent, ParkourImageComponent, BookmarkButtonComponent],
})
export class ArtikelPreviewCardComponent {
  @Input({ required: true }) public image!: IParkourArticlePreviewCardImageProps;
  @Input({ required: true }) public title!: string;
  @Input({ required: true }) public themes!: Thema[];
  @Input({ required: true }) public link!: string[];
  @Input() public isBookmarked: boolean = false;

  @Output() public clickedBookmark: EventEmitter<boolean> = new EventEmitter<boolean>();

  getThemaLabelString(themes: Thema[]) {
    return themes.map((theme) => themaMapping[theme].label).join(' | ');
  }

  onClickedBookmark(hasBookmarked: boolean) {
    this.clickedBookmark.emit(hasBookmarked);
  }
}
