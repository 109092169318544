import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class A11yConfig {
  public config() {
    let activetabindexOrderIndex = 0;

    document.addEventListener('keydown', (e: KeyboardEvent) => {
      if (e.key !== 'Tab') {
        return;
      }

      if (activetabindexOrderIndex <= 0) {
        const startFocusFlow: HTMLElement | null = document.querySelector('#start-focus');

        startFocusFlow?.focus();
      }

      activetabindexOrderIndex += 1;
    });
  }
}
