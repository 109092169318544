<div class="flex flex-col absolute top-0 left-0 h-full w-full bg-black/80">
  <ion-header class="w-full transform-none global-header-margin">
    <ion-toolbar>
      <ion-buttons slot="start">
        @if (getGespreksPartner(); as gespreksPartner) {
          @if (gespreksPartner.status === 'ACTIEF') {
            <ion-back-button parkourBackButton />
            <div class="flex items-center gap-x-2 ml-4">
              <parkour-profiel-foto-badge [alt]="false" [profiel]="gespreksPartner" [size]="'2'" />
              <div>
                <p class="typo-body variant-body-fat" data-testid="gesprekspartner-naam">
                  {{ gespreksPartner | profielnaam }}
                </p>

                @if (gespreksPartner.viewType === 'TEAMLID') {
                  <span class="typo-body variant-body-small text-p-300 flex align-center gap-x-1">
                    {{ gespreksPartner.rol | rolLabel }}

                    @if (gespreksPartner.type === 'PROFESSIONEEL_TEAMLID') {
                      <parkour-professioneel-indicator />
                    }
                  </span>
                }
              </div>
            </div>
          }
        }
      </ion-buttons>

      <ion-buttons slot="end">
        <parkour-header-action
          (clicked)="closeJitsiCall()"
          [label]="'buttons.sluiten' | translate"
          icon="cross"
        />
      </ion-buttons>
    </ion-toolbar>
  </ion-header>

  <div #jaasContainer class="flex-1"></div>
</div>
