import { Gesprek, JongereDeelnemer, TeamlidDeelnemer } from './gesprek';
import { getProfielnaam } from '../../profiel/model/profiel-in-team';

// TODO: UX Copy groepsgesprek in JSON
export class Groepsgesprek extends Gesprek {
  public override isGroepsgesprek(): boolean {
    return true;
  }

  public override getGearchiveerdMessage(): string {
    return 'Het groepsgesprek is stopgezet.';
  }
  public override getGesprekNaam(): string {
    return this.getDeelnemersAndJongere()
      .map((deelnemer) => getProfielnaam(deelnemer))
      .join(', ');
  }

  public override getSubtitleForHeader(): string {
    return 'Groepsgesprek';
  }

  public override getVisibleDeelnemer(): TeamlidDeelnemer | JongereDeelnemer {
    return this.jongere;
  }
}
