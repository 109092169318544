import { Component } from '@angular/core';
import { ParkourDataPage } from '../../../shared/parkour-data-page';
import { VerhaalDto } from 'parkour-web-app-dto';
import { VerhaalService } from '../../verhaal-shared/service/verhaal.service';
import { Observable } from 'rxjs';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';
import { ParkourRefreshDirective } from '../../../shared/directives/parkour-refresh-directive';
import { PageLayoutComponent } from '../../../shared/components/page-layout/page-layout.component';
import { PageWithHeroComponent } from '../../../shared/components/page-with-hero/page-with-hero.component';
import { TranslateModule } from '@ngx-translate/core';
import { PageSectionDirective } from '../../../shared/directives/page-section.directive';
import { AsyncPipe } from '@angular/common';
import { GebeurtenisErrorCardComponent } from '../../verhaal-shared/component/gebeurtenis-error-card/gebeurtenis-error-card.component';
import { GebeurtenisListItemComponent } from '../../verhaal-shared/component/gebeurtenis-list-item/gebeurtenis-list-item.component';
import { VerhaalPlaceholderComponent } from '../../verhaal-shared/component/verhaal-placeholder/verhaal-placeholder.component';
import { verhaalHeroSources } from '../../constants';
import { GebeurtenisListComponent } from '../../verhaal-shared/component/gebeurtenis-list/gebeurtenis-list.component';
import { ExJongereInfoCardComponent } from '../../../shared/components/ex-jongere-info-card/ex-jongere-info-card.component';

@Component({
  standalone: true,
  templateUrl: './verhaal-ex-jongere.page.html',
  imports: [
    IonHeader,
    IonToolbar,
    IonButtons,
    IonTitle,
    IonBackButton,
    IonContent,
    IonRefresher,
    IonRefresherContent,
    ParkourRefreshDirective,
    PageLayoutComponent,
    PageWithHeroComponent,
    TranslateModule,
    PageSectionDirective,
    AsyncPipe,
    GebeurtenisErrorCardComponent,
    GebeurtenisListItemComponent,
    VerhaalPlaceholderComponent,
    GebeurtenisListComponent,
    ExJongereInfoCardComponent,
  ],
})
export class VerhaalExJongerePage extends ParkourDataPage<VerhaalDto> {
  protected readonly verhaalHeroSources = verhaalHeroSources;

  constructor(private readonly verhalenService: VerhaalService) {
    super();
  }

  override retrieveMainData(): Observable<VerhaalDto> {
    return this.verhalenService.getVerhaalTijdlijn();
  }
}
