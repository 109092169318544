<div [ngClass]="classes">
  <ion-segment
    (ionChange)="segmentChanged($event)"
    [attr.aria-orientation]="'horizontal'"
    [ngClass]="{ 'justify-between': fullWidth }"
    [value]="defaultValue"
    mode="md"
  >
    <ng-container *ngFor="let item of items">
      <ion-segment-button [value]="item.value">
        <ion-label class="typo-body variant-body-fat">
          {{ item.label }}
        </ion-label>

        @if (item.showIndicator) {
          <parkour-new-indicator
            class="absolute top-[-0.25rem] right-[-0.5rem]"
            [show]="item.showIndicator | async"
            [inline]="true"
          />
        }
      </ion-segment-button>
    </ng-container>
  </ion-segment>

  <div class="w-full h-[1px] bg-n-300 full-bleed"></div>
</div>
