@if (fields.length > 0) {
  <div class="text-n-100 bg-negative p-4 rounded w-full space-y-1" role="alert">
    <div class="flex items-center gap-x-2 typo-body variant-body-fat">
      <parkour-icon name="alert-triangle" size="3" class="flex" />

      <span>{{ 'error-summary.title' | translate }}</span>
    </div>

    @if (fields.length === 1) {
      <p>{{ 'error-summary.description.single' | translate }}</p>
    } @else {
      <p>{{ 'error-summary.description.multiple' | translate }}</p>
    }

    <ul class="list-disc list-inside">
      @for (field of fields; track field) {
        <li>{{ field }}</li>
      }
    </ul>
  </div>
}
