import { Component } from '@angular/core';
import { ProfielService } from '../../service/profiel.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ParkourButtonComponent,
  ParkourDividerComponent,
  ParkourInfoCardComponent,
  ParkourPageGradientComponent,
  ParkourToastService,
} from '@parkour/ui';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { forkJoin, mergeMap } from 'rxjs';
import { ParkourBackButtonDirective } from '../../../shared/directives/parkour-back-button.directive';
import { PageLayoutComponent } from '../../../shared/components/page-layout/page-layout.component';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';

@Component({
  standalone: true,
  selector: 'parkour-profiel-verwijder-aanvraag-aanmaken',
  templateUrl: './profiel-verwijder-aanvraag-aanmaken.page.html',
  imports: [
    ParkourBackButtonDirective,
    ParkourPageGradientComponent,
    ParkourDividerComponent,
    ParkourInfoCardComponent,
    ParkourButtonComponent,
    TranslateModule,
    PageLayoutComponent,
    IonBackButton,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonTitle,
    IonContent,
  ],
})
export class ProfielVerwijderAanvraagAanmakenPage {
  constructor(
    private readonly profielService: ProfielService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly toastService: ParkourToastService,
    private readonly translateService: TranslateService,
  ) {}

  onVerwijderClicked() {
    this.profielService
      .markeerMijnProfielVoorVerwijdering()
      .pipe(
        mergeMap(() =>
          forkJoin([
            this.translateService.get('profiel.verwijderen-pagina.succes-popup.titel'),
            this.translateService.get('profiel.verwijderen-pagina.succes-popup.description'),
          ]),
        ),
      )
      .subscribe(([titel, description]) =>
        this.toastService
          .showToast({
            header: titel,
            content: `${description}`,
          })
          .then(() => this.router.navigate(['..'], { relativeTo: this.activatedRoute })),
      );
  }
}
