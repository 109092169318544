import { Component, Input } from '@angular/core';
import { ParkourIconComponent, ParkourUiComponentsModule } from '@parkour/ui';
import { TranslateModule } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { LinkifyPipe } from '../../pipes/linkify.pipe';
import { teleOnthaalOrganisation } from '../../../shared/services/watwat.service';
import { HelplineCardItemComponent } from '../../../home/component/helpline-card-item/helpline-card-item.component';
import { AfwezigheidDto } from 'parkour-web-app-dto';

@Component({
  selector: 'parkour-event-afwezigheid',
  templateUrl: './event-afwezigheid.component.html',
  standalone: true,
  imports: [
    ParkourUiComponentsModule,
    TranslateModule,
    DatePipe,
    LinkifyPipe,
    HelplineCardItemComponent,
    ParkourIconComponent,
  ],
})
export class EventAfwezigheidComponent {
  @Input({ required: true }) afwezigheid!: AfwezigheidDto;
  @Input({ required: true }) professioneel!: boolean;
  protected readonly teleOnthaalOrganisation = teleOnthaalOrganisation;
  protected readonly String = String;
}
