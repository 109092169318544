import {
  Component,
  computed,
  ElementRef,
  Input,
  Signal,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { WatWatVideoContent } from 'parkour-web-app-dto';
import { TranslateModule } from '@ngx-translate/core';
import {
  ParkourVideoContentComponent,
  VideoContent,
} from '../../../shared/components/parkour-video-content/parkour-video-content.component';

@Component({
  selector: 'parkour-artikel-video-content',
  templateUrl: './artikel-video-content.component.html',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [TranslateModule, ParkourVideoContentComponent],
})
export class ArtikelVideoContentComponent {
  @Input({ required: true }) content!: WatWatVideoContent;
  videoId: string | undefined | null = '';
  videoPlayerScript?: HTMLElement;
  @ViewChild('vimeoPlayer') vimeoPlayer!: ElementRef;

  videoContent: Signal<VideoContent | null> = computed(() => {
    if (this.content.content.type === 'youtube') {
      return {
        type: 'youtube',
        urlOrId: this.content.content.youtube,
      };
    } else if (this.content.content.type === 'vimeo') {
      return {
        type: 'vimeo',
        urlOrId: this.content.content.vimeo,
      };
    } else {
      return null;
    }
  });

  constructor() {}
}
