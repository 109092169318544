import { IdOf } from '../branded-type';
import { Rol } from './persoon.dto';
import { ProfielId } from './profiel.dto';
import { ApiProperty } from '@nestjs/swagger';

export type UitnodigingId = IdOf<'uitnodiging'>;
export type UitnodigingStatus =
  | 'AANGEMAAKT'
  | 'VERSTUURD'
  | 'AANVAARD'
  | 'GEWEIGERD'
  | 'VERWIJDERD'
  | 'BEVESTIGD';

const uitnodigingLabels: { [key in UitnodigingStatus]: string } = {
  BEVESTIGD: 'Bevestigd',
  VERWIJDERD: 'Verwijderd',
  AANGEMAAKT: 'Aangemaakt',
  VERSTUURD: 'Uitnodiging verstuurd',
  AANVAARD: 'Wacht op je goedkeuring',
  GEWEIGERD: 'Geweigerd',
};

class UitnodigingDtoDetails {
  @ApiProperty({ type: String })
  readonly id!: UitnodigingId;
  readonly naam!: string;
  readonly email?: string;
  readonly mobielNummer?: string;
  @ApiProperty({ type: String })
  readonly rol!: Rol;
  readonly initiator!: string;
  readonly aangemaaktOpTijdstip!: string;
}

export class UitnodigingDto extends UitnodigingDtoDetails {
  @ApiProperty({ type: String })
  readonly status!: UitnodigingStatus;
  @ApiProperty({ type: String })
  readonly profielId?: ProfielId;
  @ApiProperty({ type: String })
  readonly jongereProfielId!: ProfielId;
}

export type UitnodigingType =
  | UitnodigingUpsertDto['type']
  | UitnodigingEmailUpsertDto['type']
  | UitnodigingSmsUpsertDto['type'];

const uitnodigingTypeLabels: { [key in UitnodigingType]: string } = {
  EMAIL: 'E-mailadres',
  QR: 'QR-code',
  SMS: 'GSM-nummer',
} as const;

export function getUitnodigingTypeLabel(uitnodigingType: UitnodigingType): string {
  return uitnodigingTypeLabels[uitnodigingType];
}

export type UitnodigingUpsertDto =
  | UitnodigingQrUpsertDto
  | UitnodigingEmailUpsertDto
  | UitnodigingSmsUpsertDto;

export class UitnodigingQrUpsertDto {
  readonly naam!: string;
  readonly type!: 'QR';
  @ApiProperty({ type: String })
  readonly rol!: Rol;
  readonly profielId!: string;
}

export class UitnodigingEmailUpsertDto {
  readonly naam!: string;
  readonly type!: 'EMAIL';
  @ApiProperty({ type: String })
  readonly rol!: Rol;
  readonly email!: string;
}

export class UitnodigingSmsUpsertDto {
  readonly naam!: string;
  readonly type!: 'SMS';
  @ApiProperty({ type: String })
  readonly rol!: Rol;
  readonly mobielNummer!: string;
}

export function validatedUitnodidingId(id?: string | null): UitnodigingId {
  if (!id) {
    throw new Error('UitnodigingId is undefined');
  }

  return id as UitnodigingId;
}

export function getUitnodigingStatusLabel(status: UitnodigingStatus): string {
  return uitnodigingLabels[status];
}
