import { ErrorType } from 'parkour-web-app-dto';

export class ParkourError extends Error {
  constructor(
    message: string,
    public readonly errorType: ErrorType,
    options: { cause?: Error } = {},
  ) {
    super(message, options);
  }
}
