<ion-img
  *ngIf="src"
  class="w-full {{ classes }}"
  [attr.style]="getBorderRadius()"
  [ngClass]="{
    'h-full': fit === 'contain' || fit === 'cover',
    'object-contain': fit === 'contain',
    'object-cover': fit === 'cover',
    'object-center': position === 'center',
    'object-top': position === 'top',
    'object-bottom': position === 'bottom',
  }"
  [src]="src"
  [alt]="alt"
/>
