import { Component } from '@angular/core';
import { berichtenHero } from '../../model/berichten-hero';
import { PageWithHeroComponent } from '../../../shared/components/page-with-hero/page-with-hero.component';
import { TranslateModule } from '@ngx-translate/core';
import { AanmeldButtonComponent } from '../../../shared/components/aanmeld-button/aanmeld-button.component';
import { ParkourBlockPlaceholderComponent } from '@parkour/ui';
import { PageLayoutComponent } from '../../../shared/components/page-layout/page-layout.component';
import {
  IonContent,
  IonHeader,
  IonRefresher,
  IonRefresherContent,
} from '@ionic/angular/standalone';
import { ParkourPage } from 'src/app/shared/parkour-page';
import { ParkourRefreshDirective } from '../../../shared/directives/parkour-refresh-directive';
import { BerichtenPlaceholderComponent } from '../../component/berichten-placeholder/berichten-placeholder.component';

@Component({
  standalone: true,
  templateUrl: './berichten-niet-aangemeld.page.html',
  imports: [
    IonHeader,
    IonContent,
    PageWithHeroComponent,
    TranslateModule,
    AanmeldButtonComponent,
    ParkourBlockPlaceholderComponent,
    PageLayoutComponent,
    IonRefresher,
    IonRefresherContent,
    ParkourRefreshDirective,
    BerichtenPlaceholderComponent,
  ],
})
export class BerichtenNietAangemeldPage extends ParkourPage {
  protected readonly berichtenHero = berichtenHero;
}
