import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';
import { NgStyle } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ParkourButtonComponent, ParkourProgressComponent } from '@parkour/ui';

@Component({
  selector: 'parkour-audio-player',
  templateUrl: './audio-player.component.html',
  imports: [NgStyle, TranslateModule, ParkourButtonComponent, ParkourProgressComponent],
  standalone: true,
})
export class AudioPlayerComponent implements AfterViewInit, OnDestroy {
  @Input({ required: true }) audioFile!: string;
  @Input() audioType: string = 'audio/mp3';
  @Input() customDuration?: number | undefined;

  duration = '0:00';
  isAudioPlaying = false;
  audio = new Audio();
  audioProgress = 0;

  constructor(private readonly cdr: ChangeDetectorRef) {}

  ngAfterViewInit() {
    this.audio.preload = 'metadata';
    this.audio.src = `data:${this.audioType};base64,${this.audioFile}`;
    if (this.customDuration) {
      this.duration = this.formatDuration(this.customDuration);
      this.cdr.detectChanges();
    } else {
      this.audio.addEventListener('loadedmetadata', () => {
        this.duration = this.formatDuration(this.audio.duration);
      });
    }

    this.audio.addEventListener('timeupdate', () => {
      this.audioProgress = (this.audio.currentTime / this.audio.duration) * 100;
      this.duration = this.formatDuration(this.audio.currentTime);
    });

    this.audio.addEventListener('play', () => {
      this.isAudioPlaying = true;
    });

    this.audio.addEventListener('pause', () => {
      this.isAudioPlaying = false;
    });
  }

  playPause() {
    if (this.audio.paused) {
      this.audio.play();
    } else {
      this.audio.pause();
    }
  }

  formatDuration(duration: number): string {
    const minutes = Math.floor(duration / 60) | 0;
    const seconds = Math.floor(duration % 60) | 0;

    return `${String(minutes)}:${String(seconds).padStart(2, '0')}`;
  }

  ngOnDestroy() {
    this.audio.pause();
  }
}
