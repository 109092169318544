import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { ParkourButtonComponent } from '@parkour/ui';
import { CdkTrapFocus } from '@angular/cdk/a11y';
import { TranslateModule } from '@ngx-translate/core';
import { BerichtRecorderComponent } from '../bericht-recorder/bericht-recorder.component';
import { NgIf } from '@angular/common';

type Mode = 'RECORDING' | 'AUDIO_LOADING' | 'DEFAULT' | 'AUDIO_PLAYING';

@Component({
  standalone: true,
  selector: 'parkour-spraakbericht-input',
  templateUrl: './spraakbericht-input.component.html',
  imports: [CdkTrapFocus, ParkourButtonComponent, TranslateModule, BerichtRecorderComponent, NgIf],
})
export class SpraakberichtInputComponent {
  @ViewChild('sendButton', { static: false }) sendButton!: ParkourButtonComponent;
  @Output() destroyRecording = new EventEmitter<void>();
  @Output() recordingComplete = new EventEmitter<Blob>();
  mode: Mode = 'RECORDING';
  recordingUrl?: Blob;

  onStopRecordingMode() {
    this.destroyRecording.emit();
  }

  onStopRecording() {
    this.mode = 'AUDIO_PLAYING';

    setTimeout(() => this.sendButton.setFocus());
  }

  onSendRecording() {
    this.recordingComplete.emit(this.recordingUrl);
  }

  onRecordingComplete(recordingUrl: Blob) {
    this.recordingUrl = recordingUrl;
  }

  onRecordingFailed() {
    this.destroyRecording.emit();
  }
}
