import { Component } from '@angular/core';
import { CoreModule } from '../../../core/core.module';
import { map, Observable } from 'rxjs';
import { CmsPaginaDto } from 'parkour-web-app-dto';
import { PageLayoutComponent } from '../../../shared/components/page-layout/page-layout.component';
import { ParkourDividerComponent, ParkourPageGradientComponent } from '@parkour/ui';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';
import { ParkourRefreshDirective } from '../../../shared/directives/parkour-refresh-directive';
import { FaqContentComponent } from '../../../cms/faq-content/faq-content.component';
import { AdaptToSidebarVisibleDirective } from '../../../shared/directives/adapt-to-sidebar-visible.directive';
import { ParkourBackButtonDirective } from '../../../shared/directives/parkour-back-button.directive';
import { CmsService } from '../../../cms/cms.service';
import { ParkourDataPage } from '../../../shared/parkour-data-page';
import { PageContentDirective } from '../../../shared/directives/page-content.directive';

type PageData = {
  cmsContent: CmsPaginaDto;
};

@Component({
  templateUrl: './veelgestelde-vragen.page.html',
  standalone: true,
  imports: [
    CoreModule,
    PageLayoutComponent,
    ParkourPageGradientComponent,
    IonContent,
    IonRefresher,
    IonRefresherContent,
    ParkourRefreshDirective,
    IonTitle,
    IonBackButton,
    IonButtons,
    IonToolbar,
    IonHeader,
    ParkourDividerComponent,
    FaqContentComponent,
    AdaptToSidebarVisibleDirective,
    ParkourBackButtonDirective,
    PageContentDirective,
  ],
})
export class VeelgesteldeVragenPage extends ParkourDataPage<PageData> {
  constructor(private readonly cmsService: CmsService) {
    super();
  }
  override retrieveMainData(): Observable<PageData> {
    return this.cmsService.getCmsPagina().pipe(map((content) => ({ cmsContent: content })));
  }
}
