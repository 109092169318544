<ng-container *ngIf="!editable; else isCheckbox" [formGroup]="formGroup">
  <ng-container [ngTemplateOutlet]="content"></ng-container>
</ng-container>

<ng-template #isCheckbox>
  <parkour-checkbox
    [disabled]="persoon.status === 'GEBLOKKEERD'"
    [formControl]="formGroup.controls.gedeeldMet"
  >
    <ng-container [ngTemplateOutlet]="content"></ng-container>
  </parkour-checkbox>
</ng-template>

<ng-template #content>
  <parkour-profiel-block [profielData]="persoon"></parkour-profiel-block>
</ng-template>
