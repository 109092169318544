import { Component } from '@angular/core';
import { ParkourActionComponent } from '@parkour/ui';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  selector: 'parkour-bericht-gemaskeerd-content',
  templateUrl: './bericht-gemaskeerd-content.component.html',
  imports: [ParkourActionComponent, TranslateModule],
})
export class BerichtGemaskeerdContentComponent {}
