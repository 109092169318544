import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProfielCreateService } from '../../../profiel/service/profiel-create.service';
import { ProfielFormService } from '../../../profiel/service/profiel-form.service';
import { avatars } from 'parkour-web-app-dto';
import { ParkourBackButtonDirective } from '../../../shared/directives/parkour-back-button.directive';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncPipe, NgIf } from '@angular/common';
import { ProfielDetailsFotoEditComponent } from '../../../profiel/component/profiel-details-foto-edit/profiel-details-foto-edit.component';
import { FormsModule } from '@angular/forms';
import { ParkourButtonComponent } from '@parkour/ui';
import { PageLayoutComponent } from '../../../shared/components/page-layout/page-layout.component';
import { ProfileDetailsProfielnaamEditComponent } from '../../../profiel/component/profile-details-profielnaam-edit/profile-details-profielnaam-edit.component';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';
import { getVolledigeNaam } from '../../../authentication/user';
import AuthService from '../../../authentication/service/auth.service';

@Component({
  standalone: true,
  templateUrl: './profiel-create-profielnaam.page.html',
  imports: [
    ParkourBackButtonDirective,
    TranslateModule,
    AsyncPipe,
    NgIf,
    ProfielDetailsFotoEditComponent,
    FormsModule,
    ParkourButtonComponent,
    PageLayoutComponent,
    ProfileDetailsProfielnaamEditComponent,
    IonBackButton,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonTitle,
    IonContent,
  ],
})
export class ProfielCreateProfielnaamPage {
  user$ = this.authService.getGeenProfielOrAangemeldeUser$();
  naamFormGroup = this.profielFormService.createProfielNaamFormGroup({
    roepnaam: undefined,
    profielnaamType: 'VOLLEDIGE_NAAM',
  });
  fotoFormGroup = this.profielFormService.createProfielFotoFormGroup({
    avatar: this.getRandomAvatar(),
    profielFoto: undefined,
    profielFotoFile: undefined,
  });
  constructor(
    private readonly authService: AuthService,
    private readonly profielFormService: ProfielFormService,
    private readonly profileCreateService: ProfielCreateService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
  ) {}

  getRandomAvatar() {
    return avatars[Math.floor(Math.random() * avatars.length)];
  }

  isProfessioneel() {
    return this.profileCreateService.isProfessioneel();
  }

  onSubmit() {
    this.naamFormGroup.markAllAsTouched();
    if (this.naamFormGroup.valid) {
      this.profileCreateService.setNaamData(this.naamFormGroup.getRawValue());
      this.profileCreateService.setProfielFotoData(this.fotoFormGroup.getRawValue());
      this.router.navigate(['../', 'contact'], {
        relativeTo: this.route,
        queryParamsHandling: 'preserve',
      });
    }
  }

  protected readonly getVolledigeNaam = getVolledigeNaam;
}
