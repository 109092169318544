import { Component } from '@angular/core';
import { ContentUnavailableCardComponent } from '../../../../shared/components/content-unavailable-card/content-unavailable-card.component';

@Component({
  standalone: true,
  selector: 'parkour-gebeurtenis-error-card',
  templateUrl: './gebeurtenis-error-card.component.html',
  imports: [ContentUnavailableCardComponent],
})
export class GebeurtenisErrorCardComponent {}
