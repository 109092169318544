<ion-header class="full-header">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button parkourBackButton />
    </ion-buttons>

    <ion-title aria-level="1" role="heading"
      >{{ 'start.profiel-create-profielnaam-page.title' | translate }}
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <parkour-page-layout>
    <!-- TODO: replace image by user image -->
    <ng-container *ngIf="user$ | async as user">
      <parkour-profiel-details-foto-edit
        [fotoFormgroup]="fotoFormGroup"
        [professioneel]="isProfessioneel()"
      />

      <div class="py-8 container container-space">
        <form (ngSubmit)="onSubmit()" class="container-1-col">
          <div class="p-4 rounded bg-n-200">
            <h2 class="typo-h3">{{ 'profiel.profielnaam' | translate }}</h2>

            <parkour-profile-details-profielnaam-edit
              [fullName]="getVolledigeNaam(user)"
              [naamFormgroup]="naamFormGroup"
              class="block mt-4"
            />
          </div>

          <parkour-button class="block w-full mt-8" iconAfter="arrow-right" type="submit">
            Volgende stap
          </parkour-button>
        </form>
      </div>
    </ng-container>
  </parkour-page-layout>
</ion-content>
