import { Component, Input } from '@angular/core';
import { ParkourImageComponent } from '@parkour/ui';
import { WatWatImageContent } from 'parkour-web-app-dto';

@Component({
  selector: 'parkour-artikel-image-content',
  templateUrl: './artikel-image-content.component.html',
  standalone: true,
  imports: [ParkourImageComponent],
})
export class ArtikelImageContentComponent {
  @Input({ required: true }) content!: WatWatImageContent;
}
