import { Injectable } from '@angular/core';
import {
  AfspraakId,
  ContextId,
  DoelId,
  GebeurtenisId,
  GesprekId,
  MeldingOfType,
  MeldingType,
  UitnodigingId,
} from 'parkour-web-app-dto';
import { map, Observable, of } from 'rxjs';
import {
  ContextService,
  ContextUrl,
  ContextUrlQueryParams,
  GlobalUrl,
} from '../../shared/services/context.service';
import { ProfielService } from './profiel.service';
import { SupportedMelding } from '../model/meldingen';

type MeldingLinkGetter<Type extends MeldingType> = (
  melding: MeldingOfType<Type>,
) => Observable<string[] | ContextUrl | GlobalUrl>;

@Injectable({
  providedIn: 'root',
})
export class MeldingLinkService {
  constructor(
    private readonly contextService: ContextService,
    private readonly profielService: ProfielService,
  ) {}

  public getMeldingLink(melding: SupportedMelding): Observable<string[] | ContextUrl | GlobalUrl> {
    return this.getMeldingLinkGetter(melding.type)(melding);
  }

  private getMeldingLinkGetter<Type extends MeldingType>(
    meldingType: Type,
  ): MeldingLinkGetter<Type> {
    return this.meldingLinkGetterMap[meldingType];
  }

  private getUrlInContext = (
    contextId: ContextId,
    segments: string[],
    queryParams?: ContextUrlQueryParams,
  ): Observable<ContextUrl> =>
    this.profielService
      .isActiefInContext(contextId)
      .pipe(
        map((actief) =>
          actief
            ? this.contextService.getUrlInContext(contextId, segments, queryParams)
            : { type: 'context-url', path: [] },
        ),
      );

  private getEyouthGebeurtenisPagePath = (melding: {
    contextId: ContextId;
    metaData: {
      gebeurtenisId: string;
    };
  }) =>
    this.getUrlInContext(melding.contextId, ['verhaal', 'eyouth', melding.metaData.gebeurtenisId]);

  private getVeelgesteldeVragenPath = () =>
    of<GlobalUrl>({
      type: 'global-url',
      url: 'https://www.opgroeien.be/over-opgroeien/projecten/parkour/veelgestelde-vragen',
    });

  private getGebeurtenisPagePath = (melding: {
    contextId: ContextId;
    metaData: {
      gebeurtenisId: GebeurtenisId;
    };
  }) =>
    this.getUrlInContext(melding.contextId, [
      'verhaal',
      'gebeurtenis',
      melding.metaData.gebeurtenisId,
    ]);

  private getDocumentPagePath = (melding: {
    contextId: ContextId;
    metaData: {
      documentId: string;
    };
  }) =>
    this.getUrlInContext(melding.contextId, ['verhaal', 'document', melding.metaData.documentId]);

  private getDoelPagePath = (melding: {
    contextId: ContextId;
    metaData: {
      doelId: DoelId;
    };
  }) => {
    return this.getUrlInContext(melding.contextId, ['doelen', melding.metaData.doelId]);
  };

  private getDoelGesprekPagePath = (melding: {
    contextId: ContextId;
    metaData: {
      doelId: DoelId;
    };
  }) => {
    return this.getUrlInContext(melding.contextId, ['doelen', melding.metaData.doelId], {
      sheet: true,
    });
  };

  private getBerichtGesprekPagePath = (melding: {
    contextId: ContextId;
    metaData: {
      gesprekId: GesprekId;
    };
  }) =>
    this.getUrlInContext(melding.contextId, ['berichten', 'gesprek', melding.metaData.gesprekId]);

  private getGesprekkenPagePath = (melding: { contextId: ContextId }) =>
    this.getUrlInContext(melding.contextId, ['berichten']);

  private getKalenderPagePath = () => this.contextService.getAbsoluteUrl(['profiel', 'kalender']);

  private getAfspraakPath = (melding: { metaData: { afspraakId: AfspraakId } }) =>
    this.contextService.getAbsoluteUrl([
      'profiel',
      'kalender',
      'afspraak',
      melding.metaData.afspraakId,
      'detail',
    ]);

  private getWisselTeamPagePath = () =>
    this.contextService.getAbsoluteUrl(['profiel', 'wissel-team']);

  private getProfielDetailPagePath = () => this.contextService.getAbsoluteUrl(['profiel', 'mijn']);

  private getUitnodigingPagePath = (melding: {
    contextId: ContextId;
    metaData: {
      uitnodigingId: UitnodigingId;
    };
  }) =>
    this.getUrlInContext(melding.contextId, [
      'profiel',
      'team',
      'uitnodiging',
      melding.metaData.uitnodigingId,
    ]);

  private getTeamPath = (melding: { contextId: ContextId }): Observable<ContextUrl> =>
    this.getUrlInContext(melding.contextId, ['profiel', 'team']);

  private getVerhaalPath = (melding: { contextId: ContextId }): Observable<ContextUrl> =>
    this.getUrlInContext(melding.contextId, ['verhaal']);

  private getGeblokkeerdTeamPath = (melding: {
    metaData: {
      jongereProfielId: string;
    };
  }): Observable<string[]> =>
    this.contextService.getAbsoluteUrl([
      'profiel',
      'team',
      'geblokkeerd',
      melding.metaData.jongereProfielId,
    ]);

  private getAfspraakDetailPagePath = (melding: {
    metaData: {
      afspraakId: AfspraakId;
    };
  }): Observable<string[]> =>
    this.contextService.getAbsoluteUrl([
      'profiel',
      'kalender',
      'afspraak',
      melding.metaData.afspraakId,
      'detail',
    ]);

  private getDoelenOverzichtPagePath = (melding: {
    contextId: ContextId;
  }): Observable<ContextUrl> => this.getUrlInContext(melding.contextId, ['doelen']);

  private readonly meldingLinkGetterMap: { [T in MeldingType]: MeldingLinkGetter<T> } = {
    VERWIJDERD_UIT_TEAM: this.getWisselTeamPagePath,
    GEDEBLOKKEERD: this.getTeamPath,
    GEBLOKKEERD: this.getGeblokkeerdTeamPath,
    GEBEURTENIS_GESUGGEREERD: this.getVerhaalPath,
    TEAM_VERLATEN: this.getTeamPath,
    UITNODIGING_AANVAARD: this.getUitnodigingPagePath,
    UITNODIGING_GEWEIGERD: this.getTeamPath,
    UITNODIGING_BEVESTIGD: this.getTeamPath,
    BEVESTIGING_GEWEIGERD: this.getWisselTeamPagePath,
    UITGENODIGD: this.getWisselTeamPagePath,
    NIEUW_DRIE_KOLOMMEN_DOCUMENT: this.getDocumentPagePath,
    AFSPRAAK_AANGEMAAKT: this.getAfspraakDetailPagePath,
    AFSPRAAK_HERINNERING: this.getAfspraakDetailPagePath,
    AFSPRAAK_VERWIJDERD: this.getKalenderPagePath,
    AFSPRAAK_GESTART: this.getAfspraakPath,
    NIEUW_CHAT_REACTIE: this.getBerichtGesprekPagePath,
    NIEUW_CHAT_BERICHT: this.getBerichtGesprekPagePath,
    VIDEOGESPREK_GESTART: this.getBerichtGesprekPagePath,
    DOEL_GESUGGEREERD: this.getDoelenOverzichtPagePath,
    DOEL_GEDEELD: this.getDoelPagePath,
    DOEL_EMOJI_REACTIE_ADDED: this.getDoelPagePath,
    DOEL_VOLTOOID: this.getDoelPagePath,
    DOEL_NIEUW_BERICHT_REACTIE: this.getDoelGesprekPagePath,
    DOEL_NIEUW_BERICHT: this.getDoelGesprekPagePath,
    GEBEURTENIS_GEDEELD: this.getGebeurtenisPagePath,
    JEUGDHULPHISTORIEK_GEDEELD: this.getEyouthGebeurtenisPagePath,
    NIEUWE_EYOUTH_GEBEURTENIS: this.getEyouthGebeurtenisPagePath,
    JONGERE_REMINDER_ONE_WEEK: this.getVeelgesteldeVragenPath,
    JONGERE_REMINDER_ONE_DAY: this.getVeelgesteldeVragenPath,
    VERWIJDERD_UIT_TEAM_DOOR_JONGERE_25: this.getWisselTeamPagePath,
    PROFIEL_WORDT_VERWIJDERD_HERINNERING: this.getProfielDetailPagePath,
    EERSTE_TEAMLID_TOEVOEGEN: this.getTeamPath,
    EERSTE_TEAM_UITNODIGING: this.getProfielDetailPagePath,
    EERSTE_DOEL_DELEN: this.getDoelPagePath,
    EERSTE_DOEL_VOORSTELLEN: this.getDoelenOverzichtPagePath,
    EERSTE_BERICHT_STUREN: this.getGesprekkenPagePath,
  };
}
