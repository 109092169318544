<ion-header />

<ion-content [scrollY]="false">
  <parkour-page-layout>
    <div
      class="h-[calc(100vh-var(--app-plus-global-header-height))] container container-space flex items-center justify-center"
    >
      <div class="py-8 flex flex-col items-center gap-y-2 flex-1">
        <parkour-image
          alt="PARKOUR logo"
          class="block w-[60vw]"
          src="/assets/general/parkour_logo.png"
        />

        <div class="flex flex-col gap-y-8 items-center text-center whitespace-pre-wrap w-full">
          <h1>{{ 'upgrade.title' | translate }}</h1>

          <p>{{ 'upgrade.description' | translate }}</p>

          <parkour-button (clicked)="openAppStore()" class="block w-full">
            {{ 'buttons.download-update' | translate }}
          </parkour-button>
        </div>
      </div>
    </div>
  </parkour-page-layout>
</ion-content>
