<div class="relative inline-flex items-center gap-x-2 p-2 bg-n-200 rounded" role="alert">
  <div class="bg-n-300 w-[3rem] h-[3rem] flex items-center justify-center rounded-[100vmax]">
    <parkour-icon name="video-camera" size="3" />
  </div>

  <div class="flex flex-col gap-y-1 text-n-1000">
    <span class="typo-body variant-body-fat">{{
      'berichten.gesprek-page.videocall' | translate
    }}</span>

    @if (gesprekEvent.answered) {
      <span class="typo-body">{{
        calculateDuration(gesprekEvent.startTimestamp, gesprekEvent.endTimestamp)
      }}</span>
    } @else {
      <span class="typo-body">{{ 'berichten.gesprek-page.videocall-empty' | translate }}</span>
    }
  </div>

  <span class="typo-body variant-body-small text-n-600 ml-4 float-right translate-y-1 self-end">{{
    berichtTimestamp | berichtenDate
  }}</span>
</div>
