<div class="group flex flex-col relative">
  @if (gesprekId | berichtAttachmentMeta: bericht | async; as metadata) {
    @if (metadata.hasPreview) {
      <parkour-image
        alt=""
        class="bg-n-1000 rounded-tr rounded-tl overflow-hidden h-[14rem]"
        [src]="bericht | bestandPreview: gesprekId | async"
      />
    }

    <div class="relative">
      <div
        class="flex flex-col py-2 px-3 has-[:focus-visible]:outline-[3px] has-[:focus-visible]:outline-[yellow] has-[:focus-visible]:outline-dashed has-[:focus-visible]:outline-offset-[2px]"
      >
        <span
          *ngIf="(!isUser && gesprek.isGroepsgesprek()) || isNameVisible"
          class="bericht-message-title typo-body variant-body-small variant-body-fat"
        >
          <ng-container *ngIf="bericht.deelnemerId | profiel | async as profiel">
            {{ profiel | profielnaam }}
          </ng-container>
        </span>

        <div class="whitespace-pre-wrap break-words">
          <div class="inline-flex items-start gap-x-1">
            <parkour-icon *ngIf="!downloading" name="attachment" />

            <parkour-loading-spinner *ngIf="downloading" [size]="'small'" />

            <parkour-action
              (clicked)="onDownloadAttachment(bericht, metadata)"
              class="block mr-auto"
              classes="typo-body variant-body-fat text-n-1000 underline break-all before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:z-10 focus-visible:outline-none"
            >
              <span>{{ decodeURIComponent(metadata.name) }}</span>
            </parkour-action>
          </div>

          <span class="sr-only">
            @if (getIsTimeToday(bericht.timestamp)) {
              {{ 'berichten.gesprek-page.time' | translate }}
            } @else {
              {{ 'berichten.gesprek-page.date' | translate }}
            }
          </span>

          <span class="typo-body variant-body-small text-n-700 ml-4 float-right translate-y-1">
            {{ bericht.timestamp | berichtenDate }}
          </span>
        </div>
      </div>

      @if ((isDesktop() && disabled && !isUser) || (isDesktop() && !disabled)) {
        <parkour-button
          (clicked)="onMoreOptionsClick()"
          [ariaLabel]="'buttons.open-menu' | translate"
          [ngClass]="{ 'from-n-300 to-n-300/0': isUser, 'from-n-200 to-n-200/0': !isUser }"
          class="rounded-tr rounded-br pl-12 pr-4 bg-gradient-to-l group-hover:opacity-100 has-[:focus-visible]:opacity-100 absolute top-0 right-0 z-50 transition-opacity opacity-0 h-full"
          classes="h-full"
          format="iconButton"
          icon="menu"
          size="none"
          variant="ghost"
        />
      }
    </div>
  }
</div>
