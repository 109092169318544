<ion-header></ion-header>

<ion-content>
  <ion-refresher [parkourRefresh]="onPageRefresh" slot="fixed">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>
  <parkour-page-layout>
    <parkour-page-with-hero
      [heroImage]="berichtenHero"
      [title]="'berichten.gesprekken-overview-page.title' | translate"
    >
      <parkour-ex-jongere-info-card info-card />
      <div class="space-y-8 container-1-col">
        <p class="text-center">
          {{ 'berichten.gesprekken-overview-page.ex-jongere' | translate }}
        </p>

        <parkour-berichten-placeholder />
      </div>
    </parkour-page-with-hero>
  </parkour-page-layout>
</ion-content>
