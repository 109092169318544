import { Component, HostBinding, Input } from '@angular/core';
import { VideoGesprekEndedGesprekEventDto } from 'parkour-web-app-dto';
import moment from 'moment';
import { ParkourIconComponent } from '@parkour/ui';
import { TranslateModule } from '@ngx-translate/core';
import { BerichtenDatePipe } from '../../pipes/berichten-date.pipe';

@Component({
  standalone: true,
  selector: 'parkour-event-end-videogesprek',
  templateUrl: './event-end-videogesprek.component.html',
  imports: [ParkourIconComponent, TranslateModule, BerichtenDatePipe],
})
export class EventEndVideogesprekComponent {
  @Input({ required: true }) gesprekEvent!: VideoGesprekEndedGesprekEventDto;
  @Input({ required: true }) berichtTimestamp!: string;

  @HostBinding('class') get class() {
    return 'chat-flow__event';
  }

  calculateDuration(startTimestamp: string, endTimestamp: string): string {
    const start = moment(new Date(startTimestamp));
    const end = moment(new Date(endTimestamp));
    const duration = moment.duration(end.diff(start));
    const durationSeconds = duration.asSeconds();

    if (durationSeconds > 60) {
      const minuten = duration.asMinutes().toFixed(0);
      const suffix = minuten === '1' ? ' minuut' : ' minuten';

      return minuten + suffix;
    } else {
      return durationSeconds.toFixed(0) + ' seconden';
    }
  }
}
