<ion-header class="w-full transform-none"></ion-header>

<ion-content>
  <parkour-page-layout>
    <parkour-page-image
      classes="absolute top-0 left-0 z-0"
      src="/assets/onboarding/background/background_onboarding_blur.jpg"
    />

    <div class="relative z-10 py-8 container container-space">
      <div class="container-1-col">
        <h1 class="pt-8 mb-4 text-center">Gebruiksvoorwaarden</h1>

        <div class="rounded overflow-hidden">
          <div class="px-4 py-6 bg-n-300">
            <article class="wysiwyg space-y-8">
              <h2 class="typo-h3">Waarover gaat deze tekst?</h2>

              <section class="space-y-3">
                <h3 class="typo-h4">Wat is de app van PARKOUR?</h3>

                <ul>
                  <li>Het is een app voor jongeren tussen 13 en 25 jaar.</li>

                  <li>
                    De jongere met een jeugdhulptraject vindt er info over zichzelf uit het
                    jeugdhulpdossier. Die info is geheim en vertrouwelijk. De jongere beheert zelf
                    zijn verhaal in de app.
                  </li>

                  <li>
                    De jongere en teamleden vinden er ook info over thema’s die belangrijk kunnen
                    zijn.
                  </li>

                  <li>
                    De jongere met een jeugdhulptraject kan contact opnemen met zijn begeleiders via
                    de app.
                  </li>

                  <li>
                    De jongere maakt zelf doelen en acties aan, die hij dan kan delen met het team
                    dat hij zelf samenstelt.
                  </li>

                  <li>De app is veilig en gemakkelijk te gebruiken.</li>
                </ul>
              </section>

              <section class="mt-8 space-y-3">
                <h3 class="typo-h4">Wil je de app van PARKOUR gebruiken?</h3>

                <p>Lees dan eerst de regels.</p>

                <p>
                  Die gaan over jouw rechten en plichten. En over de rechten en plichten van het
                  agentschap Opgroeien.
                </p>

                <p>
                  Die dienst van de Vlaamse overheid liet de app maken en staat voor je klaar bij
                  technische problemen, vragen of klachten. Gebruik hiervoor "Fout melden" in de
                  PARKOUR-app.
                </p>

                <p>Heb je alles goed gelezen?</p>

                <p>
                  Dan moet je laten weten dat je akkoord gaat met de regels. Doe je dat niet, dan
                  kan je de app niet gebruiken.
                </p>
              </section>

              <section class="space-y-3">
                <h3 class="typo-h4">Wat zijn de belangrijkste afspraken?</h3>

                <ul>
                  <li>Gebruik je de app? Dan ken je de afspraken.</li>

                  <li>
                    Volg je de afspraken niet? Gebruik je de app voor dingen die niet volgens de wet
                    zijn? Dan kunnen we je verbieden om de app te gebruiken.
                  </li>

                  <li>
                    Merk je dat iemand anders de app fout gebruikt? Of gebruikt iemand de app voor
                    onwettige dingen? Verlies of diefstal van je toestel? Meld dat meteen via "Fout
                    melden" in de PARKOUR-app of via
                    <a href="https://parkour.opgroeien.be" target="_blank"
                      >https://parkour .opgroeien.be</a
                    >.
                  </li>

                  <li>
                    Laat jij iemand anders de app gebruiken voor onwettige dingen? Dan ben jij
                    verantwoordelijk.
                  </li>

                  <li>
                    Via deze app kan je vertrouwelijke of geheime informatie lezen. Deel je die
                    info? Dan ben je zelf verantwoordelijk. Heb je een beroepsgeheim? Respecteer
                    dat.
                  </li>

                  <li>
                    Heb jij schade als je de app gebruikte of liet gebruiken voor illegale
                    doeleinden? Dan ben je zelf verantwoordelijk.
                  </li>

                  <li>
                    Het agentschap Opgroeien is de eigenaar van de app. Wij hebben ook de rechten op
                    alles wat met de app te maken heeft.
                  </li>
                </ul>

                <p>
                  Het gebruik van de app geeft je geen bijkomende rechten binnen de jeugdhulp. Lees
                  hieronder alle gebruiksvoorwaarden.
                </p>
              </section>
            </article>
          </div>

          <div class="px-4 py-6 bg-n-200">
            <article class="wysiwyg space-y-8">
              <details class="group">
                <summary class="flex items-center gap-x-1 text-n-600 cursor-pointer">
                  <span class="typo-body variant-body-fat underline">
                    Lees alle gebruiksvoorwaarden
                  </span>

                  <parkour-icon
                    class="flex group-open:rotate-180 transition-transform"
                    name="chevron-bottom"
                  />
                </summary>

                <section class="space-y-3 mt-4">
                  <ol>
                    <li>
                      <h2 class="typo-h3">Algemeen</h2>

                      <ol>
                        <li>
                          Dit zijn afspraken tussen jou en ons, het Agentschap Opgroeien, die je
                          moet naleven. Ga je niet akkoord met deze afspraken? Dan mag je de app
                          niet gebruiken.
                        </li>

                        <li>
                          Het gebruik van de app geeft je geen bijkomende rechten binnen de
                          jeugdhulp.
                        </li>
                      </ol>
                    </li>

                    <li>
                      <h2 class="typo-h3">Wat is de app van PARKOUR?</h2>

                      <ul>
                        <li>Het is een app voor jongeren tussen 13 en 25 jaar.</li>

                        <li>
                          De jongere met een jeugdhulptraject vindt er info over zichzelf uit het
                          jeugdhulpdossier. Die info is geheim en vertrouwelijk. De jongere beheert
                          zelf zijn verhaal in de app.
                        </li>

                        <li>
                          De jongere en teamleden vinden er ook info over thema’s die belangrijk
                          kunnen zijn.
                        </li>

                        <li>
                          De jongere met een jeugdhulptraject kan contact opnemen met zijn
                          begeleiders via de app.
                        </li>

                        <li>
                          De jongere maakt zelf doelen en acties aan, die hij dan kan delen met het
                          team dat hij zelf samenstelt.
                        </li>

                        <li>De app is veilig en gemakkelijk te gebruiken.</li>
                      </ul>
                    </li>

                    <li>
                      <h2 class="typo-h3">Hoe moet je de app gebruiken?</h2>

                      <ol>
                        <li>
                          Je moet algemene regels volgen.

                          <ul>
                            <li>
                              Dat zijn voor begeleiders of hulpverleners binnen de jeugdhulp o.m.:

                              <ul>
                                <li>de regels van het beroepsgeheim</li>

                                <li>de regels inzake de bescherming van persoonsgegevens</li>
                              </ul>
                            </li>

                            <li>
                              Als begeleider of hulpverlener moet je ook alle regels volgen van je
                              eigen organisatie, zoals de regels voor professioneel gedrag en
                              deontologie, vertrouwelijkheid en gegevensbescherming.
                            </li>

                            <li>Je mag de info in de app nooit aanpassen.</li>

                            <li>
                              Je mag volgende info niet sturen of uploaden via de app:

                              <ul>
                                <li>
                                  info die de rechten van andere mensen schaadt, bijvoorbeeld de
                                  morele rechten of intellectuele eigendomsrechten
                                </li>

                                <li>
                                  info die schadelijk is, of gewelddadig, onwettig, obsceen,
                                  vernederend of negatief over anderen
                                </li>

                                <li>info die de privacy van andere mensen schaadt</li>

                                <li>
                                  info die je niet mag gebruiken of niet mag hebben volgens de wet
                                </li>

                                <li>
                                  info die virussen of instructies bevat die schadelijk kunnen zijn
                                  voor:

                                  <ul>
                                    <li>de app</li>

                                    <li>de diensten van de app</li>

                                    <li>systemen en andere toepassingen die bij de app horen</li>
                                  </ul>
                                </li>
                              </ul>
                            </li>

                            <li>
                              Als de jongere hiervoor de toestemming geeft, mogen gegevens,
                              berichten of documenten uit zijn jeugdhulpdossier verstuurd worden via
                              de app.
                            </li>
                          </ul>
                        </li>

                        <li>
                          Iedereen moet de app veilig en vertrouwelijk kunnen gebruiken. Jij bent
                          daarvoor mee verantwoordelijk. Wat wil dat zeggen?

                          <ul>
                            <li>Je gebruikt de app alleen waarvoor die dient.</li>

                            <li>Je gebruikt de app niet voor onwettige zaken.</li>

                            <li>
                              Je geeft geen toegang tot de app aan personen die de app voor
                              onwettige zaken willen gebruiken.
                            </li>

                            <li>
                              Je laat niet toe dat anderen hun vingerafdrukken of gezichtsherkenning
                              op je toestel registreren.
                            </li>

                            <li>Je gebruikt geen gejailbreakte of “rooted” toestellen.</li>
                          </ul>

                          Gebruik je de app niet zoals het moet? Dan kunnen we je verbieden om de
                          app te gebruiken.
                        </li>

                        <li>
                          Merk je dat iemand de app gebruikt voor onwettige zaken? Volgt iemand de
                          regels niet? Verlies of diefstal van je toestel? Meld dat via "Fout
                          melden" in de PARKOUR-app.
                        </li>

                        <li>
                          Je gebruikt de app van PARKOUR alleen voor jezelf. Je gebruikt de app
                          alleen met je eigen naam.
                        </li>

                        <li>
                          Behalve je profiel dat voor iedereen zichtbaar is, zie alleen jij de info
                          op jouw PARKOUR-app. Jij beslist welke info, documenten en berichten je
                          met een ander deelt. Alleen iemand van het gerecht kan de info op jouw
                          PARKOUR-app opvragen. Dat is alleen de procureur des Konings of de
                          onderzoekrechter bij een strafonderzoek. Een jeugdrechter of een sociale
                          dienst van de jeugdrechtbank kan de info van jouw PARKOUR-app niet
                          opvragen.
                        </li>

                        <li>
                          <p>
                            Ging je akkoord met de afspraken? En volgde je de afspraken toch niet?
                            Dan kunnen we je verbieden om de app te gebruiken voor een tijd of voor
                            altijd. We moeten je dat niet vooraf laten weten.
                          </p>

                          <p>
                            Je mag dan de app niet meer gebruiken totdat jij je fout hebt hersteld.
                            Jij bent daarvoor zelf verantwoordelijk.
                          </p>
                        </li>

                        <li>
                          Volgde je de afspraken niet? En heb je daardoor schade? Of raak je iets
                          kwijt? Daarvoor zijn wij niet verantwoordelijk. We betalen de schade niet
                          terug. Meer info over schade lees je in 6 en 7.
                        </li>
                      </ol>
                    </li>

                    <li>
                      <h2 class="typo-h3">Wat mag je gebruiken? En wat mag je niet gebruiken?</h2>

                      Dit stuk gaat over de intellectuele eigendomsrechten. Dat zijn bijvoorbeeld:
                      <br />

                      (a) auteursrechten <br />

                      (b) rechten op merken, zoals logo’s en slogans <br />

                      (c) rechten op kennis over zaken die geheim moet blijven voor concurrenten<br />

                      (d) rechten op nieuwe uitvindingen<br />

                      (e) rechten op het ontwerpen<br />

                      (f) rechten op de namen van bedrijven, internetdomeinen<br />

                      (g) en ook alle procedures om deze rechten te registreren, te vernieuwen en te
                      beschermen, zowel nationaal als internationaal<br />

                      <ol>
                        <li>
                          De app en alle functies en diensten zijn en blijven eigendom van het
                          agentschap Opgroeien. Ook de ‘intellectuele rechten op het eigendom’ zijn
                          van ons. Alleen wij mogen dus info van de app aanpassen of kopiëren, zoals
                          foto’s of tekst.
                        </li>

                        <li>
                          Jij hebt alleen de rechten die jij kreeg volgens deze Gebruiksvoorwaarden.
                          Alle andere rechten zijn alleen van ons. Je hebt bijvoorbeeld geen toegang
                          tot technische info over de app, zoals de broncode. De broncode zijn
                          instructies die een computer vertellen wat die moet doen om een app te
                          laten werken.
                        </li>

                        <li>
                          Jij krijgt het recht om deze app te gebruiken zolang je de regels volgt.
                          Je verliest dat recht:

                          <ul>
                            <li>als je de regels niet volgt</li>

                            <li>als je deze afspraak verbreekt</li>

                            <li>als je de app niet voor jezelf gebruikt</li>
                          </ul>
                        </li>

                        <li>
                          Het is verboden om toegang te hebben tot de technische details van de app.
                          Bijvoorbeeld:

                          <ul>
                            <li>
                              Je mag niet onderzoeken hoe de app werkt (‘reverse-engineeren’).
                            </li>

                            <li>Je mag de app niet omzetten naar de broncode (‘decompileren’).</li>

                            <li>Je mag de software niet demonteren of aanpassen.</li>
                          </ul>

                          Het is verboden om de app of een deel daarvan aan anderen te geven. Je mag
                          de app bijvoorbeeld niet:

                          <ul>
                            <li>verdelen</li>

                            <li>verkopen</li>

                            <li>verhuren of onderverhuren</li>

                            <li>leasen</li>

                            <li>uitlenen</li>
                          </ul>

                          Het is verboden om info in de app te veranderen, te verwijderen of
                          onleesbaar te maken. Dat gaat over:

                          <ul>
                            <li>
                              specifieke info over de app, zoals het nummer en het digitale
                              watermerk
                            </li>

                            <li>info over het eigendom</li>

                            <li>info over het auteursrecht</li>

                            <li>andere berichten</li>
                          </ul>

                          <p>
                            Het is verboden om een deel van de app te veranderen of te gebruiken om
                            er een afgeleid werk van te maken. Dit betekent bijvoorbeeld dat je de
                            code van de applicatie niet mag hergebruiken of aanpassen om een nieuwe
                            applicatie te maken, dat je grafische elementen of het ontwerp van de
                            applicatie niet mag kopiëren en gebruiken in een andere applicatie, of
                            dat je de functionaliteit van de applicatie niet mag namaken en
                            verwerken in een nieuw product. Je mag de app ook niet samenvoegen met
                            andere software Dat mag alleen als wij dat schriftelijk toestaan.
                          </p>

                          <p>
                            Het is verboden om de app te gebruiken om foute of onwettige dingen te
                            doen of om anderen dat te laten doen. Je mag bijvoorbeeld mensen die de
                            app voor onwettige dingen willen gebruiken, niet aan het team toevoegen.
                          </p>

                          <p>
                            Het is verboden personen aan het team toe te voegen die de app wensen te
                            gebruiken om daar illegale activiteiten mee uit te voeren. Je mag de app
                            niet doorzoeken of info verzamelen over de app.
                          </p>

                          <p>
                            Je mag dat niet zelf doen, of met elektronische middelen, zoals robots,
                            spiders of scripts. Spiders en scripts zijn programma’s waarmee je
                            automatisch toegang kunt krijgen tot info. Zo kunnen ze die info
                            verzamelen.
                          </p>

                          <p>
                            Het is verboden om opzettelijk schadelijke software te verspreiden via
                            de app. Bijvoorbeeld:
                          </p>

                          <ul>
                            <li>
                              virussen: software die zichzelf kan vermenigvuldigen en zo het
                              netwerk, bestanden of computers kan besmetten
                            </li>

                            <li>
                              ‘Trojaanse paarden’: software die een virus verbergt waarmee je
                              bijvoorbeeld info kan stelen
                            </li>

                            <li>kapotte bestanden die de app kunnen schaden</li>

                            <li>andere onbetrouwbare software</li>
                          </ul>

                          <p>Het is verboden om de app te gebruiken:</p>

                          <ul>
                            <li>voor onwettige dingen</li>

                            <li>om onwettig toegang te krijgen tot de app</li>

                            <li>
                              om de rechten van anderen te schenden, bijvoorbeeld door foto’s of
                              teksten te kopiëren
                            </li>

                            <li>
                              om foute info over anderen te geven of info die anderen kan schaden
                            </li>

                            <li>voor fraude en bedrog</li>
                          </ul>

                          <p>
                            Het is verboden om de beveiliging van de app te doorbreken. Je mag
                            bijvoorbeeld codes en wachtwoorden niet verwijderen of omzeilen. Die
                            regels zijn er om de app te beschermen tegen misbruik.
                          </p>
                        </li>
                      </ol>
                    </li>

                    <li>
                      <h2 class="typo-h3">Ondersteuning</h2>

                      <p>
                        Als je technische problemen ervaart of klachten hebt, neem dan contact op
                        via "Fout melden" in de PARKOUR app. Wij zullen alle redelijke inspanningen
                        leveren om jouw vragen in de kortst mogelijke tijd te beantwoorden en jouw
                        technische problemen met de app op te lossen. Ook voor klachten
                        (bijvoorbeeld over mogelijk misbruik) zullen wij zo snel mogelijk reageren.
                      </p>
                    </li>

                    <li>
                      <h3 class="typo-h4">Wie is aansprakelijk voor schade en kosten?</h3>

                      <p>
                        Als je door je fout schade veroorzaakt, moet je zelf de eventuele schade
                        vergoeden Je bent dan aansprakelijk.
                      </p>

                      <ol>
                        <li>
                          Iedereen is alleen aansprakelijk voor eigen fouten. Niemand kan je dus
                          dwingen om te betalen voor fouten van anderen.
                        </li>

                        <li>
                          Wij, het agentschap Opgroeien, zijn nooit aansprakelijk voor:

                          <ul>
                            <li>schade omdat de info over jou niet juist of niet volledig is</li>

                            <li>schade omdat jij de app verkeerd of onwettig gebruikt</li>

                            <li>
                              schade doordat je spullen of software die de app voorschreef, hebt
                              gebruikt
                            </li>
                          </ul>

                          <p>
                            Wij zijn dus niet verantwoordelijk voor schade die wij niet direct
                            veroorzaakt hebben, wat er ook gebeurd is. Zelfs als wij weten dat zulke
                            schade mogelijk is.
                          </p>

                          <p>Wat kan de schade bijvoorbeeld zijn?</p>

                          <ul>
                            <li>
                              extra schade of extra kosten zoals:

                              <ul>
                                <li>verlies van inkomsten</li>

                                <li>verlies van belangrijke info over jou</li>

                                <li>schade van de goede naam van een persoon of een bedrijf</li>

                                <li>kosten om andere diensten te betalen</li>

                                <li>schade aan spullen</li>

                                <li>schade doordat je de app verkeerd of onwettig gebruikt</li>

                                <li>schade omdat je de regels niet volgt</li>
                              </ul>
                            </li>

                            <li>
                              schade aan het toestel waarop je de app gebruikt, bijvoorbeeld door
                              een virus nadat je de app gebruikte
                            </li>
                          </ul>

                          <p>
                            Wat moet jij doen? <br />

                            Je moet alles doen om de schade te voorkomen en te beperken.<br />

                            Merk je dat er iets fout gaat? Dat er schade kan ontstaan doordat je de
                            app gebruikt?<br />

                            Meld dit meteen via "Fout melden" in de PARKOUR-app.
                          </p>
                        </li>

                        <li>
                          In de app kunnen fouten zitten. Wij kunnen niet garanderen dat de info en
                          de hulp die je krijgt, juist of volledig zijn. Wij kunnen wel foute info
                          over de app verbeteren. Meer info lees je in punt 7.
                        </li>

                        <li>
                          Wij bieden de app aan zoals die is, voor zover de wet dat toestaat. Je kan
                          de app gebruiken, maar wij geven geen garanties over de kwaliteit van de
                          app. We kunnen dus niet beloven:

                          <ul>
                            <li>dat de app geschikt is voor een bepaald doel</li>

                            <li>dat de app geen rechten van anderen schendt</li>
                          </ul>

                          Meer info lees je in punt 7.
                        </li>

                        <li>
                          Wij zijn nooit aansprakelijk voor schade door hoe jij de app gebruikt. Wij
                          zijn ook niet aansprakelijk voor wat de andere teamleden doen, of voor hun
                          advies, fouten, weglatingen, verklaringen, waarborgen of overtredingen.
                        </li>

                        <li>
                          <p>
                            Wij zijn ‘beperkt aansprakelijk’. Zijn wij verantwoordelijk voor een
                            fout of slordigheid in of door de regels? Dan moeten we maximaal 10.000
                            euro schade betalen. Dat is per fout per jaar, hoe groot de schade ook
                            is. Schade die met elkaar te maken heeft, beschouwen we als één fout.
                          </p>

                          <p>
                            We betalen alleen voor schade die gebeurt door de fout van het
                            agentschap. En je moet dat ook kunnen bewijzen.
                          </p>
                        </li>

                        <li>
                          Gebruik je de app niet volgens onze regels, en vooral niet volgens artikel
                          3? Dan ben jij aansprakelijk voor alle schade. Ook als de schadeclaim van
                          andere mensen komt.
                        </li>
                      </ol>
                    </li>

                    <li>
                      <h2 class="typo-h3">Wat kunnen wij garanderen? En wat niet?</h2>

                      <ol>
                        <li>
                          We zorgen ervoor dat de app goed werkt. Wij doen ons best om aan alle
                          verplichtingen te voldoen en om de regels te volgen. Maar we kunnen niet
                          garanderen dat er geen fouten zijn, bijvoorbeeld in de software of in de
                          handleiding. Jij begrijpt en aanvaardt dat de app niet perfect is, en dat
                          we niet alle fouten kunnen oplossen. Je begrijpt en aanvaardt ook dat wij
                          geen garantie op succes geven
                        </li>

                        <li>
                          <p>
                            Beweren andere mensen dat je hun rechten schendt door de app te
                            gebruiken? Dan zullen wij jou beschermen en verdedigen.
                          </p>

                          <p>We kunnen jou niet verdedigen en beschermen:</p>

                          <ul>
                            <li>als jij de app verkeerd of onwettig gebruikt</li>

                            <li>als jij of iemand die je helpt, iets in de app verandert</li>

                            <li>als je de app niet op tijd updatet</li>

                            <li>
                              als je zonder onze toestemming de app gebruikt samen met producten die
                              niet van ons zijn
                            </li>
                          </ul>
                        </li>

                        <li>
                          Laat iemand jou weten dat die schade heeft omdat je met het gebruik van de
                          app zijn intellectuele eigendomsrechten hebt geschonden? Wij zorgen dat
                          jij geen schade of kosten hebt. Dat doen we alleen als aan deze
                          voorwaarden is voldaan:

                          <ul>
                            <li>Jij laat het ons meteen via brief of mail weten.</li>

                            <li>Wij bepalen hoe we de zaak behandelen.</li>

                            <li>Je werkt met ons mee als we dat vragen. Wij betalen de kosten.</li>

                            <li>
                              Je mag niets regelen zonder onze toestemming. Je zegt bijvoorbeeld
                              niets over onze aansprakelijkheid. Je mag ook niet akkoord gaan met
                              een schikking zonder onze toestemming. Een schikking is een afspraak
                              die twee partijen maken om een conflict te regelen buiten de rechtbank
                              om.
                            </li>
                          </ul>

                          Dat geldt voor schade en kosten die een rechtbank oplegt. Of die in een
                          schikking met onze toestemming zijn bepaald.
                        </li>

                        <li>
                          Denken wij dat er iemand schade gehad heeft? Dan kunnen wij de app
                          aanpassen. Zo voorkomen we dat er een klacht komt. We zorgen ervoor dat
                          jij de app kan blijven gebruiken.
                        </li>
                      </ol>
                    </li>
                  </ol>
                </section>
              </details>
            </article>
          </div>
        </div>

        <div class="pt-6">
          <parkour-checkbox [(ngModel)]="voorwaardenChecked">
            Ik aanvaard de gebruiksvoorwaarden
          </parkour-checkbox>
          <parkour-form-error-message
            [errorMessage]="'Aanvaard de gebruiksvoorwaarden.'"
            [show]="showVoorwaardenCheckedRequiredError"
          />
        </div>

        <parkour-button (clicked)="onNextStep()" class="w-full block pt-6" iconAfter="arrow-right">
          Volgende stap
        </parkour-button>
      </div>
    </div>
  </parkour-page-layout>
</ion-content>
