import { ApiProperty } from '@nestjs/swagger';

export class JitsiGesprekDto {
  readonly room!: string;
  readonly jwt!: string;
}

export type BeeldbelGesprekStatus = 'NIET_BEGONNEN' | 'GESTART' | 'GESTOPT';

export class Deelnemer {
  id!: string;
  joined!: boolean;
}

export class BeeldbelGesprekDto {
  readonly id!: string;
  @ApiProperty({ type: String })
  readonly status!: BeeldbelGesprekStatus;
  readonly eigenaar!: Deelnemer;
  readonly deelnemer!: Deelnemer;
}
