import { Component, Input } from "@angular/core";
import { ParkourImageComponent } from "../parkour-image/parkour-image.component";

@Component({
  standalone: true,
  selector: "parkour-banner",
  templateUrl: "./parkour-banner.component.html",
  imports: [ParkourImageComponent],
})
export class ParkourBannerComponent {
  @Input() public classes?: string;
  @Input({ required: true }) public src!: string;
  @Input({ required: true }) public alt!: string;
}
