import { Injectable } from '@angular/core';
import { from, Observable, switchMap } from 'rxjs';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { environment } from '../../environments/environment';
import { isNativeApp } from '../utils';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { LoggingService } from '../core/logging.service';

@Injectable({
  providedIn: 'root',
})
export class VersioningService {
  constructor(
    private http: HttpClient,
    private readonly loggingService: LoggingService,
    private router: Router,
  ) {}

  initializeVersionCheck() {
    if (!isNativeApp()) {
      return;
    }

    this.checkVersionValidity();
    App.addListener('resume', () => this.checkVersionValidity());
  }

  private isVersionValid(version: string, os: string): Observable<boolean> {
    return this.http.get<boolean>(`${environment.API_BASE_URL}/api/version/valid`, {
      params: {
        version,
        os,
      },
    });
  }

  private checkVersionValidity() {
    const appInfo = from(App.getInfo());
    const os = Capacitor.getPlatform();

    appInfo
      .pipe(
        switchMap((appInfo: { version: string }) => {
          return this.isVersionValid(appInfo.version, os);
        }),
      )
      .subscribe({
        next: (isVersionValid) => {
          if (!isVersionValid) {
            this.router.navigate(['/app/upgrade']);
            history.pushState(null, '');
          }
        },
        error: (e) => {
          this.loggingService.error('Could not determine version validity', e);
        },
      });
  }
}
