import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { TOnboardingSlides } from './onboarding.types';
import { ParkourCarouselModule, ParkourIconComponent, ParkourImageComponent } from '@parkour/ui';
import { NgForOf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SwiperContainer } from 'swiper/swiper-element';
import { AnalyticsService } from '../../../analytics/analytics.service';
import { AnalyticsEvent } from '../../../analytics/analytics-event.model';

@Component({
  standalone: true,
  selector: 'parkour-onboarding',
  templateUrl: './onboarding.component.html',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  encapsulation: ViewEncapsulation.None,
  imports: [
    ParkourImageComponent,
    NgForOf,
    ParkourCarouselModule,
    ParkourIconComponent,
    TranslateModule,
  ],
})
export class OnboardingComponent implements OnInit, OnDestroy {
  @Input({ required: true }) slides!: TOnboardingSlides;
  @ViewChild('swiperContainer', { static: true }) swiper!: ElementRef<SwiperContainer>;

  constructor(private readonly analyticsService: AnalyticsService) {}

  ngOnInit() {
    this.swiper.nativeElement.addEventListener('swiperslidechange', this.onSlideChange);
  }

  ngOnDestroy() {
    this.swiper.nativeElement.removeEventListener('swiperslidechange', this.onSlideChange);
  }

  private onSlideChange = () => {
    this.analyticsService.trackEvent(
      new AnalyticsEvent(
        'home',
        'carouselSlideVeranderd',
        String(this.swiper.nativeElement.swiper.activeIndex),
      ),
    );
  };
}
