import { inject } from '@angular/core';
import { GebeurtenisId } from 'parkour-web-app-dto';
import { Gebeurtenis, GebeurtenisUpsert } from '../../verhaal-shared/model/gebeurtenis';
import { TeamlidProfiel } from '../../../team/model/teamlid.model';
import { ParkourDataPage } from '../../../shared/parkour-data-page';
import { CustomPageTitle, PageTitle } from '../../../shared/custom-page-title';
import { VerhaalService } from '../../verhaal-shared/service/verhaal.service';
import { TeamService } from '../../../team/service/team.service';
import { ContextService } from '../../../shared/services/context.service';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, map, Observable, switchMap, take } from 'rxjs';
import { validateGebeurtenisId } from '../../verhaal-shared/utils';
import { Context } from '../../../shared/model/context';

type PageData = {
  gebeurtenis: Gebeurtenis;
  context: Context;
  teamleden: TeamlidProfiel[];
};

export abstract class GebeurtenisEditPage
  extends ParkourDataPage<PageData>
  implements CustomPageTitle
{
  protected readonly verhalenService = inject(VerhaalService);
  protected readonly teamService = inject(TeamService);
  protected readonly contextService = inject(ContextService);
  protected readonly router = inject(Router);
  protected readonly route = inject(ActivatedRoute);

  getCustomPageTitle(): Observable<PageTitle> {
    return this.mainPageData$.pipe(
      switchMap((result) => {
        if (result.success) {
          const detailKey =
            result.value.gebeurtenis.type === 'EYOUTH'
              ? 'verhaal.eyouth-gebeurtenis-edit-page.title'
              : 'verhaal.gebeurtenis-edit-page.title';

          return this.translateService.get(detailKey).pipe(
            map((detail) => ({
              title: result.value.gebeurtenis.titel,
              detail,
            })),
          );
        } else {
          return this.translateService.get('verhaal.gebeurtenis-edit-page.title');
        }
      }),
    );
  }

  override retrieveMainData() {
    const gebeurtenis$ = this.route.paramMap.pipe(
      take(1),
      map((params) => params.get('gebeurtenis-id')),
      map(validateGebeurtenisId),
      switchMap((id) => this.verhalenService.getGebeurtenis(id)),
    );

    return combineLatest({
      gebeurtenis: gebeurtenis$,
      teamleden: this.teamService.getMyTeamleden(),
      context: this.contextService.context$,
    });
  }

  getSubmitGeneratorForId =
    (gebeurtenisId: GebeurtenisId) => (gebeurtenisUpsert: GebeurtenisUpsert) => {
      return this.getCurrentMainDataOrThrow().pipe(
        switchMap((mainData) =>
          this.verhalenService.updateGebeurtenis(
            gebeurtenisId,
            gebeurtenisUpsert,
            mainData.gebeurtenis,
          ),
        ),
        switchMap(() => this.router.navigate(['../'], { relativeTo: this.route })),
        map(() => undefined),
      );
    };
}
