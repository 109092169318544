import { Directive, OnInit, ViewContainerRef } from '@angular/core';
import { BeeldbellenService } from '../beeldbellen.service';

@Directive({
  standalone: true,
  selector: '[parkourBeeldbellenAnchor]',
})
export class ParkourBeeldbellenAnchorDirective implements OnInit {
  constructor(
    public viewContainerRef: ViewContainerRef,
    private readonly beeldbellenService: BeeldbellenService,
  ) {}

  ngOnInit(): void {
    this.beeldbellenService.registerAnchor(this);
  }
}
