import { Component, Input } from '@angular/core';
import { ProfielFotoBadgeComponent } from '../profiel-foto-badge/profiel-foto-badge.component';
import { CoreModule } from '../../../core/core.module';
import { ParkourNewIndicatorComponent, ParkourTagComponent } from '@parkour/ui';
import { ProfessioneelIndicatorComponent } from '../professioneel-indicator/professioneel-indicator.component';
import { ProfielnaamPipe } from '../../pipes/profielnaam.pipe';
import { JongereProfiel } from '../../../profiel/model/profiel';
import { TeamlidProfiel } from '../../../team/model/teamlid.model';
import { RolLabelWithDefaultPipe } from '../../pipes/rol-label-with-default.pipe';

@Component({
  selector: 'parkour-profiel-block',
  templateUrl: './parkour-profiel-block.component.html',
  standalone: true,
  imports: [
    ProfielFotoBadgeComponent,
    CoreModule,
    ProfessioneelIndicatorComponent,
    ProfielnaamPipe,
    RolLabelWithDefaultPipe,
    ParkourTagComponent,
    ParkourNewIndicatorComponent,
  ],
})
export class ParkourProfielBlockComponent {
  @Input({ required: true }) public profielData!: JongereProfiel | TeamlidProfiel;
  @Input() public reverse = false;
  @Input() public showNewIndicator = false;
  @Input() customTag = false;
  protected readonly JSON = JSON;
}
