import { Component, Input, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Avatar } from 'parkour-web-app-dto';
import { ProfielFormService } from '../../../profiel/service/profiel-form.service';
import { ProfielFotoService } from '../../service/profiel-foto.service';
import {
  ParkourAvatarComponent,
  ParkourButtonComponent,
  ParkourModalComponent,
  ParkourPageImageComponent,
} from '@parkour/ui';
import { AsyncPipe, NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ProfielDetailsFotoModalComponent } from '../profiel-details-foto-modal/profiel-details-foto-modal.component';
import { SecureImagePipe } from '../../../shared/pipes/secure-image.pipe';

export type ProfielFotoFormGroup = FormGroup<{
  avatar: FormControl<Avatar>;
  profielFoto: FormControl<string | undefined>;
  profielFotoFile: FormControl<File | undefined>;
}>;

@Component({
  standalone: true,
  selector: 'parkour-profiel-details-foto-edit',
  templateUrl: './profiel-details-foto-edit.component.html',
  imports: [
    ParkourPageImageComponent,
    ParkourAvatarComponent,
    AsyncPipe,
    ParkourButtonComponent,
    NgIf,
    TranslateModule,
    ParkourModalComponent,
    ProfielDetailsFotoModalComponent,
    SecureImagePipe,
  ],
})
export class ProfielDetailsFotoEditComponent {
  @ViewChild(ParkourModalComponent) modal!: ParkourModalComponent;
  @Input({ required: true }) professioneel!: boolean;
  @Input({ required: true }) fotoFormgroup!: ProfielFotoFormGroup;

  modalFotoFormGroup: ProfielFotoFormGroup | undefined = undefined;

  constructor(
    private readonly profielFormService: ProfielFormService,
    private readonly profielFotoService: ProfielFotoService,
  ) {}

  getProfielFoto() {
    return this.fotoFormgroup.value.profielFoto
      ? this.fotoFormgroup.value.profielFoto
      : this.profielFotoService.getAvatarUrl(this.fotoFormgroup.getRawValue().avatar);
  }

  onAfbeeldingVerwijder() {
    this.clearFoto();
  }

  clearFoto() {
    this.fotoFormgroup.patchValue({
      profielFoto: undefined,
      profielFotoFile: undefined,
    });
    this.fotoFormgroup.markAsDirty();
  }

  dismissChooseImageModal() {
    this.modalFotoFormGroup = undefined;
    this.modal.close();
  }

  onAfbeeldingAanpassenClick() {
    this.modalFotoFormGroup = this.profielFormService.createProfielFotoFormGroup({
      ...this.fotoFormgroup.getRawValue(),
    });

    this.modal.open();
  }

  onFotoDetailsSaved(fotoDetails: {
    avatar: Avatar | undefined;
    profielFoto: string | undefined;
    profielFotoFile: File | undefined;
  }) {
    this.fotoFormgroup.patchValue({
      avatar: fotoDetails.avatar ?? this.fotoFormgroup.value.avatar,
      profielFoto: fotoDetails.profielFoto,
      profielFotoFile: fotoDetails.profielFotoFile,
    });
    this.fotoFormgroup.markAsDirty();
    this.dismissChooseImageModal();
  }
}
