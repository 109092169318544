@switch (bericht.gesprekEvent.type) {
  @case ('DEELNEMER_LEFT') {
    <parkour-event-user-left [gesprekEvent]="bericht.gesprekEvent" />
  }
  @case ('DEELNEMER_REJOINED') {
    <parkour-event-user-rejoined [gesprekEvent]="bericht.gesprekEvent" />
  }
  @case ('VIDEOGESPREK_ENDED') {
    <parkour-event-end-videogesprek
      [gesprekEvent]="bericht.gesprekEvent"
      [berichtTimestamp]="bericht.timestamp"
    />
  }
}
