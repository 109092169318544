import { IdOf } from '../branded-type';
import { ApiProperty } from '@nestjs/swagger';
import { Thema } from './thema.dto';
import { FileData, FotoId } from './gebeurtenis.dto';
import { ProfielId } from './profiel.dto';

export type SuggestieId = IdOf<'suggestie'>;
export type SuggestieStatus = 'ACTIEF' | 'AFGEWEZEN' | 'AANVAARD';

export class GebeurtenisSuggestieDto {
  @ApiProperty({ type: String })
  readonly id!: SuggestieId;
  @ApiProperty({ type: String })
  readonly suggestedById!: ProfielId;
  @ApiProperty({ type: String })
  readonly status!: SuggestieStatus;
  readonly titel!: string;
  readonly aangemaaktOpTijdstip!: string;
  readonly gebeurtenisData!: GebeurtenisDataDto;
}

class GebeurtenisDataDto {
  readonly titel?: string;
  readonly omschrijving?: string;
  readonly startDatum?: string;
  @ApiProperty({ type: String })
  readonly thema?: Thema;
  @ApiProperty({ type: String })
  readonly imageId?: FotoId;
  readonly bijlagen!: FileData[];
}

export class GebeurtenisSuggestieUpsertDto {
  readonly titel!: string;
  @ApiProperty({ type: String })
  readonly suggestedToId!: ProfielId;
  readonly gebeurtenisData!: GebeurtenisDataDto;
}
