<li>
  <article class="clickable-card-300 flex justify-between p-3 mb-2 rounded">
    <div class="flex flex-col gap-2">
      <p class="typo-body variant-body-fat">
        <parkour-action [fillContainer]="true" [href]="selfTest.url">
          {{ selfTest.title }}
        </parkour-action>
      </p>
      <p class="typo-body">{{ selfTest.link_text }}</p>
    </div>
    <parkour-icon [size]="'3'" class="flex text-s-100" name="link" />
  </article>
</li>
