import { Component, Input } from '@angular/core';
import { WatWatTextContent } from 'parkour-web-app-dto';
import { MakeReferencesAbsolutePipe } from '../../pipes/make-references-absolute.pipe';

@Component({
  selector: 'parkour-artikel-text-content',
  templateUrl: './artikel-text-content.component.html',
  styleUrls: ['./artikel-text-content.component.css'],
  standalone: true,
  imports: [MakeReferencesAbsolutePipe],
})
export class ArtikelTextContentComponent {
  @Input({ required: true }) content!: WatWatTextContent;
}
