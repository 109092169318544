import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';
import { Component } from '@angular/core';
import { ParkourHeaderActionComponent } from '../../../shared/components/parkour-header-action/parkour-header-action.component';
import { TranslateModule } from '@ngx-translate/core';
import { ParkourBackButtonDirective } from '../../../shared/directives/parkour-back-button.directive';
import { AsyncPipe } from '@angular/common';
import {
  ParkourButtonComponent,
  ParkourIconComponent,
  ParkourPageImageComponent,
} from '@parkour/ui';
import { DoelDetailsComponent } from '../../doel-shared/component/doel-details/doel-details.component';
import { ReactieOverviewComponent } from '../../doel-shared/component/reactie-overview/reactie-overview.component';
import { PageLayoutComponent } from '../../../shared/components/page-layout/page-layout.component';
import { PageContentDirective } from '../../../shared/directives/page-content.directive';
import { PageSectionDirective } from '../../../shared/directives/page-section.directive';
import { DoelBerichtenComponent } from '../../../bericht/component/doel-berichten/doel-berichten.component';
import { ShowIfSuccesfulDirective } from '../../../shared/directives/show-if-succesful.directive';
import { ParkourRefreshDirective } from '../../../shared/directives/parkour-refresh-directive';
import { ArtikelCardComponent } from '../../../watwat/components/artikel-card/artikel-card.component';
import { DoelDetailPage } from '../../doel-shared/page/doel-detail/doel-detail-page';
import { CustomPageTitle } from '../../../shared/custom-page-title';
import { ExJongereInfoCardComponent } from '../../../shared/components/ex-jongere-info-card/ex-jongere-info-card.component';

@Component({
  standalone: true,
  templateUrl: './doel-details-ex-jongere.page.html',
  imports: [
    ParkourHeaderActionComponent,
    TranslateModule,
    ParkourBackButtonDirective,
    AsyncPipe,
    ParkourPageImageComponent,
    DoelDetailsComponent,
    ParkourButtonComponent,
    ReactieOverviewComponent,
    PageLayoutComponent,
    PageContentDirective,
    PageSectionDirective,
    DoelBerichtenComponent,
    ShowIfSuccesfulDirective,
    IonRefresher,
    IonRefresherContent,
    ParkourRefreshDirective,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonTitle,
    IonContent,
    ParkourIconComponent,
    ArtikelCardComponent,
    ExJongereInfoCardComponent,
  ],
})
export class DoelDetailsExJongerePage extends DoelDetailPage implements CustomPageTitle {
  doelCompleteAnimationOpen = false;

  constructor() {
    super();
  }

  getCustomPageTitle() {
    return this.generateDefaultTitle((data) => data.doel.naam, 'doelen.doel-detail-page.title');
  }

  protected readonly String = String;
}
