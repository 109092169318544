import { HeroSources } from '../shared/components/page-with-hero/page-with-hero.types';

export const verhaalHeroSources: HeroSources = {
  background: '/assets/verhaal/background/background_verhaal_blur.jpg',
  hero: {
    sources: {
      lg: {
        src: '/assets/verhaal/background/lg/background_verhaal_lg.webp',
        fallback: '/assets/verhaal/background/lg/background_verhaal_lg.jpg',
      },
      md: {
        src: '/assets/verhaal/background/md/background_verhaal_md.webp',
        fallback: '/assets/verhaal/background/md/background_verhaal_md.jpg',
      },
      default: {
        src: '/assets/verhaal/background/default/background_verhaal_default.webp',
        fallback: '/assets/verhaal/background/default/background_verhaal_default.jpg',
      },
    },
  },
};

export const placeholderHeroSources: HeroSources = {
  background: '/assets/general/placeholder_image/background/placeholder_image_blur.jpg',
  hero: {
    sources: {
      lg: {
        src: '/assets/general/placeholder_image/lg/placeholder_image_lg.webp',
        fallback: '/assets/general/placeholder_image/lg/placeholder_image_lg.jpg',
      },
      md: {
        src: '/assets/general/placeholder_image/md/placeholder_image_md.webp',
        fallback: '/assets/general/placeholder_image/md/placeholder_image_md.jpg',
      },
      default: {
        src: '/assets/general/placeholder_image/default/placeholder_image_default.webp',
        fallback: '/assets/general/placeholder_image/default/placeholder_image_default.jpg',
      },
    },
  },
};
