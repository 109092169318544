<parkour-card [theme]="'n-200'" [icon]="'broken-link'" [iconTheme]="'n-100'">
  <div class="text-left flex-1">
    <p class="text-n-1000">
      <span>{{ 'profiel.meldingen-page.unsupported-melding' | translate }}</span>
    </p>
  </div>
  <div class="flex items-center gap-2">
    <p class="text-n-600 mt-1">{{ meldingView.timestamp | date: 'd MMMM HH:mm' }}</p>
  </div>
</parkour-card>
