import { BerichtenPage, BerichtId } from 'parkour-web-app-dto';
import { AttachmentBericht, Bericht, SpraakBericht, TextBericht } from '../../model/gesprek';
import { Subject } from 'rxjs';

export class BerichtenContainer {
  public berichten: Bericht[] = [];
  public readonly berichtAdded = new Subject<void>();
  private latestTimestamp: string | undefined;
  private oldestTimestamp: string | undefined;
  private berichtenIds = new Set<BerichtId>();

  private _isLastPage: boolean = false;

  get isLastPage(): boolean {
    return this._isLastPage;
  }

  getLatestBerichtTimestamp(): string | undefined {
    return this.latestTimestamp;
  }

  getOldestBerichtTimestamp(): string | undefined {
    return this.oldestTimestamp;
  }

  addOldBerichtenPage(berichtenPage: BerichtenPage): void {
    this._isLastPage = berichtenPage.last;
    this.addBerichten(berichtenPage.content);
  }

  addNewBerichtenPage(berichtenPage: BerichtenPage): void {
    this.addBerichten(berichtenPage.content);
  }

  deleteBericht(berichtId: BerichtId): void {
    this.clearOrigineelBerichtId(berichtId);
    this.berichtenIds.delete(berichtId);
    this.berichten = this.berichten.filter((b) => b.id !== berichtId);
  }

  private clearOrigineelBerichtId(berichtId: string & { __type: 'bericht' }) {
    this.berichten = this.berichten.map((bericht) => {
      if (this.isContentBericht(bericht) && bericht.origineelBerichtId === berichtId) {
        return {
          ...bericht,
          origineelBerichtId: undefined,
        };
      } else {
        return bericht;
      }
    });
  }

  private isContentBericht(
    bericht: Bericht,
  ): bericht is TextBericht | SpraakBericht | AttachmentBericht {
    return bericht.type === 'TEKST' || bericht.type === 'SPRAAK' || bericht.type === 'ATTACHMENT';
  }

  addBericht(bericht: Bericht): void {
    this.addBerichten([bericht]);
  }

  updateBericht(bericht: Bericht): void {
    this.berichten = this.berichten.map((b) => {
      if (b.id === bericht.id) {
        return bericht;
      }
      return b;
    });
  }

  reset(): void {
    this.berichten = [];
    this.berichtenIds.clear();
    this.latestTimestamp = undefined;
    this.oldestTimestamp = undefined;
    this._isLastPage = false;
  }

  private compareBerichtByTimestamp(bericht1: Bericht, bericht2: Bericht): number {
    return new Date(bericht1.timestamp) > new Date(bericht2.timestamp) ? 1 : -1;
  }

  private addBerichten(berichten: Bericht[]): void {
    const newBerichten = berichten.filter((bericht) => !this.berichtenIds.has(bericht.id));

    if (newBerichten.length === 0) {
      return;
    }

    for (const bericht of newBerichten) {
      this.berichtenIds.add(bericht.id);
    }

    if (
      !this.latestTimestamp ||
      new Date(newBerichten[0].timestamp) > new Date(this.latestTimestamp)
    ) {
      this.latestTimestamp = newBerichten[0].timestamp;
    }

    if (
      !this.oldestTimestamp ||
      new Date(newBerichten[newBerichten.length - 1].timestamp) < new Date(this.oldestTimestamp)
    ) {
      this.oldestTimestamp = newBerichten[newBerichten.length - 1].timestamp;
    }

    this.berichten = this.berichten.concat(newBerichten).sort(this.compareBerichtByTimestamp);

    this.berichtAdded.next();
  }
}
