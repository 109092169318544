<parkour-form
  [errorLabelMapping]="{
    doelDataSection: {
      naam: 'doelen.doel-toevoegen-page.form.naam' | translate,
      thema: 'doelen.doel-toevoegen-page.form.thema' | translate,
      omschrijving: 'doelen.doel-toevoegen-page.bericht' | translate,
      streefDatum: 'doelen.doel-toevoegen-page.form.streefdatum' | translate,
    },
    actieSection: {
      acties: false,
    },
    gedeeldMetSection: {
      gedeeldMet: false,
      deelMode: false,
    },
  }"
  [formGroup]="doelForm"
  [formId]="formId"
  [submitObservableGenerator]="submitDoel"
>
  <div class="flex flex-col gap-y-8">
    <parkour-doel-edit-data-section
      [doelFormGroup]="doelForm.controls.doelDataSection"
      [initialState]="initialState"
    />

    <div class="flex flex-col gap-y-4 container-1-col">
      <h2 class="typo-h3">{{ 'doelen.doel-toevoegen-page.acties.title' | translate }}</h2>

      <parkour-acties-select-section [actieSectionGroup]="doelForm.controls.actieSection" />
    </div>

    <div class="flex flex-col gap-y-6 container-1-col">
      <div class="space-y-2">
        <h2 class="typo-h3">{{ 'doelen.doel-toevoegen-page.delen.title' | translate }}</h2>

        <p>{{ 'doelen.doel-toevoegen-page.delen.description' | translate }}</p>
      </div>

      <parkour-delen-section
        [delenFormGroup]="doelForm.controls.gedeeldMetSection"
        [teamleden]="teamleden"
      />
    </div>
  </div>
</parkour-form>
