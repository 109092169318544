import { Component, Input, ViewEncapsulation } from "@angular/core";

import { TParkourImageFit, TParkourImagePosition } from "./parkour-image.types";
import { NgClass, NgIf } from "@angular/common";
import { IonImg } from "@ionic/angular/standalone";

@Component({
  standalone: true,
  selector: "parkour-image",
  templateUrl: "./parkour-image.component.html",
  encapsulation: ViewEncapsulation.None,
  imports: [NgIf, NgClass, IonImg],
})
export class ParkourImageComponent {
  @Input() public classes?: string;
  @Input() public src: string | null = null;
  @Input() public alt: string = "";
  @Input() public fit: TParkourImageFit = "cover";
  @Input() public position: TParkourImagePosition = "center";
  @Input() public rounded = false;

  getBorderRadius() {
    return `--parkour-image-border-radius: ${this.rounded ? "100vmax" : "0"}`;
  }
}
