import { Component } from '@angular/core';
import {
  IonContent,
  IonHeader,
  IonRefresher,
  IonRefresherContent,
} from '@ionic/angular/standalone';
import { PageLayoutComponent } from '../../../../shared/components/page-layout/page-layout.component';
import {
  ParkourDividerComponent,
  ParkourHeroComponent,
  ParkourPageImageComponent,
} from '@parkour/ui';
import { TranslateModule } from '@ngx-translate/core';
import { AanmeldButtonComponent } from '../../../../shared/components/aanmeld-button/aanmeld-button.component';
import { DoelenPlaceholderComponent } from '../../component/doelen-placeholder/doelen-placeholder.component';
import { ParkourRefreshDirective } from '../../../../shared/directives/parkour-refresh-directive';
import { ParkourPage } from '../../../../shared/parkour-page';
import { PageWithHeroComponent } from '../../../../shared/components/page-with-hero/page-with-hero.component';
import { doelHeroSources } from '../../constants';

@Component({
  standalone: true,
  templateUrl: './doel-empty-state-not-aangemeld.page.html',
  imports: [
    IonHeader,
    IonContent,
    PageLayoutComponent,
    ParkourPageImageComponent,
    ParkourHeroComponent,
    TranslateModule,
    ParkourDividerComponent,
    AanmeldButtonComponent,
    DoelenPlaceholderComponent,
    IonRefresher,
    IonRefresherContent,
    ParkourRefreshDirective,
    PageWithHeroComponent,
  ],
})
export class DoelenEmptyStateNotAangemeldPage extends ParkourPage {
  protected readonly doelHeroSources = doelHeroSources;
}
