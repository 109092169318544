import { HeroSources } from '../../shared/components/page-with-hero/page-with-hero.types';

export const berichtenHero: HeroSources = {
  background: '/assets/berichten/background/background_berichten_blur.jpg',
  hero: {
    sources: {
      lg: {
        src: '/assets/berichten/background/lg/background_berichten_lg.webp',
        fallback: '/assets/berichten/background/lg/background_berichten_lg.jpg',
      },
      md: {
        src: '/assets/berichten/background/md/background_berichten_md.webp',
        fallback: '/assets/berichten/background/md/background_berichten_md.jpg',
      },
      default: {
        src: '/assets/berichten/background/default/background_berichten_default.webp',
        fallback: '/assets/berichten/background/default/background_berichten_default.jpg',
      },
    },
  },
};
