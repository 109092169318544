import { Component, Input } from '@angular/core';
import { GebeurtenisErrorCardComponent } from '../gebeurtenis-error-card/gebeurtenis-error-card.component';
import {
  GebeurtenisListItemComponent,
  GebeurtenisMelding,
} from '../gebeurtenis-list-item/gebeurtenis-list-item.component';
import { VerhaalPlaceholderComponent } from '../verhaal-placeholder/verhaal-placeholder.component';
import { VerhaalDto } from 'parkour-web-app-dto';

@Component({
  selector: 'parkour-gebeurtenis-list',
  standalone: true,
  imports: [
    GebeurtenisErrorCardComponent,
    GebeurtenisListItemComponent,
    VerhaalPlaceholderComponent,
  ],
  templateUrl: './gebeurtenis-list.component.html',
})
export class GebeurtenisListComponent {
  @Input({ required: true }) verhaal!: VerhaalDto;
  @Input({ required: true }) ongelezenGebeurtenisMeldingen!: GebeurtenisMelding[];
  @Input() placeholderEnabled: boolean = true;
}
