import { Component, Input } from '@angular/core';
import { Gesprek } from '../../model/gesprek';
import { getRolLabel } from '../../../profiel/rol-labels';
import { getProfielVolledigeNaam } from 'parkour-web-app-dto';
import { ParkourProfielBlockComponent } from '../../../shared/components/parkour-profiel-block/parkour-profiel-block.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgForOf } from '@angular/common';

@Component({
  standalone: true,
  selector: 'parkour-groepsgesprek-deelnemer-list',
  templateUrl: './groepsgesprek-deelnemer-list.component.html',
  imports: [ParkourProfielBlockComponent, TranslateModule, NgForOf],
})
export class GroepsgesprekDeelnemerListComponent {
  @Input({ required: true }) gesprek!: Gesprek;

  protected readonly getRolLabel = getRolLabel;
  protected readonly getProfielVolledigeNaam = getProfielVolledigeNaam;
}
