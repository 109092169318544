import { Component, Input } from '@angular/core';
import { WatWatHtmlEmbedLargeContent } from 'parkour-web-app-dto';

@Component({
  selector: 'parkour-artikel-html-embed-large-content',
  templateUrl: './artikel-html-embed-large-content.component.html',
  standalone: true,
})
export class ArtikelHtmlEmbedLargeContentComponent {
  @Input({ required: true }) content!: WatWatHtmlEmbedLargeContent;
}
