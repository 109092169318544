import { Injectable } from '@angular/core';
import { map, Observable, of, shareReplay, switchMap } from 'rxjs';
import { CapacitorHttp } from '@capacitor/core';
import { LoggingService } from '../../core/logging.service';
import { SilentError } from '../../core/silent-error';
import { backoffRetry } from '../../utils';

@Injectable({
  providedIn: 'root',
})
export class ImagesService {
  private cachedImageObservables = new Map<string, Observable<string>>();

  constructor(private readonly loggingService: LoggingService) {}

  getImage(url: string): Observable<string> {
    this.cachedImageObservables.has(url);

    if (!this.cachedImageObservables.has(url)) {
      const imageObservable = of(url).pipe(
        switchMap((imageUrl) => CapacitorHttp.get({ url: imageUrl, responseType: 'blob' })),
        map((image) => {
          if (typeof image.data === 'string') {
            return image.data;
          } else {
            throw new SilentError('Failed to retrieve image');
          }
        }),
        backoffRetry({ count: 3, delay: 500 }),
        map((imageData) => `data:image/png;base64,${imageData}`),
        shareReplay({ bufferSize: 1, refCount: false }),
      );
      this.cachedImageObservables.set(url, imageObservable);
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this.cachedImageObservables.get(url)!;
  }
}
