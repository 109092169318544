export type TParkourIconTypes = "default" | "inline";

export type TParkourIconSizes = "default" | "1" | "2" | "3" | "4" | "7";

export type TParkourIconName = keyof typeof icons;
export const icons = {
  "add-reaction": "add-reaction-off-15-3-round",
  "ai-images": "ai-images-off-15-3-round",
  alert: "alert-off-15-3-round",
  "alert-triangle": "alert-triangle-off-15-3-round",
  "arrow-left": "arrow-left-off-15-3-round",
  "arrow-right": "arrow-right-off-15-3-round",
  "badge-on": "badge-on-15-3-round",
  "broken-link": "broken-link-off-15-3-round",
  "chevron-bottom": "chevron-bottom-off-15-3-round",
  "chevron-down": "chevron-down-off-15-3-round",
  "chevron-left": "chevron-left-off-15-3-round",
  "chevron-right": "chevron-right-off-15-3-round",
  "chevron-top": "chevron-top-off-15-3-round",
  "circle-check": "circle-check-off-15-round",
  "close-circle": "close-circle-off-15-3-round",
  "cloud-download": "cloud-download-off-15-3-round",
  "eye-off": "eye-off-off-15-3-round",
  "info-circle": "info-circle-off-15-3-round",
  "life-buoy": "life-buoy-off-2-3-round",
  "lock-2": "lock-off-15-3-round",
  "menu-simple": "menu-simple-off-15-3-round",
  "paper-plane": "paper-plane-off-15-3-round",
  "people-2": "people2-off-15-3-round",
  "people-3": "people-off-15-3-round",
  "people-blocked": "people-blocked-off-15-3-round",
  "people-group": "people-group-off-15-3-round",
  "people-remove": "people-remove-off-15-3-round",
  "plus-circle": "plus-circle-off-15-3-round",
  "qr-code": "qr-code-on-15-3-round",
  "video-camera": "video-camera-off-15-3-round",
  addpeople: "addpeople-off-15-3-round",
  attachment: "attachment-off-15-3-round",
  block: "block-off-15-3-round",
  book: "book-off-15-3-round",
  bookmark: "bookmark-off-15-3-round",
  "bookmark-fill": "bookmark-on-15-3-round",
  bubble: "bubble-off-15-3-round",
  bubbles: "bubbles-off-15-3-round",
  calendar: "calendar-off-15-3-round",
  camera: "camera-off-15-3-round",
  check: "check-off-15-3-round",
  clock: "clock-off-15-3-round",
  compass: "compass-off-15-3-round",
  cookies: "cookies-off-15-3-round",
  cross: "cross-off-15-3-round",
  disk: "disk-off-15-3-round",
  download: "download-off-15-3-round",
  earth: "earth-off-15-3-round",
  edit: "edit-off-15-3-round",
  email: "email-off-15-3-round",
  file: "file-off-15-3-round",
  home: "home-off-15-3-round",
  images: "images4-off-15-3-round",
  link: "link3-off-15-3-round",
  loader: "loader-off-15-3-round",
  lock: "lock-off-15-3-round",
  login: "login-off-15-3-round",
  logout: "logout-off-15-3-round",
  menu: "menu-off-15-3-round",
  microphone: "microphone-off-15-3-round",
  notifications: "notifications-off-15-3-round",
  pause: "pause-off-15-3-round",
  people: "people2-off-15-3-round",
  play: "play-off-15-3-round",
  plus: "plus-off-15-3-round",
  record: "record-off-15-3-round",
  search: "search-off-15-3-round",
  settings: "settings-off-15-3-round",
  smile: "smile-off-15-3-round",
  sort: "sort2-off-15-3-round",
  target: "target-off-15-3-round",
  telephone: "telephone-off-15-3-round",
  trash: "trash2-off-15-3-round",
  "calendar-video": "calendar-video-off-15-3-round",
  reply: "reply-off-15-3-round",
  share: "share-off-15-3-round",
  "clock-snooze": "clock-snooze-off-15-3-round",
  "calendar-add": "calendar-add-off-15-3-round",
  pin: "pin-off-15-3-round",
} as const;
