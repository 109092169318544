<div [ngClass]="classes" class="grid grid-cols-1 grid-rows-[auto_50px_1fr]">
  <div class="relative col-start-1 col-end-2 row-start-1 row-end-3">
    <parkour-image
      *ngIf="image.src"
      [ngClass]="{ rounded: !fullScreen }"
      [src]="image.src"
      class="block aspect-video overflow-hidden w-full h-[16.5rem]"
    />

    <parkour-picture
      *ngIf="image.sources"
      [ngClass]="{ rounded: !fullScreen }"
      [sources]="image.sources"
      class="block overflow-hidden"
      fetchPriority="high"
    />

    <div
      class="absolute bottom-0 left-0 w-full h-full pointer-events-none bg-gradient-to-t from-n-100"
      role="presentation"
    ></div>
  </div>

  <div class="relative col-start-1 col-end-2 row-start-2 row-end-4 z-10">
    <ng-content></ng-content>
  </div>
</div>
