<div [cdkTrapFocusAutoCapture]="true" cdkTrapFocus class="grow flex items-center gap-x-2">
  <parkour-button
    (clicked)="onStopRecordingMode()"
    [ariaLabel]="'buttons.sluiten' | translate"
    class="flex"
    format="iconButton"
    icon="cross"
    size="medium"
    variant="ghost"
  />

  <parkour-bericht-recorder
    (recordingComplete)="onRecordingComplete($event)"
    (recordingFailed)="onRecordingFailed()"
    [isAudioPlayer]="mode === 'AUDIO_PLAYING'"
    [isRecording]="mode === 'RECORDING'"
    class="flex-1"
  />

  <parkour-button
    (clicked)="onStopRecording()"
    *ngIf="mode === 'RECORDING'"
    [ariaLabel]="'buttons.stop-recording' | translate"
    cdkFocusInitial
    format="iconButton"
    icon="record"
    size="medium"
  />

  <parkour-button
    #sendButton
    (clicked)="onSendRecording()"
    *ngIf="mode === 'AUDIO_PLAYING'"
    [ariaLabel]="'buttons.send-recording' | translate"
    format="iconButton"
    icon="paper-plane"
    size="medium"
  />
  <!--            [disabled]="isAudioLoading" TODO-->
</div>
