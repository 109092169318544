import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { FailureType } from '../../../utils';
import {
  ParkourActionComponent,
  ParkourButtonComponent,
  ParkourLottiePlayerComponent,
} from '@parkour/ui';
import { getActiveTab, Tab } from '../../../tabs/routes';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  standalone: true,
  selector: 'parkour-error-component',
  templateUrl: './error.component.html',
  imports: [ParkourLottiePlayerComponent, ParkourButtonComponent, ParkourActionComponent],
})
export class ErrorComponent implements OnInit {
  @Input() public errorType: FailureType = 'not-found';
  @Input()
  public refresh: (() => void) | 'none' = 'none';
  protected readonly environment = environment;
  protected activeTab: Tab | undefined = undefined;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
  ) {}

  ngOnInit(): void {
    this.activeTab = getActiveTab(this.activatedRoute.snapshot);
  }

  onClickNavigateRootTab() {
    const activeTab = getActiveTab(this.activatedRoute.snapshot);

    if (activeTab) {
      this.router.navigate(['/', 'app', 'me', activeTab], { onSameUrlNavigation: 'reload' });
    } else {
      this.router.navigate(['/'], { onSameUrlNavigation: 'reload' });
    }
  }

  onClickNavigateHome() {
    this.router.navigate(['/'], { onSameUrlNavigation: 'reload' });
  }
}
