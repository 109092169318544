import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { provideLottieOptions } from "ngx-lottie";
import { ParkourCarouselModule } from "./parkour-carousel/parkour-carousel.module";
import { ParkourFocusVisibleModule } from "./parkour-focus-visible/parkour-focus-visible.module";

export function playerFactory() {
  return import("lottie-web/build/player/lottie_svg");
}

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    ReactiveFormsModule,
    ParkourCarouselModule,
    ParkourFocusVisibleModule,
    FormsModule,
  ],
  exports: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    provideLottieOptions({
      player: playerFactory,
    }),
  ],
})
export class ParkourUiComponentsModule {}
