import { TOnboardingSlides } from '../app/home/component/onboarding/onboarding.types';

const aanDeSlagData: TOnboardingSlides = [
  {
    image: {
      src: '/assets/home/onboarding/team_default.png',
      alt: '',
    },
    icon: 'people-group',
    title: 'Team',
    description: 'Stel een team samen van mensen die je kunnen ondersteunen.',
  },
  {
    image: {
      src: '/assets/home/onboarding/doelen_default.png',
      alt: '',
    },
    icon: 'target',
    title: 'Doelen',
    description: 'Werk aan je persoonlijke doelen en vind motivatie bij je team.',
  },
  {
    image: {
      src: '/assets/home/onboarding/berichten_default.png',
      alt: '',
    },
    icon: 'paper-plane',
    title: 'Berichten',
    description: 'Communiceer en werk samen met je team in alle veiligheid en vertrouwen.',
  },
  {
    image: {
      src: '/assets/home/onboarding/ondersteuning_default.png',
      alt: '',
    },
    icon: 'life-buoy',
    title: 'Ondersteuning',
    description: 'Vind snel en eenvoudig betrouwbare informatie en hulp.',
  },
  {
    image: {
      src: '/assets/home/onboarding/verhaal_default.png',
      alt: '',
    },
    icon: 'compass',
    title: 'Verhaal',
    description: 'Maak een overzicht met voor jou belangrijke gebeurtenissen en documenten.',
  },
];
// TODO: Aan de slag copy aan JSON toevoegen?
export default aanDeSlagData;
