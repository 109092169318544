<div
  *ngIf="src"
  class="w-full h-[15rem] flex items-center justify-center overflow-hidden opacity-90 {{
    classes
  }}"
  role="presentation"
>
  <parkour-image
    [src]="src"
    class="flex w-full h-full blur-[20px] scale-110"
    classes="relative z-0"
    fit="cover"
  />

  <div
    class="absolute inset-0 z-10 w-full h-full bg-gradient-to-b from-transparent to-n-100 to-80%"
  ></div>
</div>
