import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import AuthService from '../../../../authentication/service/auth.service';
import { Router } from '@angular/router';
import { MeldingenService } from '../../../../profiel/service/meldingen.service';
import { ParkourActionComponent, ParkourBadgeComponent, ParkourIconComponent } from '@parkour/ui';
import {
  meldingenForKalenderPage,
  meldingenForMeldingenPage,
  meldingenForMijnProfielPage,
  meldingenForTeamPage,
} from '../../../../meldingen/config';
import { ParkourPopoverDirective } from '../../../directives/parkour-popover.directive';
import { PageLayoutSidebarNavItemComponent } from '../../page-layout-sidebar-nav-item/page-layout-sidebar-nav-item.component';
import { IonPopover } from '@ionic/angular/standalone';

@Component({
  selector: 'parkour-sidebar-profiel-popover',
  standalone: true,
  imports: [
    AsyncPipe,
    TranslateModule,
    ParkourPopoverDirective,
    ParkourActionComponent,
    ParkourIconComponent,
    ParkourBadgeComponent,
    PageLayoutSidebarNavItemComponent,
    IonPopover,
  ],
  templateUrl: './sidebar-profiel-popover.component.html',
})
export class SidebarProfielPopoverComponent {
  @Input({ required: true }) triggerId!: string;

  @Output() hasPresented = new EventEmitter<void>();
  @Output() hasDismissed = new EventEmitter<void>();

  wisselTeamIndicator$ = this.meldingenService.hasWisselTeamMeldingen();
  hasKalenderMeldingen$ = this.meldingenService.hasMeldingenInCurrentContextByType(
    meldingenForKalenderPage.allMeldingen,
  );
  hasMijnTeamMeldingen$ = this.meldingenService.hasMeldingenInCurrentContextByType(
    meldingenForTeamPage.allMeldingen,
  );
  hasMeldingenPageMeldingen$ = this.meldingenService.hasMeldingenInCurrentContextByType(
    meldingenForMeldingenPage.allMeldingen,
  );
  hasMijnProfielMeldingen$ = this.meldingenService.hasMeldingenInCurrentContextByType(
    meldingenForMijnProfielPage.allMeldingen,
  );

  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly meldingenService: MeldingenService,
  ) {}

  closeUitloggen() {
    this.authService.logout().subscribe(() => this.router.navigateByUrl('/'));
  }

  willPresent() {
    this.hasPresented.emit();
  }

  willDismiss() {
    this.hasDismissed.emit();
  }
}
