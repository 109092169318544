<ul class="space-y-2">
  <li
    *ngFor="let attachment of content.content.attachments"
    class="relative flex items-center justify-between w-full bg-n-300 rounded p-4"
  >
    <div class="space-y-2">
      <parkour-action [href]="attachment.file" classes="artikel-attachment-content__action">
        <p class="typo-body variant-body-fat">{{ attachment.title }}</p>
      </parkour-action>
    </div>

    <parkour-icon class="text-s-100" name="cloud-download" size="3" />
  </li>
</ul>
