import { AfterContentChecked, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { ParkourBackButtonDirective } from '../../../shared/directives/parkour-back-button.directive';
import { TranslateModule } from '@ngx-translate/core';
import { ParkourHeaderActionComponent } from '../../../shared/components/parkour-header-action/parkour-header-action.component';
import {
  DoelForm,
  DoelFormComponent,
} from '../../doel-shared/component/doel-form/doel-form.component';
import { ParkourPageGradientComponent, ParkourPopupService } from '@parkour/ui';
import { AsyncPipe } from '@angular/common';
import { PageLayoutComponent } from '../../../shared/components/page-layout/page-layout.component';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  ViewWillEnter,
} from '@ionic/angular/standalone';
import { DirtyFormCheck } from '../../../shared/components/dirty-form-guard';
import {
  firstValueFrom,
  from,
  map,
  Observable,
  OperatorFunction,
  shareReplay,
  switchMap,
  takeWhile,
  tap,
} from 'rxjs';
import { TeamlidProfiel } from '../../../team/model/teamlid.model';
import { DoelUpsert } from '../../doel-shared/model/doel-upsert';
import { SuggestieId, Thema } from 'parkour-web-app-dto';
import { DoelenService } from '../../doel-shared/service/doelen.service';
import { TeamService } from '../../../team/service/team.service';
import { ActivatedRoute, Router } from '@angular/router';
import { validatedSuggestieId } from '../../../verhaal/verhaal-shared/model/gebeurtenis';
import { RawValueOfFormGroup } from '../../../shared/components/parkour-form/parkour-form.types';

@Component({
  standalone: true,
  templateUrl: './doel-add.page.html',
  imports: [
    ParkourBackButtonDirective,
    TranslateModule,
    ParkourHeaderActionComponent,
    DoelFormComponent,
    AsyncPipe,
    PageLayoutComponent,
    IonHeader,
    IonBackButton,
    IonToolbar,
    IonButtons,
    IonTitle,
    IonContent,
    ParkourPageGradientComponent,
  ],
})
export class DoelAddPage implements ViewWillEnter, AfterContentChecked, DirtyFormCheck {
  teamleden$!: Observable<Array<TeamlidProfiel>>;

  initialState: Partial<DoelUpsert> = {};
  initialStateLoaded = false;
  originSuggestieId?: SuggestieId;
  private shareWarningPopped = false;
  @ViewChild('doelForm') doelForm?: DoelFormComponent;

  constructor(
    private readonly doelenService: DoelenService,
    private readonly teamService: TeamService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly parkourPopupService: ParkourPopupService,
  ) {}

  async ionViewWillEnter(): Promise<void> {
    this.teamleden$ = this.teamService.getMyTeamleden().pipe(shareReplay(1));

    const params = await firstValueFrom(this.route.queryParamMap);

    if (params.has('suggestieId')) {
      this.originSuggestieId = validatedSuggestieId(params.get('suggestieId'));
      const suggestie = await firstValueFrom(
        this.doelenService.getDoelSuggestie(this.originSuggestieId),
      );

      this.initialState = suggestie.doelData;
    }

    if (params.has('thema')) {
      this.initialState = {
        ...this.initialState,
        thema: params.get('thema') as Thema,
      };
    }

    this.initialStateLoaded = true;
  }

  isFormDirty() {
    return this.doelForm?.doelForm.dirty ?? false;
  }

  ngAfterContentChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  submitDoel = (formValues: RawValueOfFormGroup<DoelForm>): Observable<void> => {
    return this.teamleden$.pipe(
      switchMap((teamleden) =>
        from(this.showShareWarning(formValues, teamleden)).pipe(
          this.mapShareWarning(() => this.doelForm?.doelForm.markAsDirty()),
          takeWhile((value) => value === 'save-form'),
          switchMap(() => {
            if (formValues.doelDataSection.thema === '') {
              throw new Error('Thema is verplicht');
            }

            const doelUpsert: DoelUpsert = {
              naam: formValues.doelDataSection.naam,
              omschrijving: formValues.doelDataSection.omschrijving,
              thema: formValues.doelDataSection.thema,
              streefDatum:
                formValues.doelDataSection.streefDatum !== ''
                  ? new Date(formValues.doelDataSection.streefDatum)
                  : undefined,
              acties: formValues.actieSection.acties.map((actie) => ({
                naam: actie.naam,
                voltooid: actie.voltooid,
                order: actie.order,
              })),
              deelMode: formValues.gedeeldMetSection.deelMode,
              gedeeldMet: teamleden
                .filter(
                  (teamLid, index) => formValues.gedeeldMetSection.gedeeldMet[index].gedeeldMet,
                )
                .map((teamlid) => teamlid.id),
              originSuggestieId: this.originSuggestieId,
            };

            return this.doelenService
              .addDoel(doelUpsert)
              .pipe(tap(() => this.router.navigate(['..'], { relativeTo: this.route })));
          }),
        ),
      ),
    );
  };

  private async showShareWarning(
    formValues: RawValueOfFormGroup<DoelForm>,
    teamleden: TeamlidProfiel[],
  ) {
    if (
      teamleden.length > 0 &&
      !this.shareWarningPopped &&
      formValues.gedeeldMetSection.deelMode === 'SPECIFIEK' &&
      formValues.gedeeldMetSection.gedeeldMet.filter((gedeeld) => gedeeld.gedeeldMet).length === 0
    ) {
      return this.parkourPopupService.showPopup({
        icon: 'alert-triangle',
        title: 'Doel met niemand gedeeld',
        description: 'Je deelt dit doel niet met een teamlid. Wil je het delen nog aanpassen?',
        jaText: 'Ja, delen aanpassen',
        neeText: 'Nee, opslaan',
      });
    }
    return undefined;
  }

  private mapShareWarning(
    stayOnFormCallback: () => void,
  ): OperatorFunction<unknown, 'stay-on-form' | 'save-form'> {
    return map((result) => {
      this.shareWarningPopped = true;
      if (result === 'yes') {
        document
          .querySelector('#doel-delen-section')
          ?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        stayOnFormCallback();
        return 'stay-on-form';
      } else {
        return 'save-form';
      }
    });
  }
}
