<ion-header></ion-header>

<ion-content>
  <ion-refresher [parkourRefresh]="onPageRefresh" slot="fixed">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>

  <parkour-page-layout>
    <parkour-page-with-hero
      [heroImage]="doelHeroSources"
      [title]="'doelen.doelen-page.title' | translate"
    >
      <ng-container *parkourSectionContent="{ data: mainPageData$ | async }; let data">
        @if (
          data.actieveDoelen.length > 0 ||
          data.behaaldeDoelen.length > 0 ||
          data.voorstellen.length > 0
        ) {
          <ng-container *parkourSectionContent="{ data: segmentItems$ | async }; let segmentItems">
            <div class="container-1-col">
              <parkour-segment
                (changed)="segmentChanged($event)"
                [defaultValue]="activeSegmentItem"
                [items]="segmentItems"
              />
            </div>

            <div class="container-1-col">
              @switch (activeSegmentItem) {
                @case ('actieveDoelen') {
                  <div class="flex flex-col gap-y-6">
                    <parkour-button class="w-full" iconAfter="plus-circle" link="add">
                      {{ 'doelen.doelen-page.nieuw-doel' | translate }}
                    </parkour-button>

                    @if (data.voorstellen.length > 0) {
                      <ul class="space-y-6">
                        @for (voorstel of data.voorstellen; track voorstel.id) {
                          <li>
                            <parkour-doel-voorstel
                              (confirm)="onVoorstelConfirm(voorstel)"
                              (reject)="onVoorstelReject(voorstel)"
                              [canConfirm]="true"
                              [doelSuggestie]="voorstel"
                            />
                          </li>
                        }
                      </ul>
                    }

                    <parkour-doel-list
                      [doelen]="data.actieveDoelen"
                      [ongelezenDoelMeldingen$]="ongelezenDoelMeldingen$"
                      [geenDoelenText]="'doelen.doelen-page.geen-doelen' | translate"
                    />
                  </div>
                }
                @case ('behaaldeDoelen') {
                  <div class="flex flex-col gap-y-8">
                    <parkour-doel-list
                      [doelen]="data.behaaldeDoelen"
                      [ongelezenDoelMeldingen$]="ongelezenDoelMeldingen$"
                      [geenDoelenText]="'doelen.behaalde-doelen-page.geen-doelen' | translate"
                    />
                  </div>
                }
              }
            </div>
          </ng-container>
        } @else {
          <div class="container-1-col space-y-8">
            <p class="text-center">{{ 'doelen.doelen-page.geen-doelen' | translate }}</p>

            <parkour-button class="w-full block" iconAfter="plus-circle" link="add">
              {{ 'doelen.doelen-page.nieuw-doel' | translate }}
            </parkour-button>

            <parkour-doelen-placeholder />
          </div>
        }
      </ng-container>
    </parkour-page-with-hero>
  </parkour-page-layout>
</ion-content>
