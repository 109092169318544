<ul class="flex items-center gap-2" role="tablist">
  @for (filter of filters; track filter.value; let index = $index) {
    <li role="tab" [attr.aria-selected]="activeIndex === index">
      <parkour-button
        [ariaPressed]="activeIndex === index"
        classes="{{ activeIndex === index ? 'active' : '' }}"
        (clicked)="onClick(index)"
        [iconBefore]="filter.icon"
        variant="quaternary"
        size="small"
      >
        <span class="uppercase typo-body variant-body-small">{{ filter.label }}</span>
      </parkour-button>
    </li>
  }
</ul>
