import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { DirtyFormCheck } from '../../../shared/components/dirty-form-guard';
import { GesprekDeelnemerUpsert, GroepsgesprekInsert } from '../../model/gesprek';
import { JongereContext, TeamlidContext } from '../../../shared/model/context';
import { TeamlidProfiel } from '../../../team/model/teamlid.model';
import { ParkourButtonComponent, ParkourFormFieldWithoutLabelComponent } from '@parkour/ui';
import { ParkourTeamlidCheckboxComponent } from '../../../shared/components/teamlid-checkbox/parkour-teamlid-checkbox.component';
import { NgForOf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { Profiel } from '../../../profiel/model/profiel';

export type TeamlidAddedToChatGroup = FormGroup<{
  teamleden: FormArray<TeamlidAddedGroup>;
}>;

export type TeamlidAddedGroup = FormGroup<{
  checked: FormControl<boolean>;
}>;

@Component({
  standalone: true,
  selector: 'parkour-groepsgesprek-form',
  templateUrl: './groepsgesprek-form.component.html',
  imports: [
    ReactiveFormsModule,
    ParkourFormFieldWithoutLabelComponent,
    ParkourTeamlidCheckboxComponent,
    NgForOf,
    ParkourButtonComponent,
    TranslateModule,
  ],
})
export class GroepsgesprekFormComponent implements OnInit, DirtyFormCheck {
  @Input() formId = '';
  @Input({ required: true }) currentProfiel!: Profiel;
  @Input({ required: true }) teamleden!: Array<TeamlidProfiel>;
  @Input({ required: true }) geblokkeerdeTeamleden!: Array<TeamlidProfiel>;
  @Input({ required: true }) context!: JongereContext | TeamlidContext;
  @Output() formSubmitted = new EventEmitter<GroepsgesprekInsert>();
  deelnemersFormGroup!: TeamlidAddedToChatGroup;

  constructor(private readonly formBuilder: FormBuilder) {}

  private getDefaultChatMembers(): Array<GesprekDeelnemerUpsert> {
    if (this.context.type === 'jongere') {
      return [
        {
          externeId: this.currentProfiel.id,
        },
      ];
    } else if (this.context.type === 'teamlid') {
      return [
        {
          externeId: this.context.jongereProfiel.id,
        },
        {
          externeId: this.currentProfiel.id,
        },
      ];
    } else return [];
  }

  ngOnInit(): void {
    const minimumAmountToAdd =
      this.context.type === 'jongere' ? 2 : this.context.type === 'teamlid' ? 1 : 0;
    const teamleden = this.teamleden.map(() => {
      return this.formBuilder.nonNullable.group({
        checked: this.formBuilder.nonNullable.control(false),
      });
    });
    this.deelnemersFormGroup = this.formBuilder.nonNullable.group(
      {
        teamleden: this.formBuilder.nonNullable.array<TeamlidAddedGroup>(teamleden),
      },
      { validators: aantalDeelnemersValidator(minimumAmountToAdd) },
    );
  }

  onSubmit() {
    const formValues = this.deelnemersFormGroup.getRawValue();
    this.deelnemersFormGroup.markAllAsTouched();
    if (this.deelnemersFormGroup.valid) {
      const map = this.teamleden
        .filter((teamLid, index) => formValues.teamleden[index].checked)
        .map(
          (teamlid): GesprekDeelnemerUpsert => ({
            externeId: teamlid.id,
          }),
        );
      this.formSubmitted.emit({
        ownerId: this.context.contextId,
        deelnemers: [...map, ...this.getDefaultChatMembers()],
        type: 'GROEPSGESPREK',
      });
    }
  }

  isFormDirty() {
    return this.deelnemersFormGroup.dirty;
  }
}

const aantalDeelnemersValidator =
  (minimum: number): ValidatorFn =>
  (form: AbstractControl): ValidationErrors | null => {
    const teamleden = form.getRawValue().teamleden;
    const length = teamleden.filter(
      (teamlidChecked: { checked: boolean }) => teamlidChecked.checked,
    ).length;
    if (length < minimum) {
      return {
        aantalDeelnemersDrie: true,
      };
    } else {
      return null;
    }
  };
