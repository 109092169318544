<parkour-action
  (clicked)="openModal()"
  [ariaExpanded]="modal.isOpen"
  [ariaLabel]="label"
  [ngClasses]="{
    'border-n-500': !error && !disabled,
    'border-negative': error,
    'border-n-300': disabled,
  }"
  ariaHasPopup="listbox"
  ariaControls="custom-list"
  class="block"
  ariaRole="combobox"
  classes="flex items-center gap-x-3 justify-between border-[1.5px] rounded bg-n-200 w-full p-3"
>
  <ng-container *ngIf="value">
    <parkour-picture
      *ngIf="options[value].sources"
      [sources]="
        options[value].sources || { default: { src: null, fallback: null } }
      "
      alt=""
      class="w-[2.5rem] h-[2.5rem] rounded overflow-hidden"
    />

    <div class="flex-1">
      <p class="text-left uppercase typo-body variant-body-small">
        {{ label }}
      </p>

      <p class="text-left">
        {{ options[value].label }}
      </p>
    </div>

    <parkour-icon name="chevron-down" size="3" />
  </ng-container>
</parkour-action>

<parkour-modal #modal [hasSpacing]="false" [title]="label" id="reacties-modal">
  <ul
    class="divide-y-[1.5px] divide-n-500 bg-n-100 transition"
    content
    role="listbox"
    id="custom-list"
  >
    <li
      *ngFor="let option of getOptionValues(); let i = index"
      [attr.aria-selected]="option === value"
      [attr.aria-setsize]="getOptionValues().length"
      [attr.aria-posinset]="i + 1"
      role="option"
    >
      <parkour-action
        (clicked)="selectOption(option)"
        *ngIf="value"
        classes="flex items-center gap-x-3 w-full h-14 px-3 {{
          value === option ? 'bg-n-200' : ''
        }} hover:bg-n-200 focus:bg-n-200 transition-colors"
      >
        <parkour-picture
          *ngIf="options[option].sources"
          [sources]="
            options[option].sources || {
              default: { src: null, fallback: null },
            }
          "
          alt=""
          class="w-[2.5rem] h-[2.5rem] rounded overflow-hidden"
        />

        <p class="flex-1 text-left">
          {{ options[option].label }}
        </p>

        <parkour-icon
          *ngIf="value === option"
          class="text-positive"
          name="check"
          size="3"
        />
      </parkour-action>
    </li>
  </ul>
</parkour-modal>
