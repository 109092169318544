import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';
import { Component, computed, viewChild } from '@angular/core';
import { ParkourPage } from '../../../../shared/parkour-page';
import { DirtyFormCheck } from '../../../../shared/components/dirty-form-guard';
import { EMPTY, forkJoin, map, Observable, startWith, switchMap, tap } from 'rxjs';
import { HeroSources } from 'src/app/shared/components/page-with-hero/page-with-hero.types';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { FileData, FotoId, TeamlidContextDto } from 'parkour-web-app-dto';
import { PageLayoutComponent } from '../../../../shared/components/page-layout/page-layout.component';
import { AsyncPipe } from '@angular/common';
import { ParkourBackButtonDirective } from '../../../../shared/directives/parkour-back-button.directive';
import {
  FormSubmitObservableGenerator,
  ParkourFormComponent,
} from '../../../../shared/components/parkour-form/parkour-form.component';
import { ParkourHeaderActionComponent } from '../../../../shared/components/parkour-header-action/parkour-header-action.component';
import { TranslateModule } from '@ngx-translate/core';
import { PageWithHeroComponent } from '../../../../shared/components/page-with-hero/page-with-hero.component';
import {
  ParkourButtonComponent,
  ParkourFormFieldComponent,
  ParkourInputComponent,
  ParkourTextareaComponent,
  ParkourToastService,
} from '@parkour/ui';
import { AttachmentsUploadComponent } from '../../../verhaal-shared/component/gebeurtenis-attachments-upload/attachments-upload.component';
import { RawValueOfFormGroup } from '../../../../shared/components/parkour-form/parkour-form.types';
import { ContextService } from '../../../../shared/services/context.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ParkourRefreshDirective } from '../../../../shared/directives/parkour-refresh-directive';
import { CustomFotoEditComponent } from '../../../../shared/components/custom-foto-edit/custom-foto-edit.component';

import { ImageUploadData, toImageId } from '../../../../shared/model/image-upload';
import { placeholderHeroSources } from '../../../constants';
import { getProfielnaam } from '../../../../profiel/model/profiel-in-team';
import { VerhaalService } from '../../../verhaal-shared/service/verhaal.service';

type GebeurtenisSuggestieForm = FormGroup<{
  imageUploadData: FormControl<ImageUploadData>;
  titel: FormControl<string>;
  omschrijving: FormControl<string | undefined>;
  datum: FormControl<string>;
  bijlagen: FormControl<FileData[]>;
}>;

@Component({
  standalone: true,
  templateUrl: './gebeurtenis-suggestie-add.page.html',
  imports: [
    IonHeader,
    IonToolbar,
    IonButtons,
    IonBackButton,
    ParkourBackButtonDirective,
    IonTitle,
    IonContent,
    PageLayoutComponent,
    AsyncPipe,
    ParkourHeaderActionComponent,
    TranslateModule,
    PageWithHeroComponent,
    ParkourFormComponent,
    ParkourFormFieldComponent,
    ParkourInputComponent,
    ParkourTextareaComponent,
    AttachmentsUploadComponent,
    ReactiveFormsModule,
    ParkourButtonComponent,
    IonRefresher,
    IonRefresherContent,
    ParkourRefreshDirective,
    CustomFotoEditComponent,
  ],
})
export class GebeurtenisSuggestieAddPage extends ParkourPage implements DirtyFormCheck {
  gebeurtenisSuggestieForm: GebeurtenisSuggestieForm = this.formBuilder.nonNullable.group({
    titel: this.formBuilder.nonNullable.control<string>(''),
    omschrijving: this.formBuilder.nonNullable.control<string | undefined>(undefined),
    imageUploadData: this.formBuilder.nonNullable.control<ImageUploadData>({ type: 'no-image' }),
    datum: this.formBuilder.nonNullable.control<string>(''),
    bijlagen: this.formBuilder.nonNullable.control<FileData[]>([]),
  });

  parkourForm = viewChild.required(ParkourFormComponent);
  formBusy = computed(() => this.parkourForm().busy);

  hero$: Observable<HeroSources | string> =
    this.gebeurtenisSuggestieForm.controls.imageUploadData.valueChanges.pipe(
      startWith(this.gebeurtenisSuggestieForm.controls.imageUploadData.value),
      map((imageUploadData) => {
        if (imageUploadData.type === 'newly-uploaded' || imageUploadData.type === 'uploading') {
          return imageUploadData.base64;
        } else {
          return placeholderHeroSources;
        }
      }),
    );

  constructor(
    private readonly contextService: ContextService,
    private readonly formBuilder: FormBuilder,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly toastService: ParkourToastService,
    private readonly verhaalService: VerhaalService,
  ) {
    super();
  }

  submitGebeurtenisSuggestie: FormSubmitObservableGenerator<
    RawValueOfFormGroup<GebeurtenisSuggestieForm>
  > = (formValue: RawValueOfFormGroup<GebeurtenisSuggestieForm>) =>
    this.contextService.teamlidContext$().pipe(
      switchMap((teamlidContext) => {
        return this.verhaalService
          .addGebeurtenisSuggestie({
            titel: '',
            gebeurtenisData: {
              titel: formValue.titel,
              omschrijving: formValue.omschrijving,
              startDatum: formValue.datum,
              imageId: toImageId(formValue.imageUploadData),
              bijlagen: formValue.bijlagen,
            },
            suggestedToId: teamlidContext.jongereProfiel.id,
          })
          .pipe(tap(() => this.showSuccessToaster(teamlidContext)));
      }),
      tap(() => this.router.navigate(['..'], { relativeTo: this.route })),
    );

  private showSuccessToaster(teamlidContext: TeamlidContextDto) {
    return forkJoin([
      this.translateService.get('verhaal.gebeurtenis-suggestie-add-page.sent-toast.title'),
      this.translateService.get('verhaal.gebeurtenis-suggestie-add-page.sent-toast.content', {
        naam: getProfielnaam(teamlidContext.jongereProfiel),
      }),
    ]).subscribe(([title, content]) => this.toastService.showToast({ header: title, content }));
  }

  getGebeurtenisImage: (imageId: FotoId) => Observable<string> = () => EMPTY;

  isFormDirty(): boolean {
    return this.gebeurtenisSuggestieForm.dirty;
  }
}
