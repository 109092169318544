<script src="parkour-icon.component.ts"></script>
<span
  [ngClass]="{
    'inline-block': type === 'inline',
    'inline-flex items-center justify-center align-middle': type === 'default',
    'text-0': type === 'default' && size === 'default',
    'text-2': type === 'default' && size === '1',
    'text-4': type === 'default' && size === '2',
    'text-6': type === 'default' && size === '3',
    'text-8': type === 'default' && size === '4',
    'text-14': type === 'default' && size === '7',
  }"
  class="text-inherit {{ classes }}"
>
	@if (label) {
		<span class="sr-only">{{ label }}</span>
	}

  <i
    aria-hidden="true"
    class="inline-flex items-center justify-center fill-current w-[1em] h-[1em] icon-{{
      icons[name]
    }}"
  ></i
></span>
