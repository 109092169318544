import { Component, Input } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  ParkourButtonComponent,
  ParkourFormFieldComponent,
  ParkourFormFieldWithoutLabelComponent,
  ParkourIconComponent,
  ParkourInputComponent,
  ParkourTextareaComponent,
} from '@parkour/ui';
import { TranslateModule } from '@ngx-translate/core';
import { NgForOf } from '@angular/common';

export type OverJezelfFormGroup = FormGroup<{
  bio: FormControl<string | undefined>;
  links: FormArray<FormControl<string>>;
  adres: FormControl<string | undefined>;
}>;

@Component({
  standalone: true,
  selector: 'parkour-profile-details-over-jezelf-edit',
  templateUrl: './profile-details-over-jezelf-edit.component.html',
  imports: [
    ParkourFormFieldComponent,
    TranslateModule,
    ParkourTextareaComponent,
    NgForOf,
    ParkourFormFieldWithoutLabelComponent,
    ParkourInputComponent,
    ParkourIconComponent,
    ParkourButtonComponent,
  ],
})
export class ProfileDetailsOverJezelfEditComponent {
  @Input({ required: true }) overJezelfFormGroup!: OverJezelfFormGroup;

  constructor(private readonly fb: FormBuilder) {}

  onLinkAdd() {
    this.overJezelfFormGroup.controls.links.push(
      this.fb.nonNullable.control('', [Validators.required, Validators.maxLength(255)]),
    );
  }

  onDeleteLink(index: number) {
    this.overJezelfFormGroup.controls.links.removeAt(index);
  }
}
