<article class="overflow-hidden rounded bg-n-300">
  <div class="max-h-[12rem] flex">
    @if (content.content.image) {
      <parkour-image class="block w-full" fit="cover" [src]="content.content.image.file" />
    }
  </div>

  <div class="p-4">
    <h3 class="mb-6 typo-h3">{{ content.content.title }}</h3>

    @if (content.content.description) {
      <div class="wysiwyg" [innerHTML]="content.content.description | makeReferencesAbsolute"></div>
    }

    @if (content.content.link) {
      <parkour-button
        class="block w-full mt-6"
        [href]="content.content.link.url | makeUrlAbsolute"
        variant="secondary"
        >{{ content.content.link.text }}
      </parkour-button>
    }
  </div>
</article>
