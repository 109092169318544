import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  defaultIfEmpty,
  filter,
  forkJoin,
  from,
  map,
  mergeMap,
  Observable,
  of,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { environment } from '../../../environments/environment';
import {
  ContextId,
  ContextOptionDto,
  hasContextId,
  MeldingDto,
  MeldingEvent,
  MeldingOfType,
  MeldingType,
  ProfielId,
  PushberichtenSettingsDto,
  UpdatePushberichtenSettingsDto,
} from 'parkour-web-app-dto';
import { MeldingVisualizationService } from './melding-visualization.service';
import {
  isSupportedMelding,
  Melding,
  MeldingenPage,
  MeldingenViewPage,
  MeldingView,
  SupportedMelding,
} from '../model/meldingen';
import { AangemeldeUser } from '../../authentication/user';
import { ContextService, ContextUrl, GlobalUrl } from '../../shared/services/context.service';
import { MeldingLinkService } from './melding-link.service';
import { Router } from '@angular/router';
import { App } from '@capacitor/app';
import { PusherService } from '../../bericht/service/pusher.service';
import { HumanReadableError } from '../../core/human-readable-error';
import { meldingenForWisselTeamPage } from '../../meldingen/config';
import { LoggingService } from '../../core/logging.service';
import AuthService from '../../authentication/service/auth.service';
import { Browser } from '@capacitor/browser';
import { AnalyticsService } from '../../analytics/analytics.service';
import { AnalyticsEvent, trackAnalyticsEvent } from '../../analytics/analytics-event.model';
import { ProfielService } from './profiel.service';

export type MeldingenMapType<Type extends MeldingType> = {
  readonly [key in Type]: key;
};

export type FilterType = 'berichten';

@Injectable({
  providedIn: 'root',
})
export class MeldingenService {
  wisselTeamMeldingen$ = combineLatest([
    this.getMeldingenNotInCurrentContext(),
    this.getMeldingenInCurrentContextByType(meldingenForWisselTeamPage.allMeldingen),
  ]).pipe(
    map(([meldingenNotInCurrentcontext, wisselTeamButtonMeldingen]) =>
      meldingenNotInCurrentcontext.concat(wisselTeamButtonMeldingen),
    ),
  );
  private readonly ongelezenMeldingenFilters = new Map<
    FilterType,
    (melding: SupportedMelding) => boolean
  >();
  private readonly _ongelezenMeldingen$ = new BehaviorSubject<SupportedMelding[]>([]);

  constructor(
    private readonly http: HttpClient,
    private readonly meldingVisualizationService: MeldingVisualizationService,
    private readonly meldingLinkService: MeldingLinkService,
    private readonly authService: AuthService,
    private readonly profielService: ProfielService,
    private readonly contextService: ContextService,
    private readonly router: Router,
    private readonly pusherService: PusherService,
    private readonly loggingService: LoggingService,
    private readonly analyticsService: AnalyticsService,
  ) {}

  get ongelezenMeldingen$(): Observable<SupportedMelding[]> {
    return this._ongelezenMeldingen$;
  }

  public startMeldingenFetcher() {
    App.addListener('appStateChange', ({ isActive }) => {
      if (isActive) {
        this.fetchOngelezenMeldingen();
      }
    });

    this.authService.user$.subscribe(() => {
      this.fetchOngelezenMeldingen();
    });

    this.pusherService.pusherReconnected$.subscribe(() => this.fetchOngelezenMeldingen());

    this.pusherService
      .createPusherObservableForEventOnProfielChannel<MeldingEvent>('nieuwe-melding')
      .subscribe({
        next: (meldingEvent) => {
          this.getMeldingOrUndefined(meldingEvent.meldingId).subscribe({
            next: (melding) => {
              if (melding && isSupportedMelding(melding) && !melding.gelezen) {
                const currentOngelezenMeldingen = this._ongelezenMeldingen$.value;
                currentOngelezenMeldingen.push(melding);
                this.updateOngelezenMeldingen(currentOngelezenMeldingen);
              }
            },
            error: (err) => this.loggingService.error(err),
          });
        },
        complete: () => {
          throw new HumanReadableError(
            'Er is iets misgegaan tijdens het ophalen van je meldingen. Probeer opnieuw of herstart de applicatie.',
          );
        },
      });
  }

  isMeldingSupportedEnGelezen(meldingId: string) {
    return this.getMeldingOrUndefined(meldingId).pipe(
      map(
        (melding) =>
          melding &&
          isSupportedMelding(melding) &&
          (melding.gelezen || this.matchesOngelezenMeldingenFilter(melding)),
      ),
    );
  }

  getMeldingen(
    profielId: ProfielId,
    pageNumber?: number,
    contextId?: string,
  ): Observable<MeldingenViewPage> {
    let params = new HttpParams();
    if (contextId) {
      params = params.append('contextId', contextId);
    }
    params = params.append('pageNumber', pageNumber || 0);
    return combineLatest([
      this.http.get<MeldingenPage>(
        `${environment.API_BASE_URL}/api/meldingen/targets/${profielId}`,
        {
          params,
        },
      ),
      this.profielService.getMijnSortedContexten(),
    ]).pipe(
      switchMap(([pageOfMeldingDto, contexten]) =>
        forkJoin(
          pageOfMeldingDto.content.map((melding) =>
            this.meldingVisualizationService.transform(
              melding,
              contexten.map((context) => context.contextId),
            ),
          ),
        ).pipe(
          map((transformedMeldingen) => {
            return {
              ...pageOfMeldingDto,
              content: transformedMeldingen,
            };
          }),
        ),
      ),
      defaultIfEmpty({
        content: [],
        last: true,
        number: 0,
        totalPages: 0,
        totalOngelezenMeldingen: 0,
      }),
    );
  }

  getMelding(meldingId: string): Observable<Melding> {
    return this.http.get<Melding>(`${environment.API_BASE_URL}/api/meldingen/${meldingId}`);
  }

  markMeldingAsRead(meldingId: string): Observable<void> {
    return this.http
      .put(`${environment.API_BASE_URL}/api/meldingen/${meldingId}/gelezen`, {})
      .pipe(map(() => {}));
  }

  getOngelezenMeldingen(profielId: ProfielId): Observable<SupportedMelding[]> {
    return this.http
      .get<MeldingDto[]>(`${environment.API_BASE_URL}/api/meldingen/targets/${profielId}/ongelezen`)
      .pipe(tap(() => this.loggingService.log('Fetched ongelezen meldingen')));
  }

  fetchOngelezenMeldingen() {
    this.authService
      .getCurrentUser$()
      .pipe(
        mergeMap((user) => {
          if (user.type === 'aangemeld') {
            return this.getOngelezenMeldingenForUser(user);
          } else {
            return of([]);
          }
        }),
        map((meldingen) => meldingen ?? []),
      )
      .subscribe((meldingen) => this.updateOngelezenMeldingen(meldingen));
  }

  getOngelezenMeldingenForUser(aangemeldeUser: AangemeldeUser) {
    return this.getOngelezenMeldingen(aangemeldeUser.profielId).pipe(
      catchError((error) => {
        this.loggingService.error('Error while fetching ongelezen meldingen', error);

        return of([]);
      }),
    );
  }

  isMeldingOfTypes<T extends MeldingType>(
    melding: MeldingDto,
    types: MeldingenMapType<T>,
  ): melding is MeldingOfType<T> {
    return Object.keys(types).includes(melding.type);
  }

  getMeldingenInCurrentContextByType<T extends MeldingType>(
    types: MeldingenMapType<T>,
  ): Observable<MeldingOfType<T>[]> {
    return this.contextService.context$.pipe(
      switchMap((context) =>
        this.ongelezenMeldingen$.pipe(
          map((meldingen) =>
            meldingen.filter((melding) => {
              return (
                this.isMeldingRelevantInContext(melding, context.contextId) &&
                this.isMeldingOfTypes(melding, types)
              );
            }),
          ),
        ),
      ),
      map((meldingen) => meldingen as MeldingOfType<T>[]),
    );
  }

  hasMeldingenInContextByType(contextId: ContextId): Observable<boolean> {
    return this.ongelezenMeldingen$.pipe(
      map(
        (meldingen) =>
          meldingen.filter((melding) => {
            return hasContextId(melding) && melding.contextId === contextId;
          }).length > 0,
      ),
    );
  }

  getMeldingenNotInCurrentContext(): Observable<SupportedMelding[]> {
    return this.contextService.context$.pipe(
      mergeMap((user) => {
        return this.ongelezenMeldingen$.pipe(
          map((meldingen) => {
            return meldingen.filter(
              (melding) => hasContextId(melding) && melding.contextId !== user.contextId,
            );
          }),
        );
      }),
    );
  }

  markMeldingenInCurrentContextAsReadWithTypes<T extends MeldingType>(
    types: MeldingenMapType<T>,
    matcher: (melding: MeldingOfType<T>) => boolean = () => true,
  ): void {
    this.markMeldingenAsRead(
      this.getMeldingenInCurrentContextByType(types).pipe(
        take(1),
        map((meldingen) => meldingen.filter((melding) => matcher(melding))),
      ),
    );
  }

  hasWisselTeamMeldingen(): Observable<boolean> {
    return this.wisselTeamMeldingen$.pipe(map((meldingen) => meldingen.length > 0));
  }

  hasMeldingenInCurrentContextByType<T extends MeldingType>(
    types: MeldingenMapType<T>,
  ): Observable<boolean> {
    return this.getMeldingenInCurrentContextByType(types).pipe(
      map((meldingen) => meldingen.length > 0),
    );
  }

  navigateToMelding(meldingId: string) {
    this.loggingService.log('Navigate To Melding', meldingId);
    combineLatest([this.getMelding(meldingId), this.profielService.getMijnSortedContexten()])
      .pipe(
        filter(
          (meldingAndContexten): meldingAndContexten is [SupportedMelding, ContextOptionDto[]] =>
            isSupportedMelding(meldingAndContexten[0]),
        ),
        tap(([melding]) => {
          if (this.isAanmoediging(melding.type)) {
            this.markMeldingAsRead(melding.id).subscribe();
          }
        }),
        switchMap(([melding, contexten]) =>
          this.meldingLinkService
            .getMeldingLink(
              melding,
              contexten.map((context) => context.contextId),
            )
            .pipe(
              trackAnalyticsEvent(this.analyticsService, () =>
                this.meldingToAnalyticsEvent(
                  isSupportedMelding(melding) ? melding.type : 'unsupported',
                  'notificatie',
                ),
              ),
            ),
        ),
        take(1),
      )
      .subscribe({
        next: (url) => {
          if (Array.isArray(url)) {
            this.router.navigate(url, { replaceUrl: true });
          } else if (url.type === 'global-url') {
            Browser.open({ url: url.url });
          } else {
            this.router.navigate(url.path, { replaceUrl: true, queryParams: url.queryParams });
          }
        },
        error: (err) => this.loggingService.error('Failed navigating to melding', err),
      });
  }

  addOngelezenMeldingenFilter(key: FilterType, filter: (melding: MeldingDto) => boolean) {
    this.ongelezenMeldingenFilters.set(key, filter);
  }

  removeOngelezenMeldingenFilter(key: FilterType) {
    this.ongelezenMeldingenFilters.delete(key);
  }

  private getMeldingOrUndefined(meldingId: string): Observable<Melding | undefined> {
    return this.authService.getCurrentUser$().pipe(
      switchMap((user) => {
        if (user.type !== 'aangemeld') {
          return of(undefined);
        } else {
          return this.getMelding(meldingId);
        }
      }),
    );
  }

  private updateOngelezenMeldingen(currentOngelezenMeldingen: SupportedMelding[]) {
    const meldingenToMarkAsRead = currentOngelezenMeldingen.filter((melding) =>
      this.matchesOngelezenMeldingenFilter(melding),
    );
    const ongelezenMeldingen = currentOngelezenMeldingen.filter(
      (melding) => !this.matchesOngelezenMeldingenFilter(melding),
    );
    this._ongelezenMeldingen$.next(ongelezenMeldingen);
    meldingenToMarkAsRead.forEach((melding) => this.markMeldingAsRead(melding.id).subscribe());
  }

  private matchesOngelezenMeldingenFilter(melding: SupportedMelding): boolean {
    return [...this.ongelezenMeldingenFilters.values()].some((filter) => filter(melding));
  }

  private isMeldingRelevantInContext(melding: MeldingDto, contextId: ContextId): boolean {
    return !hasContextId(melding) || melding.contextId === contextId;
  }

  private markMeldingenAsRead(meldingen$: Observable<SupportedMelding[]>): void {
    meldingen$
      .pipe(
        take(1),
        switchMap((meldingen) =>
          combineLatest(meldingen.map((melding) => this.markMeldingAsRead(melding.id))),
        ),
      )
      .subscribe(() => this.fetchOngelezenMeldingen());
  }

  public markAllMeldingenAsRead(): Observable<void> {
    return this.profielService.getCurrentUserProfiel$().pipe(
      switchMap((profiel) =>
        this.http.put<void>(
          `${environment.API_BASE_URL}/api/meldingen/targets/${profiel.id}/markeer-als-gelezen`,
          {},
        ),
      ),
      tap(() => this.fetchOngelezenMeldingen()),
    );
  }

  public markMeldingenWitContextIdAsRead(contextId: ContextId): Observable<void> {
    const params = new HttpParams().append('contextId', contextId);
    return this.profielService.getCurrentUserProfiel$().pipe(
      switchMap((profiel) =>
        this.http.put<void>(
          `${environment.API_BASE_URL}/api/meldingen/targets/${profiel.id}/markeer-als-gelezen`,
          {},
          { params },
        ),
      ),
      tap(() => this.fetchOngelezenMeldingen()),
    );
  }

  private isAanmoediging(melding: MeldingType | 'unsupported'): boolean {
    if (melding === 'unsupported') {
      return false;
    }

    const aanmoedigingTypes: MeldingType[] = [
      'EERSTE_TEAM_UITNODIGING',
      'EERSTE_TEAMLID_TOEVOEGEN',
      'EERSTE_DOEL_DELEN',
      'EERSTE_DOEL_VOORSTELLEN',
      'EERSTE_BERICHT_STUREN',
      'WERKEN_AAN_DOEL',
      'HELPEN_BIJ_DOEL_1',
      'HELPEN_BIJ_DOEL_2',
      'EERSTE_STAP_AFGEVINKT',
    ];
    return aanmoedigingTypes.includes(melding);
  }

  meldingToAnalyticsEvent(
    meldingType: MeldingType | 'unsupported',
    via: 'notificatie' | 'meldingen-scherm',
  ): AnalyticsEvent<'meldingen'> {
    switch (via) {
      case 'notificatie':
        if (this.isAanmoediging(meldingType)) {
          return new AnalyticsEvent('meldingen', 'doorgekliktAanmoedigingViaNotificatie');
        } else {
          return new AnalyticsEvent('meldingen', 'doorgekliktMeldingViaNotificatie');
        }
      case 'meldingen-scherm':
        if (this.isAanmoediging(meldingType)) {
          return new AnalyticsEvent('meldingen', 'doorgekliktAanmoedigingViaNotificatie');
        } else {
          return new AnalyticsEvent('meldingen', 'doorgekliktMeldingViaMedlingScherm');
        }
    }
  }

  clickMelding(melding: MeldingView): Observable<unknown> {
    this.markMeldingAsRead(melding.id).subscribe();

    this.analyticsService.trackEvent(
      this.meldingToAnalyticsEvent(
        melding.type === 'supported' ? melding.key : 'unsupported',
        'meldingen-scherm',
      ),
    );

    if (melding.type === 'unsupported') {
      throw new Error('Unsupported melding clicked');
    }
    const link: string[] | ContextUrl | GlobalUrl = melding.link;
    if (!Array.isArray(link)) {
      if (link.type === 'context-url') {
        return from(
          this.router.navigate(link.path, {
            queryParams: { ...link.queryParams },
            queryParamsHandling: 'merge',
          }),
        );
      } else {
        return from(Browser.open({ url: link.url }));
      }
    } else {
      return from(this.router.navigate(link));
    }
  }

  updatePushberichtenInstellingen(
    profielId: ProfielId,
    updateSettings: UpdatePushberichtenSettingsDto,
  ): Observable<void> {
    return this.http
      .put<void>(
        `${environment.API_BASE_URL}/api/meldingen/persoon/${profielId}/settings`,
        updateSettings,
      )
      .pipe(map(() => {}));
  }

  getPushberichtenInstellingen(profielId: ProfielId): Observable<PushberichtenSettingsDto> {
    return this.http.get<PushberichtenSettingsDto>(
      `${environment.API_BASE_URL}/api/meldingen/persoon/${profielId}/settings`,
    );
  }
}
