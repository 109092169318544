<ion-accordion-group>
  <ion-accordion *ngFor="let item of items" class="parkour-accordion" toggleIconSlot="start">
    <button
      class="flex items-center gap-x-2 py-4 w-full hover:bg-n-200 focus:bg-n-200 transition-colors"
      slot="header"
    >
      <parkour-icon class="ion-accordion-toggle-icon text-s-100" name="chevron-right" />

      <p [innerHTML]="item.header" class="typo-body variant-body-fat"></p>
    </button>
    <div slot="content">
      <div [innerHTML]="item.content" class="wysiwyg pl-6 pb-4 pt-2">Content</div>
      @if (item.imageUrl) {
        <parkour-image [alt]="item.imageAlt || ''" [src]="item.imageUrl" />
      }
      @if (item.videoUrl && item.videoType) {
        @if (item.videoTitle) {
          <p class="typo-h4">{{ item.videoTitle }}</p>
        }
        <parkour-video-content [content]="{ type: item.videoType, urlOrId: item.videoUrl }" />
      }
    </div>
  </ion-accordion>
</ion-accordion-group>
