import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { CurrentRecordingStatus, RecordingData, VoiceRecorder } from 'capacitor-voice-recorder';
import { PermissionService } from '../../../shared/services/permission.service';
import { NgForOf, NgIf } from '@angular/common';
import { AudioPlayerComponent } from '../audio-player/audio-player.component';

@Component({
  standalone: true,
  selector: 'parkour-bericht-recorder',
  templateUrl: './bericht-recorder.component.html',
  styleUrls: ['./bericht-recorder.component.css'],
  imports: [NgIf, NgForOf, AudioPlayerComponent],
})
export class BerichtRecorderComponent implements OnDestroy {
  private timer?: NodeJS.Timeout;

  @Input({ required: true }) set isRecording(value: boolean) {
    if (value) {
      this.startRecording();
    } else {
      this.stopRecording();
    }
  }

  @Input({ required: true }) public isAudioPlayer!: boolean;

  @Output() public hasAudioBeenLoaded = new EventEmitter<void>();
  @Output() recordingComplete = new EventEmitter<Blob>();
  @Output() recordingFailed = new EventEmitter<void>();

  durationDisplay = '00:00';
  duration = 0;
  isAudioPlaying = false;
  recording?: RecordingData;

  constructor(
    private cdr: ChangeDetectorRef,
    private readonly permissionService: PermissionService,
  ) {}

  async startRecording() {
    await VoiceRecorder.startRecording();
    this.startCalculationDuration();
  }

  stopRecording() {
    clearInterval(this.timer);
    VoiceRecorder.stopRecording().then(async (result: RecordingData) => {
      this.recording = result;
      this.isAudioPlayer = true;

      const blob = this.b64toBlob(result.value.recordDataBase64, result.value.mimeType);

      this.recordingComplete.emit(blob);
    });
  }

  calculateDuration() {
    this.duration += 1;

    const minutes = Math.floor(this.duration / 60)
      .toString()
      .padStart(2, '0');
    const seconds = (this.duration % 60).toString().padStart(2, '0');

    this.durationDisplay = `${minutes}:${seconds}`;
    this.cdr.detectChanges();
  }

  startCalculationDuration() {
    this.timer = setInterval(() => {
      this.calculateDuration();
    }, 1000);
  }

  b64toBlob = (b64Data: string, contentType = '') => {
    const binaryData = atob(b64Data);
    const arrayBuffer = new ArrayBuffer(binaryData.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < binaryData.length; i++) {
      uint8Array[i] = binaryData.charCodeAt(i);
    }

    return new Blob([uint8Array], { type: contentType });
  };

  async ngOnDestroy() {
    const result: CurrentRecordingStatus = await VoiceRecorder.getCurrentStatus();
    if (result.status === 'RECORDING') {
      await VoiceRecorder.stopRecording();
    }
  }
}
