import { Component, Input, OnInit } from '@angular/core';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { SpraakBerichtDto } from 'parkour-web-app-dto';
import { Observable, shareReplay } from 'rxjs';
import { BerichtenService } from '../../service/berichten.service';
import { AbstractGesprek } from '../../model/abstractGesprek';
import { AudioPlayerComponent } from '../audio-player/audio-player.component';
import { BerichtenDatePipe } from '../../pipes/berichten-date.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { ProfielnaamPipe } from '../../../shared/pipes/profielnaam.pipe';
import { ProfielPipe } from '../../../shared/pipes/profiel.pipe';

@Component({
  selector: 'parkour-bericht-speech-content',
  standalone: true,
  imports: [
    AsyncPipe,
    NgIf,
    AudioPlayerComponent,
    BerichtenDatePipe,
    TranslateModule,
    NgClass,
    ProfielnaamPipe,
    ProfielPipe,
  ],
  templateUrl: './bericht-speech-content.component.html',
})
export class BerichtSpeechContentComponent implements OnInit {
  @Input({ required: true }) bericht!: SpraakBerichtDto;
  @Input() isUser: boolean = false;
  @Input({ required: true }) gesprek!: AbstractGesprek;
  @Input() isNameVisible = false;

  audioFile$ = new Observable<string>();

  constructor(private readonly berichtenService: BerichtenService) {}

  ngOnInit(): void {
    this.audioFile$ = this.berichtenService
      .getBlobString(this.gesprek.id, this.bericht.id, this.bericht.bestand.id)
      .pipe(shareReplay(1));
  }
}
