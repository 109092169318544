<ion-header class="modal-header">
  <ion-toolbar>
    <ion-title aria-level="1" role="heading">
      {{ 'biometrics.config-popup.header-title' | translate }}
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="modal-content">
  <parkour-page-gradient></parkour-page-gradient>
  <div class="relative z-10 py-19 container container-space h-full flex flex-col justify-between">
    <div class="flex justify-center">
      <parkour-image
        class="w-[10rem] h-[10rem]"
        [src]="
          success ? 'assets/biometrics/fingerprint-check.svg' : 'assets/biometrics/fingerprint.svg'
        "
      ></parkour-image>
    </div>
    <div class="space-y-4">
      <p class="text-center typo-h3">
        @if (success) {
          {{ 'biometrics.config-popup.success-title' | translate }}
        } @else {
          {{ 'biometrics.config-popup.title' | translate }}
        }
      </p>
      <p class="text-center mb-4">
        @if (success) {
          {{ 'biometrics.config-popup.success-description' | translate }}
        } @else {
          {{ 'biometrics.config-popup.description' | translate }}
        }
      </p>
    </div>

    <div>
      @if (success) {
        <parkour-button
          class="block w-full mt-10"
          iconAfter="arrow-right"
          (clicked)="onVolgendeStapClick()"
          >{{ 'biometrics.config-popup.next-button' | translate }}
        </parkour-button>
      } @else {
        <parkour-button class="block w-full mt-10" (clicked)="onBiometricsActivateClick()"
          >{{ 'biometrics.config-popup.activate-button' | translate }}
        </parkour-button>
        <parkour-button class="block w-full mt-10" (clicked)="onCancelClick()" variant="ghost"
          >{{ 'biometrics.config-popup.skip-button' | translate }}
        </parkour-button>
      }
    </div>
  </div>
</ion-content>
