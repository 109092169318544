<parkour-hero
  [image]="{
    sources: themaMapping[doel.thema].largeImage.sources,
  }"
>
  <div class="flex flex-col items-center gap-y-4 text-center container-1-col">
    <div class="flex flex-col gap-y-1">
      <div>
        @if (doel.isBehaald) {
          <div class="inline-flex items-center gap-x-1 bg-t-100 px-2 py-[6px] rounded-[10rem]">
            <parkour-icon class="flex" name="check" />

            <span class="typo-body variant-body-fat variant-body-small">
              {{ 'doelen.doel-detail-page.behaald' | translate }}
            </span>
          </div>
        } @else {
          <span class="typo-body variant-body-fat">
            {{ 'doelen.doel-detail-page.doel' | translate }}
          </span>
        }
      </div>

      <h1>{{ doel.naam }}</h1>

      @if (doel.eindDatum) {
        <span class="typo-h5 text-n-600" data-testid="eindDatum">
          {{ doel.eindDatum | date }}
        </span>
      } @else if (doel.streefDatum) {
        <span class="typo-h5 text-n-600" data-testid="streefDatum">
          {{ doel.streefDatum | date }}
        </span>
      }
    </div>

    <parkour-divider />

    <ng-content />
  </div>
</parkour-hero>
