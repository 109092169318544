<div
  [ngClass]="{
    'px-2': size === 'default',
    'px-3 variant-tag-large': size === 'large',
    'bg-p-100': variant === 'default',
    'bg-linear-gradient-1': variant === 'black',
    'bg-n-600': variant === 'light-blue',
    'bg-s-100': variant === 'cyan',
    'bg-n-300': variant === 'dark-blue',
    'text-n-1000':
      variant === 'default' ||
      variant === 'black' ||
      variant === 'dark-blue' ||
      variant === 'solid-black',
    'text-n-100': variant === 'light-blue' || variant === 'cyan',
    'bg-n-200': variant === 'solid-black',
  }"
  class="flex gap-x-1 py-1 items-center rounded-sm uppercase typo-tag text-center {{
    classes
  }}"
>
  <parkour-icon *ngIf="iconBefore" [name]="iconBefore" class="inline-flex" />

  <ng-content></ng-content>

  <parkour-icon *ngIf="iconAfter" [name]="iconAfter" class="inline-flex" />
</div>
