import { Pipe, PipeTransform } from '@angular/core';
import { Observable, switchMap } from 'rxjs';
import { TeamlidContext } from '../model/context';
import { ImagesService } from '../services/images.service';
import { ProfielFotoService } from '../../profiel/service/profiel-foto.service';

@Pipe({
  name: 'profielFotoOfJongere',
  standalone: true,
})
export class ProfielFotoOfJongerePipe implements PipeTransform {
  constructor(
    private readonly imageService: ImagesService,
    private readonly profielFotoService: ProfielFotoService,
  ) {}

  transform(teamlidContext$: Observable<TeamlidContext>): Observable<string> {
    return teamlidContext$.pipe(
      switchMap((context) =>
        this.imageService.getImage(
          this.profielFotoService.getProfielFotoUrl(
            context.jongereProfiel.id,
            context.jongereProfiel.profielFotoId,
            'full',
          ),
        ),
      ),
    );
  }
}
