<div
  class="flex items-center justify-center rounded aspect-square bg-n-400 {{
    width
  }}"
  [ngClass]="{
    'bg-n-100': theme === 'n-100',
    'bg-n-400': theme === 'n-400',
    'bg-n-300': theme === 'n-300',
    'bg-negative': theme === 'negative',
  }"
>
  <parkour-icon class="flex text-n-1000" [name]="icon" [size]="iconSize" />
</div>
