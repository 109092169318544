<parkour-button (clicked)="qrCodeModal.open()" class="w-full" iconAfter="qr-code" variant="default">
  {{ 'profiel.open-qr-code-button' | translate }}
</parkour-button>

<parkour-modal #qrCodeModal [title]="'profiel.qr-code-title' | translate" icon="qr-code">
  <div class="flex flex-col items-center gap-4" content>
    <parkour-avatar
      [professional]="profiel.type === 'PROFESSIONEEL_TEAMLID'"
      [image]="{ type: 'image', src: profiel | profielFoto: 'full' | async }"
      class="block max-w-[10rem]"
    />

    <!-- TODO a11y -->
    <h1>{{ getProfielnaamWithoutFallback(profiel) }}</h1>

    <parkour-info-card>
      {{ 'profiel.qr-code-modal-info' | translate }}
    </parkour-info-card>

    <qr-code
      [darkColor]="'#000000'"
      [id]="'qr-code'"
      [lightColor]="'#ffffff'"
      [value]="getQrCodeUrl()"
      class="bg-n-1000"
      errorCorrectionLevel="M"
      size="300"
    >
    </qr-code>

    <parkour-button
      (clicked)="onDownloadQr()"
      class="w-full"
      iconAfter="download"
      variant="tertiary"
    >
      {{ 'profiel.qr-code-download-button' | translate }}
    </parkour-button>
  </div>
</parkour-modal>
