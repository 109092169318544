import { Component, Input } from "@angular/core";

import { IParkourHeroImageProps } from "./parkour-hero.types";
import { NgClass, NgIf } from "@angular/common";
import { ParkourImageComponent } from "../parkour-image/parkour-image.component";
import { ParkourPictureComponent } from "../parkour-picture/parkour-picture.component";

@Component({
  standalone: true,
  selector: "parkour-hero",
  templateUrl: "./parkour-hero.component.html",
  imports: [NgClass, ParkourImageComponent, NgIf, ParkourPictureComponent],
})
export class ParkourHeroComponent {
  @Input() public classes?: string;
  @Input({ required: true }) public image!: IParkourHeroImageProps;
  @Input() public fullScreen = false;
}
