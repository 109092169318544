import { Component, Input } from '@angular/core';
import { Thema } from 'parkour-web-app-dto';
import { themaMapping } from '../../model/thema.const';
import { ParkourActionComponent, ParkourPictureComponent } from '@parkour/ui';

@Component({
  standalone: true,
  selector: 'parkour-thema-button',
  templateUrl: './thema-button.component.html',
  imports: [ParkourPictureComponent, ParkourActionComponent],
})
export class ThemaButtonComponent {
  @Input({ required: true }) public thema!: Thema;
  @Input() public isHidden = false;
  protected readonly themaMapping = themaMapping;
}
