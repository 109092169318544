import { Component, Input } from '@angular/core';
import { WatWatHtmlEmbedContent } from 'parkour-web-app-dto';

@Component({
  selector: 'parkour-artikel-html-embed-content',
  templateUrl: './artikel-html-embed-content.component.html',
  standalone: true,
})
export class ArtikelHtmlEmbedContentComponent {
  @Input({ required: true }) content!: WatWatHtmlEmbedContent;
}
