import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ProfielId, ReactieDto, ReactiesDto } from 'parkour-web-app-dto';
import { TranslatePipe } from '@ngx-translate/core';
import { getParkourEmoji, ParkourEmojiPipe } from '../../pipes/parkourEmoji.pipe';
import { ParkourActionComponent, ParkourModalComponent } from '@parkour/ui';
import { CoreModule } from '../../../core/core.module';
import { ParkourProfielBlockComponent } from '../parkour-profiel-block/parkour-profiel-block.component';
import { ProfielPipe } from '../../pipes/profiel.pipe';

@Component({
  standalone: true,
  selector: 'parkour-emoji-reacties',
  templateUrl: './emoji-reacties.component.html',
  imports: [
    ParkourActionComponent,
    ParkourEmojiPipe,
    ParkourModalComponent,
    CoreModule,
    ParkourProfielBlockComponent,
    ProfielPipe,
  ],
})
export class EmojiReactiesComponent {
  @ViewChild(ParkourModalComponent, { static: true }) modal!: ParkourModalComponent;
  @Input({ required: true }) reacties!: ReactiesDto;
  @Input({ required: true }) totalAmount!: number;
  @Input({ required: true }) userProfielId!: ProfielId;
  @Input() disabled: boolean = false;
  @Input() type: 'bericht' | 'doel' = 'bericht';

  isReactiesDetailOpen = false;

  @Output() deleteEmoji: EventEmitter<void> = new EventEmitter<void>();
  protected readonly console = console;

  constructor(private readonly translate: TranslatePipe) {}

  getUniqueReacties() {
    return this.reacties.reduce((uniqueReactions: ReactiesDto, reaction: ReactieDto) => {
      const existingIndex = uniqueReactions.findIndex(
        (item: ReactieDto) => item.type === reaction.type,
      );

      if (existingIndex === -1) {
        uniqueReactions.push(reaction);
      }

      return uniqueReactions;
    }, []);
  }

  getExternalReacties() {
    return this.reacties.filter((reactie) => reactie.ownerId !== this.userProfielId);
  }

  getUserReactie() {
    return this.reacties.filter((reactie) => reactie.ownerId === this.userProfielId);
  }

  getAriaLabel() {
    const uniqueReacties = this.getUniqueReacties();

    switch (this.totalAmount) {
      case 1:
        return this.translate.transform('reacties.1-reactie', {
          totalAmount: this.totalAmount,
          reactie: getParkourEmoji(uniqueReacties[0].type),
        });
      case 2:
        return this.translate.transform('reacties.2-reacties', {
          totalAmount: this.totalAmount,
          firstReactie: getParkourEmoji(uniqueReacties[0].type),
          lastReactie: getParkourEmoji(uniqueReacties[uniqueReacties.length - 1].type),
        });
      default:
        return this.translate.transform('reacties.multiple-reacties', {
          totalAmount: this.totalAmount,
          reacties: uniqueReacties
            .slice(0, -1)
            .map((reactie) => getParkourEmoji(reactie.type))
            .join(', '),
          lastReactie: getParkourEmoji(uniqueReacties[uniqueReacties.length - 1].type),
        });
    }
  }

  deleteReactie() {
    this.modal.close();
    this.deleteEmoji.emit();
  }
}
