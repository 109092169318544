<div
  class="relative before:content-[''] before:absolute before:top-0 before:left-0 before:w-[4rem] before:h-full before:z-10 before:bg-gradient-to-r before:from-n-200 before:pointer-events-none after:content-[''] after:absolute after:top-0 after:right-0 after:w-[4rem] after:h-full after:z-10 after:bg-gradient-to-l after:from-n-200 after:pointer-events-none"
>
  <div
    class="absolute z-10 top-0 left-1/2 transform -translate-x-1/2 size-[3.375rem] bg-transparent outline outline-p-100 outline-[3px] outline-offset-[3px] pointer-events-none rounded focus-visible:outline-[yellow]"
  ></div>

  <swiper-container
    (swiperslidechange)="handleSlideChange($event)"
    [attr.initial-slide]="items.indexOf(value)"
    a11y-slide-role="option"
    a11y-scroll-on-focus="false"
    a11y="true"
    mousewheel="true"
    keyboard="true"
    grab-cursor="true"
    centered-slides="true"
    space-between="24"
    slides-per-view="auto"
    slide-to-clicked-slide="true"
    grab-cursor="true"
    role="listbox"
    tabindex="0"
    class="outline-dashed focus-visible:outline-[yellow] outline-transparent outline-[3px] outline-offset-[3px] transition-all"
  >
    @for (item of items; track item; let i = $index) {
      <swiper-slide class="size-[3.375rem]" [attr.aria-current]="value === item">
        <parkour-action
          (clicked)="handleClick(item)"
          [tabIndex]="-1"
          [disabled]="disabled"
          [ngClasses]="{
            'opacity-50': disabled,
          }"
        >
          <parkour-image
            [alt]="convertAlt(item)"
            [src]="profielFotoService.getAvatarUrl(item) | secureImage | async"
            class="block aspect-square rounded overflow-hidden"
          />
        </parkour-action>
      </swiper-slide>
    }
  </swiper-container>
</div>
