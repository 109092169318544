import { ApiProperty } from '@nestjs/swagger';
import { BerichtId, GesprekId } from './bericht.dto';
import { ProfielId } from './profiel.dto';

export type ProbleemType = 'FOUT_GEBRUIKER' | 'FOUT_PARKOUR' | 'OPMERKING';

export type ProbleemReden =
  | 'FRAUDE_BEDROG'
  | 'HAATSPRAAK'
  | 'ONGEPAST'
  | 'PESTEN'
  | 'ZELFDODING'
  | 'ANDERE';

export type CreateRapporteerderDto = AangemeldeRapporteerderDto | AnoniemeRapporteerderDto;

export type CreateProbleemDto = CreateDefaultProbleemDto | CreateberichtProbleemDto;

export class CreateDefaultProbleemDto {
  @ApiProperty({ type: String })
  readonly type!: ProbleemType;
  readonly beschrijving!: string;
  readonly rapporteerder!: CreateRapporteerderDto;
  readonly metaData!: ProbleemMetaData;
}

export class CreateberichtProbleemDto {
  readonly type!: 'BERICHT';
  readonly beschrijving!: string;
  @ApiProperty({ type: String })
  readonly berichtId!: BerichtId;
  @ApiProperty({ type: String })
  readonly gesprekId!: GesprekId;
  @ApiProperty({ type: String })
  readonly reden!: ProbleemReden;
  readonly rapporteerder!: AangemeldeRapporteerderDto;
  readonly metaData!: ProbleemMetaData;
}

export class AangemeldeRapporteerderDto {
  readonly type!: 'AANGEMELD';
  @ApiProperty({ type: String })
  readonly profielId!: ProfielId;
}

export class AnoniemeRapporteerderDto {
  readonly type!: 'ANONIEM';
  readonly naam!: string;
  readonly voornaam!: string;
  readonly email!: string;
}

export class ProbleemMetaData {
  readonly os?: string;
  readonly osVersion?: string;
  readonly appVersion?: string;
}
