<parkour-action
  (clicked)="click($event)"
  [link]="link"
  classes="flex items-center gap-3 p-4 w-full"
>
  <div class="relative">
    <parkour-new-indicator [show]="showIndicator" />

    <parkour-badge [icon]="icon" iconSize="3" theme="n-300" width="w-8" />
  </div>

  <p class="typo-body variant-body-fat">{{ text }}</p>
</parkour-action>
