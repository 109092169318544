import { Component, Input } from "@angular/core";
import { IParkourPictureSources } from "../parkour-picture/parkour-picture.types";
import { ParkourPictureComponent } from "../parkour-picture/parkour-picture.component";

@Component({
  standalone: true,
  selector: "parkour-block-placeholder",
  templateUrl: "./parkour-block-placeholder.component.html",
  imports: [ParkourPictureComponent],
})
export class ParkourBlockPlaceholderComponent {
  @Input() public classes?: string;
  @Input({ required: true }) public sources!: IParkourPictureSources;
  @Input() public alt: string = "";
}
