import { Component, Input } from '@angular/core';
import { EventAttributes } from 'ics';
import moment from 'moment';
import { Directory } from '@capacitor/filesystem';
import { AfspraakViewDetailDto } from 'parkour-web-app-dto';
import { AangemeldeUser } from '../../../authentication/user';
import { isNativeApp } from '../../../utils';
import { FileService } from '../../../shared/services/file.service';
import { getProfielnaam } from '../../model/profiel-in-team';
import { ParkourButtonComponent } from '@parkour/ui';
import { ExportKalenderService } from '../../service/export-kalender.service';

@Component({
  standalone: true,
  selector: 'parkour-download-afspraken',
  templateUrl: './download-afspraken.component.html',
  imports: [ParkourButtonComponent],
})
export class DownloadAfsprakenComponent {
  @Input({ required: true }) afspraken!: AfspraakViewDetailDto[];
  @Input({ required: true }) user!: AangemeldeUser;

  constructor(
    private readonly fileService: FileService,
    private readonly exportKalenderService: ExportKalenderService,
  ) {}

  async downloadAfspraken() {
    const filename = 'afspraken.ics';
    const events: EventAttributes[] = this.mapAfspraken();

    const file: Blob = await this.exportKalenderService.exportAfsprakenToIcsBlob(events, filename);

    if (!isNativeApp()) {
      const url = URL.createObjectURL(file);
      const anchor = document.createElement('a');

      anchor.href = url;
      anchor.download = filename;
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);

      URL.revokeObjectURL(url);
    } else {
      const toBase64 = (file: Blob) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();

          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = reject;
        });

      const b64 = (await toBase64(file)) as string;

      await this.fileService.downloadFile(b64, filename, Directory.Cache);
    }
  }

  getGespreksPartner(user: AangemeldeUser, afspraak: AfspraakViewDetailDto) {
    return afspraak.type === 'VIDEOGESPREK' && user.profielId !== afspraak.teamlid.id
      ? afspraak.teamlid
      : afspraak.jongere;
  }

  private mapAfspraken(): EventAttributes[] {
    return this.afspraken.map((afspraak: AfspraakViewDetailDto): EventAttributes => {
      const startDateTime = moment(afspraak.van);
      const endDateTime = moment(afspraak.tot);
      const start = startDateTime.format('YYYYMMDDTHHmmss');
      const end = endDateTime.format('YYYYMMDDTHHmmss');

      return {
        uid: afspraak.id,
        title: this.getAfspraakTitle(afspraak),
        start,
        end,
        description: afspraak.omschrijving,
      };
    });
  }

  private getAfspraakTitle(afspraak: AfspraakViewDetailDto) {
    return afspraak.type === 'VIDEOGESPREK'
      ? `Videogesprek met ${getProfielnaam(this.getGespreksPartner(this.user, afspraak))}`
      : `${afspraak.titel}`;
  }
}
