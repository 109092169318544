<parkour-block-placeholder
  [sources]="{
    default: {
      src: '/assets/berichten/placeholder/placeholder_berichten_default.svg',
    },
    md: {
      src: '/assets/berichten/placeholder/placeholder_berichten_md.svg',
    },
  }"
  alt=""
  class="block"
/>
