import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { ParkourButtonComponent, ParkourIconComponent } from '@parkour/ui';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  selector: 'parkour-event-start-videogesprek',
  templateUrl: './event-start-videogesprek.component.html',
  imports: [ParkourIconComponent, TranslateModule, ParkourButtonComponent],
})
export class EventStartVideogesprekComponent {
  @Output() public clicked: EventEmitter<void> = new EventEmitter<void>();
  @Input() disabled: boolean = false;

  @HostBinding('class') get class() {
    return 'chat-flow__event';
  }

  onClickedJoin() {
    this.clicked.emit();
  }
}
