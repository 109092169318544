<ion-header class="w-full transform-none">
  <ion-toolbar>
    <ion-title aria-level="1" role="heading">{{ 'not-found-page.title' | translate }} </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content [scrollY]="false">
  <parkour-page-layout>
    <parkour-error-component />
  </parkour-page-layout>
</ion-content>
