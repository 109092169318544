<div
  class="rounded overflow-hidden border-[1.5px] border-solid border-s-100 max-w-[28rem]"
  role="alert"
>
  <div class="flex items-start gap-x-1 px-3 py-2 bg-s-100 text-n-0">
    <parkour-icon name="video-camera" size="3" />

    <p class="typo-body variant-body-fat">
      {{ 'berichten.gesprek-page.incoming-videocall' | translate }}
    </p>
  </div>

  <div class="p-6 bg-n-200">
    <parkour-button
      (clicked)="onClickedJoin()"
      [disabled]="disabled"
      class="block w-full"
      iconAfter="telephone"
    >
      {{ 'buttons.join' | translate }}
    </parkour-button>
  </div>
</div>
