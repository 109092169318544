<parkour-form
  [formGroup]="eyouthGebeurtenisUpdateForm"
  [formId]="formId"
  [submitObservableGenerator]="submitForm"
  [errorLabelMapping]="false"
>
  <div class="flex flex-col gap-y-8">
    <div class="flex flex-col gap-y-6">
      <parkour-checkbox [formControl]="eyouthGebeurtenisUpdateForm.controls.verborgenVoorJongere">
        <span class="typo-body variant-body-fat">{{
          'verhaal.eyouth-gebeurtenis-edit-page.verbergen' | translate
        }}</span>
      </parkour-checkbox>

      <div class="space-y-2">
        <h2 class="typo-h3">Delen</h2>
        <!-- TODO: UX Copy verhaal aan JSON toevoegen -->

        <p>Met wie wil je dit delen? Je kan dit altijd aanpassen.</p>
      </div>

      <parkour-delen-section
        [delenFormGroup]="eyouthGebeurtenisUpdateForm.controls.delenSection"
        [teamleden]="teamleden"
      />
    </div>

    <parkour-button class="block w-full" iconAfter="disk" type="submit">
      {{ 'verhaal.eyouth-gebeurtenis-edit-page.gebeurtenis-save' | translate }}
    </parkour-button>
  </div>
</parkour-form>
