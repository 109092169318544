<parkour-block-placeholder
  [sources]="{
    default: {
      src: '/assets/verhaal/placeholder/placeholder_verhaal_default.svg',
    },
    md: {
      src: '/assets/verhaal/placeholder/placeholder_verhaal_md.svg',
    },
  }"
  alt=""
  class="block mt-8"
/>
