import { Component, Input } from "@angular/core";
import { TParkourTagSize, TParkourTagVariant } from "./parkour-tag.types";
import { TParkourIconName } from "../parkour-icon/parkour-icon.types";
import { NgClass, NgIf } from "@angular/common";
import { ParkourIconComponent } from "../parkour-icon/parkour-icon.component";

@Component({
  selector: "parkour-tag",
  templateUrl: "./parkour-tag.component.html",
  standalone: true,
  imports: [NgClass, ParkourIconComponent, NgIf],
})
export class ParkourTagComponent {
  @Input() public classes?: string;
  @Input() public iconBefore?: TParkourIconName;
  @Input() public iconAfter?: TParkourIconName;
  @Input() public variant?: TParkourTagVariant = "default";
  @Input() public size?: TParkourTagSize = "default";
}
