<ion-checkbox
  (ionChange)="onCheckboxChange()"
  [checked]="checked"
  [disabled]="disabled"
  class="ion-focusable typo-body variant-body-fat"
  labelPlacement="end"
  mode="md"
>
  <span class="typo-body whitespace-pre-wrap">
    <ng-content></ng-content>
  </span>
</ion-checkbox>
