import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ParkourFocusVisibleDirective } from "./parkour-focus-visible.directive";

@NgModule({
  declarations: [ParkourFocusVisibleDirective],
  imports: [CommonModule],
  exports: [ParkourFocusVisibleDirective],
})
export class ParkourFocusVisibleModule {}
