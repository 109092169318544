<div class="flex items-center justify-center w-full h-[10rem]">
  @if (hulplijn$ | async; as hulplijn) {
    <parkour-helpline-card-item
      class="border-[1px] border-solid border-n-500 rounded block h-full"
      [organisation]="hulplijn"
      [segmentsToHulplijn]="['hulplijn', String(hulplijn.id)]"
      [isPreview]="true"
      titleTag="h2"
    >
      <ng-content></ng-content>
    </parkour-helpline-card-item>
  } @else {
    <parkour-loading-spinner />
  }
</div>
