<ion-header></ion-header>

<ion-content>
  <ion-refresher slot="fixed" [parkourRefresh]="onPageRefresh">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>
  <parkour-page-layout>
    <parkour-page-with-hero
      [heroImage]="verhaalHeroSources"
      [title]="'verhaal.overview-page.title' | translate"
    >
      <div class="container-1-col space-y-8">
        <p class="text-center">Meld je aan om je eigen verhaal op te bouwen.</p>

        <parkour-aanmeld-button [redirectAfterLogin]="'/app/me/verhaal'" class="block" />

        <parkour-verhaal-placeholder />
      </div>
    </parkour-page-with-hero>
  </parkour-page-layout>
</ion-content>
