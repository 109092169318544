<div class="flex gap-x-1">
  @if (!isUser && gesprekWithDeelnemers.isGroepsgesprek()) {
    <div class="w-[2rem] flex-none">
      <parkour-foto-badge
        [image]="{ type: 'icon', name: 'alert-triangle' }"
        class="block"
        [size]="'2'"
      />
    </div>
  }

  <div
    [ngClass]="{
      'bg-n-300': isUser,
      'bg-n-200': !isUser,
    }"
    class="w-full py-2 px-3 bericht-message-content rounded"
  >
    <parkour-bericht-gemaskeerd-content />
  </div>
</div>
