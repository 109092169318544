import { Component, Input } from '@angular/core';
import { ActieSectionGroup } from '../doel-form/doel-form.component';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { IonReorderGroup, ItemReorderEventDetail } from '@ionic/angular/standalone';
import { NgForOf, NgIf } from '@angular/common';
import { ParkourButtonComponent } from '@parkour/ui';
import { TranslateModule } from '@ngx-translate/core';
import { ActieItemEditComponent } from '../actie-item/actie-item-edit.component';

@Component({
  standalone: true,
  selector: 'parkour-acties-select-section',
  templateUrl: './acties-select-section.component.html',
  imports: [
    ReactiveFormsModule,
    NgIf,
    NgForOf,
    ParkourButtonComponent,
    TranslateModule,
    ActieItemEditComponent,
    IonReorderGroup,
  ],
})
export class ActiesSelectSectionComponent {
  @Input({ required: true }) actieSectionGroup!: ActieSectionGroup;

  /*
   * Set isDisabled false if the reorder feature is needed
   */
  isDisabled = true;

  constructor(private formBuilder: FormBuilder) {}

  addActie() {
    const actieItem = this.formBuilder.nonNullable.group({
      naam: this.formBuilder.nonNullable.control('', [
        Validators.required,
        Validators.maxLength(255),
      ]),
      voltooid: this.formBuilder.nonNullable.control(false),
      order: this.formBuilder.nonNullable.control(
        Math.max(...this.actieSectionGroup.getRawValue().acties.map((actie) => actie.order), 0) + 1,
      ),
    });

    this.actieSectionGroup.controls.acties.push(actieItem);
  }

  deleteActie(index: number) {
    this.actieSectionGroup.controls.acties.removeAt(index);
  }

  handleReorder(ev: CustomEvent<ItemReorderEventDetail>) {
    ev.detail.complete();
  }

  protected readonly JSON = JSON;
}
