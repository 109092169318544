import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'httpLink',
  standalone: true,
})
export class HttpLinkPipe implements PipeTransform {
  transform(link: string): string {
    const lowerCaseLink = link.toLowerCase();

    if (lowerCaseLink.startsWith('http://') || lowerCaseLink.startsWith('https://')) {
      return link;
    }

    return `https://${link}`;
  }
}
