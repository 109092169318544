import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Actie } from '../../model/doel';
import { NgForOf } from '@angular/common';
import { ActieItemViewComponent } from '../actie-item-view/actie-item-view.component';

@Component({
  standalone: true,
  selector: 'parkour-actie-list',
  templateUrl: './actie-list.component.html',
  imports: [NgForOf, ActieItemViewComponent],
})
export class ActieListComponent {
  @Input({ required: true }) acties!: Actie[];
  @Input() editable = true;
  @Output() actiesModified = new EventEmitter<Actie[]>();

  toggleActie(index: number, $event: boolean) {
    const actie = this.acties[index];
    const modifiedActies = [...this.acties];
    modifiedActies[index] = { naam: actie.naam, voltooid: $event, order: actie.order };

    this.actiesModified.emit(modifiedActies);
  }
}
