<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button parkourBackButton />
    </ion-buttons>

    <ion-title aria-level="1" role="heading"
      >{{ 'profiel.pushberichten.pauzeren-label' | translate }}
    </ion-title>

    <ion-buttons slot="end">
      <parkour-header-action
        [label]="'profiel.pushberichten.save' | translate"
        form="pauzeren-form"
        icon="disk"
        testId="submit"
        type="submit"
      />
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-refresher [parkourRefresh]="onPageRefresh" slot="fixed">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>

  <parkour-page-layout>
    <parkour-page-gradient />
    <div class="relative z-10 py-8 container container-space flex flex-col gap-y-8">
      <div class="container-1-col gap-y-8">
        <div class="flex justify-between gap-x-4">
          @if (
            !this.currentSettings ||
            (!this.currentSettings.pauzeUntil && !this.currentSettings.pauzeAlways)
          ) {
            <div class="mb-4 flex flex-col gap-x-2">
              <small class="variant-body-small">
                {{ 'profiel.pushberichten.description' | translate }}
              </small>
            </div>
          } @else {
            <div class="mb-4 flex flex-col gap-x-2">
              <parkour-button
                classes="pl-0 pb-2"
                (clicked)="onResetPauzeSettings()"
                [variant]="'ghost-light'"
              >
                Pushberichten terug inschakelen
              </parkour-button>
              <small class="variant-body-small">
                @if (this.currentSettings.pauzeAlways) {
                  Pushberichten zijn uitgeschakeld
                } @else if (!this.currentSettings.pauzeAlways && this.currentSettings.pauzeUntil) {
                  Pushberichten zijn uitgeschakeld tot en met
                  {{ this.currentSettings.pauzeUntil | date: 'dd.MM.yyyy' }} om
                  {{ this.currentSettings.pauzeUntil | date: 'HH:mm' }}
                }
              </small>
            </div>
          }
        </div>
        <div>
          <parkour-form
            [formGroup]="pauzeForm"
            [formId]="'pauzeren-form'"
            [submitObservableGenerator]="onSubmit"
            [errorLabelMapping]="false"
            class="flex flex-col gap-y-8"
          >
            <ion-radio-group (ionChange)="onSelectPauzeType($event)" class="flex flex-col gap-y-4">
              <parkour-radio
                [inputId]="'ONE_HOUR'"
                [label]="getPauzeNotificationsTypeLabel('ONE_HOUR')"
                [value]="'ONE_HOUR'"
              ></parkour-radio>
              <parkour-radio
                [inputId]="'EIGHT_HOUR'"
                [label]="getPauzeNotificationsTypeLabel('EIGHT_HOUR')"
                [value]="'EIGHT_HOUR'"
              ></parkour-radio>
              <parkour-radio
                [inputId]="'ONE_WEEK'"
                [label]="getPauzeNotificationsTypeLabel('ONE_WEEK')"
                [value]="'ONE_WEEK'"
              ></parkour-radio>
              <parkour-radio
                [inputId]="'ALWAYS'"
                [label]="getPauzeNotificationsTypeLabel('ALWAYS')"
                [value]="'ALWAYS'"
              ></parkour-radio>
              <parkour-radio
                [inputId]="'CUSTOM'"
                [label]="getPauzeNotificationsTypeLabel('CUSTOM')"
                [value]="'CUSTOM'"
              ></parkour-radio>
            </ion-radio-group>

            @if (this.currentPauzeType === 'CUSTOM') {
              <parkour-form-field
                [additionalErrors]="getToDateErrors()"
                [errorMessages]="{
                  required: 'Vul een datum in.',
                  dateInPastInvalid: 'Datum kan niet in het verleden liggen.',
                }"
                [inputFormControl]="pauzeForm.controls.customToDate"
                [label]="'profiel.pushberichten.form.until' | translate"
                [required]="true"
                class="flex-1"
                classes="mt-2"
                inputId="toDate"
              >
                <parkour-input
                  [autocomplete]="null"
                  [formControl]="pauzeForm.controls.customToDate"
                  class="block"
                  inputId="toDate"
                  inputPlaceholder="Kies datum"
                  type="datetime-local"
                  [min]="todayString"
                >
                  <parkour-icon
                    class="absolute top-1/2 -translate-y-2/4 right-[0.7rem] pointer-events-none"
                    name="clock"
                    size="3"
                  />
                </parkour-input>
              </parkour-form-field>
            }
          </parkour-form>
        </div>
      </div>
    </div>
  </parkour-page-layout>
</ion-content>
