<ng-container *ngIf="formGroup !== undefined; else noFormControl">
  <parkour-checkbox [formControl]="formGroup.controls.checked" [disabled]="geblokkeerd">
    <ng-container [ngTemplateOutlet]="content" />
  </parkour-checkbox>
</ng-container>

<ng-template #noFormControl>
  <parkour-checkbox [disabled]="geblokkeerd">
    <ng-container [ngTemplateOutlet]="content" />
  </parkour-checkbox>
</ng-template>

<ng-template #content>
  <parkour-profiel-block [profielData]="teamlid" />
</ng-template>
