import { DoelUpsertDto } from 'parkour-web-app-dto';

export function doelUpsertFromDto(dto: DoelUpsertDto): DoelUpsert {
  return {
    ...dto,
    streefDatum: dto.streefDatum ? new Date(dto.streefDatum) : undefined,
  };
}

export type DoelUpsert = Omit<DoelUpsertDto, 'streefDatum'> & { streefDatum: Date | undefined };
