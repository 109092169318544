import { Component } from '@angular/core';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';
import { ParkourBackButtonDirective } from '../../../shared/directives/parkour-back-button.directive';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncPipe } from '@angular/common';
import { PageLayoutComponent } from '../../../shared/components/page-layout/page-layout.component';
import { PageContentDirective } from '../../../shared/directives/page-content.directive';
import {
  ParkourButtonComponent,
  ParkourIconComponent,
  ParkourLoadingSpinnerComponent,
  ParkourPageImageComponent,
} from '@parkour/ui';
import { DoelDetailsComponent } from '../../doel-shared/component/doel-details/doel-details.component';
import { PageSectionDirective } from '../../../shared/directives/page-section.directive';
import { ReactieOverviewComponent } from '../../doel-shared/component/reactie-overview/reactie-overview.component';
import { DoelBerichtenComponent } from '../../../bericht/component/doel-berichten/doel-berichten.component';
import { ParkourRefreshDirective } from '../../../shared/directives/parkour-refresh-directive';
import { ArtikelCardComponent } from '../../../watwat/components/artikel-card/artikel-card.component';
import { DoelDetailPage } from '../../doel-shared/page/doel-detail/doel-detail-page';
import { CustomPageTitle, PageTitle } from '../../../shared/custom-page-title';
import {
  MarksMeldingenAsReadOnView,
  MeldingenToMarkAsRead,
} from '../../../shared/marks-meldingen-as-read';
import { map, Observable } from 'rxjs';
import { meldingenForPage } from '../../../meldingen/config';

@Component({
  standalone: true,
  templateUrl: './doel-details-teamlid.page.html',
  imports: [
    IonHeader,
    IonToolbar,
    IonButtons,
    IonBackButton,
    ParkourBackButtonDirective,
    IonTitle,
    TranslateModule,
    AsyncPipe,
    IonContent,
    PageLayoutComponent,
    PageContentDirective,
    ParkourPageImageComponent,
    DoelDetailsComponent,
    PageSectionDirective,
    ReactieOverviewComponent,
    DoelBerichtenComponent,
    ParkourLoadingSpinnerComponent,
    IonRefresher,
    IonRefresherContent,
    ParkourRefreshDirective,
    ArtikelCardComponent,
    ParkourButtonComponent,
    ParkourIconComponent,
  ],
})
export class DoelDetailsTeamlidPage
  extends DoelDetailPage
  implements CustomPageTitle, MarksMeldingenAsReadOnView
{
  getCustomPageTitle(): Observable<PageTitle> {
    return this.generateDefaultTitle((data) => data.doel.naam, 'doelen.doel-detail-page.title');
  }

  getMeldingenToMarkAsRead() {
    return this.mainPageData$.pipe(
      map(
        (data) =>
          new MeldingenToMarkAsRead(
            meldingenForPage.DOELEN_TAB.DOELEN_PAGE.DOEL_PAGE.specificMeldingen,
            (melding) => data.success && melding.metaData.doelId === data.value.doel.id,
          ),
      ),
    );
  }

  protected readonly String = String;
}
