import { Component, Input } from '@angular/core';
import { AsyncPipe, NgClass } from '@angular/common';
import { ProfielFotoPipe } from '../../pipes/profiel-foto.pipe';
import { ProfielFotoData } from '../../model/teamlid-info';
import { ParkourAvatarComponent } from '@parkour/ui';

export type ProfielFotoSize = '10' | '5.5' | '4' | '3';

@Component({
  selector: 'parkour-profiel-foto',
  standalone: true,
  imports: [AsyncPipe, NgClass, ProfielFotoPipe, ParkourAvatarComponent],
  templateUrl: './profiel-foto.component.html',
})
export class ProfielFotoComponent {
  @Input({ required: true }) public profiel!: ProfielFotoData;
  @Input({ required: true }) public size!: ProfielFotoSize;
  @Input() badgeCount?: number | undefined;
  @Input() grayout? = false;

  getIconNaam(
    profiel:
      | { status: 'GEBLOKKEERD' }
      | { status: 'INACTIEF' }
      | { status: 'ONBEKEND' }
      | { status: 'UNSUPPORTED' }
      | { status: 'INACTIEVE_WEERGAVE' }
      | { status: 'GEBLOKKEERDE_WEERGAVE' },
  ) {
    switch (profiel.status) {
      case 'GEBLOKKEERD':
        return 'people-blocked';
      case 'INACTIEF':
        return 'people-remove';
      case 'UNSUPPORTED':
        return 'people';
      case 'ONBEKEND':
        return 'people';
      case 'GEBLOKKEERDE_WEERGAVE':
        return 'people-blocked';
      case 'INACTIEVE_WEERGAVE':
        return 'people-remove';
    }
  }

  getFotoType() {
    if (this.size === '5.5' || this.size === '10') {
      return 'full';
    } else {
      return 'thumbnail';
    }
  }
}
