import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import lottie from "lottie-web/build/player/lottie.js";

@Component({
  selector: "parkour-lottie-player",
  templateUrl: "./parkour-lottie-player.component.html",
  standalone: true,
})
export class ParkourLottiePlayerComponent implements AfterViewInit {
  @ViewChild("container") container!: ElementRef;

  @Input({ required: true }) src!: string;
  @Input() loop: boolean = false;
  @Output() completed = new EventEmitter<void>();

  ngAfterViewInit() {
    const options = {
      container: this.container.nativeElement,
      path: this.src,
      renderer: "svg",
      loop: this.loop,
      autoplay: true,
    } as const;

    const animation = lottie.loadAnimation(options);

    animation.addEventListener("complete", () => this.completed.emit());
  }
}
