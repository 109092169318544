<ion-tabs>
  <div class="order-1" slot="top">
    <div
      class="absolute top-0 left-0 z-10 w-[var(--app-sidebar-width)] max-lg:hidden h-[calc(100vh-var(--app-plus-global-header-height))]"
    >
      <parkour-page-layout-sidebar />
    </div>

    <ion-tab-bar>
      <ion-tab-button #tabButton class="ion-focusable space-y-1" tab="home">
        <parkour-icon name="home" size="3" />

        <span class="max-w-full truncate typo-tab" role="navigation">{{
          'tabs.home' | translate
        }}</span>
      </ion-tab-button>

      <ion-tab-button #tabButton [tab]="'verhaal'" class="ion-focusable space-y-1">
        <div class="relative w-full">
          <parkour-new-indicator
            [show]="hasVerhaalTabMeldingen$ | async"
            class="[--notification-outline-color:transparent]"
          />

          <parkour-icon name="compass" size="3" />
        </div>

        <span class="max-w-full truncate typo-tab" role="navigation">{{
          'tabs.verhaal' | translate
        }}</span>
      </ion-tab-button>

      <ion-tab-button #tabButton [tab]="'doelen'" class="ion-focusable space-y-1">
        <div class="relative w-full">
          <parkour-new-indicator
            [show]="hasDoelTabMeldingen$ | async"
            class="[--notification-outline-color:transparent]"
          />

          <parkour-icon name="target" size="3" />
        </div>

        <span class="max-w-full truncate typo-tab" role="navigation">{{
          'tabs.doelen' | translate
        }}</span>
      </ion-tab-button>

      <ion-tab-button #tabButton class="ion-focusable space-y-1" tab="berichten">
        <div class="relative w-full">
          <parkour-new-indicator
            [show]="hasBerichtenTabMeldingen$ | async"
            class="[--notification-outline-color:transparent]"
          />

          <parkour-icon name="paper-plane" size="3" />
        </div>
        <span class="max-w-full truncate typo-tab" role="navigation">{{
          'tabs.berichten' | translate
        }}</span>
      </ion-tab-button>

      <ion-tab-button #tabButton class="ion-focusable min-w-[3.625rem] group" tab="profiel">
        <div *ngIf="data$ | async as data" class="relative">
          <parkour-new-indicator
            [show]="(hasProfielTabMeldingen$ | async) || (hasWisselTeamIndicator$ | async)"
            class="[--notification-outline-color:theme('colors.n-300')] group-[.tab-selected]:[--notification-outline-color:theme('colors.p-100')]"
          />

          <parkour-profiel-indicator [context]="data.context" [userProfiel]="data.userProfiel" />
        </div>
      </ion-tab-button>
    </ion-tab-bar>
  </div>

  <div id="main-content-{{ contextId }}" slot="top"></div>
</ion-tabs>
