import { Component } from '@angular/core';
import { ParkourInfoCardWithTitleComponent } from '@parkour/ui';
import { TranslateModule } from '@ngx-translate/core';
import { ContextService } from '../../services/context.service';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'parkour-ex-jongere-info-card',
  standalone: true,
  imports: [ParkourInfoCardWithTitleComponent, TranslateModule, AsyncPipe],
  templateUrl: './ex-jongere-info-card.component.html',
})
export class ExJongereInfoCardComponent {
  constructor(private readonly contextService: ContextService) {}

  context$ = this.contextService.context$;
}
