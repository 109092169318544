import { Component, EventEmitter, Input, Output } from "@angular/core";

import { TParkourSegmentItems } from "./parkour-segment.types";
import { AsyncPipe, NgClass, NgForOf } from "@angular/common";
import { ParkourNewIndicatorComponent } from "../parkour-new-indicator/parkour-new-indicator.component";
import {
  IonLabel,
  IonSegment,
  IonSegmentButton,
} from "@ionic/angular/standalone";

@Component({
  selector: "parkour-segment",
  templateUrl: "./parkour-segment.component.html",
  standalone: true,
  imports: [
    NgClass,
    NgForOf,
    ParkourNewIndicatorComponent,
    AsyncPipe,
    IonSegment,
    IonSegmentButton,
    IonLabel,
  ],
})
export class ParkourSegmentComponent {
  @Input() public classes?: string;
  @Input({ required: true }) public defaultValue!: string;
  @Input() public fullWidth: boolean = false;
  @Input({ required: true }) public items!: TParkourSegmentItems;

  @Output() public changed: EventEmitter<Event> = new EventEmitter<Event>();

  segmentChanged(event: Event): void {
    this.changed.emit(event);
  }
}
