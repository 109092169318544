import { Injectable } from '@angular/core';
import { FotoId, GebeurtenisId, GebeurtenisImageVariant } from 'parkour-web-app-dto';
import { environment } from '../../../../environments/environment';
import { ContextService } from '../../../shared/services/context.service';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GebeurtenisFotoService {
  constructor(private readonly contextService: ContextService) {}

  getGebeurtenisFotoUrl(
    gebeurtenisId: GebeurtenisId,
    gebeurtenisFotoId: FotoId,
    gebeurtenisImageVariant: GebeurtenisImageVariant,
  ): Observable<string> {
    return this.contextService
      .contextIdOfJongere$()
      .pipe(
        map(
          (contextId) =>
            `${environment.API_BASE_URL}/api/jongere/${contextId}/gebeurtenissen/${gebeurtenisId}/foto/${gebeurtenisFotoId}?variant=${gebeurtenisImageVariant}`,
        ),
      );
  }
}
