import { Component, Input } from "@angular/core";
import { ParkourIconComponent } from "../parkour-icon/parkour-icon.component";
import { NgIf } from "@angular/common";

@Component({
  standalone: true,
  selector: "parkour-form-error-message",
  templateUrl: "./parkour-form-error-message.html",
  imports: [ParkourIconComponent, NgIf],
})
export class ParkourFormErrorMessageComponent {
  @Input({ required: true }) errorMessage!: string;
  @Input() show = false;
}
