import { Component, forwardRef, Input } from "@angular/core";
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
} from "@angular/forms";

import { TParkourSelectOptions } from "./parkour-select.types";
import { NgClass, NgIf } from "@angular/common";
import { ParkourFocusVisibleModule } from "../parkour-focus-visible/parkour-focus-visible.module";
import { ParkourIconComponent } from "../parkour-icon/parkour-icon.component";

@Component({
  selector: "parkour-select",
  templateUrl: "./parkour-select.component.html",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ParkourSelectComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    FormsModule,
    ParkourFocusVisibleModule,
    ParkourIconComponent,
  ],
})
export class ParkourSelectComponent implements ControlValueAccessor {
  @Input() public classes?: string;
  @Input() public testId?: string;
  @Input() public error?: string;
  @Input({ required: true }) public selectId!: string;
  @Input({ required: true }) public placeholder!: string;
  @Input({ required: true }) public options!: TParkourSelectOptions;
  @Input() public disabled = false;
  @Input() public required = false;
  public onChange?: (value: string) => void;
  public onTouched?: () => void;
  protected value?: string;

  writeValue(value: string) {
    this.value = value;
  }

  registerOnChange(fn: () => void) {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  handleChange(event: string) {
    if (!this.disabled) {
      this.onChange?.(event);
    }
  }
}
