<ion-header class="full-header">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button parkourBackButton />
    </ion-buttons>

    <ion-title aria-level="1" role="heading"
      >{{ 'start.profiel-create-about-page.title' | translate }}
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <parkour-page-layout>
    <!-- TODO: replace image with the chosen user image -->
    <parkour-page-image
      classes="absolute top-0 left-0 z-0"
      src="/assets/onboarding/background/background_onboarding_blur.jpg"
    />

    <div class="relative z-10 py-8 container container-space">
      <parkour-info-card class="block container-1-col">
        <p>
          {{ 'start.profiel-create-about-page.info' | translate }}
        </p>
      </parkour-info-card>

      <form (ngSubmit)="onSubmit()" class="mt-8 container-1-col">
        <div class="p-4 rounded bg-n-200">
          <h2 class="typo-h3">{{ 'start.profiel-create-about-page.form-title' | translate }}</h2>

          <parkour-profile-details-over-jezelf-edit [overJezelfFormGroup]="overJezelfFormGroup" />
        </div>

        <parkour-button
          [disabled]="overJezelfFormGroup.invalid || busy"
          class="block w-full mt-8"
          iconAfter="check"
          type="submit"
        >
          {{ 'start.profiel-create-about-page.ready-button' | translate }}
        </parkour-button>
      </form>
    </div>
  </parkour-page-layout>
</ion-content>
