<ion-modal
  (didDismiss)="didDismiss($event)"
  [canDismiss]="canDismiss"
  [id]="id"
  class="modal"
>
  <ng-template>
    <div
      class="rounded overflow-hidden bg-n-100 border-[1.5px] border-n-500 my-[calc(var(--ion-safe-area-top)+1rem)]"
    >
      <div
        *ngIf="title"
        class="flex items-center justify-between gap-x-1 p-2 bg-n-400"
      >
        <div class="flex items-start gap-x-1">
          <parkour-icon *ngIf="icon" [name]="icon" size="3" />

          <p class="typo-body variant-body-fat">
            {{ title }}
          </p>
        </div>

        <parkour-button
          (clicked)="handleCloseModal()"
          ariaLabel="sluiten"
          class="flex"
          format="iconButton"
          icon="cross"
          iconSize="3"
          size="medium"
          variant="ghost"
        />
      </div>

      <div [ngClass]="{ 'p-4': hasSpacing }" class="empty:hidden bg-n-200">
        <ng-content select="[content]"></ng-content>
      </div>

      <div
        [ngClass]="{ 'p-4': hasSpacing }"
        class="empty:hidden {{ footerTransparent ? 'bg-n-200' : 'bg-n-100' }}"
      >
        <ng-content select="[footer]"></ng-content>
      </div>
    </div>
  </ng-template>
</ion-modal>
