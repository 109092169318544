import { Injectable } from '@angular/core';
import { ParkourBeeldbellenAnchorDirective } from './directive/parkour-beeldbellen-anchor.directive';
import { JitsiComponent } from './component/jitsi.component';
import { HttpClient } from '@angular/common/http';
import { JitsiGesprekDto, ProfielDto, VideogesprekId } from 'parkour-web-app-dto';
import { environment } from '../../environments/environment';
import { getProfielnaam } from '../profiel/model/profiel-in-team';
import { Observable, switchMap } from 'rxjs';
import { JongereProfiel, Profiel } from '../profiel/model/profiel';
import { TeamlidProfiel } from '../team/model/teamlid.model';
import { PermissionService } from '../shared/services/permission.service';
import { JitsiMeetWebApi, JitsiMeetWebApiArguments } from './model/jitsi.model';
import { AnalyticsEvent, trackAnalyticsEvent } from '../analytics/analytics-event.model';
import { AnalyticsService } from '../analytics/analytics.service';

declare let JitsiMeetExternalAPI: new (
  arg0: string,
  arg1: JitsiMeetWebApiArguments,
) => JitsiMeetWebApi | undefined;
export type VideogesprekData = {
  jongere: JongereProfiel;
  teamlid: TeamlidProfiel;
  videogesprekId: VideogesprekId;
};

@Injectable({
  providedIn: 'root',
})
export class BeeldbellenService {
  private anchor: ParkourBeeldbellenAnchorDirective | undefined;
  private webApi?: JitsiMeetWebApi;
  private currentVideogesprekData?: VideogesprekData;
  private currentInitiator?: ProfielDto;

  constructor(
    private readonly http: HttpClient,
    private readonly permissionService: PermissionService,
    private readonly analyticsService: AnalyticsService,
  ) {}

  registerAnchor(anchor: ParkourBeeldbellenAnchorDirective) {
    this.anchor = anchor;
  }

  startVideogesprek(
    data: VideogesprekData,
    naam: string,
    profiel: Profiel,
    from: 'afspraak' | 'gesprek',
    joinType: 'start' | 'joined',
  ): Observable<void> {
    return this.http
      .put<JitsiGesprekDto>(
        `${environment.API_BASE_URL}/api/beeldbellen/gesprekken/${data.videogesprekId}`,
        {
          naam,
        },
      )
      .pipe(
        switchMap(async (jitsiGesprekDto) =>
          this.openJitsiComponent(jitsiGesprekDto, profiel, data),
        ),
        trackAnalyticsEvent(this.analyticsService, () => {
          switch (from) {
            case 'afspraak':
              switch (joinType) {
                case 'start':
                  return new AnalyticsEvent('beeldbellen', 'gesprekGestartVanuitAfspraak');
                case 'joined':
                  return new AnalyticsEvent('beeldbellen', 'gesprekDeelgenomenVanuitAfspraak');
              }
              break; // Otherwise the linter complains
            case 'gesprek':
              switch (joinType) {
                case 'start':
                  return new AnalyticsEvent('beeldbellen', 'gesprekGestartVanuitBerichten');
                case 'joined':
                  return new AnalyticsEvent('beeldbellen', 'gesprekDeelgenomenVanuitBerichten');
              }
          }
        }),
      );
  }

  private generateCustomRoomName(data: VideogesprekData) {
    return getProfielnaam(data.jongere) + ' - ' + getProfielnaam(data.teamlid);
  }

  private async openJitsiComponent(
    jitsiGesprekDto: JitsiGesprekDto,
    profiel: Profiel,
    data: VideogesprekData,
  ) {
    if (!this.anchor) {
      throw new Error('no anchor found to bind jitsi container');
    }

    await this.permissionService.checkJitsiPermisssions();

    const jitsiComponentRef = this.anchor.viewContainerRef.createComponent(JitsiComponent);
    jitsiComponentRef.instance.readyToClose.subscribe(() => {
      this.anchor?.viewContainerRef.clear();
    });

    jitsiComponentRef.setInput('customRoomName', this.generateCustomRoomName(data));
    jitsiComponentRef.setInput('roomId', jitsiGesprekDto.room);
    jitsiComponentRef.setInput('jwt', jitsiGesprekDto.jwt);
    jitsiComponentRef.setInput('initiator', profiel);
    jitsiComponentRef.setInput('data', data);
  }

  stopCall(videogesprekId: VideogesprekId, fullRoomName: string): Observable<void> {
    return this.http.post<void>(
      `${environment.API_BASE_URL}/api/beeldbellen/gesprekken/${videogesprekId}/call/stop`,
      {},
      { params: { fullRoomName: encodeURIComponent(fullRoomName) } },
    );
  }

  postVideogesprekVerlaten(
    videogesprekId: VideogesprekId,
    particpantProfielId: string,
  ): Observable<void> {
    return this.http
      .post<void>(
        `${environment.API_BASE_URL}/api/beeldbellen/gesprekken/${videogesprekId}/call/left`,
        {},
        { params: { participantId: particpantProfielId } },
      )
      .pipe(
        trackAnalyticsEvent(
          this.analyticsService,
          new AnalyticsEvent('beeldbellen', 'gesprekVerlaten'),
        ),
      );
  }

  initializeJitsiWebApi(
    jitsiWebApiArguments: JitsiMeetWebApiArguments,
    videogesprekData: VideogesprekData,
    initiator: ProfielDto,
  ): JitsiMeetWebApi | undefined {
    if (!this.webApi) {
      this.webApi = new JitsiMeetExternalAPI(environment.jitsiBaseUrl, jitsiWebApiArguments);
    }
    this.currentVideogesprekData = videogesprekData;
    this.currentInitiator = initiator;

    this.webApi?.addEventListener('videoConferenceLeft', this.handleVideoConferenceLeft);

    return this.webApi;
  }

  private handleVideoConferenceLeft = () => {
    if (!this.currentVideogesprekData || !this.currentInitiator) {
      throw new Error('Cannot leave conference');
    }
    this.postVideogesprekVerlaten(
      this.currentVideogesprekData.videogesprekId,
      this.currentInitiator.id,
    ).subscribe();
  };

  destroyJitsiWebApi() {
    this.webApi?.removeListener('videoConferenceLeft', this.handleVideoConferenceLeft);
    this.webApi = undefined;
    this.currentVideogesprekData = undefined;
    this.currentInitiator = undefined;
  }

  closeJitsiCall() {
    if (this.webApi) {
      if (!this.currentVideogesprekData || !this.currentInitiator) {
        throw new Error('Cannot leave conference');
      }
      this.postVideogesprekVerlaten(
        this.currentVideogesprekData.videogesprekId,
        this.currentInitiator.id,
      ).subscribe();

      this.webApi.dispose();
    }
  }
}
