<div class="inline-grid">
  @if ((!isUser && gesprek.isGroepsgesprek()) || isNameVisible) {
    @if (bericht.deelnemerId | profiel | async; as profiel) {
      <span class="bericht-message-title typo-body variant-body-small variant-body-fat">{{
        profiel | profielnaam
      }}</span>
    }
  }

  <span
    [innerHTML]="safeHtmlContent"
    [ngClass]="{ truncate: isTruncatedVisible }"
    class="typo-body text-n-1000 wysiwyg"
  ></span>
</div>
