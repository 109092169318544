import { Component, Input, OnInit } from '@angular/core';
import { ArtikelCardComponent } from '../../../watwat/components/artikel-card/artikel-card.component';
import { ArtikelId, BookmarkId, parseArtikelId } from 'parkour-web-app-dto';
import { ArtikelWithBookmark, WatwatService } from '../../../shared/services/watwat.service';
import { ReplaySubject, shareReplay, Subject } from 'rxjs';
import { asResult, Result } from '../../../utils';
import { AsyncPipe } from '@angular/common';
import { BookmarkService } from '../../../shared/services/bookmark.service';
import { ContentUnavailableCardComponent } from '../../../shared/components/content-unavailable-card/content-unavailable-card.component';
import { ParkourLoadingSpinnerComponent } from '@parkour/ui';

@Component({
  selector: 'parkour-bericht-artikel',
  templateUrl: './bericht-artikel.component.html',
  imports: [
    ArtikelCardComponent,
    AsyncPipe,
    ContentUnavailableCardComponent,
    ParkourLoadingSpinnerComponent,
  ],
  standalone: true,
})
export class BerichtArtikelComponent implements OnInit {
  @Input({ required: true }) artikelId!: string;

  readonly artikel$: Subject<Result<ArtikelWithBookmark>> = new ReplaySubject(1);
  protected readonly String = String;

  constructor(
    private readonly watwatService: WatwatService,
    private readonly bookmarkService: BookmarkService,
  ) {}

  ngOnInit() {
    this.fetchArtikel();
  }

  fetchArtikel() {
    asResult(
      this.watwatService.getArtikel(parseArtikelId(this.artikelId)).pipe(shareReplay(1)),
    ).subscribe((value) => this.artikel$.next(value));
  }

  onBookmarkClicked(
    hasBookmarked: boolean,
    artikelId: ArtikelId,
    bookmarkId: BookmarkId | undefined,
  ) {
    if (hasBookmarked) {
      this.bookmarkService
        .addBookmark({
          itemId: String(artikelId),
          itemType: 'ARTIKEL',
        })
        .subscribe(() => this.fetchArtikel());
    } else {
      if (bookmarkId) {
        this.bookmarkService.deleteBookmark(bookmarkId).subscribe(() => this.fetchArtikel());
      }
    }
  }
}
