import { Pipe, PipeTransform } from '@angular/core';
import { ProfielId } from 'parkour-web-app-dto';
import { TeamlidProfiel } from '../../team/model/teamlid.model';

@Pipe({
  standalone: true,
  name: 'filterTeamleden',
})
export class TeamledenPipe implements PipeTransform {
  transform(ids: ProfielId[], teamleden: TeamlidProfiel[]): TeamlidProfiel[] {
    return teamleden.filter((teamlid) => ids.includes(teamlid.id));
  }
}
