import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { map, Observable, switchMap } from 'rxjs';
import { WatwatService } from '../../../shared/services/watwat.service';
import { BookmarkId, OrganisationId, WatWatOrganisationMethodType } from 'parkour-web-app-dto';
import {
  ParkourButtonComponent,
  ParkourCheckboxComponent,
  ParkourFormFieldComponent,
  ParkourInputComponent,
  ParkourPageGradientComponent,
  ParkourPaginationComponent,
} from '@parkour/ui';
import { CoreModule } from '../../../core/core.module';
import { HelplineCardItemComponent } from '../../../home/component/helpline-card-item/helpline-card-item.component';
import { ParkourBackButtonDirective } from '../../../shared/directives/parkour-back-button.directive';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';
import { PageLayoutComponent } from '../../../shared/components/page-layout/page-layout.component';
import { PageSectionDirective } from '../../../shared/directives/page-section.directive';
import { ParkourRefreshDirective } from '../../../shared/directives/parkour-refresh-directive';
import { ParkourDataPage } from '../../../shared/parkour-data-page';
import { FiltersComponent } from '../../../shared/components/filters/filters.component';
import { OrganisationWithBookmarkQueryResult } from '../../../shared/model/artikel';

@Component({
  selector: 'parkour-hulplijnen-page',
  templateUrl: './hulplijnen.page.html',
  standalone: true,
  imports: [
    CoreModule,
    HelplineCardItemComponent,
    ParkourBackButtonDirective,
    ParkourPageGradientComponent,
    ParkourFormFieldComponent,
    ParkourInputComponent,
    ParkourButtonComponent,
    ParkourPaginationComponent,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonTitle,
    IonContent,
    IonBackButton,
    PageLayoutComponent,
    PageSectionDirective,
    IonRefresher,
    IonRefresherContent,
    ParkourRefreshDirective,
    FiltersComponent,
    ParkourCheckboxComponent,
  ],
})
export class HulplijnenPage extends ParkourDataPage<OrganisationWithBookmarkQueryResult> {
  @ViewChild(IonContent) contentRef!: IonContent;

  enteredQueryString = '';
  queryString$ = this.activatedRoute.queryParamMap.pipe(map((map) => this.getQueryString(map)));
  page$ = this.activatedRoute.queryParamMap.pipe(map((map) => this.getPageNr(map)));
  type$ = this.activatedRoute.queryParamMap.pipe(map((map) => this.getType(map)));
  nowOpen$ = this.activatedRoute.queryParamMap.pipe(map((map) => this.getNowOpen(map)));

  protected readonly String = String;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly watwatService: WatwatService,
    private readonly router: Router,
  ) {
    super();
  }

  override retrieveMainData(): Observable<OrganisationWithBookmarkQueryResult> {
    return this.activatedRoute.queryParamMap.pipe(
      switchMap((queryParams) => {
        return this.watwatService.getEnrichedOrganisations({
          type: this.getType(queryParams),
          nowOpen: this.getNowOpen(queryParams),
          query: this.getQueryString(queryParams),
          page: this.getPageNr(queryParams),
        });
      }),
    );
  }

  onSearch() {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        query: this.enteredQueryString,
        page: 1,
      },
      queryParamsHandling: 'merge',
    });
  }

  changePage(page: number) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { page },
      queryParamsHandling: 'merge',
    });
  }

  onBookmarkClicked(
    hasBookmarked: boolean,
    hulplijnId: OrganisationId,
    bookmarkId: BookmarkId | undefined,
  ) {
    if (hasBookmarked) {
      this.watwatService.addOrganisationBookmark(hulplijnId).subscribe();
    } else {
      if (bookmarkId) {
        this.watwatService.deleteOrganisationBookmark(bookmarkId).subscribe();
      }
    }
  }

  onFilterChange(type: string | null) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        type,
        page: 1,
      },
      queryParamsHandling: 'merge',
    });
  }

  onOpenCheckboxChange(nowOpen: boolean) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        nowOpen,
        page: 1,
      },
      queryParamsHandling: 'merge',
    });
  }

  private getPageNr(map: ParamMap): number | undefined {
    const page = map.get('page');

    return page === null ? undefined : parseInt(page);
  }

  private getType(map: ParamMap) {
    const type = map.get('type');

    return (type as WatWatOrganisationMethodType) ?? null;
  }

  private getNowOpen(map: ParamMap): boolean {
    const nowOpen = map.get('nowOpen');

    return nowOpen ? JSON.parse(nowOpen) : false;
  }

  private getQueryString(map: ParamMap) {
    const query = map.get('query');

    return query ?? '';
  }
}
