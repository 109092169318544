import { Injectable } from '@angular/core';
import { FormArray, FormBuilder } from '@angular/forms';
import { DelenSectionGroup } from '../model/delen-form-group';
import { DeelMode, ProfielId } from 'parkour-web-app-dto';
import { GedeeldMetItemGroup } from '../../doel/doel-shared/component/doel-form/doel-form.component';
import { TeamlidProfiel } from '../../team/model/teamlid.model';

@Injectable({
  providedIn: 'root',
})
export class DelenFormService {
  constructor(private readonly formBuilder: FormBuilder) {}

  private createGedeeldMetToFormArray(
    teamleden: Array<TeamlidProfiel>,
    gedeeldMet?: ProfielId[],
  ): FormArray<GedeeldMetItemGroup> {
    return this.formBuilder.array<GedeeldMetItemGroup>(
      teamleden.map((teamLid) => {
        return this.formBuilder.nonNullable.group({
          gedeeldMet: this.formBuilder.nonNullable.control(
            gedeeldMet?.includes(teamLid.id) ?? false,
          ),
        });
      }),
    );
  }

  createDelenFormGroup(
    teamleden: Array<TeamlidProfiel>,
    initialState: Partial<{ gedeeldMet: ProfielId[]; deelMode: DeelMode }> = {},
  ): DelenSectionGroup {
    return this.formBuilder.nonNullable.group({
      deelMode: this.formBuilder.nonNullable.control(initialState.deelMode ?? 'SPECIFIEK'),
      gedeeldMet: this.createGedeeldMetToFormArray(teamleden, initialState.gedeeldMet),
    });
  }
}
