<ion-toggle
  (ionChange)="onToggleChange()"
  [attr.aria-label]="ariaLabel"
  [(ngModel)]="checked"
  [disabled]="disabled"
  class="ion-focusable typo-body variant-body-fat"
  labelPlacement="start"
  mode="ios"
>
  <span class="typo-body whitespace-pre-wrap">
    <ng-content></ng-content>
  </span>
</ion-toggle>
