import { Component, Input, OnInit } from '@angular/core';
import { DrieKolommenDocumentDto, Rubriek } from 'parkour-web-app-dto';
import { teleOnthaalOrganisation, WatwatService } from '../../../../shared/services/watwat.service';
import {
  ParkourAccordionComponent,
  ParkourDividerComponent,
  ParkourHeroComponent,
  ParkourImageComponent,
  ParkourInfoCardComponent,
  ParkourModalComponent,
  ParkourPageImageComponent,
} from '@parkour/ui';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncPipe, DatePipe, NgIf } from '@angular/common';
import { HelplineCardItemComponent } from '../../../../home/component/helpline-card-item/helpline-card-item.component';
import { GedeeldMetIndicatorComponent } from '../../../../delen/component/gedeeld-met-indicator/gedeeld-met-indicator.component';
import { ExJongereInfoCardComponent } from '../../../../shared/components/ex-jongere-info-card/ex-jongere-info-card.component';

type AccordionItem = {
  content: string;
  header: string;
};

@Component({
  standalone: true,
  selector: 'parkour-drie-kolommen-gebeurtenis-details-component',
  templateUrl: './drie-kolommen-gebeurtenis-details-component.component.html',
  imports: [
    ParkourPageImageComponent,
    ParkourHeroComponent,
    ParkourImageComponent,
    TranslateModule,
    ParkourDividerComponent,
    NgIf,
    DatePipe,
    ParkourInfoCardComponent,
    HelplineCardItemComponent,
    ParkourAccordionComponent,
    ParkourModalComponent,
    GedeeldMetIndicatorComponent,
    AsyncPipe,
    ExJongereInfoCardComponent,
  ],
})
export class DominoGebeurtenisDetailsComponent implements OnInit {
  @Input({ required: true }) document!: DrieKolommenDocumentDto;

  zorgen: Array<AccordionItem> = [];
  krachten: Array<AccordionItem> = [];
  acties: Array<AccordionItem> = [];
  teleOnthaalOrganisation$ = this.watwatService.getOrganisationsOfIds([76]);
  protected readonly teleOnthaalOrganisation = teleOnthaalOrganisation;
  protected readonly String = String;

  constructor(private readonly watwatService: WatwatService) {}

  ngOnInit(): void {
    this.zorgen = this.getSubrubriekenItems(this.document, 'ZORGEN');
    this.krachten = this.getSubrubriekenItems(this.document, 'KRACHTEN');
    this.acties = this.getSubrubriekenItems(this.document, 'ACTIES');
  }

  getSubrubriekenItems(
    document: DrieKolommenDocumentDto,
    rubriek: Rubriek,
  ): Array<{
    content: string;
    header: string;
  }> {
    const items: Array<{
      content: string;
      header: string;
    }> = [];
    document.rubrieken
      .filter((docRubriek) => docRubriek.rubriek === rubriek)
      .forEach((docRubriek) =>
        items.push({
          header: docRubriek.subrubriek,
          content: docRubriek.inhoud,
        }),
      );
    return items;
  }
}
