import { Component } from '@angular/core';
import { forkJoin, Observable, switchMap } from 'rxjs';
import {
  IonContent,
  IonHeader,
  IonRefresher,
  IonRefresherContent,
} from '@ionic/angular/standalone';
import { PageLayoutComponent } from '../../../shared/components/page-layout/page-layout.component';
import { PageWithHeroComponent } from '../../../shared/components/page-with-hero/page-with-hero.component';
import { ProfielFotoOfJongerePipe } from '../../../shared/pipes/profiel-foto-of-jongere.pipe';
import { AsyncPipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import {
  ParkourBlockPlaceholderComponent,
  ParkourButtonComponent,
  ParkourLoadingSpinnerComponent,
  ParkourSegmentComponent,
} from '@parkour/ui';
import { ContentUnavailableCardComponent } from '../../../shared/components/content-unavailable-card/content-unavailable-card.component';
import { DoelListItemComponent } from '../../doel-shared/component/doel-list-item/doel-list-item.component';
import { ProfielnaamPipe } from '../../../shared/pipes/profielnaam.pipe';
import { DoelVoorstelComponent } from '../../doel-shared/component/doel-voorstel/doel-voorstel.component';
import { PageContentDirective } from '../../../shared/directives/page-content.directive';
import { PageSectionDirective } from '../../../shared/directives/page-section.directive';
import { ParkourRefreshDirective } from '../../../shared/directives/parkour-refresh-directive';
import { DoelenPlaceholderComponent } from '../../doel-shared/component/doelen-placeholder/doelen-placeholder.component';
import { DoelListComponent } from '../../doel-shared/component/doel-list/doel-list.component';
import { DoelOverviewPage } from '../../doel-shared/page/doel-overview/doel-overview-page';
import { ContextService } from '../../../shared/services/context.service';
import AuthService from '../../../authentication/service/auth.service';
import { Doel, DoelSuggestie } from '../../doel-shared/model/doel';
import { HasMeldingenForDoelPipe } from '../../doel-shared/pipe/has-meldingen-for-doel.pipe';

@Component({
  standalone: true,
  templateUrl: './doelen-teamlid.page.html',
  imports: [
    IonHeader,
    IonContent,
    PageLayoutComponent,
    PageWithHeroComponent,
    ProfielFotoOfJongerePipe,
    AsyncPipe,
    TranslateModule,
    ParkourSegmentComponent,
    ContentUnavailableCardComponent,
    DoelListItemComponent,
    ProfielnaamPipe,
    ParkourButtonComponent,
    DoelVoorstelComponent,
    ParkourLoadingSpinnerComponent,
    ParkourBlockPlaceholderComponent,
    PageContentDirective,
    PageSectionDirective,
    HasMeldingenForDoelPipe,
    ParkourRefreshDirective,
    IonRefresher,
    IonRefresherContent,
    DoelenPlaceholderComponent,
    DoelListComponent,
  ],
})
export class DoelenTeamlidPage extends DoelOverviewPage {
  teamlidContext$ = this.contextService.teamlidContext$();
  constructor(
    private readonly contextService: ContextService,
    private readonly authService: AuthService,
  ) {
    super();
  }

  override getDoelSuggesties(): Observable<DoelSuggestie[]> {
    return forkJoin([this.teamlidContext$, this.authService.getAangemeldeUser$()]).pipe(
      switchMap(([context, user]) =>
        this.doelenService.getDoelSuggestiesBy(user.profielId, context.contextId),
      ),
    );
  }

  getActieveDoelen(): Observable<Doel[]> {
    return this.doelenService.getGedeeldeActieveDoelen();
  }

  getBehaaldeDoelen(): Observable<Doel[]> {
    return this.doelenService.getGedeeldeBehaaldeDoelen();
  }
}
