<div class="grid grid-cols-[1fr_auto_auto] items-center gap-x-2">
  <parkour-button
    (clicked)="downloadFile()"
    [ngClass]="{ 'text-negative': file.type === 'error' }"
    classes="!text-left grid grid-cols-[auto_1fr]"
    iconBefore="attachment"
    size="none"
    variant="ghost"
  >
    {{ file.naam }}
  </parkour-button>

  <div>
    @if (file.type === 'uploading') {
      <parkour-loading-spinner />
    }
  </div>

  <parkour-button
    (clicked)="removeFile()"
    [ariaLabel]="'buttons.delete' | translate"
    class="flex"
    classes="!text-negative"
    format="iconButton"
    icon="trash"
    variant="ghost-light"
  />
</div>

@if (file.type === 'error') {
  <span class="text-negative typo-body variant-body-small">Er was een probleem met deze file</span>
}
