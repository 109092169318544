<div
  [ngClass]="{ 'page-layout--full-width': (sidebarVisible$ | async) === false }"
  class="page-layout relative h-full"
>
  <div
    [ngClass]="{
      'col-start-2 col-end-3': sidebarVisible$ | async,
      'col-start-1 col-end-2': (sidebarVisible$ | async) === false,
    }"
    class="relative z-10 row-start-2 row-end-4 h-full"
  >
    <ng-content></ng-content>
  </div>
</div>
