import { inject, Pipe, PipeTransform } from '@angular/core';
import { AttachmentBerichtDto, BestandMetadataDto, GesprekId } from 'parkour-web-app-dto';
import { BerichtenService } from '../../service/berichten.service';
import { Observable } from 'rxjs';

@Pipe({
  name: 'berichtAttachmentMeta',
  standalone: true,
})
export class BerichtAttachmentMetaPipe implements PipeTransform {
  private readonly berichtService = inject(BerichtenService);

  transform(gesprekId: GesprekId, bericht: AttachmentBerichtDto): Observable<BestandMetadataDto> {
    return this.berichtService.getBestandMetadata(gesprekId, bericht.id, bericht.bestandId);
  }
}
