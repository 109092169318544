import { Component, Input } from "@angular/core";
import { NgClass } from "@angular/common";

export type TParkourSpinnerSizes = "default" | "small";
export type TParkourSpinnerVariant = "default" | "secondary";

@Component({
  selector: "parkour-loading-spinner",
  templateUrl: "./parkour-loading-spinner.component.html",
  standalone: true,
  imports: [NgClass],
})
export class ParkourLoadingSpinnerComponent {
  @Input() public classes?: string;
  @Input() public size: TParkourSpinnerSizes = "default";
  @Input() public variant: TParkourSpinnerVariant = "default";
}
