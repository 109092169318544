import { Pipe, PipeTransform } from '@angular/core';
import { getProfielnaam } from '../../profiel/model/profiel-in-team';
import { ProfielId } from 'parkour-web-app-dto';
import { map, Observable } from 'rxjs';
import { ProfielService } from '../../profiel/service/profiel.service';

@Pipe({
  standalone: true,
  name: 'profielnaamFromProfielId',
})
export class ProfielnaamFromProfielidPipe implements PipeTransform {
  constructor(private readonly profielService: ProfielService) {}

  transform(profielId: ProfielId): Observable<string> {
    return this.profielService
      .getProfielInCurrentTeam(profielId)
      .pipe(map((profiel) => getProfielnaam(profiel)));
  }
}
