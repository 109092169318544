import { Injectable } from '@angular/core';
import { MatomoTracker } from 'ngx-matomo-client';
import { AnalyticsEvent, EventCategory } from './analytics-event.model';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  constructor(private readonly tracker: MatomoTracker) {}

  public trackEvent<C extends EventCategory>(event: AnalyticsEvent<C>): void {
    this.tracker.trackEvent(event.category, event.event);
  }

  public optUserOut(): void {
    this.tracker.optUserOut();
  }

  public forgetUserOptOut(): void {
    this.tracker.forgetUserOptOut();
  }

  public isUserOptedOut() {
    return this.tracker.isUserOptedOut();
  }
}
