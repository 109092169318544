<parkour-card class="block mt-4" icon="addpeople">
  <p>
    {{
      'team.uitnodiging.uitnodiging-description'
        | translate: { naam: uitnodiging.initiator, rol: uitnodiging.rol | rolLabel }
    }}
  </p>

  <div class="flex items-center justify-between gap-4 mt-4">
    <parkour-button (clicked)="onNeeClick()" class="w-full" size="small" variant="tertiary">
      {{ 'buttons.nee' | translate }}
    </parkour-button>
    <parkour-button (clicked)="onJaClick()" class="w-full" size="small" variant="secondary">
      {{ 'buttons.ja' | translate }}
    </parkour-button>
  </div>
</parkour-card>
