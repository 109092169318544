<ion-button
  #ionButton
  (click)="handleClicked()"
  [attr.aria-label]="label"
  [attr.data-testid]="testId"
  [disabled]="disabled"
  [form]="form"
  [routerLink]="link"
  [type]="type"
  fill="solid"
  mode="ios"
>
  <div class="flex items-center gap-x-1">
    @if (loading) {
      <parkour-loading-spinner size="small" variant="secondary" />
    }

    <span class="typo-body variant-body-small variant-body-fat text-n-1000 max-md:hidden">
      {{ label }}
    </span>

    <parkour-icon [name]="icon" class="block w-[1.25rem] h-[1.25rem] text-n-1000" />
  </div>
</ion-button>
