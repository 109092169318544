<div class="flex items-stretch">
  <div class="flex flex-col gap-y-2 w-[20px]" role="presentation">
    <div class="flex flex-col w-full h-[56px] relative">
      <div
        class="absolute z-10 bottom-0 right-0 translate-x-1/2 translate-y-[7px] rounded-[50%] border-[3px] border-n-100 bg-n-300 w-[16px] h-[16px]"
      ></div>

      <div
        class="relative z-20 w-[2px] h-[46px] bg-n-300 rounded-tl-[100vmax] rounded-tr-[100vmax]"
      ></div>

      <div
        class="relative z-20 w-full h-[10px] border-l-2 border-b-2 rounded-bl border-n-300"
      ></div>
    </div>

    @if (!isLastItem) {
      <div
        class="relative z-20 w-[2px] flex-1 bg-n-300 rounded-tl-[100vmax] rounded-tr-[100vmax]"
      ></div>
    }
  </div>

  <div [ngClass]="{ 'pb-6': !isLastItem }" class="flex-1">
    @switch (verhaalItem.type) {
      @case ('PARKOUR') {
        <parkour-gebeurtenis-list-item-card
          [parkourGebeurtenis]="verhaalItem"
          [ongelezenGebeurtenisMeldingen]="ongelezenParkourGebeurtenisMeldingen"
        />
      }
      @case ('EYOUTH') {
        <parkour-jeugdhulp-historiek-list-item-card
          [eyouthGebeurtenis]="verhaalItem"
          [ongelezenEyouthGebeurtenisMeldingen]="ongelezenJeugdhulpHistoriekMeldingen"
        />
      }
      @case ('DRIE_KOLOMMEN') {
        <parkour-drie-kolommen-gebeurtenis-list-item-card
          [document]="verhaalItem"
          [ongelezenDrieKolommenMeldingen]="ongelezenDrieKolommenMeldingen"
        />
      }
    }
  </div>
</div>
