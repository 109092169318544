<div
  [attr.data-testid]="testId"
  [ngClass]="{
    'border-n-500': !error && !disabled && !isFocus,
    'border-negative': error,
    'border-n-300': disabled,
    'border-n-600': isFocus,
  }"
  class="flex border-[1.5px] rounded bg-n-200 transition-colors
    {{ classes }}
  "
>
  <textarea
    (blur)="onBlur()"
    (focus)="onFocus()"
    (input)="onInputChange($event)"
    [attr.required]="required"
    [disabled]="disabled"
    [formControl]="formControl || value"
    [id]="inputId"
    [name]="inputId"
    [ngClass]="{
      'placeholder:text-n-1000-7': !disabled,
      'cursor-not-allowed placeholder:text-n-1000-3': disabled,
    }"
    [placeholder]="inputPlaceholder"
    class="min-h-[8rem] lg:min-h-[20rem] flex-1 w-full border-none resize-none bg-n-200 placeholder:typo-body typo-body text-n-1000 pl-3 px-2 py-2 rounded"
    parkourFocusVisibleElement
  ></textarea>
</div>
