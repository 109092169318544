<form (ngSubmit)="onFotoSubmit()" class="flex flex-col gap-y-6">
  @if (!imageToCrop) {
    @if (imageSrc(); as image) {
      <parkour-image class="rounded overflow-hidden" [src]="image | secureImage | async" alt="" />
    }
  }

  @if (imageToCrop) {
    <image-cropper
      (imageCropped)="imageCropped($event)"
      (imageLoaded)="imageLoaded()"
      (loadImageFailed)="loadImageFailed()"
      [aspectRatio]="aspectRatio"
      [hidden]="isImageCropperHidden"
      [imageBase64]="imageToCrop"
      [maintainAspectRatio]="true"
      [resizeToWidth]="700"
      class="rounded"
      [format]="'jpeg'"
    />

    @if (isImageCropperHidden) {
      <parkour-loading-spinner />
    }
  }

  <div class="flex flex-col gap-y-6">
    <h4>{{ 'verhaal.upload-picture' | translate }}</h4>

    @if (!isNativeApp) {
      <parkour-button
        (clicked)="handleClickOpenImageLibrary()"
        class="w-full"
        iconAfter="camera"
        variant="tertiary"
      >
        {{ 'buttons.upload-picture' | translate }}
      </parkour-button>
    } @else {
      <parkour-button
        (clicked)="handleClickTakeSelfie()"
        class="w-full"
        iconAfter="camera"
        variant="tertiary"
      >
        {{ 'buttons.capture-picture' | translate }}
      </parkour-button>

      <parkour-button
        (clicked)="handleClickOpenImageLibrary()"
        class="w-full"
        iconAfter="ai-images"
        variant="tertiary"
      >
        {{ 'buttons.open-library' | translate }}
      </parkour-button>
    }
  </div>

  <parkour-button class="w-full" iconAfter="disk" type="submit">
    {{ 'profiel.image-submit-button' | translate }}
  </parkour-button>
</form>
