import { Injectable } from "@angular/core";
import { ModalController } from "@ionic/angular/standalone";
import { TParkourIconName } from "../parkour-icon/parkour-icon.types";
import { ParkourYesNoPopupComponent } from "./yes-no-popup/parkour-yes-no-popup.component";

export type PopupResult = 'yes' | 'no' | 'closed-externally';

@Injectable({
  providedIn: "root",
})
export class ParkourPopupService {
  constructor(private readonly modalController: ModalController) {}

  async showPopup(config: {
    title: string;
    description: string;
    icon: TParkourIconName;
    jaText?: string;
    neeText?: string;
  }): Promise<PopupResult> {
    const id = "yes-no-popup";
    const modal = await this.modalController.create({
      component: ParkourYesNoPopupComponent,
      showBackdrop: true,
      cssClass: "popup dynamic",
      id,
      componentProps: {
        icon: config.icon,
        title: config.title,
        description: config.description,
        jaText: config.jaText || "Ja",
        neeText: config.neeText || "Nee",
        closeCallback: () => this.modalController.dismiss(null, "close", id),
        cancelCallback: () => this.modalController.dismiss(null, "cancel", id),
        confirmCallback: () => this.modalController.dismiss(null, "confirm", id),
      },
    });

    await modal.present();

    const { role } = await modal.onWillDismiss();

    switch (role) {
      case "confirm":
        return 'yes'
      case "cancel":
        return 'no'
      case "close":
        return 'closed-externally'
      default:
        return 'closed-externally'
    }
  }
}
