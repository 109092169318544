import { ErrorHandler, Injectable } from '@angular/core';
import { ParkourToastService } from '@parkour/ui';
import { HumanReadableError, TranslatedHumanReadableError } from './human-readable-error';
import { LoggingService } from './logging.service';
import { map, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { SilentError } from './silent-error';
import { trace, Tracer } from '@opentelemetry/api';

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {
  constructor(
    private readonly toastService: ParkourToastService,
    private readonly loggingService: LoggingService,
    private readonly translateService: TranslateService,
  ) {
    super();
  }

  getErrorContent(error: HumanReadableError): Observable<{ header: string; message: string }> {
    if (error instanceof TranslatedHumanReadableError) {
      const headerKey = error.getHeaderKey();
      const messageKey = error.getMessageKey();
      return this.translateService.get([headerKey, messageKey]).pipe(
        map((translations) => ({
          header: translations[headerKey],
          message: translations[messageKey],
        })),
      );
    } else {
      return this.translateService
        .get('errors.default-error.title')
        .pipe(map((header) => ({ header, message: error.message })));
    }
  }

  override handleError(error: Error | string): void {
    const tracer = trace.getTracer('error-handler');
    this.loggingService.error('An error occured: ', error);
    if (error) {
      if (error instanceof Error) {
        this.logErrorInOpentelemetry(tracer, error);
      }
      try {
        if (error instanceof HumanReadableError) {
          this.getErrorContent(error).subscribe((errorContent) =>
            this.toastService
              .showToast({
                header: errorContent.header,
                content: errorContent.message,
                error: true,
              })
              .catch((e) =>
                this.loggingService.error('An error occured while showing the toast: ', e),
              ),
          );
        } else if (error instanceof SilentError) {
          this.loggingService.error('A silent error occured: ', error);
        } else if (typeof error === 'string' && error.includes('MatomoTracker')) {
          // Ignore weird Matomo errors
          return;
        } else {
          this.toastService
            .showToast({
              header: 'Oeps!',
              content: 'Er ging iets mis. Probeer opnieuw of herstart de applicatie.',
              error: true,
            })
            .catch((e) =>
              this.loggingService.error('An error occured while showing the toast: ', e),
            );
        }
      } catch (e) {
        this.loggingService.error('An error occured in the error handler: ', e);
      }
    }
  }

  private logErrorInOpentelemetry(tracer: Tracer, error: Error) {
    const span = tracer.startSpan('Error Handling');
    span.recordException({
      message: error.message || 'Unknown error',
      stack: error.stack || 'No stack trace',
      name: error.name || 'Error',
    });
    span.setStatus({ code: 2, message: error.message });
    span.end();
  }
}
