import { inject, Injectable } from '@angular/core';
import { MatomoRouterInterceptor, MatomoTracker } from 'ngx-matomo-client';
import { NavigationEnd } from '@angular/router';

@Injectable()
export class UuidFilterInterceptor implements MatomoRouterInterceptor {
  private readonly tracker = inject(MatomoTracker);

  beforePageTrack(event: NavigationEnd): void {
    this.tracker.setCustomUrl(this.filterUuidAndContextId(event.url));
  }

  private filterUuidAndContextId(url: string): string {
    return url.replace(/[a-f0-9]{8}-([a-f0-9]{4}-){3}[a-f0-9]{12}/g, 'id');
  }
}
