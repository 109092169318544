import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../../environments/environment';

@Pipe({
  name: 'makeReferencesAbsolute',
  standalone: true,
})
export class MakeReferencesAbsolutePipe implements PipeTransform {
  transform(value: string): string {
    const parser = new DOMParser();
    const dom = parser.parseFromString(value, 'text/html');

    const anchors = dom.getElementsByTagName('a');

    for (const anchor of Array.from(anchors)) {
      const href = anchor.getAttribute('href');
      if (href && href.startsWith('/')) {
        const absoluteLink = environment.watWatBaseUrl + href;
        anchor.setAttribute('href', absoluteLink);
      }
    }

    const images = dom.getElementsByTagName('img');

    for (const image of Array.from(images)) {
      const src = image.getAttribute('src');
      if (src && src.startsWith('/')) {
        const absoluteLink = environment.watWatBaseUrl + src;
        image.setAttribute('src', absoluteLink);
      }
    }

    return new XMLSerializer().serializeToString(dom);
  }
}
