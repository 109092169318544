import { Component, Input } from "@angular/core";
import {
  IParkourPictureSources,
  TParkourFetchPriority,
  TParkourPictureFit,
  TParkourPicturePosition,
} from "./parkour-picture.types";
import { NgClass, NgIf } from "@angular/common";

@Component({
  selector: "parkour-picture",
  templateUrl: "./parkour-picture.component.html",
  standalone: true,
  imports: [NgClass, NgIf],
})
export class ParkourPictureComponent {
  @Input() public classes?: string;
  @Input() public alt: string = "";
  @Input() public sources!: IParkourPictureSources;
  @Input() public fit: TParkourPictureFit = "cover";
  @Input() public position: TParkourPicturePosition = "center";
  @Input() public rounded: boolean = false;
  @Input() public fetchPriority: TParkourFetchPriority = "auto";
}
