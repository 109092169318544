<div class="flex items-center justify-between gap-x-2">
  @if (reactieOverview.getTotalAmountOfReacties() > 0) {
    <parkour-emoji-reacties
      (deleteEmoji)="onDeleteEmoji()"
      [reacties]="reactieOverview.getReacties()"
      [totalAmount]="reactieOverview.getTotalAmountOfReacties()"
      [userProfielId]="userProfielId"
      type="doel"
    />
  }

  <parkour-action
    (clicked)="openEmojiSelect($event)"
    [ariaLabel]="'buttons.add-reaction' | translate"
    class="bg-n-400 w-[1.5rem] h-[1.5rem] rounded-[10rem] flex items-center justify-center ml-auto"
  >
    <parkour-icon class="flex" name="add-reaction" />
  </parkour-action>

  <ion-popover
    parkourPopover
    #popoverEmojiSelect="parkourPopover"
    [alignment]="'end'"
    [side]="'bottom'"
    [size]="'auto'"
    [showBackdrop]="false"
  >
    <ng-template>
      @if (isNativeApp()) {
        <parkour-button (clicked)="popoverEmojiSelect.close()" class="sr-only outline-none">
          {{ 'buttons.close-popover' | translate }}
        </parkour-button>
      }

      <div class="p-4 rounded bg-n-200 shadow-1">
        <parkour-add-emoji
          (deleteEmoji)="onDeleteEmoji()"
          (selectedEmoji)="onSelectedEmoji($event)"
          [activeEmojiKey]="getActiveSelectedReactie()"
        />
      </div>
    </ng-template>
  </ion-popover>
</div>
