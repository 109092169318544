import { Component, Input } from '@angular/core';
import { Gebeurtenis } from '../../model/gebeurtenis';
import { verhaalHeroSources } from '../../../constants';
import { PageWithHeroComponent } from '../../../../shared/components/page-with-hero/page-with-hero.component';
import { GebeurtenisHeroPipe } from '../../pipes/gebeurtenis-hero.pipe';
import { AsyncPipe, DatePipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { GedeeldMetIndicatorComponent } from '../../../../delen/component/gedeeld-met-indicator/gedeeld-met-indicator.component';
import { GedeeldMetInfoComponent } from '../../../../delen/component/gedeeld-met-info/gedeeld-met-info.component';
import { ActieListComponent } from '../../../../doel/doel-shared/component/actie-list/actie-list.component';
import { ParkourButtonComponent } from '@parkour/ui';
import { VerhaalService } from '../../service/verhaal.service';
import { FileData } from 'parkour-web-app-dto';
import { ExJongereInfoCardComponent } from '../../../../shared/components/ex-jongere-info-card/ex-jongere-info-card.component';

@Component({
  standalone: true,
  selector: 'parkour-gebeurtenis-details-component',
  templateUrl: './parkour-gebeurtenis-details-component.component.html',
  imports: [
    PageWithHeroComponent,
    GebeurtenisHeroPipe,
    AsyncPipe,
    TranslateModule,
    DatePipe,
    GedeeldMetIndicatorComponent,
    GedeeldMetInfoComponent,
    ActieListComponent,
    ParkourButtonComponent,
    ExJongereInfoCardComponent,
  ],
})
export class ParkourGebeurtenisDetailsComponent {
  @Input({ required: true }) gebeurtenis!: Gebeurtenis;
  @Input({ required: true }) exJongereView!: boolean;

  protected readonly verhaalHeroSources = verhaalHeroSources;

  constructor(private readonly verhalenService: VerhaalService) {}

  downloadFile(file: FileData) {
    if (!this.gebeurtenis.id) {
      throw new Error('Gebeurtenis has no id');
    }

    this.verhalenService.downloadBijlage(this.gebeurtenis.id, file).subscribe();
  }
}
