<div class="flex items-center gap-x-2">
  <ng-container *ngIf="isCompleted(); else inProgress">
    <parkour-icon class="text-positive flex" name="check" size="3" />

    <span class="typo-body text-n-600">{{ 'doelen.doelen-page.completed-doel' | translate }}</span>
  </ng-container>

  <ng-template #inProgress>
    <ng-container *ngIf="getTotalAmountOfAction() > 0">
      <parkour-doel-progress-circle
        [currentValue]="getAmountOfCompletedAction()"
        [maxValue]="getTotalAmountOfAction()"
      ></parkour-doel-progress-circle>

      <span
        *ngIf="getTotalAmountOfAction() > 1 || getTotalAmountOfAction() === 0; else single"
        class="typo-body text-n-700"
      >
        {{ getTotalAmountOfAction() }}{{ 'doelen.doelen-page.acties' | translate }}
      </span>

      <ng-template #single>
        <span class="typo-body text-n-700">
          {{ getTotalAmountOfAction() }}{{ 'doelen.doelen-page.actie' | translate }}
        </span>
      </ng-template>
    </ng-container>
  </ng-template>
</div>
