import { Component, Input } from "@angular/core";
import { TParkourIconName } from "../parkour-icon/parkour-icon.types";
import { ParkourIconComponent } from "../parkour-icon/parkour-icon.component";
import { IonRadio } from "@ionic/angular/standalone";
import { NgIf } from "@angular/common";

@Component({
  selector: "parkour-radio",
  templateUrl: "./parkour-radio.component.html",
  standalone: true,
  imports: [ParkourIconComponent, IonRadio, NgIf],
})
export class ParkourRadioComponent {
  @Input() public classes?: string;
  @Input() public disabled = false;
  @Input() public value?: string | number;
  @Input() public label?: string;
  @Input() public icon?: TParkourIconName;
  @Input({ required: true }) inputId!: string;

  //   @Input({ required: true }) formControl!: FormControl;
}
