<ul class="divide-y-[1.5px] divide-n-300">
  <li *ngFor="let actie of acties; let i = index" class="py-4 first:pt-0 last:pb-0">
    <parkour-actie-item-view
      (toggleActieVoltooid)="toggleActie(i, $event)"
      [actie]="actie"
      [editable]="editable"
      class="flex"
    />
  </li>
</ul>
