<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button parkourBackButton />
    </ion-buttons>

    <ion-title aria-level="1" role="heading"
      >{{ 'profiel.verwijderen-pagina.titel' | translate }}
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <parkour-page-layout>
    <parkour-page-gradient />

    <div class="relative z-10 py-8 container container-space">
      <div class="container-1-col flex flex-col gap-y-8">
        <h1 class="text-center">{{ 'profiel.verwijderen-pagina.titel' | translate }}</h1>

        <parkour-divider class="block mt-12" />

        <parkour-info-card class="block mt-12 mb-12">
          {{ 'profiel.verwijderen-pagina.info' | translate }}
        </parkour-info-card>

        <div>
          <p class="font-semibold">{{ 'profiel.verwijderen-pagina.subtitel' | translate }}</p>

          <p class="mt-2">{{ 'profiel.verwijderen-pagina.description-1' | translate }}</p>

          <p class="mt-2">{{ 'profiel.verwijderen-pagina.description-2' | translate }}</p>

          <parkour-button
            (clicked)="onVerwijderClicked()"
            [iconAfter]="'trash'"
            class="block w-full mt-12"
            >{{ 'profiel.verwijderen-pagina.verzenden-button' | translate }}
          </parkour-button>
        </div>
      </div>
    </div>
  </parkour-page-layout>
</ion-content>
