<div class="flex items-center gap-x-2 p-2 rounded bg-n-100 border-[1px] border-n-300">
  <ng-container *ngIf="!isAudioPlayer; else audioPlayer">
    <div class="w-full flex-1 flex gap-x-2 items-center">
      <div class="bericht-recorder__dot"></div>

      <div class="w-full flex-1 flex justify-center">
        <div class="h-[1.2rem] flex gap-x-[0.125rem]">
          <div
            *ngFor="let item of [].constructor(20); let i = index"
            class="bericht-recorder__bar bericht-recorder__bar--{{
              i + 1
            }} bg-n-400 h-full w-[0.125rem] rounded scale-40"
          ></div>
        </div>
      </div>
    </div>

    <span class="typo-body !text-2 text-n-1000">{{ durationDisplay }}</span>
  </ng-container>

  <ng-template #audioPlayer>
    @if (recording && recording.value) {
      <parkour-audio-player
        [audioFile]="recording.value.recordDataBase64"
        [customDuration]="recording.value.msDuration / 1000"
        [audioType]="recording.value.mimeType"
        class="flex-1"
      />
    }
  </ng-template>
</div>
