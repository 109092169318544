import { Component, Input } from "@angular/core";
import { FormControl, ValidationErrors } from "@angular/forms";
import { NgClass, NgIf } from "@angular/common";
import { ParkourIconComponent } from "../parkour-icon/parkour-icon.component";

@Component({
  standalone: true,
  selector: "parkour-form-field",
  templateUrl: "./parkour-form-field.component.html",
  imports: [NgClass, ParkourIconComponent, NgIf],
})
export class ParkourFormFieldComponent {
  @Input() public classes: string = "";
  @Input({ required: true }) public inputId!: string;
  @Input() public testId?: string;
  @Input({ required: true }) public label!: string;
  @Input() public isLabelHidden = false;
  @Input() public inputFormControl?: FormControl | null;
  @Input() public errorMessages?: Record<string, string>;
  @Input() public additionalErrors: ValidationErrors | null = null;
  @Input() public required = false;
  protected readonly Object = Object;

  getFormErrors() {
    return {
      ...this.inputFormControl?.errors,
      ...this.additionalErrors,
    };
  }

  getErrorMessage(key: string) {
    const errorStrings: Record<string, string> = {
      required: `Vul een ${this.label?.toLocaleLowerCase()} in.`,
      ...this.errorMessages,
    };
    if (key in errorStrings) {
      return errorStrings[key];
    } else {
      return null;
    }
  }
}
