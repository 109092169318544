import { Component, Input } from "@angular/core";
import { NgIf } from "@angular/common";
import { ParkourImageComponent } from "../parkour-image/parkour-image.component";

@Component({
  selector: "parkour-page-image",
  templateUrl: "./parkour-page-image.component.html",
  standalone: true,
  imports: [NgIf, ParkourImageComponent],
})
export class ParkourPageImageComponent {
  @Input() public classes?: string;
  @Input() public src: string | null = null;
  @Input() public alt?: string;
}
