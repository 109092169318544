import { Component, Input } from "@angular/core";
import { IParkourAvatarImageProps } from "./parkour-avatar.types";
import { NgClass, NgIf } from "@angular/common";
import { ParkourIconComponent } from "../parkour-icon/parkour-icon.component";
import { ParkourImageComponent } from "../parkour-image/parkour-image.component";

@Component({
  standalone: true,
  selector: "parkour-avatar",
  templateUrl: "./parkour-avatar.component.html",
  imports: [NgClass, ParkourIconComponent, ParkourImageComponent, NgIf],
})
export class ParkourAvatarComponent {
  @Input() public classes?: string;
  @Input({ required: true }) public image!: IParkourAvatarImageProps;
  @Input() public professional: boolean = false;
  @Input() public updated: boolean = false;
  @Input() public badgeCount?: number;

  identifier: number | null = null;

  constructor() {
    this.identifier = Math.random();
  }

  getMaskId() {
    return `avatar-mask-${this.identifier}`;
  }

  getMaskUrl() {
    return `url(#${this.getMaskId()})`;
  }

  getSvgHeight() {
    if (this.professional && this.updated) {
      return "178";
    }

    if (this.professional || this.updated) {
      return "171";
    }

    return "164";
  }

  getSvgY() {
    if (this.updated) {
      return "7";
    }

    return "0";
  }

  getSvgBadgeMaskTranslate() {
    if (this.professional && !this.updated) {
      return "translate(0 -6.5)";
    }

    return "translate(0 1)";
  }

  getSvgBadgeTranslate() {
    if (this.professional && !this.updated) {
      return "translate(0 -7)";
    }

    return "translate(0 0)";
  }

  getSvgViewBox() {
    return `0 0 164 ${this.getSvgHeight()}`;
  }
}
