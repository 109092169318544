import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ParkourButtonComponent, ParkourLoadingSpinnerComponent } from '@parkour/ui';
import { TranslateModule } from '@ngx-translate/core';
import { AttachmentFileData } from '../gebeurtenis-attachments-upload/attachments-upload.component';
import { NgClass } from '@angular/common';

@Component({
  standalone: true,
  selector: 'parkour-gebeurtenis-attachment-upload',
  templateUrl: './gebeurtenis-attachment-upload.component.html',
  imports: [ParkourButtonComponent, TranslateModule, NgClass, ParkourLoadingSpinnerComponent],
})
export class GebeurtenisAttachmentUploadComponent {
  @Input({ required: true }) file!: AttachmentFileData;

  @Output() remove = new EventEmitter<void>();
  @Output() download = new EventEmitter<void>();

  constructor() {}

  removeFile() {
    this.remove.emit();
  }

  async downloadFile() {
    this.download.emit();
  }
}
