import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { CookiesModalComponent } from '../components/cookies-modal/cookies-modal.component';
import { ReplaySubject, take } from 'rxjs';
import { AnalyticsService } from '../../analytics/analytics.service';
import { AnalyticsEvent } from '../../analytics/analytics-event.model';

@Injectable({
  providedIn: 'root',
})
export class ParkourCookiesService {
  constructor(
    private readonly cookieService: CookieService,
    private readonly analyticsService: AnalyticsService,
  ) {}

  cookiePopup$ = new ReplaySubject<CookiesModalComponent>(1);

  public registerCookiePopup(cookiePopup: CookiesModalComponent) {
    this.cookiePopup$.next(cookiePopup);
  }

  public isMarketingCookiesEnabled(): boolean {
    return this.cookieService.get('marketingCookiesEnabled') === 'true';
  }

  public setMarketingCookiesEnabled(enabled: boolean) {
    this.cookieService.set('marketingCookiesEnabled', enabled ? 'true' : 'false', 365, '/');
    this.analyticsService.trackEvent(
      new AnalyticsEvent(
        'cookies',
        enabled ? 'marketingCookiesAangezet' : 'marketingCookiesUitgezet',
      ),
    );
  }

  public setAnalyticsCookiesEnabled(enabled: boolean) {
    this.cookieService.set('analyticsCookiesEnabled', enabled ? 'true' : 'false', 730, '/');
    this.analyticsService.trackEvent(
      new AnalyticsEvent(
        'cookies',
        enabled ? 'analytischeCookiesAangezet' : 'analytischeCookiesUitgezet',
      ),
    );
    if (enabled) {
      this.analyticsService.forgetUserOptOut();
    } else {
      this.analyticsService.optUserOut();
    }
  }

  public set callback(value: () => void) {
    this.cookiePopup$.pipe(take(1)).subscribe((modal) => (modal.confirmCallback = value));
  }

  public checkCookiePermissions() {
    if (!this.cookieService.check('marketingCookiesEnabled')) {
      this.openCookiePopup();
    }
  }

  public openCookiePopup() {
    this.cookiePopup$.pipe(take(1)).subscribe((modal) => modal.open());
  }
}
