import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'berichtenDate',
  standalone: true,
})
export class BerichtenDatePipe implements PipeTransform {
  constructor(private readonly datePipe: DatePipe) {}
  transform(timestamp: string): string | null {
    return this.formatTijdstip(timestamp);
  }

  private formatTijdstip(tijdstipString: string) {
    const tijdstip: Date = new Date(tijdstipString);
    if (tijdstip.getDay() === new Date().getDay()) {
      return this.datePipe.transform(tijdstip, 'HH:mm');
    } else {
      return this.datePipe.transform(tijdstip);
    }
  }
}
