import { Component, HostBinding } from '@angular/core';
import { ParkourBlockPlaceholderComponent } from '@parkour/ui';

@Component({
  selector: 'parkour-berichten-placeholder',
  standalone: true,
  imports: [ParkourBlockPlaceholderComponent],
  templateUrl: './berichten-placeholder.component.html',
})
export class BerichtenPlaceholderComponent {
  @HostBinding('class') class = 'block';
}
