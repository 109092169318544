import {
  AttachmentBerichtDto,
  BerichtDto,
  BerichtId,
  DeelnemerDto,
  EventBerichtDto,
  FotoId,
  GedeeldeResourceBerichtDto,
  GesprekDeelnemerUpsertDto,
  GesprekId,
  GesprekStatus,
  GesprekUpdateDto,
  GroepsgesprekInsertDto,
  ProfielId,
  SpraakBerichtDto,
  TextBerichtDto,
} from 'parkour-web-app-dto';
import { TeamlidProfiel } from '../../team/model/teamlid.model';
import { AangemeldeUser } from '../../authentication/user';
import { AbstractGesprek } from './abstractGesprek';
import { JongereProfiel } from '../../profiel/model/profiel';

export abstract class Gesprek extends AbstractGesprek {
  constructor(
    id: GesprekId,
    protected readonly partners: TeamlidDeelnemer[],
    public readonly jongere: JongereDeelnemer,
    aangemaaktOp: string,
    status: GesprekStatus,
    public readonly laatsteBericht?: Bericht,
    public readonly fotoId?: FotoId,
  ) {
    super(id, aangemaaktOp, status);
  }

  public abstract getGesprekNaam(user: AangemeldeUser): string;

  public abstract getGearchiveerdMessage(): string;

  public abstract getSubtitleForHeader(user: AangemeldeUser): string;

  public abstract getVisibleDeelnemer(
    userProfielId: ProfielId,
  ): TeamlidDeelnemer | JongereDeelnemer;

  public isGesprekWithBegeleider(): boolean {
    return this.getActieveDeelnemers().some((deelnemer) => {
      return deelnemer.status === 'ACTIEF' && deelnemer.type === 'PROFESSIONEEL_TEAMLID';
    });
  }

  public getDeelnemers(): Array<TeamlidDeelnemer> {
    return this.partners.filter(
      (deelnemer) => deelnemer.status === 'ACTIEF' || deelnemer.status === 'GEBLOKKEERD',
    );
  }

  public getDeelnemersAndJongereIncludingRemoved(): Array<TeamlidDeelnemer | JongereDeelnemer> {
    return [this.jongere, ...this.partners];
  }

  public getDeelnemersAndJongere(): Array<TeamlidDeelnemer | JongereDeelnemer> {
    return [this.jongere, ...this.getDeelnemers()];
  }

  public getActieveDeelnemers(): Array<TeamlidDeelnemer | JongereDeelnemer> {
    return this.partners.filter((deelnemer) => deelnemer.status === 'ACTIEF');
  }

  public getGeblokkeerdeDeelnemers(): Array<TeamlidDeelnemer> {
    return this.partners.filter((deelnemer) => deelnemer.status === 'GEBLOKKEERD');
  }

  public isActief(): boolean {
    return this.status === 'ACTIEF';
  }
}

type UnsupportedBericht = {
  readonly type: string;
  readonly id: BerichtId;
  readonly timestamp: string;
};

const supportedBerichtTypes: {
  [key in BerichtDto['type']]: boolean;
} = {
  TEKST: true,
  GEDEELDE_RESOURCE: true,
  ATTACHMENT: true,
  SPRAAK: true,
  EVENT: true,
  GEMASKEERD: true,
};

export function isSupportedBericht(bericht: Bericht): bericht is SupportedBericht {
  return Object.keys(supportedBerichtTypes).includes(bericht.type);
}

export type SupportedBericht = BerichtDto;
export type Bericht = BerichtDto | UnsupportedBericht;

export type TextBericht = TextBerichtDto;
export type AttachmentBericht = AttachmentBerichtDto;
export type SpraakBericht = SpraakBerichtDto;
export type EventBericht = EventBerichtDto;
export type GedeeldeResourceBericht = GedeeldeResourceBerichtDto;

export type Deelnemer = DeelnemerDto;

export type GroepsgesprekInsert = GroepsgesprekInsertDto;
export type GesprekUpdate = GesprekUpdateDto;

export type GesprekDeelnemerUpsert = GesprekDeelnemerUpsertDto;

export type TeamlidDeelnemer = TeamlidProfiel & {
  deelnemerId: ProfielId;
  channel: string;
};
export type JongereDeelnemer = JongereProfiel & {
  deelnemerId: ProfielId;
  channel: string;
};
