import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { AttachmentBerichtDto, BestandMetadataDto, GesprekId } from 'parkour-web-app-dto';
import { AbstractGesprek } from '../../model/abstractGesprek';
import { BerichtenDatePipe } from '../../pipes/berichten-date.pipe';
import { BerichtenService } from '../../service/berichten.service';
import { FileService } from '../../../shared/services/file.service';
import { TranslateModule } from '@ngx-translate/core';
import { isPlatform } from '@ionic/core';
import { BestandPreviewPipe } from '../../pipes/bestand-preview.pipe';
import {
  ParkourActionComponent,
  ParkourButtonComponent,
  ParkourIconComponent,
  ParkourImageComponent,
  ParkourLoadingSpinnerComponent,
  ParkourUiComponentsModule,
} from '@parkour/ui';
import { ProfielnaamPipe } from '../../../shared/pipes/profielnaam.pipe';
import { getIsTimeToday } from '../../../utils';
import { ProfielPipe } from '../../../shared/pipes/profiel.pipe';
import { BerichtAttachmentMetaPipe } from '../bericht-attachment-preview/bericht-attachment-meta.pipe';

@Component({
  selector: 'parkour-bericht-attachment-content',
  standalone: true,
  imports: [
    AsyncPipe,
    NgIf,
    BerichtenDatePipe,
    TranslateModule,
    NgClass,
    BestandPreviewPipe,
    ParkourUiComponentsModule,
    ProfielnaamPipe,
    ParkourImageComponent,
    ParkourIconComponent,
    ParkourLoadingSpinnerComponent,
    ParkourActionComponent,
    ParkourButtonComponent,
    ProfielPipe,
    BerichtAttachmentMetaPipe,
  ],
  templateUrl: './bericht-attachment-content.component.html',
})
export class BerichtAttachmentContentComponent {
  @Input({ required: true }) bericht!: AttachmentBerichtDto;
  @Input() isUser: boolean = false;
  @Input({ required: true }) gesprekId!: GesprekId;
  @Input({ required: true }) gesprek!: AbstractGesprek;
  @Input() disabled: boolean = false;
  @Input() isNameVisible = false;

  @Output() moreOptionsClick = new EventEmitter<void>();

  downloading = false;
  protected readonly getIsTimeToday = getIsTimeToday;

  constructor(
    private readonly berichtenService: BerichtenService,
    private readonly fileService: FileService,
  ) {}

  isDesktop() {
    return isPlatform('desktop');
  }

  onMoreOptionsClick() {
    this.moreOptionsClick.emit();
  }

  onDownloadAttachment(bericht: AttachmentBerichtDto, metadata: BestandMetadataDto) {
    if (!this.downloading) {
      this.downloading = true;

      this.berichtenService
        .getDownloadLink(this.gesprekId, bericht.id, bericht.bestandId)
        .subscribe((url) => {
          this.fileService
            .downloadFileFromUrl(url, decodeURIComponent(metadata.name))
            .then(() => {
              setTimeout(() => (this.downloading = false), 1000);
            })
            .catch(() => {
              // this.downloading = false;
            });
        });
    }
  }

  protected readonly decodeURIComponent = decodeURIComponent;
}
