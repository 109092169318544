import { Routes } from '@angular/router';
import { artikelOverviewRoutes, specificArtikelRoute } from '../../watwat/routes';
import { DoelenExJongerePage } from './doelen-ex-jongere-page/doelen-ex-jongere.page';
import { DoelDetailsExJongerePage } from './doel-details-ex-jongere-page/doel-details-ex-jongere.page';

export const doelExJongereRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: DoelenExJongerePage,
    data: { tabRoot: true },
  },
  {
    path: ':id',
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: DoelDetailsExJongerePage,
      },
      artikelOverviewRoutes,
      specificArtikelRoute,
    ],
  },
];
