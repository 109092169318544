export const profielAnalyticsEvents = [
  'profielAangemaakt',
  'profielGewijzigd',
  'profielFotoUpgeload',
  'profielFotoVerwijderd',
  'profielGemarkeerdVoorVerwijdering',
  'profielOntmarkeerdVoorVerwijdering',
  'profielGewisseld',
  'afwezigheidGewijzigd',
  'profielType',
] as const;
