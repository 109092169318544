<ion-radio class="w-full rounded" [value]="thema" mode="ios">
  <div class="flex gap-x-2 items-center p-1">
    <parkour-picture
      class="block aspect-square w-[3rem] h-[3rem] rounded-sm overflow-hidden"
      [sources]="{ default: themaMapping[thema].smallImage }"
      [alt]="''"
    />

    <span class="flex-1 typo-body whitespace-normal">{{ themaMapping[thema].label }}</span>
  </div>
</ion-radio>
