import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import {
  AfspraakDto,
  AfspraakInsertDto,
  AfspraakUpdateDto,
  AfspraakViewDetailDto,
} from 'parkour-web-app-dto';
import { asType, stripNullProperties } from '../../utils';
import { AnalyticsService } from '../../analytics/analytics.service';
import { AnalyticsEvent, trackAnalyticsEvent } from '../../analytics/analytics-event.model';

@Injectable({
  providedIn: 'root',
})
export class KalenderService {
  constructor(
    private readonly http: HttpClient,
    private readonly analyticsService: AnalyticsService,
  ) {}

  getAfspraken(): Observable<AfspraakViewDetailDto[]> {
    return this.http.get<AfspraakViewDetailDto[]>(`${environment.API_BASE_URL}/api/afspraken`);
  }

  addAfspraak(afspraak: AfspraakInsertDto): Observable<void> {
    return this.http
      .post<void>(
        `${environment.API_BASE_URL}/api/afspraken`,
        asType<AfspraakInsertDto>(stripNullProperties(afspraak)),
      )
      .pipe(
        trackAnalyticsEvent(
          this.analyticsService,
          new AnalyticsEvent(
            'afspraken',
            afspraak.type === 'VIDEOGESPREK' ? 'beeldbelAfspraakAangemaakt' : 'afspraakAangemaakt',
          ),
        ),
      );
  }

  updateAfspraak(afspraak: AfspraakUpdateDto): Observable<void> {
    return this.http
      .put<void>(
        `${environment.API_BASE_URL}/api/afspraak/${afspraak.id}`,
        asType<AfspraakUpdateDto>(stripNullProperties(afspraak)),
      )
      .pipe(
        trackAnalyticsEvent(
          this.analyticsService,
          new AnalyticsEvent(
            'afspraken',
            afspraak.type === 'VIDEOGESPREK' ? 'beeldbelAfspraakGewijzigd' : 'afspraakGewijzigd',
          ),
        ),
      );
  }

  getAfspraak(afspraakId: string): Observable<AfspraakViewDetailDto> {
    return this.http.get<AfspraakViewDetailDto>(
      `${environment.API_BASE_URL}/api/afspraken/${afspraakId}`,
    );
  }

  deleteAfspraak(afspraak: { id: string; type: AfspraakDto['type'] }) {
    return this.http
      .delete<void>(`${environment.API_BASE_URL}/api/afspraken/${afspraak.id}`)
      .pipe(
        trackAnalyticsEvent(
          this.analyticsService,
          new AnalyticsEvent(
            'afspraken',
            afspraak.type === 'VIDEOGESPREK' ? 'beeldbelAfspraakVerwijderd' : 'afspraakVerwijderd',
          ),
        ),
      );
  }
}
