/* eslint-disable @typescript-eslint/no-explicit-any */
import { Capacitor } from '@capacitor/core';
import {
  catchError,
  concat,
  concatMap,
  EMPTY,
  filter,
  map,
  Observable,
  of,
  OperatorFunction,
  repeat,
  retry,
  shareReplay,
  switchMap,
  timer,
} from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { ContextId, ProfielId } from 'parkour-web-app-dto';
import { OfflineError } from './core/human-readable-error';

export function asType<Type>(value: Type): Type {
  return value;
}

export function deepEqual(object1: any, object2: any) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if ((areObjects && !deepEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
      return false;
    }
  }

  return true;
}

export const isObject = (object: any) => {
  return object != null && typeof object === 'object';
};

export function isNonNullable<T>() {
  return (source$: Observable<null | undefined | T>) =>
    source$.pipe(filter((v): v is NonNullable<T> => v !== null && v !== undefined));
}

export const getIsTimeToday = (timestamp: string) => {
  const tijdstip: Date = new Date(timestamp);

  return tijdstip.getDay() === new Date().getDay();
};

export const isToday = (date: Date): boolean => {
  const today = new Date();

  return (
    date.getFullYear() === today.getFullYear() &&
    date.getMonth() === today.getMonth() &&
    date.getDate() === today.getDate()
  );
};

export const getOrThrow = <T>(value: T | undefined, message: string): T => {
  if (value === undefined) {
    throw new Error(message);
  }
  return value;
};

export function stripNullProperties(source: any): any {
  if (Array.isArray(source)) {
    return source.map((item) => stripNullProperties(item));
  }

  if (isObject(source)) {
    return Object.fromEntries(
      Object.entries(source)
        .filter(([, value]) => value != null)
        .map(([key, value]) => [key, stripNullProperties(value)]),
    );
  }

  return source;
}

export function isNativeApp() {
  return Capacitor.isNativePlatform();
}

export const checkIfActiveDate = (from: string | undefined, to: string | undefined) => {
  const now = new Date().setHours(0, 0, 0, 0);

  if (!from) {
    return false;
  }

  const fromDate = new Date(from).setHours(0, 0, 0, 0);

  if (now >= fromDate && !to) {
    return true;
  }

  if (!to) {
    return false;
  }

  const toDate = new Date(to).setHours(0, 0, 0, 0);

  return now >= fromDate && now <= toDate;
};

export class Success<T> {
  public readonly success = true as const;

  constructor(public readonly value: T) {}

  map<ToType>(operator: (value: T) => ToType): Result<ToType> {
    return new Success(operator(this.value));
  }

  mapToObservable<ToType>(operator: (value: T) => Observable<ToType>): Observable<Result<ToType>> {
    return asResult(operator(this.value));
  }

  unwrapOrThrow(): T {
    return this.value;
  }
}

export type FailureType =
  | 'unknown'
  | 'offline'
  | 'not-found'
  | 'server-error'
  | 'server-unreachable';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export class Failure<T> {
  public readonly success = false as const;

  constructor(
    public readonly errorMessage: string,
    public readonly failureType: FailureType,
  ) {}

  map<ToType>(): Result<ToType> {
    return new Failure<ToType>(this.errorMessage, this.failureType);
  }

  mapToObservable<ToType>(): Observable<Result<ToType>> {
    return of(new Failure<ToType>(this.errorMessage, this.failureType));
  }

  unwrapOrThrow(error: Error): T {
    throw error;
  }
}

export type Result<T> = Success<T> | Failure<T>;

export function asResult<T>(observable: Observable<T>): Observable<Result<T>> {
  return observable.pipe(
    map((value) => new Success<T>(value)),
    catchError((error: Error) => {
      // eslint-disable-next-line no-console
      console.error(error);
      return of(new Failure<T>(error.message, errorToFailureType(error)));
    }),
  );
}

export function errorToFailureType(error: unknown): FailureType {
  if (error instanceof OfflineError) return 'offline';

  if (error instanceof HttpErrorResponse) {
    if (error.status === 404) return 'not-found';
    if (error.status < 400 || error.status == 502 || error.status == 503)
      return 'server-unreachable';

    return 'server-error';
  }

  return 'unknown';
}

export const successOrThrow = <T>(result: Result<T>): T => {
  if (result instanceof Success) {
    return result.value;
  }
  throw new Error(`Result is not a success: ${result.errorMessage}`);
};

type UnwrappedResult<T> = T extends Result<infer Type> ? Type : T;

export function combineResults<T extends Record<string, Result<unknown> | unknown>>(
  results: T,
): Result<{ [K in keyof T]: UnwrappedResult<T[K]> }> {
  const resultingMap: Record<string, unknown> = {};
  for (const key of Object.keys(results)) {
    const result = results[key];
    if (result instanceof Success) {
      resultingMap[key] = result.value;
    } else if (result instanceof Failure) {
      return result;
    } else {
      resultingMap[key] = result;
    }
  }
  return new Success(resultingMap) as Result<{ [K in keyof T]: UnwrappedResult<T[K]> }>;
}

export function getSortedItems<T>(
  source$: Observable<T[]>,
  sortFn: (a: T, b: T) => number,
): Observable<T[]> {
  return source$.pipe(map((result) => [...result.sort(sortFn)]));
}

export const repeatOn: <T>(on: Observable<void>) => OperatorFunction<T, T> = (
  on: Observable<void>,
) => repeat({ delay: () => on });

export function repeatableIgnoreErrorsExceptInitial<T, RefreshCause>(
  source: (refreshCause?: RefreshCause) => Observable<T>,
  onError: T,
  refreshTrigger: Observable<RefreshCause> = EMPTY,
  mode: 'switch' | 'concat' = 'switch',
  initialErrorLog?: string,
  ignoredErrorLog?: string,
): Observable<T> {
  const mapFunction = mode === 'switch' ? switchMap : concatMap;

  return concat(
    source(undefined).pipe(
      catchError(() => {
        if (initialErrorLog) {
          // eslint-disable-next-line no-console
          console.error(initialErrorLog);
        }
        return of(onError);
      }),
    ),
    refreshTrigger.pipe(
      mapFunction((cause) =>
        source(cause).pipe(
          catchError(() => {
            if (ignoredErrorLog) {
              // eslint-disable-next-line no-console
              console.error(ignoredErrorLog);
            }
            return EMPTY;
          }),
        ),
      ),
    ),
  ).pipe(
    shareReplay({
      bufferSize: 1,
      refCount: true,
    }),
  );
}

export function backoffRetry<T>(config: { count: number; delay: number }) {
  return (obs$: Observable<T>) =>
    obs$.pipe(
      retry({
        count: config.count,
        delay: (_, retryIndex) => {
          const d = Math.pow(2, retryIndex - 1) * config.delay;
          return timer(d);
        },
      }),
    );
}

export function parseProfielIdFromUrl(url: string): ProfielId | undefined {
  const segments = url.split('/');

  if (segments.length >= 3) {
    const id = segments[2];

    if (isContextId(id)) {
      if (id === 'anoniem') {
        return undefined;
      } else {
        return id;
      }
    } else {
      return undefined;
    }
  } else {
    return undefined;
  }
}

export function isContextId(value: string): value is ContextId {
  const regex = /^[a-z,0-9,-]{36,36}$/;

  return value === 'anoniem' || regex.test(value);
}
