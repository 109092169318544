import { Component, Input } from '@angular/core';
import { NgClass, NgIf } from '@angular/common';
import { ParkourFotoBadgeImageProps } from './parkour-foto-badge.types';
import { ProfielFotoBadgeSize } from '../profiel-foto-badge/profiel-foto-badge.component';
import { ParkourIconComponent, ParkourImageComponent } from '@parkour/ui';

@Component({
  selector: 'parkour-foto-badge',
  standalone: true,
  imports: [NgIf, NgClass, ParkourIconComponent, ParkourImageComponent],
  templateUrl: './foto-badge.component.html',
})
export class FotoBadgeComponent {
  @Input({ required: true }) public image!: ParkourFotoBadgeImageProps;
  @Input() public badgeCount?: number | undefined;
  @Input({ required: true }) public size!: ProfielFotoBadgeSize;
  @Input() grayout? = false;
}
