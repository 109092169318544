import { Component, Input } from '@angular/core';
import { GedeeldMetItemGroup } from '../../../doel/doel-shared/component/doel-form/doel-form.component';
import { TeamlidProfiel } from '../../../team/model/teamlid.model';
import { ParkourCheckboxComponent } from '@parkour/ui';
import { NgIf, NgTemplateOutlet } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { ParkourProfielBlockComponent } from '../../../shared/components/parkour-profiel-block/parkour-profiel-block.component';

@Component({
  standalone: true,
  selector: 'parkour-gedeeld-met-item',
  templateUrl: './gedeeld-met-item.component.html',
  imports: [
    ParkourCheckboxComponent,
    NgTemplateOutlet,
    NgIf,
    ReactiveFormsModule,
    ParkourProfielBlockComponent,
  ],
})
export class GedeeldMetItemComponent {
  @Input({ required: true }) formGroup!: GedeeldMetItemGroup;
  @Input({ required: true }) persoon!: TeamlidProfiel;
  @Input() editable = true;
}
