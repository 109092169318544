import { Component, Input } from '@angular/core';

@Component({
  standalone: true,
  selector: 'parkour-profile-details-list-item',
  templateUrl: './profile-details-list-item.component.html',
  styleUrls: ['./profile-details-list-item.component.css'],
})
export class ProfileDetailsListItemComponent {
  @Input({ required: true }) label!: string;
  @Input({ required: true }) detail!: string;
}
