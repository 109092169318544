import { Injectable } from '@angular/core';
import { ParkourPopupService } from '@parkour/ui';
import { AndroidSettings, IOSSettings, NativeSettings } from 'capacitor-native-settings';
import { isNativeApp } from '../../utils';
import { Camera } from '@capacitor/camera';
import { GenericResponse, VoiceRecorder } from 'capacitor-voice-recorder';
import { LoggingService } from '../../core/logging.service';

interface CapcatitorPlugin<PermissionName extends string> {
  requestPermissions(): Promise<{ [key in PermissionName]: PermissionState | string }>;
  checkPermissions(): Promise<{ [key in PermissionName]: PermissionState | string }>;
}

type ErrorPopupData = { errorTitle: string; errorDescription: string; neeText?: string };

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  constructor(
    private readonly popupService: ParkourPopupService,
    private readonly loggingService: LoggingService,
  ) {}

  async withPermissions<PermissionName extends string>(
    capacitorPlugin: CapcatitorPlugin<PermissionName>,
    permissionName: PermissionName,
    grantedCallback: () => void,
    errorPopupData: ErrorPopupData,
  ) {
    if (!isNativeApp()) {
      grantedCallback();
      return;
    }
    const checkPermissionsResult = await capacitorPlugin.checkPermissions();
    let status: PermissionState | string = checkPermissionsResult[permissionName];
    if (status !== 'granted') {
      status = (await capacitorPlugin.requestPermissions())[permissionName];
    }

    if (status !== 'denied') {
      grantedCallback();
    } else {
      await this.showPermissionsDeniedPopup(errorPopupData);
    }
  }

  public async showPermissionsDeniedPopup(errorPopupData: ErrorPopupData) {
    const result = await this.popupService.showPopup({
      title: errorPopupData.errorTitle,
      description: errorPopupData.errorDescription,
      icon: 'alert-triangle',
      jaText: isNativeApp() ? 'Instellingen openen' : 'Oké',
      neeText: errorPopupData.neeText ?? 'Annuleren',
    });
    if (result === 'yes' && isNativeApp()) {
      await NativeSettings.open({
        optionAndroid: AndroidSettings.ApplicationDetails,
        optionIOS: IOSSettings.App,
      });
    }
  }

  public async checkJitsiPermisssions() {
    try {
      if (isNativeApp()) {
        await this.checkCameraPermissions();
        await this.checkMicrophonePermissions();
      }
    } catch (e) {
      this.loggingService.error('Failed to check permissons for Jitsi');
    }
  }

  private async checkMicrophonePermissions() {
    const permissionStatus = await VoiceRecorder.hasAudioRecordingPermission();
    if (!permissionStatus.value) {
      const result: GenericResponse = await VoiceRecorder.requestAudioRecordingPermission();
      if (!result.value) {
        await this.showPermissionsDeniedPopup({
          errorTitle: 'PARKOUR wil je microfoon gebruiken',
          errorDescription: 'Geef toestemming voor toegang tot je microfoon in je instellingen.',
          neeText: 'Verder',
        });
      }
    }
  }

  private async checkCameraPermissions() {
    const permissionStatus = await Camera.checkPermissions();
    if (permissionStatus.camera !== 'granted') {
      const requestedStatus = await Camera.requestPermissions({ permissions: ['camera'] });
      if (requestedStatus.camera !== 'granted') {
        await this.showPermissionsDeniedPopup({
          errorTitle: 'PARKOUR wil je camera gebruiken',
          errorDescription: 'Geef toestemming voor toegang tot je camera in je instellingen.',
          neeText: 'Verder',
        });
      }
    }
  }
}
