import { Injectable } from "@angular/core";
import { ToastController } from "@ionic/angular";

export type ToastConfig = {
  header: string;
  content?: string;
  error?: boolean;
};

@Injectable({
  providedIn: "root",
})
export class ParkourToastService {
  constructor(private toastController: ToastController) {}

  async showToast(config: ToastConfig) {
    const toast = await this.toastController.create({
      cssClass: config.error ? "ion-toast--error" : "ion-toast--default",
      header: config.header,
      message: config.content ? config.content : "",
      icon: config.error
        ? "/assets/toaster/alert-triangle-off-15-3-round.svg"
        : "/assets/toaster/circle-check-off-15-round.svg",
      duration: 5000,
      position: "bottom",
    });

    await toast.present();
  }
}
