import { Component } from '@angular/core';
import { ParkourDividerComponent, ParkourPictureComponent } from '@parkour/ui';
import { TranslateModule } from '@ngx-translate/core';
import { AanmeldButtonComponent } from '../../../shared/components/aanmeld-button/aanmeld-button.component';
import { PageLayoutComponent } from '../../../shared/components/page-layout/page-layout.component';
import { ParkourProfielNavItemComponent } from '../../component/profiel-nav-item/profiel-nav-item.component';
import { ParkourPage } from 'src/app/shared/parkour-page';
import { ParkourRefreshDirective } from '../../../shared/directives/parkour-refresh-directive';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';

@Component({
  standalone: true,
  templateUrl: './profiel-empty-state-no-user.page.html',
  imports: [
    ParkourDividerComponent,
    ParkourPictureComponent,
    TranslateModule,
    AanmeldButtonComponent,
    PageLayoutComponent,
    ParkourProfielNavItemComponent,
    ParkourRefreshDirective,
    IonBackButton,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonTitle,
    IonContent,
    IonRefresher,
    IonRefresherContent,
  ],
})
export class ProfielEmptyStateNoUserPage extends ParkourPage {}
