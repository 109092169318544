import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  OnDestroy,
} from "@angular/core";

@Directive({
  selector: "[parkourFocusVisibleElement]",
})
export class ParkourFocusVisibleDirective implements AfterViewInit, OnDestroy {
  isFocusable = "is-focused";
  keyboardMode = false;

  constructor(private element: ElementRef<HTMLElement>) {}

  ngAfterViewInit() {
    const el = this.element.nativeElement;

    document.addEventListener("keydown", this.onKeyDown.bind(this));
    el.addEventListener("touchstart", () => this.onPointerDown());
    el.addEventListener("mousedown", () => this.onPointerDown());
  }

  onKeyDown(e: Event) {
    if ((e as KeyboardEvent).key === "Tab") {
      this.keyboardMode = true;
    }
  }

  @HostListener("focusin")
  onFocusIn() {
    if (this.keyboardMode) {
      this.element.nativeElement.classList.add(this.isFocusable);
    }
  }

  @HostListener("focusout")
  onFocusOut() {
    if (this.keyboardMode) {
      this.element.nativeElement.classList.remove(this.isFocusable);
    }
  }

  onPointerDown() {
    this.keyboardMode = false;
  }

  ngOnDestroy() {
    const el = this.element.nativeElement;

    document.removeEventListener("keydown", this.onKeyDown.bind(this));
    el.removeEventListener("touchstart", this.onPointerDown);
    el.removeEventListener("mousedown", this.onPointerDown);
  }
}
