import { Component, Input } from '@angular/core';
import { TeamlidAddedGroup } from '../../../bericht/component/groepsgesprek-add-form/groepsgesprek-form.component';
import { TeamlidProfiel } from '../../../team/model/teamlid.model';
import { ParkourCheckboxComponent } from '@parkour/ui';
import { ReactiveFormsModule } from '@angular/forms';
import { NgIf, NgTemplateOutlet } from '@angular/common';
import { ParkourProfielBlockComponent } from '../parkour-profiel-block/parkour-profiel-block.component';

@Component({
  standalone: true,
  selector: 'parkour-teamlid-checkbox',
  templateUrl: './parkour-teamlid-checkbox.component.html',
  imports: [
    ParkourCheckboxComponent,
    ReactiveFormsModule,
    NgIf,
    NgTemplateOutlet,
    ParkourProfielBlockComponent,
  ],
})
export class ParkourTeamlidCheckboxComponent {
  @Input({ required: true }) public teamlid!: TeamlidProfiel;
  @Input() formGroup: TeamlidAddedGroup | undefined;
  @Input() geblokkeerd = false;
}
