import { Directive, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { User } from '../../authentication/user';
import AuthService from '../../authentication/service/auth.service';

@Directive({
  selector: '[parkourIsUserWithProfiel]',
  standalone: true,
})
export class IsUserWithProfielDirective implements OnInit, OnDestroy {
  hasView = false;
  private subscription?: Subscription;

  constructor(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private readonly authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.subscription = this.authService.user$.subscribe((user) => this.onContextTypeChanged(user));
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  private onContextTypeChanged(user: User) {
    if (user.type === 'aangemeld' && !this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (user.type !== 'aangemeld' && this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }
}
