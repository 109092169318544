import { Pipe, PipeTransform } from '@angular/core';
import { mergeMap, Observable } from 'rxjs';
import { ImagesService } from '../../../shared/services/images.service';
import { GebeurtenisFotoService } from '../service/gebeurtenis-foto.service';

import { FotoId, GebeurtenisId, GebeurtenisImageVariant } from 'parkour-web-app-dto';

type GebeurtenisWithFoto = {
  id: GebeurtenisId;
  fotoId: FotoId;
};

@Pipe({
  name: 'gebeurtenisImage',
  standalone: true,
})
export class GebeurtenisImagePipe implements PipeTransform {
  constructor(
    private readonly gebeurtenisFotoService: GebeurtenisFotoService,
    private readonly imageService: ImagesService,
  ) {}

  transform(
    gebeurtenisWithFoto: GebeurtenisWithFoto,
    gebeurtenisImageVariant: GebeurtenisImageVariant = 'ORIGINAL',
  ): Observable<string> {
    return this.gebeurtenisFotoService
      .getGebeurtenisFotoUrl(
        gebeurtenisWithFoto.id,
        gebeurtenisWithFoto.fotoId,
        gebeurtenisImageVariant,
      )
      .pipe(mergeMap((url) => this.imageService.getImage(url)));
  }
}
