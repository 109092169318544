import { inject, Injectable } from '@angular/core';
import { MatomoRouterInterceptor, MatomoTracker } from 'ngx-matomo-client';

@Injectable()
export class PageTitleInterceptor implements MatomoRouterInterceptor {
  private readonly tracker = inject(MatomoTracker);

  beforePageTrack(): void {
    this.tracker.setDocumentTitle('PARKOUR');
  }
}
