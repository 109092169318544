import { Directive, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[parkourAdaptToSidebarVisible]',
  standalone: true,
})
export class AdaptToSidebarVisibleDirective implements OnInit, OnDestroy {
  private subscription?: Subscription;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private readonly activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.subscription = this.activatedRoute.data.subscribe((data) => {
      if (data['sidebarVisible'] === false) {
        this.renderer.addClass(this.el.nativeElement, 'full-header');
      } else {
        this.renderer.removeClass(this.el.nativeElement, '.full-header');
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
