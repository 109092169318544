import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ParkourButtonComponent, TParkourIconName } from '@parkour/ui';

export type Filter = {
  icon: TParkourIconName;
  label: string;
  value: string;
};

@Component({
  standalone: true,
  selector: 'parkour-filters',
  templateUrl: './filters.component.html',
  imports: [TranslateModule, ParkourButtonComponent],
})
export class FiltersComponent implements OnChanges {
  @Input({ required: true }) filters!: Filter[];
  @Input({ required: true }) activeFilter!: string | null;

  @Output() changed = new EventEmitter<string | null>();

  activeIndex: null | number = null;

  ngOnChanges(changes: SimpleChanges) {
    if ('activeFilter' in changes) {
      this.activeIndex = this.filters.findIndex((filter) => filter.value === this.activeFilter);
    }
  }

  onClick(index: number) {
    if (this.activeIndex === index) {
      this.activeIndex = null;

      this.changed.emit(null);
    } else {
      this.activeIndex = index;

      this.changed.emit(this.filters[index].value);
    }
  }
}
