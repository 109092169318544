<p class="typo-body variant-body-fat">{{ 'delen.actieve-teamleden' | translate }}</p>

<ul class="mt-6 space-y-6">
  <li>
    <parkour-profiel-block [profielData]="gesprek.jongere" />
  </li>

  @for (deelnemer of gesprek.getActieveDeelnemers(); track deelnemer.id) {
    <li>
      <parkour-profiel-block [profielData]="deelnemer" />
    </li>
  }
</ul>
