import { Component } from '@angular/core';
import { CoreModule } from '../../../core/core.module';
import { AdaptToSidebarVisibleDirective } from '../../../shared/directives/adapt-to-sidebar-visible.directive';
import { ParkourBackButtonDirective } from '../../../shared/directives/parkour-back-button.directive';
import { ParkourDividerComponent, ParkourPageGradientComponent } from '@parkour/ui';
import { PageLayoutComponent } from '../../../shared/components/page-layout/page-layout.component';
import { ParkourPage } from '../../../shared/parkour-page';
import { ParkourRefreshDirective } from '../../../shared/directives/parkour-refresh-directive';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';

@Component({
  templateUrl: './privacy-verklaring.page.html',
  standalone: true,
  imports: [
    CoreModule,
    AdaptToSidebarVisibleDirective,
    ParkourBackButtonDirective,
    ParkourPageGradientComponent,
    ParkourDividerComponent,
    PageLayoutComponent,
    ParkourRefreshDirective,
    IonBackButton,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonTitle,
    IonContent,
    IonRefresher,
    IonRefresherContent,
  ],
})
export class PrivacyVerklaringPage extends ParkourPage {}
