import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  async setValue(key: string, value: string): Promise<void> {
    return await Preferences.set({
      key: key,
      value: value,
    });
  }

  async getValue(key: string): Promise<string | null> {
    const ret = await Preferences.get({ key: key });
    return ret.value;
  }

  async removeValue(key: string): Promise<void> {
    return await Preferences.remove({ key: key });
  }
}
