<div class="flex gap-x-3">
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
    <path
      d="M28.3397 31.6667H30.0063C32.7678 31.6667 35.0063 29.4282 35.0063 26.6667V22.9488C35.0063 20.3998 32.94 18.3334 30.391 18.3334C30.1785 18.3334 30.0063 18.1612 30.0063 17.9488L30.0063 8.33342C30.0063 8.33342 23.3397 10.8334 23.3397 18.3334V26.6684C23.3397 29.4298 25.5783 31.6667 28.3397 31.6667Z"
      stroke="#54BBBC"
      stroke-width="2"
      stroke-linejoin="round"
    />
    <path
      d="M10.0063 31.6667H11.673C14.4344 31.6667 16.673 29.4282 16.673 26.6667V22.9488C16.673 20.3998 14.6066 18.3334 12.0576 18.3334C11.8452 18.3334 11.673 18.1612 11.673 17.9488L11.673 8.33342C11.673 8.33342 5.00635 10.8334 5.00635 18.3334V26.6684C5.00635 29.4298 7.24492 31.6667 10.0063 31.6667Z"
      stroke="#54BBBC"
      stroke-width="2"
      stroke-linejoin="round"
    />
  </svg>

  <blockquote class="flex-1 typo-h5">
    {{ content.content.text }}

    <footer class="mt-1 typo-body variant-body-small variant-body-fat text-n-600">
      {{ content.content.source }}
    </footer>
  </blockquote>
</div>
