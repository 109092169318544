import { Component, Input, OnInit } from "@angular/core";

//eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let vl: any;

@Component({
  selector: "parkour-vo-header",
  templateUrl: "./parkour-vo-header.component.html",
  standalone: true,
})
export class ParkourVoHeaderComponent implements OnInit {
  @Input() public globalHeaderEmbedScriptSrc: string =
    "https://tni.widgets.burgerprofiel.dev-vlaanderen.be/api/v1/widget/14545545-2a96-42b5-8e4a-20a4eb1eeaa7";
  @Input() public userAuthenticated = false;
  @Input() public mock = false;
  @Input() public classes?: string;
  private globalHeaderEmbedScript: HTMLScriptElement;

  constructor() {
    this.globalHeaderEmbedScript = document.createElement("script");
    this.globalHeaderEmbedScript.src = this.globalHeaderEmbedScriptSrc;
  }

  ngOnInit(): void {
    const root = document.querySelector<HTMLElement>(":root");
    //eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    root!.style.setProperty("--app-plus-global-header-height", "43px");

    if (!this.mock) {
      this.bootstrapGlobalHeader();
      this.configureActivityTracking();
      this.configureSessionExtensionBasedOnActivity();
      this.configureLogout();
    }
  }

  private bootstrapGlobalHeader(): void {
    vl.widget.client
      .bootstrap(this.globalHeaderEmbedScriptSrc)
      //eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((widget: any) => {
        return widget
          .setMountElement(document.getElementById("global-header"))
          .mount();
      })
      .catch();
  }

  private configureActivityTracking(): void {
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    vl.widget.client.capture((widget: any) => {
      if (widget.getPluginTypeId() === "global_header") {
        //eslint-disable-next-line @typescript-eslint/no-explicit-any
        widget.getExtension("citizen_profile.session").then((session: any) => {
          function activityEventHandler() {
            session.extend();
          }

          const eventNames = [
            "mousedown",
            "mousemove",
            "mousewheel",
            "DOMMouseScroll",
            "scroll",
            "wheel",
            "keydown",
            "keypress",
            "touchmove",
            "touchstart",
          ];
          for (let i = 0; i < eventNames.length; i++) {
            window.addEventListener(eventNames[i], activityEventHandler);
          }

          const websiteHasAuthenticatedSession = this.userAuthenticated;
          session.configure({
            active: websiteHasAuthenticatedSession,
            endpoints: {
              loginUrl: "/login",
              loginRedirectUrl: "/login-succesvol",
              logoutUrl: "/logout",
            },
          });
        });
      }
    });
  }

  private configureSessionExtensionBasedOnActivity() {
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    vl.widget.client.capture(function (widget: any) {
      if (widget.getPluginTypeId() === "global_header") {
        widget.on("citizen_profile.session.extend", function () {
          // console.debug("Received session extend request");
        });
      }
    });
  }

  private configureLogout(): void {
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    vl.widget.client.capture(function (widget: any) {
      if (widget.getPluginTypeId() === "global_header") {
        widget.on(
          "citizen_profile.session.logout.request",
          //eslint-disable-next-line @typescript-eslint/no-explicit-any
          function (logoutRequest: any) {
            logoutRequest.acknowledge();
            switch (logoutRequest.getRequest().getReason()) {
              case "expired":
              case "inactivity":
                fetch("/api/profiel/me")
                  .then(function (response) {
                    if (response.status === 200) {
                      logoutRequest.reject();
                    } else {
                      logoutRequest.accept();
                    }
                  })
                  .catch(function () {
                    logoutRequest.accept();
                  });
                break;

              case "user":
                logoutRequest.accept();
                break;

              default:
                logoutRequest.reject();
                break;
            }
          },
        );
      }
    });
  }
}
