import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Profiel } from '../../model/profiel';
import { ProfielCreateOption } from 'parkour-web-app-dto';
import { ParkourModalBehaviorDirective } from '../../../shared/directives/parkour-modal-behavior.directive';
import { ParkourActionComponent, ParkourBadgeComponent, ParkourIconComponent } from '@parkour/ui';
import { TranslateModule } from '@ngx-translate/core';
import { ProfielTypeLabelComponent } from '../profiel-type-label/profiel-type-label.component';
import { ProfielSelectOptionComponent } from '../profiel-select-option/profiel-select-option.component';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonModal,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';

@Component({
  standalone: true,
  selector: 'parkour-profiel-select',
  templateUrl: './profiel-select.component.html',
  styleUrls: ['./profiel-select.component.css'],
  imports: [
    ParkourActionComponent,
    ParkourIconComponent,
    ParkourModalBehaviorDirective,
    ParkourBadgeComponent,
    TranslateModule,
    ProfielTypeLabelComponent,
    ProfielSelectOptionComponent,
    IonBackButton,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonTitle,
    IonContent,
    IonModal,
  ],
})
export class ProfielSelectComponent {
  @ViewChild('selectModal') selectModal!: ParkourModalBehaviorDirective;
  @Input({ required: true }) profielen!: Profiel[];
  @Input({ required: true }) profielCreateOptions!: ProfielCreateOption[];
  @Input({ required: true }) currentProfiel!: Profiel;
  @Output() profielSelect = new EventEmitter<Profiel>();
  @Output() createPersoonlijkProfiel = new EventEmitter<void>();
  @Output() createProfessioneelProfiel = new EventEmitter<void>();

  onProfielSelect(profiel: Profiel) {
    this.selectModal.close();
    this.profielSelect.emit(profiel);
  }

  onProfielSwitchClick() {
    this.selectModal.open();
  }

  onAddPersoonlijkProfielClick() {
    this.selectModal.close();
    setTimeout(() => {
      this.createPersoonlijkProfiel.emit();
    });
  }

  onAddProfessioneelProfielClick() {
    this.selectModal.close();
    setTimeout(() => {
      this.createProfessioneelProfiel.emit();
    });
  }
}
