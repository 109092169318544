<div class="group relative inline-flex w-full">
  @if ((isDesktop() && disabled && !isUser) || (isDesktop() && !disabled)) {
    <parkour-button
      (clicked)="onMoreOptionsClick()"
      [ariaLabel]="'buttons.open-menu' | translate"
      [ngClass]="{ 'from-n-300 to-n-300/0': isUser, 'from-n-200 to-n-200/0': !isUser }"
      class="rounded-tr rounded-br pl-12 pr-4 bg-gradient-to-l group-hover:opacity-100 has-[:focus-visible]:opacity-100 absolute top-0 right-0 z-10 transition-opacity opacity-0 h-full"
      classes="h-full"
      format="iconButton"
      icon="menu"
      size="none"
      variant="ghost"
    />
  }

  <div class="flex flex-col py-2 px-3 flex-1">
    <ng-content></ng-content>

    <span class="sr-only">
      @if (getIsTimeToday(bericht.timestamp)) {
        {{ 'berichten.gesprek-page.time' | translate }}
      } @else {
        {{ 'berichten.gesprek-page.date' | translate }}
      }
    </span>

    <span class="self-end typo-body variant-body-small text-n-700">{{
      bericht.timestamp | berichtenDate
    }}</span>
  </div>
</div>
