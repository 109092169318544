<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button parkourBackButton />
    </ion-buttons>

    <ion-title aria-level="1" role="heading"
      >{{ 'doelen.header.suggestie' | translate }}
    </ion-title>

    <ion-buttons slot="end">
      <parkour-header-action
        [label]="'doelen.doel-suggestie-page.verzenden' | translate"
        form="suggest-form"
        icon="paper-plane"
        testId="submit"
        type="submit"
      />
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <parkour-page-layout>
    <parkour-page-gradient />

    <div class="relative z-10 py-8 container container-space">
      <div class="container-1-col">
        <parkour-form
          [errorLabelMapping]="{
            doelDataSection: {
              naam: 'doelen.doel-toevoegen-page.form.naam' | translate,
              thema: 'doelen.doel-toevoegen-page.form.thema' | translate,
              omschrijving: 'doelen.doel-suggestie-page.bericht' | translate,
              streefDatum: 'doelen.doel-toevoegen-page.form.streefdatum' | translate,
            },
            actieSection: {
              acties: false,
            },
          }"
          [formGroup]="doelForm"
          [submitObservableGenerator]="submitDoel"
          formId="suggest-form"
        >
          <div class="flex flex-col gap-y-8">
            <parkour-doel-data-section [doelFormGroup]="doelForm.controls.doelDataSection" />

            <div class="flex flex-col gap-y-4">
              <h2 class="typo-h3">{{ 'doelen.doel-toevoegen-page.acties.title' | translate }}</h2>

              <p>{{ 'doelen.doel-toevoegen-page.acties.beschrijving' | translate }}</p>

              <parkour-acties-select-section [actieSectionGroup]="doelForm.controls.actieSection" />
            </div>

            <parkour-button class="block w-full" iconAfter="paper-plane" type="submit">
              {{ 'doelen.doel-suggestie-page.verzenden' | translate }}
            </parkour-button>
          </div>
        </parkour-form>
      </div>
    </div>
  </parkour-page-layout>
</ion-content>
