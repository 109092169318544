@if (isReplyBerichtMode && replyBericht) {
  <parkour-bericht-reply
    [replyBericht]="replyBericht"
    (closeReplyBericht)="onCloseReplyBericht()"
  />
}

<div class="flex items-end gap-x-2 relative text-n-1000 py-5">
  <form class="sr-only">
    <input
      #attachment
      (input)="onBerichtInputChangeAssets($event)"
      aria-hidden="true"
      type="file"
    />
  </form>

  @if (inputMode === 'SPRAAK') {
    <parkour-spraakbericht-input
      (destroyRecording)="destroyRecording()"
      (recordingComplete)="sendRecording($event)"
      class="w-full"
    />
  }

  @if (inputMode === 'TEKST') {
    <parkour-bericht-input
      #berichtInput
      (clickedAssets)="onBerichtInputClickedAssets()"
      (enter)="onStuurBericht()"
      [(ngModel)]="nieuwBerichtValue"
      class="grow"
      data-testid="send-message-input"
    />
    @if (showMicrophone()) {
      <parkour-button
        (clicked)="openRecordingMode()"
        [ariaLabel]="'buttons.add-recording' | translate"
        format="iconButton"
        icon="microphone"
        size="medium"
      />
    } @else {
      <parkour-button
        (clicked)="onStuurBericht()"
        [ariaLabel]="'buttons.send' | translate"
        [testId]="'send-message-button'"
        format="iconButton"
        icon="paper-plane"
        size="medium"
      />
    }
  }
</div>

<ion-modal #uploadModal="parkourModalBehavior" parkourModalBehavior backdrop-dismiss="false">
  <ng-template>
    <div class="py-4 h-full flex flex-col">
      <div class="flex-1 flex flex-col justify-center">
        @if (busyUploadingFile) {
          <parkour-progress [progress]="uploadingPercentage" class="mb-4" />
        }

        <p
          class="w-full rounded bg-n-300 text-n-1000 py-10 px-4 text-center typo-body variant-body-fat"
        >
          {{ file?.name }}
        </p>

        <parkour-button (clicked)="resetUploadFile()" class="w-full block mt-16" variant="tertiary">
          {{ 'berichten.gesprek-page.bestand-sturen-button-cancel' | translate }}
        </parkour-button>

        <parkour-button
          (clicked)="onUploadFile()"
          [disabled]="busyUploadingFile"
          class="w-full block mt-4"
          iconAfter="file"
        >
          {{ 'berichten.gesprek-page.bestand-sturen-button-confirm' | translate }}
        </parkour-button>
      </div>
    </div>
  </ng-template>
</ion-modal>
