<div class="relative">
  @switch (image.type) {
    @case ("icon") {
      <div
        [ngClass]="classes"
        class="relative z-10 aspect-square flex items-center justify-center bg-n-200 rounded overflow-hidden"
      >
        <parkour-icon class="text-n-1000" [name]="image.name" size="7" />
      </div>
    }
    @case ("image") {
      @if (image.src) {
        <div>
          @if (professional) {
            <svg
              [attr.viewBox]="getSvgViewBox()"
              fill="none"
              height="100%"
              width="100%"
              xmlns="http://www.w3.org/2000/svg"
            >
              <mask [attr.id]="getMaskId()">
                <rect
                  [attr.y]="getSvgY()"
                  fill="white"
                  height="164"
                  rx="24"
                  width="164"
                  x="0"
                />

                <circle *ngIf="updated" cx="130" cy="7" fill="black" r="7" />

                <path
                  *ngIf="professional"
                  [attr.transform]="getSvgBadgeMaskTranslate()"
                  d="M67 166.548C67 159.066 73.066 153 80.548 153h3.404c7.482 0 13.548 6.066 13.548 13.548a3.952 3.952 0 0 0 3.952 3.952h2.048H61h2.048A3.952 3.952 0 0 0 67 166.548Z"
                  fill="black"
                />
              </mask>

              <image
                [attr.href]="image.src"
                [attr.mask]="getMaskUrl()"
                [attr.y]="getSvgY()"
                height="164"
                preserveAspectRatio="xMidYMid slice"
                width="164"
              />

              <path
                [attr.transform]="getSvgBadgeTranslate()"
                class="fill-n-600 stroke-n-600"
                d="M80.537 159.219a2.088 2.088 0 0 1 3.154 0l.804.925c.247.284.622.42.993.362l1.21-.193a2.09 2.09 0 0 1 2.417 2.028l.02 1.225c.007.376.207.722.529.915l1.05.631a2.09 2.09 0 0 1 .549 3.106l-.772.953a1.088 1.088 0 0 0-.184 1.04l.4 1.159a2.089 2.089 0 0 1-1.577 2.731l-1.203.234a1.09 1.09 0 0 0-.81.679l-.439 1.145a2.089 2.089 0 0 1-2.963 1.078l-1.072-.594a1.085 1.085 0 0 0-1.057 0l-1.072.594a2.089 2.089 0 0 1-2.963-1.078l-.44-1.145a1.09 1.09 0 0 0-.809-.679l-1.203-.234a2.089 2.089 0 0 1-1.577-2.731l.4-1.159a1.088 1.088 0 0 0-.184-1.04l-.772-.953a2.09 2.09 0 0 1 .548-3.106l1.05-.631c.323-.193.523-.539.529-.915l.021-1.225a2.09 2.09 0 0 1 2.416-2.028l1.21.193a1.09 1.09 0 0 0 .993-.362l.804-.925Zm5.138 8.342a1.5 1.5 0 0 0-2.121-2.122l-2.44 2.44-.439-.44a1.501 1.501 0 0 0-2.121 2.122l1.146 1.146a2 2 0 0 0 2.829 0l3.146-3.146Z"
              />

              <circle
                *ngIf="updated"
                class="fill-positive"
                cx="130"
                cy="7"
                r="5"
              />
            </svg>
          } @else {
            <parkour-image
              [alt]="''"
              [src]="image.src"
              class="relative z-10 block aspect-square rounded-sm overflow-hidden"
            />
          }
        </div>
      }
    }
  }
  @if (badgeCount && badgeCount > 2) {
    <span
      class="absolute z-20 typo-body bg-n-400 rounded-sm py-1 px-2 flex gap-x-1 items-center -right-2 -bottom-2"
    >
      <parkour-icon name="people" />

      {{ badgeCount }}
    </span>

    <div
      class="absolute top-0 left-0 w-full h-full bg-n-400 rounded translate-x-1 translate-y-1"
    ></div>
  }
</div>
