import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { CoreModule } from './core/core.module';
import { NotFoundPage } from './shared/page/not-found-page/not-found.page';
import {
  addContextToUrlRedirect,
  fetchContextAndEnsureCorrectness,
  preventBackNavigation,
  replaceMeWithContextIdOrLogin,
  showLoginErrorsGuards,
} from './core/security/guard/auth.guard';
import { isNativeAppGuard } from './core/security/guard/native.guard';
import { EmptyComponent } from './tabs/component/empty/empty.component';
import { UpgradePage } from './versioning/page/upgrade-page/upgrade.page';
import {
  ParkourDividerComponent,
  ParkourImageComponent,
  ParkourLoadingSpinnerComponent,
} from '@parkour/ui';
import { tabsRoutes } from './tabs/routes';
import { startRoutes } from './start/routes';
import { uitnodigingRoutes } from './uitnodiging/routes';
import { LogoutPage } from './authentication/logout-page/logout.page';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: EmptyComponent,
    canActivate: [addContextToUrlRedirect],
  },
  {
    path: 'login-failed',
    canActivate: [showLoginErrorsGuards],
    component: EmptyComponent,
  },
  {
    path: 'app/logout',
    title: 'Logout',
    component: LogoutPage,
  },
  {
    path: 'app/upgrade',
    pathMatch: 'full',
    title: 'Upgrade',
    component: UpgradePage,
    canActivate: [isNativeAppGuard],
    canDeactivate: [() => false],
    data: { sidebarVisible: false },
  },
  {
    path: 'app/uitgenodigd',
    children: uitnodigingRoutes,
    data: { sidebarVisible: false },
  },
  {
    path: 'app/me',
    children: [
      {
        path: '**',
        component: EmptyComponent,
        canActivate: [replaceMeWithContextIdOrLogin],
      },
    ],
  },
  {
    path: 'app/block-back-navigation',
    component: EmptyComponent,
    canActivate: [preventBackNavigation],
  },
  {
    path: 'app/start',
    children: startRoutes,
    data: { sidebarVisible: false },
  },
  {
    path: 'app/:contextId',
    children: tabsRoutes,
    canMatch: [fetchContextAndEnsureCorrectness],
  },
  {
    path: '**',
    pathMatch: 'full',
    component: NotFoundPage,
    data: { sidebarVisible: false },
  },
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
    CoreModule,
    ParkourDividerComponent,
    ParkourImageComponent,
    ParkourLoadingSpinnerComponent,
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
