import { Component } from '@angular/core';
import { ProfielService } from '../../service/profiel.service';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  ViewWillEnter,
} from '@ionic/angular/standalone';
import { ReplaySubject, switchMap } from 'rxjs';
import { MyProfielUpsertDto } from 'parkour-web-app-dto';
import { ActivatedRoute, Router } from '@angular/router';
import { ParkourBackButtonDirective } from '../../../shared/directives/parkour-back-button.directive';
import { ParkourHeaderActionComponent } from '../../../shared/components/parkour-header-action/parkour-header-action.component';
import { AsyncPipe, NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { PageLayoutComponent } from '../../../shared/components/page-layout/page-layout.component';
import { ProfielDetailsEditComponent } from '../../component/profiel-details-edit/profiel-details-edit.component';
import { MijnProfiel } from '../../model/profiel';

@Component({
  standalone: true,
  templateUrl: './mijn-profiel-edit.page.html',
  imports: [
    ParkourBackButtonDirective,
    ParkourHeaderActionComponent,
    NgIf,
    TranslateModule,
    AsyncPipe,
    PageLayoutComponent,
    ProfielDetailsEditComponent,
    IonBackButton,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonTitle,
    IonContent,
  ],
})
export class MijnProfielEditPage implements ViewWillEnter {
  readonly profiel$ = new ReplaySubject<MijnProfiel>(1);

  constructor(
    private readonly profielService: ProfielService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
  ) {}

  fetchProfiel() {
    this.profielService
      .getCurrentUserProfiel$()
      .subscribe((profiel) => this.profiel$.next(profiel));
  }

  ionViewWillEnter(): void {
    this.fetchProfiel();
  }

  onVerwijderingStopzettenClicked() {
    this.profielService.onmarkeerMijnProfielVoorVerwijdering().subscribe(() => this.fetchProfiel());
  }

  async onSubmit(data: {
    upsert: MyProfielUpsertDto;
    profielFotoFile: File | undefined;
    profielFotoChanged: boolean;
  }) {
    this.profielService
      .getCurrentUserProfiel$()
      .pipe(switchMap((profiel) => this.profielService.updateMyProfiel(data.upsert, profiel)))
      .subscribe({
        complete: () => {
          if (data.profielFotoChanged) {
            if (data.profielFotoFile) {
              this.profielService
                .uploadMyProfielFoto(data.profielFotoFile)
                .subscribe(() => this.router.navigate(['..'], { relativeTo: this.activatedRoute }));
            } else {
              this.profielService
                .deleteMyProfielFoto()
                .subscribe(() => this.router.navigate(['..'], { relativeTo: this.activatedRoute }));
            }
          } else {
            this.router.navigate(['..'], { relativeTo: this.activatedRoute });
          }
        },
      });
  }
}
