import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CapacitorHttp } from '@capacitor/core';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';

export const getSecureImage = (url: string, type = 'png'): Observable<string> => {
  if (url.startsWith('data:image')) {
    return of(url);
  }
  return fromPromise(
    CapacitorHttp.get({
      url,
      responseType: 'blob',
    }).then((response) => `data:image/${type};base64,${response.data}`),
  );
};

@Pipe({
  standalone: true,
  name: 'secureImage',
})
export class SecureImagePipe implements PipeTransform {
  transform = getSecureImage;
}
