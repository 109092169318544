import { Component, HostBinding, Input } from '@angular/core';
import { ProfielType } from 'parkour-web-app-dto';
import { NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  selector: 'parkour-profiel-type-label',
  templateUrl: './profiel-type-label.component.html',
  imports: [NgIf, TranslateModule],
})
export class ProfielTypeLabelComponent {
  @HostBinding('attr.data-testid') readonly dataTestId = 'profiel-type';
  @Input({ required: true }) profielType!: ProfielType;
  @Input() classes?: string;
}
