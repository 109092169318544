import { MeldingDto, MeldingType, Page } from 'parkour-web-app-dto';
import { ProfielFotoData } from '../../shared/model/teamlid-info';
import { ContextUrl, GlobalUrl } from '../../shared/services/context.service';

export type MeldingVisualization =
  | {
      type: 'profiel';
      profiel: ProfielFotoData;
      berichtRichText: string;
    }
  | {
      type: 'icon';
      icon: MeldingIcon;
      berichtRichText: string;
    }
  | {
      type: 'default';
      berichtRichText: string;
    }
  | {
      type: 'error';
    }
  | {
      type: 'unsupported';
    };

export type MeldingIcon = 'vlaanderen' | 'kalender' | 'parkour';

export type SupportedMeldingView = {
  type: 'supported';
  id: string;
  visualization: MeldingVisualization;
  timestamp: string;
  gelezen: boolean;
  link: string[] | ContextUrl | GlobalUrl;
  key: MeldingType;
};

export type UnsupportedMeldingView = {
  type: 'unsupported';
  id: string;
  timestamp: string;
};

export type MeldingView = SupportedMeldingView | UnsupportedMeldingView;

// List of all supported meldingen, should always be in sync with the backend, but is used for backwards compatibility
const supportedMeldingen: {
  [key in MeldingType]: boolean;
} = {
  GEBLOKKEERD: true,
  GEDEBLOKKEERD: true,
  VERWIJDERD_UIT_TEAM: true,
  TEAM_VERLATEN: true,
  UITNODIGING_AANVAARD: true,
  UITNODIGING_GEWEIGERD: true,
  UITNODIGING_BEVESTIGD: true,
  BEVESTIGING_GEWEIGERD: true,
  UITGENODIGD: true,
  NIEUW_DRIE_KOLOMMEN_DOCUMENT: true,
  NIEUWE_EYOUTH_GEBEURTENIS: true,
  AFSPRAAK_AANGEMAAKT: true,
  AFSPRAAK_VERWIJDERD: true,
  AFSPRAAK_GESTART: true,
  NIEUW_CHAT_BERICHT: true,
  NIEUW_CHAT_REACTIE: true,
  DOEL_GEDEELD: true,
  DOEL_GESUGGEREERD: true,
  DOEL_EMOJI_REACTIE_ADDED: true,
  DOEL_VOLTOOID: true,
  GEBEURTENIS_GEDEELD: true,
  GEBEURTENIS_GESUGGEREERD: true,
  JEUGDHULPHISTORIEK_GEDEELD: true,
  DOEL_NIEUW_BERICHT: true,
  DOEL_NIEUW_BERICHT_REACTIE: true,
  VIDEOGESPREK_GESTART: true,
  AFSPRAAK_HERINNERING: true,
  VERWIJDERD_UIT_TEAM_DOOR_JONGERE_25: true,
  JONGERE_REMINDER_ONE_DAY: true,
  JONGERE_REMINDER_ONE_WEEK: true,
  PROFIEL_WORDT_VERWIJDERD_HERINNERING: true,
  EERSTE_TEAMLID_TOEVOEGEN: true,
  EERSTE_TEAM_UITNODIGING: true,
  EERSTE_DOEL_DELEN: true,
  EERSTE_DOEL_VOORSTELLEN: true,
  EERSTE_BERICHT_STUREN: true,
};

export function isSupportedMelding(melding: Melding): melding is SupportedMelding {
  return Object.keys(supportedMeldingen).includes(melding.type);
}

export type SupportedMelding = MeldingDto;
export type Melding =
  | SupportedMelding
  | {
      readonly type: string;
      readonly id: string;
      readonly tijdstip: string;
      readonly gelezen: boolean;
    };

export type MeldingenPage = Page<Melding>;
