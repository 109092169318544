import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { asType } from '../../utils';
import { NotitieId } from 'parkour-web-app-dto';
import { environment } from 'src/environments/environment';
import { Notitie, NotitieUpsert } from '../model/notitie';
import { AnalyticsService } from '../../analytics/analytics.service';
import { AnalyticsEvent, trackAnalyticsEvent } from '../../analytics/analytics-event.model';

@Injectable({
  providedIn: 'root',
})
export class BewaardeItemsService {
  constructor(
    private readonly http: HttpClient,
    private readonly analyticsService: AnalyticsService,
  ) {}

  getNotities(): Observable<Notitie[]> {
    return this.http.get<Notitie[]>(
      `${environment.API_BASE_URL}/api/profiel/me/bewaarde-items/notitie`,
    );
  }

  addNotitie(notitie: NotitieUpsert): Observable<Notitie> {
    return this.http
      .post<Notitie>(
        `${environment.API_BASE_URL}/api/profiel/me/bewaarde-items/notitie`,
        asType<NotitieUpsert>(notitie),
      )
      .pipe(
        trackAnalyticsEvent(
          this.analyticsService,
          new AnalyticsEvent('bewaardeItems', 'notitieAangemaakt'),
        ),
      );
  }

  deleteNotitie(id: NotitieId) {
    return this.http
      .delete<void>(`${environment.API_BASE_URL}/api/profiel/me/bewaarde-items/notitie/${id}`)
      .pipe(
        trackAnalyticsEvent(
          this.analyticsService,
          new AnalyticsEvent('bewaardeItems', 'notitieAangemaakt'),
        ),
      );
  }

  getNotitie(notitieId: NotitieId): Observable<Notitie> {
    return this.http.get<Notitie>(
      `${environment.API_BASE_URL}/api/profiel/me/bewaarde-items/notitie/${notitieId}`,
    );
  }

  updateNotitie(notitieId: NotitieId, notitie: NotitieUpsert): Observable<Notitie> {
    return this.http
      .put<Notitie>(
        `${environment.API_BASE_URL}/api/profiel/me/bewaarde-items/notitie/${notitieId}`,
        asType<NotitieUpsert>(notitie),
      )
      .pipe(
        trackAnalyticsEvent(
          this.analyticsService,
          new AnalyticsEvent('bewaardeItems', 'notitieGewijzigd'),
        ),
      );
  }
}
