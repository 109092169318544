import { Component, Input } from "@angular/core";
import { TParkourIconName } from "../parkour-icon/parkour-icon.types";
import { ParkourIconComponent } from "../parkour-icon/parkour-icon.component";

@Component({
  selector: "parkour-info-card",
  templateUrl: "./parkour-info-card.component.html",
  standalone: true,
  imports: [ParkourIconComponent],
})
export class ParkourInfoCardComponent {
  @Input() public icon: TParkourIconName = "info-circle";
  @Input() public style: string = "bg-n-300";
  @Input() public classes?: string;
}
