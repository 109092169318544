<article class="clickable-card-200 rounded overflow-hidden h-full flex flex-col cursor-pointer">
  <parkour-image
    [alt]="image.alt"
    [src]="image.src"
    class="block w-full h-[6.75rem] lg:h-[12.75rem]"
  />

  <div class="p-4 grid gap-1 grid-cols-[1fr_auto] grid-rows-[auto_1fr_auto] h-full">
    <parkour-action
      [link]="link"
      class="col-start-1 col-end-2"
      classes="before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:z-10 focus-visible:outline-none"
    >
      <h4 class="typo-body variant-body-fat">{{ title }}</h4>
    </parkour-action>

    <span class="col-start-1 col-end-2 row-start-3 typo-body variant-body-small text-n-600">{{
      getThemaLabelString(themes)
    }}</span>

    <parkour-bookmark-button
      (clicked)="onClickedBookmark($event)"
      [isActive]="isBookmarked"
      class="col-start-2 col-end-3 relative z-20"
    />
  </div>
</article>
