import { Pipe, PipeTransform } from '@angular/core';
import { Bericht, isSupportedBericht, SupportedBericht } from '../model/gesprek';

@Pipe({
  standalone: true,
  name: 'isSupportedBericht',
})
export class IsSupportedBerichtPipe implements PipeTransform {
  transform(bericht: Bericht): bericht is SupportedBericht {
    return isSupportedBericht(bericht);
  }
}
