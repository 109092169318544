import { Component, Input } from '@angular/core';
import { NgClass } from '@angular/common';
import { ProfielFotoBadgeSize } from '../profiel-foto-badge/profiel-foto-badge.component';

@Component({
  selector: 'parkour-foto-badge-placeholder',
  templateUrl: './foto-badge-placeholder.component.html',
  standalone: true,
  imports: [NgClass],
})
export class FotoBadgePlaceholderComponent {
  @Input({ required: true }) size!: ProfielFotoBadgeSize;
}
