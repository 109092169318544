import { Component, Input } from '@angular/core';
import { ContextOption } from '../../../../profiel/model/context-option';
import { ProfielFotoData } from '../../../model/teamlid-info';
import { ProfielFotoBadgeComponent } from '../../profiel-foto-badge/profiel-foto-badge.component';

@Component({
  selector: 'parkour-persoon-select-option',
  standalone: true,
  imports: [ProfielFotoBadgeComponent],
  templateUrl: './persoon-select-option.component.html',
})
export class PersoonSelectOptionComponent {
  @Input({ required: true }) option!: ContextOption | null;

  optionToProfielData(option: ContextOption): ProfielFotoData {
    if (option.geblokkeerd) {
      return { status: 'GEBLOKKEERD' };
    } else {
      return { id: option.contextId, profielFotoId: option.profielFotoId, status: 'ACTIEF' };
    }
  }
}
