import { Component } from '@angular/core';
import { ParkourCookiesService } from '../../../shared/services/parkour-cookies.service';
import { CoreModule } from '../../../core/core.module';
import { AdaptToSidebarVisibleDirective } from '../../../shared/directives/adapt-to-sidebar-visible.directive';
import {
  ParkourActionComponent,
  ParkourButtonComponent,
  ParkourHeroComponent,
  ParkourIconComponent,
  ParkourPageGradientComponent,
} from '@parkour/ui';
import { isNativeApp } from '../../../utils';
import { ParkourBackButtonDirective } from '../../../shared/directives/parkour-back-button.directive';
import { PageLayoutComponent } from '../../../shared/components/page-layout/page-layout.component';
import { DownloadAppsComponent } from '../../../shared/components/download-apps/download-apps.component';
import { ParkourPage } from '../../../shared/parkour-page';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';
import { ParkourRefreshDirective } from '../../../shared/directives/parkour-refresh-directive';

@Component({
  imports: [
    CoreModule,
    AdaptToSidebarVisibleDirective,
    ParkourBackButtonDirective,
    ParkourPageGradientComponent,
    ParkourHeroComponent,
    ParkourActionComponent,
    ParkourIconComponent,
    ParkourButtonComponent,
    PageLayoutComponent,
    DownloadAppsComponent,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonTitle,
    IonContent,
    IonRefresher,
    IonRefresherContent,
    ParkourRefreshDirective,
  ],
  standalone: true,
  templateUrl: './over-parkour.page.html',
})
export class OverParkourPage extends ParkourPage {
  isCookiesModalOpen = false;
  protected readonly isNativeApp = isNativeApp;

  constructor(protected readonly parkourCookiesService: ParkourCookiesService) {
    super();
  }
}
