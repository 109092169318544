import { Component, Input } from '@angular/core';
import { Gebeurtenis } from '../../model/gebeurtenis';
import { themaMapping } from '../../../../home/model/thema.const';
import { GebeurtenisHeroPipe } from '../../pipes/gebeurtenis-hero.pipe';
import { GebeurtenisImagePipe } from '../../pipes/gebeurtenis-image.pipe';
import { AsyncPipe } from '@angular/common';
import { ParkourImageComponent, ParkourPictureComponent } from '@parkour/ui';

@Component({
  selector: 'parkour-gebeurtenis-preview-image',
  templateUrl: './gebeurtenis-preview-image.component.html',
  standalone: true,
  imports: [
    GebeurtenisHeroPipe,
    GebeurtenisImagePipe,
    AsyncPipe,
    ParkourImageComponent,
    ParkourPictureComponent,
  ],
})
export class GebeurtenisPreviewImageComponent {
  @Input({ required: true }) gebeurtenis!: Gebeurtenis;
  protected readonly themaMapping = themaMapping;
}
