<ng-container *ngIf="link; else externalButton">
  <parkour-action
    #action
    *ngIf="format === 'default'; else isIconButton"
    [ariaControls]="ariaControls"
    [ariaCurrent]="ariaCurrent"
    [ariaExpanded]="ariaExpanded"
    [ariaLabel]="ariaLabel"
    [ariaPressed]="ariaPressed"
    [class]="getParkourActionClasses()"
    [classes]="getParkourActionRootClasses()"
    [disabled]="disabled"
    [link]="link"
    [ngClasses]="getParkourActionNgClasses()"
    [queryParams]="queryParams"
    [routeInfo]="routeInfo"
    [tabIndex]="tabIndex"
    [testId]="testId"
  >
    <ng-container [ngTemplateOutlet]="buttonContent"></ng-container>
  </parkour-action>
</ng-container>

<ng-template #externalButton>
  <parkour-action
    #action
    (clicked)="clickAction($event)"
    *ngIf="format === 'default'; else isIconButton"
    [ariaControls]="ariaControls"
    [ariaCurrent]="ariaCurrent"
    [ariaExpanded]="ariaExpanded"
    [ariaLabel]="ariaLabel"
    [ariaPressed]="ariaPressed"
    [class]="getParkourActionClasses()"
    [classes]="getParkourActionRootClasses()"
    [disabled]="disabled"
    [download]="download"
    [href]="href"
    [ngClasses]="getParkourActionNgClasses()"
    [tabIndex]="tabIndex"
    [testId]="testId"
    [type]="type"
  >
    <ng-container [ngTemplateOutlet]="buttonContent"></ng-container>
  </parkour-action>
</ng-template>

<ng-template #isIconButton>
  <parkour-action
    #action
    *ngIf="format === 'iconButton' && link; else isExternalIconButton"
    [ariaControls]="ariaControls"
    [ariaCurrent]="ariaCurrent"
    [ariaExpanded]="ariaExpanded"
    [ariaLabel]="ariaLabel"
    [ariaPressed]="ariaPressed"
    [class]="getParkourActionClasses()"
    [classes]="getParkourActionRootClasses()"
    [disabled]="disabled"
    [link]="link"
    [ngClasses]="getParkourActionNgIconButtonClasses()"
    [queryParams]="queryParams"
    [routeInfo]="routeInfo"
    [tabIndex]="tabIndex"
    [testId]="testId"
  >
    <parkour-icon
      *ngIf="icon"
      [name]="icon"
      [size]="iconSize"
      class="inline-flex text-current"
    />

    <ng-container *ngIf="!icon" [ngTemplateOutlet]="textContent"></ng-container>
  </parkour-action>
</ng-template>

<ng-template #isExternalIconButton>
  <parkour-action
    #action
    (clicked)="clickAction($event)"
    [ariaControls]="ariaControls"
    [ariaCurrent]="ariaCurrent"
    [ariaExpanded]="ariaExpanded"
    [ariaLabel]="ariaLabel"
    [ariaPressed]="ariaPressed"
    [class]="getParkourActionClasses()"
    [classes]="getParkourActionRootClasses()"
    [disabled]="disabled"
    [href]="href"
    [ngClasses]="getParkourActionNgIconButtonClasses()"
    [queryParams]="queryParams"
    [tabIndex]="tabIndex"
    [testId]="testId"
    [type]="type"
  >
    <parkour-icon
      *ngIf="icon"
      [name]="icon"
      [size]="iconSize"
      class="inline-flex text-current"
    />

    <ng-container *ngIf="!icon" [ngTemplateOutlet]="textContent"></ng-container>
  </parkour-action>
</ng-template>

<ng-template #buttonContent>
  @if (loading) {
    <parkour-loading-spinner
      class="relative z-10"
      size="small"
      variant="secondary"
    />
  } @else {
    <parkour-icon
      *ngIf="iconBefore"
      [name]="iconBefore"
      class="relative z-10 inline-flex text-current"
    />
  }

  <ng-container [ngTemplateOutlet]="textContent"></ng-container>

  <parkour-icon
    *ngIf="iconAfter"
    [name]="iconAfter"
    class="relative z-10 inline-flex text-current group-hover:translate-x-[0.1rem] transition-transform"
  />
</ng-template>

<ng-template #textContent>
  <span
    [ngClass]="{
      'variant-body-small': size === 'small',
      underline: variant === 'ghost' || variant === 'ghost-light',
    }"
    class="text-current typo-body variant-body-fat relative z-10 truncate"
  >
    <ng-content></ng-content>
  </span>
</ng-template>
