<div class="flex flex-col items-start gap-y-4">
  <ion-radio
    [disabled]="disabled"
    [id]="inputId"
    [value]="value"
    class="typo-body variant-body-fat"
    labelPlacement="end"
    mode="md"
  >
    <div class="flex items-center gap-x-2">
      <parkour-icon *ngIf="icon" [name]="icon" size="3" />

      <p *ngIf="label" class="typo-label whitespace-normal">{{ label }}</p>
    </div>
  </ion-radio>

  <ng-content></ng-content>
</div>
