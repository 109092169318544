<ion-header class="full-header">
  <ion-toolbar>
    <ion-title aria-level="1" role="heading"
      >{{ 'uitnodiging-weigeren-page.title' | translate }}
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content [scrollY]="false">
  <parkour-page-layout>
    <div class="py-8 container container-space h-full">
      <div class="container-1-col flex flex-col justify-center h-full">
        <parkour-info-card icon="check" class="mt-auto" [style]="'bg-n-200'">
          <span class="typo-body variant-body-fat">{{
            'uitnodiging-weigeren-page.title' | translate
          }}</span>

          <p class="text-n-600">{{ 'uitnodiging-weigeren-page.description' | translate }}</p>
        </parkour-info-card>

        <parkour-button class="block w-full mt-auto" link="/">
          {{ 'buttons.go-home' | translate }}
        </parkour-button>
      </div>
    </div>
  </parkour-page-layout>
</ion-content>
