import { Component, input, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ParkourButtonComponent, ParkourModalComponent } from '@parkour/ui';
import { TranslateModule } from '@ngx-translate/core';
import { CustomFotoEditModalComponent } from '../custom-foto-edit-modal/custom-foto-edit-modal.component';
import { FotoId } from 'parkour-web-app-dto';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { combineLatest, Observable, of, ReplaySubject, Subject, switchMap, takeUntil } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { FileService } from '../../services/file.service';
import { ImageUploadData } from '../../model/image-upload';

@Component({
  standalone: true,
  selector: 'parkour-custom-foto-edit',
  templateUrl: './custom-foto-edit.component.html',
  imports: [
    ParkourButtonComponent,
    ParkourModalComponent,
    TranslateModule,
    CustomFotoEditModalComponent,
    AsyncPipe,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CustomFotoEditComponent,
    },
  ],
})
export class CustomFotoEditComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @ViewChild(ParkourModalComponent) modal!: ParkourModalComponent;
  @Input({ required: true }) mode!: 'create' | 'edit';
  @Input({ required: true }) fotoName!: string;
  @Input() aspectRatio: number = 1;

  existingImageGetFunction = input.required<(imageId: FotoId) => Observable<string>>();

  clearCropper = false;

  destroy$ = new Subject<void>();
  currentImage$ = new ReplaySubject<ImageUploadData>(1);
  imageBase64$ = this.currentImage$.pipe(
    switchMap((image) => {
      switch (image.type) {
        case 'converting':
          return of(null);
        case 'no-image':
          return of(null);
        case 'uploaded':
          return this.existingImageGetFunction()(image.imageId);
        case 'uploading':
          return of(image.base64);
        case 'error':
          return of(null);
        case 'newly-uploaded':
          return of(image.base64);
      }
    }),
  );

  private onChange$ = new ReplaySubject<(imageUploadData: ImageUploadData) => void>(1);
  private onTouched?: () => void;

  constructor(private readonly fileService: FileService) {}

  ngOnInit() {
    combineLatest([this.currentImage$, this.onChange$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([imageData, onChange]) => {
        onChange(imageData);
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  writeValue(imageUploadData: ImageUploadData): void {
    this.currentImage$.next(imageUploadData);
  }

  registerOnChange(onChange: (imageUploadData: ImageUploadData) => void): void {
    this.onChange$.next(onChange);
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  clearPicture() {
    this.currentImage$.next({ type: 'no-image' });
    this.clearCropper = true;
    this.modal.close();
  }

  onAddPicture() {
    this.clearCropper = false;
    this.modal.open();
  }

  onHasBeenDismissed() {
    this.clearCropper = true;
  }

  onPictureSubmit(blob: Blob) {
    this.onTouched?.();
    this.modal.close();

    this.fileService.uploadImageBlob(blob, 'jpg', this.fotoName, 'verhaal').subscribe((image) => {
      if (image.type === 'error') {
        this.currentImage$.next({ type: 'no-image' });
        throw image.error;
      } else {
        this.currentImage$.next(image);
      }
    });
  }
}
