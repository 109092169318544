import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { GebeurtenisUpsert } from '../../model/gebeurtenis';
import { DelenFormService } from '../../../../delen/service/delen-form.service';
import { DelenSectionGroup } from '../../../../delen/model/delen-form-group';
import { TeamlidProfiel } from '../../../../team/model/teamlid.model';
import { RawValueOfFormGroup } from '../../../../shared/components/parkour-form/parkour-form.types';
import { Observable } from 'rxjs';
import { ParkourFormComponent } from '../../../../shared/components/parkour-form/parkour-form.component';
import { ParkourButtonComponent, ParkourCheckboxComponent } from '@parkour/ui';
import { DelenSectionComponent } from '../../../../delen/component/delen-section/delen-section.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  selector: 'parkour-jeugdhulp-historiek-form',
  templateUrl: './jeugdhulp-historiek-form.component.html',
  imports: [
    ParkourFormComponent,
    ParkourButtonComponent,
    DelenSectionComponent,
    TranslateModule,
    ParkourCheckboxComponent,
    ReactiveFormsModule,
  ],
})
export class JeugdhulpHistoriekFormComponent implements OnInit {
  @Input({ required: true }) formId!: string;
  @Input() initialState: Partial<GebeurtenisUpsert> = {};
  @Input({ required: true }) teamleden!: TeamlidProfiel[];
  @Input({ required: true }) submitJeugdhulpHistoriekForm!: (
    gebeurtenisUpsert: GebeurtenisUpsert,
  ) => Observable<unknown>;

  eyouthGebeurtenisUpdateForm!: FormGroup<{
    verborgenVoorJongere: FormControl<boolean>;
    delenSection: DelenSectionGroup;
  }>;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly delenFormService: DelenFormService,
  ) {}

  ngOnInit() {
    this.eyouthGebeurtenisUpdateForm = this.formBuilder.nonNullable.group({
      verborgenVoorJongere: this.formBuilder.nonNullable.control(
        this.initialState.type === 'EYOUTH'
          ? this.initialState.verborgenVoorJongere ?? false
          : false,
      ),
      delenSection: this.delenFormService.createDelenFormGroup(this.teamleden, this.initialState),
    });
  }

  submitForm = (
    formValue: RawValueOfFormGroup<
      FormGroup<{ verborgenVoorJongere: FormControl<boolean>; delenSection: DelenSectionGroup }>
    >,
  ) => {
    return this.submitJeugdhulpHistoriekForm({
      verborgenVoorJongere: formValue.verborgenVoorJongere,
      deelMode: formValue.delenSection.deelMode,
      gedeeldMet: this.teamleden
        .filter((teamLid, index) => formValue.delenSection.gedeeldMet[index].gedeeldMet)
        .map((teamlid) => teamlid.id),
      type: 'EYOUTH',
    });
  };
}
