<div
  class="flex items-baseline justify-between gap-2 p-4 rounded {{ style }} {{
    classes
  }}"
>
  <parkour-icon class="text-n-600" [name]="icon" size="3" />

  <div class="flex-1">
    <ng-content></ng-content>
  </div>
</div>
