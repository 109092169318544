import { Observable } from 'rxjs';

export type PageTitle =
  | string
  | {
      category: string;
      title: string;
    };

// Interface for pages that have a custom page title
export interface CustomPageTitle {
  getCustomPageTitle(): Observable<PageTitle>;
}

export const implementsCustomPageTitle = (page: object): page is CustomPageTitle => {
  return 'getCustomPageTitle' in page;
};
