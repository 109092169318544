import { AfterViewInit, Directive, ElementRef } from "@angular/core";
import { register } from "swiper/element/bundle";
import { SwiperContainer } from "swiper/element";

register();

@Directive({
  selector: "[parkourCarouselElement]",
})
export class ParkourCarouselDirective implements AfterViewInit {
  constructor(private element: ElementRef<SwiperContainer>) {}

  ngAfterViewInit() {
    this.element.nativeElement.initialize();
  }
}
