import { Component } from '@angular/core';
import { combineLatest, map, Observable, of, shareReplay, startWith, switchMap, take } from 'rxjs';
import { BerichtenService } from '../../service/berichten.service';
import { FotoId, validateGesprekId } from 'parkour-web-app-dto';
import { ActivatedRoute, Router } from '@angular/router';
import { Gesprek } from '../../model/gesprek';
import { AangemeldeUser } from '../../../authentication/user';
import {
  ParkourButtonComponent,
  ParkourCardComponent,
  ParkourPageGradientComponent,
  ParkourPopupService,
} from '@parkour/ui';
import { ContextService } from '../../../shared/services/context.service';
import { JongereContext, TeamlidContext } from '../../../shared/model/context';
import { GesprekForm } from './gesprek-settings.types';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ParkourBackButtonDirective } from '../../../shared/directives/parkour-back-button.directive';
import { AsyncPipe } from '@angular/common';
import { ShowIfSuccesfulDirective } from '../../../shared/directives/show-if-succesful.directive';
import { GesprekFotoBadgeComponent } from '../../component/gesprek-foto-badge/gesprek-foto-badge.component';
import { ProfessioneelIndicatorComponent } from '../../../shared/components/professioneel-indicator/professioneel-indicator.component';
import { ParkourHeaderActionComponent } from '../../../shared/components/parkour-header-action/parkour-header-action.component';
import { PageLayoutComponent } from '../../../shared/components/page-layout/page-layout.component';
import { PageContentDirective } from '../../../shared/directives/page-content.directive';
import { CustomFotoEditComponent } from '../../../shared/components/custom-foto-edit/custom-foto-edit.component';
import { GroepsgesprekDeelnemerListComponent } from '../../component/groepsgesprek-deelnemer-list/groepsgesprek-deelnemer-list.component';
import { GroepsgesprekGeblokkeerdeDeelnemerListComponent } from '../../component/groepsgesprek-geblokkeerde-deelnemer-list/groepsgesprek-geblokkeerde-deelnemer-list.component';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonRefresher,
  IonRefresherContent,
  IonToolbar,
} from '@ionic/angular/standalone';
import { ParkourRefreshDirective } from '../../../shared/directives/parkour-refresh-directive';
import { ParkourDataPage } from '../../../shared/parkour-data-page';
import {
  FormSubmitObservableGenerator,
  ParkourFormComponent,
} from '../../../shared/components/parkour-form/parkour-form.component';
import { CustomPageTitle } from '../../../shared/custom-page-title';
import { RawValueOfFormGroup } from '../../../shared/components/parkour-form/parkour-form.types';
import { getSecureImage } from '../../../shared/pipes/secure-image.pipe';
import { ImageUploadData } from '../../../shared/model/image-upload';
import AuthService from '../../../authentication/service/auth.service';

export type GesprekSettingsPageData = {
  user: AangemeldeUser;
  gesprek: Gesprek;
  context: JongereContext | TeamlidContext;
};

@Component({
  standalone: true,
  templateUrl: './gesprek-settings.page.html',
  imports: [
    ParkourBackButtonDirective,
    AsyncPipe,
    ShowIfSuccesfulDirective,
    GesprekFotoBadgeComponent,
    ProfessioneelIndicatorComponent,
    ParkourHeaderActionComponent,
    TranslateModule,
    PageLayoutComponent,
    PageContentDirective,
    ParkourPageGradientComponent,
    FormsModule,
    CustomFotoEditComponent,
    ParkourCardComponent,
    ParkourButtonComponent,
    GroepsgesprekDeelnemerListComponent,
    GroepsgesprekGeblokkeerdeDeelnemerListComponent,
    IonRefresher,
    IonRefresherContent,
    IonContent,
    ParkourRefreshDirective,
    IonButtons,
    IonToolbar,
    IonHeader,
    IonBackButton,
    ReactiveFormsModule,
    ParkourFormComponent,
  ],
})
export class GesprekSettingsPage
  extends ParkourDataPage<GesprekSettingsPageData>
  implements CustomPageTitle
{
  gesprekForm: GesprekForm = this.formBuilder.nonNullable.group({
    imageData: this.formBuilder.nonNullable.control<ImageUploadData>({ type: 'no-image' }),
  });

  imageBase64$ = this.gesprekForm.controls.imageData.valueChanges.pipe(
    startWith(this.gesprekForm.controls.imageData.value),
    switchMap((imageData) => {
      switch (imageData.type) {
        case 'converting':
          return of(null);
        case 'no-image':
          return of(null);
        case 'uploaded':
          return this.getGesprekFoto(imageData.imageId);
        case 'uploading':
          return of(imageData.base64);
        case 'error':
          return of(null);
        case 'newly-uploaded':
          return of(imageData.base64);
      }
    }),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  constructor(
    private readonly berichtenService: BerichtenService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly contextService: ContextService,
    private readonly authService: AuthService,
    private readonly popupService: ParkourPopupService,
    private readonly formBuilder: FormBuilder,
    private readonly router: Router,
  ) {
    super();
  }

  override ionViewWillEnter() {
    super.ionViewWillEnter();

    this.mainPageData$.pipe(take(1)).subscribe((data) => {
      if (data.success) {
        const imageData: ImageUploadData = data.value.gesprek.fotoId
          ? {
              type: 'uploaded',
              imageId: data.value.gesprek.fotoId,
            }
          : { type: 'no-image' };

        this.gesprekForm.controls.imageData.setValue(imageData, { emitEvent: true });
      }
    });
  }

  override retrieveMainData(): Observable<GesprekSettingsPageData> {
    const gesprekId$ = this.activatedRoute.paramMap.pipe(
      map((params) => validateGesprekId(params.get('gesprekId'))),
      take(1),
    );

    return combineLatest({
      gesprek: gesprekId$.pipe(switchMap((id) => this.berichtenService.getGesprek(id))),
      user: this.authService.getAangemeldeUser$(),
      context: this.contextService.contextWithActiveJongere$(),
    });
  }

  getCustomPageTitle() {
    return this.generateDefaultTitle(
      (data) => data.gesprek.getGesprekNaam(data.user),
      'berichten.groepsgesprek-instellingen',
      true,
    );
  }

  onGroepsgesprekStopzetten() {
    this.popupService
      .showPopup({
        // TODO: Use text from JSON Berichten
        title: 'Groepsgesprek stopzetten',
        description:
          'Je verlaat het gesprek als teameigenaar. Wil je het groepsgesprek stopzetten voor alle deelnemers?',
        jaText: 'Ja',
        icon: 'logout',
      })
      .then((result) => {
        if (result === 'yes') {
          this.getCurrentMainDataOrThrow()
            .pipe(switchMap((data) => this.berichtenService.archiveerGesprek(data.gesprek.id)))
            .subscribe(() => {
              this.router.navigate(['..'], { relativeTo: this.activatedRoute });
            });
        }
      });
  }

  onGroepsgesprekVerlaten() {
    this.popupService
      .showPopup({
        // TODO: Add text in JSON Berichten
        title: 'Groepsgesprek verlaten',
        description:
          'Je verzonden berichten blijven zichtbaar voor actieve deelnemers. Wil je het groepsgesprek verlaten?',
        jaText: 'Ja',
        icon: 'logout',
      })
      .then((result) => {
        if (result === 'yes') {
          this.getCurrentMainDataOrThrow()
            .pipe(switchMap((data) => this.berichtenService.verlaatGesprek(data.gesprek.id)))
            .subscribe(() => {
              this.contextService.navigateToAbsoluteUrl(['berichten']);
            });
        }
      });
  }

  protected getGesprekFoto = (imageId: FotoId) =>
    this.getCurrentMainDataOrThrow().pipe(
      switchMap((data) =>
        this.berichtenService.getGesprekFotoUrl(data.gesprek.id, imageId, 'ORIGINAL'),
      ),
      switchMap((url) => getSecureImage(url)),
    );

  protected submitGesprekForm: FormSubmitObservableGenerator<RawValueOfFormGroup<GesprekForm>> = (
    formValue: RawValueOfFormGroup<GesprekForm>,
  ) =>
    this.getCurrentMainDataOrThrow().pipe(
      switchMap((data) => {
        if (
          formValue.imageData.type !== 'no-image' &&
          formValue.imageData.type !== 'uploaded' &&
          formValue.imageData.type !== 'newly-uploaded'
        ) {
          throw new Error('Fout bij uploaden afbeelding');
        }

        const imageId =
          formValue.imageData.type === 'no-image' ? undefined : formValue.imageData.imageId;

        return this.berichtenService.updateGesprek(data.gesprek.id, { imageId });
      }),
      switchMap(() => this.router.navigate(['..'], { relativeTo: this.activatedRoute })),
    );
}
