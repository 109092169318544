import { Component, Input } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { QrCodeModule } from 'ng-qrcode';
import { TranslateModule } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import { Directory } from '@capacitor/filesystem';
import { FileService } from 'src/app/shared/services/file.service';
import { Profiel } from '../../model/profiel';
import { getProfielnaamWithoutFallback } from 'parkour-web-app-dto';
import { ProfielFotoPipe } from '../../../shared/pipes/profiel-foto.pipe';
import { ProfielFotoComponent } from '../../../shared/components/profiel-foto/profiel-foto.component';
import {
  ParkourAvatarComponent,
  ParkourButtonComponent,
  ParkourInfoCardComponent,
  ParkourModalComponent,
} from '@parkour/ui';

@Component({
  selector: 'parkour-profiel-qr-code-button',
  standalone: true,
  imports: [
    AsyncPipe,
    QrCodeModule,
    TranslateModule,
    ProfielFotoPipe,
    ProfielFotoComponent,
    ParkourButtonComponent,
    ParkourModalComponent,
    ParkourAvatarComponent,
    ParkourInfoCardComponent,
  ],
  templateUrl: './profiel-qr-code-button.component.html',
})
export class ProfielQrCodeButtonComponent {
  @Input({ required: true }) profiel!: Profiel;

  qrCodeUrl: string | undefined;

  constructor(private readonly fileService: FileService) {}

  getQrCodeUrl(): string {
    return `${environment.API_BASE_URL}/app/me/profiel/team/add?profielId=${this.profiel.id}`;
  }

  async onDownloadQr() {
    const href = this.convertCanvasToBase64();
    href && (await this.fileService.downloadFile(href, 'qr-code.png', Directory.Documents));
  }

  private convertCanvasToBase64(): string | null {
    const qrCode: HTMLElement | null = document.getElementById('qr-code') as HTMLElement;
    let href = null;
    if (qrCode.children.item(0)) {
      const canvas = qrCode.children.item(0) as HTMLCanvasElement;
      href = canvas.toDataURL();
    }
    return href;
  }

  protected readonly getProfielnaamWithoutFallback = getProfielnaamWithoutFallback;
}
