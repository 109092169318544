import { Component } from '@angular/core';
import { combineLatest, map, Observable, of, shareReplay, switchMap } from 'rxjs';
import { BerichtenService } from '../../service/berichten.service';
import { Gesprek } from '../../model/gesprek';
import { AangemeldeUser } from '../../../authentication/user';
import { ContextService } from '../../../shared/services/context.service';
import { JongereContext, TeamlidContext } from '../../../shared/model/context';
import { berichtenHero } from '../../model/berichten-hero';
import { HeroSources } from '../../../shared/components/page-with-hero/page-with-hero.types';
import { ProfielFotoPipe } from 'src/app/shared/pipes/profiel-foto.pipe';
import { meldingenForPage } from '../../../meldingen/config';
import {
  IonContent,
  IonHeader,
  IonRefresher,
  IonRefresherContent,
} from '@ionic/angular/standalone';
import { PageLayoutComponent } from '../../../shared/components/page-layout/page-layout.component';
import { PageWithHeroComponent } from '../../../shared/components/page-with-hero/page-with-hero.component';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { PageSectionDirective } from '../../../shared/directives/page-section.directive';
import {
  ParkourBlockPlaceholderComponent,
  ParkourButtonComponent,
  ParkourPageImageComponent,
} from '@parkour/ui';
import { GesprekOverviewCardPage } from '../../../shared/components/gesprek-overview-card/gesprek-overview-card.component';
import { ParkourDataPage } from '../../../shared/parkour-data-page';
import { ParkourRefreshDirective } from '../../../shared/directives/parkour-refresh-directive';
import {
  MarksMeldingenAsReadOnView,
  MeldingenToMarkAsRead,
} from '../../../shared/marks-meldingen-as-read';
import { ActiefAlsJongereDirective } from '../../../shared/directives/actief-als-jongere.directive';
import AuthService from '../../../authentication/service/auth.service';
import { BerichtenPlaceholderComponent } from '../../component/berichten-placeholder/berichten-placeholder.component';

export type GesprekkenOverviewPageData = {
  user: AangemeldeUser;
  gesprekken: Gesprek[];
  context: JongereContext | TeamlidContext;
};

@Component({
  standalone: true,
  templateUrl: './gesprekken-overview-page.component.html',
  imports: [
    IonHeader,
    IonContent,
    PageLayoutComponent,
    PageWithHeroComponent,
    AsyncPipe,
    TranslateModule,
    PageSectionDirective,
    ParkourButtonComponent,
    NgIf,
    GesprekOverviewCardPage,
    NgForOf,
    ParkourBlockPlaceholderComponent,
    ParkourPageImageComponent,
    IonRefresher,
    IonRefresherContent,
    ParkourRefreshDirective,
    ActiefAlsJongereDirective,
    BerichtenPlaceholderComponent,
  ],
  providers: [ProfielFotoPipe],
})
export class GesprekkenOverviewPage
  extends ParkourDataPage<GesprekkenOverviewPageData>
  implements MarksMeldingenAsReadOnView
{
  berichtenHero$: Observable<HeroSources> = new Observable();

  ongelezenBerichtenMeldingen$ = this.meldingenService
    .getMeldingenInCurrentContextByType(
      meldingenForPage.BERICHTEN_TAB.GESPREKKEN_PAGE.GESPREK_PAGE.allMeldingen,
    )
    .pipe(shareReplay(1));

  constructor(
    private readonly berichtenService: BerichtenService,
    private readonly authService: AuthService,
    private readonly contextService: ContextService,
    private readonly profielFotoPipe: ProfielFotoPipe,
  ) {
    super();
  }

  override retrieveMainData(): Observable<GesprekkenOverviewPageData> {
    return combineLatest({
      user: this.authService.getAangemeldeUser$(),
      gesprekken: this.berichtenService.getGesprekken(),
      context: this.contextService.contextWithActiveJongere$(),
    });
  }

  getMeldingenToMarkAsRead() {
    return of(
      new MeldingenToMarkAsRead(meldingenForPage.BERICHTEN_TAB.GESPREKKEN_PAGE.specificMeldingen),
    );
  }

  override ionViewWillEnter(): void {
    super.ionViewWillEnter();

    this.berichtenHero$ = this.contextService.contextWithActiveJongere$().pipe(
      switchMap((context) => {
        switch (context.type) {
          case 'jongere':
            return of(berichtenHero);
          case 'teamlid':
            return this.profielFotoPipe.transform(context.jongereProfiel, 'full').pipe(
              map((profielFoto) => ({
                background: profielFoto,
                hero: {
                  src: profielFoto,
                  alt: '',
                },
              })),
            );
        }
      }),
    );
  }
}
