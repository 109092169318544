import { Injectable } from '@angular/core';
import { filter, forkJoin, Observable, of, switchMap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { LoggingService } from '../../core/logging.service';
import AuthService from './auth.service';
import { HttpClient } from '@angular/common/http';
import BackgroundDetectionService from './background-detection.service';

@Injectable({
  providedIn: 'root',
})
export default class StatusService {
  constructor(
    private readonly http: HttpClient,
    private readonly loggingService: LoggingService,
    private readonly authService: AuthService,
    private readonly backgroundDetectionService: BackgroundDetectionService,
  ) {}

  initialize() {
    // Poll user status every minute to check if user is still logged in, used to trigger automatic logout
    setInterval(
      () =>
        this.pollStatus().subscribe({
          error: (err) => this.loggingService.error('Getting user status failed', err),
        }),
      1000 * 30,
    );
  }

  pollStatus(): Observable<string> {
    return forkJoin([
      this.authService.getCurrentUser$(),
      this.backgroundDetectionService.isCurrentlyActive(),
    ]).pipe(
      filter(([, active]) => active),
      switchMap(([user]) => {
        if (user.type === 'aangemeld') {
          return this.http.get(`${environment.API_BASE_URL}/api/users/current/status`, {
            responseType: 'text',
          });
        } else {
          return of('NIET_AANGEMELD');
        }
      }),
    );
  }
}
