import { Component, Input } from '@angular/core';
import { ParkourButtonComponent } from '@parkour/ui';
import { TranslateModule } from '@ngx-translate/core';
import AuthService from '../../../authentication/service/auth.service';

@Component({
  standalone: true,
  selector: 'parkour-aanmeld-button',
  templateUrl: './aanmeld-button.component.html',
  imports: [ParkourButtonComponent, TranslateModule],
})
export class AanmeldButtonComponent {
  @Input({ required: true }) redirectAfterLogin!: string;

  constructor(private authService: AuthService) {}

  onLoginClick() {
    this.authService.login({ redirectUrl: this.redirectAfterLogin }).subscribe();
  }
}
