import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TeamlidProfiel } from '../../team/model/teamlid.model';
import { JongereProfiel, Profiel } from '../../profiel/model/profiel';
import { BeeldbellenService, VideogesprekData } from '../beeldbellen.service';
import { ParkourHeaderActionComponent } from '../../shared/components/parkour-header-action/parkour-header-action.component';
import { ProfielFotoBadgeComponent } from '../../shared/components/profiel-foto-badge/profiel-foto-badge.component';
import { RolLabelPipe } from '../../shared/pipes/rol-label.pipe';
import { ProfessioneelIndicatorComponent } from '../../shared/components/professioneel-indicator/professioneel-indicator.component';
import { TranslateModule } from '@ngx-translate/core';
import { ProfielnaamPipe } from '../../shared/pipes/profielnaam.pipe';
import { ParkourBackButtonDirective } from '../../shared/directives/parkour-back-button.directive';
import { IonButtons, IonHeader, IonToolbar } from '@ionic/angular/standalone';
import { ActivityDetectionService } from '../../authentication/service/activity-detection.service';
import { JitsiConfigOverwrite, JitsiMeetWebApiArguments } from '../model/jitsi.model';
import { environment } from '../../../environments/environment';

@Component({
  standalone: true,
  templateUrl: './jitsi.component.html',
  imports: [
    ParkourHeaderActionComponent,
    ProfielFotoBadgeComponent,
    RolLabelPipe,
    ProfessioneelIndicatorComponent,
    TranslateModule,
    ProfielnaamPipe,
    ParkourBackButtonDirective,
    IonHeader,
    IonToolbar,
    IonButtons,
  ],
})
export class JitsiComponent implements AfterViewInit, OnInit, OnDestroy {
  @ViewChild('jaasContainer') jaasContainer!: ElementRef<HTMLElement>;
  @Input({ required: true }) jwt!: string;
  @Input({ required: true }) roomId!: string;
  @Input({ required: true }) customRoomName!: string;
  @Input({ required: true }) initiator!: Profiel;
  @Input({ required: true }) data!: VideogesprekData;
  @Output() readyToClose = new EventEmitter<void>();

  private routerSubscription?: Subscription;

  private jaasJitsiConfigOverrides: Partial<JitsiConfigOverwrite> = {
    subject: '',
    prejoinConfig: {
      enabled: false,
      hideDisplayName: true,
      hideExtraJoinButtons: [],
    },
    startWithAudioMuted: true,
    startWithVideoMuted: true,
    speakerStats: {
      disabled: true,
      disableSearch: true,
    },
    hideAddRoomButton: true,
    breakoutRooms: {
      hideAddRoomButton: true,
      hideAutoAssignButton: true,
      hideJoinRoomButton: true,
    },
    lobby: {
      enableChat: false,
    },
    remoteVideoMenu: {
      disablePrivateChat: true,
    },
    disableChatSmileys: true,
    disableModeratorIndicator: true,
    participantsPane: {
      enabled: false,
      hideModeratorSettingsTab: true,
      hideMoreActionsButton: true,
      hideMuteAllButton: true,
    },
    disableInviteFunctions: true,
    disableBeforeUnloadHandler: true, // disables callstats.js's window.onbeforeunload parameter.
    enableDisplayNameInStats: false,
    enableEmailInStats: false,
    gatherStats: false,
    disableReactions: true,
    disableReactionsModeration: true,
    disablePolls: true,
    // disableProfile: true, BREAKS JITSI CALL ON ANDROID
    securityUi: {
      hideLobbyButton: true,
      disableLobbyPassword: true,
    },
    disableThirdPartyRequests: true,
    toolbarButtons: [
      'camera',
      'closedcaptions',
      'desktop',
      'embedmeeting',
      'filmstrip',
      'fullscreen',
      'hangup',
      'highlight',
      'microphone',
      'noisesuppression',
      'select-background',
      'settings',
      'tileview',
      'toggle-camera',
      'videoquality',
    ],
    mainToolbarButtons: ['microphone', 'camera'],
    notifications: [],
    whiteboard: {
      enabled: false,
    },
    etherpad_base: null,
    openSharedDocumentOnJoin: false,
    p2p: {
      enabled: true,
    },
  };
  private pusherSubscription?: Subscription;

  constructor(
    private readonly router: Router,
    private readonly beeldbellenService: BeeldbellenService,
    private readonly activityDetectionService: ActivityDetectionService,
  ) {}

  ngOnInit(): void {
    this.activityDetectionService.startKeepingSessionActive();
  }

  ngAfterViewInit(): void {
    this.initializeJitsi();
  }

  ngOnDestroy(): void {
    this.beeldbellenService.destroyJitsiWebApi();
    this.pusherSubscription?.unsubscribe();
    this.routerSubscription?.unsubscribe();
    this.activityDetectionService.stopKeepingSessionActive();
  }

  async initializeJitsi() {
    this.routerSubscription = this.router.events.subscribe((e) => {
      if (e instanceof NavigationStart) {
        this.closeJitsiCall();
      }
    });
    await this.initializeJitsiWeb();
  }

  getGespreksPartner(): TeamlidProfiel | JongereProfiel {
    return this.initiator.id !== this.data.teamlid.id ? this.data.teamlid : this.data.jongere;
  }

  async initializeJitsiWeb() {
    const overwrite = environment.jitsiBaseUrl === '8x8.vc' ? this.jaasJitsiConfigOverrides : {};

    const jitsiWebApiArguments: JitsiMeetWebApiArguments = {
      lang: 'nl',
      roomName: this.roomId,
      parentNode: this.jaasContainer.nativeElement,
      jwt: this.jwt,
      GENERATE_ROOMNAMES_ON_WELCOME_PAGE: true,
      disableInviteFunctions: true,
      configOverwrite: {
        ...overwrite,
        startWithAudioMuted: true,
        startWithVideoMuted: true,
        subject: this.customRoomName,
      },
    };
    const webApi = this.beeldbellenService.initializeJitsiWebApi(
      jitsiWebApiArguments,
      this.data,
      this.initiator,
    );
    webApi?.addEventListener('readyToClose', () => {
      this.readyToClose.emit();
    });
  }

  async closeJitsiCall() {
    this.beeldbellenService.closeJitsiCall();

    this.readyToClose.emit();
  }
}
