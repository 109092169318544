import { Component, HostBinding, Input } from '@angular/core';
import { DeelnemerLeftEventDto } from 'parkour-web-app-dto';
import { AsyncPipe } from '@angular/common';
import { ProfielnaamPipe } from '../../../shared/pipes/profielnaam.pipe';
import { ProfielPipe } from '../../../shared/pipes/profiel.pipe';

@Component({
  selector: 'parkour-event-user-left',
  templateUrl: './event-user-left.component.html',
  imports: [AsyncPipe, ProfielnaamPipe, ProfielPipe],
  standalone: true,
})
export class EventUserLeftComponent {
  @Input({ required: true }) gesprekEvent!: DeelnemerLeftEventDto;

  @HostBinding('class') get class() {
    return 'chat-flow__event';
  }
}
