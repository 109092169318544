import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, Validators } from '@angular/forms';
import { NotitieUpsert } from '../../model/notitie';
import { CoreModule } from '../../../core/core.module';
import {
  ParkourButtonComponent,
  ParkourFormFieldComponent,
  ParkourInputComponent,
  ParkourTextareaComponent,
} from '@parkour/ui';

@Component({
  selector: 'parkour-notitie-form',
  standalone: true,
  imports: [
    FormsModule,
    CoreModule,
    ParkourFormFieldComponent,
    ParkourInputComponent,
    ParkourTextareaComponent,
    ParkourButtonComponent,
  ],
  templateUrl: './notitie-form.component.html',
})
export class NotitieFormComponent implements OnInit {
  notitieForm!: FormGroup<{
    titel: FormControl<string>;
    beschrijving: FormControl<string>;
  }>;
  @Input() type: 'default' | 'edit' = 'default';
  @Output() formSubmit = new EventEmitter<NotitieUpsert>();

  constructor(private readonly formBuilder: FormBuilder) {}

  private _initialState: Partial<NotitieUpsert> = {
    titel: '',
    beschrijving: '',
  };

  set initialState(value: Partial<NotitieUpsert>) {
    this._initialState = value;
    this.buildForm();
  }

  ngOnInit(): void {
    this.buildForm();
  }

  onFormSubmit() {
    this.formSubmit.emit({
      titel: this.notitieForm.getRawValue().titel,
      beschrijving:
        this.notitieForm.getRawValue().beschrijving === ''
          ? undefined
          : this.notitieForm.getRawValue().beschrijving,
    });
  }

  private buildForm() {
    this.notitieForm = this.formBuilder.nonNullable.group({
      titel: this.formBuilder.nonNullable.control(this._initialState.titel ?? '', [
        Validators.required,
        Validators.maxLength(255),
      ]),
      beschrijving: this.formBuilder.nonNullable.control(this._initialState.beschrijving ?? '', [
        Validators.maxLength(10_000),
      ]),
    });
  }
}
