import { Component, HostBinding } from '@angular/core';
import { ParkourBlockPlaceholderComponent } from '@parkour/ui';

@Component({
  standalone: true,
  selector: 'parkour-doelen-placeholder',
  templateUrl: './doelen-placeholder.component.html',
  imports: [ParkourBlockPlaceholderComponent],
})
export class DoelenPlaceholderComponent {
  @HostBinding('class') class = 'block';
}
