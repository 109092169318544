<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button [parkourBackButton]="['../..']" />
    </ion-buttons>

    <ng-container *parkourShowIfSuccessful="mainPageData$ | async; let gebeurtenis">
      <ion-title>
        @switch (gebeurtenis.type) {
          @case ('EYOUTH') {
            {{ 'verhaal.eyouth-gebeurtenis-details-page.title' | translate }}
          }
          @case ('PARKOUR') {
            {{ 'verhaal.gebeurtenis-details-page.title' | translate }}
          }
        }
      </ion-title>
    </ng-container>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-refresher slot="fixed" [parkourRefresh]="onPageRefresh">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>

  <parkour-page-layout>
    <ng-container
      *parkourPageContent="mainPageData$ | async; refresh: onPageRefresh; let gebeurtenis"
    >
      <article>
        @switch (gebeurtenis.type) {
          @case ('EYOUTH') {
            <parkour-jeugdhulphistoriek-details-component
              [gebeurtenis]="gebeurtenis"
              [exJongereView]="true"
            />
          }
          @case ('PARKOUR') {
            <parkour-gebeurtenis-details-component
              [gebeurtenis]="gebeurtenis"
              [exJongereView]="true"
            />
          }
        }
      </article>
    </ng-container>
  </parkour-page-layout>
</ion-content>
